import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisMembersAnalysisDetailOfPostsIndexRequestTypes,
  ApisMembersAnalysisDetailOfPostsIndexResponseTypes,
} from "~/domains";

type RequestDataType = {
  params: ApisMembersAnalysisDetailOfPostsIndexRequestTypes;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApisMembersAnalysisDetailOfPostsIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisMembersAnalysisDetailOfPostsIndexResponseTypes>(
      "/api/members/analysis/detail_of_posts",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params: ApisMembersAnalysisDetailOfPostsIndexRequestTypes;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersAnalysisDetailOfPostsIndex = ({
  params,
  config = {},
}: PropsType): UseQueryResult<ApisMembersAnalysisDetailOfPostsIndexResponseTypes> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersAnalysisDetailOfPostsIndex", params],
    queryFn: () => request({ params }),
  });
};
