import { convertToRaw } from "draft-js";
import update from "immutability-helper";
import { toast } from "react-toastify";

import { rawDraftContentStateToDraftJsContent } from "~/utils";

import {
  useArray,
  useApisManagersSkillEvaluationStandardSummaryCategoriesSkillEvaluationStandardSummariesDuplicatesCreate,
  useApisManagerSkillEvaluationStandardSummaryCategoriesSkillEvaluationStandardSummariesCreate,
  useApisManagerSkillEvaluationStandardSummaryCategoriesSkillEvaluationStandardSummariesDestroy,
  useApisManagerSkillEvaluationStandardSummaryCategoriesDisplayOrdersUpdate,
  useApisManagerSkillEvaluationStandardSummaryCategoriesSkillEvaluationStandardSummariesUpdate,
} from "~/hooks";

import {
  EditorStateType,
  SkillEvaluationStandardSummaryWithStandardsType,
  SkillEvaluationStandardType,
} from "~/domains";

export type FormSkillEvaluationStandardType = Pick<
  SkillEvaluationStandardType,
  "id" | "skillEvaluationStandardSummaryId" | "point" | "title" | "description"
>;

export type FormSkillEvaluationStandardSummaryType = Pick<
  SkillEvaluationStandardSummaryWithStandardsType,
  | "id"
  | "skillEvaluationStandardSummaryCategoryId"
  | "title"
  | "content"
  | "displayOrder"
> & {
  contentEditorState?: EditorStateType;
  isNew?: boolean;
  readOnly?: boolean;
  skillEvaluationStandards: FormSkillEvaluationStandardType[];
};

type OnChangeStringPropsType = {
  skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType;
  newValue: string;
};

type OnChangeDescriptionPropsType = {
  skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType;
  newValue: EditorStateType;
};

type OnChangeSkillStringPropsType = {
  skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType;
  skillEvaluationStandard: FormSkillEvaluationStandardType;
  newValue: string;
};

type OnChangeSkillNumberPropsType = {
  skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType;
  skillEvaluationStandard: FormSkillEvaluationStandardType;
  newValue: number;
};

type NewSkillStringPropsType = {
  skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType;
  newSkillEvaluationStandard: FormSkillEvaluationStandardType;
};

type RemoveSkillPropsType = {
  skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType;
  skillEvaluationStandard: FormSkillEvaluationStandardType;
};

type AddSkillPropsType = {
  skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType;
};

type ReturnType = {
  isSubmitting: boolean;
  skillEvaluationStandardSummaries: FormSkillEvaluationStandardSummaryType[];
  handleDuplicate: (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => void;
  onChangeSetNotReadOnly: (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => void;
  onChangeSetReadOnly: (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => void;
  onChangeDescription: ({
    skillEvaluationStandardSummary,
    newValue,
  }: OnChangeDescriptionPropsType) => void;
  onChangeTitle: ({
    skillEvaluationStandardSummary,
    newValue,
  }: OnChangeStringPropsType) => void;
  onChangeSkillTitle: ({
    skillEvaluationStandardSummary,
    skillEvaluationStandard,
    newValue,
  }: OnChangeSkillStringPropsType) => void;
  onChangeSkillDescription: ({
    skillEvaluationStandardSummary,
    skillEvaluationStandard,
    newValue,
  }: OnChangeSkillStringPropsType) => void;
  onChangeSkillPoint: ({
    skillEvaluationStandardSummary,
    skillEvaluationStandard,
    newValue,
  }: OnChangeSkillNumberPropsType) => void;
  removeSkillEvaluationStandard: ({
    skillEvaluationStandardSummary,
    skillEvaluationStandard,
  }: RemoveSkillPropsType) => void;
  addSkillEvaluationStandard: ({
    skillEvaluationStandardSummary,
  }: AddSkillPropsType) => void;
  canAddSkillEvaluationStandard: (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => boolean;
  addSkillEvaluationStandardSummary: () => void;
  removeSkillEvaluationStandardSummary: (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => void;
  saveSkillEvaluationStandardSummary: (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => void;
  changeDisplayOrder: (beforeIndex: number, index: number) => void;
};

type PropsType = {
  skillEvaluationStandardSummaryCategoryId: string;
  skillEvaluationStandardSummaries: FormSkillEvaluationStandardSummaryType[];
};

export function useSkillEvaluationStandardSummaryForms({
  skillEvaluationStandardSummaryCategoryId,
  skillEvaluationStandardSummaries,
}: PropsType): ReturnType {
  const { mutate: postSkillEvaluationStandardSummary, isLoading: isCreating } =
    useApisManagerSkillEvaluationStandardSummaryCategoriesSkillEvaluationStandardSummariesCreate();
  const { mutate: putSkillEvaluationStandardSummary, isLoading: isUpdating } =
    useApisManagerSkillEvaluationStandardSummaryCategoriesSkillEvaluationStandardSummariesUpdate();
  const { mutate: deleteSkillEvaluationStandardSummary } =
    useApisManagerSkillEvaluationStandardSummaryCategoriesSkillEvaluationStandardSummariesDestroy();
  const { mutate: putSkillEvaluationStandardSummaryDisplayOrder } =
    useApisManagerSkillEvaluationStandardSummaryCategoriesDisplayOrdersUpdate();
  const {
    mutate: postSkillEvaluationStandardSummaryDuplicate,
    isLoading: isDuplicating,
  } =
    useApisManagersSkillEvaluationStandardSummaryCategoriesSkillEvaluationStandardSummariesDuplicatesCreate();

  const randomNumber = () => {
    return Math.floor(Math.random() * 10000000).toString();
  };

  const defaultItemFormat = (id: string, displayOrder: number) => {
    return {
      id: id,
      skillEvaluationStandardSummaryCategoryId:
        skillEvaluationStandardSummaryCategoryId,
      title: "",
      content: convertToRaw(
        rawDraftContentStateToDraftJsContent().getCurrentContent(),
      ),
      contentEditorState: rawDraftContentStateToDraftJsContent(),
      isNew: true,
      displayOrder: displayOrder,
      readOnly: false,
      skillEvaluationStandards: [
        defaultSkillEvaluationStandard(randomNumber(), id),
      ],
    };
  };

  const defaultSkillEvaluationStandard = (
    id: string,
    skillEvaluationStandardSummaryId: string,
  ) => {
    return {
      id: id,
      skillEvaluationStandardSummaryId: skillEvaluationStandardSummaryId,
      point: 0,
      title: "",
      description: "",
    };
  };

  const { items, findAndReplace, pushItem, findAndRemove, setItems } =
    useArray<FormSkillEvaluationStandardSummaryType>(
      skillEvaluationStandardSummaries.length
        ? skillEvaluationStandardSummaries.map((item) => ({
            ...item,
            contentEditorState: rawDraftContentStateToDraftJsContent(
              item.content,
            ),
            readOnly: true,
            isNew: false,
          }))
        : [defaultItemFormat("1", 1)],
    );

  const addItem = () => {
    const order = Math.max(...items.map((item) => item.displayOrder)) + 1;
    pushItem(defaultItemFormat(order.toString(), order));
  };

  const changeDisplayOrder = (beforeIndex: number, newIndex: number) => {
    const targetSkillEvaluationStandardSummary = items[beforeIndex];
    if (!targetSkillEvaluationStandardSummary) return;
    const newItems = update(items, {
      $splice: [
        [beforeIndex, 1],
        [newIndex, 0, targetSkillEvaluationStandardSummary],
      ],
    });

    putSkillEvaluationStandardSummaryDisplayOrder(
      {
        skillEvaluationStandardSummaryCategoryId:
          skillEvaluationStandardSummaryCategoryId,
        body: generateOrderParams(newItems),
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setItems(newItems);
        },
      },
    );
  };

  const generateOrderParams = (
    newItems: FormSkillEvaluationStandardSummaryType[],
  ) => {
    return newItems
      .filter((item) => !item.isNew)
      .map((item, index) => ({
        id: item.id.toString(),
        displayOrder: index + 1,
      }));
  };

  const removeSkillEvaluationStandardSummary = (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;
    skillEvaluationStandardSummary.isNew
      ? findAndRemove((item) => item.id === skillEvaluationStandardSummary.id)
      : deleteSkillEvaluationStandardSummary(
          {
            skillEvaluationStandardSummaryCategoryId:
              skillEvaluationStandardSummaryCategoryId,
            id: skillEvaluationStandardSummary.id,
          },
          {
            onSuccess: (data) => {
              findAndRemove(
                (item) => item.id === skillEvaluationStandardSummary.id,
              );
              toast(data.message);
            },
          },
        );
  };

  const handleDuplicate = (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => {
    const isConfirm = confirm("本当に複製しますか？");
    if (!isConfirm) return;
    postSkillEvaluationStandardSummaryDuplicate(
      {
        skillEvaluationStandardSummaryCategoryId:
          skillEvaluationStandardSummaryCategoryId,
        skillEvaluationStandardSummaryId: skillEvaluationStandardSummary.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          pushItem({
            ...data.skillEvaluationStandardSummary,
            contentEditorState: rawDraftContentStateToDraftJsContent(
              data.skillEvaluationStandardSummary.content,
            ),
            readOnly: true,
            isNew: false,
          });
        },
      },
    );
  };

  const saveSkillEvaluationStandardSummary = (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => {
    const body = generateParams(skillEvaluationStandardSummary);
    if (body.skillEvaluationStandards.length <= 0) {
      toast.error("評価基準を1つ以上入力してください。");
      return;
    }

    skillEvaluationStandardSummary.isNew
      ? postSkillEvaluationStandardSummary(
          {
            skillEvaluationStandardSummaryCategoryId:
              skillEvaluationStandardSummary.skillEvaluationStandardSummaryCategoryId,
            body,
          },
          {
            onSuccess: (data) => {
              toast(data.message);
              const newObject = {
                ...skillEvaluationStandardSummary,
                id: data.skillEvaluationStandardSummary.id,
                isNew: false,
                readOnly: true,
              };

              findAndReplace(
                newObject,
                (item) => item.id === skillEvaluationStandardSummary.id,
              );
            },
          },
        )
      : putSkillEvaluationStandardSummary(
          {
            skillEvaluationStandardSummaryCategoryId:
              skillEvaluationStandardSummaryCategoryId,
            id: skillEvaluationStandardSummary.id,
            body,
          },
          {
            onSuccess: (data) => {
              toast(data.message);
              const newObject = {
                ...skillEvaluationStandardSummary,
                id: data.skillEvaluationStandardSummary.id,
                isNew: false,
                readOnly: true,
              };
              findAndReplace(
                newObject,
                (item) => item.id === skillEvaluationStandardSummary.id,
              );
            },
          },
        );
  };

  const generateParams = (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => {
    const { title, content, displayOrder, skillEvaluationStandards } =
      skillEvaluationStandardSummary;
    return {
      title,
      content,
      displayOrder,
      skillEvaluationStandards: skillEvaluationStandards.map((standard) => ({
        title: standard.title,
        description: standard.description,
        point: standard.point,
      })),
    };
  };

  const onChangeTitle = ({
    skillEvaluationStandardSummary,
    newValue,
  }: OnChangeStringPropsType) => {
    const newObject = { ...skillEvaluationStandardSummary, title: newValue };

    findAndReplace(
      newObject,
      (item) => item.id === skillEvaluationStandardSummary.id,
    );
  };

  const onChangeSetNotReadOnly = (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => {
    const newObject = { ...skillEvaluationStandardSummary, readOnly: false };

    findAndReplace(
      newObject,
      (item) => item.id === skillEvaluationStandardSummary.id,
    );
  };

  const onChangeSetReadOnly = (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => {
    const newObject = { ...skillEvaluationStandardSummary, readOnly: true };

    findAndReplace(
      newObject,
      (item) => item.id === skillEvaluationStandardSummary.id,
    );
  };

  const onChangeDescription = ({
    skillEvaluationStandardSummary,
    newValue,
  }: OnChangeDescriptionPropsType) => {
    const newObject = {
      ...skillEvaluationStandardSummary,
      content: convertToRaw(newValue.getCurrentContent()),
      contentEditorState: newValue,
    };

    findAndReplace(
      newObject,
      (item) => item.id === skillEvaluationStandardSummary.id,
    );
  };

  const onChangeSkillTitle = ({
    skillEvaluationStandardSummary,
    skillEvaluationStandard,
    newValue,
  }: OnChangeSkillStringPropsType) => {
    const newSkillEvaluationStandard = {
      ...skillEvaluationStandard,
      title: newValue,
    };
    const newSkillEvaluationStandards = newSkillEvaluationStandardValues({
      skillEvaluationStandardSummary,
      newSkillEvaluationStandard,
    });

    findAndReplace(
      newSkillEvaluationStandards,
      (item) => item.id === skillEvaluationStandardSummary.id,
    );
  };

  const onChangeSkillDescription = ({
    skillEvaluationStandardSummary,
    skillEvaluationStandard,
    newValue,
  }: OnChangeSkillStringPropsType) => {
    const newSkillEvaluationStandard = {
      ...skillEvaluationStandard,
      description: newValue,
    };
    const newSkillEvaluationStandards = newSkillEvaluationStandardValues({
      skillEvaluationStandardSummary,
      newSkillEvaluationStandard,
    });

    findAndReplace(
      newSkillEvaluationStandards,
      (item) => item.id === skillEvaluationStandardSummary.id,
    );
  };

  const onChangeSkillPoint = ({
    skillEvaluationStandardSummary,
    skillEvaluationStandard,
    newValue,
  }: OnChangeSkillNumberPropsType) => {
    const newSkillEvaluationStandard = {
      ...skillEvaluationStandard,
      point: newValue,
    };
    const newSkillEvaluationStandards = newSkillEvaluationStandardValues({
      skillEvaluationStandardSummary,
      newSkillEvaluationStandard,
    });

    findAndReplace(
      newSkillEvaluationStandards,
      (item) => item.id === skillEvaluationStandardSummary.id,
    );
  };

  const newSkillEvaluationStandardValues = ({
    skillEvaluationStandardSummary,
    newSkillEvaluationStandard,
  }: NewSkillStringPropsType) => {
    const copy = [...skillEvaluationStandardSummary.skillEvaluationStandards];
    const index = copy.findIndex(
      (item) => item.id === newSkillEvaluationStandard.id,
    );

    if (index <= -1) return skillEvaluationStandardSummary;

    copy[index] = newSkillEvaluationStandard;

    return {
      ...skillEvaluationStandardSummary,
      skillEvaluationStandards: copy,
    };
  };

  const removeSkillEvaluationStandard = ({
    skillEvaluationStandardSummary,
    skillEvaluationStandard,
  }: RemoveSkillPropsType) => {
    if (skillEvaluationStandardSummary.skillEvaluationStandards.length <= 1) {
      toast.error("最低1つは必要なため、削除できません。");
      return;
    }

    const copy = [...skillEvaluationStandardSummary.skillEvaluationStandards];
    const index = copy.findIndex(
      (item) => item.id === skillEvaluationStandard.id,
    );

    if (index <= -1) return;
    copy.splice(index, 1);
    const newObject = {
      ...skillEvaluationStandardSummary,
      skillEvaluationStandards: copy,
    };
    findAndReplace(
      newObject,
      (item) => item.id === skillEvaluationStandardSummary.id,
    );
  };

  const canAddSkillEvaluationStandard = (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => {
    return skillEvaluationStandardSummary.skillEvaluationStandards.length < 5;
  };

  const addSkillEvaluationStandard = ({
    skillEvaluationStandardSummary,
  }: AddSkillPropsType) => {
    if (!canAddSkillEvaluationStandard(skillEvaluationStandardSummary)) return;

    const newSkillStandard = defaultSkillEvaluationStandard(
      randomNumber(),
      skillEvaluationStandardSummary.id,
    );
    const copy = [
      ...skillEvaluationStandardSummary.skillEvaluationStandards,
      newSkillStandard,
    ];
    const newObject = {
      ...skillEvaluationStandardSummary,
      skillEvaluationStandards: copy,
    };
    findAndReplace(
      newObject,
      (item) => item.id === skillEvaluationStandardSummary.id,
    );
  };

  return {
    isSubmitting: isCreating || isUpdating || isDuplicating,
    skillEvaluationStandardSummaries: items,
    onChangeSetNotReadOnly,
    onChangeSetReadOnly,
    onChangeTitle,
    onChangeDescription,
    onChangeSkillDescription,
    onChangeSkillTitle,
    onChangeSkillPoint,
    handleDuplicate,
    addSkillEvaluationStandardSummary: addItem,
    removeSkillEvaluationStandardSummary,
    saveSkillEvaluationStandardSummary,
    changeDisplayOrder,
    removeSkillEvaluationStandard,
    addSkillEvaluationStandard,
    canAddSkillEvaluationStandard,
  };
}
