import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersKaizenIdeasNewType } from "~/domains";

const request = async (): Promise<ApiMembersKaizenIdeasNewType> => {
  const response = await new ApiClient().get<ApiMembersKaizenIdeasNewType>(
    "/api/members/kaizen_ideas/new",
  );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersKaizenIdeasNew = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiMembersKaizenIdeasNewType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersKaizenIdeasNew"],
    queryFn: () => request(),
  });
};
