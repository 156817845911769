import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiAnalyticsPlansSalesConceptCategoriesIndexResponseType,
  ApiAnalyticsPlansSalesConceptCategoriesIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiAnalyticsPlansSalesConceptCategoriesIndexRequestType;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApiAnalyticsPlansSalesConceptCategoriesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiAnalyticsPlansSalesConceptCategoriesIndexResponseType>(
      "/api/analytics_plans/sales_concept_categories",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiAnalyticsPlansSalesConceptCategoriesIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisAnalyticsPlansSalesConceptCategoriesIndex = ({
  params,
  config = {},
}: PropsType = {}): UseQueryResult<ApiAnalyticsPlansSalesConceptCategoriesIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisAnalyticsPlansSalesConceptCategoriesIndex", params],
    queryFn: () => request({ params }),
  });
};
