import { useState, useCallback, useEffect } from "react";

import {
  useApisMembersBusinessSectionRelationEmployeesIndex,
  useArray,
} from "~/hooks";

import {
  BusinessDivisionType,
  BusinessSectionType,
  ParentType,
  ChildType,
  EmployeeRoleType,
} from "~/domains";

type PropsType = {
  selectableDivisions?: BusinessDivisionType[];
  defaultDivisions?: BusinessDivisionType[];
  selectableSections?: BusinessSectionType[];
  defaultSections?: BusinessSectionType[];
  isSelectEmployee?: boolean;
  selectableEmployeeRoles?: EmployeeRoleType[];
};

type ReturnType = {
  selectableDivisions: BusinessDivisionType[];
  selectedDivisions: BusinessDivisionType[];
  onDivisionChange: (newValue: ParentType[]) => void;
  selectableSections: BusinessSectionType[];
  selectedSections: BusinessSectionType[];
  onSectionChange: (newValue: ChildType[]) => void;
  optionSelectableSections: ChildType[];
  optionSelectedSections: ChildType[];
  setSelectedDivisions: (newValue: BusinessDivisionType[]) => void;
  setSelectedSections: (newValue: BusinessSectionType[]) => void;
  selectableEmployees: ParentType[];
  selectedEmployees: ParentType[];
  selectableArchivedEmployees: ParentType[];
  selectedArchivedEmployees: ParentType[];
  selectedEmployee: ParentType | undefined;
  onEmployeesChange: (newValue: ParentType[]) => void;
  onArchivedEmployeesChange: (newValue: ParentType[]) => void;
  onEmployeeChange: (newValue: ParentType | undefined) => void;
};

export const useMultiDivisionMultiSectionDropdown = ({
  selectableDivisions = [],
  defaultDivisions = [],
  selectableSections = [],
  defaultSections = [],
  isSelectEmployee = false,
  selectableEmployeeRoles = [],
}: PropsType): ReturnType => {
  const [selectedDivisions, setSelectedDivisions] = useState(defaultDivisions);
  const [selectedSections, setSelectedSections] = useState(defaultSections);
  const { items: selectableEmployees, setItems: setSelectableEmployees } =
    useArray<ParentType>([]);
  const { items: selectedEmployees, setItems: setSelectedEmployees } =
    useArray<ParentType>([]);

  const {
    items: selectableArchivedEmployees,
    setItems: setSelectableArchivedEmployees,
  } = useArray<ParentType>([]);
  const {
    items: selectedArchivedEmployees,
    setItems: setSelectedArchivedEmployees,
  } = useArray<ParentType>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<ParentType>();

  useApisMembersBusinessSectionRelationEmployeesIndex({
    params: {
      businessSectionIds:
        selectedSections.length > 0
          ? selectedSections.map((section) => section.id)
          : selectableSections.map((section) => section.id),
      employeeRoleIds: selectableEmployeeRoles.map((role) => role.id),
    },
    config: {
      enabled: isSelectEmployee,
      onSuccess: (res) => {
        setSelectableEmployees(res.employees);
        setSelectableArchivedEmployees(res.archivedEmployees);

        const filteredEmployees = selectedEmployees.filter((selectedEmployee) =>
          res.employees.some(
            (resEmployee) => resEmployee.id === selectedEmployee.id,
          ),
        );
        setSelectedEmployees(filteredEmployees);

        const filteredArchivedEmployees = selectedArchivedEmployees.filter(
          (selectedEmployee) =>
            res.archivedEmployees.some(
              (resEmployee) => resEmployee.id === selectedEmployee.id,
            ),
        );
        setSelectedArchivedEmployees(filteredArchivedEmployees);

        const filetedEmployee = res.employees.some(
          (resEmployee) => resEmployee.id === selectedEmployee?.id,
        );
        !filetedEmployee && setSelectedEmployee(undefined);
      },
    },
  });

  const convertChildToOption = useCallback(
    (sections: BusinessSectionType[]) => {
      return sections.map((section) => ({
        id: section.id,
        name: section.name,
        parentId: section.businessDivisionId,
      }));
    },
    [],
  );

  const convertToSections = useCallback((newValues: ChildType[]) => {
    return newValues.map((value) => ({
      id: value.id,
      name: value.name,
      businessDivisionId: value.parentId,
    }));
  }, []);

  const onBusinessDivisionChange = (newDivisions: BusinessDivisionType[]) => {
    const divisionIds = newDivisions.map((division) => division.id);
    const filteredSections = selectedSections.filter((section) =>
      divisionIds.includes(section.businessDivisionId),
    );
    setSelectedDivisions(newDivisions);
    setSelectedSections(filteredSections);
  };

  const onBusinessSectionChange = (newSections: ChildType[]) => {
    setSelectedSections(convertToSections(newSections));
  };

  const optionSelectableSections = convertChildToOption(selectableSections);
  const optionSelectedSections = convertChildToOption(selectedSections);

  useEffect(() => {
    defaultDivisions.length && setSelectedDivisions(defaultDivisions);
  }, [defaultDivisions]);

  useEffect(() => {
    defaultSections.length && setSelectedSections(defaultSections);
  }, [defaultSections]);

  return {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange: onBusinessDivisionChange,
    selectableSections,
    selectedSections,
    onSectionChange: onBusinessSectionChange,
    optionSelectableSections,
    optionSelectedSections,
    setSelectedDivisions,
    setSelectedSections,
    selectableEmployees,
    selectedEmployees,
    onEmployeesChange: setSelectedEmployees,
    selectedEmployee,
    onEmployeeChange: setSelectedEmployee,
    selectableArchivedEmployees,
    selectedArchivedEmployees,
    onArchivedEmployeesChange: setSelectedArchivedEmployees,
  };
};
