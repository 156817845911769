import React, { FC, MouseEvent } from "react";

import { currentFormatTimestamp } from "~/libs";

import { downloadBlob, arrayToCsv } from "~/utils";

import { Button } from "~/components/atoms";
import { DoughnutChartBox, RangeDatePicker } from "~/components/molecules";
import { DivisionAndSectionAndEmployeeDropdownField } from "~/components/organisms";

import {
  BusinessDivisionType,
  ParentType,
  ApiAnalyticsPlansFeedbackAnalysisIndexResponseType,
  RangeDatePropsType,
  ChildType,
} from "~/domains";

type PropsType = {
  formattedStartDate: string | null;
  formattedEndDate: string | null;
  selectedDivisions: BusinessDivisionType[];
  optionSelectedSections: ChildType[];
  data?: ApiAnalyticsPlansFeedbackAnalysisIndexResponseType;
  className?: string;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onDivisionChange: (newValue: ParentType[]) => void;
  onSectionChange: (newValue: ChildType[]) => void;
  selectableDivisions: BusinessDivisionType[];
  optionSelectableSections: ChildType[];
  selectedDropdownSections: ChildType[];
  selectedEmployee?: ParentType;
  onEmployeeChange: (newValue: ParentType | undefined) => void;
  selectableEmployees: ParentType[];
};

const today = new Date();

export const AnalyticsFeedbackAnalysisTemplate: FC<PropsType> = ({
  formattedStartDate,
  formattedEndDate,
  selectedDivisions,
  optionSelectedSections,
  isLoading,
  className = "",
  startDate,
  endDate,
  onChangeDateRange,
  onDivisionChange,
  onSectionChange,
  selectableDivisions,
  optionSelectableSections,
  data,
  selectedEmployee,
  onEmployeeChange,
  selectableEmployees,
}: PropsType) => {
  const handleExportCsv = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    downloadBlob(
      arrayToCsv(generateCsvData()),
      `${currentFormatTimestamp}-feedback_analysis.csv`,
    );
  };

  const generateCsvData = () => {
    return [
      ["期間", `${formattedStartDate} ~ ${formattedEndDate}`],
      [
        "部署",
        selectedDivisions.map((division) => division.name).join(", ") ||
          "すべての部署",
      ],
      [
        "課",
        optionSelectedSections?.length
          ? optionSelectedSections.map((section) => section.name).join(", ")
          : selectedDivisions?.length
            ? "選択された部署に属するすべての課"
            : "すべての課",
      ],
      ["ユーザー", selectedEmployee?.name || "すべてのユーザー"],
      [
        "インサイトへのいいね率",
        `${data?.insightPostLikesDoughnut.numerator}/${data?.insightPostLikesDoughnut.denominator}`,
      ],
      [
        "インサイトへのいいね率(%)",
        `${data?.insightPostLikesDoughnut.rate || 0}%`,
      ],
      [
        "インサイトへのリプライ率",
        `${data?.insightPostCommentsDoughnut.numerator}/${data?.insightPostCommentsDoughnut.denominator}`,
      ],
      [
        "インサイトへのリプライ率(%)",
        `${data?.insightPostCommentsDoughnut.rate || 0}%`,
      ],
      [
        "チャレンジへのいいね率",
        `${data?.focusChallengeEmployeeLikesDoughnut.numerator}/${data?.focusChallengeEmployeeLikesDoughnut.denominator}`,
      ],
      [
        "チャレンジへのいいね率(%)",
        `${data?.focusChallengeEmployeeLikesDoughnut.rate || 0}%`,
      ],
      [
        "チャレンジへのリプライ率",
        `${data?.focusChallengeEmployeeRepliesDoughnut.numerator}/${data?.focusChallengeEmployeeRepliesDoughnut.denominator}`,
      ],
      [
        "チャレンジへのリプライ率(%)",
        `${data?.focusChallengeEmployeeRepliesDoughnut.rate || 0}%`,
      ],
      [
        "改善・相談へのいいね率",
        `${data?.kaizenIdeaLikesDoughnut.numerator}/${data?.kaizenIdeaLikesDoughnut.denominator}`,
      ],
      [
        "改善・相談へのいいね率(%)",
        `${data?.kaizenIdeaLikesDoughnut.rate || 0}%`,
      ],
      [
        "改善・相談へのリプライ率",
        `${data?.kaizenIdeaRepliesDoughnut.numerator}/${data?.kaizenIdeaRepliesDoughnut.denominator}`,
      ],
      [
        "改善・相談へのリプライ率(%)",
        `${data?.kaizenIdeaRepliesDoughnut.rate || 0}%`,
      ],
    ];
  };

  return (
    <div className={className}>
      <div className="grid miniTablet:grid-cols-2 gap-4">
        <DivisionAndSectionAndEmployeeDropdownField
          selectableDropdownDivisions={selectableDivisions}
          selectedDropdownDivisions={selectedDivisions}
          onDivisionChange={onDivisionChange}
          selectableDropdownSections={optionSelectableSections}
          selectedDropdownSections={optionSelectedSections}
          onSectionChange={onSectionChange}
          selectableDropdownEmployees={selectableEmployees}
          selectedDropdownEmployee={selectedEmployee}
          onEmployeeChange={onEmployeeChange}
        />
        <RangeDatePicker
          labelText="期間"
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeDateRange}
          maxDate={today}
        />
      </div>
      <div className="mt-6 text-right">
        <Button
          outline
          text="CSVエクスポート"
          onClick={handleExportCsv}
          color="primary"
          readonly={isLoading}
        />
      </div>
      <div className="grid miniTablet:grid-cols-3 gap-6 mt-6">
        <DoughnutChartBox
          title="インサイトへのいいね率"
          doughnut={data?.insightPostLikesDoughnut}
          isLoading={isLoading}
        />
        <DoughnutChartBox
          title="インサイトへのリプライ率"
          doughnut={data?.insightPostCommentsDoughnut}
          isLoading={isLoading}
        />
        <DoughnutChartBox
          title="チャレンジへのいいね率"
          doughnut={data?.focusChallengeEmployeeLikesDoughnut}
          isLoading={isLoading}
        />
        <DoughnutChartBox
          title="チャレンジへのリプライ率"
          doughnut={data?.focusChallengeEmployeeRepliesDoughnut}
          isLoading={isLoading}
        />
        <DoughnutChartBox
          title="改善・相談へのいいね率"
          doughnut={data?.kaizenIdeaLikesDoughnut}
          isLoading={isLoading}
        />
        <DoughnutChartBox
          title="改善・相談へのリプライ率"
          doughnut={data?.kaizenIdeaRepliesDoughnut}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
