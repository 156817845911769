import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMemberInsightPostsNewResponseType } from "~/domains";

const request = async (): Promise<ApiMemberInsightPostsNewResponseType> => {
  const response =
    await new ApiClient().get<ApiMemberInsightPostsNewResponseType>(
      "/api/members/insight_posts/new",
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersInsightPostsNew = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiMemberInsightPostsNewResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersInsightPostsNew"],
    queryFn: request,
  });
};
