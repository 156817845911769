import React, { FC, ChangeEvent } from "react";

type PropsType = {
  name: string;
  value?: string | number;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const RadioButtonField: FC<PropsType> = ({
  name,
  value,
  checked = false,
  required = false,
  disabled = false,
  className = "",
  onChange,
}: PropsType) => {
  return (
    <input
      type="radio"
      name={name}
      id={name}
      value={value}
      checked={checked}
      required={required}
      disabled={disabled}
      className={`cursor-pointer ${className}`}
      onChange={onChange}
    />
  );
};
