import { ParentType, OptionType } from "~/domains";

export function convertToDropdownOption(record: ParentType): OptionType {
  return {
    value: record.id,
    label: record.name,
  };
}

export function convertToValue(record: OptionType): ParentType {
  return {
    id: record.value,
    name: record.label,
  };
}
