import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersBusinessDivisionsArchivedsDestroy,
  useSearchManagersArchivedBusinessDivisions,
} from "~/hooks";

import {
  FormSubmitButton,
  Form,
  Button,
  GridHeaderCells,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  GridRow,
  OpenedMenuInGrid,
  LabelWithTextField,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";

import { BusinessDivisionType } from "~/domains";

export const ManagersArchivedBusinessDivisionsIndex: FC = () => {
  const {
    businessDivisions,
    hasNextPage,
    isLoading,
    keyword,
    fetchNextPage,
    findAndRemove,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  } = useSearchManagersArchivedBusinessDivisions();

  const { mutate: archivedDestroyRequest } =
    useApisManagersBusinessDivisionsArchivedsDestroy();

  const handleDelete = (targetBusinessDivision: BusinessDivisionType) => {
    const isConfirm = confirm("本当に有効化しますか？");
    if (!isConfirm) return;
    archivedDestroyRequest(
      {
        businessDivisionId: targetBusinessDivision.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((item) => item.id === targetBusinessDivision.id);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "部署設定", href: "/managers/business_divisions" },
    { label: "無効部署", href: "/managers/archived_business_divisions" },
  ];

  return (
    <>
      <EmployeeLayout
        withRightSidebar={false}
        breadCrumbItems={breadCrumbItems}
      >
        <EmployeeLayoutMainContent withRightSidebar={false}>
          <Form onSubmit={onSearchSubmit} className="space-y-4">
            <>
              <div className="grid miniTablet:grid-cols-2 gap-4">
                <LabelWithTextField
                  labelText="フリーワード"
                  type="text"
                  name="keyword"
                  placeholder="部署名検索"
                  value={keyword}
                  onChange={onChangeKeyword}
                />
              </div>
              <div className="flex items-center justify-end space-x-4">
                <Button
                  color="gray"
                  outline
                  className="w-full miniTablet:w-auto"
                  text="リセット"
                  onClick={onConditionReset}
                />
                <FormSubmitButton
                  value="検索する"
                  color="primary"
                  className="w-full miniTablet:w-auto"
                />
              </div>
            </>
          </Form>
          <InfiniteScrollWithMoreButton
            itemsLength={businessDivisions.length}
            nextFetchFunction={fetchNextPage}
            isLoading={isLoading}
            hasMore={hasNextPage}
            className="mt-12"
          >
            <div className="grid grid-cols-1">
              <GridHeaderCells texts={["部署"]} />
              <>
                {businessDivisions.map((businessDivision) => (
                  <GridRow
                    key={businessDivision.id}
                    lists={[businessDivision.name]}
                    openedMenu={
                      <OpenedMenuInGrid
                        cols={1}
                        buttons={[
                          {
                            text: "有効化",
                            onClick: () => handleDelete(businessDivision),
                          },
                        ]}
                      />
                    }
                  />
                ))}
              </>
            </div>
          </InfiniteScrollWithMoreButton>
        </EmployeeLayoutMainContent>
      </EmployeeLayout>
    </>
  );
};
