import React, { MouseEvent, useState, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { toDateString } from "~/libs";

import {
  useApisManagersMeetingNotesCreate,
  useApisManagersMeetingNotesUpdate,
  useEditorState,
  useInput,
  useMultiDivisionMultiSectionDropdown,
  useMultipleDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { Button } from "~/components/atoms";
import {
  DatePicker,
  LabelWithMultipleDropdownField,
  LabelWithTextArea,
  LabelWithTextField,
  PostedFilePreviews,
  TemplateSelectButtonWithModal,
} from "~/components/molecules";
import {
  MultiParentsWithMultiChildrenDropdownField,
  TextEditor,
} from "~/components/organisms";

import {
  AvatarAndNameEmployeeType,
  MeetingNoteTemplateType,
  MeetingNoteType,
  PreviewWithFileType,
} from "~/domains";

type PropsType = {
  meetingNote?: MeetingNoteType;
  className?: string;
  meetingNoteTemplates?: MeetingNoteTemplateType[];
  selectableEmployees?: AvatarAndNameEmployeeType[];
};

export const ManagersMeetingNoteForm: FC<PropsType> = ({
  meetingNote,
  className = "",
  meetingNoteTemplates = [],
  selectableEmployees = [],
}: PropsType) => {
  const navigate = useNavigate();
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [postDate, setPostDate] = useState(
    meetingNote?.postDate ? new Date(meetingNote.postDate) : new Date(),
  );

  const [editorState, setEditorState] = useEditorState(meetingNote?.content);
  const [title] = useInput(meetingNote?.title || "");
  const [memo] = useInput(meetingNote?.memo || "");
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const [selectedTemplate, setSelectedTemplate] =
    useState<Pick<MeetingNoteTemplateType, "id" | "title" | "content">>();

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    defaultDivisions: meetingNote?.businessDivisions,
    defaultSections: meetingNote?.businessSections,
  });

  const [
    {
      dropdownValue: selectedEmployees,
      dropdownSelectableValue: dropdownSelectableEmployees,
      onChange: onChangeSelectableEmployees,
    },
  ] = useMultipleDropdown({
    initialValue: meetingNote?.participationEmployees,
    selectableValue: selectableEmployees,
  });

  const { mutate: postRequest, isLoading: isCreating } =
    useApisManagersMeetingNotesCreate();
  const { mutate: updateRequest, isLoading: isUpdating } =
    useApisManagersMeetingNotesUpdate();

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    meetingNote ? handleUpdate() : handleCreate();
  };

  const handleUpdate = () => {
    if (!meetingNote) return;
    updateRequest(
      {
        id: meetingNote.id,
        body: generateParams(),
      },
      {
        onSuccess: (data) => {
          navigate("/managers/meeting_notes");
          toast(data.message);
        },
      },
    );
  };

  const handleCreate = () => {
    postRequest(
      {
        body: generateParams(),
      },
      {
        onSuccess: (data) => {
          navigate("/managers/meeting_notes");
          toast(data.message);
        },
      },
    );
  };

  const generateParams = () => {
    return {
      title: title.value,
      content: editorState.jsonContent,
      postDate: toDateString(postDate),
      meetingNoteTemplateId: selectedTemplate?.id,
      memo: memo.value,
      participationEmployeeIds: selectedEmployees.map(
        (employee) => employee.value,
      ),
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      ...(files.length > 0 ? { attachFiles: files } : {}),
    };
  };

  return (
    <>
      <div className={`space-y-6 ${className}`}>
        <DatePicker
          selectDate={postDate}
          onChange={setPostDate}
          parentClassName="mt-6"
        />
        <TemplateSelectButtonWithModal
          templates={meetingNoteTemplates || []}
          setEditorState={setEditorState}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
        <MultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentLabel="部署"
          parentRequired
          parentsOnChange={onDivisionChange}
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childLabel="課"
          childrenOnChange={onSectionChange}
          childRequired
        />
        <LabelWithMultipleDropdownField
          labelText="参加者"
          name="employee"
          required
          options={dropdownSelectableEmployees}
          value={selectedEmployees}
          onChange={onChangeSelectableEmployees}
        />
        <LabelWithTextField
          required
          labelText="タイトル"
          type="text"
          name="title"
          value={title.value}
          onChange={title.onChange}
          placeholder="タイトル"
        />
        <TextEditor
          labelText="内容"
          required
          editorState={editorState.value}
          setEditorState={editorState.onChange}
          mentions={[]}
          files={files}
          onChangeFiles={setFiles}
        />
        {Boolean(meetingNote?.attachFiles?.length) && (
          <PostedFilePreviews
            files={meetingNote?.attachFiles || []}
            className="mt-4"
          />
        )}
        <LabelWithTextArea
          labelText="メモ(管理者のみ表示)"
          name="memo"
          value={memo.value}
          onChange={memo.onChange}
          placeholder="メモ(管理者のみ表示)"
          rows={8}
        />
        <Button
          text="保存"
          onClick={handleSubmit}
          color="primary"
          className="w-full"
          readonly={isCreating || isUpdating}
        />
      </div>
    </>
  );
};
