import React, { FC } from "react";

import { CatchTitleBorder } from "~/components/atoms";
import { InfiniteScroll } from "~/components/molecules";

import { PrivatePersonalMemosDetailWithEditForm } from "./PrivatePersonalMemosDetailWithEditForm";

import { PrivatePersonalMemoType } from "~/domains";

type PropsType = {
  privatePersonalMemos: PrivatePersonalMemoType[];
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isLoading: boolean;
  className?: string;
  updateIndexPost: (newItem: PrivatePersonalMemoType, index: number) => void;
  deleteIndexPost: (index: number) => void;
};

export const PrivatePersonalMemosDetailWithEditForms: FC<PropsType> = ({
  privatePersonalMemos,
  fetchNextPage,
  hasNextPage,
  isLoading,
  deleteIndexPost,
  updateIndexPost,
  className,
}: PropsType) => {
  return (
    <div className={className}>
      <CatchTitleBorder text={"キャンパス一覧"} />
      <InfiniteScroll
        itemsLength={privatePersonalMemos.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        isLoading={isLoading}
        className={"mt-9"}
        scrollClassName="space-y-6"
      >
        {privatePersonalMemos.map((privatePersonalMemo, index) => (
          <PrivatePersonalMemosDetailWithEditForm
            key={privatePersonalMemo.id}
            privatePersonalMemo={privatePersonalMemo}
            deleteIndexPost={() => deleteIndexPost(index)}
            updateIndexPost={(newItem) => updateIndexPost(newItem, index)}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
