import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import {
  RawDraftContentState,
  ApiManagersEmployeesEmployeeMemoReviewsMutateResponseType,
  PreviewWithFileType,
} from "~/domains";

type RequestDataType = {
  employeeId: string;
  body: {
    content: RawDraftContentState;
    attachFiles: PreviewWithFileType[];
  };
};

const request = ({ employeeId, body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ employeeMemoReview: body });
  return new ApiClient()
    .post<ApiManagersEmployeesEmployeeMemoReviewsMutateResponseType>(
      `/api/managers/employees/${employeeId}/employee_memo_reviews`,
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersEmployeesEmployeeMemoReviewsCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersEmployeesEmployeeMemoReviewsMutateResponseType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
