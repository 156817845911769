// NOTE: このページは使用していない可能性があります。確認後、削除してください。
import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useBoolean,
  useApisMembersFocusChallengesNew,
  useApisMembersFocusChallengeEmployeeEffortsShow,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  FocusChallengeEmployeeDetailWithEditForm,
  FocusChallengeEmployeeRepliesWithFocusChallengeEmployee,
} from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const MembersFocusChallengeEmployeeEffortsShow: FC = () => {
  const navigate = useNavigate();

  const { id = "" } = useParams<{ id: string }>();
  const { isChecked: isOpen, toggle: openReply } = useBoolean(true);
  const breadCrumbItems = [
    { label: "チャレンジ", href: "/focus_challenges" },
    { label: "詳細", href: `/focus_challenge_employee_efforts/${id}` },
  ];

  const { data: fetchNew } = useApisMembersFocusChallengesNew();
  const {
    data: fetchShow,
    refetch,
    isError,
  } = useApisMembersFocusChallengeEmployeeEffortsShow({
    id,
  });

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
        {fetchShow && (
          <FocusChallengeEmployeeDetailWithEditForm
            mentions={fetchNew?.mentionableEmployees || []}
            selectableProvidingServices={
              fetchNew?.selectableProvidingServices || []
            }
            selectableJobCategoryPhases={
              fetchNew?.selectableJobCategoryPhases || []
            }
            focusChallengeEmployee={fetchShow.focusChallengeEmployee}
            openReply={openReply}
            deleteItem={() => navigate("/focus_challenges")}
            isHighlight={
              fetchShow.focusChallengeEmployee.focusChallengeEmployeeEffort
                ?.id === id
            }
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        {isOpen && fetchShow && (
          <>
            <RightSidebarTitle title="リプライ" />
            <FocusChallengeEmployeeRepliesWithFocusChallengeEmployee
              className="mt-6 mx-4"
              mentions={fetchNew?.mentionableEmployees || []}
              focusChallengeEmployee={fetchShow.focusChallengeEmployee}
              openReply={openReply}
              resetPageFetch={refetch}
              focusChallengeReplies={fetchShow.focusChallengeEmployeeReplies}
            />
          </>
        )}
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
