import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersRoleplayingEvaluationMutateType } from "~/domains";

type RequestDataType = {
  roleplayingConditionId: string;
  body: {
    myEvaluationGoodContent: string;
    myEvaluationImproveContent: string;
    otherUserEvaluationGoodContent: string;
    otherUserEvaluationImproveContent: string;
  };
};

const request = ({ roleplayingConditionId, body }: RequestDataType) => {
  return new ApiClient()
    .post<ApiMembersRoleplayingEvaluationMutateType>(
      `/api/members/roleplaying_conditions/${roleplayingConditionId}/roleplaying_evaluations`,
      {
        roleplayingEvaluation: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersRoleplayingConditionsRoleplayingEvaluationsCreate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ApiMembersRoleplayingEvaluationMutateType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
