import React, { FC } from "react";

import {
  useApisMembersDocumentFilesBookmarksCreate,
  useApisMembersDocumentFilesBookmarksDestroy,
  useApisMembersDocumentFilesDownloadsCreate,
  useApisMembersDocumentFilesPreviewsCreate,
  useBoolean,
} from "~/hooks";

import { Thumbnail } from "~/components/molecules";

import { DocumentFileType } from "~/domains";

type PropsType = {
  documentFile: DocumentFileType;
};

export const DocumentFile: FC<PropsType> = ({ documentFile }: PropsType) => {
  const { id, name, thumbnailImage, attachFile, providingServices } =
    documentFile;
  const {
    isChecked: bookmarked,
    setTrue: setBookmarkedTrue,
    setFalse: setBookmarkedFalse,
  } = useBoolean(documentFile.bookmarked);

  const { mutate: bookmarkPostRequest } =
    useApisMembersDocumentFilesBookmarksCreate();
  const { mutate: bookmarkDeleteRequest } =
    useApisMembersDocumentFilesBookmarksDestroy();
  const { mutate: downloadPostRequest } =
    useApisMembersDocumentFilesDownloadsCreate();
  const { mutate: previewPostRequest } =
    useApisMembersDocumentFilesPreviewsCreate();

  const addBookmarkRequest = () => {
    bookmarkPostRequest(
      {
        documentFileId: id,
      },
      {
        onSuccess: () => {
          setBookmarkedTrue();
        },
      },
    );
  };

  const removeBookmarkRequest = () => {
    bookmarkDeleteRequest(
      {
        documentFileId: id,
      },
      {
        onSuccess: () => {
          setBookmarkedFalse();
        },
      },
    );
  };

  const downloadRequest = () => {
    downloadPostRequest({
      documentFileId: id,
    });
  };

  const onPreviewsRequest = () => {
    previewPostRequest({
      documentFileId: id,
    });
  };

  return (
    <Thumbnail
      thumbnailImageUrl={thumbnailImage.url}
      attachFile={attachFile}
      onPreviewsRequest={onPreviewsRequest}
      title={name}
      bookmark={{
        request: bookmarked ? removeBookmarkRequest : addBookmarkRequest,
        bookmarked: bookmarked,
      }}
      download={{
        url: attachFile.url,
        fileName: attachFile.name,
        onSuccess: downloadRequest,
      }}
      tags={providingServices.map((service) => service.name)}
    />
  );
};
