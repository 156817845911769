import React, { FC, MouseEvent } from "react";

import { InfiniteData } from "@tanstack/react-query";

import { currentFormatTimestamp } from "~/libs";

import { downloadBlob, arrayToCsv } from "~/utils";

import { Button } from "~/components/atoms";
import {
  DoughnutChartBox,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";
import { DivisionAndSectionAndEmployeeDropdownField } from "~/components/organisms";

import {
  BusinessDivisionType,
  ParentType,
  ApisAnalyticsPlansEmployeesInsightPostObjectivesIndexResponseTypes,
  ChildType,
} from "~/domains";

type PropsType = {
  selectedDivisions: BusinessDivisionType[];
  optionSelectedSections: ChildType[];
  data?: InfiniteData<ApisAnalyticsPlansEmployeesInsightPostObjectivesIndexResponseTypes>;
  className?: string;
  isLoading: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  onDivisionChange: (newValue: ParentType[]) => void;
  onSectionChange: (newValue: ChildType[]) => void;
  selectableDivisions: BusinessDivisionType[];
  optionSelectableSections: ChildType[];
  selectedDropdownSections: ChildType[];
  selectedEmployee?: ParentType;
  onEmployeeChange: (newValue: ParentType | undefined) => void;
  selectableEmployees: ParentType[];
};

export const AnalyticsInsightPostObjectivesTemplate: FC<PropsType> = ({
  selectedDivisions,
  optionSelectedSections,
  isLoading,
  hasNextPage,
  fetchNextPage,
  className = "",
  onDivisionChange,
  onSectionChange,
  selectableDivisions,
  optionSelectableSections,
  data,
  selectedEmployee,
  onEmployeeChange,
  selectableEmployees,
}: PropsType) => {
  const handleExportCsv = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    downloadBlob(
      arrayToCsv(generateCsvData()),
      `${currentFormatTimestamp}-insight_post_objectives_analysis.csv`,
    );
  };

  const calculatePerformances = data?.pages
    ?.map((page) => page?.calculatePerformances)
    .flat();

  const generateCsvData = () => {
    return [
      [
        "部署",
        selectedDivisions.map((division) => division.name).join(", ") ||
          "すべての部署",
      ],
      [
        "課",
        optionSelectedSections?.length
          ? optionSelectedSections.map((section) => section.name).join(", ")
          : selectedDivisions?.length
            ? "選択された部署に属するすべての課"
            : "すべての課",
      ],
      ["ユーザー", selectedEmployee?.name || "すべてのユーザー"],
      ["期間", "投稿数", "ポイント"],
      ...(calculatePerformances || []).map((calculatePerformance) => [
        `${calculatePerformance.startDate}~${calculatePerformance.endDate}`,
        `${calculatePerformance.performanceCount} / ${calculatePerformance.objectivePerformance}`,
        `${calculatePerformance.performancePoint} / ${calculatePerformance.objectivePerformance}`,
      ]),
    ];
  };

  return (
    <div className={className}>
      <div className="grid miniTablet:grid-cols-2 gap-4">
        <DivisionAndSectionAndEmployeeDropdownField
          selectableDropdownDivisions={selectableDivisions}
          selectedDropdownDivisions={selectedDivisions}
          onDivisionChange={onDivisionChange}
          selectableDropdownSections={optionSelectableSections}
          selectedDropdownSections={optionSelectedSections}
          onSectionChange={onSectionChange}
          selectableDropdownEmployees={selectableEmployees}
          selectedDropdownEmployee={selectedEmployee}
          onEmployeeChange={onEmployeeChange}
        />
      </div>
      <div className="mt-6 text-right">
        <Button
          outline
          text="CSVエクスポート"
          onClick={handleExportCsv}
          color="primary"
          readonly={isLoading}
        />
      </div>
      <div className="">
        <InfiniteScrollWithMoreButton
          itemsLength={calculatePerformances?.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isLoading}
          className="mt-6"
          scrollClassName="space-y-8"
        >
          {calculatePerformances?.map((calculatePerformances) => (
            <div key={`${calculatePerformances.title}`}>
              <h3 className="font-semibold text-center text-xl text-secondary-600">
                {calculatePerformances.title}
              </h3>
              <div className="mt-4 grid miniTablet:grid-cols-2 gap-6">
                <DoughnutChartBox
                  title="目標に対するインサイト数"
                  doughnut={{
                    rate: calculatePerformances.performanceCountRate,
                    numerator: calculatePerformances.performanceCount,
                    denominator: calculatePerformances.objectivePerformance,
                  }}
                  isLoading={isLoading}
                />
                <DoughnutChartBox
                  title="目標に対するポイント数"
                  doughnut={{
                    rate: calculatePerformances.performancePointRate,
                    numerator: calculatePerformances.performancePoint,
                    denominator: calculatePerformances.objectivePerformance,
                  }}
                  isLoading={isLoading}
                />
              </div>
            </div>
          ))}
        </InfiniteScrollWithMoreButton>
      </div>
    </div>
  );
};
