import React, { FC, FormEvent } from "react";

import { Form, FormSubmitButton } from "~/components/atoms";
import { TextEditor } from "~/components/organisms";

import { EditorStateType, PreviewWithFileType } from "~/domains";

type PropsType = {
  editorState: EditorStateType;
  isSubmitting: boolean;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onChangeEditorState: (editorState: EditorStateType) => void;
  files: PreviewWithFileType[];
  onChangeFiles: (files: PreviewWithFileType[]) => void;
  className?: string;
};

export const PrivatePersonalMemosForm: FC<PropsType> = ({
  editorState,
  onChangeEditorState,
  isSubmitting,
  files,
  onChangeFiles,
  handleSubmit,
  className,
}: PropsType) => {
  return (
    <Form className={`space-y-6 ${className}`} onSubmit={handleSubmit}>
      <TextEditor
        required
        editorState={editorState}
        setEditorState={onChangeEditorState}
        files={files}
        onChangeFiles={onChangeFiles}
        mentions={[]}
      />
      <FormSubmitButton
        color="primary"
        value="保存"
        isReadOnly={isSubmitting}
        className="w-full"
      />
    </Form>
  );
};
