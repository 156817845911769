import React, { FC } from "react";

import { endOfMonth, startOfMonth } from "date-fns";

import { useApisMembersRankingInsightPostsIndex, useDateRange } from "~/hooks";

import { RangeDatePicker } from "~/components/molecules";

import { RankingInsightPostList } from "./RankingInsightPostList";

type PropsType = {
  className?: string;
};

const today = new Date();

export const RankingInsightPosts: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  const [
    {
      startDate,
      formattedStartDate,
      endDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange([startOfMonth(today), endOfMonth(today)]);
  const { data, isLoading } = useApisMembersRankingInsightPostsIndex({
    params: {
      createdAtFrom: formattedStartDate || "",
      createdAtTo: formattedEndDate || "",
    },
    config: {
      enabled: !!(formattedStartDate && formattedEndDate),
    },
  });

  return (
    <div className={`${className}`}>
      <RangeDatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={onChangeDateRange}
        maxDate={today}
      />
      <RankingInsightPostList
        title="いいね数"
        isLoading={isLoading}
        insightPosts={data?.likedInsightPosts}
        className="mt-9"
      />
      <RankingInsightPostList
        title="ブックマーク数"
        isLoading={isLoading}
        insightPosts={data?.bookmarkedInsightPosts}
        className="mt-9"
      />
    </div>
  );
};
