import React, { FC } from "react";

import Select, { MultiValue, SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";

import { RequiredHiddenField } from "~/components/atoms";

import { OptionType } from "~/domains";

export type { MultiValue, SingleValue };

type PropsType = {
  name: string;
  options: OptionType[];
  value?: MultiValue<OptionType>;
  isDisabled?: boolean;
  placeholder?: string;
  className?: string;
  creatable?: boolean;
  required?: boolean;
  onCreateOption?: (newValue: string) => void;
  onChange?: (newValue: MultiValue<OptionType>) => void;
};

export const MultipleDropDownField: FC<PropsType> = ({
  name,
  options,
  value,
  isDisabled,
  placeholder = "選択してください",
  className = "",
  creatable = false,
  required = false,
  onCreateOption,
  onChange,
}: PropsType) => {
  return (
    <div className={"relative"}>
      {creatable ? (
        <CreatableSelect
          name={name}
          value={value}
          options={options}
          isDisabled={isDisabled}
          isSearchable
          isMulti
          isClearable
          placeholder={placeholder}
          onCreateOption={onCreateOption}
          className={`${className}`}
          classNames={{
            placeholder: () => "!text-secondary-400",
            option: ({ isSelected, isFocused }) =>
              isSelected
                ? "!bg-primary-600"
                : isFocused
                  ? "!bg-primary-200"
                  : "",
            control: ({ isDisabled }) =>
              isDisabled ? "!bg-secondary-300 !border-secondary-400" : "",
            singleValue: ({ isDisabled }) =>
              isDisabled ? "!text-secondary-600" : "",
            multiValueRemove: () =>
              "hover:!bg-primary-400 hover:!text-primary-600 !bg-primary-200",
            multiValueLabel: () => "!bg-primary-200",
          }}
          onChange={onChange}
          formatCreateLabel={(inputValue: string) => (
            <>{inputValue} を新規作成</>
          )}
          noOptionsMessage={() => "選択肢がありません"}
        />
      ) : (
        <Select
          name={name}
          value={value}
          options={options}
          isDisabled={isDisabled}
          isSearchable
          isMulti
          isClearable
          placeholder={placeholder}
          className={`${className}`}
          classNames={{
            placeholder: () => "!text-secondary-400",
            option: ({ isSelected, isFocused }) =>
              isSelected
                ? "!bg-primary-600"
                : isFocused
                  ? "!bg-primary-200"
                  : "",
            control: ({ isDisabled }) =>
              isDisabled ? "!bg-secondary-300 !border-secondary-400" : "",
            singleValue: ({ isDisabled }) =>
              isDisabled ? "!text-secondary-600" : "",
            multiValueRemove: () =>
              "hover:!bg-primary-400 hover:!text-primary-600 !bg-primary-200",
            multiValueLabel: () => "!bg-primary-200",
          }}
          onChange={onChange}
          noOptionsMessage={() => "選択肢がありません"}
        />
      )}
      {required && <RequiredHiddenField value={value?.map((v) => v.value)} />}
    </div>
  );
};
