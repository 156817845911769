import React, { FC, MouseEvent } from "react";

import {
  useApisMembersAnalysisCurrentInsightPostPerformancesShow,
  useBoolean,
} from "~/hooks";

import { Button, Icon } from "~/components/atoms";
import {
  DoughnutChartBox,
  Loading,
  RangeDatePicker,
} from "~/components/molecules";

import { PastInsightPostPerformancesModal } from "./PastInsightPostPerformancesModal";

type PropsType = {
  className?: string;
};

export const InsightPostsPerformance: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  const { data, isLoading } =
    useApisMembersAnalysisCurrentInsightPostPerformancesShow();

  const {
    isChecked: isOpen,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setOpen();
  };

  return (
    <div className={className}>
      <h3 className="font-semibold text-center text-secondary-600 py-2 w-full border border-solid border-secondary-400 rounded">
        インサイト目標とポイント
      </h3>
      {isLoading && <Loading />}
      {data?.calculatePerformance ? (
        <div className="space-y-8 mt-8">
          <RangeDatePicker
            readOnly
            wrapperClassName="w-full text-center justify-center"
            className="w-full text-center"
            startDate={new Date(data.calculatePerformance.startDate)}
            endDate={new Date(data.calculatePerformance.endDate)}
          />
          <DoughnutChartBox
            title="目標に対するインサイト数"
            doughnut={{
              rate: data.calculatePerformance.performanceCountRate,
              numerator: data.calculatePerformance.performanceCount,
              denominator: data.calculatePerformance.objectivePerformance,
            }}
            isLoading={isLoading}
          />
          <div>
            <DoughnutChartBox
              title="目標に対するポイント数"
              doughnut={{
                rate: data.calculatePerformance.performancePointRate,
                numerator: data.calculatePerformance.performancePoint,
                denominator: data.calculatePerformance.objectivePerformance,
              }}
              isLoading={isLoading}
            />
            <p className="text-sm text-center mt-3">
              インサイトに対して以下で計算
            </p>
            <div className="justify-center flex items-center space-x-4 mt-1">
              <div className="flex items-center space-x-2">
                <Icon icon="ioHeart" color="text-icon-heart" size="1.25rem" />
                <span>1pt</span>
              </div>
              <div className="flex items-center space-x-2">
                <Icon
                  icon="ioChatbubbleEllipses"
                  color="text-icon-reply"
                  size="1.25rem"
                />
                <span>1pt</span>
              </div>
              <div className="flex items-center space-x-2">
                <Icon
                  icon="ioShareSocial"
                  color="text-icon-share"
                  size="1.25rem"
                />
                <span>1pt</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className="mt-8 text-center text-sm text-gray-400">
          現在インサイト目標が設定されていません
        </p>
      )}
      <div className="text-center">
        <Button
          text="過去の一覧"
          className="!text-primary-900 mt-8"
          onClick={handleOpen}
          color="gray"
          outline
          readonly={isOpen}
        />
      </div>
      {isOpen && <PastInsightPostPerformancesModal onClose={setClose} />}
    </div>
  );
};
