import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  id: string;
  body: {
    name: string;
    deleteJobCategoryPhaseIds: string[];
    updateJobCategoryPhases: {
      id: string | null;
      name: string;
      displayOrder: number;
    }[];
    createJobCategoryPhases: {
      name: string;
      displayOrder: number;
    }[];
  };
};

const request = ({ id, body }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseMessageType>(`/api/managers/job_categories/${id}`, {
      job_category: body,
    })
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersJobCategoriesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
