import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { useApisManagersSkillEvaluationStandardSummaryCategoriesEdit } from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { SkillEvaluationStandardSummaryCategoryForm } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const ManagersSkillEvaluationStandardSummaryCategoriesEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const breadCrumbItems = [
    {
      label: "スキルマップ",
      href: "/managers/skill_evaluation_standard_summary_categories",
    },
    {
      label: "編集",
      href: `/managers/skill_evaluation_standard_summary_categories/${id}/edit`,
    },
  ];

  const { data, isError } =
    useApisManagersSkillEvaluationStandardSummaryCategoriesEdit({
      id,
    });

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        {data && (
          <SkillEvaluationStandardSummaryCategoryForm
            skillEvaluationStandardSummaryCategory={
              data.skillEvaluationStandardSummaryCategory
            }
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
