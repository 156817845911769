import React, { FormEvent, MouseEvent, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useApisManagersRoleplayingConditionTemplatesCreate,
  useApisManagersRoleplayingConditionTemplatesUpdate,
  useBoolean,
  useEditorState,
  useInput,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { Button, Form, FormSubmitButton } from "~/components/atoms";
import { LabelWithTextField } from "~/components/molecules";
import {
  BlogEditor,
  AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField,
} from "~/components/organisms";

import {
  RoleplayingConditionTemplateType,
  PublicStatus,
  ApiManagersRoleplayingConditionTemplateMutateRequestType,
} from "~/domains";

type PropsType = {
  roleplayingConditionTemplate?: RoleplayingConditionTemplateType;
};

export const RoleplayingConditionTemplateForm: FC<PropsType> = ({
  roleplayingConditionTemplate,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const navigate = useNavigate();
  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: onChangeAllBusinessSectionsDistribution,
  } = useBoolean(roleplayingConditionTemplate?.allBusinessSectionsDistribution);
  const [editorState] = useEditorState(roleplayingConditionTemplate?.content);
  const [title] = useInput(roleplayingConditionTemplate?.title || "");
  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    defaultDivisions: roleplayingConditionTemplate?.businessDivisions,
    defaultSections: roleplayingConditionTemplate?.businessSections,
  });

  const { mutate: crateRequest, isLoading: isCreating } =
    useApisManagersRoleplayingConditionTemplatesCreate();
  const { mutate: updateRequest, isLoading: isUpdating } =
    useApisManagersRoleplayingConditionTemplatesUpdate();

  const handleDraftSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const params = generateParams(PublicStatus.DRAFTED.id);
    handleSubmit(params);
  };

  const handlePublishSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const params = generateParams(PublicStatus.PUBLISHED.id);
    handleSubmit(params);
  };

  const generateParams = (publicStatusId: number) => {
    return {
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      title: title.value,
      content: editorState.jsonContent,
      publicStatusId,
      allBusinessSectionsDistribution,
    };
  };

  const handleSubmit = (
    body: ApiManagersRoleplayingConditionTemplateMutateRequestType,
  ) => {
    roleplayingConditionTemplate
      ? updateRequest(
          {
            id: roleplayingConditionTemplate.id,
            body,
          },
          {
            onSuccess: (data) => {
              toast(data.message);
              navigate("/managers/roleplaying_condition_templates");
            },
          },
        )
      : crateRequest(
          {
            body,
          },
          {
            onSuccess: (data) => {
              toast(data.message);
              navigate("/managers/roleplaying_condition_templates");
            },
          },
        );
  };
  const isSubmitting = isCreating || isUpdating;
  return (
    <Form className="space-y-8" onSubmit={handlePublishSubmit}>
      <AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField
        allParents={selectableDivisions}
        parentsValue={selectedDivisions}
        parentsOnChange={onDivisionChange}
        parentRequired={true}
        parentLabel="配布先: 部署"
        allChildren={optionSelectableSections}
        childrenValue={optionSelectedSections}
        childrenOnChange={onSectionChange}
        childRequired={true}
        childLabel="配布先: 課"
        allBusinessSectionsDistribution={allBusinessSectionsDistribution}
        onChangeAllBusinessSectionsDistribution={
          onChangeAllBusinessSectionsDistribution
        }
        currentEmployeeRole={currentEmployee?.employeeRole}
      />
      <LabelWithTextField
        required
        labelText="タイトル"
        type="text"
        name="title"
        value={title.value}
        onChange={title.onChange}
        placeholder="タイトル"
      />
      <BlogEditor
        labelText="内容"
        required
        editorState={editorState.value}
        setEditorState={editorState.onChange}
      />
      <div className="flex items-center space-x-4 mx-auto">
        <Button
          text="下書き保存"
          onClick={handleDraftSubmit}
          color="gray"
          outline
          className="w-full"
          readonly={isSubmitting}
        />
        <FormSubmitButton
          value="配布する"
          color="primary"
          className="w-full"
          isReadOnly={isSubmitting}
        />
      </div>
    </Form>
  );
};
