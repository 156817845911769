import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useBoolean,
  useFooters,
  useApisMembersInsightPostsLikesCreate,
  useApisMembersInsightPostsLikesDestroy,
  useApisMembersInsightPostBookmarkFoldersIndex,
  useApisMembersInsightPostsBookmarksCreate,
  useApisMembersInsightPostsBookmarksDestroy,
  useCounter,
  useProvidersCurrentEmployee,
  useApisMembersInsightPostsSharesDestroy,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import {
  PostHeader,
  PostContent,
  InsightPostShareFormModal,
  InsightPostBookmarkMoveModal,
  InsightPostsLikedEmployeesModal,
} from "~/components/organisms";

import {
  InsightPostType,
  EmployeeRole,
  PostDetailMenuItemType,
  ResponseMessageType,
} from "~/domains";

type PropsType = {
  insightPost: InsightPostType;
  menuItems: PostDetailMenuItemType[];
  className?: string;
  isHighlight?: boolean;
  openComment: (value: string) => void;
};

export const InsightPostDetail: FC<PropsType> = ({
  insightPost,
  menuItems,
  className = "",
  isHighlight = false,
  openComment,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const {
    isChecked: isShareableModalOpen,
    setTrue: setShareableModalOpen,
    setFalse: setShareableModalClose,
  } = useBoolean(false);
  const {
    isChecked: isShared,
    setTrue: setShared,
    setFalse: setNotShared,
  } = useBoolean(insightPost.shared);
  const {
    isChecked: isBookmarkModalOpen,
    setTrue: setBookmarkModalOpen,
    setFalse: setBookmarkModalClose,
  } = useBoolean(false);
  const {
    isChecked: isLikedEmployeesModalOpen,
    setTrue: setLikedEmployeesModalOpen,
    setFalse: setLikedEmployeesModalClose,
  } = useBoolean(false);
  const {
    isChecked: liked,
    setTrue: setLiked,
    setFalse: setNotLiked,
  } = useBoolean(insightPost.liked);
  const {
    count: likeCount,
    increment: likeIncrement,
    decrement: likeDecrement,
  } = useCounter(insightPost.insightPostStat.likesCount);
  const {
    isChecked: bookmarked,
    setTrue: setBookmarked,
    setFalse: setNotBookmarked,
  } = useBoolean(insightPost.bookmarked);
  const {
    count: bookmarkCount,
    increment: bookmarkIncrement,
    decrement: bookmarkDecrement,
  } = useCounter(insightPost.insightPostStat.bookmarksCount);
  const { data: bookmarkFoldersIndex } =
    useApisMembersInsightPostBookmarkFoldersIndex({
      config: {
        enabled: isBookmarkModalOpen,
      },
    });

  const { mutate: shareDeleteRequest } =
    useApisMembersInsightPostsSharesDestroy();

  const { mutate: likeCreateRequest } = useApisMembersInsightPostsLikesCreate();
  const { mutate: likeDeleteRequest } =
    useApisMembersInsightPostsLikesDestroy();

  const { mutate: bookmarkCreateRequest } =
    useApisMembersInsightPostsBookmarksCreate();
  const { mutate: bookmarkDeleteRequest } =
    useApisMembersInsightPostsBookmarksDestroy();

  const handleCreateLike = () => {
    likeCreateRequest(
      {
        insightPostId: insightPost.id,
      },
      {
        onSuccess: () => {
          setLiked();
          likeIncrement();
        },
      },
    );
  };

  const handleDeleteLike = () => {
    likeDeleteRequest(
      {
        insightPostId: insightPost.id,
      },
      {
        onSuccess: () => {
          setNotLiked();
          likeDecrement();
        },
      },
    );
  };

  const handleCreateBookmark = () => {
    bookmarkCreateRequest(
      {
        insightPostId: insightPost.id,
      },
      {
        onSuccess: () => {
          setBookmarked();
          bookmarkIncrement();
        },
      },
    );
  };

  const handleDeleteBookmark = () => {
    bookmarkDeleteRequest(
      {
        insightPostId: insightPost.id,
      },
      {
        onSuccess: () => {
          setNotBookmarked();
          bookmarkDecrement();
        },
      },
    );
  };

  const handleDeleteShare = () => {
    const isConfirm = window.confirm("シェアを解除しますか？");
    if (!isConfirm) return;

    shareDeleteRequest(
      {
        insightPostId: insightPost.id,
      },
      {
        onSuccess: (res) => {
          setNotShared();
          toast(res.message);
        },
      },
    );
  };

  const leftFooters = useFooters({
    like: {
      doneAlready: liked,
      undoOnClick: handleDeleteLike,
      doOnClick: handleCreateLike,
      count: likeCount,
      textOnClick: setLikedEmployeesModalOpen,
    },
    comment: {
      doneAlready: Boolean(insightPost.insightPostStat.commentsCount),
      undoOnClick: () => openComment(insightPost.id),
      doOnClick: () => openComment(insightPost.id),
      count: insightPost.insightPostStat.commentsCount,
    },
    bookmark: {
      doneAlready: bookmarked,
      undoOnClick: handleDeleteBookmark,
      doOnClick: handleCreateBookmark,
      count: bookmarkCount,
    },
    shareable: {
      doneAlready: isShared,
      undoOnClick: setShareableModalOpen,
      doOnClick: handleDeleteShare,
      disable:
        !insightPost.shareable ||
        currentEmployee?.employeeRole.id === EmployeeRole.MEMBER.id,
    },
  });

  const rightFooters = useFooters({
    bookmarkFolder: {
      doneAlready: bookmarked,
      undoOnClick: setBookmarkModalOpen,
      doOnClick: setBookmarkModalOpen,
      disable: !bookmarked,
    },
  });

  const shareOnSuccess = (data: ResponseMessageType) => {
    toast(data.message);
    setShareableModalClose();
    setShared();
  };

  const canBookmarkMove =
    isBookmarkModalOpen && bookmarked && bookmarkFoldersIndex;

  return (
    <PostDetailWrapper isHighlight={isHighlight} className={className}>
      <PostHeader
        miniText={insightPost.createdAt}
        postedEmployee={insightPost.employee}
        menuItems={menuItems}
        underLabel={`${insightPost.jobCategory.name} - ${
          insightPost.providingService?.name || "すべて"
        } - 
        ${insightPost.jobCategoryPhase?.name || "すべて"}`}
      />
      <PostContent
        content={insightPost.content}
        images={insightPost.attachFiles}
        footers={leftFooters.footers}
        rightFooters={rightFooters.footers}
        blockQuote={
          insightPost.insightPostQuotePost
            ? {
                content: insightPost.insightPostQuotePost.content,
                employee: insightPost.insightPostQuotePost.employee,
                files: insightPost.insightPostQuotePost.attachFiles,
                createdAt: insightPost.insightPostQuotePost.createdAt,
              }
            : undefined
        }
        className="mt-1 pl-16"
      />
      {isShareableModalOpen && (
        <InsightPostShareFormModal
          originalInsightPost={insightPost}
          onClose={setShareableModalClose}
          onSuccess={shareOnSuccess}
        />
      )}
      {canBookmarkMove && (
        <InsightPostBookmarkMoveModal
          insightPostId={insightPost.id}
          onClose={setBookmarkModalClose}
        />
      )}
      {isLikedEmployeesModalOpen && (
        <InsightPostsLikedEmployeesModal
          insightPostId={insightPost.id}
          onClose={setLikedEmployeesModalClose}
        />
      )}
    </PostDetailWrapper>
  );
};
