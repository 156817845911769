import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useAnchorScroll = (loaded = false) => {
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) return;

    const targetElement = document.getElementById(hash.slice(1));
    if (targetElement && loaded) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [hash, loaded]);
};
