import React, { FC } from "react";

import { GridCell } from "~/components/atoms";

type PropsType = {
  texts: string[];
  className?: string;
};

export const GridHeaderCells: FC<PropsType> = ({
  texts,
  className = "",
}: PropsType) => {
  return (
    <>
      {texts.map((text, index) => (
        <GridCell key={index} className={`font-semibold ${className}`}>
          {text}
        </GridCell>
      ))}
    </>
  );
};
