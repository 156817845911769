import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useAnchorScroll,
  useApisMembersKnowledgeInsightPostCommentsShow,
  useApisMembersKnowledgeInsightPostsNew,
  useRightSidebarState,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  KnowledgeInsightPostDetailWithEditForm,
  KnowledgeInsightPostCommentsWithKnowledgeInsightPost,
} from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

import { ManagerRole } from "~/domains";

export const MembersKnowledgeInsightPostCommentsShow: FC = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();
  const { isReplyOpen, setReplyOpen } = useRightSidebarState("isReplyOpen");

  const { data, refetch, isError } =
    useApisMembersKnowledgeInsightPostCommentsShow({
      id,
    });

  const type = data?.knowledgeInsightPost.employeeRole.type || ManagerRole.type;

  const breadCrumbItems = [
    {
      label: type === ManagerRole.type ? "部署の発信" : "本部の発信",
      href: `/knowledge_post/${type}`,
    },
    { label: "詳細", href: `/knowledge_insight_post_comments/${id}` },
  ];

  const { data: fetchNewData } = useApisMembersKnowledgeInsightPostsNew();

  useEffect(() => {
    setReplyOpen();
  }, [setReplyOpen]);

  useAnchorScroll(!!data);
  if (isError) return <NotFoundIndex />;

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
          {data && (
            <KnowledgeInsightPostDetailWithEditForm
              knowledgeInsightPost={data.knowledgeInsightPost}
              openComment={setReplyOpen}
              deleteItem={() => navigate("/knowledge_post/team")}
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          {isReplyOpen && data && (
            <>
              <RightSidebarTitle title="リプライ" />
              <KnowledgeInsightPostCommentsWithKnowledgeInsightPost
                className="mt-6 mx-4"
                refetch={refetch}
                mentions={fetchNewData?.mentionableEmployees || []}
                knowledgeInsightPost={data.knowledgeInsightPost}
                knowledgeInsightPostComments={data.knowledgeInsightPostComments}
                highlightKnowledgeInsightPostCommentId={id}
              />
            </>
          )}
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
