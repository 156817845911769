import { useState, useCallback } from "react";

import { createEditorStateWithText } from "@draft-js-plugins/editor";
import {
  EditorState,
  RawDraftContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";

import { rawDraftContentStateToDraftJsContent } from "~/utils";

type ReturnType = [
  {
    value: EditorState;
    onChange: (value: EditorState) => void;
    jsonContent: RawDraftContentState;
    plainText: string;
    hasText: boolean;
    setPlainText: (value: string) => void;
  },
  (value: RawDraftContentState) => void,
];

export const useEditorState = (
  initialValue?: RawDraftContentState,
): ReturnType => {
  const defaultEdit = rawDraftContentStateToDraftJsContent(initialValue);
  const [editorState, setEditorState] = useState(defaultEdit);

  const currentContent = editorState.getCurrentContent();
  const jsonContent = convertToRaw(currentContent);

  const setValue = useCallback((newValue: RawDraftContentState) => {
    setEditorState(EditorState.createWithContent(convertFromRaw(newValue)));
  }, []);

  return [
    {
      value: editorState,
      onChange: (value: EditorState) => setEditorState(value),
      jsonContent: jsonContent,
      plainText: currentContent.getPlainText(),
      hasText: editorState.getCurrentContent().hasText(),
      setPlainText: (newValue: string) =>
        setEditorState(createEditorStateWithText(newValue)),
    },
    useCallback(
      (newValue: RawDraftContentState) => setValue(newValue),
      [setValue],
    ),
  ];
};
