import React, { useState, FC, MouseEvent } from "react";

import { QueryObserverResult } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  useApisMembersInsightPostsInsightPostCommentsCreate,
  useEditorState,
  useEditorStateLinkContentGetter,
} from "~/hooks";

import {
  InsightPostCommentDetailWithEditForm,
  InsightPostDetail,
  TextEditor,
} from "~/components/organisms";

import {
  InsightPostType,
  InsightPostCommentType,
  MentionSuggestEmployeeType,
  PreviewWithFileType,
  ApiMemberInsightPostsInsightPostCommentsIndexType,
} from "~/domains";

type PropsType = {
  highlightInsightPostCommentId?: string;
  insightPost: InsightPostType;
  insightPostComments: InsightPostCommentType[];
  mentions: MentionSuggestEmployeeType[];
  className?: string;
  refetch: () => Promise<
    QueryObserverResult<
      ApiMemberInsightPostsInsightPostCommentsIndexType,
      unknown
    >
  >;
};

export const InsightPostCommentsWithInsightPost: FC<PropsType> = ({
  highlightInsightPostCommentId = "",
  insightPost,
  insightPostComments,
  mentions,
  className = "",
  refetch,
}: PropsType) => {
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const [
    {
      value: editorState,
      onChange: setEditorState,
      jsonContent,
      hasText,
      setPlainText,
    },
  ] = useEditorState();
  const { fetchedData } = useEditorStateLinkContentGetter({
    watchEditorState: editorState,
    isWatch: true,
  });

  const { mutate, isLoading } =
    useApisMembersInsightPostsInsightPostCommentsCreate();

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!hasText) {
      toast.error("テキストが空です");
      return;
    }
    const body = {
      content: jsonContent,
      attachFiles: files,
      quotePostId: fetchedData?.id,
    };

    mutate(
      {
        insightPostId: insightPost.id,
        body,
      },
      {
        onSuccess: async () => {
          setPlainText("");
          setFiles([]);
          await refetch();
        },
      },
    );
  };

  return (
    <div className={className}>
      <div className="space-y-6">
        <InsightPostDetail
          insightPost={insightPost}
          menuItems={[]}
          openComment={() => refetch()}
        />
        <div>
          {insightPostComments.map((comment) => (
            <InsightPostCommentDetailWithEditForm
              key={comment.id}
              insightPostComment={comment}
              mentions={mentions}
              refetch={refetch}
              isHighlight={comment.id === highlightInsightPostCommentId}
            />
          ))}
        </div>
        <TextEditor
          submitButton={{
            isSubmitting: isLoading,
            onSubmit: handleSubmit,
          }}
          mentions={mentions}
          editorState={editorState}
          setEditorState={setEditorState}
          files={files}
          onChangeFiles={setFiles}
          blockQuote={fetchedData}
          isEmojiPickerTop
        />
      </div>
    </div>
  );
};
