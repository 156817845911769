export const AiChatSelectableExpertRoles = [
  {
    id: "1",
    name: "営業の専門家",
  },
  {
    id: "2",
    name: "インサイドセールスの専門家",
  },
  {
    id: "3",
    name: "フィールドセールスの専門家",
  },
  {
    id: "4",
    name: "営業コンサルタント",
  },
  {
    id: "5",
    name: "セールスイネーブルメントの専門家",
  },
  {
    id: "6",
    name: "カスタマーサポートの専門家",
  },
  {
    id: "7",
    name: "カスタマーサクセスの専門家",
  },
  {
    id: "8",
    name: "マーケティングの専門家",
  },
];

export const AiChatSelectablePromptTemplates = [
  {
    id: "1",
    name: "調査",
    template: `# 指示
以下の箇条書きから、●●業界の「調査」をお願いします。
一歩ずつ考え、最高の出力をお願いします。
・業界の過去5年間の成長性を教えてください
・業界の中での新興企業を教えてください
・業界の中での大手企業を教えてください
・業界の市場規模を教えてください
・業界の法的な規制や制約条件を教えてください
・業界でトレンドとなっている言葉を教えてください
・業界で起きている変化について教えてください
・業界の課題を教えてください

# 制約条件
・簡潔に教えてください
・論理的に教えてください
・海外ではなく、国内の状況を教えてください`,
  },
  {
    id: "2",
    name: "御礼メール文書作成",
    template: `# 指示
メールでお客様からの信頼を獲得したいと考えています。
以下の制約条件とメール内容をもとに、メール文を出力してください。
一歩ずつ考え、最高の出力をお願いします。

# 制約条件
・相手を尊重し、丁寧で誠実さが伝わる文体にする
・信頼獲得を目指す
・適度な親密さを出す
・冗長でない、簡潔で読みやすい文章にする
・最終目的は自社サービスを相手に導入してもらうこととする 
・押し売りはしない
・日本のビジネス慣習に従い、正しい敬語を使う
・「！」や「？」は使わない
・相手に興味を持ってもらう工夫をする
・相手にお願いをする際は一方的にならないよう、相手の都合を尊重した聞き方にする

# メール内容
(ここにメール内容や目的などを記載。以下は例)
・資料請求したお客様に対するメール
・まず資料請求への感謝を伝える
・資料請求のきっかけとお客様の課題を自然な形で聞き出し、返信いただけるよう促す
・自然な形で、ヒアリングと製品デモのお打ち合わせの打診をする
・お打ち合わせでは他社事例など、お客様にお役立ていただける情報を提供することを伝える`,
  },
  {
    id: "3",
    name: "アウトバウンドメール文書作成",
    template: `# 指示
メールでお客様からの信頼を獲得したいと考えています。
以下の制約条件とメール内容をもとに、メール文を出力してください。
一歩ずつ考え、最高の出力をお願いします。

# 制約条件
・相手を尊重し、丁寧で誠実さが伝わる文体にする
・信頼獲得を目指す
・適度な親密さを出す
・冗長でない、簡潔で読みやすい文章にする
・最終目的は自社サービスを相手に導入してもらうこととする 
・ただし押し売りはしないこと
・日本のビジネス慣習に従い、正しい敬語を使う
・「！」や「？」は使わない
・相手に興味を持ってもらう工夫をする
・相手にお願いをする際は一方的にならないよう、相手の都合を尊重した聞き方にする

# メール内容
(ここにメール内容や目的などを記載。以下は例)
・初めての相手に対するアウトバウンド営業のメール
・お打ち合わせを打診するための日程調整リンク(urlは◯◯で良いです)をお送りし、アポの日程調整をしてもらうことが目的です
・紹介するサービスは、相手の□□という課題を△△の観点から解決できるものとします
・お打ち合わせでは、他社事例など有益な情報を提供することを伝える`,
  },
  {
    id: "4",
    name: "行動計画",
    template: `# 指示
私は●●商材を取り扱う営業です。
これから営業を行っていくにあたり、目標を達成するための行動計画が必要になります。
以下の制約条件をもとに、行動計画を立案してください。
一歩ずつ考え、最高の出力をお願いします。

# 制約条件
・現実的な方法を選択する
・論理的に計画を立てる
・ターゲットとして想定される業界を列挙してください
・どのようなアクションが必要にいなるのかを段階的に示してください
・営業手法は架電による営業と訪問による提案営業を想定しています
・営業リストのリストアップ数を算出してください
・1ヶ月単位での行動計画とする
・12ヶ月分の行動計画を出す
・1ヶ月あたりの営業日は20日とする
・12ヶ月の間で達成する成約金額は●●●円とする`,
  },
  {
    id: "5",
    name: "ターゲティング",
    template: `# 指示
以下の箇条書きから、●●商材に適するターゲットと想定される方を教えてください。
一歩ずつ考え、最高の出力をお願いします。

・主にターゲットとなる方の年齢層を教えてください
・主にターゲットとなる方の性別を教えてください
・主にターゲットとなる方の趣味や嗜好を教えてください
・主にターゲットとなる方の性格や行動特性を教えてください
・意外なターゲットとなる方を教えてください

# 制約条件
・論理的に考えて教えてください`,
  },
  {
    id: "6",
    name: "ヒヤリング",
    template: `# 指示
私は●●商材を取り扱う営業です。
お客様に貢献するためのヒアリング項目を考えています。
ヒアリングにおいて、どんな質問が有効で、ポイントが何かを教えてください。
一歩ずつ考え、最高の出力をお願いします。

# 制約条件
・論理的に教えてください
・具体例を交えて教えてください
・意外性のあるヒアリング手法を教えてください
・高い成果をあげる営業が行うヒアリングを教えてください`,
  },
  {
    id: "7",
    name: "提案",
    template: `# 指示
私は●●商材を取り扱う営業です。
お客様に貢献するための提案を考えています。
どんなが提案が有効で、ポイントが何かを教えてください。
一歩ずつ考え、最高の出力をお願いします。

# 制約条件
・論理的に教えてください
・具体例を交えて教えてください
・意外性のある提案を教えてください
・高い成果をあげる営業が行う提案を教えてください`,
  },
  {
    id: "8",
    name: "差別化",
    template: `# 指示
私は●●商材を取り扱う営業です。
お客様への提案を行う際における、他社商材との「差別化」のポイントを考えています。
取り扱っている商材において、差別化を考える上での全体像やポイントを列挙してください。
一歩ずつ考え、最高の出力をお願いします。

# 制約条件
・論理的に教えてください
・具体例を交えて教えてください
・意外性のある差別化の視点を教えてください
・高い成果をあげる営業が行う差別化のポイントを教えてください`,
  },
  {
    id: "9",
    name: "クロージング",
    template: `# 指示
私は●●商材を取り扱う営業です。
お客様との商談において「クロージング」を行っていくためのポイントを考えています。
取り扱っている商材において、クロージングを考える上での全体像やポイントを列挙してください。
一歩ずつ考え、最高の出力をお願いします。

# 制約条件
・論理的に教えてください
・具体例を交えて教えてください
・意外性のあるクロージングの視点を教えてください
・高い成果をあげる営業が行うクロージングのポイントを教えてください`,
  },
  {
    id: "10",
    name: "仮説構築",
    template: `# 指示
私は●●商材を取り扱う営業です。
お客様との商談準備において「仮説構築」をするためのポイントを考えています。
取り扱っている商材において、仮説構築をする上での全体像やポイントを列挙してください。
一歩ずつ考え、最高の出力をお願いします。

# 制約条件
・論理的に教えてください
・具体例を交えて教えてください
・意外性のある仮説構築の視点を教えてください
・高い成果をあげる営業が行う仮説構築のポイントを教えてください`,
  },
  {
    id: "11",
    name: "心構え",
    template: `# 指示
私は●●商材を取り扱う営業です。
この営業で成果を上げ続けるためには、どのような心構えが大切かを考えています。
取り扱っている商材における、心構えを列挙してください。
一歩ずつ考え、最高の出力をお願いします。

# 制約条件
・論理的に教えてください
・具体例を交えて教えてください
・高い成果をあげる営業の心構えを教えてください
・講師が受講者に教えるように伝えてください`,
  },
];
