import React, { FC, FormEvent, ChangeEvent, MouseEvent } from "react";

import { Button, Form, FormSubmitButton, TextField } from "~/components/atoms";
import { RangeDatePropsType, TitleWithChild } from "~/components/molecules";
import { SelectDateRangeButtons } from "~/components/organisms";

type PropsType = {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  searchKeyword: {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  date: {
    startDate: Date | null;
    endDate: Date | null;
    onChange: (value: RangeDatePropsType) => void;
  };
};

export const PrivatePersonalMemosSearchForm: FC<PropsType> = ({
  onSubmit,
  onConditionReset,
  searchKeyword,
  date,
}: PropsType) => {
  return (
    <Form onSubmit={onSubmit} className="mt-6 space-y-6">
      <>
        <TitleWithChild title="期間指定">
          <SelectDateRangeButtons
            startDate={date.startDate}
            endDate={date.endDate}
            onChange={date.onChange}
            className="my-2"
          />
        </TitleWithChild>
        <TitleWithChild title="フリーワード">
          <>
            <TextField
              value={searchKeyword.value}
              placeholder="キーワード"
              type="search"
              name="keyword"
              onChange={searchKeyword.onChange}
            />
          </>
        </TitleWithChild>
        <div className="text-right space-x-2 flex">
          <Button
            color="gray"
            outline
            onClick={onConditionReset}
            text="リセット"
            className="w-full"
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full"
          />
        </div>
      </>
    </Form>
  );
};
