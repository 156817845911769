import {
  useArray,
  useApisManagersBusinessSectionsBusinessSectionCompetencyReviewsIndex,
} from "~/hooks";

import { BusinessSectionCompetencyReviewType } from "~/domains";

type ReturnType = {
  businessSectionCompetencyReviews: BusinessSectionCompetencyReviewType[];
  hasNextPage?: boolean;
  isLoading: boolean;
  fetchNextPage: () => void;
  refetch: () => void;
  unshiftItem: (item: BusinessSectionCompetencyReviewType) => void;
};

export const useSearchManagersBusinessSectionsBusinessSectionCompetencyReviews =
  (businessSectionId: string): ReturnType => {
    const {
      items: businessSectionCompetencyReviews,
      setItems: setBusinessSectionCompetencyReviews,
      unshiftItem,
    } = useArray<BusinessSectionCompetencyReviewType>([]);

    const { fetchNextPage, refetch, hasNextPage, isFetching } =
      useApisManagersBusinessSectionsBusinessSectionCompetencyReviewsIndex({
        businessSectionId,
        config: {
          onSuccess: (data) => {
            setBusinessSectionCompetencyReviews(
              data.pages
                .map((page) => page.businessSectionCompetencyReviews)
                .flat(),
            );
          },
        },
      });

    return {
      isLoading: isFetching,
      businessSectionCompetencyReviews,
      hasNextPage,
      fetchNextPage,
      refetch,
      unshiftItem,
    };
  };
