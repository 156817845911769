export type AiChatRoomType = {
  id: string;
  title: string;
  aiChatRoomSetting?: AiChatRoomSettingType;
  createdAt: string;
  updatedAt: string;
};

export type AiChatRoomSettingType = {
  id: string;
  aiChatRoomId: string;
  expertRoleName: string;
  aiSystemMessage: string;
  createdAt: string;
  updatedAt: string;
};

export type AiEmployeeConversationType = {
  id: string;
  message: string;
  aiChatRoomId: string;
  senderType: AiEmployeeConversationSenderType;
  createdAt: string;
  updatedAt: string;
};

type RoleType = {
  id: 1 | 10 | 20;
  name: "ユーザー" | "アシスタント" | "システム";
  type: "user" | "assistant" | "system";
};

const AiEmployeeConversationSenderUser = {
  id: 1,
  name: "ユーザー",
  type: "user",
} as const satisfies RoleType;

const AiEmployeeConversationSenderAssistant = {
  id: 10,
  name: "アシスタント",
  type: "assistant",
} as const satisfies RoleType;

const AiEmployeeConversationSenderSystem = {
  id: 20,
  name: "システム",
  type: "system",
} as const satisfies RoleType;

export type AiEmployeeConversationSenderType =
  | typeof AiEmployeeConversationSenderUser
  | typeof AiEmployeeConversationSenderAssistant
  | typeof AiEmployeeConversationSenderSystem;

export const AiEmployeeConversationSender = {
  user: AiEmployeeConversationSenderUser,
  assistant: AiEmployeeConversationSenderAssistant,
  system: AiEmployeeConversationSenderSystem,
} as const;
