import React, { FC } from "react";

import {
  useFooters,
  useApisMembersFocusChallengeEmployeeRepliesLikesCreate,
  useApisMembersFocusChallengeEmployeeRepliesLikesDestroy,
  useBoolean,
  useCounter,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import {
  PostHeader,
  PostContent,
  FocusChallengeEmployeeReplyLikedEmployeesModal,
} from "~/components/organisms";

import {
  FocusChallengeEmployeeReplyType,
  PostDetailMenuItemType,
} from "~/domains";

type PropsType = {
  focusChallengeEmployeeReply: FocusChallengeEmployeeReplyType;
  menuItems: PostDetailMenuItemType[];
  isHighlight?: boolean;
};

export const FocusChallengeEmployeeReplyDetail: FC<PropsType> = ({
  focusChallengeEmployeeReply,
  menuItems,
  isHighlight = false,
}: PropsType) => {
  const {
    isChecked: isLikedEmployeesModalOpen,
    setTrue: setLikedEmployeesModalOpen,
    setFalse: setLikedEmployeesModalClose,
  } = useBoolean(false);
  const {
    isChecked: liked,
    setTrue: setLikedTrue,
    setFalse: setLikedFalse,
  } = useBoolean(focusChallengeEmployeeReply.liked);
  const {
    count: likeCount,
    increment: likeIncrement,
    decrement: likeDecrement,
  } = useCounter(focusChallengeEmployeeReply.likesCount);
  const { mutate: likePostRequest } =
    useApisMembersFocusChallengeEmployeeRepliesLikesCreate();
  const { mutate: likeDestroyRequest } =
    useApisMembersFocusChallengeEmployeeRepliesLikesDestroy();

  const handleLikePostRequest = () => {
    likePostRequest(
      {
        focusChallengeEmployeeReplyId: focusChallengeEmployeeReply.id,
      },
      {
        onSuccess: () => {
          setLikedTrue();
          likeIncrement();
        },
      },
    );
  };

  const handleLikeDestroyRequest = () => {
    likeDestroyRequest(
      {
        focusChallengeEmployeeReplyId: focusChallengeEmployeeReply.id,
      },
      {
        onSuccess: () => {
          setLikedFalse();
          likeDecrement();
        },
      },
    );
  };

  const leftFooters = useFooters({
    like: {
      doneAlready: liked,
      undoOnClick: handleLikeDestroyRequest,
      doOnClick: handleLikePostRequest,
      textOnClick: setLikedEmployeesModalOpen,
      count: likeCount,
      disable: false,
    },
  });

  return (
    <PostDetailWrapper isHighlight={isHighlight}>
      <PostHeader
        miniText={focusChallengeEmployeeReply.createdAt}
        postedEmployee={focusChallengeEmployeeReply.employee}
        menuItems={menuItems}
      />
      <PostContent
        content={focusChallengeEmployeeReply.content}
        images={focusChallengeEmployeeReply.attachFiles}
        footers={leftFooters.footers}
        className="mt-2 pl-16"
      />
      {isLikedEmployeesModalOpen && (
        <FocusChallengeEmployeeReplyLikedEmployeesModal
          focusChallengeEmployeeReplyId={focusChallengeEmployeeReply.id}
          onClose={setLikedEmployeesModalClose}
        />
      )}
    </PostDetailWrapper>
  );
};
