import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersKpiTermsMutateType } from "~/domains";

type RequestDataType = {
  body: {
    businessSectionId: string;
    title: string;
    fromDate: string;
    toDate: string;
    publicStatusId: number;
  };
};

const request = ({ body }: RequestDataType) => {
  return new ApiClient()
    .post<ApiManagersKpiTermsMutateType>("/api/managers/kpi_terms", {
      kpiTerm: body,
    })
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersKpiTermsCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersKpiTermsMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
