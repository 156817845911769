import React, { FormEvent, MouseEvent, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useApisManagersInsightFocusKaizenContentTemplatesCreate,
  useApisManagersInsightFocusKaizenContentTemplatesUpdate,
  useBoolean,
  useDropdown,
  useEditorState,
  useInput,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { Button, Form, FormSubmitButton } from "~/components/atoms";
import {
  LabelWithDropDownField,
  LabelWithTextField,
} from "~/components/molecules";
import {
  AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField,
  BlogEditor,
} from "~/components/organisms";

import {
  AllInsightFocusKaizenContentTypes,
  ApiManagersInsightFocusKaizenContentTemplateMutateRequestType,
  InsightFocusKaizenContentTemplateType,
  PublicStatus,
} from "~/domains";

type PropsType = {
  insightFocusKaizenContentTemplate?: InsightFocusKaizenContentTemplateType;
};

export const InsightFocusKaizenContentTemplateForm: FC<PropsType> = ({
  insightFocusKaizenContentTemplate,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const navigate = useNavigate();
  const defaultType = AllInsightFocusKaizenContentTypes.find(
    (status) =>
      status.id ===
      insightFocusKaizenContentTemplate?.insightFocusKaizenContentType.id,
  );
  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: onChangeAllBusinessSectionsDistribution,
  } = useBoolean(
    insightFocusKaizenContentTemplate?.allBusinessSectionsDistribution,
  );
  const [editorState] = useEditorState(
    insightFocusKaizenContentTemplate?.content,
  );
  const [title] = useInput(insightFocusKaizenContentTemplate?.title || "");
  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    defaultDivisions: insightFocusKaizenContentTemplate?.businessDivisions,
    defaultSections: insightFocusKaizenContentTemplate?.businessSections,
  });
  const [
    {
      dropdownValue: selectedDropdownInsightFocusKaizenContentTypeId,
      onChange: onChangeDropdownInsightFocusKaizenContentTypeId,
      dropdownSelectableValue:
        selectableDropdownInsightFocusKaizenContentTypeIds,
    },
  ] = useDropdown({
    selectableValue: AllInsightFocusKaizenContentTypes.map((status) => ({
      name: status.name,
      id: status.id.toString(),
    })),
    initialValue: defaultType
      ? {
          id: defaultType.id.toString(),
          name: defaultType.name,
        }
      : undefined,
  });

  const { mutate: postRequest, isLoading: isCreating } =
    useApisManagersInsightFocusKaizenContentTemplatesCreate();
  const { mutate: putRequest, isLoading: isUpdating } =
    useApisManagersInsightFocusKaizenContentTemplatesUpdate();

  const handleDraftSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const params = generateParams(PublicStatus.DRAFTED.id);
    handleSubmit(params);
  };

  const handlePublishSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const params = generateParams(PublicStatus.PUBLISHED.id);
    handleSubmit(params);
  };

  const generateParams = (publicStatusId: number) => {
    return {
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      title: title.value,
      content: editorState.jsonContent,
      insightFocusKaizenContentTypeId:
        selectedDropdownInsightFocusKaizenContentTypeId?.value,
      publicStatusId: publicStatusId,
      allBusinessSectionsDistribution,
    };
  };

  const handleSubmit = (
    body: ApiManagersInsightFocusKaizenContentTemplateMutateRequestType,
  ) => {
    insightFocusKaizenContentTemplate
      ? putRequest(
          {
            id: insightFocusKaizenContentTemplate.id,
            body,
          },
          {
            onSuccess: (data) => {
              toast(data.message);
              navigate("/managers/insight_focus_kaizen_content_templates");
            },
          },
        )
      : postRequest(
          {
            body,
          },
          {
            onSuccess: (data) => {
              toast(data.message);
              navigate("/managers/insight_focus_kaizen_content_templates");
            },
          },
        );
  };
  const isSubmitting = isCreating || isUpdating;
  return (
    <>
      <Form className="space-y-8" onSubmit={handlePublishSubmit}>
        <AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentsOnChange={onDivisionChange}
          parentRequired={true}
          parentLabel="配布先: 部署"
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childrenOnChange={onSectionChange}
          childRequired={true}
          childLabel="配布先: 課"
          allBusinessSectionsDistribution={allBusinessSectionsDistribution}
          onChangeAllBusinessSectionsDistribution={
            onChangeAllBusinessSectionsDistribution
          }
          currentEmployeeRole={currentEmployee?.employeeRole}
        />
        <LabelWithDropDownField
          labelText="種類"
          required
          name="insightFocusKaizenContentTypeId"
          options={selectableDropdownInsightFocusKaizenContentTypeIds}
          value={selectedDropdownInsightFocusKaizenContentTypeId}
          onChange={onChangeDropdownInsightFocusKaizenContentTypeId}
        />
        <LabelWithTextField
          required
          labelText="タイトル"
          type="text"
          name="title"
          value={title.value}
          onChange={title.onChange}
          placeholder="タイトル"
        />
        <BlogEditor
          labelText="内容"
          required
          editorState={editorState.value}
          setEditorState={editorState.onChange}
        />
        <div className="flex items-center space-x-4 mx-auto">
          <Button
            text="下書き保存"
            onClick={handleDraftSubmit}
            color="gray"
            outline
            className="w-full"
            readonly={isSubmitting}
          />
          <FormSubmitButton
            value="配布する"
            color="primary"
            className="w-full"
            isReadOnly={isSubmitting}
          />
        </div>
      </Form>
    </>
  );
};
