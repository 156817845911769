import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  roleplayingConditionId: string;
  body: {
    archivedSkillEvaluationStandardSummaryCategoryId: string;
    employeeSelectArchivedSkillEvaluationIds: string[];
  };
};

const request = ({ roleplayingConditionId, body }: RequestDataType) => {
  return new ApiClient()
    .post<ResponseMessageType>(
      `/api/members/roleplaying_conditions/${roleplayingConditionId}/employee_select_skill_evaluations`,
      {
        employeeSelectSkillEvaluation: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersRoleplayingConditionsEmployeeSelectSkillEvaluationsCreate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ResponseMessageType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
