import { useEffect, MouseEvent, ChangeEvent } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersSkillEvaluationTermsEmployeeSelectSkillEvaluationsCreate,
  useApisMembersSkillEvaluationTermsEmployeeSelectSkillEvaluationsCreate,
  useArray,
  useBoolean,
  useInput,
} from "~/hooks";

import {
  ArchivedSkillEvaluationStandardType,
  EmployeeRole,
  EmployeeSelectArchivedSkillEvaluationType,
  ManagerRole,
  MemberRole,
  SkillEvaluationTermRevieweeCommentType,
} from "~/domains";

type ReturnType = {
  selectedMemberSkillEvaluationStandardIds: string[];
  selectedManagerSkillEvaluationStandardIds: string[];
  revieweeDisable: boolean;
  reviewerDisable: boolean;
  skillEvaluationStandardSummariesCount: number;
  isReadOnly: boolean;
  revieweeComment: string;
  onChangeRevieweeComment: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleNotReadonly: () => void;
  memberSelectSkillEvaluationStandard: (
    newSkillEvaluationStand: PickSkillEvaluationStandardType,
  ) => void;
  managerSelectSkillEvaluationStandard: (
    newSkillEvaluationStand: PickSkillEvaluationStandardType,
  ) => void;
  memberSave: (e: MouseEvent<HTMLButtonElement>) => void;
  managerSave: (e: MouseEvent<HTMLButtonElement>) => void;
};

export type PickSkillEvaluationStandardType = Pick<
  ArchivedSkillEvaluationStandardType,
  "id" | "archivedSkillEvaluationStandardSummaryId"
>;

type PickEmployeeSelectArchivedSkillEvaluationType = Pick<
  EmployeeSelectArchivedSkillEvaluationType,
  "revieweeEmployeeId" | "reviewerEmployeeId" | "skillEvaluationTermId"
> & {
  revieweeSelectArchivedSkillEvaluationStandard: PickSkillEvaluationStandardType;
  reviewerSelectArchivedSkillEvaluationStandard?: PickSkillEvaluationStandardType;
};

type ReviewerItemsType =
  Required<PickEmployeeSelectArchivedSkillEvaluationType>;

type PropsType = {
  skillEvaluationStandardSummariesCount: number;
  employeeRoleType: typeof MemberRole.type | typeof ManagerRole.type;
  skillEvaluationTermId: string;
  initialValue: PickEmployeeSelectArchivedSkillEvaluationType[];
  skillEvaluationTermRevieweeComment?: SkillEvaluationTermRevieweeCommentType;
  revieweeEmployeeId: string;
  reviewerEmployeeId?: string;
};

export const useSkillEvaluationCheckForm = ({
  skillEvaluationStandardSummariesCount,
  skillEvaluationTermId,
  employeeRoleType,
  initialValue = [],
  skillEvaluationTermRevieweeComment,
  revieweeEmployeeId,
  reviewerEmployeeId = "",
}: PropsType): ReturnType => {
  const {
    isChecked: isReadOnly,
    setFalse: handleNotReadonly,
    setTrue: handleReadonly,
  } = useBoolean(false);

  const [{ value: revieweeComment, onChange: onChangeRevieweeComment }] =
    useInput(skillEvaluationTermRevieweeComment?.comment || "");

  const {
    items: evaluateItems,
    pushItem: evaluatePushItem,
    findAndRemove: evaluateFindAndRemove,
    setItems: evaluateSetItems,
    findAndReplace: evaluateFindAndReplace,
  } = useArray<PickEmployeeSelectArchivedSkillEvaluationType>(initialValue);

  const { mutate: managerPostRequest } =
    useApisManagersSkillEvaluationTermsEmployeeSelectSkillEvaluationsCreate();
  const { mutate: memberPostRequest } =
    useApisMembersSkillEvaluationTermsEmployeeSelectSkillEvaluationsCreate();

  const revieweeItems = evaluateItems.filter(
    (v) => v.revieweeEmployeeId === revieweeEmployeeId,
  );
  const reviewerItems = evaluateItems.filter(
    (v): v is ReviewerItemsType =>
      Boolean(v.reviewerSelectArchivedSkillEvaluationStandard) &&
      Boolean(v.reviewerEmployeeId),
  );
  const revieweeEvaluated =
    skillEvaluationStandardSummariesCount <= evaluateItems.length;
  const revieweeDisable = Boolean(reviewerItems.length);
  const reviewerDisable = !revieweeEvaluated;

  const memberSelectSkillEvaluationStandard = (
    newSkillEvaluationStand: PickSkillEvaluationStandardType,
  ) => {
    const sameItem = revieweeItems.find(
      (v) =>
        v.revieweeSelectArchivedSkillEvaluationStandard.id ===
        newSkillEvaluationStand.id,
    );
    if (sameItem) {
      removeMemberSkillEvaluationStandard(sameItem);
      return;
    }

    const alreadySameSummarySelected = revieweeItems.find(
      (v) =>
        v.revieweeSelectArchivedSkillEvaluationStandard
          .archivedSkillEvaluationStandardSummaryId ===
        newSkillEvaluationStand.archivedSkillEvaluationStandardSummaryId,
    );
    if (revieweeEvaluated && !alreadySameSummarySelected) return;

    const newEvaluate = {
      ...alreadySameSummarySelected,
      skillEvaluationTermId: skillEvaluationTermId,
      revieweeEmployeeId: revieweeEmployeeId,
      revieweeSelectArchivedSkillEvaluationStandard: newSkillEvaluationStand,
    };
    if (alreadySameSummarySelected) {
      removeMemberSkillEvaluationStandard(alreadySameSummarySelected);
    }
    evaluatePushItem(newEvaluate);
  };

  const managerSelectSkillEvaluationStandard = (
    newSkillEvaluationStand: PickSkillEvaluationStandardType,
  ) => {
    const revieweeSameSummarySelected = revieweeItems.find(
      (v) =>
        v.revieweeSelectArchivedSkillEvaluationStandard
          .archivedSkillEvaluationStandardSummaryId ===
        newSkillEvaluationStand.archivedSkillEvaluationStandardSummaryId,
    );
    if (!revieweeEvaluated || !revieweeSameSummarySelected) {
      toast.error("メンバーの評価がまだありません");
      return;
    }

    const sameItem = reviewerItems.find(
      (v) =>
        v.reviewerSelectArchivedSkillEvaluationStandard?.id ===
        newSkillEvaluationStand.id,
    );

    const newEvaluate = {
      ...revieweeSameSummarySelected,
      reviewerEmployeeId: sameItem ? undefined : reviewerEmployeeId,
      reviewerSelectArchivedSkillEvaluationStandard: sameItem
        ? undefined
        : newSkillEvaluationStand,
    };

    evaluateFindAndReplace(
      newEvaluate,
      (v) =>
        v.revieweeSelectArchivedSkillEvaluationStandard
          .archivedSkillEvaluationStandardSummaryId ===
        newSkillEvaluationStand.archivedSkillEvaluationStandardSummaryId,
    );
  };

  const removeMemberSkillEvaluationStandard = (
    newSkillEvaluationStand: PickEmployeeSelectArchivedSkillEvaluationType,
  ) => {
    evaluateFindAndRemove(
      (v) =>
        v.revieweeSelectArchivedSkillEvaluationStandard.id ===
          newSkillEvaluationStand.revieweeSelectArchivedSkillEvaluationStandard
            .id &&
        v.revieweeEmployeeId === newSkillEvaluationStand.revieweeEmployeeId,
    );
  };

  const memberSave = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!revieweeEvaluated) {
      toast.error(
        `スキルチェックされていない項目があります。${skillEvaluationStandardSummariesCount}個のチェックが必要です。`,
      );
      return;
    }
    saveRequest();
  };

  const managerSave = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!revieweeEvaluated) {
      toast.error(
        `スキルチェックされていない項目があります。${skillEvaluationStandardSummariesCount}個のチェックが必要です。`,
      );
      return;
    }
    saveRequest();
  };

  const saveRequest = () => {
    const body = {
      employeeSelectArchivedSkillEvaluations: evaluateItems.map((item) => ({
        skillEvaluationTermId: item.skillEvaluationTermId,
        revieweeEmployeeId: item.revieweeEmployeeId,
        reviewerEmployeeId: item.reviewerEmployeeId,
        revieweeSelectArchivedSkillEvaluationStandardId:
          item.revieweeSelectArchivedSkillEvaluationStandard.id,
        reviewerSelectArchivedSkillEvaluationStandardId:
          item.reviewerSelectArchivedSkillEvaluationStandard?.id,
      })),
    };

    employeeRoleType === EmployeeRole.MEMBER.type
      ? memberPostRequest(
          {
            skillEvaluationTermId,
            body: {
              ...body,
              skillEvaluationTermRevieweeComment: revieweeComment,
            },
          },
          {
            onSuccess: (data) => {
              toast(data.message);
              handleReadonly();
            },
          },
        )
      : managerPostRequest(
          {
            skillEvaluationTermId,
            body,
          },
          {
            onSuccess: (data) => {
              toast(data.message);
              handleReadonly();
            },
          },
        );
  };

  useEffect(() => {
    evaluateSetItems(initialValue);
  }, [evaluateSetItems, initialValue]);

  return {
    selectedMemberSkillEvaluationStandardIds: revieweeItems.map(
      (v) => v.revieweeSelectArchivedSkillEvaluationStandard.id,
    ),
    selectedManagerSkillEvaluationStandardIds: reviewerItems.map(
      (v) => v.reviewerSelectArchivedSkillEvaluationStandard.id,
    ),
    skillEvaluationStandardSummariesCount,
    revieweeComment,
    onChangeRevieweeComment,
    memberSelectSkillEvaluationStandard,
    managerSelectSkillEvaluationStandard,
    memberSave,
    managerSave,
    revieweeDisable,
    reviewerDisable,
    isReadOnly,
    handleNotReadonly,
  };
};
