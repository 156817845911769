import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersSkillEvaluationStandardSummaryCategoryMutateType } from "~/domains";

type RequestDataType = {
  skillEvaluationStandardSummaryCategoryId: string;
};

const request = ({
  skillEvaluationStandardSummaryCategoryId,
}: RequestDataType) => {
  return new ApiClient()
    .post<ApiManagersSkillEvaluationStandardSummaryCategoryMutateType>(
      `/api/managers/skill_evaluation_standard_summary_categories/${skillEvaluationStandardSummaryCategoryId}/duplicates`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersSkillEvaluationStandardSummaryCategoriesDuplicatesCreate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ApiManagersSkillEvaluationStandardSummaryCategoryMutateType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
