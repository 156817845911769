import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import { ApiMemberInsightPostCommentsShowResponseType } from "~/domains";

type ParamsType = {
  id: string;
};

const request = async ({
  id,
}: ParamsType): Promise<ApiMemberInsightPostCommentsShowResponseType> => {
  const response =
    await new ApiClient().get<ApiMemberInsightPostCommentsShowResponseType>(
      `/api/members/insight_post_comments/${id}`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  id: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersInsightPostCommentsShow = ({
  id,
  config = {},
}: PropsType): UseQueryResult<ApiMemberInsightPostCommentsShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersInsightPostCommentsShow", id],
    queryFn: () => request({ id }),
    useErrorBoundary: (error) => {
      return error instanceof ApiResponseError && error.status >= 500;
    },
  });
};
