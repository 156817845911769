import React, { FC } from "react";

import NotFoundImage from "~/assets/images/404.png";

import { LinkButton } from "~/components/atoms";

type PropsType = {
  className?: string;
};

export const NotFoundContent: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  return (
    <div className={`w-full mx-auto text-center ${className}`}>
      <img src={NotFoundImage as string} alt="404" className="w-full mx-auto" />
      <p className="mt-10 text-lg font-bold">
        お探しのページは見つかりませんでした
      </p>
      <LinkButton
        text="トップページはこちら"
        path="/"
        color="primary"
        className="mt-8 w-full block"
      />
    </div>
  );
};
