import React, { useState, FC, FormEvent, MouseEvent } from "react";

import { toast } from "react-toastify";

import { formatDateTime } from "~/libs";

import {
  useEditorState,
  useFooters,
  useBoolean,
  useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeEffortsCreate,
  useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeEffortsUpdate,
  useApisMembersFocusChallengeEmployeesLikesCreate,
  useApisMembersFocusChallengeEmployeesLikesDestroy,
  useCounter,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import {
  FocusChallengeEmployeeEffortForm,
  FocusChallengeEmployeeDetail,
  FocusChallengeEmployeeLikedEmployeesModal,
} from "~/components/organisms";

import {
  AvatarAndNameEmployeeType,
  FocusChallengeEmployeeType,
  PostDetailMenuItemType,
  PreviewWithFileType,
} from "~/domains";

type PropsType = {
  mentions: AvatarAndNameEmployeeType[];
  focusChallengeEmployee: FocusChallengeEmployeeType;
  menuItems: PostDetailMenuItemType[];
  openReply: (focusChallengeEmployee: FocusChallengeEmployeeType) => void;
  updateItem?: (newItem: FocusChallengeEmployeeType) => void;
  completable: boolean;
  isHighlight?: boolean;
};

export const FocusChallengeEmployeeDetailWithEffort: FC<PropsType> = ({
  mentions,
  focusChallengeEmployee,
  menuItems,
  openReply,
  updateItem,
  completable,
  isHighlight = false,
}: PropsType) => {
  const {
    id,
    employee,
    focusChallengeEmployeeEffort,
    focusChallengeEmployeeStat,
  } = focusChallengeEmployee;

  const [completeEditorState] = useEditorState(
    focusChallengeEmployeeEffort?.content,
  );
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const isCompleted = Boolean(focusChallengeEmployeeEffort?.completedAt);
  const {
    isChecked: isLikedEmployeesModalOpen,
    setTrue: setLikedEmployeesModalOpen,
    setFalse: setLikedEmployeesModalClose,
  } = useBoolean(false);

  const {
    isChecked: isReadOnly,
    setTrue: setReadOnly,
    setFalse: setNotReadOnly,
  } = useBoolean(true);
  const { mutate: postRequest, isLoading: isCreating } =
    useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeEffortsCreate();
  const { mutate: putRequest, isLoading: isUpdating } =
    useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeEffortsUpdate();
  const {
    isChecked: liked,
    setTrue: setLikedTrue,
    setFalse: setLikedFalse,
  } = useBoolean(focusChallengeEmployee.liked);
  const {
    count: likeCount,
    increment: likeIncrement,
    decrement: likeDecrement,
  } = useCounter(focusChallengeEmployeeStat.likesCount);
  const { mutate: likePostRequest } =
    useApisMembersFocusChallengeEmployeesLikesCreate();
  const { mutate: likeDestroyRequest } =
    useApisMembersFocusChallengeEmployeesLikesDestroy();

  const handleLikePostRequest = () => {
    likePostRequest(
      {
        focusChallengeEmployeeId: id,
      },
      {
        onSuccess: () => {
          setLikedTrue();
          likeIncrement();
        },
      },
    );
  };

  const handleLikeDestroyRequest = () => {
    likeDestroyRequest(
      {
        focusChallengeEmployeeId: id,
      },
      {
        onSuccess: () => {
          setLikedFalse();
          likeDecrement();
        },
      },
    );
  };

  const leftFooters = useFooters({
    like: {
      doneAlready: liked,
      undoOnClick: handleLikeDestroyRequest,
      doOnClick: handleLikePostRequest,
      textOnClick: setLikedEmployeesModalOpen,
      count: likeCount,
      disable: false,
    },
    comment: {
      doneAlready: false,
      undoOnClick: () => openReply(focusChallengeEmployee),
      doOnClick: () => openReply(focusChallengeEmployee),
      count: focusChallengeEmployeeStat.repliesCount,
      disable: false,
    },
  });

  const onEffortingSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = generatePostParams(undefined);
    focusChallengeEmployeeEffort
      ? putRequest(
          {
            focusChallengeEmployeeId: id,
            id: focusChallengeEmployeeEffort.id,
            body,
          },
          {
            onSuccess: (data) => {
              updateItem?.(data.focusChallengeEmployee);
              toast(data.message);
              setReadOnly();
              setFiles([]);
            },
          },
        )
      : postRequest(
          {
            focusChallengeEmployeeId: id,
            body,
          },
          {
            onSuccess: (data) => {
              updateItem?.(data.focusChallengeEmployee);
              toast(data.message);
              setReadOnly();
              setFiles([]);
            },
          },
        );
  };

  const onCompleteSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = confirm("完了後は編集できません。完了しますか？");
    if (!result) return;
    const body = generatePostParams(new Date());

    focusChallengeEmployeeEffort
      ? putRequest(
          {
            focusChallengeEmployeeId: id,
            id: focusChallengeEmployeeEffort.id,
            body,
          },
          {
            onSuccess: (data) => {
              updateItem?.(data.focusChallengeEmployee);
              toast(data.message);
              setReadOnly();
              setFiles([]);
            },
          },
        )
      : postRequest(
          {
            focusChallengeEmployeeId: id,
            body,
          },
          {
            onSuccess: (data) => {
              updateItem?.(data.focusChallengeEmployee);
              toast(data.message);
              setReadOnly();
              setFiles([]);
            },
          },
        );
  };

  const generatePostParams = (completedAt: undefined | Date) => {
    return {
      content: completeEditorState.jsonContent,
      attachFiles: files,
      completedAt: completedAt
        ? formatDateTime(completedAt, "yyyy-MM-dd HH:mm:ss")
        : undefined,
    };
  };

  const handleNotReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setNotReadOnly();
  };

  const handleReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setReadOnly();
  };

  return (
    <PostDetailWrapper
      isHighlight={isReadOnly && isHighlight}
      skipHoverStyle={!isReadOnly}
    >
      <FocusChallengeEmployeeDetail
        focusChallengeEmployee={focusChallengeEmployee}
        menuItems={menuItems}
        openReply={openReply}
        withFooter={false}
      />
      <FocusChallengeEmployeeEffortForm
        isSubmitting={isCreating || isUpdating}
        editorState={{
          value: completeEditorState.value,
          onChange: completeEditorState.onChange,
        }}
        avatarUrl={employee.avatarUrl}
        mentions={mentions}
        files={files}
        attachedFiles={focusChallengeEmployeeEffort?.attachFiles || []}
        onChangeFiles={setFiles}
        onEffortingSubmit={onEffortingSubmit}
        onCompleteSubmit={onCompleteSubmit}
        footers={leftFooters.footers}
        className="mt-2"
        completable={!isCompleted && completable}
        readOnly={isReadOnly}
        handleNotReadonly={handleNotReadonly}
        handleReadonly={handleReadonly}
      />
      {isLikedEmployeesModalOpen && (
        <FocusChallengeEmployeeLikedEmployeesModal
          focusChallengeEmployeeId={id}
          onClose={setLikedEmployeesModalClose}
        />
      )}
    </PostDetailWrapper>
  );
};
