import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ManagersEmployeesNewType } from "~/domains";

const request = async (): Promise<ManagersEmployeesNewType> => {
  const response = await new ApiClient().get<ManagersEmployeesNewType>(
    "/api/managers/employees/new",
  );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersEmployeesNew = ({
  config = {},
}: PropsType = {}): UseQueryResult<ManagersEmployeesNewType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersEmployeesNew"],
    queryFn: request,
  });
};
