import React, { FC } from "react";

import { CountDisplayBox } from "~/components/atoms";
import { DoughnutChartBox } from "~/components/molecules";

import { KpiObjectiveDashboardType } from "~/domains";

type PropsType = {
  className?: string;
  kpiObjectiveDashboard: Pick<
    KpiObjectiveDashboardType,
    | "title"
    | "totalPerformance"
    | "objectivePerformance"
    | "unitName"
    | "totalPerformanceRate"
  >;
};

export const KpiDashboardByTeam: FC<PropsType> = ({
  className = "",
  kpiObjectiveDashboard,
}: PropsType) => {
  const {
    title,
    totalPerformance,
    objectivePerformance,
    unitName,
    totalPerformanceRate,
  } = kpiObjectiveDashboard;

  return objectivePerformance ? (
    <DoughnutChartBox
      title={`チーム別: ${title}`}
      doughnut={{
        numerator: totalPerformance,
        denominator: objectivePerformance,
        rate: totalPerformanceRate || 0,
      }}
      unitName={unitName}
      className={className}
      isLoading={false}
    />
  ) : (
    <CountDisplayBox
      title={`チーム別: ${title}`}
      count={totalPerformance}
      unitName={unitName}
      className={className}
    />
  );
};
