import React, { ChangeEvent, MouseEvent, FormEvent, FC } from "react";

import { FileRejection } from "react-dropzone";
import { toast } from "react-toastify";

import { convertToDropdownOption } from "~/utils";

import { FormDocumentFileType, useBoolean } from "~/hooks/";

import { Form, Icon, DropzoneFileField } from "~/components/atoms";
import {
  EditAndFormSubmitButton,
  LabelWithTextField,
  LabelWithMultipleDropdownField,
  FolderIconWithTitle,
  Thumbnail,
} from "~/components/molecules";

import { MultiValueType, OptionType } from "~/domains";

import {
  DOCUMENT_FILE_AVAILABLE_TYPE,
  DOCUMENT_FILE_MAX_BYTE_SIZE,
} from "~/constants/file";

type PropsType = {
  isSubmitting: boolean;
  defaultAccordionOpen: boolean;
  documentFile: FormDocumentFileType;
  selectableDocumentFileTagOptions: OptionType[];
  selectableProvidingServiceOptions: OptionType[];
  onChangeName: (newValue: string) => void;
  onChangeAttachFile: (newValue: File) => void;
  onChangeThumbnailImage: (newValue: File) => void;
  handleTagNameCreate: (newValue: string) => void;
  onChangeTags: (newValue: MultiValueType<OptionType>) => void;
  onChangeProvidingServices: (newValue: MultiValueType<OptionType>) => void;
  removeDocumentFile: () => void;
  saveDocumentFile: () => void;
  onChangeSetNotReadOnly: () => void;
  onChangeSetReadOnly: () => void;
};

export const DocumentFileForm: FC<PropsType> = ({
  isSubmitting,
  defaultAccordionOpen,
  documentFile,
  selectableDocumentFileTagOptions,
  selectableProvidingServiceOptions,
  removeDocumentFile,
  saveDocumentFile,
  onChangeName,
  onChangeAttachFile,
  onChangeThumbnailImage,
  handleTagNameCreate,
  onChangeTags,
  onChangeProvidingServices,
  onChangeSetNotReadOnly,
  onChangeSetReadOnly,
}: PropsType) => {
  const accordionOpen = useBoolean(defaultAccordionOpen);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveDocumentFile();
  };

  const handleNotReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onChangeSetNotReadOnly();
  };

  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    removeDocumentFile();
  };

  const handleAttachFileChange = (
    acceptedFile: File[],
    fileRejections: FileRejection[],
  ) => {
    if (fileRejections.length) {
      fileRejections.map(({ file, errors }) => {
        if (!errors[0]) return;
        toast.error(`${file.name} - ${errors[0].message}`);
      });
      return;
    }
    acceptedFile[0] && onChangeAttachFile(acceptedFile[0]);
  };

  const handleThumbnailImage = (
    acceptedFile: File[],
    fileRejections: FileRejection[],
  ) => {
    if (fileRejections.length) {
      fileRejections.map(({ file, errors }) => {
        if (!errors[0]) return;
        toast.error(`${file.name} - ${errors[0].message}`);
      });
      return;
    }
    acceptedFile[0] && onChangeThumbnailImage(acceptedFile[0]);
  };
  const dropdownTagValues = documentFile.documentFileTags.map((tag) =>
    convertToDropdownOption(tag),
  );
  const dropdownProvidingServiceValues = documentFile.providingServices.map(
    (service) => convertToDropdownOption(service),
  );

  const accordionToggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    accordionOpen.toggle();
  };

  const dropzoneClassName = `${
    documentFile.isReadOnly
      ? "bg-secondary-300"
      : "bg-primary-200 cursor-pointer"
  } rounded-3xl text-gray-500 flex justify-center items-center py-2 px-2`;

  return (
    <Form className="space-y-4" onSubmit={onSubmit}>
      <FolderIconWithTitle
        title={documentFile.name}
        onClick={accordionToggle}
      />
      {accordionOpen.isChecked && (
        <div className="space-y-6 ml-9">
          <LabelWithTextField
            labelText="名前"
            type="text"
            name="name"
            placeholder="名前"
            required
            value={documentFile.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeName(e.target.value)
            }
            disabled={documentFile.isReadOnly}
          />
          <DropzoneFileField
            readonly={documentFile.isReadOnly}
            label="ファイル"
            required
            maxFileByte={DOCUMENT_FILE_MAX_BYTE_SIZE}
            acceptFileTypes={DOCUMENT_FILE_AVAILABLE_TYPE}
            onDropFiles={handleAttachFileChange}
            fileClassName={dropzoneClassName}
          >
            <Icon
              icon="ioImageOutline"
              size="1.25rem"
              className="shrink-0"
              color="text-gray-500"
            />
            <p className="ml-2">
              {documentFile.attachFile.name
                ? documentFile.attachFile.name
                : "PowerPoint / PDF / Word / Excel / JPEG / PNG"}
            </p>
          </DropzoneFileField>
          <DropzoneFileField
            readonly={documentFile.isReadOnly}
            label="サムネイル"
            onDropFiles={handleThumbnailImage}
            fileClassName={dropzoneClassName}
          >
            <Icon
              icon="ioImageOutline"
              size="1.25rem"
              className="shrink-0"
              color="text-gray-500"
            />
            <p className="ml-2">
              {documentFile.thumbnailImage.name
                ? documentFile.thumbnailImage.name
                : "JPEG / PNG"}
            </p>
          </DropzoneFileField>
          <LabelWithMultipleDropdownField
            labelText="サービス"
            name="providingServices"
            options={selectableProvidingServiceOptions}
            value={dropdownProvidingServiceValues}
            onChange={onChangeProvidingServices}
            isDisabled={documentFile.isReadOnly}
          />
          <LabelWithMultipleDropdownField
            labelText="タグ"
            name="documentFileTag"
            options={selectableDocumentFileTagOptions}
            value={dropdownTagValues}
            onChange={onChangeTags}
            onCreateOption={handleTagNameCreate}
            creatable
            isDisabled={documentFile.isReadOnly}
          />
          {documentFile.thumbnailImage.url && (
            <div className="grid grid-cols-2 miniTablet:grid-cols-3 mt-8">
              <Thumbnail
                thumbnailImageUrl={documentFile.thumbnailImage.url}
                attachFile={documentFile.thumbnailImage}
                title={documentFile.name}
                tags={documentFile.providingServices.map(
                  (service) => service.name,
                )}
              />
            </div>
          )}
          <EditAndFormSubmitButton
            isSubmitting={isSubmitting}
            isReadOnly={documentFile.isReadOnly}
            handleNotReadonly={handleNotReadonly}
            handleReadonly={onChangeSetReadOnly}
            handleRemove={handleRemove}
          />
        </div>
      )}
    </Form>
  );
};
