import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersEmployeeSurveysSubmissionSummaryShowResponseType } from "~/domains";

type RequestDataType = {
  employeeSurveyId: string;
  params?: {
    startDate?: string;
    endDate?: string;
    employeeId?: string;
  };
};

const request = async ({
  employeeSurveyId,
  params,
}: RequestDataType): Promise<ApiManagersEmployeeSurveysSubmissionSummaryShowResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersEmployeeSurveysSubmissionSummaryShowResponseType>(
      `/api/managers/employee_surveys/${employeeSurveyId}/submissions_summary`,
      { ...params },
    );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  employeeSurveyId: string;
  params?: {
    startDate?: string;
    endDate?: string;
    employeeId?: string;
  };
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersEmployeeSurveysSubmissionSummaryShow = ({
  employeeSurveyId,
  params,
  config = {},
}: PropsType): UseQueryResult<ApiManagersEmployeeSurveysSubmissionSummaryShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisManagersEmployeeSurveysSubmissionSummaryShow",
      employeeSurveyId,
      params,
    ],
    queryFn: () => request({ employeeSurveyId, params }),
  });
};
