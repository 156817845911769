import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { useApisManagersSkillEvaluationTermsEdit } from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { SkillEvaluationTermForm } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const ManagersSkillEvaluationTermsEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();

  const breadCrumbItems = [
    { label: "スキルチェック", href: "/managers/skill_evaluation_terms" },
    { label: "編集", href: `/managers/skill_evaluation_terms/${id}/edit` },
  ];

  const { data, isError } = useApisManagersSkillEvaluationTermsEdit({
    id,
  });

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        {data && (
          <SkillEvaluationTermForm
            skillEvaluationStandardSummaryCategories={
              data.skillEvaluationStandardSummaryCategories
            }
            skillEvaluationTerm={data.skillEvaluationTerm}
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
