import React, { FC } from "react";

import { Link } from "~/components/atoms";

import { ButtonColor, ButtonSizes } from "~/constants/buttonAndForm";

type PropsType = {
  path: string;
  size?: keyof typeof ButtonSizes;
  color: "primary" | "gray" | "red";
  outline?: boolean;
  text: string;
  className?: string;
};

export const LinkButton: FC<PropsType> = ({
  path,
  size = "md",
  color,
  outline = false,
  text,
  className = "",
}: PropsType) => {
  return (
    <Link
      className={`${ButtonSizes[size]} border border-solid rounded ${
        ButtonColor[outline ? "outline" : "contain"][color]
      } ${className}`}
      to={path}
    >
      {text}
    </Link>
  );
};
