import { MouseEvent, ChangeEvent, FormEvent, useState } from "react";

import { toDateString } from "~/libs";

import {
  useInput,
  useDateRange,
  useApisManagersKpiTermsIndex,
  useArray,
  useDropdown,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
  useApisManagersBelongingEmployeesKpiTermsIndex,
} from "~/hooks";

import { RangeDatePropsType } from "~/components/molecules";

import {
  KpiTermType,
  OptionType,
  ApiManagersKpiTermsIndexRequestType,
  AllPublicStatus,
  BusinessDivisionType,
  ChildType,
  ParentType,
  SingleValueType,
} from "~/domains";

type ReturnType = {
  kpiTerms: KpiTermType[];
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  hasNextPage?: boolean;
  isLoading: boolean;
  selectableDropdownPublicStatusIds: OptionType[];
  selectedDropdownPublicStatusId?: SingleValueType<OptionType>;
  selectableDropdownDivisions: BusinessDivisionType[];
  selectedDropdownDivisions: BusinessDivisionType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  deleteIndexItem: (index: number) => void;
  unshiftItem: (item: KpiTermType) => void;
  findAndRemove: (func: (item: KpiTermType) => boolean) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeDropdownPublicStatusId: (value: SingleValueType<OptionType>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

type PropsType = {
  useApisKpiTerms:
    | typeof useApisManagersKpiTermsIndex
    | typeof useApisManagersBelongingEmployeesKpiTermsIndex;
};

export const useSearchManagersKpiTerms = ({
  useApisKpiTerms,
}: PropsType): ReturnType => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const [
    {
      dropdownValue: selectedDropdownPublicStatusId,
      onChange: onChangeDropdownPublicStatusId,
      dropdownSelectableValue: selectableDropdownPublicStatusIds,
    },
    setDropdownPublicStatus,
  ] = useDropdown({
    selectableValue: AllPublicStatus.map((status) => ({
      name: status.name,
      id: status.id.toString(),
    })),
  });
  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });
  const [params, setParams] = useState<ApiManagersKpiTermsIndexRequestType>();

  const { items, setItems, deleteIndexItem, unshiftItem, findAndRemove } =
    useArray<KpiTermType>([]);

  const { fetchNextPage, hasNextPage, isFetching } = useApisKpiTerms({
    params,
    config: {
      onSuccess: (data) => {
        setItems(data.pages.map((page) => page.kpiTerms).flat());
      },
    },
  });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      businessDivisionIds: selectedDivisions.map((division) => division.id),
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      publicStatusId: selectedDropdownPublicStatusId?.value,
      fromDate: startDate ? toDateString(startDate) : "",
      toDate: endDate ? toDateString(endDate) : "",
    });
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    setDropdownPublicStatus();
    onDivisionChange([]);
    onSectionChange([]);
    onChangeDateRange([null, null]);
    setParams({});
  };

  return {
    kpiTerms: items,
    keyword,
    isLoading: isFetching,
    hasNextPage,
    startDate,
    endDate,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownDivisions: selectableDivisions,
    selectedDropdownDivisions: selectedDivisions,
    selectableDropdownSections: optionSelectableSections,
    selectedDropdownSections: optionSelectedSections,
    onDivisionChange,
    onSectionChange,
    unshiftItem,
    deleteIndexItem,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    fetchNextPage,
    onChangeDateRange,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  };
};
