import React, { FC, MouseEvent } from "react";

import { currentFormatTimestamp } from "~/libs";

import { downloadBlob, arrayToCsv } from "~/utils";

import { Button } from "~/components/atoms";
import { RangeDatePicker } from "~/components/molecules";
import {
  MultiParentsWithMultiChildrenDropdownField,
  ScalableBarGraph,
} from "~/components/organisms";

import {
  BusinessDivisionType,
  ParentType,
  ApiAnalyticsPlansCumulativeRankingsIndexResponseType,
  RangeDatePropsType,
  ChildType,
} from "~/domains";

type PropsType = {
  formattedStartDate: string | null;
  formattedEndDate: string | null;
  selectedDivisions: BusinessDivisionType[];
  optionSelectedSections: ChildType[];
  data?: ApiAnalyticsPlansCumulativeRankingsIndexResponseType;
  className?: string;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onDivisionChange: (newValue: ParentType[]) => void;
  onSectionChange: (newValue: ChildType[]) => void;
  selectableDivisions: BusinessDivisionType[];
  optionSelectableSections: ChildType[];
  selectedDropdownSections: ChildType[];
};

const today = new Date();

export const AnalyticsCumulativeRankingsTemplate: FC<PropsType> = ({
  formattedStartDate,
  formattedEndDate,
  selectedDivisions,
  optionSelectedSections,
  isLoading,
  className = "",
  startDate,
  endDate,
  onChangeDateRange,
  onDivisionChange,
  onSectionChange,
  selectableDivisions,
  optionSelectableSections,
  data,
}: PropsType) => {
  const handleExportCsv = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    downloadBlob(
      arrayToCsv(generateCsvData()),
      `${currentFormatTimestamp}-cumulative_rankings_analysis.csv`,
    );
  };

  const generateCsvData = () => {
    const sections = fetchedSections();
    return [
      ["期間", `${formattedStartDate} 〜 ${formattedEndDate}`],
      ["課", ...sections],
      [
        "インサイト",
        ...sections.map(
          (section) =>
            data?.insightPostRankings.find(
              (ranking) => ranking.name === section,
            )?.postsCount,
        ),
      ],
      [
        "インサイトへのいいね数",
        ...sections.map(
          (section) =>
            data?.insightPostLikeRankings.find(
              (ranking) => ranking.name === section,
            )?.postsCount,
        ),
      ],
      [
        "インサイトへのブックマーク数",
        ...sections.map(
          (section) =>
            data?.insightPostBookmarkRankings.find(
              (ranking) => ranking.name === section,
            )?.postsCount,
        ),
      ],
      [
        "チャレンジ",
        ...sections.map(
          (section) =>
            data?.focusChallengeEmployeeRankings.find(
              (ranking) => ranking.name === section,
            )?.postsCount,
        ),
      ],
      [
        "チャレンジへのいいね数",
        ...sections.map(
          (section) =>
            data?.focusChallengeEmployeeLikeRankings.find(
              (ranking) => ranking.name === section,
            )?.postsCount,
        ),
      ],
      [
        "改善・相談",
        ...sections.map(
          (section) =>
            data?.kaizenIdeaRankings.find((ranking) => ranking.name === section)
              ?.postsCount,
        ),
      ],
      [
        "改善・相談へのいいね数",
        ...sections.map(
          (section) =>
            data?.kaizenIdeaLikeRankings.find(
              (ranking) => ranking.name === section,
            )?.postsCount,
        ),
      ],
    ];
  };

  const fetchedSections = () => {
    if (!data) return [];

    const sectionsArray = [
      ...data.insightPostRankings.map((ranking) => ranking.name),
      ...data.insightPostLikeRankings.map((ranking) => ranking.name),
      ...data.insightPostBookmarkRankings.map((ranking) => ranking.name),
      ...data.focusChallengeEmployeeRankings.map((ranking) => ranking.name),
      ...data.focusChallengeEmployeeLikeRankings.map((ranking) => ranking.name),
      ...data.kaizenIdeaRankings.map((ranking) => ranking.name),
      ...data.kaizenIdeaLikeRankings.map((ranking) => ranking.name),
    ];
    return [...new Set(sectionsArray)].sort();
  };

  return (
    <div className={className}>
      <div className="grid miniTablet:grid-cols-2 gap-4">
        <MultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentLabel="部署"
          parentsOnChange={onDivisionChange}
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childLabel="課"
          childrenOnChange={onSectionChange}
        />
        <RangeDatePicker
          labelText="期間"
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeDateRange}
          maxDate={today}
        />
      </div>
      <div className="mt-6 text-right">
        <Button
          outline
          text="CSVエクスポート"
          onClick={handleExportCsv}
          color="primary"
          readonly={isLoading}
        />
      </div>
      <div className="grid miniTablet:grid-cols-2 gap-6 mt-6">
        <ScalableBarGraph
          isLoading={isLoading}
          perforManceData={
            data?.insightPostRankings.map((post) => ({
              name: post.name,
              value: post.postsCount,
            })) || []
          }
          title="インサイト"
          graphWrapperClassName="h-80"
          graphClassName="w-72 miniTablet:w-full"
        />
        <ScalableBarGraph
          isLoading={isLoading}
          perforManceData={
            data?.insightPostLikeRankings.map((post) => ({
              name: post.name,
              value: post.postsCount,
            })) || []
          }
          title="インサイトへのいいね数"
          graphWrapperClassName="h-80"
          graphClassName="w-72 miniTablet:w-full"
        />
        <ScalableBarGraph
          isLoading={isLoading}
          perforManceData={
            data?.insightPostBookmarkRankings.map((post) => ({
              name: post.name,
              value: post.postsCount,
            })) || []
          }
          title="インサイトへのブクマ数"
          graphWrapperClassName="h-80"
          graphClassName="w-72 miniTablet:w-full"
        />
        <ScalableBarGraph
          isLoading={isLoading}
          perforManceData={
            data?.focusChallengeEmployeeRankings.map((post) => ({
              name: post.name,
              value: post.postsCount,
            })) || []
          }
          title="チャレンジ"
          graphWrapperClassName="h-80"
          graphClassName="w-72 miniTablet:w-full"
        />
        <ScalableBarGraph
          isLoading={isLoading}
          perforManceData={
            data?.focusChallengeEmployeeLikeRankings.map((post) => ({
              name: post.name,
              value: post.postsCount,
            })) || []
          }
          title="チャレンジへのいいね数"
          graphWrapperClassName="h-80"
          graphClassName="w-72 miniTablet:w-full"
        />
        <ScalableBarGraph
          isLoading={isLoading}
          perforManceData={
            data?.kaizenIdeaRankings.map((post) => ({
              name: post.name,
              value: post.postsCount,
            })) || []
          }
          title="改善・相談"
          graphWrapperClassName="h-80"
          graphClassName="w-72 miniTablet:w-full"
        />
        <ScalableBarGraph
          isLoading={isLoading}
          perforManceData={
            data?.kaizenIdeaLikeRankings.map((post) => ({
              name: post.name,
              value: post.postsCount,
            })) || []
          }
          title="改善・相談へのいいね数"
          graphWrapperClassName="h-80"
          graphClassName="w-72 miniTablet:w-full"
        />
        <ScalableBarGraph
          isLoading={isLoading}
          perforManceData={
            data?.sharedInsightPostRankings.map((post) => ({
              name: post.name,
              value: post.postsCount,
            })) || []
          }
          title="ナレッジシェアされた数"
          graphWrapperClassName="h-80"
          graphClassName="w-72 miniTablet:w-full"
        />
      </div>
    </div>
  );
};
