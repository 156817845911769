import React, { FC, MouseEvent } from "react";

import { useBoolean } from "~/hooks";

import { Icon, IconColorType, Link } from "~/components/atoms";

type List = {
  href: string;
  label: string;
};

type Props = {
  title: string;
  buttonClassName?: string;
  lists: List[];
  iconColor: IconColorType;
  isDefaultOpen: boolean;
};

export const Accordion: FC<Props> = ({
  title,
  buttonClassName = "",
  iconColor,
  lists,
  isDefaultOpen = false,
}: Props) => {
  const { isChecked, toggle } = useBoolean(isDefaultOpen);

  const handleToggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggle();
  };

  return (
    <div>
      <button
        type="button"
        className={`flex items-center w-full relative ${buttonClassName}`}
        onClick={handleToggle}
      >
        {isDefaultOpen && (
          <div className="contents before:content-[''] before:w-[5px] before:h-full before:bg-primary-600 before:absolute before:top-0 before:left-0" />
        )}
        <Icon
          icon={isChecked ? "ioCaretDown" : "ioCaretForward"}
          size="1rem"
          color={iconColor}
          className="shrink-0"
        />
        <p className="truncate">{title}</p>
      </button>
      {isChecked && (
        <ul className="ml-8 space-y-1">
          {lists.map((list, index) => (
            <li
              key={`${list.href}-${index}`}
              className="px-2 py-1 hover:bg-primary-300"
            >
              <Link to={list.href} className="block">
                {list.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
