import React, { FC } from "react";

import { FileContentTypeIcon } from "~/components/molecules";

import { ACCEPT_IMAGE_FILE_TYPES } from "~/constants/file";

type PropsType = {
  fileName: string;
  fileContentType: string;
  filePreviewPath: string;
  className?: string;
  imageClassName?: string;
  fileClassName?: string;
};

export const FilePreviewByContentType: FC<PropsType> = ({
  fileName,
  fileContentType,
  filePreviewPath,
  className = "",
  imageClassName = "",
  fileClassName = "",
}: PropsType) => {
  const imageMimeTypes = Object.keys(ACCEPT_IMAGE_FILE_TYPES);

  if (imageMimeTypes.includes(fileContentType)) {
    return (
      <img
        className={`object-cover object-center ${className} ${imageClassName}`}
        src={filePreviewPath}
        alt={`preview ${fileName}`}
      />
    );
  }

  return (
    <FileContentTypeIcon
      contentType={fileContentType}
      className={`${className} ${fileClassName}`}
      iconSize="1rem"
    />
  );
};
