import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useApisManagersQuestionAnswerCategoriesEdit,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { QuestionAnswerCategoryWithQuestionAnswersForm } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const ManagersQuestionAnswerCategoriesEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { currentEmployee } = useProvidersCurrentEmployee();

  const { data: fetchEditData, isError } =
    useApisManagersQuestionAnswerCategoriesEdit({
      id,
    });

  const breadCrumbItems = [
    { label: "Q&A", href: "/managers/question_answer_categories" },
    {
      label: "編集",
      href: `/managers/question_answer_categories/${id}/edit`,
    },
  ];

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        {currentEmployee && fetchEditData && (
          <QuestionAnswerCategoryWithQuestionAnswersForm
            currentEmployee={currentEmployee}
            questionAnswerCategory={fetchEditData.questionAnswerCategory}
            selectableProvidingServices={[
              {
                id: "",
                name: "すべて",
              },
              ...fetchEditData.selectableProvidingServices,
            ]}
            selectableQuestionAnswerTags={
              fetchEditData.selectableQuestionAnswerTags
            }
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
