import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersSkillEvaluationStandardSummaryMutateType } from "~/domains";

type RequestDataType = {
  skillEvaluationStandardSummaryCategoryId: string;
  skillEvaluationStandardSummaryId: string;
};

const request = ({
  skillEvaluationStandardSummaryCategoryId,
  skillEvaluationStandardSummaryId,
}: RequestDataType) => {
  return new ApiClient()
    .post<ApiManagersSkillEvaluationStandardSummaryMutateType>(
      `/api/managers/skill_evaluation_standard_summary_categories/${skillEvaluationStandardSummaryCategoryId}/skill_evaluation_standard_summaries/${skillEvaluationStandardSummaryId}/duplicates`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersSkillEvaluationStandardSummaryCategoriesSkillEvaluationStandardSummariesDuplicatesCreate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ApiManagersSkillEvaluationStandardSummaryMutateType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
