import React, { FC, ReactNode } from "react";

import { MenuType } from "~/hooks";

import { Link } from "~/components/atoms";

type PropsType = {
  menuType: MenuType;
  matchMenu: boolean;
  href?: string;
  selectMenu: (menuType: MenuType) => void;
  children: ReactNode;
};

export const SidebarMenuWrapper: FC<PropsType> = ({
  menuType,
  matchMenu,
  href,
  selectMenu,
  children,
}: PropsType) => {
  const className = `group px-2 py-4 miniTablet:px-4 cursor-pointer block miniTablet:hover:bg-primary-200 ${
    matchMenu ? "miniTablet:bg-primary-200" : ""
  }`;
  return href ? (
    <Link className={className} to={href} onClick={() => selectMenu(menuType)}>
      {children}
    </Link>
  ) : (
    <div className={className} onClick={() => selectMenu(menuType)}>
      {children}
    </div>
  );
};
