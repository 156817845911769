import { useCallback, useState, useMemo } from "react";

import { useDropdown } from "~/hooks";

import {
  ParentType,
  OptionType,
  SingleValueType,
  Completed,
  UnComplete,
} from "~/domains";

type StatusNameType = {
  [key: string]: string;
};

type PropsType = {
  initialValue?: ParentType;
  initialStatusNames?: StatusNameType;
};

type ReturnType = [
  {
    statusValue?: boolean;
    value?: ParentType;
    selectedDropdownCompleteStatusId?: SingleValueType<OptionType>;
    selectableDropdownCompleteStatusIds: OptionType[];
    onChangeDropdownCompleteStatusId: (
      newValue: SingleValueType<OptionType> | null,
    ) => void;
    setStatusNames: (statusNames: StatusNameType) => void;
  },
  (value?: ParentType) => void,
];

export const useCompleteStatusDropdown = ({
  initialValue,
  initialStatusNames = { true: "完了", false: "未完了" },
}: PropsType = {}): ReturnType => {
  const [statusNames, setStatusNames] =
    useState<StatusNameType>(initialStatusNames);

  const CompleteStatus = useMemo(
    () => ({
      true: {
        ...Completed,
        name: statusNames.true || "",
      },
      false: {
        ...UnComplete,
        name: statusNames.false || "",
      },
    }),
    [statusNames],
  );

  const getSelectableValues = useCallback(() => {
    return Object.values(CompleteStatus).map((status) => ({
      ...status,
      label: status.name,
      id: status.id.toString(),
    }));
  }, [CompleteStatus]);

  const [
    {
      dropdownValue: selectedDropdownCompleteStatusId,
      onChange: onChangeDropdownCompleteStatusId,
      dropdownSelectableValue: selectableDropdownCompleteStatusIds,
    },
    setDropdownCompleteStatus,
  ] = useDropdown({
    initialValue: initialValue,
    selectableValue: getSelectableValues(),
  });

  const calcStatusValue = useCallback(() => {
    const id = Number(selectedDropdownCompleteStatusId?.value);
    const selectedStatus = Object.values(CompleteStatus).find(
      (status) => status.id === id,
    );
    return selectedStatus ? selectedStatus.value : undefined;
  }, [selectedDropdownCompleteStatusId, CompleteStatus]);

  const statusValue = calcStatusValue();

  return [
    {
      statusValue,
      selectedDropdownCompleteStatusId,
      onChangeDropdownCompleteStatusId,
      selectableDropdownCompleteStatusIds,
      setStatusNames,
    },
    useCallback(
      (value?: ParentType) => setDropdownCompleteStatus(value),
      [setDropdownCompleteStatus],
    ),
  ];
};
