import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { useApisAdminOperatorsCompaniesEmployeesIndex } from "~/hooks";

import { GridHeaderCells } from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import { GridRow, InfiniteScrollWithMoreButton } from "~/components/molecules";

export const AdminOperatorsCompaniesEmployeesIndex: FC = () => {
  const { companyId = "" } = useParams<{ companyId: string }>();

  const { data, fetchNextPage, hasNextPage } =
    useApisAdminOperatorsCompaniesEmployeesIndex({
      companyId,
    });
  const company = data?.pages[0]?.company;
  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: "/admin_operators",
    },
    {
      label: "会社一覧",
      href: "/admin_operators",
    },
    {
      label: company?.name || "",
      href: `/admin_operators/companies/${companyId}`,
    },
    {
      label: "ユーザー一覧",
      href: `/admin_operators/companies/${companyId}/employees`,
    },
  ];

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      <div className="flex items-center justify-between">
        <h1 className="text-xl">
          <span className="font-bold">{company?.name}</span> のユーザー一覧
        </h1>
      </div>
      <InfiniteScrollWithMoreButton
        itemsLength={data?.pages.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
      >
        <div className="mt-4 grid grid-cols-7">
          <GridHeaderCells
            texts={[
              "id",
              "名前",
              "メール",
              "権限",
              "課",
              "無効日時",
              "作成日時",
            ]}
          />
          {data?.pages.map((page) =>
            page.employees.map((employee) => (
              <GridRow
                key={employee.id}
                lists={[
                  employee.id,
                  employee.name,
                  employee.email,
                  employee.employeeRole.name,
                  employee.businessSections.map((item) => item.name).join(", "),
                  employee.leftAt,
                  employee.createdAt,
                ]}
              />
            )),
          )}
        </div>
      </InfiniteScrollWithMoreButton>
    </AdminOperatorLayout>
  );
};
