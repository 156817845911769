import React, { FC } from "react";

import { startOfMonth, endOfMonth } from "date-fns";

import {
  useApisAnalyticsPlansQuestionAnswerCategoriesIndex,
  useDateRange,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import { AnalyticsPlansCountDisplayBoxes } from "~/components/organisms";

const today = new Date();

export const AnalyticsPlansQuestionAnswerCategoriesIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const breadCrumbItems = [
    { label: "Q&A分析", href: "/analytics_plans/question_answer_categories" },
  ];

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });

  const [
    {
      startDate,
      formattedStartDate,
      endDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange([startOfMonth(today), endOfMonth(today)]);

  const { data, isLoading } =
    useApisAnalyticsPlansQuestionAnswerCategoriesIndex({
      params: {
        businessDivisionIds: selectedDivisions.map((division) => division.id),
        businessSectionIds: optionSelectedSections.map((section) => section.id),
        fromDate: formattedStartDate || undefined,
        toDate: formattedEndDate || undefined,
      },
      config: {
        enabled: !!(formattedStartDate && formattedEndDate),
      },
    });

  return (
    <>
      <EmployeeLayout
        withRightSidebar={false}
        breadCrumbItems={breadCrumbItems}
      >
        <EmployeeLayoutMainContent withRightSidebar={false}>
          <AnalyticsPlansCountDisplayBoxes
            selectableDivisions={selectableDivisions}
            selectedDivisions={selectedDivisions}
            onDivisionChange={onDivisionChange}
            optionSelectableSections={optionSelectableSections}
            optionSelectedSections={optionSelectedSections}
            onSectionChange={onSectionChange}
            startDate={startDate}
            endDate={endDate}
            onChangeDateRange={onChangeDateRange}
            data={
              data
                ? {
                    managersPostsCount: data.managersPostsCount,
                    ownersPostsCount: data.ownersPostsCount,
                    others: [
                      {
                        count: data.postsClicksCount,
                        title: "クリック数",
                      },
                    ],
                  }
                : undefined
            }
            rankings={[
              {
                title: "ユーザー別クリック数",
                data: data?.postsClicksEmployeesRankings,
              },
              {
                title: "Q&A別クリック数",
                data: data?.postsClicksQuestionAnswersRankings,
              },
            ]}
            isLoading={isLoading}
          />
        </EmployeeLayoutMainContent>
      </EmployeeLayout>
    </>
  );
};
