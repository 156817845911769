import React, { FC, FormEvent } from "react";

import { toast } from "react-toastify";

import { useInput, useApisAuthenticatePasswordUpdate } from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { SettingPassword } from "~/components/organisms";

export const MembersSettingPasswordShow: FC = () => {
  const breadCrumbItems = [
    { label: "マイページ", href: "/profile" },
    { label: "編集", href: "/setting/profile" },
    { label: "パスワード編集", href: "/setting/password" },
  ];
  const [{ value: password, onChange: onChangePassword }] = useInput("");
  const [
    { value: passwordConfirmation, onChange: onChangePasswordConfirmation },
  ] = useInput("");

  const { mutate, isLoading } = useApisAuthenticatePasswordUpdate();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      {
        body: {
          password,
          passwordConfirmation,
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
        },
      },
    );
  };

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <SettingPassword
          onSubmit={handleSubmit}
          onChangePassword={onChangePassword}
          onChangePasswordConfirmation={onChangePasswordConfirmation}
          isLoading={isLoading}
          className="mt-0 miniTablet:mt-8"
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
