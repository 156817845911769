import React, { MouseEvent, useState, FC } from "react";

import { QueryObserverResult } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  useBoolean,
  useClipboard,
  useApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsDestroy,
  useApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsUpdate,
  useEditorState,
  useEditorStateLinkContentGetter,
} from "~/hooks";

import {
  KnowledgeInsightPostCommentDetail,
  CommentEditForm,
} from "~/components/organisms";

import {
  KnowledgeInsightPostCommentType,
  PostDetailMenuItemType,
  AvatarAndNameEmployeeType,
  PreviewWithFileType,
  ApiMemberKnowledgeInsightPostKnowledgeInsightPostCommentsIndexType,
} from "~/domains";

import { Href } from "~/constants/postUrl";

type PropsType = {
  isHighlight?: boolean;
  knowledgeInsightPostComment: KnowledgeInsightPostCommentType;
  mentions: AvatarAndNameEmployeeType[];
  refetch: () => Promise<
    QueryObserverResult<
      ApiMemberKnowledgeInsightPostKnowledgeInsightPostCommentsIndexType,
      unknown
    >
  >;
};

export const KnowledgeInsightPostCommentDetailWithEditForm: FC<PropsType> = ({
  knowledgeInsightPostComment,
  refetch,
  mentions,
  isHighlight = false,
}: PropsType) => {
  const { clipboardToCopy } = useClipboard();
  const {
    isChecked: isEditFormOpen,
    setFalse: closeEditForm,
    toggle: toggleEditForm,
  } = useBoolean(false);

  const [editorState] = useEditorState(knowledgeInsightPostComment.content);
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const { fetchedData } = useEditorStateLinkContentGetter({
    watchEditorState: editorState.value,
    isWatch: isEditFormOpen,
  });

  const { mutate: updateRequest, isLoading } =
    useApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsUpdate();

  const { mutate: deleteRequest } =
    useApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsDestroy();

  const deleteInsightPostCommentRequest = () => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        knowledgeInsightPostId:
          knowledgeInsightPostComment.knowledgeInsightPostId,
        id: knowledgeInsightPostComment.id,
      },
      {
        onSuccess: async (data) => {
          toast(data.message);
          await refetch();
        },
      },
    );
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const body = {
      content: editorState.jsonContent,
      attachFiles: files,
      quotePostId: fetchedData?.id,
    };
    updateRequest(
      {
        knowledgeInsightPostId:
          knowledgeInsightPostComment.knowledgeInsightPostId,
        id: knowledgeInsightPostComment.id,
        body,
      },
      {
        onSuccess: async (data) => {
          toast(data.message);
          await refetch().then(() => closeEditForm());
        },
      },
    );
  };

  const detailItems: PostDetailMenuItemType[] = [
    {
      icon: "ioCopyOutline",
      text: "リンクをコピー",
      onClick: () =>
        clipboardToCopy(
          `${location.origin}${Href.KnowledgeInsightPostComment(
            knowledgeInsightPostComment.id,
          )}`,
        ),
      disabled: false,
    },
    {
      icon: "ioCreateOutline",
      text: "編集する",
      onClick: () => toggleEditForm(),
      disabled: !knowledgeInsightPostComment.editable,
    },
    {
      icon: "ioTrashOutline",
      text: "削除する",
      onClick: () => deleteInsightPostCommentRequest(),
      disabled: !knowledgeInsightPostComment.editable,
    },
  ];

  return (
    <div className="" id={knowledgeInsightPostComment.id}>
      {isEditFormOpen ? (
        <CommentEditForm
          submitButton={{
            isSubmitting: isLoading,
            onSubmit: handleSubmit,
          }}
          mentions={mentions}
          post={{
            createdAt: knowledgeInsightPostComment.createdAt,
            employee: knowledgeInsightPostComment.employee,
            content: knowledgeInsightPostComment.content,
          }}
          closeEditForm={closeEditForm}
          files={files}
          setFiles={setFiles}
          editorStateContent={editorState.value}
          setEditorStateContent={editorState.onChange}
          blockQuote={fetchedData}
        />
      ) : (
        <KnowledgeInsightPostCommentDetail
          knowledgeInsightPostComment={knowledgeInsightPostComment}
          menuItems={detailItems}
          isHighlight={isHighlight}
        />
      )}
    </div>
  );
};
