import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersMeetingNotesNewType } from "~/domains";

const request = async (): Promise<ApiMembersMeetingNotesNewType> => {
  const response = await new ApiClient().get<ApiMembersMeetingNotesNewType>(
    "/api/members/meeting_notes/new",
  );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersMeetingNotesNew = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiMembersMeetingNotesNewType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersMeetingNotesNew"],
    queryFn: request,
  });
};
