import { FormEvent, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { useApisAdminOperatorsCompaniesCreate, useInput } from "~/hooks";

type ReturnType = {
  isSubmitting: boolean;
  name: string;
  email: string;
  password: string;
  subdomain: string;
  onChangeName: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeEmail: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangePassword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeSubdomain: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const useComponentsAdminOperatorsCompaniesCreateForm =
  (): ReturnType => {
    const navigate = useNavigate();

    const [{ value: email, onChange: onChangeEmail }] = useInput("");
    const [{ value: password, onChange: onChangePassword }] = useInput("");
    const [{ value: subdomain, onChange: onChangeSubdomain }] = useInput("");
    const [{ value: name, onChange: onChangeName }] = useInput("");

    const { mutate, isLoading: isSubmitting } =
      useApisAdminOperatorsCompaniesCreate();

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      mutate(
        {
          body: {
            email,
            password,
            subdomain,
            name,
          },
        },
        {
          onSuccess: (data) => {
            toast(data.message);
            navigate(`/admin_operators/companies/${data.company.id}`);
          },
        },
      );
    };
    return {
      email,
      password,
      subdomain,
      name,
      isSubmitting,
      onChangeEmail,
      onChangePassword,
      onChangeSubdomain,
      onChangeName,
      handleSubmit,
    };
  };
