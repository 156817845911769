import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { useSearchMembersKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPosts } from "~/hooks";

import { EmployeeLayout } from "~/components/layouts";
import { BookmarkKnowledgeInsightPostsIndexTemplate } from "~/components/templates";

export const MembersBookmarkKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndex: FC =
  () => {
    const { id = "" } = useParams<{ id: string }>();

    const {
      knowledgeInsightPosts,
      startDate,
      endDate,
      hasNextPage,
      isLoading,
      sort,
      selectableDropdownEmployees,
      selectedDropdownEmployees,
      bookmarkFolderId,
      bookmarkFolderName,
      keyword,
      selectableDropdownHasCommentIds,
      selectedDropdownHasCommentId,
      onChangeDropdownHasCommentId,
      onChangeDateRange,
      onChangeKeyword,
      onChangeDropdownEmployees,
      fetchNextPage,
      setSort,
      onSearchSubmit,
      onConditionReset,
      updateIndexKnowledgeInsightPost,
      deleteIndexKnowledgeInsightPost,
    } =
      useSearchMembersKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPosts({
        knowledgeInsightPostBookmarkFolderId: id,
      });
    const breadCrumbItems = [
      {
        label: "上司へのブクマ",
        href: "/bookmark/knowledge_insight_posts",
      },
      {
        label: bookmarkFolderName,
        href: `/bookmark/knowledge_insight_post_bookmark_folders/${bookmarkFolderId}`,
      },
    ];

    return (
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <BookmarkKnowledgeInsightPostsIndexTemplate
          isLoading={isLoading}
          knowledgeInsightPosts={knowledgeInsightPosts}
          startDate={startDate}
          endDate={endDate}
          hasNextPage={hasNextPage}
          sort={sort}
          keyword={keyword}
          selectableDropdownHasCommentIds={selectableDropdownHasCommentIds}
          selectedDropdownHasCommentId={selectedDropdownHasCommentId}
          selectableDropdownEmployees={selectableDropdownEmployees}
          selectedDropdownEmployees={selectedDropdownEmployees}
          onChangeDateRange={onChangeDateRange}
          onChangeKeyword={onChangeKeyword}
          onChangeDropdownHasCommentId={onChangeDropdownHasCommentId}
          onChangeDropdownEmployees={onChangeDropdownEmployees}
          fetchNextPage={fetchNextPage}
          setSort={setSort}
          onSearchSubmit={onSearchSubmit}
          onConditionReset={onConditionReset}
          bookmarkFolderId={bookmarkFolderId}
          bookmarkFolderName={bookmarkFolderName}
          updateIndexKnowledgeInsightPost={updateIndexKnowledgeInsightPost}
          deleteIndexKnowledgeInsightPost={deleteIndexKnowledgeInsightPost}
        />
      </EmployeeLayout>
    );
  };
