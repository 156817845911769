import React, { FC } from "react";

import { useProvidersCurrentEmployee } from "~/hooks";

import { Accordion } from "~/components/molecules";

import { SidebarLink } from "./SidebarLink";

import { ApiMembersUnreadNotificationCountsType } from "~/domains";

const InsightFocusKaizenLists = [
  {
    href: "/managers/insight_focus_kaizen_content_templates/new",
    label: "新規作成",
  },
  { href: "/managers/insight_focus_kaizen_content_templates", label: "一覧" },
];

const FocusLists = [
  { href: "/managers/focus_challenges/new", label: "新規作成" },
  { href: "/managers/focus_challenges", label: "一覧" },
];

const CheckOutLists = [
  { href: "/managers/employee_surveys/new", label: "新規作成" },
  { href: "/managers/employee_surveys", label: "一覧" },
];

const InsightObjectiveLists = [
  { href: "/managers/insight_post_objectives/new", label: "新規作成" },
  { href: "/managers/insight_post_objectives", label: "一覧" },
];

const KpiLists = [
  { href: "/managers/kpi_terms/new", label: "新規作成" },
  { href: "/managers/kpi_terms", label: "一覧" },
];

const DocumentFiles = [
  { href: "/managers/document_file_categories/new", label: "新規作成" },
  { href: "/managers/document_file_categories", label: "一覧" },
  {
    href: "/managers/document_file_category/display_orders",
    label: "並び替え",
  },
];

const RoleplayingConditionTemplateLists = [
  { href: "/managers/roleplaying_condition_templates/new", label: "新規作成" },
  { href: "/managers/roleplaying_condition_templates", label: "一覧" },
];
const RoleplayingConditions = [
  { href: "/managers/roleplaying_conditions/new", label: "新規作成" },
  { href: "/managers/roleplaying_conditions", label: "一覧" },
  {
    href: "/managers/roleplaying_condition/employees/dashboard",
    label: "状況確認",
  },
];

const MeetingNoteTemplateLists = [
  { href: "/managers/meeting_note_templates/new", label: "新規作成" },
  { href: "/managers/meeting_note_templates", label: "一覧" },
];

const MeetingNotes = [
  { href: "/managers/meeting_notes/new", label: "新規作成" },
  { href: "/managers/meeting_notes", label: "一覧" },
];

const QAndALists = [
  { href: "/managers/question_answer_categories/new", label: "新規作成" },
  { href: "/managers/question_answer_categories", label: "一覧" },
  {
    href: "/managers/question_answer_category/display_orders",
    label: "並び替え",
  },
  {
    href: "/managers/private_question_answers",
    label: "メンバーの投稿",
  },
];

const SkillCategoriesLists = [
  {
    href: "/managers/skill_evaluation_standard_summary_categories/new",
    label: "新規作成",
  },
  {
    href: "/managers/skill_evaluation_standard_summary_categories",
    label: "一覧",
  },
  {
    href: "/managers/skill_evaluation_standard_summary_category/display_orders",
    label: "並び替え",
  },
];

const SkillTermsLists = [
  {
    href: "/managers/skill_evaluation_terms/new",
    label: "新規作成",
  },
  {
    href: "/managers/skill_evaluation_terms",
    label: "一覧",
  },
];

const SalesConceptLists = [
  { href: "/managers/sales_concept_categories/new", label: "新規作成" },
  { href: "/managers/sales_concept_categories", label: "一覧" },
  {
    href: "/managers/sales_concept_category/display_orders",
    label: "並び替え",
  },
];

const UserSettingLists = [
  { href: "/managers/employees/new", label: "新規作成" },
  { href: "/managers/employees", label: "一覧" },
  { href: "/managers/left_employees", label: "無効ユーザー" },
];

const BusinessDivisionsLists = [
  { href: "/managers/business_divisions/new", label: "新規作成" },
  { href: "/managers/business_divisions", label: "一覧" },
  { href: "/managers/archived_business_divisions", label: "無効部署" },
];

const BusinessSectionsLists = [
  { href: "/managers/business_sections/new", label: "新規作成" },
  { href: "/managers/business_sections", label: "一覧" },
  { href: "/managers/archived_business_sections", label: "無効課" },
];

const ProvidingServiceLists = [
  { href: "/managers/providing_services/new", label: "新規作成" },
  { href: "/managers/providing_services", label: "一覧" },
  { href: "/managers/archived_providing_services", label: "無効サービス" },
];

const JobCategoryLists = [
  { href: "/managers/job_categories/new", label: "新規作成" },
  { href: "/managers/job_categories", label: "一覧" },
  { href: "/managers/archived_job_categories", label: "無効職種" },
  {
    href: "/managers/archived_job_category_phases",
    label: "無効インサイト項目",
  },
];

type PropsType = {
  breadCrumbText: string;
  unreadNotification?: ApiMembersUnreadNotificationCountsType;
};

export const ManagerSidebar: FC<PropsType> = ({
  breadCrumbText,
  unreadNotification,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const defaultBusinessSectionId =
    currentEmployee?.businessSections[0]?.id || "";
  const navs = [
    {
      label: "インサイト",
      href: "/managers/feedback/insight_posts",
    },
    {
      label: "メンバーメモ",
      href: `/managers/business_section_reviews?business_section_id=${defaultBusinessSectionId}`,
    },
    {
      label: "チャレンジ確認",
      href: "/managers/feedback/focus_challenges",
    },
    {
      label: "チャレンジ作成",
      lists: FocusLists,
    },
    {
      label: "チェックアウト作成",
      lists: CheckOutLists,
    },
    {
      label: "ナレッジシェア",
      href: "/managers/knowledge_post/team",
    },
    {
      label: "本部の発信",
      href: "/managers/knowledge_post/owner",
    },
    {
      label: "部署の発信",
      href: "/managers/knowledge_post/manager",
    },
    {
      label: "各種テンプレ",
      lists: InsightFocusKaizenLists,
    },
    {
      label: "インサイト目標",
      lists: InsightObjectiveLists,
    },
    {
      label: "資料・ツール",
      lists: DocumentFiles,
    },
    {
      label: "スキルマップ",
      lists: SkillCategoriesLists,
    },
    {
      label: "スキルチェック",
      lists: SkillTermsLists,
    },
    {
      label: "ブログ", // 元々は「ブログ」
      lists: SalesConceptLists,
    },
    {
      label: "Q&A",
      lists: QAndALists,
    },
    {
      label: "1on1・MTGテンプレ",
      lists: MeetingNoteTemplateLists,
    },
    {
      label: "1on1・MTG",
      lists: MeetingNotes,
    },
    {
      label: "KPI・KGI",
      lists: KpiLists,
    },
    {
      label: "ロールプレイングテンプレ",
      lists: RoleplayingConditionTemplateLists,
    },
    {
      label: "ロールプレイング",
      lists: RoleplayingConditions,
    },
    {
      label: "改善・相談",
      href: "/managers/feedback/kaizen_ideas",
    },
    {
      label: "",
    },
    {
      label: "ユーザー設定",
      lists: UserSettingLists,
    },
    {
      label: "部署設定",
      lists: BusinessDivisionsLists,
    },
    {
      label: "課設定",
      lists: BusinessSectionsLists,
    },
    {
      label: "サービス設定",
      lists: ProvidingServiceLists,
    },
    {
      label: "職種設定",
      lists: JobCategoryLists,
    },
  ];

  return (
    <>
      {navs.map((nav) =>
        nav.lists ? (
          <Accordion
            key={nav.label}
            buttonClassName={`px-4 py-2 font-medium truncate ${
              breadCrumbText === nav.label
                ? "bg-primary-900 text-white"
                : "hover:bg-primary-300"
            }`}
            iconColor={
              breadCrumbText === nav.label ? "text-white" : "text-secondary-600"
            }
            title={nav.label}
            lists={nav.lists}
            isDefaultOpen={breadCrumbText === nav.label}
          />
        ) : nav.href ? (
          <SidebarLink
            key={nav.label}
            label={nav.label}
            href={nav.href}
            breadCrumbText={breadCrumbText}
            unreadNotification={unreadNotification}
          />
        ) : (
          <hr key={nav.label} className="bg-secondary-300 my-2 mx-4" />
        ),
      )}
    </>
  );
};
