import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  questionAnswerCategoryId: string;
  id: string;
};

const request = ({ id, questionAnswerCategoryId }: RequestDataType) => {
  return new ApiClient()
    .delete<ResponseMessageType>(
      `/api/managers/question_answer_categories/${questionAnswerCategoryId}/question_answers/${id}`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};
export const useApisManagersQuestionAnswerCategoriesQuestionAnswersDestroy = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
