import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersRoleplayingConditionEmployeesIndexResponseType } from "~/domains";

const request =
  async (): Promise<ApiManagersRoleplayingConditionEmployeesIndexResponseType> => {
    const response =
      await new ApiClient().get<ApiManagersRoleplayingConditionEmployeesIndexResponseType>(
        "/api/managers/roleplaying_condition/employees",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersRoleplayingConditionEmployeesIndex = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiManagersRoleplayingConditionEmployeesIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersRoleplayingConditionEmployeesIndex"],
    queryFn: () => request(),
  });
};
