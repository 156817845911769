import { SlideNavigationType } from "~/components/molecules";

type ReturnType = {
  navigations: SlideNavigationType[];
};

type PropsType = {
  selectedArray: [boolean, boolean, boolean];
  businessSectionId?: string;
};

export const useSkillEvaluationStandardSummaryCategorySlideNavigation = ({
  selectedArray,
  businessSectionId = "",
}: PropsType): ReturnType => {
  const navigations = [
    {
      label: "一覧",
      href: `/skill_evaluation_standard_summary_categories?business_section_id=${businessSectionId}`,
      selected: selectedArray[0],
    },
    {
      label: "チェック",
      href: `/skill_evaluation_terms?business_section_id=${businessSectionId}`,
      selected: selectedArray[1],
    },
    {
      label: "結果",
      href: `/skill_evaluation_term/results?business_section_id=${businessSectionId}`,
      selected: selectedArray[2],
    },
  ];

  return {
    navigations: navigations,
  };
};
