import React from "react";
import { createRoot } from "react-dom/client";

import { initializeLogger } from "~/utils";

import { App } from "~/App";

initializeLogger();

const container = document.getElementById("index");
if (!container) throw new Error("Error!");

const root = createRoot(container);
root.render(<App />);
