import React, { useState, FC } from "react";

import {
  useRoleplayingConditionSlideNavigation,
  useApisMembersRoleplayingConditionsNew,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { DatePicker } from "~/components/molecules";
import {
  RoleplayingConditionCurrentEmployeeDailyCountCalendar,
  RoleplayingConditionForm,
} from "~/components/organisms";

export const MembersRoleplayingConditionsNew: FC = () => {
  const [postDate, setPostDate] = useState(new Date());
  const { navigations } = useRoleplayingConditionSlideNavigation([
    true,
    false,
    false,
  ]);

  const { data } = useApisMembersRoleplayingConditionsNew();

  const breadCrumbItems = [
    { label: "ロールプレイング", href: "/roleplaying_conditions/new" },
    { label: "入力", href: "/roleplaying_conditions/new" },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <DatePicker
          selectDate={postDate}
          onChange={setPostDate}
          parentClassName="mt-6"
        />
        <RoleplayingConditionForm
          date={postDate}
          employees={data?.employees || []}
          providingServices={[
            { id: "", name: "すべて" },
            ...(data?.providingServices || []),
          ]}
          className="space-y-6 mt-6"
          roleplayingConditionTemplates={
            data?.roleplayingConditionTemplates || []
          }
          skillEvaluationStandardSummaryCategories={[]}
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarTitle title="Information" />
        <RoleplayingConditionCurrentEmployeeDailyCountCalendar className="mt-6 mx-4" />
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
