import { MouseEvent, useState } from "react";

import { toast } from "react-toastify";

import {
  useApisMembersRoleplayingConditionsEmployeeSelectSkillEvaluationsCreate,
  useApisMembersRoleplayingConditionsSelectSkillEvaluationStandardSummaryCategoriesUpdate,
  useArray,
  useBoolean,
} from "~/hooks";

import {
  RoleplayingConditionType,
  SkillEvaluationStandardSummaryCategoryType,
  ArchivedSkillEvaluationStandardType,
  ArchivedSkillEvaluationStandardSummaryCategoryWithSummariesType,
} from "~/domains";

type ReturnType = {
  selectSkillEvaluationStandardIds: string[];
  selectSkillEvaluationStandard: (
    newSkillEvaluationStand: PickSkillEvaluationStandardType,
  ) => void;
  handleSave: (e: MouseEvent<HTMLButtonElement>) => void;
  skillEvaluationStandardSummaryCategory?: ArchivedSkillEvaluationStandardSummaryCategoryWithSummariesType;
  handleSkillEvaluationStandardSummaryCategory: (
    skillCategory?: SkillEvaluationStandardSummaryCategoryType,
  ) => void;
  handleNotReadonly: (e: MouseEvent<HTMLButtonElement>) => void;
  isReadOnly: boolean;
};

export type PickSkillEvaluationStandardType = Pick<
  ArchivedSkillEvaluationStandardType,
  "id" | "archivedSkillEvaluationStandardSummaryId"
>;

type PropsType = {
  roleplayingCondition: RoleplayingConditionType;
  defaultArchivedSkillEvaluationStandardSummaryCategory?: ArchivedSkillEvaluationStandardSummaryCategoryWithSummariesType;
  defaultArchivedSkillEvaluationStandards: ArchivedSkillEvaluationStandardType[];
};

export const useRoleplayingConditionSkillEvaluationStandardCheck = ({
  roleplayingCondition,
  defaultArchivedSkillEvaluationStandardSummaryCategory,
  defaultArchivedSkillEvaluationStandards,
}: PropsType): ReturnType => {
  const {
    isChecked: isReadOnly,
    setTrue: setReadOnly,
    setFalse: setNotReadOnly,
  } = useBoolean(false);

  const [
    skillEvaluationStandardSummaryCategory,
    setSkillEvaluationStandardSummaryCategory,
  ] = useState<
    ArchivedSkillEvaluationStandardSummaryCategoryWithSummariesType | undefined
  >(defaultArchivedSkillEvaluationStandardSummaryCategory);

  const skillEvaluationStandardSummariesCount =
    skillEvaluationStandardSummaryCategory
      ?.archivedSkillEvaluationStandardSummaries.length || 0;

  const {
    items: evaluateItems,
    pushItem: evaluatePushItem,
    findAndRemove: evaluateFindAndRemove,
    setItems: setEvaluateItems,
  } = useArray<PickSkillEvaluationStandardType>(
    defaultArchivedSkillEvaluationStandards,
  );

  const { mutate: evaluateRequest } =
    useApisMembersRoleplayingConditionsEmployeeSelectSkillEvaluationsCreate();
  const { mutate: selectSkillEvaluationStandardSummaryCategory } =
    useApisMembersRoleplayingConditionsSelectSkillEvaluationStandardSummaryCategoriesUpdate();

  const evaluated =
    skillEvaluationStandardSummariesCount <= evaluateItems.length;

  const selectSkillEvaluationStandard = (
    newSkillEvaluationStand: PickSkillEvaluationStandardType,
  ) => {
    const sameItem = evaluateItems.find(
      (v) => v.id === newSkillEvaluationStand.id,
    );
    if (sameItem) {
      evaluateFindAndRemove((v) => v.id === sameItem.id);
      return;
    }

    const alreadySameSummarySelected = evaluateItems.find(
      (v) =>
        v.archivedSkillEvaluationStandardSummaryId ===
        newSkillEvaluationStand.archivedSkillEvaluationStandardSummaryId,
    );

    if (evaluated && !alreadySameSummarySelected) return;

    if (alreadySameSummarySelected) {
      evaluateFindAndRemove((v) => v.id === alreadySameSummarySelected.id);
    }
    evaluatePushItem(newSkillEvaluationStand);
  };

  const handleSkillEvaluationStandardSummaryCategory = (
    newSkillEvaluationStandardSummaryCategory?: SkillEvaluationStandardSummaryCategoryType,
  ) => {
    selectSkillEvaluationStandardSummaryCategory(
      {
        roleplayingConditionId: roleplayingCondition.id,
        body: {
          skillEvaluationStandardSummaryCategoryId:
            newSkillEvaluationStandardSummaryCategory?.id || "",
        },
      },
      {
        onSuccess: (data) => {
          data &&
            setSkillEvaluationStandardSummaryCategory(
              data.archivedSkillEvaluationStandardSummaryCategory,
            );
          setEvaluateItems([]);
          setNotReadOnly();
        },
      },
    );
  };

  const handleSave = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!evaluated) {
      toast.error(
        `スキルチェックされていない項目があります。${skillEvaluationStandardSummariesCount}個のチェックが必要です。`,
      );
      return;
    }
    saveRequest();
  };

  const saveRequest = () => {
    const body = {
      archivedSkillEvaluationStandardSummaryCategoryId:
        skillEvaluationStandardSummaryCategory?.id || "",
      employeeSelectArchivedSkillEvaluationIds: evaluateItems.map(
        (item) => item.id,
      ),
    };
    evaluateRequest(
      {
        roleplayingConditionId: roleplayingCondition.id,
        body,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setReadOnly();
        },
      },
    );
  };

  const handleNotReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setNotReadOnly();
  };

  return {
    selectSkillEvaluationStandardIds: evaluateItems.map((v) => v.id),
    selectSkillEvaluationStandard,
    handleSave,
    skillEvaluationStandardSummaryCategory,
    handleSkillEvaluationStandardSummaryCategory,
    handleNotReadonly,
    isReadOnly,
  };
};
