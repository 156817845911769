import React, { FC } from "react";

import { GuestSignInRequest } from "~/utils";

import { useApisPartnerConsolesCompaniesIndex } from "~/hooks";

import { GridHeaderCells } from "~/components/atoms";
import { PartnerConsoleLayout } from "~/components/layouts";
import { GridRow, OpenedMenuInGrid } from "~/components/molecules";

export const PartnerConsolesCompaniesIndex: FC = () => {
  const breadCrumbItems = [
    {
      label: "パートナー画面",
      href: "/partner_consoles",
    },
    {
      label: "会社一覧",
      href: "/partner_consoles",
    },
  ];

  const { data } = useApisPartnerConsolesCompaniesIndex();

  const handleGuestLogin = async ({ companyId }: { companyId: string }) => {
    const isConfirm = confirm("ゲストログインをしますか？");
    if (!isConfirm) return;

    await GuestSignInRequest({ companyId });
  };

  return (
    <PartnerConsoleLayout breadCrumbItems={breadCrumbItems}>
      <h1 className="text-xl">会社一覧</h1>
      <div className="mt-4 grid grid-cols-5">
        <GridHeaderCells
          texts={["名前", "サブドメイン", "作成日時", "更新日時", "契約状況"]}
        />
        {data?.companies.map((company) => (
          <GridRow
            key={company.id}
            lists={[
              company.name,
              company.subdomain,
              company.createdAt,
              company.updatedAt,
              company.active ? "契約中" : "未契約",
            ]}
            openedMenu={
              <OpenedMenuInGrid
                cols={5}
                buttons={[
                  {
                    text: "アナリティクス",
                    link: `/partner_consoles/analytics?company_id=${company.id}`,
                  },
                  {
                    text: "ゲストログイン",
                    onClick: () =>
                      handleGuestLogin({
                        companyId: company.id,
                      }),
                  },
                ]}
              />
            }
          />
        ))}
      </div>
    </PartnerConsoleLayout>
  );
};
