import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useApisMembersSkillEvaluationTermsResultPointsIndex,
  useDropdown,
  useProvidersCurrentEmployee,
  useSkillEvaluationStandardSummaryCategorySlideNavigation,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { LabelWithDropDownField } from "~/components/molecules";
import { SkillEvaluationTermDetail } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const MembersSkillEvaluationTermsResultShow: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const { id = "" } = useParams<{ id: string }>();
  const [dropdownBusinessSection] = useDropdown({
    initialValue: currentEmployee?.businessSections[0],
  });
  const { navigations } =
    useSkillEvaluationStandardSummaryCategorySlideNavigation({
      businessSectionId: dropdownBusinessSection.value?.id,
      selectedArray: [false, false, true],
    });

  const breadCrumbItems = [
    {
      label: "スキルマップ",
      href: "/skill_evaluation_standard_summary_categories",
    },
    { label: "結果", href: "/skill_evaluation_term/results" },
    {
      label: "詳細",
      href: `/skill_evaluation_terms/${id}/result`,
    },
  ];
  const { data, isError } = useApisMembersSkillEvaluationTermsResultPointsIndex(
    {
      id,
      params: { businessSectionId: dropdownBusinessSection.value?.id },
    },
  );

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        {data && (
          <>
            <div className="mt-6">
              <LabelWithDropDownField
                labelText="課"
                required
                name="businessSections"
                options={dropdownBusinessSection.convertToDropdown(
                  data.revieweedBusinessSections,
                )}
                value={dropdownBusinessSection.dropdownValue}
                onChange={dropdownBusinessSection.onChange}
              />
            </div>
            <SkillEvaluationTermDetail
              resultPoints={
                data.result
                  ? {
                      totalPoint: data.result.totalPoint,
                      revieweeSumPoint: data.result.revieweeSumPoint,
                      reviewerSumPoint: data.result.reviewerSumPoint,
                      revieweeAveragePoint: data.result.revieweeAveragePoint,
                      reviewerAveragePoint: data.result.reviewerAveragePoint,
                    }
                  : undefined
              }
              skillEvaluationTermEmployeeComment={
                data.skillEvaluationTermEmployeeComment
              }
              skillEvaluationTermRevieweeComment={
                data.skillEvaluationTermRevieweeComment
              }
              skillEvaluationTerm={data.skillEvaluationTerm}
              archivedSkillEvaluationStandardSummaryCategory={
                data.archivedSkillEvaluationStandardSummaryCategory
              }
              revieweeEmployeeId={currentEmployee?.id}
              selectArchivedSkillEvaluationStandards={
                data.employeeSelectArchivedSkillEvaluations
              }
              disabled
              employeeRoleType="member"
              className="mt-6"
            />
          </>
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
