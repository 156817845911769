import React, { FC } from "react";

import {
  useApisMembersMeetingNotesBookmarksCreate,
  useApisMembersMeetingNotesBookmarksDestroy,
  useApisMembersMeetingNotesCompletedsUpdate,
  useApisMembersMeetingNotesDestroy,
  useBoolean,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import { CompletedMarkMessage } from "~/components/molecules";
import { MeetingNoteForm, PostHeader } from "~/components/organisms";

import {
  ApiMembersMeetingNoteMutateType,
  AvatarAndNameEmployeeType,
  MeetingNoteType,
  PostDetailMenuItemType,
  ResponseMessageType,
} from "~/domains";

type PropsType = {
  className?: string;
  meetingNote: MeetingNoteType;
  defaultReadOnly: boolean;
  selectableEmployees?: AvatarAndNameEmployeeType[];
  putRequestOnSuccess: (data: ApiMembersMeetingNoteMutateType) => void;
  deleteRequestOnSuccess: (data: ResponseMessageType) => void;
};

export const MeetingNoteDetail: FC<PropsType> = ({
  className = "",
  meetingNote,
  defaultReadOnly,
  selectableEmployees = [],
  putRequestOnSuccess,
  deleteRequestOnSuccess,
}: PropsType) => {
  const {
    isChecked: isReadOnly,
    setTrue: setReadOnly,
    setFalse: setNotReadOnly,
  } = useBoolean(defaultReadOnly);
  const {
    isChecked: bookmarked,
    setTrue: setBookmarkedTrue,
    setFalse: setBookmarkedFalse,
  } = useBoolean(meetingNote.bookmarked);

  const { isChecked: completed, toggle: toggleCompleted } = useBoolean(
    !!meetingNote.completedAt,
  );

  const { mutate: completeRequest } =
    useApisMembersMeetingNotesCompletedsUpdate();

  const { mutate: deleteRequest } = useApisMembersMeetingNotesDestroy();
  const { mutate: bookmarkDoRequest } =
    useApisMembersMeetingNotesBookmarksCreate();
  const { mutate: bookmarkDeleteDoRequest } =
    useApisMembersMeetingNotesBookmarksDestroy();

  const handleCompleteRequest = () => {
    const isConfirm = confirm("本当に更新にしますか？");
    if (!isConfirm) return;

    completeRequest(
      {
        meetingNoteId: meetingNote.id,
      },
      {
        onSuccess: (data) => {
          putRequestOnSuccess(data);
          toggleCompleted();
        },
      },
    );
  };

  const handleDeleteRequest = () => {
    if (!meetingNote) return;
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: meetingNote.id,
      },
      {
        onSuccess: (data) => {
          deleteRequestOnSuccess?.(data);
        },
      },
    );
  };

  const handlePostBookmark = () => {
    if (!meetingNote) return;
    bookmarkDoRequest(
      {
        meetingNoteId: meetingNote.id,
      },
      {
        onSuccess: () => {
          setBookmarkedTrue();
        },
      },
    );
  };

  const handleDeleteBookmark = () => {
    if (!meetingNote || !bookmarked) return;
    bookmarkDeleteDoRequest(
      {
        meetingNoteId: meetingNote.id,
      },
      {
        onSuccess: () => {
          setBookmarkedFalse();
        },
      },
    );
  };

  const cancelItems: PostDetailMenuItemType[] = [
    {
      icon: completed ? "ioRemoveCircleOutline" : "ioCheckmarkCircleOutline",
      text: completed ? "未完了にする" : "完了済みにする",
      onClick: () => handleCompleteRequest(),
    },
    {
      icon: bookmarked ? "ioBookmark" : "ioBookmarkOutline",
      text: bookmarked ? "ブックマークを外す" : "ブックマークする",
      onClick: () =>
        bookmarked ? handleDeleteBookmark() : handlePostBookmark(),
    },
    {
      icon: "ioTrashOutline",
      text: "削除する",
      onClick: () => handleDeleteRequest(),
    },
    {
      icon: "ioCloseOutline",
      text: "編集をキャンセル",
      onClick: () => setReadOnly(),
    },
  ];

  const menuItems: PostDetailMenuItemType[] = [
    {
      icon: "ioCreateOutline",
      text: "編集する",
      onClick: () => setNotReadOnly(),
    },
    {
      icon: bookmarked ? "ioBookmark" : "ioBookmarkOutline",
      text: bookmarked ? "ブックマークを外す" : "ブックマークする",
      onClick: () =>
        bookmarked ? handleDeleteBookmark() : handlePostBookmark(),
    },
  ];

  const mutateRequestOnSuccess = (data: ApiMembersMeetingNoteMutateType) => {
    putRequestOnSuccess(data);
    setReadOnly();
  };

  return (
    <PostDetailWrapper skipHoverStyle className={className}>
      {completed && <CompletedMarkMessage message="完了済み" />}
      <PostHeader
        miniText={meetingNote.createdAt}
        postedEmployee={meetingNote.employee}
        menuItems={isReadOnly ? menuItems : cancelItems}
        className={completed ? "mt-2" : ""}
      />
      <MeetingNoteForm
        isReadOnly={isReadOnly}
        meetingNote={meetingNote}
        date={new Date(meetingNote.postDate)}
        selectableEmployees={selectableEmployees}
        mutateRequestOnSuccess={mutateRequestOnSuccess}
      />
    </PostDetailWrapper>
  );
};
