import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useApisMembersSalesConceptsPageViewsCreate,
  useApisMembersSalesConceptsShow,
  useSalesConceptCategoriesSlideNavigation,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { SalesConceptDetail } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

import { AllRoles, ManagerRole } from "~/domains";

export const MembersSalesConceptsShow: FC = () => {
  const query = new URLSearchParams(location.search);

  const paramsRole = query.get("employee_role");
  const businessSectionId = query.get("business_section_id") || "";
  const role = AllRoles.find((role) => role.type === paramsRole) || ManagerRole;

  const { id = "" } = useParams<{ id: string }>();
  const { mutate: pageViewRequest } =
    useApisMembersSalesConceptsPageViewsCreate();

  const { data, isError } = useApisMembersSalesConceptsShow({
    id,
    config: {
      onSuccess: () => {
        pageViewRequest({ salesConceptId: id });
      },
    },
  });

  const { navigations } = useSalesConceptCategoriesSlideNavigation({
    selectedRole: role,
    businessSectionId,
  });
  const breadCrumbItems = [
    {
      label: "ブログ",
      href: `/sales_concept_category/${ManagerRole.type}?business_section_id=${businessSectionId}`,
    },
    {
      label: role.name,
      href: `/sales_concept_category/${paramsRole}?business_section_id=${businessSectionId}`,
    },
    {
      label: "詳細",
      href: `/sales_concepts/${id}?employee_role=${paramsRole}&business_section_id=${businessSectionId}`,
    },
  ];

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        {data && (
          <SalesConceptDetail
            salesConcept={data.salesConcept}
            className="mt-6"
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
