import React, { FC } from "react";
import { Outlet } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import Default from "~/assets/images/logos/insight.png";

export const NotRequiredLoginLayout: FC = () => {
  return (
    <>
      <Helmet defer={false}>
        <link rel="icon" type="image/png" href={Default as string} />
      </Helmet>
      <Outlet />
    </>
  );
};
