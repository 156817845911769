import React, { FC } from "react";

import { ButtonSizes, ButtonColor } from "~/constants/buttonAndForm";

type PropsType = {
  value: string;
  size?: keyof typeof ButtonSizes;
  color: "primary" | "gray" | "red";
  outline?: boolean;
  isReadOnly?: boolean;
  className?: string;
};

export const FormSubmitButton: FC<PropsType> = ({
  value,
  color,
  size = "md",
  isReadOnly = false,
  outline = false,
  className = "",
}: PropsType) => {
  return (
    <input
      disabled={isReadOnly}
      type="submit"
      className={`${ButtonSizes[size]} border border-solid rounded ${
        isReadOnly ? "cursor-not-allowed opacity-80" : "cursor-pointer"
      } ${ButtonColor[outline ? "outline" : "contain"][color]} ${className}`}
      value={value}
    />
  );
};
