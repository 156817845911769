import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useApisManagersSkillEvaluationTermsShow,
  useDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { LabelWithDropDownField } from "~/components/molecules";
import { SkillEvaluationTermDetail } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const ManagersSkillEvaluationTermsShow: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const { id = "" } = useParams<{ id: string }>();
  const breadCrumbItems = [
    { label: "スキルチェック", href: "/managers/skill_evaluation_terms" },
    { label: "詳細", href: `/managers/skill_evaluation_terms/${id}` },
  ];

  const [{ dropdownValue, convertToDropdown, onChange }] = useDropdown();
  const { data, isError } = useApisManagersSkillEvaluationTermsShow({
    id,
    params: { employeeId: dropdownValue?.value },
  });

  if (isError) return <NotFoundIndex />;

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          {data && (
            <>
              <LabelWithDropDownField
                name="employees"
                options={convertToDropdown(data.selectableEmployees)}
                value={dropdownValue}
                onChange={onChange}
                labelText="ユーザー"
              />
              <SkillEvaluationTermDetail
                skillEvaluationTerm={data.skillEvaluationTerm}
                archivedSkillEvaluationStandardSummaryCategory={
                  data.archivedSkillEvaluationStandardSummaryCategory
                }
                skillEvaluationTermEmployeeComment={
                  data.skillEvaluationTermEmployeeComment
                }
                revieweeEmployeeId={dropdownValue?.value}
                reviewerEmployeeId={currentEmployee?.id}
                selectArchivedSkillEvaluationStandards={
                  data.employeeSelectArchivedSkillEvaluations
                }
                skillEvaluationTermRevieweeComment={
                  data.skillEvaluationTermRevieweeComment
                }
                employeeRoleType="manager"
                className="mt-6"
              />
            </>
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
