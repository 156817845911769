import React, { useState, FC, MouseEvent } from "react";

import { toast } from "react-toastify";

import {
  useEditorState,
  useBoolean,
  useApisMembersDirectMessageRoomsDirectMessagesDestroy,
  useApisMembersDirectMessageRoomsDirectMessagesUpdate,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import { PostHeader, TextEditor } from "~/components/organisms";

import { DirectMessageDetail } from "./DirectMessageDetail";

import {
  DirectMessageType,
  PostDetailMenuItemType,
  PreviewWithFileType,
} from "~/domains";

type PropsType = {
  directMessage: DirectMessageType;
  className?: string;
  deleteItem: () => void;
  updateItem: (newItem: DirectMessageType) => void;
};

export const DirectMessageDetailWithEditForm: FC<PropsType> = ({
  directMessage,
  className = "",
  deleteItem,
  updateItem,
}: PropsType) => {
  const {
    isChecked: isEditFormOpen,
    setTrue: setEditFormOpen,
    setFalse: setEditFormClose,
  } = useBoolean(false);
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const [{ value, jsonContent, onChange: onEditorChange }] = useEditorState(
    directMessage.content,
  );
  const { mutate: deleteRequest } =
    useApisMembersDirectMessageRoomsDirectMessagesDestroy();
  const { mutate: updateRequest, isLoading: isSubmitting } =
    useApisMembersDirectMessageRoomsDirectMessagesUpdate();

  const handleDeleteRequest = () => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        directMessageRoomId: directMessage.directMessageRoomId,
        id: directMessage.id,
      },
      {
        onSuccess: (data) => {
          deleteItem();
          toast(data.message);
        },
      },
    );
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const body = {
      content: jsonContent,
      attachFiles: files,
    };
    updateRequest(
      {
        directMessageRoomId: directMessage.directMessageRoomId,
        id: directMessage.id,
        body,
      },
      {
        onSuccess: (data) => {
          updateItem(data.directMessage);
          setEditFormClose();
          toast(data.message);
        },
      },
    );
  };

  const detailItems: PostDetailMenuItemType[] = [
    {
      icon: "ioCreateOutline",
      text: "編集する",
      onClick: setEditFormOpen,
      disabled: !directMessage.editable,
    },
    {
      icon: "ioTrashOutline",
      text: "削除する",
      onClick: handleDeleteRequest,
      disabled: !directMessage.editable,
    },
  ];

  const cancelItems = [
    {
      text: "編集をキャンセル",
      onClick: setEditFormClose,
      disabled: !directMessage.editable,
    },
  ];

  return (
    <div className={className}>
      {isEditFormOpen ? (
        <PostDetailWrapper skipHoverStyle>
          <PostHeader
            miniText={directMessage.createdAt}
            postedEmployee={directMessage.employee}
            menuItems={cancelItems}
          />
          <TextEditor
            className="mt-8"
            mentions={[]}
            editorState={value}
            setEditorState={onEditorChange}
            files={files}
            onChangeFiles={setFiles}
            submitButton={{
              isSubmitting: isSubmitting,
              onSubmit: handleSubmit,
            }}
            forBottom
            isEmojiPickerTop
          />
        </PostDetailWrapper>
      ) : (
        <DirectMessageDetail
          directMessage={directMessage}
          menuItems={detailItems}
        />
      )}
    </div>
  );
};
