import React, { ChangeEvent, FC, FormEvent } from "react";

import { Form, FormSubmitButton } from "~/components/atoms";
import {
  LabelWithTextField,
  LabelWithDropDownField,
} from "~/components/molecules";

import { OptionType, SingleValueType } from "~/domains";

type PropsType = {
  isSubmitting: boolean;
  businessSectionName: string;
  onChangeBusinessSectionName: (e: ChangeEvent<HTMLInputElement>) => void;
  selectableBusinessDivisions: OptionType[];
  selectedBusinessDivision?: SingleValueType<OptionType>;
  onChangeBusinessDivision: (newValue: SingleValueType<OptionType>) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const BusinessSectionForm: FC<PropsType> = ({
  isSubmitting,
  businessSectionName,
  onChangeBusinessSectionName,
  selectableBusinessDivisions,
  selectedBusinessDivision,
  onChangeBusinessDivision,
  handleSubmit,
}) => {
  return (
    <Form onSubmit={handleSubmit} className="space-y-6">
      <LabelWithTextField
        labelText="課名"
        type="text"
        name="businessSection"
        required
        placeholder="課名"
        value={businessSectionName}
        onChange={onChangeBusinessSectionName}
      />
      <LabelWithDropDownField
        labelText="部署"
        required
        name="businessDivisions"
        options={selectableBusinessDivisions}
        value={selectedBusinessDivision}
        onChange={onChangeBusinessDivision}
      />
      <FormSubmitButton
        color="primary"
        value="保存"
        className="w-full"
        isReadOnly={isSubmitting}
      />
    </Form>
  );
};
