import React, { FC, useEffect } from "react";

import { useEditorState } from "~/hooks";

import { Editor, PostedFilePreviews } from "~/components/molecules";

import {
  AttachFileType,
  AvatarAndNameEmployeeType,
  RawDraftContentState,
} from "~/domains";

type PropsType = {
  content: RawDraftContentState;
  files: AttachFileType[];
  blockQuote?: {
    content: RawDraftContentState;
    employee: AvatarAndNameEmployeeType;
    files: AttachFileType[];
    createdAt: string;
  };
  className?: string;
};

export const PostContentEditorWithImages: FC<PropsType> = ({
  content,
  files,
  blockQuote,
  className = "",
}: PropsType) => {
  const [{ value, onChange }, setContent] = useEditorState(content);

  useEffect(() => {
    setContent(content);
  }, [content, setContent]);

  return (
    <div className={className}>
      <Editor
        editorState={value}
        onChange={onChange}
        canFileInText={false}
        readOnly
        blockQuote={blockQuote}
      />
      {Boolean(files.length) && (
        <PostedFilePreviews files={files} className="mt-3" />
      )}
    </div>
  );
};
