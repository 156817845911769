import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ResponseMessageType,
  ApiMembersEmployeeSurveysEmployeeSurveySubmissionsMutationRequestType,
} from "~/domains";

type RequestDataType = {
  employeeSurveyId: string;
  body: ApiMembersEmployeeSurveysEmployeeSurveySubmissionsMutationRequestType;
};

const request = ({ employeeSurveyId, body }: RequestDataType) => {
  return new ApiClient()
    .post<ResponseMessageType>(
      `/api/members/employee_surveys/${employeeSurveyId}/employee_survey_submissions`,
      {
        employeeSurveySubmission: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersEmployeeSurveysEmployeeSurveySubmissionsCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
