import React, { FC } from "react";

import { CatchTitleBorder } from "~/components/atoms";
import {
  AnalysisDetailOfPosts,
  AnalysisCumulativeOfPosts,
  RankingInsightPosts,
} from "~/components/organisms";

type PropsType = {
  className?: string;
};

export const ProfilesShowDashboards: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  return (
    <div className={`${className}`}>
      <div>
        <CatchTitleBorder text="ランキング" />
        <RankingInsightPosts className="mt-6" />
      </div>
      <div className="mt-12">
        <CatchTitleBorder text="累計分析" />
        <AnalysisCumulativeOfPosts className="mt-6" />
      </div>
      <div className="mt-12">
        <CatchTitleBorder text="内訳分析" />
        <AnalysisDetailOfPosts className="mt-6" />
      </div>
    </div>
  );
};
