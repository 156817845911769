import React, { ReactNode, FC } from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

type PropsType = {
  children: ReactNode;
};

export const DragDropProvider: FC<PropsType> = ({ children }: PropsType) => {
  return <DndProvider backend={HTML5Backend}>{children}</DndProvider>;
};
