import React, { FC } from "react";

import { LabelWithMultipleDropdownField } from "~/components/molecules";

import {
  convertParentToOptions,
  convertChildToOptions,
  convertOptionToParents,
  convertOptionToChildren,
  selectableChildren,
} from "./CommonFunctions";

import {
  ParentType,
  ChildType,
  OptionType,
  DropDownOnChangeType,
  ChildOptionType,
} from "~/domains";

type PropsType = {
  allParents: ParentType[];
  parentsValue?: ParentType[];
  parentLabel?: string;
  parentRequired?: boolean;
  parentsOnChange: (newValue: ParentType[]) => void;
  allChildren: ChildType[];
  childrenValue?: ChildType[];
  childLabel?: string;
  childRequired?: boolean;
  childrenOnChange: (newValue: ChildType[]) => void;
  isDisabled?: boolean;
};

export const MultiParentsWithMultiChildrenDropdownField: FC<PropsType> = ({
  allParents,
  parentsValue,
  parentLabel = "部署",
  parentRequired = false,
  parentsOnChange,
  allChildren,
  childrenValue,
  childLabel = "課",
  childrenOnChange,
  childRequired = false,
  isDisabled = false,
}: PropsType) => {
  const handleChangeParent = (parents: OptionType[]) => {
    const selectParents = convertOptionToParents(parents);
    parentsOnChange(selectParents);
  };

  const handleChangeChildren = (children: ChildOptionType[]) => {
    const selectChildren = convertOptionToChildren(children);
    childrenOnChange(selectChildren);
  };

  const children = selectableChildren(allChildren, parentsValue);
  const parentDropDownItems = convertParentToOptions(allParents);
  const childDropDownItems = convertChildToOptions(children);

  return (
    <>
      <LabelWithMultipleDropdownField
        required={parentRequired}
        labelText={parentLabel}
        name="parents"
        options={parentDropDownItems}
        value={convertParentToOptions(parentsValue || [])}
        onChange={(newValue: DropDownOnChangeType<OptionType>) =>
          handleChangeParent(newValue as OptionType[])
        }
        isDisabled={isDisabled}
      />
      <LabelWithMultipleDropdownField
        required={childRequired}
        labelText={childLabel}
        name="children"
        options={childDropDownItems}
        value={convertChildToOptions(childrenValue || [])}
        onChange={(newValue: DropDownOnChangeType<OptionType>) =>
          handleChangeChildren(newValue as ChildOptionType[])
        }
        isDisabled={isDisabled}
      />
    </>
  );
};
