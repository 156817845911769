import React, { FC, FormEvent, ChangeEvent } from "react";

import { Form, TextField, ButtonWithIcon } from "~/components/atoms";

type PropsType = {
  onFormClose?: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

export const BookmarkFolderForm: FC<PropsType> = ({
  onFormClose,
  value,
  onChange,
  onSubmit,
  className = "",
}: PropsType) => {
  return (
    <div className={`flex items-center ${className}`}>
      <ButtonWithIcon
        srOnlyText="ブックマークフォルダを追加する"
        onClick={onFormClose}
        icon={{
          icon: "ioAddCircleOutline",
          size: "1rem",
          color: "text-secondary-600",
          className: "block",
        }}
      />
      <Form onSubmit={onSubmit} className="space-y-8 ml-2">
        <TextField
          type="text"
          name="folderName"
          value={value}
          onChange={onChange}
          className={"miniTablet:text-xs bg-gray-50 block"}
          placeholder="新規フォルダ"
        />
      </Form>
    </div>
  );
};
