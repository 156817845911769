import React, { FC, useState, FormEvent, ChangeEvent } from "react";

import { toast } from "react-toastify";

import { uniqueObjectArrayById } from "~/utils";

import {
  useBoolean,
  useEditorState,
  useClipboard,
  useDateRange,
  useDropdown,
  useApisMembersFocusChallengesDestroy,
  useApisMembersFocusChallengesUpdate,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import { PostHeader, FocusChallengeEmployeeForm } from "~/components/organisms";

import { FocusChallengeEmployeeDetailWithEffort } from "./FocusChallengeEmployeeDetailWithEffort";

import {
  FocusChallengeEmployeeType,
  PostDetailMenuItemType,
  PreviewWithFileType,
  JobCategoryPhaseType,
  ProvidingServiceType,
  AvatarAndNameEmployeeType,
  OptionType,
} from "~/domains";

import { Href } from "~/constants/postUrl";

type PropsType = {
  mentions: AvatarAndNameEmployeeType[];
  selectableJobCategoryPhases: JobCategoryPhaseType[];
  selectableProvidingServices: ProvidingServiceType[];
  focusChallengeEmployee: FocusChallengeEmployeeType;
  openReply: (focusChallengeEmployee: FocusChallengeEmployeeType) => void;
  deleteItem?: () => void;
  updateItem?: (newItem: FocusChallengeEmployeeType) => void;
  isHighlight?: boolean;
  className?: string;
};

export const FocusChallengeEmployeeDetailWithEditForm: FC<PropsType> = ({
  mentions,
  selectableJobCategoryPhases,
  selectableProvidingServices,
  focusChallengeEmployee,
  deleteItem,
  updateItem,
  openReply,
  className = "",
  isHighlight = false,
}: PropsType) => {
  const [dateRange] = useDateRange([
    new Date(focusChallengeEmployee.focusChallenge.challengeStartDate),
    new Date(focusChallengeEmployee.focusChallenge.challengeFinishDate),
  ]);

  const openEditForm = useBoolean(false);
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const [editorState] = useEditorState(
    focusChallengeEmployee.focusChallenge.content,
  );
  const { clipboardToCopy } = useClipboard();

  const [providingService] = useDropdown({
    initialValue: {
      id: focusChallengeEmployee.focusChallenge.providingService?.id || "",
      name:
        focusChallengeEmployee.focusChallenge.providingService?.name ||
        "すべて",
    },
    selectableValue: uniqueObjectArrayById(
      focusChallengeEmployee.focusChallenge.providingService
        ? [
            focusChallengeEmployee.focusChallenge.providingService,
            ...(selectableProvidingServices || []),
          ]
        : selectableProvidingServices,
    ),
  });

  const [jobCategoryPhase, setJobCategoryPhase] = useState<OptionType>({
    label:
      focusChallengeEmployee.focusChallenge.jobCategoryPhase?.name || "すべて",
    value: focusChallengeEmployee.focusChallenge.jobCategoryPhase?.id || "",
  });

  const handleChangeJobCategoryPhase = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setJobCategoryPhase({ label: e.target.name, value: e.target.value });
  };

  const { mutate: updateRequest, isLoading: isSubmitting } =
    useApisMembersFocusChallengesUpdate();
  const { mutate: deleteRequest } = useApisMembersFocusChallengesDestroy();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      content: editorState.jsonContent,
      jobCategoryPhaseId: jobCategoryPhase?.value,
      providingServiceId: providingService?.value?.id,
      challengeStartDate: dateRange.formattedStartDate || "",
      challengeFinishDate: dateRange.formattedEndDate || "",
      attachFiles: files,
      focusChallengeEmployeeId: focusChallengeEmployee.id,
    };
    updateRequest(
      {
        id: focusChallengeEmployee.focusChallenge.id,
        body,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          updateItem?.(data.focusChallengeEmployee);
          openEditForm.setFalse();
        },
      },
    );
  };

  const deleteInsightPostRequest = () => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: focusChallengeEmployee.focusChallenge.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          deleteItem?.();
        },
      },
    );
  };

  const editable =
    focusChallengeEmployee.focusChallenge.editable &&
    !focusChallengeEmployee.focusChallengeEmployeeEffort?.completedAt;

  const detailItems: PostDetailMenuItemType[] = [
    {
      icon: "ioCopyOutline",
      text: "リンクをコピー",
      onClick: () =>
        clipboardToCopy(
          `${location.origin}${Href.FocusChallengeEmployee(
            focusChallengeEmployee.id,
          )}`,
        ),
      disabled: false,
    },
    {
      icon: "ioChatbubbleEllipsesOutline",
      text: "リプライする",
      onClick: () => openReply(focusChallengeEmployee),
      disabled: false,
    },
    {
      icon: "ioCreateOutline",
      text: "編集する",
      onClick: openEditForm.setTrue,
      disabled: !editable,
    },
    {
      icon: "ioTrashOutline",
      text: "削除する",
      onClick: deleteInsightPostRequest,
      disabled: !focusChallengeEmployee.focusChallenge.editable,
    },
  ];

  const cancelItems = [
    {
      text: "編集をキャンセル",
      onClick: openEditForm.setFalse,
      disabled: !focusChallengeEmployee.focusChallenge.editable,
    },
  ];

  const selectableJobCategoryPhasesWithArchived = uniqueObjectArrayById(
    focusChallengeEmployee.focusChallenge.jobCategoryPhase
      ? [
          focusChallengeEmployee.focusChallenge.jobCategoryPhase,
          ...(selectableJobCategoryPhases || []),
        ]
      : selectableJobCategoryPhases,
  );

  return (
    <div className={className}>
      {openEditForm.isChecked ? (
        <PostDetailWrapper skipHoverStyle>
          <PostHeader
            miniText={focusChallengeEmployee.focusChallenge.createdAt}
            postedEmployee={focusChallengeEmployee.employee}
            menuItems={cancelItems}
            underLabel={`${
              focusChallengeEmployee.focusChallenge.jobCategory.name
            } - ${
              focusChallengeEmployee.focusChallenge.providingService?.name ||
              "すべて"
            } -
            ${
              focusChallengeEmployee.focusChallenge.jobCategoryPhase?.name ||
              "すべて"
            }`}
          />
          <FocusChallengeEmployeeForm
            isSubmitting={isSubmitting}
            mentions={mentions}
            files={files}
            onChangeFiles={setFiles}
            editorState={editorState.value}
            setEditorState={editorState.onChange}
            selectableProvidingServices={
              providingService.dropdownSelectableValue
            }
            selectedProvidingService={providingService.dropdownValue}
            setProvidingService={providingService.onChange}
            selectableJobCategoryPhases={
              selectableJobCategoryPhasesWithArchived
            }
            selectedJobCategoryPhase={jobCategoryPhase}
            onChangeJobCategoryPhase={handleChangeJobCategoryPhase}
            handleSubmit={handleSubmit}
            submitButtonLabel="保存する"
            date={{
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
              onChange: dateRange.onChange,
            }}
            className="mt-8"
          />
        </PostDetailWrapper>
      ) : (
        <FocusChallengeEmployeeDetailWithEffort
          mentions={mentions}
          focusChallengeEmployee={focusChallengeEmployee}
          menuItems={detailItems}
          openReply={openReply}
          updateItem={updateItem}
          completable={focusChallengeEmployee.completable}
          isHighlight={isHighlight}
        />
      )}
    </div>
  );
};
