import React, { FC } from "react";

import { CatchTitleBorder, Link } from "~/components/atoms";
import { InfiniteScrollWithMoreButton } from "~/components/molecules";

import { EmployeeSurveyType } from "~/domains";

type PropsType = {
  title: string;
  employeeSurveys: EmployeeSurveyType[];
  fetchNextPage: () => void;
  hasMore?: boolean;
  className?: string;
  businessSectionId?: string;
};

export const EmployeeSurveyTitleInfinitiesScroll: FC<PropsType> = ({
  title,
  employeeSurveys,
  hasMore,
  className = "",
  businessSectionId = "",
  fetchNextPage,
}: PropsType) => {
  return (
    <div className={className}>
      <CatchTitleBorder text={title} />
      <InfiniteScrollWithMoreButton
        itemsLength={employeeSurveys.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasMore}
        className="mt-6"
        scrollClassName="space-y-6"
      >
        {employeeSurveys.map((item) => (
          <Link
            key={item.id}
            to={`/employee_surveys/${item.id}?business_section_id=${businessSectionId}`}
            className="block p-4 border border-gray-300 rounded font-semibold text-secondary-600 cursor-pointer"
          >
            {item.title}
          </Link>
        ))}
      </InfiniteScrollWithMoreButton>
    </div>
  );
};
