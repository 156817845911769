import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersRoleplayingConditionTemplatesDestroy,
  useSearchManagersRoleplayingConditionTemplates,
} from "~/hooks";

import {
  Form,
  Button,
  FormSubmitButton,
  GridHeaderCells,
  BooleanDropDownField,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  LabelWithDropDownField,
  LabelWithTextField,
  GridRow,
  OpenedMenuInGrid,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { RoleplayingConditionTemplateType } from "~/domains";

export const ManagersRoleplayingConditionTemplatesIndex: FC = () => {
  const { mutate: deleteRequest } =
    useApisManagersRoleplayingConditionTemplatesDestroy();

  const {
    roleplayingConditionTemplates,
    titleKeyword,
    isLoading,
    hasNextPage,
    allBusinessSectionsDistribution,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    onDivisionChange,
    onSectionChange,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    fetchNextPage,
    onChangeTitleKeyword,
    onConditionReset,
    onSearchSubmit,
    setAllBusinessSectionsDistribution,
  } = useSearchManagersRoleplayingConditionTemplates();
  const breadCrumbItems = [
    {
      label: "ロールプレイングテンプレ",
      href: "/managers/roleplaying_condition_templates",
    },
    { label: "一覧", href: "/managers/roleplaying_condition_templates" },
  ];

  const handleDelete = (
    roleplayingConditionTemplate: RoleplayingConditionTemplateType,
  ) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: roleplayingConditionTemplate.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((item) => item.id === roleplayingConditionTemplate.id);
        },
      },
    );
  };

  const generateRoleplayingConditionTemplateLists = (
    roleplayingConditionTemplate: RoleplayingConditionTemplateType,
  ) => {
    return [
      roleplayingConditionTemplate.title,
      roleplayingConditionTemplate.allBusinessSectionsDistribution
        ? "すべての部署"
        : roleplayingConditionTemplate.businessDivisions
            .map((division) => division.name)
            .join(","),
      roleplayingConditionTemplate.allBusinessSectionsDistribution
        ? "すべての課"
        : roleplayingConditionTemplate.businessSections
            .map((section) => section.name)
            .join(","),
      roleplayingConditionTemplate.publicStatus.name,
    ];
  };

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <Form onSubmit={onSearchSubmit} className="space-y-6">
          <>
            <div className="grid miniTablet:grid-cols-2 gap-4">
              <MultiParentsWithMultiChildrenDropdownField
                allParents={selectableDropdownDivisions}
                parentsValue={selectedDropdownDivisions}
                parentLabel="部署"
                parentsOnChange={onDivisionChange}
                allChildren={selectableDropdownSections}
                childrenValue={selectedDropdownSections}
                childLabel="課"
                childrenOnChange={onSectionChange}
              />
              <BooleanDropDownField
                name="isAllBusinessSections"
                trueLabel="すべての課に配布する"
                falseLabel="個別の課に配布する"
                labelText="配布種別の選択"
                value={allBusinessSectionsDistribution}
                onChange={setAllBusinessSectionsDistribution}
              />
              <LabelWithDropDownField
                labelText="ステータス"
                name="publicStatus"
                options={selectableDropdownPublicStatusIds}
                value={selectedDropdownPublicStatusId}
                onChange={onChangeDropdownPublicStatusId}
              />
              <LabelWithTextField
                labelText="フリーワード"
                type="search"
                name="keyword"
                placeholder="フリーワード検索"
                value={titleKeyword}
                onChange={onChangeTitleKeyword}
              />
            </div>
            <div className="flex items-center justify-end space-x-4">
              <Button
                color="gray"
                outline
                className="w-full miniTablet:w-auto"
                text="リセット"
                onClick={onConditionReset}
              />
              <FormSubmitButton
                value="検索する"
                color="primary"
                className="w-full miniTablet:w-auto"
              />
            </div>
          </>
        </Form>
        <InfiniteScrollWithMoreButton
          itemsLength={roleplayingConditionTemplates.length}
          nextFetchFunction={fetchNextPage}
          isLoading={isLoading}
          hasMore={hasNextPage}
          className="mt-9"
        >
          <div className="grid grid-cols-4">
            <GridHeaderCells texts={["タイトル", "部署", "課", "ステータス"]} />
            {roleplayingConditionTemplates.map(
              (roleplayingConditionTemplate) => (
                <GridRow
                  key={roleplayingConditionTemplate.id}
                  lists={generateRoleplayingConditionTemplateLists(
                    roleplayingConditionTemplate,
                  )}
                  openedMenu={
                    <OpenedMenuInGrid
                      cols={4}
                      buttons={[
                        {
                          text: "編集",
                          link: `/managers/roleplaying_condition_templates/${roleplayingConditionTemplate.id}/edit`,
                        },
                        {
                          text: "削除",
                          onClick: () =>
                            handleDelete(roleplayingConditionTemplate),
                        },
                      ]}
                    />
                  }
                />
              ),
            )}
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
