import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiAnalyticsPlansMeetingNotesIndexResponseType,
  ApiAnalyticsPlansMeetingNotesIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiAnalyticsPlansMeetingNotesIndexRequestType;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApiAnalyticsPlansMeetingNotesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiAnalyticsPlansMeetingNotesIndexResponseType>(
      "/api/analytics_plans/meeting_notes",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiAnalyticsPlansMeetingNotesIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisAnalyticsPlansMeetingNotesIndex = ({
  params,
  config = {},
}: PropsType = {}): UseQueryResult<ApiAnalyticsPlansMeetingNotesIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisAnalyticsPlansMeetingNotesIndex", params],
    queryFn: () => request({ params }),
  });
};
