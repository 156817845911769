import React, { MouseEvent, useState, FC } from "react";

import { QueryObserverResult } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  useBoolean,
  useClipboard,
  useApisMembersInsightPostsInsightPostCommentsDestroy,
  useApisMembersInsightPostsInsightPostCommentsUpdate,
  useEditorState,
  useEditorStateLinkContentGetter,
} from "~/hooks";

import {
  InsightPostCommentDetail,
  CommentEditForm,
} from "~/components/organisms";

import {
  InsightPostCommentType,
  PostDetailMenuItemType,
  AvatarAndNameEmployeeType,
  PreviewWithFileType,
  ApiMemberInsightPostsInsightPostCommentsIndexType,
} from "~/domains";

import { Href } from "~/constants/postUrl";

type PropsType = {
  isHighlight?: boolean;
  insightPostComment: InsightPostCommentType;
  mentions: AvatarAndNameEmployeeType[];
  refetch: () => Promise<
    QueryObserverResult<
      ApiMemberInsightPostsInsightPostCommentsIndexType,
      unknown
    >
  >;
};

export const InsightPostCommentDetailWithEditForm: FC<PropsType> = ({
  isHighlight = false,
  insightPostComment,
  refetch,
  mentions,
}: PropsType) => {
  const { clipboardToCopy } = useClipboard();
  const {
    isChecked: isEditFormOpen,
    setFalse: closeEditForm,
    toggle: toggleEditForm,
  } = useBoolean(false);

  const [editorState] = useEditorState(insightPostComment.content);
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const { fetchedData } = useEditorStateLinkContentGetter({
    watchEditorState: editorState.value,
    isWatch: isEditFormOpen,
  });

  const { mutate: updateRequest, isLoading } =
    useApisMembersInsightPostsInsightPostCommentsUpdate();

  const { mutate: deleteRequest } =
    useApisMembersInsightPostsInsightPostCommentsDestroy();

  const deleteInsightPostCommentRequest = () => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        insightPostId: insightPostComment.insightPostId,
        id: insightPostComment.id,
      },
      {
        onSuccess: async (data) => {
          toast(data.message);
          await refetch();
        },
      },
    );
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const body = {
      content: editorState.jsonContent,
      attachFiles: files,
      quotePostId: fetchedData?.id,
    };
    updateRequest(
      {
        insightPostId: insightPostComment.insightPostId,
        id: insightPostComment.id,
        body,
      },
      {
        onSuccess: async (data) => {
          toast(data.message);
          await refetch().then(() => {
            closeEditForm();
          });
        },
      },
    );
  };

  const detailItems: PostDetailMenuItemType[] = [
    {
      icon: "ioCopyOutline",
      text: "リンクをコピー",
      onClick: () =>
        clipboardToCopy(
          `${location.origin}${Href.InsightPostComment(insightPostComment.id)}`,
        ),
      disabled: false,
    },
    {
      icon: "ioCreateOutline",
      text: "編集する",
      onClick: () => toggleEditForm(),
      disabled: !insightPostComment.editable,
    },
    {
      icon: "ioTrashOutline",
      text: "削除する",
      onClick: () => deleteInsightPostCommentRequest(),
      disabled: !insightPostComment.editable,
    },
  ];

  return (
    <div className="" id={insightPostComment.id}>
      {isEditFormOpen ? (
        <CommentEditForm
          submitButton={{
            isSubmitting: isLoading,
            onSubmit: handleSubmit,
          }}
          mentions={mentions}
          post={{
            createdAt: insightPostComment.createdAt,
            employee: insightPostComment.employee,
            content: insightPostComment.content,
          }}
          closeEditForm={closeEditForm}
          files={files}
          setFiles={setFiles}
          editorStateContent={editorState.value}
          setEditorStateContent={editorState.onChange}
          blockQuote={fetchedData}
        />
      ) : (
        <InsightPostCommentDetail
          insightPostComment={insightPostComment}
          menuItems={detailItems}
          isHighlight={isHighlight}
        />
      )}
    </div>
  );
};
