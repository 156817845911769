import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersSkillEvaluationStandardSummaryMutateType,
  RawDraftContentState,
} from "~/domains";

type RequestDataType = {
  skillEvaluationStandardSummaryCategoryId: string;
  body: {
    title: string;
    content: RawDraftContentState;
    displayOrder: number;
    skillEvaluationStandards: {
      title: string;
      description: string;
      point: number;
    }[];
  };
};

const request = ({
  skillEvaluationStandardSummaryCategoryId,
  body,
}: RequestDataType) => {
  return new ApiClient()
    .post<ApiManagersSkillEvaluationStandardSummaryMutateType>(
      `/api/managers/skill_evaluation_standard_summary_categories/${skillEvaluationStandardSummaryCategoryId}/skill_evaluation_standard_summaries`,
      { skillEvaluationStandardSummary: body },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagerSkillEvaluationStandardSummaryCategoriesSkillEvaluationStandardSummariesCreate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ApiManagersSkillEvaluationStandardSummaryMutateType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
