import React, { FormEvent, useState, FC, ChangeEvent } from "react";

import { toast } from "react-toastify";

import {
  useDateRange,
  useDropdown,
  useEditorState,
  useRightSidebarState,
  useApisMembersFocusChallengesNew,
  useSearchMembersFocusChallengeEmployees,
  useApisMembersFocusChallengesCreate,
  useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesIndex,
  useInterval,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  RightSidebarToggleIconWithLabel,
  InfiniteScroll,
  FilterIconWithSortMenu,
  TemplateSelectButtonWithModal,
} from "~/components/molecules";
import {
  FocusChallengeEmployeeForm,
  FocusChallengeEmployeeDetailWithEditForm,
  FocusChallengeEmployeeRepliesWithFocusChallengeEmployee,
  FocusChallengeEmployeeSearchForm,
} from "~/components/organisms";
import { FocusChallengeEmployeeEffortCompletesCountCalendar } from "~/components/organisms/FocusChallengeEmployeeEffortCompletesCountCalendar/FocusChallengeEmployeeEffortCompletesCountCalendar";

import {
  FocusChallengeEmployeeType,
  InsightFocusKaizenContentTemplateType,
  OptionType,
  PreviewWithFileType,
} from "~/domains";

export const MembersFocusChallengesIndex: FC = () => {
  const {
    isSearchOpen,
    isReplyOpen,
    setReplyOpen,
    searchCalendarOpenToggle,
    setCalendarOpen,
    isOpen,
    isCalendarOpen,
    setClose,
    currentOpenLabel,
  } = useRightSidebarState("isCalendarOpen");
  const [selectedTemplate, setSelectedTemplate] =
    useState<
      Pick<InsightFocusKaizenContentTemplateType, "id" | "title" | "content">
    >();
  const [editorState, setEditorState] = useEditorState();
  const [selectFocusChallengeEmployee, setSelectFocusChallengeEmployee] =
    useState<FocusChallengeEmployeeType>();

  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const [postDateRange, setPostDateRange] = useDateRange([null, null]);
  const { data: fetchNewData } = useApisMembersFocusChallengesNew();

  const [providingService, setProvidingService] = useDropdown({
    selectableValue: fetchNewData?.selectableProvidingServices,
  });
  const [jobCategoryPhase, setJobCategoryPhase] = useState<OptionType>({
    label: "すべて",
    value: "",
  });

  const {
    focusChallengeEmployees,
    keyword,
    isLoading,
    selectableDropdownCompleteStatusIds,
    selectedDropdownCompleteStatusId,
    selectableDropdownHasReplyIds,
    selectedDropdownHasReplyId,
    hasNextPage,
    startDate,
    endDate,
    sort,
    selectableJobCategoryPhases,
    selectedJobCategoryPhases,
    selectableDropdownProvidingServices,
    selectedDropdownProvidingServices,
    unshiftFocusChallengeEmployee,
    updateIndexFocusChallengeEmployee,
    deleteIndexFocusChallengeEmployee,
    fetchNextPage,
    setSort,
    onChangeJobCategoryPhases,
    onChangeDropdownProvidingServices,
    onChangeDateRange,
    onChangeKeyword,
    onChangeDropdownCompleteStatusId,
    onChangeDropdownHasReplyId,
    onSearchSubmit,
    onConditionReset,
  } = useSearchMembersFocusChallengeEmployees();

  const { mutate: createRequest, isLoading: isSubmitting } =
    useApisMembersFocusChallengesCreate();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      content: editorState.jsonContent,
      providingServiceId: providingService?.value?.id,
      jobCategoryPhaseId: jobCategoryPhase?.value,
      challengeStartDate: postDateRange.formattedStartDate || "",
      challengeFinishDate: postDateRange.formattedEndDate || "",
      attachFiles: files,
    };
    createRequest(
      {
        body,
      },
      {
        onSuccess: (data) => {
          unshiftFocusChallengeEmployee(data.focusChallengeEmployee);
          toast(data?.message);
          formReset();
        },
      },
    );
  };

  const { data: replayFetch, refetch } =
    useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesIndex({
      focusChallengeEmployeeId: selectFocusChallengeEmployee?.id || "",
      config: {
        enabled: !!selectFocusChallengeEmployee?.id,
      },
    });

  useInterval({
    onUpdate: async () => {
      isReplyOpen && selectFocusChallengeEmployee && (await refetch());
    },
    intervalMs: 1000,
  });

  const handleChangeJobCategoryPhase = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setJobCategoryPhase({ label: e.target.name, value: e.target.value });
  };

  const openReply = (focusChallengeEmployee: FocusChallengeEmployeeType) => {
    setSelectFocusChallengeEmployee(focusChallengeEmployee);
    setReplyOpen();
  };

  const formReset = () => {
    setFiles([]);
    editorState.setPlainText("");
    setPostDateRange([null, null]);
    setSelectedTemplate(undefined);
    setProvidingService(undefined);
    setJobCategoryPhase({ label: "すべて", value: "" });
  };

  const breadCrumbItems = [{ label: "チャレンジ", href: "/focus_challenges" }];

  const mentions = fetchNewData?.mentionableEmployees || [];

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
          {!isSearchOpen && (
            <>
              <TemplateSelectButtonWithModal
                templates={
                  fetchNewData?.insightFocusKaizenContentTemplates || []
                }
                setEditorState={setEditorState}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                className="mt-6"
              />
              <FocusChallengeEmployeeForm
                isSubmitting={isSubmitting}
                mentions={mentions}
                files={files}
                onChangeFiles={setFiles}
                editorState={editorState.value}
                setEditorState={editorState.onChange}
                selectableProvidingServices={
                  providingService.dropdownSelectableValue
                }
                setProvidingService={providingService.onChange}
                selectableJobCategoryPhases={
                  fetchNewData?.selectableJobCategoryPhases || []
                }
                selectedProvidingService={providingService.dropdownValue}
                selectedJobCategoryPhase={jobCategoryPhase}
                onChangeJobCategoryPhase={handleChangeJobCategoryPhase}
                date={{
                  startDate: postDateRange.startDate,
                  endDate: postDateRange.endDate,
                  onChange: postDateRange.onChange,
                }}
                handleSubmit={onSubmit}
                className="mt-6"
              />
            </>
          )}
          <FilterIconWithSortMenu
            likeSort={() => setSort("likeCountDesc")}
            createdAtAscSort={() => setSort("createdAtAsc")}
            createdAtDescSort={() => setSort("createdAtDesc")}
            currentSortType={sort}
            className={isSearchOpen ? "mt-6" : "mt-12"}
          />
          <InfiniteScroll
            itemsLength={focusChallengeEmployees.length}
            nextFetchFunction={fetchNextPage}
            hasMore={hasNextPage}
            isLoading={isLoading}
            className="border-t-2 border-secondary-200"
          >
            {focusChallengeEmployees.map((post, index) => (
              <FocusChallengeEmployeeDetailWithEditForm
                key={post.id}
                mentions={mentions}
                selectableProvidingServices={
                  fetchNewData?.selectableProvidingServices || []
                }
                selectableJobCategoryPhases={
                  fetchNewData?.selectableJobCategoryPhases || []
                }
                focusChallengeEmployee={post}
                deleteItem={() => {
                  deleteIndexFocusChallengeEmployee(index);
                  if (selectFocusChallengeEmployee?.id === post.id) {
                    setSelectFocusChallengeEmployee(undefined);
                    setClose();
                  }
                }}
                updateItem={(newItem: FocusChallengeEmployeeType) =>
                  updateIndexFocusChallengeEmployee(newItem, index)
                }
                openReply={openReply}
              />
            ))}
          </InfiniteScroll>
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          <>
            <RightSidebarToggleIconWithLabel
              label={currentOpenLabel}
              iconOnClick={searchCalendarOpenToggle}
              handleOnClose={isCalendarOpen ? undefined : setCalendarOpen}
              isOpen={isOpen}
            >
              {isCalendarOpen && (
                <FocusChallengeEmployeeEffortCompletesCountCalendar className="mt-6" />
              )}
              {isSearchOpen && (
                <FocusChallengeEmployeeSearchForm
                  onSubmit={onSearchSubmit}
                  onConditionReset={onConditionReset}
                  date={{
                    startDate: startDate,
                    endDate: endDate,
                    onChange: onChangeDateRange,
                  }}
                  providingService={{
                    options: selectableDropdownProvidingServices,
                    value: selectedDropdownProvidingServices,
                    onChange: onChangeDropdownProvidingServices,
                  }}
                  jobCategoryPhase={{
                    options: selectableJobCategoryPhases,
                    value: selectedJobCategoryPhases,
                    onChange: onChangeJobCategoryPhases,
                  }}
                  hasReply={{
                    options: selectableDropdownHasReplyIds,
                    value: selectedDropdownHasReplyId,
                    onChange: onChangeDropdownHasReplyId,
                  }}
                  focusCompleted={{
                    options: selectableDropdownCompleteStatusIds,
                    value: selectedDropdownCompleteStatusId,
                    onChange: onChangeDropdownCompleteStatusId,
                  }}
                  searchKeyword={{
                    value: keyword,
                    onChange: onChangeKeyword,
                  }}
                />
              )}
              {isReplyOpen && selectFocusChallengeEmployee && (
                <FocusChallengeEmployeeRepliesWithFocusChallengeEmployee
                  className="mt-6"
                  mentions={mentions}
                  focusChallengeEmployee={selectFocusChallengeEmployee}
                  openReply={openReply}
                  resetPageFetch={refetch}
                  focusChallengeReplies={
                    replayFetch?.focusChallengeEmployeeReplies || []
                  }
                />
              )}
            </RightSidebarToggleIconWithLabel>
          </>
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
