import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import {
  ApiManagersSalesConceptMutateRequestType,
  ApiManagersSalesConceptMutateType,
} from "~/domains";

type RequestDataType = {
  salesConceptCategoryId: string;
  id: string;
  body: ApiManagersSalesConceptMutateRequestType;
};

const request = ({ id, salesConceptCategoryId, body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ salesConcept: body });
  return new ApiClient()
    .put<ApiManagersSalesConceptMutateType>(
      `/api/managers/sales_concept_categories/${salesConceptCategoryId}/sales_concepts/${id}`,
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersSalesConceptCategoriesSalesConceptsUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersSalesConceptMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
