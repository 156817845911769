import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersJobCategoriesIndexResponseType,
  ApiManagersJobCategoriesIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiManagersJobCategoriesIndexRequestType;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApiManagersJobCategoriesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersJobCategoriesIndexResponseType>(
      "/api/managers/job_categories",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiManagersJobCategoriesIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersJobCategoriesIndex = ({
  params,
  config = {},
}: PropsType = {}): UseQueryResult<ApiManagersJobCategoriesIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersJobCategoriesIndex", params],
    queryFn: () => request({ params }),
  });
};
