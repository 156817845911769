import React, { FC, FormEvent, ChangeEvent, MouseEvent } from "react";

import {
  Button,
  Form,
  FormSubmitButton,
  MultipleDropDownField,
  TextField,
} from "~/components/atoms";
import { TitleWithChild } from "~/components/molecules";

import { MultiValueType, OptionType } from "~/domains";

type PropsType = {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  searchKeyword: {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  tag: {
    option: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
  providingService: {
    option: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
};

export const SalesConceptSearchForm: FC<PropsType> = ({
  onSubmit,
  onConditionReset,
  searchKeyword,
  tag,
  providingService,
}: PropsType) => {
  return (
    <Form onSubmit={onSubmit} className="mt-6 space-y-6">
      <>
        <TitleWithChild title="フリーワード">
          <TextField
            placeholder="キーワード"
            type="search"
            name="keyword"
            value={searchKeyword.value}
            onChange={searchKeyword.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="サービス">
          <MultipleDropDownField
            name="salesConceptProvidingService"
            options={providingService.option}
            value={providingService.value}
            onChange={providingService.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="タグ">
          <MultipleDropDownField
            name="salesConceptTag"
            options={tag.option}
            value={tag.value}
            onChange={tag.onChange}
          />
        </TitleWithChild>
        <div className="space-x-4 flex">
          <Button
            text="リセット"
            onClick={onConditionReset}
            color="gray"
            outline
            className="w-full"
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full"
          />
        </div>
      </>
    </Form>
  );
};
