import React, { FC } from "react";

const SIZE_CLASSES = {
  xs: { class: "w-8 h-8", width: "32", height: "32" },
  sm: { class: "w-10 h-10", width: "40", height: "40" },
  md: { class: "w-12 h-12", width: "48", height: "48" },
  lg: { class: "w-32 h-32", width: "128", height: "128" },
};

type PropsType = {
  className?: string;
  src: string;
  size: keyof typeof SIZE_CLASSES;
  withRing?: boolean;
};

export const Avatar: FC<PropsType> = ({
  className = "",
  src,
  size,
  withRing = false,
}: PropsType) => {
  return (
    <div className={`shrink-0 ${SIZE_CLASSES[size].class}`}>
      <img
        src={src}
        width={SIZE_CLASSES[size].width}
        height={SIZE_CLASSES[size].height}
        alt="avatar"
        className={`object-cover max-w-full w-full h-full rounded-full ${withRing ? "ring-2 ring-primary-800" : ""} ${className}`}
      />
    </div>
  );
};
