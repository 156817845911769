import React, { FC, FormEvent, useState } from "react";

import { toast } from "react-toastify";

import {
  useApisMembersPrivatePersonalMemosDestroy,
  useApisMembersPrivatePersonalMemosUpdate,
  useBoolean,
  useEditorState,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import {
  PrivatePersonalMemosForm,
  PostContentEditorWithImages,
  PostHeader,
} from "~/components/organisms";

import {
  PrivatePersonalMemoType,
  PostDetailMenuItemType,
  PreviewWithFileType,
} from "~/domains";

type PropsType = {
  privatePersonalMemo: PrivatePersonalMemoType;
  updateIndexPost: (newItem: PrivatePersonalMemoType) => void;
  deleteIndexPost: () => void;
  className?: string;
};

export const PrivatePersonalMemosDetailWithEditForm: FC<PropsType> = ({
  privatePersonalMemo,
  updateIndexPost,
  deleteIndexPost,
  className,
}: PropsType) => {
  const { isChecked, setTrue, setFalse } = useBoolean(false);
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const [
    {
      jsonContent: editorStateJsonContent,
      value: editorState,
      onChange: onChangeEditorState,
    },
  ] = useEditorState(privatePersonalMemo.content);

  const { mutate: updateRequest, isLoading: isUpdateSubmitting } =
    useApisMembersPrivatePersonalMemosUpdate();
  const { mutate: deleteRequest } = useApisMembersPrivatePersonalMemosDestroy();

  const handleUpdateRequest = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    updateRequest(
      {
        id: privatePersonalMemo.id,
        body: {
          content: editorStateJsonContent,
          ...(files.length > 0 ? { attachFiles: files } : {}),
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          updateIndexPost(data.privatePersonalMemo);
          setFalse();
        },
      },
    );
  };

  const handleDeleteRequest = () => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: privatePersonalMemo.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          deleteIndexPost();
        },
      },
    );
  };

  const cancelItems = [
    {
      text: "編集をキャンセル",
      onClick: () => setFalse(),
    },
  ];

  const menuItems: PostDetailMenuItemType[] = [
    {
      icon: "ioCreateOutline",
      text: "編集する",
      onClick: () => setTrue(),
    },
    {
      icon: "ioTrashOutline",
      text: "削除する",
      onClick: () => handleDeleteRequest(),
    },
  ];

  return (
    <div className={`${className}`}>
      {isChecked ? (
        <PostDetailWrapper skipHoverStyle>
          <PostHeader
            miniText={privatePersonalMemo.createdAt}
            postedEmployee={privatePersonalMemo.employee}
            menuItems={cancelItems}
          />
          <PrivatePersonalMemosForm
            editorState={editorState}
            onChangeEditorState={onChangeEditorState}
            handleSubmit={handleUpdateRequest}
            isSubmitting={isUpdateSubmitting}
            files={files}
            onChangeFiles={setFiles}
            className="mt-1 pl-16"
          />
        </PostDetailWrapper>
      ) : (
        <PostDetailWrapper>
          <PostHeader
            miniText={privatePersonalMemo.createdAt}
            postedEmployee={privatePersonalMemo.employee}
            menuItems={menuItems}
          />
          <PostContentEditorWithImages
            content={privatePersonalMemo.content}
            files={privatePersonalMemo.attachFiles}
            className="mt-1 pl-16"
          />
        </PostDetailWrapper>
      )}
    </div>
  );
};
