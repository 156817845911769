import React, { ChangeEvent, MouseEvent, FC } from "react";

import { FormSkillEvaluationStandardType } from "~/hooks";

import { TextArea, TextField, NumberField, Button } from "~/components/atoms";

type PropsType = {
  skillEvaluationStandard: FormSkillEvaluationStandardType;
  onChangeSkillEvaluationStandardTitle: (newValue: string) => void;
  onChangeSkillEvaluationStandardDescription: (newValue: string) => void;
  onChangeSkillEvaluationStandardPoint: (newValue: number) => void;
  remove: () => void;
  className?: string;
  readonly: boolean;
};

export const SkillEvaluationStandardForm: FC<PropsType> = ({
  skillEvaluationStandard,
  remove,
  onChangeSkillEvaluationStandardTitle,
  onChangeSkillEvaluationStandardDescription,
  onChangeSkillEvaluationStandardPoint,
  className = "",
  readonly,
}: PropsType) => {
  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    remove();
  };

  return (
    <div className={`min-w-[13rem] max-w-[13rem] space-y-4 ${className}`}>
      <NumberField
        name="point"
        placeholder="ポイント"
        required
        min={0}
        max={100}
        disabled={readonly}
        value={skillEvaluationStandard.point}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChangeSkillEvaluationStandardPoint(parseInt(e.target.value))
        }
      />
      <TextField
        type="text"
        name="title"
        placeholder="タイトル"
        required
        disabled={readonly}
        value={skillEvaluationStandard.title}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChangeSkillEvaluationStandardTitle(e.target.value)
        }
      />
      <TextArea
        name="description"
        placeholder="内容"
        required
        rows={10}
        disabled={readonly}
        value={skillEvaluationStandard.description}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          onChangeSkillEvaluationStandardDescription(e.target.value)
        }
      />
      {!readonly && (
        <div className="flex justify-end">
          <Button
            size="sm"
            text="削除"
            onClick={handleRemove}
            color="gray"
            outline
            readonly={readonly}
          />
        </div>
      )}
    </div>
  );
};
