import React, { FC, MouseEvent } from "react";

import { currentFormatTimestamp, formatDateTime } from "~/libs";

import { downloadBlob, arrayToCsv } from "~/utils";

import { Button } from "~/components/atoms";
import { DoughnutChartBox, RangeDatePicker } from "~/components/molecules";
import {
  AnalysisCumulativeOfPostGraph,
  AnalysisDetailOfPostGraph,
  MultiParentsWithMultiChildrenDropdownField,
} from "~/components/organisms";

import {
  BusinessDivisionType,
  ParentType,
  ApiAnalyticsPlansKpiTermsIndexResponseType,
  RangeDatePropsType,
  ChildType,
} from "~/domains";

type PropsType = {
  formattedStartDate: string | null;
  formattedEndDate: string | null;
  selectedDivisions: BusinessDivisionType[];
  optionSelectedSections: ChildType[];
  data?: ApiAnalyticsPlansKpiTermsIndexResponseType;
  className?: string;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onDivisionChange: (newValue: ParentType[]) => void;
  onSectionChange: (newValue: ChildType[]) => void;
  selectableDivisions: BusinessDivisionType[];
  optionSelectableSections: ChildType[];
  selectedDropdownSections: ChildType[];
  monthArray: Date[];
};

const today = new Date();

export const AnalyticsKpiTermsTemplate: FC<PropsType> = ({
  formattedStartDate,
  formattedEndDate,
  selectedDivisions,
  optionSelectedSections,
  isLoading,
  className = "",
  startDate,
  endDate,
  onChangeDateRange,
  onDivisionChange,
  onSectionChange,
  selectableDivisions,
  optionSelectableSections,
  data,
  monthArray,
}: PropsType) => {
  const handleExportCsv = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    downloadBlob(
      arrayToCsv(generateCsvData()),
      `${currentFormatTimestamp}-kpi_analysis.csv`,
    );
  };

  const generateCsvData = () => {
    return [
      ["期間", `${formattedStartDate} ~ ${formattedEndDate}`],
      [
        "部署",
        selectedDivisions.map((division) => division.name).join(", ") ||
          "すべての部署",
      ],
      [
        "課",
        optionSelectedSections?.length
          ? optionSelectedSections.map((section) => section.name).join(", ")
          : selectedDivisions?.length
            ? "選択された部署に属するすべての課"
            : "すべての課",
      ],
      ["実施率", `${data?.doughnut?.numerator}/${data?.doughnut.denominator}`],
      ["実施率(%)", `${data?.doughnut?.rate}%`],
      [
        "月",
        "月毎の実施状況",
        "月毎の実施状況(平均)",
        "累計の実施",
        "累計の実施(平均)",
      ],
      ...monthArray.map((month) => {
        const formatMonth = formatDateTime(month, "MM-dd");
        const monthlyPostsCount = data?.monthlyPostsCounts.find(
          (item) => item.name === formatMonth,
        );
        const monthlyCumulativePostsCount =
          data?.monthlyCumulativePostsCounts.find(
            (item) => item.name === formatMonth,
          );
        return [
          formatMonth,
          monthlyPostsCount?.postsTotalCount,
          monthlyPostsCount?.postsAverageCount,
          monthlyCumulativePostsCount?.postsTotalCount,
          monthlyCumulativePostsCount?.postsAverageCount,
        ];
      }),
    ];
  };

  return (
    <div className={className}>
      <div className="grid miniTablet:grid-cols-2 gap-4">
        <MultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentLabel="部署"
          parentsOnChange={onDivisionChange}
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childLabel="課"
          childrenOnChange={onSectionChange}
        />
        <RangeDatePicker
          labelText="期間"
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeDateRange}
          maxDate={today}
        />
      </div>
      <div className="mt-6 text-right">
        <Button
          outline
          text="CSVエクスポート"
          onClick={handleExportCsv}
          color="primary"
          readonly={isLoading}
        />
      </div>
      <div className="grid miniTablet:grid-cols-2 gap-6 mt-6">
        <AnalysisDetailOfPostGraph
          title="月毎の実施状況"
          isLoading={isLoading}
          postAnalysis={data?.monthlyPostsCounts.map((item) => ({
            ...item,
            name: formatDateTime(item.name, "MM-dd"),
          }))}
        />
        <AnalysisCumulativeOfPostGraph
          title="累計の実施"
          isLoading={isLoading}
          postAnalysis={data?.monthlyCumulativePostsCounts.map((item) => ({
            ...item,
            name: formatDateTime(item.name, "MM-dd"),
          }))}
        />
        <DoughnutChartBox
          title="実施率"
          isLoading={isLoading}
          doughnut={data?.doughnut}
        />
      </div>
    </div>
  );
};
