import { MouseEvent, ChangeEvent, FormEvent, useState } from "react";

import {
  useInput,
  useApisManagersSalesConceptCategoriesIndex,
  useArray,
  useDropdown,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
  useMultipleDropdown,
} from "~/hooks";

import {
  OptionType,
  ApiManagersSalesConceptCategoriesRequestIndex,
  AllPublicStatus,
  BusinessDivisionType,
  ChildType,
  ParentType,
  SingleValueType,
  MultiValueType,
  SalesConceptCategoryType,
} from "~/domains";

type ReturnType = {
  salesConceptCategories: SalesConceptCategoryType[];
  keyword: string;
  hasNextPage?: boolean;
  isLoading: boolean;
  allBusinessSectionsDistribution?: boolean;
  selectableDropdownPublicStatusIds: OptionType[];
  selectedDropdownPublicStatusId?: SingleValueType<OptionType>;
  selectableDropdownTags: OptionType[];
  selectedDropdownTags: MultiValueType<OptionType>;
  selectableDropdownDivisions: BusinessDivisionType[];
  selectedDropdownDivisions: BusinessDivisionType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  onChangeDropdownTags: (value: MultiValueType<OptionType>) => void;
  deleteIndexItem: (index: number) => void;
  unshiftItem: (item: SalesConceptCategoryType) => void;
  findAndRemove: (func: (item: SalesConceptCategoryType) => boolean) => void;
  onChangeDropdownPublicStatusId: (value: SingleValueType<OptionType>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  setAllBusinessSectionsDistribution: (value: boolean | undefined) => void;
};

export const useSearchManagersSalesConceptCategories = (): ReturnType => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [allBusinessSectionsDistribution, setAllBusinessSectionsDistribution] =
    useState<boolean | undefined>();
  const [{ value: keyword, onChange: onChangeKeyword }, setTitleKeyword] =
    useInput("");
  const [
    {
      dropdownValue: selectedDropdownTags,
      onChange: onChangeDropdownTags,
      dropdownSelectableValue: selectableDropdownTags,
      setSelectableOption: setSelectableDropdownTags,
    },
    setDropdownTags,
  ] = useMultipleDropdown();

  const [
    {
      dropdownValue: selectedDropdownPublicStatusId,
      onChange: onChangeDropdownPublicStatusId,
      dropdownSelectableValue: selectableDropdownPublicStatusIds,
    },
    setDropdownPublicStatus,
  ] = useDropdown({
    selectableValue: AllPublicStatus.map((status) => ({
      name: status.name,
      id: status.id.toString(),
    })),
  });
  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });
  const [params, setParams] =
    useState<ApiManagersSalesConceptCategoriesRequestIndex>();

  const { items, setItems, deleteIndexItem, unshiftItem, findAndRemove } =
    useArray<SalesConceptCategoryType>([]);

  const { fetchNextPage, hasNextPage, isFetching } =
    useApisManagersSalesConceptCategoriesIndex({
      params,
      config: {
        onSuccess: (data) => {
          setSelectableDropdownTags(
            data.pages[0]?.selectableSalesConceptTags || [],
          );
          setItems(
            data.pages.map((page) => page.salesConceptCategories).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      businessDivisionIds: selectedDivisions.map((division) => division.id),
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      publicStatusId: selectedDropdownPublicStatusId?.value,
      tagIds: selectedDropdownTags.map((tag) => tag.value),
      allBusinessSectionsDistribution,
    });
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setTitleKeyword("");
    setDropdownPublicStatus();
    onDivisionChange([]);
    onSectionChange([]);
    setDropdownTags([]);
    setParams({});
    setAllBusinessSectionsDistribution(undefined);
  };

  return {
    salesConceptCategories: items,
    keyword,
    isLoading: isFetching,
    allBusinessSectionsDistribution,
    hasNextPage,
    selectableDropdownTags,
    selectedDropdownTags,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownDivisions: selectableDivisions,
    selectedDropdownDivisions: selectedDivisions,
    selectableDropdownSections: optionSelectableSections,
    selectedDropdownSections: optionSelectedSections,
    onDivisionChange,
    onSectionChange,
    onChangeDropdownTags,
    unshiftItem,
    deleteIndexItem,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    fetchNextPage,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
    setAllBusinessSectionsDistribution,
  };
};
