import React, { FC, MouseEvent } from "react";

import { formatDateTime } from "~/libs";

import { AreaChart, BarChart } from "~/components/atoms";
import { PrevNextSelectMonthly } from "~/components/molecules";

type PropsType = {
  title: string;
  chartType: "Bar" | "Area";
  dailyRoleplayingTimes: ItemType[];
  targetDate: Date;
  handlePrevMonthClick: (e: MouseEvent<HTMLButtonElement>) => void;
  handleNextMonthClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

type ItemType = {
  postDate: string;
  times: number;
  cumulativeTimes: number;
};

const CalculateValue = {
  Bar: (item: ItemType) => item.times,
  Area: (item: ItemType) => item.cumulativeTimes,
};

export const RoleplayingConditionChart: FC<PropsType> = ({
  title,
  chartType,
  dailyRoleplayingTimes,
  targetDate,
  handlePrevMonthClick,
  handleNextMonthClick,
}: PropsType) => {
  const dashboard =
    dailyRoleplayingTimes.map((v) => ({
      name: v.postDate,
      value: CalculateValue[chartType](v),
    })) || [];

  const DisplayChart = {
    Bar: (
      <BarChart
        perforManceData={dashboard.map((item) => ({
          ...item,
          name: formatDateTime(item.name, "MM-dd"),
        }))}
        indexAxis="x"
      />
    ),
    Area: (
      <AreaChart
        datasets={dashboard.map((item) => ({
          ...item,
          name: formatDateTime(item.name, "MM-dd"),
        }))}
      />
    ),
  };

  return (
    <div>
      <h2 className="font-bold text-center text-lg">{title}</h2>
      <PrevNextSelectMonthly
        targetDate={targetDate}
        onPrevMonthClick={handlePrevMonthClick}
        onNextMonthClick={handleNextMonthClick}
        className="mt-6"
      />
      <div className="mt-2 rounded-md mx-auto">{DisplayChart[chartType]}</div>
    </div>
  );
};
