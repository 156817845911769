import React, { MouseEvent, useState, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useDivisionSectionDropdown,
  useDateRange,
  useKpiObjectiveForms,
  FormEmployeeKpiObjectiveType,
  useApisManagersKpiTermsUpdate,
} from "~/hooks";

import { Button, NoContentMessage } from "~/components/atoms";
import { DragDropProvider } from "~/components/atoms";
import { VerticalProgressLine } from "~/components/atoms";
import {
  LabelWithTextField,
  RangeDatePicker,
  AddFormFieldButton,
  TabList,
  NameOrderableForm,
} from "~/components/molecules";
import {
  KpiObjectiveForm,
  ParentWithChildDropdownField,
} from "~/components/organisms";

import {
  PublicStatus,
  AvatarAndNameEmployeeType,
  BusinessDivisionType,
  BusinessSectionType,
  KpiTermWithKpiObjectiveType,
} from "~/domains";

type PropsType = {
  employees: AvatarAndNameEmployeeType[];
  selectableBusinessDivisions: BusinessDivisionType[];
  selectableBusinessSections: BusinessSectionType[];
  kpiTerm: KpiTermWithKpiObjectiveType;
};

export const KpiTermWithObjectiveForm: FC<PropsType> = ({
  employees,
  selectableBusinessDivisions,
  selectableBusinessSections,
  kpiTerm,
}: PropsType) => {
  const navigate = useNavigate();

  const { mutate: putKpiTerm, isLoading: isSubmitting } =
    useApisManagersKpiTermsUpdate();

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const [title] = useInput(kpiTerm.title);
  const [dateRange] = useDateRange([
    new Date(kpiTerm.fromDate),
    new Date(kpiTerm.toDate),
  ]);

  const {
    isSubmitting: isSubmittingKpiObjectiveForm,
    kpiObjectives,
    onChangeTitle,
    onChangeObjectivePerformance,
    onChangeUnitName,
    onChangeEmployeeCount,
    addKpiObjective,
    removeKpiObjective,
    saveKpiObjective,
    employeeCountEvenUp,
    changeDisplayOrder,
    setNotReadOnly,
    setReadOnly,
    handleDuplicate,
    onChangeSetEmployee,
    onChangeUnsetEmployee,
  } = useKpiObjectiveForms({
    kpiTermId: kpiTerm.id,
    employees: employees,
    kpiObjectives: kpiTerm.kpiObjectives,
  });

  const {
    selectableDivisions,
    selectedDivision,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSection,
    onSectionChange,
  } = useDivisionSectionDropdown({
    selectableDivisions: selectableBusinessDivisions || [],
    selectableSections: selectableBusinessSections || [],
    defaultDivision: kpiTerm.businessDivision,
    defaultSection: kpiTerm.businessSection,
  });

  const handlePublishSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isConfirm = confirm(
      "作成・編集した「各項目」を「保存」しなければ反映されません。配布を実行してよろしいですか？",
    );
    if (!isConfirm) return;

    handleSubmit(PublicStatus.PUBLISHED.id);
  };

  const handleDraftSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isConfirm = confirm(
      "作成・編集した「各項目」を「保存」しなければ反映されません。配布を実行してよろしいですか？",
    );
    if (!isConfirm) return;

    handleSubmit(PublicStatus.DRAFTED.id);
  };

  const handleSubmit = (publicStatusId: number) => {
    const body = {
      businessSectionId: optionSelectedSection?.id || "",
      title: title.value,
      fromDate: dateRange.formattedStartDate || "",
      toDate: dateRange.formattedEndDate || "",
      publicStatusId: publicStatusId,
    };
    putKpiTerm(
      {
        id: kpiTerm.id,
        body,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate("/managers/kpi_terms");
        },
      },
    );
  };

  const isCurrentTabKpiObjective = selectedTabIndex === 0;
  const savedKpiObjectives = kpiObjectives.filter(
    (kpiObjective) => !kpiObjective.isNew,
  );
  return (
    <>
      <div className="space-y-6">
        <ParentWithChildDropdownField
          allParents={selectableDivisions}
          parentValue={selectedDivision}
          parentsOnChange={onDivisionChange}
          parentRequired={true}
          parentLabel="配布先: 部署"
          allChildren={optionSelectableSections}
          childValue={optionSelectedSection}
          childrenOnChange={onSectionChange}
          childRequired={true}
          childLabel="配布先: 課"
        />
        <LabelWithTextField
          labelText="タイトル"
          type="text"
          name="title"
          placeholder="タイトル"
          required
          value={title.value}
          onChange={title.onChange}
        />
        <RangeDatePicker
          labelText="期間指定"
          required={true}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onChange={dateRange.onChange}
        />
        <TabList
          tabTexts={["作成", "並び替え"]}
          onClick={setSelectedTabIndex}
          selectedIndex={selectedTabIndex}
        />
        {isCurrentTabKpiObjective ? (
          kpiObjectives.length ? (
            <VerticalProgressLine>
              {kpiObjectives.map((kpiObjective) => (
                <KpiObjectiveForm
                  isSubmitting={isSubmittingKpiObjectiveForm}
                  defaultAccordionOpen={Boolean(kpiObjective.isNew)}
                  key={kpiObjective.id}
                  setNotReadOnly={() => setNotReadOnly(kpiObjective)}
                  setReadOnly={() => setReadOnly(kpiObjective)}
                  kpiObjective={kpiObjective}
                  removeKpiObjective={() => removeKpiObjective(kpiObjective)}
                  saveKpiObjective={() => saveKpiObjective(kpiObjective)}
                  employeeCountEvenUp={() => employeeCountEvenUp(kpiObjective)}
                  onChangeTitle={(newValue: string) =>
                    onChangeTitle({ kpiObjective, newValue })
                  }
                  onChangeObjectivePerformance={(newValue: number | "") =>
                    onChangeObjectivePerformance({
                      kpiObjective,
                      newValue,
                    })
                  }
                  onChangeUnitName={(newValue: string) =>
                    onChangeUnitName({
                      kpiObjective,
                      newValue,
                    })
                  }
                  onChangeEmployeeCount={(
                    employeeKpiObjective: FormEmployeeKpiObjectiveType,
                    newValue: number | "",
                  ) =>
                    onChangeEmployeeCount({
                      kpiObjective,
                      employeeKpiObjective,
                      newValue,
                    })
                  }
                  handleDuplicate={() => handleDuplicate(kpiObjective)}
                  onChangeSetEmployee={(
                    employeeKpiObjective: FormEmployeeKpiObjectiveType,
                  ) =>
                    onChangeSetEmployee({
                      kpiObjective,
                      employeeKpiObjective,
                    })
                  }
                  onChangeUnsetEmployee={(
                    employeeKpiObjective: FormEmployeeKpiObjectiveType,
                  ) =>
                    onChangeUnsetEmployee({
                      kpiObjective,
                      employeeKpiObjective,
                    })
                  }
                />
              ))}
            </VerticalProgressLine>
          ) : (
            <NoContentMessage text="目標がありません" className="mt-4" />
          )
        ) : savedKpiObjectives.length ? (
          <DragDropProvider>
            <VerticalProgressLine>
              {savedKpiObjectives.map((kpiObjective, index) => (
                <NameOrderableForm
                  key={`kpi-order-${kpiObjective.id}`}
                  name={kpiObjective.title}
                  index={index}
                  changeDisplayOrder={(beforeIndex: number, newIndex: number) =>
                    changeDisplayOrder(beforeIndex, newIndex)
                  }
                />
              ))}
            </VerticalProgressLine>
          </DragDropProvider>
        ) : (
          <NoContentMessage
            text="保存された目標がありません"
            className="mt-4"
          />
        )}
        {isCurrentTabKpiObjective && (
          <AddFormFieldButton onClick={addKpiObjective} />
        )}
        <div className="flex items-center space-x-4">
          <Button
            text="下書き保存"
            color="gray"
            outline
            className="w-full"
            onClick={handleDraftSubmit}
            readonly={isSubmitting}
          />
          <Button
            text="配布をする"
            color="primary"
            className="w-full"
            readonly={isSubmitting}
            onClick={handlePublishSubmit}
          />
        </div>
      </div>
    </>
  );
};
