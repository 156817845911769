import React, { FC, FormEvent } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useApisAdminOperatorsCompaniesContractPlanLogsCreate,
  useApisAdminOperatorsCompaniesContractPlanLogsIndex,
  useDropdown,
} from "~/hooks";

import { Form, FormSubmitButton, GridHeaderCells } from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import {
  GridRow,
  InfiniteScrollWithMoreButton,
  LabelWithDropDownField,
} from "~/components/molecules";

import { AnalyticsPlanType, NormalPlanType } from "~/domains";

export const AdminOperatorsCompaniesContractPlanLogsIndex: FC = () => {
  const { companyId = "" } = useParams<{ companyId: string }>();

  const { data, fetchNextPage, hasNextPage, refetch } =
    useApisAdminOperatorsCompaniesContractPlanLogsIndex({
      companyId,
    });

  const { mutate: postRequest } =
    useApisAdminOperatorsCompaniesContractPlanLogsCreate();
  const company = data?.pages[0]?.company;
  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: "/admin_operators",
    },
    {
      label: "会社一覧",
      href: "/admin_operators",
    },
    {
      label: company?.name || "",
      href: `/admin_operators/companies/${companyId}`,
    },
    {
      label: "プランログ一覧",
      href: `/admin_operators/companies/${companyId}/contract_plan_logs`,
    },
  ];

  const [
    {
      value: contractPlanId,
      dropdownValue: dropdownValueContractPlanId,
      onChange: onChangeContractPlanId,
      dropdownSelectableValue: contractPlanIdSelectableValue,
    },
  ] = useDropdown({
    selectableValue: [NormalPlanType, AnalyticsPlanType].map((plan) => ({
      id: plan.id.toString(),
      name: plan.name,
    })),
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isConfirm = confirm(
      "この操作は取り消せません。本当に新しい契約プランを登録しますか？",
    );
    if (!isConfirm) return;

    postRequest(
      {
        companyId,
        body: {
          companyContractPlanId: contractPlanId?.id,
        },
      },
      {
        onSuccess: async (res) => {
          toast(res.message);
          onChangeContractPlanId(null);
          await refetch();
        },
      },
    );
  };

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      <h1 className="text-xl">
        <span className="font-bold">{company?.name}</span>のユーザー上限数ログ
      </h1>
      <Form onSubmit={handleSubmit} className="space-y-6 mt-12">
        <LabelWithDropDownField
          labelText="プラン"
          required
          name="contractPlanId"
          value={dropdownValueContractPlanId}
          onChange={onChangeContractPlanId}
          options={contractPlanIdSelectableValue}
        />
        <div className="text-right">
          <FormSubmitButton
            value="新しい契約プランを登録する"
            color="primary"
          />
        </div>
      </Form>
      <InfiniteScrollWithMoreButton
        itemsLength={data?.pages.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        className="mt-12"
      >
        <div className="grid grid-cols-3">
          <GridHeaderCells texts={["プラン名", "作成日時", "更新日時"]} />
          {data?.pages.map((page) =>
            page.companyContractPlanLogs.map((log) => (
              <GridRow
                key={log.id}
                lists={[
                  log.companyContractPlan.name,
                  log.createdAt,
                  log.updatedAt,
                ]}
              />
            )),
          )}
        </div>
      </InfiniteScrollWithMoreButton>
    </AdminOperatorLayout>
  );
};
