import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import {
  ResponseMessageType,
  RawDraftContentState,
  PreviewWithFileType,
} from "~/domains";

type RequestDataType = {
  employeeSurveySubmissionId: string;
  body: {
    content: RawDraftContentState;
    attachFiles: PreviewWithFileType[];
  };
};

const request = ({ employeeSurveySubmissionId, body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ reply: body });
  return new ApiClient()
    .post<ResponseMessageType>(
      `/api/members/employee_survey_submissions/${employeeSurveySubmissionId}/employee_survey_submission_replies`,
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersEmployeeSurveySubmissionsEmployeeSurveySubmissionRepliesCreate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ResponseMessageType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
