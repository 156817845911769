import React, { FC } from "react";

import { useBoolean } from "~/hooks";

import { FloatingMenu } from "~/components/molecules";
import { PopupAvatar } from "~/components/organisms";

import { AvatarAndNameEmployeeType, PostDetailMenuItemType } from "~/domains";

type PropsType = {
  miniText?: string;
  postedEmployee: AvatarAndNameEmployeeType;
  menuItems: PostDetailMenuItemType[];
  underLabel?: string;
  className?: string;
  isDmOnly?: boolean;
};

export const PostHeader: FC<PropsType> = ({
  miniText,
  postedEmployee,
  menuItems,
  underLabel = "ー",
  className = "",
  isDmOnly = false,
}: PropsType) => {
  const menuModalOpen = useBoolean(false);
  const displayableMenuItems = menuItems.filter((item) => !item.disabled);
  return (
    <div className={`flex ${className}`}>
      <PopupAvatar
        employee={postedEmployee}
        avatarSize="md"
        isDmOnly={isDmOnly}
      />
      <div className="ml-4 w-full max-w-full truncate">
        <div className="flex items-center">
          <span className="text-md truncate font-bold">
            {postedEmployee.name}
          </span>
          <div className="flex items-center">
            {miniText && (
              <span className="text-xs text-secondary-600 ml-2">
                {miniText}
              </span>
            )}
          </div>
        </div>
        <span className="text-sm truncate text-secondary-600">
          {underLabel}
        </span>
      </div>
      {Boolean(displayableMenuItems.length) && (
        <FloatingMenu
          menuItems={displayableMenuItems}
          iconSize="1.5rem"
          className="ml-2"
          modal={{
            toggle: menuModalOpen.toggle,
            close: menuModalOpen.setFalse,
            opened: menuModalOpen.isChecked,
          }}
        />
      )}
    </div>
  );
};
