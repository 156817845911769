import React, { FC } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ActiveElement,
  ChartEvent,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

type PropsType = {
  perforManceData: {
    name: string;
    value: number;
    stackValue?: number;
  }[];
  title?: string;
  width?: number | string;
  height?: number;
  indexAxis?: "x" | "y";
  className?: string;
  onClick?: (index?: { index: number }) => void;
};

type DatasetType = {
  label: string;
  data: number[];
  backgroundColor: string;
};

export const BarChart: FC<PropsType> = ({
  title,
  perforManceData,
  width,
  height,
  indexAxis = "y",
  className = "",
  onClick,
}: PropsType) => {
  const data = {
    labels: perforManceData.map((item) => item.name),
    datasets: [
      {
        label: "実績",
        data: perforManceData.map((item) => item.value),
        backgroundColor: "#00A1DF",
      },
      perforManceData[0]?.stackValue === undefined
        ? undefined
        : {
            label: "目標",
            data: perforManceData.map((item) => item.stackValue || 0),
            backgroundColor: "#EBF8FD",
          },
    ].filter((item) => !!item) as DatasetType[],
  };

  const handleBarClick = (_: ChartEvent, elements: ActiveElement[]) => {
    const element = elements[0];
    if (!element) return;
    onClick && onClick({ index: element.index });
  };

  const options = {
    indexAxis,
    plugins: {
      title: {
        display: !!title,
        text: title,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    onClick: handleBarClick,
  };

  return (
    <div className={`min-h-[250px] ${className}`}>
      <Bar options={options} data={data} width={width} height={height} />
    </div>
  );
};
