import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersBusinessSectionsBusinessSectionMemoReviewsIndexRequestType,
  ApiManagersBusinessSectionsBusinessSectionMemoReviewsIndexResponseType,
} from "~/domains";

type RequestDataType = {
  businessSectionId: string;
  params?: ApiManagersBusinessSectionsBusinessSectionMemoReviewsIndexRequestType;
  page?: number;
};

const request = async ({
  businessSectionId,
  params,
  page = 1,
}: RequestDataType): Promise<ApiManagersBusinessSectionsBusinessSectionMemoReviewsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersBusinessSectionsBusinessSectionMemoReviewsIndexResponseType>(
      `/api/managers/business_sections/${businessSectionId}/business_section_memo_reviews`,
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  businessSectionId: string;
  params?: ApiManagersBusinessSectionsBusinessSectionMemoReviewsIndexRequestType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisManagersBusinessSectionsBusinessSectionMemoReviewsIndex = ({
  businessSectionId,
  params,
  config = {},
}: PropsType): UseInfiniteQueryResult<ApiManagersBusinessSectionsBusinessSectionMemoReviewsIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    [
      "ApiManagersBusinessSectionsBusinessSectionMemoReviewsIndex",
      businessSectionId,
      params,
    ],
    ({ pageParam }) => {
      return request({
        params,
        businessSectionId,
        page: pageParam as number | undefined,
      });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
