import React, { ChangeEvent, FC, FormEvent } from "react";

import { Form, FormSubmitButton } from "~/components/atoms";
import { LabelWithTextField } from "~/components/molecules";

type PropsType = {
  isSubmitting: boolean;
  businessDivisionName: string;
  onChangeBusinessDivisionName: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const BusinessDivisionForm: FC<PropsType> = ({
  isSubmitting,
  businessDivisionName,
  onChangeBusinessDivisionName,
  handleSubmit,
}) => {
  return (
    <Form onSubmit={handleSubmit} className="space-y-6">
      <LabelWithTextField
        labelText="部署名"
        type="text"
        name="businessDivision"
        required
        placeholder="部署名"
        value={businessDivisionName}
        onChange={onChangeBusinessDivisionName}
      />
      <FormSubmitButton
        color="primary"
        className="w-full"
        value="保存"
        isReadOnly={isSubmitting}
      />
    </Form>
  );
};
