import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useApisMembersSkillEvaluationTermsShow,
  useProvidersCurrentEmployee,
  useSkillEvaluationStandardSummaryCategorySlideNavigation,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { SkillEvaluationTermDetail } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const MembersSkillEvaluationTermsShow: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const { id = "" } = useParams<{ id: string }>();
  const breadCrumbItems = [
    { label: "スキルチェック", href: "/skill_evaluation_terms" },
    { label: "詳細", href: `/skill_evaluation_terms/${id}` },
  ];

  const { data, isError } = useApisMembersSkillEvaluationTermsShow({ id });
  const { navigations } =
    useSkillEvaluationStandardSummaryCategorySlideNavigation({
      selectedArray: [false, true, false],
    });

  if (isError) return <NotFoundIndex />;

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
          {data && (
            <SkillEvaluationTermDetail
              skillEvaluationTerm={data.skillEvaluationTerm}
              archivedSkillEvaluationStandardSummaryCategory={
                data.archivedSkillEvaluationStandardSummaryCategory
              }
              skillEvaluationTermEmployeeComment={
                data.skillEvaluationTermEmployeeComment
              }
              revieweeEmployeeId={currentEmployee?.id}
              selectArchivedSkillEvaluationStandards={
                data.employeeSelectArchivedSkillEvaluations
              }
              skillEvaluationTermRevieweeComment={
                data.skillEvaluationTermRevieweeComment
              }
              employeeRoleType="member"
              className="mt-6"
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
