import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";

import { InfiniteDataType, toDateString } from "~/libs";

import {
  useInput,
  useDateRange,
  useApisManagersFocusChallengeEmployeesIndex,
  usePostSortType,
  useMultipleDropdown,
  useArray,
  useProvidersCurrentEmployee,
  useMultiDivisionMultiSectionDropdown,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
  useBoolean,
  useApisMembersFocusChallengeSearchItemsIndex,
} from "~/hooks";

import { RangeDatePropsType } from "~/components/molecules";

import {
  ApiManagersFocusChallengeEmployeesIndexResponseType,
  FocusChallengeEmployeeType,
  MultiValueType,
  OptionType,
  SortType,
  ApiManagersFocusChallengesIndexRequestType,
  ChildType,
  ParentType,
  SingleValueType,
  ID_ALL_TYPE,
} from "~/domains";

type ReturnType = {
  focusChallengeEmployees: FocusChallengeEmployeeType[];
  data?: InfiniteDataType<ApiManagersFocusChallengeEmployeesIndexResponseType>;
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  selectableDropdownCompleteStatusIds: OptionType[];
  selectedDropdownCompleteStatusId?: SingleValueType<OptionType>;
  selectableDropdownHasReplyIds: OptionType[];
  selectedDropdownHasReplyId?: SingleValueType<OptionType>;
  hasNextPage?: boolean;
  isLoading: boolean;
  sort: SortType;
  isBelongingEmployee: boolean;
  selectableJobCategoryPhases: OptionType[];
  selectedJobCategoryPhases: MultiValueType<OptionType>;
  selectableDropdownProvidingServices: OptionType[];
  selectedDropdownProvidingServices: MultiValueType<OptionType>;
  selectedDropdownEmployees: ParentType[];
  selectableDropdownEmployees: ParentType[];
  selectedDropdownArchivedEmployees: ParentType[];
  selectableDropdownArchivedEmployees: ParentType[];
  selectableDropdownDivisions: ParentType[];
  selectedDropdownDivisions: ParentType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  onEmployeesChange: (value: ParentType[]) => void;
  onArchivedEmployeesChange: (value: ParentType[]) => void;
  deleteIndexFocusChallengeEmployee: (index: number) => void;
  updateIndexFocusChallengeEmployee: (
    newItem: FocusChallengeEmployeeType,
    index: number,
  ) => void;
  unshiftFocusChallengeEmployee: (newItem: FocusChallengeEmployeeType) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeIsBelongingEmployee: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeJobCategoryPhases: (options: MultiValueType<OptionType>) => void;
  onChangeDropdownProvidingServices: (
    options: MultiValueType<OptionType>,
  ) => void;
  fetchNextPage: () => void;
  setSort: (val: SortType) => void;
  onChangeDropdownCompleteStatusId: (
    value: SingleValueType<OptionType>,
  ) => void;
  onChangeDropdownHasReplyId: (value: SingleValueType<OptionType>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchManagersFocusChallengeEmployees = (): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const {
    isChecked: isBelongingEmployee,
    onChange: onChangeIsBelongingEmployee,
    setFalse: setBelongingEmployeeFalse,
  } = useBoolean(false);
  const [
    {
      statusValue,
      selectedDropdownCompleteStatusId,
      selectableDropdownCompleteStatusIds,
      onChangeDropdownCompleteStatusId,
    },
  ] = useCompleteStatusDropdown();
  const [
    {
      statusValue: hasReply,
      selectedDropdownCompleteStatusId: selectedDropdownHasReplyId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasReplyIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasReplyId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "リプライあり",
      false: "リプライなし",
    },
  });
  const { sort, snakeKeyValue, setSort } = usePostSortType();
  const [params, setParams] =
    useState<ApiManagersFocusChallengesIndexRequestType>({
      sortType: snakeKeyValue,
    });

  const { items, setItems, deleteIndexItem, updateIndexItem, unshiftItem } =
    useArray<FocusChallengeEmployeeType>([]);

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
    selectableEmployees,
    selectedEmployees,
    onEmployeesChange,
    selectableArchivedEmployees,
    selectedArchivedEmployees,
    onArchivedEmployeesChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    isSelectEmployee: true,
  });
  const [
    {
      dropdownValue: selectedJobCategoryPhases,
      dropdownSelectableValue: selectableJobCategoryPhases,
      onChange: onChangeJobCategoryPhases,
      setSelectableOption: setSelectableJobCategoryPhases,
    },
  ] = useMultipleDropdown();
  const [
    {
      dropdownValue: selectedDropdownProvidingServices,
      value: selectedProvidingServices,
      onChange: onChangeDropdownProvidingServices,
      dropdownSelectableValue: selectableDropdownProvidingServices,
      setSelectableOption: setSelectableDropdownProvidingServices,
    },
  ] = useMultipleDropdown();

  useApisMembersFocusChallengeSearchItemsIndex({
    config: {
      onSuccess: (data) => {
        setSelectableDropdownProvidingServices(
          data.selectableProvidingServices,
        );
        setSelectableJobCategoryPhases([
          ID_ALL_TYPE,
          ...data.selectableJobCategoryPhases,
        ]);
      },
    },
  });

  const { data, isFetching, fetchNextPage, hasNextPage } =
    useApisManagersFocusChallengeEmployeesIndex({
      params,
      config: {
        onSuccess: (data) => {
          setItems(
            data.pages.map((page) => page.focusChallengeEmployees).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      focusCompleted: statusValue,
      hasReply,
      isBelongingEmployee,
      businessDivisionIds: selectedDivisions.map((ele) => ele.id),
      businessSectionIds: optionSelectedSections.map((ele) => ele.id),
      employeeIds: [...selectedEmployees, ...selectedArchivedEmployees].map(
        (ele) => ele.id,
      ),
      jobCategoryPhaseIds: selectedJobCategoryPhases?.map((ele) => ele.value),
      providingServiceIds: selectedProvidingServices?.map((ele) => ele.id),
      sortType: snakeKeyValue,
      dateFrom: startDate ? toDateString(startDate) : "",
      dateTo: endDate ? toDateString(endDate) : "",
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownCompleteStatusId(null);
    onChangeDropdownHasReplyId(null);
    onDivisionChange([]);
    onSectionChange([]);
    onEmployeesChange([]);
    onArchivedEmployeesChange([]);
    onChangeJobCategoryPhases([]);
    onChangeDropdownProvidingServices([]);
    onChangeDateRange([null, null]);
    setParams({ sortType: snakeKeyValue });
    setFalseRightSidebarMain();
    setBelongingEmployeeFalse();
  };

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      sortType: snakeKeyValue,
    }));
  }, [snakeKeyValue]);

  return {
    focusChallengeEmployees: items,
    data,
    keyword,
    selectableDropdownCompleteStatusIds,
    selectedDropdownCompleteStatusId,
    selectableDropdownHasReplyIds,
    selectedDropdownHasReplyId,
    isLoading: isFetching,
    hasNextPage,
    startDate,
    endDate,
    sort,
    isBelongingEmployee,
    selectableJobCategoryPhases,
    selectedJobCategoryPhases,
    selectableDropdownProvidingServices,
    selectedDropdownProvidingServices,
    selectedDropdownEmployees: selectedEmployees,
    selectableDropdownEmployees: selectableEmployees,
    selectedDropdownArchivedEmployees: selectedArchivedEmployees,
    selectableDropdownArchivedEmployees: selectableArchivedEmployees,
    selectableDropdownDivisions: selectableDivisions,
    selectedDropdownDivisions: selectedDivisions,
    selectableDropdownSections: optionSelectableSections,
    selectedDropdownSections: optionSelectedSections,
    onDivisionChange,
    onSectionChange,
    onEmployeesChange,
    onArchivedEmployeesChange,
    unshiftFocusChallengeEmployee: unshiftItem,
    updateIndexFocusChallengeEmployee: updateIndexItem,
    deleteIndexFocusChallengeEmployee: deleteIndexItem,
    fetchNextPage,
    setSort,
    onChangeIsBelongingEmployee,
    onChangeJobCategoryPhases,
    onChangeDropdownProvidingServices,
    onChangeDateRange,
    onChangeKeyword,
    onChangeDropdownCompleteStatusId,
    onChangeDropdownHasReplyId,
    onSearchSubmit,
    onConditionReset,
  };
};
