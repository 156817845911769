import { useContext } from "react";

import { CurrentAdminOperatorContext } from "~/components/providers/";

import { AdminOperatorType } from "~/domains";

type Props = {
  currentAdminOperator?: AdminOperatorType;
  refetch: () => void;
};

export const useProvidersCurrentAdminOperator = (): Props => {
  const { currentAdminOperator, refetch } = useContext(
    CurrentAdminOperatorContext,
  );

  return { currentAdminOperator, refetch };
};
