import React, { FC } from "react";

import { CatchTitleBorder } from "~/components/atoms";
import { InfiniteScroll } from "~/components/molecules";

import { EmployeeMemoReviewDetailWithEditForm } from "./EmployeeMemoReviewDetailWithEditForm";

import { AvatarAndNameEmployeeType, EmployeeMemoReviewType } from "~/domains";

type PropsType = {
  employee: AvatarAndNameEmployeeType;
  employeeMemoReviews: EmployeeMemoReviewType[];
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isLoading: boolean;
  className?: string;
  updateIndexPost: (newItem: EmployeeMemoReviewType, index: number) => void;
  deleteIndexPost: (index: number) => void;
};

export const EmployeeMemoReviewDetailWithEditForms: FC<PropsType> = ({
  employee,
  employeeMemoReviews,
  fetchNextPage,
  hasNextPage,
  isLoading,
  deleteIndexPost,
  updateIndexPost,
  className,
}: PropsType) => {
  return (
    <div className={className}>
      <CatchTitleBorder text={`${employee.name}の更新履歴`} />
      <InfiniteScroll
        itemsLength={employeeMemoReviews.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        isLoading={isLoading}
        className={"mt-9"}
        scrollClassName="space-y-6"
      >
        {employeeMemoReviews.map((employeeMemoReview, index) => (
          <EmployeeMemoReviewDetailWithEditForm
            key={employeeMemoReview.id}
            employeeMemoReview={employeeMemoReview}
            deleteIndexPost={() => deleteIndexPost(index)}
            updateIndexPost={(newItem) => updateIndexPost(newItem, index)}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
