import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import {
  ApiMembersSkillEvaluationTermResultPointsIndexType,
  ApiMembersSkillEvaluationTermResultPointsIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiMembersSkillEvaluationTermResultPointsIndexRequestType;
  id: string;
};

const request = async ({
  params,
  id,
}: RequestDataType): Promise<ApiMembersSkillEvaluationTermResultPointsIndexType> => {
  const response =
    await new ApiClient().get<ApiMembersSkillEvaluationTermResultPointsIndexType>(
      `/api/members/skill_evaluation_terms/${id}/result_points`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  id: string;
  params?: ApiMembersSkillEvaluationTermResultPointsIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersSkillEvaluationTermsResultPointsIndex = ({
  id,
  params,
  config = {},
}: PropsType): UseQueryResult<ApiMembersSkillEvaluationTermResultPointsIndexType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersSkillEvaluationTermsResultPointsIndex", id, params],
    queryFn: () => request({ id, params }),
    useErrorBoundary: (error) => {
      return error instanceof ApiResponseError && error.status >= 500;
    },
  });
};
