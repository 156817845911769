import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersEmployeeLimitSummariesShow,
  useApisManagersEmployeesLeftsCreate,
  useProvidersCurrentEmployee,
  useSearchManagersEmployees,
} from "~/hooks";

import { GridHeaderCells } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  GridRow,
  OpenedMenuInGrid,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";
import { EmployeesSearchForm } from "~/components/organisms";

import {
  EmployeeRole,
  ManagersEmployeesIndexEmployeeType,
  OwnerRole,
} from "~/domains";

export const ManagersEmployeesIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const {
    employees,
    unauthorizedEmployees,
    employeesIsLoading,
    unauthorizedEmployeesIsLoading,
    keyword,
    employeesHasNextPage,
    unauthorizedEmployeesHasNextPage,
    selectableDropdownJobCategories,
    selectedDropdownJobCategories,
    selectableDropdownJobTitles,
    selectedDropdownJobTitles,
    selectableDropdownEmployeeRoleIds,
    selectedDropdownEmployeeRoleIds,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    onDivisionChange,
    onSectionChange,
    findAndRemoveEmployee,
    findAndRemoveUnauthorizedEmployee,
    onChangeDropdownJobCategories,
    onChangeDropdownJobTitles,
    onChangeDropdownEmployeeRoleIds,
    employeesFetchNextPage,
    unauthorizedEmployeesFetchNextPage,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  } = useSearchManagersEmployees();
  const { data: fetchSummaryData } =
    useApisManagersEmployeeLimitSummariesShow();
  const { mutate: doLeftRequest } = useApisManagersEmployeesLeftsCreate();

  const handleDeleteEmployee = (
    employee: ManagersEmployeesIndexEmployeeType,
  ) => {
    if (!isDeletable(employee)) {
      alert("無効にできません");
      return;
    }

    const isConfirm = confirm("本当に無効にさせますか？");
    if (!isConfirm) return;
    doLeftRequest(
      {
        employeeId: employee.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemoveEmployee((item) => item.id === employee.id);
        },
      },
    );
  };

  const handleDeleteUnauthorizedEmployee = (
    employee: ManagersEmployeesIndexEmployeeType,
  ) => {
    const isConfirm = confirm("本当に無効にさせますか？");
    if (!isConfirm) return;
    doLeftRequest(
      {
        employeeId: employee.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemoveUnauthorizedEmployee((item) => item.id === employee.id);
        },
      },
    );
  };

  const generateGridLists = (employee: ManagersEmployeesIndexEmployeeType) => {
    return [
      `${employee.lastName}${employee.firstName}`,
      employee.employeeRole.type === OwnerRole.type
        ? "すべての部署"
        : employee.businessDivisions.map((unit) => `${unit.name}`).join(" / "),
      employee.employeeRole.type === OwnerRole.type
        ? "すべての課"
        : employee.businessSections.map((unit) => `${unit.name}`).join(" / "),
      employee.jobCategory.name,
      employee.jobTitle,
      employee.employeeRole.name,
    ];
  };

  const isDeletable = (employee: ManagersEmployeesIndexEmployeeType) => {
    if (employee.id === currentEmployee?.id) return false;
    if (currentEmployee?.employeeRole.id === EmployeeRole.OWNER.id) return true;

    return employee.employeeRole.id < (currentEmployee?.employeeRole.id || 0);
  };

  const breadCrumbItems = [
    { label: "ユーザー設定", href: "/managers/employees" },
    { label: "一覧", href: "/managers/employees" },
  ];

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <EmployeesSearchForm
          onConditionReset={onConditionReset}
          onSearchSubmit={onSearchSubmit}
          selectableDropdownJobCategories={selectableDropdownJobCategories}
          selectedDropdownJobCategories={selectedDropdownJobCategories}
          onChangeDropdownJobCategories={onChangeDropdownJobCategories}
          selectableDropdownJobTitles={selectableDropdownJobTitles}
          selectedDropdownJobTitles={selectedDropdownJobTitles}
          onChangeDropdownJobTitles={onChangeDropdownJobTitles}
          selectableDropdownEmployeeRoleIds={selectableDropdownEmployeeRoleIds}
          selectedDropdownEmployeeRoleIds={selectedDropdownEmployeeRoleIds}
          onChangeDropdownEmployeeRoleIds={onChangeDropdownEmployeeRoleIds}
          keyword={keyword}
          onChangeKeyword={onChangeKeyword}
          divisionAndSections={{
            selectableDropdownDivisions,
            selectedDropdownDivisions,
            selectableDropdownSections,
            selectedDropdownSections,
            onDivisionChange,
            onSectionChange,
          }}
        />
        {fetchSummaryData && (
          <div className="mt-12 text-lg text-secondary-600 font-bold flex items-center justify-end space-x-4">
            <h2>ライセンス</h2>
            <div>
              {fetchSummaryData.availableEmployeesSize}/
              {fetchSummaryData.currentAvailableEmployeeUpperLimit}
            </div>
          </div>
        )}
        <InfiniteScrollWithMoreButton
          itemsLength={employees.length}
          nextFetchFunction={employeesFetchNextPage}
          hasMore={employeesHasNextPage}
          isLoading={employeesIsLoading}
          className="mt-4"
        >
          <div className="grid grid-cols-6">
            <GridHeaderCells
              texts={["氏名", "部署", "課", "職種", "役職", "権限"]}
            />
            <>
              {employees.map((employee) => (
                <GridRow
                  key={employee.id}
                  lists={generateGridLists(employee)}
                  openedMenu={
                    employee.editable ? (
                      <OpenedMenuInGrid
                        cols={6}
                        buttons={[
                          {
                            text: "編集",
                            link: `/managers/employees/${employee.id}/edit`,
                            isHidden: !isDeletable(employee),
                          },
                          {
                            text: "無効化",
                            onClick: () => handleDeleteEmployee(employee),
                            isHidden: !isDeletable(employee),
                          },
                        ]}
                      />
                    ) : undefined
                  }
                />
              ))}
            </>
          </div>
        </InfiniteScrollWithMoreButton>
        <h3 className="mt-12 font-semibold">承認待ちユーザー</h3>
        <InfiniteScrollWithMoreButton
          itemsLength={unauthorizedEmployees.length}
          nextFetchFunction={unauthorizedEmployeesFetchNextPage}
          hasMore={unauthorizedEmployeesHasNextPage}
          isLoading={unauthorizedEmployeesIsLoading}
          className="mt-4"
        >
          <div className="grid grid-cols-6">
            <GridHeaderCells
              texts={["氏名", "部署", "課", "職種", "役職", "権限"]}
            />
            <>
              {unauthorizedEmployees.map((employee) => (
                <GridRow
                  key={employee.id}
                  lists={generateGridLists(employee)}
                  openedMenu={
                    employee.editable ? (
                      <OpenedMenuInGrid
                        cols={6}
                        buttons={[
                          {
                            text: "編集",
                            link: `/managers/employees/${employee.id}/edit`,
                            isHidden: !isDeletable(employee),
                          },
                          {
                            text: "無効化",
                            onClick: () =>
                              handleDeleteUnauthorizedEmployee(employee),
                            isHidden: !isDeletable(employee),
                          },
                        ]}
                      />
                    ) : undefined
                  }
                />
              ))}
            </>
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
