import { snakeCase } from "snake-case";

export const ConvertParamsToFormData = (
  params: Record<string, unknown>,
  formData = new FormData(),
  namespace = "",
): FormData => {
  for (const propertyName in params) {
    const value = params[propertyName];
    const convertedPropertyName = snakeCase(propertyName);
    const formKey = namespace
      ? `${namespace}[${convertedPropertyName}]`
      : convertedPropertyName;
    if (value === null || value === undefined) {
      formData.append(formKey, "");
    } else if (value instanceof Array) {
      value.forEach((element) => {
        if (typeof element !== "object" || element instanceof File) {
          formData.append(`${formKey}[]`, element as string);
          return;
        }
        const tempFormKey = `${formKey}[]`;
        ConvertParamsToFormData(
          element as Record<string, unknown>,
          formData,
          tempFormKey,
        );
      });
      // FIXME: 空配列で保存すべき
      if (!value.length) {
        formData.append(`${formKey}[]`, "");
      }
    } else if (value instanceof File) {
      formData.append(formKey, value);
    } else if (value instanceof Date) {
      formData.append(formKey, value.toISOString());
    } else if (typeof value === "string") {
      formData.append(formKey, value);
    } else if (typeof value === "number" || typeof value === "boolean") {
      formData.append(formKey, value.toString());
    } else if (typeof value === "object") {
      ConvertParamsToFormData(
        value as Record<string, unknown>,
        formData,
        formKey,
      );
    } else {
      formData.append(formKey, value as string);
    }
  }
  return formData;
};
