import React, { ChangeEvent, FC, FormEvent, MouseEvent } from "react";

import { toast } from "react-toastify";

import { useApisManagersRoleplayingConditionsDestroy } from "~/hooks";

import {
  Form,
  Button,
  FormSubmitButton,
  GridHeaderCells,
} from "~/components/atoms";
import {
  LabelWithTextField,
  GridRow,
  InfiniteScrollWithMoreButton,
  OpenedMenuInGrid,
  RangeDatePicker,
  LabelWithMultipleDropdownField,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import {
  BusinessDivisionType,
  ChildType,
  MultiValueType,
  OptionType,
  ParentType,
  RangeDatePropsType,
  RoleplayingConditionType,
} from "~/domains";

type PropsType = {
  className?: string;
  roleplayingConditions: RoleplayingConditionType[];
  hasNextPage?: boolean;
  keyword: string;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  selectableDropdownCreatedEmployees: OptionType[];
  selectedDropdownCreatedEmployees: MultiValueType<OptionType>;
  selectableDropdownParticipationEmployees: OptionType[];
  selectedDropdownParticipationEmployees: MultiValueType<OptionType>;
  selectableDropdownDivisions: BusinessDivisionType[];
  selectedDropdownDivisions: BusinessDivisionType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  onChangeDropdownCreatedEmployees: (value: MultiValueType<OptionType>) => void;
  onChangeDropdownParticipationEmployees: (
    value: MultiValueType<OptionType>,
  ) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  findAndRemove: (func: (item: RoleplayingConditionType) => boolean) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const ManagersRoleplayingConditionsIndexTemplate: FC<PropsType> = ({
  className = "",
  roleplayingConditions,
  hasNextPage,
  isLoading,
  startDate,
  endDate,
  keyword,
  selectableDropdownCreatedEmployees,
  selectedDropdownCreatedEmployees,
  selectableDropdownParticipationEmployees,
  selectedDropdownParticipationEmployees,
  selectableDropdownDivisions,
  selectedDropdownDivisions,
  selectableDropdownSections,
  selectedDropdownSections,
  onDivisionChange,
  onSectionChange,
  onChangeDropdownCreatedEmployees,
  onChangeDropdownParticipationEmployees,
  fetchNextPage,
  onChangeDateRange,
  findAndRemove,
  onChangeKeyword,
  onConditionReset,
  onSearchSubmit,
}: PropsType) => {
  const { mutate: deleteRequest } =
    useApisManagersRoleplayingConditionsDestroy();

  const handleDelete = (roleplayingCondition: RoleplayingConditionType) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: roleplayingCondition.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((item) => item.id === roleplayingCondition.id);
        },
      },
    );
  };

  const generateRoleplayingConditionLists = (
    roleplayingCondition: RoleplayingConditionType,
  ) => {
    return [
      roleplayingCondition.postDate,
      roleplayingCondition.title,
      roleplayingCondition.businessDivisions
        .map((division) => division.name)
        .join("、"),
      roleplayingCondition.businessSections
        .map((section) => section.name)
        .join("、"),
      roleplayingCondition.employee.name,
      roleplayingCondition.participationEmployees
        .map((employee) => employee.name)
        .join("、"),
    ];
  };
  return (
    <div className={className}>
      <Form onSubmit={onSearchSubmit} className="space-y-6">
        <div className="grid miniTablet:grid-cols-2 gap-4">
          <MultiParentsWithMultiChildrenDropdownField
            allParents={selectableDropdownDivisions}
            parentsValue={selectedDropdownDivisions}
            parentLabel="部署"
            parentsOnChange={onDivisionChange}
            allChildren={selectableDropdownSections}
            childrenValue={selectedDropdownSections}
            childLabel="課"
            childrenOnChange={onSectionChange}
          />
          <LabelWithMultipleDropdownField
            name="createdEmployees"
            options={selectableDropdownCreatedEmployees}
            value={selectedDropdownCreatedEmployees}
            onChange={onChangeDropdownCreatedEmployees}
            labelText="作成者"
          />
          <RangeDatePicker
            labelText="期間指定"
            startDate={startDate}
            endDate={endDate}
            onChange={onChangeDateRange}
          />
          <LabelWithMultipleDropdownField
            name="participationEmployees"
            options={selectableDropdownParticipationEmployees}
            value={selectedDropdownParticipationEmployees}
            onChange={onChangeDropdownParticipationEmployees}
            labelText="参加者"
          />
          <LabelWithTextField
            labelText="フリーワード"
            type="search"
            name="keyword"
            placeholder="フリーワード検索"
            value={keyword}
            onChange={onChangeKeyword}
          />
        </div>
        <div className="flex items-center justify-end space-x-4">
          <Button
            color="gray"
            outline
            className="w-full miniTablet:w-auto"
            text="リセット"
            onClick={onConditionReset}
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full miniTablet:w-auto"
          />
        </div>
      </Form>
      <InfiniteScrollWithMoreButton
        itemsLength={roleplayingConditions.length}
        nextFetchFunction={fetchNextPage}
        isLoading={isLoading}
        hasMore={hasNextPage}
        className="mt-9"
      >
        <div className="grid grid-cols-6">
          <GridHeaderCells
            texts={["日付", "タイトル", "部署", "課", "作成者", "参加者"]}
          />
          {roleplayingConditions.map((roleplayingCondition) => (
            <GridRow
              key={roleplayingCondition.id}
              lists={generateRoleplayingConditionLists(roleplayingCondition)}
              openedMenu={
                <OpenedMenuInGrid
                  cols={6}
                  buttons={[
                    {
                      text: "編集",
                      link: `/managers/roleplaying_conditions/${roleplayingCondition.id}/edit`,
                    },
                    {
                      text: "削除",
                      onClick: () => handleDelete(roleplayingCondition),
                    },
                  ]}
                />
              }
            />
          ))}
        </div>
      </InfiniteScrollWithMoreButton>
    </div>
  );
};
