import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiPartnerConsolesCompaniesBusinessDivisionsAndSectionsResponseType } from "~/domains";

type RequestDataType = {
  companyId: string;
};

const request = async ({
  companyId,
}: RequestDataType): Promise<ApiPartnerConsolesCompaniesBusinessDivisionsAndSectionsResponseType> => {
  const response =
    await new ApiClient().get<ApiPartnerConsolesCompaniesBusinessDivisionsAndSectionsResponseType>(
      `/api/partner_consoles/companies/${companyId}/business_divisions_and_sections`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  companyId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisPartnerConsolesCompaniesBusinessDivisionsAndSectionsIndex =
  ({
    companyId,
    config = {},
  }: PropsType): UseQueryResult<ApiPartnerConsolesCompaniesBusinessDivisionsAndSectionsResponseType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisPartnerConsolesCompaniesBusinessDivisionsAndSectionsIndex",
        companyId,
      ],
      queryFn: () => request({ companyId }),
    });
  };
