import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisMembersAnalysisInsightPostsCountCalendarsIndexRequestTypes,
  ApisMembersAnalysisInsightPostsCountCalendarsIndexResponseTypes,
} from "~/domains";

type RequestDataType = {
  params: ApisMembersAnalysisInsightPostsCountCalendarsIndexRequestTypes;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApisMembersAnalysisInsightPostsCountCalendarsIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisMembersAnalysisInsightPostsCountCalendarsIndexResponseTypes>(
      "/api/members/analysis/insight_posts_count_calendars",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params: ApisMembersAnalysisInsightPostsCountCalendarsIndexRequestTypes;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersAnalysisInsightPostsCountCalendarsIndex = ({
  params,
  config = {},
}: PropsType): UseQueryResult<ApisMembersAnalysisInsightPostsCountCalendarsIndexResponseTypes> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersAnalysisInsightPostsCountCalendarsIndex", params],
    queryFn: () => request({ params }),
  });
};
