import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersKnowledgeInsightPostsBookmarksShowResponseType } from "~/domains";

type RequestDataType = {
  knowledgeInsightPostId: string;
};

const request = async ({
  knowledgeInsightPostId,
}: RequestDataType): Promise<ApiMembersKnowledgeInsightPostsBookmarksShowResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersKnowledgeInsightPostsBookmarksShowResponseType>(
      `/api/members/knowledge_insight_posts/${knowledgeInsightPostId}/bookmarks`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  knowledgeInsightPostId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersKnowledgeInsightPostsBookmarksShow = ({
  knowledgeInsightPostId,
  config = {},
}: PropsType): UseQueryResult<ApiMembersKnowledgeInsightPostsBookmarksShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisMembersKnowledgeInsightPostsBookmarksShow",
      knowledgeInsightPostId,
    ],
    queryFn: () => request({ knowledgeInsightPostId }),
  });
};
