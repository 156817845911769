import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { useApisManagersInsightPostObjectivesEdit } from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { InsightPostObjectiveForm } from "~/components/organisms";

export const ManagersInsightPostObjectivesEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();

  const { data } = useApisManagersInsightPostObjectivesEdit({
    id: id,
  });

  const breadCrumbItems = [
    { label: "インサイト目標", href: "/managers/insight_post_objectives" },
    { label: "編集", href: "/managers/insight_post_objectives/new" },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        {data && (
          <InsightPostObjectiveForm
            insightPostObjective={data.insightPostObjective}
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
