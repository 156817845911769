import { useState, useCallback, MouseEvent } from "react";

import { subMonths, addMonths } from "date-fns";

import { toDateString } from "~/libs";

type ReturnType = {
  targetDate: Date;
  targetDateString: string;
  handlePrevMonthClick: (e: MouseEvent<HTMLButtonElement>) => void;
  handleNextMonthClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const usePrevNextSelectMonth = (initial = new Date()): ReturnType => {
  const [targetDate, setTargetDate] = useState<Date>(initial);
  const handlePrevMonthClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setTargetDate((prev) => subMonths(prev, 1));
  };

  const handleNextMonthClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setTargetDate((prev) => addMonths(prev, 1));
    },
    [],
  );

  return {
    targetDate,
    targetDateString: toDateString(targetDate),
    handlePrevMonthClick,
    handleNextMonthClick,
  };
};
