import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";

import { InfiniteDataType, toDateString } from "~/libs";

import {
  useInput,
  useDateRange,
  useApisManagersKnowledgeInsightPostsIndex,
  ApiManagersKnowledgeInsightPostsIndexParamsType,
  usePostSortType,
  useMultipleDropdown,
  useArray,
  useApisManagersKnowledgeInsightPostSearchItemsIndex,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
} from "~/hooks";

import { RangeDatePropsType } from "~/components/molecules";

import {
  ApiManagersKnowledgeInsightPostsIndexType,
  KnowledgeInsightPostType,
  MultiValueType,
  OptionType,
  SingleValueType,
  SortType,
} from "~/domains";

type ReturnType = {
  knowledgeInsightPosts: KnowledgeInsightPostType[];
  data?: InfiniteDataType<ApiManagersKnowledgeInsightPostsIndexType>;
  isLoading: boolean;
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  selectableDropdownHasCommentIds: OptionType[];
  selectedDropdownHasCommentId?: SingleValueType<OptionType>;
  selectableDropdownHasBookmarkIds: OptionType[];
  selectedDropdownHasBookmarkId?: SingleValueType<OptionType>;
  hasNextPage?: boolean;
  sort: SortType;
  selectableDropdownEmployees: OptionType[];
  selectedDropdownEmployees: MultiValueType<OptionType>;
  deleteIndexKnowledgeInsightPost: (index: number) => void;
  updateIndexKnowledgeInsightPost: (
    newItem: KnowledgeInsightPostType,
    index: number,
  ) => void;
  addKnowledgeInsightPost: (newItem: KnowledgeInsightPostType) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDropdownEmployees: (options: MultiValueType<OptionType>) => void;
  fetchNextPage: () => void;
  setSort: (val: SortType) => void;
  onChangeDropdownHasCommentId: (value: SingleValueType<OptionType>) => void;
  onChangeDropdownHasBookmarkId: (value: SingleValueType<OptionType>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

type PropsType = {
  employeeRoleId: number;
};

export const useSearchManagersKnowledgeInsightPosts = ({
  employeeRoleId,
}: PropsType): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const [
    {
      statusValue: hasComment,
      selectedDropdownCompleteStatusId: selectedDropdownHasCommentId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasCommentIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasCommentId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "リプライあり",
      false: "リプライなし",
    },
  });
  const [
    {
      statusValue: hasBookmark,
      selectedDropdownCompleteStatusId: selectedDropdownHasBookmarkId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasBookmarkIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasBookmarkId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "ブックマークあり",
      false: "ブックマークなし",
    },
  });
  const { sort, snakeKeyValue, setSort } = usePostSortType();

  const [params, setParams] =
    useState<ApiManagersKnowledgeInsightPostsIndexParamsType>({
      sortType: snakeKeyValue,
    });

  const { items, setItems, deleteIndexItem, updateIndexItem, unshiftItem } =
    useArray<KnowledgeInsightPostType>([]);

  const [
    {
      dropdownValue: selectedDropdownEmployees,
      value: selectedEmployees,
      onChange: onChangeDropdownEmployees,
      dropdownSelectableValue: selectableDropdownEmployees,
      setSelectableOption: setSelectableDropdownEmployees,
    },
  ] = useMultipleDropdown();

  useApisManagersKnowledgeInsightPostSearchItemsIndex({
    employeeRoleId,
    config: {
      onSuccess: (data) => {
        onChangeDropdownEmployees([]);
        setSelectableDropdownEmployees(data.employees);
      },
    },
  });

  const { data, isFetching, fetchNextPage, hasNextPage } =
    useApisManagersKnowledgeInsightPostsIndex({
      params: { employeeRoleId, ...params },
      config: {
        onSuccess: (data) => {
          setItems(data.pages.map((page) => page.knowledgeInsightPosts).flat());
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      hasComment,
      hasBookmark,
      employeeIds: selectedEmployees?.map((employee) => employee.id),
      sortType: snakeKeyValue,
      createdAtFrom: startDate ? toDateString(startDate) : "",
      createdAtTo: endDate ? toDateString(endDate) : "",
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDateRange([null, null]);
    onChangeDropdownHasBookmarkId(null);
    onChangeDropdownHasCommentId(null);
    onChangeDropdownEmployees([]);
    setParams({ sortType: snakeKeyValue });
    setFalseRightSidebarMain();
  };

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      sortType: snakeKeyValue,
    }));
  }, [snakeKeyValue]);

  return {
    knowledgeInsightPosts: items,
    data,
    keyword,
    isLoading: isFetching,
    selectableDropdownHasCommentIds,
    selectedDropdownHasCommentId,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    hasNextPage,
    startDate,
    endDate,
    sort,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    addKnowledgeInsightPost: unshiftItem,
    updateIndexKnowledgeInsightPost: updateIndexItem,
    deleteIndexKnowledgeInsightPost: deleteIndexItem,
    fetchNextPage,
    setSort,
    onChangeDropdownEmployees,
    onChangeDateRange,
    onChangeKeyword,
    onChangeDropdownHasCommentId,
    onChangeDropdownHasBookmarkId,
    onSearchSubmit,
    onConditionReset,
  };
};
