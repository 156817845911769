import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import {
  ApiMembersPrivatePersonalMemoMutateType,
  RawDraftContentState,
} from "~/domains";

type RequestDataType = {
  body: {
    content: RawDraftContentState;
    attachFiles?: File[];
  };
};

const request = ({ body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ privatePersonalMemo: body });
  return new ApiClient()
    .post<ApiMembersPrivatePersonalMemoMutateType>(
      "/api/members/private_personal_memos",
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersPrivatePersonalMemosCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiMembersPrivatePersonalMemoMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
