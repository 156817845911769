import React, { FC } from "react";

import { useApisMembersMeetingNotesNew } from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { ManagersMeetingNoteForm } from "~/components/organisms";

export const ManagersMeetingNotesNew: FC = () => {
  const breadCrumbItems = [
    { label: "1on1・MTG", href: "/managers/meeting_notes" },
    { label: "新規作成", href: "/managers/meeting_notes/new" },
  ];

  const { data: meetingNoteFetchData } = useApisMembersMeetingNotesNew();

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          <ManagersMeetingNoteForm
            meetingNoteTemplates={meetingNoteFetchData?.meetingNoteTemplates}
            selectableEmployees={meetingNoteFetchData?.selectableEmployees}
          />
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
