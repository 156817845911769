import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";

import { toDateString } from "~/libs";

import {
  useInput,
  useDateRange,
  useApisMembersKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndex,
  usePostSortType,
  useMultipleDropdown,
  useArray,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
} from "~/hooks";

import { RangeDatePropsType } from "~/components/molecules";

import {
  KnowledgeInsightPostType,
  MultiValueType,
  OptionType,
  SortType,
  ApiMembersBookmarkedKnowledgeInsightPostsIndexRequestType,
  SingleValueType,
} from "~/domains";

type ReturnType = {
  knowledgeInsightPosts: KnowledgeInsightPostType[];
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  hasNextPage?: boolean;
  isLoading: boolean;
  sort: SortType;
  selectableDropdownEmployees: OptionType[];
  selectedDropdownEmployees: MultiValueType<OptionType>;
  bookmarkFolderId: string;
  bookmarkFolderName: string;
  selectableDropdownHasCommentIds: OptionType[];
  selectedDropdownHasCommentId?: SingleValueType<OptionType>;
  deleteIndexKnowledgeInsightPost: (index: number) => void;
  updateIndexKnowledgeInsightPost: (
    newItem: KnowledgeInsightPostType,
    index: number,
  ) => void;
  onChangeDropdownHasCommentId: (value: SingleValueType<OptionType>) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDropdownEmployees: (options: MultiValueType<OptionType>) => void;
  fetchNextPage: () => void;
  setSort: (val: SortType) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

type PropsType = {
  knowledgeInsightPostBookmarkFolderId: string;
};

export const useSearchMembersKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPosts =
  ({ knowledgeInsightPostBookmarkFolderId }: PropsType): ReturnType => {
    const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
    const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
      useInput("");
    const [{ startDate, endDate, onChange: onChangeDateRange }] =
      useDateRange();
    const { sort, snakeKeyValue, setSort } = usePostSortType();
    const [
      {
        statusValue: hasComment,
        selectedDropdownCompleteStatusId: selectedDropdownHasCommentId,
        selectableDropdownCompleteStatusIds: selectableDropdownHasCommentIds,
        onChangeDropdownCompleteStatusId: onChangeDropdownHasCommentId,
      },
    ] = useCompleteStatusDropdown({
      initialStatusNames: {
        true: "リプライあり",
        false: "リプライなし",
      },
    });
    const [params, setParams] =
      useState<ApiMembersBookmarkedKnowledgeInsightPostsIndexRequestType>({
        sortType: snakeKeyValue,
      });
    const [bookmarkFolderName, setBookmarkFolderName] = useState("");
    const { items, setItems, deleteIndexItem, updateIndexItem } =
      useArray<KnowledgeInsightPostType>([]);

    const [
      {
        dropdownValue: selectedDropdownEmployees,
        value: selectedEmployees,
        onChange: onChangeDropdownEmployees,
        dropdownSelectableValue: selectableDropdownEmployees,
      },
    ] = useMultipleDropdown();

    const { fetchNextPage, hasNextPage, isFetching } =
      useApisMembersKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndex(
        {
          knowledgeInsightPostBookmarkFolderId,
          params: { ...params },
          config: {
            onSuccess: (data) => {
              setItems(
                data.pages.map((page) => page.knowledgeInsightPosts).flat(),
              );
              data.pages[0] &&
                setBookmarkFolderName(data.pages[0].bookmarkFolder.name);
            },
          },
        },
      );

    const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setParams({
        keyword,
        employeeIds: selectedEmployees?.map((employee) => employee.id),
        sortType: snakeKeyValue,
        hasComment,
        createdAtFrom: startDate ? toDateString(startDate) : "",
        createdAtTo: endDate ? toDateString(endDate) : "",
      });
      setFalseRightSidebarMain();
    };

    const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setKeyword("");
      onChangeDateRange([null, null]);
      onChangeDropdownHasCommentId(null);
      onChangeDropdownEmployees([]);
      setSort("createdAtAsc");
      setParams({ sortType: snakeKeyValue });
      setFalseRightSidebarMain();
    };

    useEffect(() => {
      setParams((prevParams) => ({
        ...prevParams,
        sortType: snakeKeyValue,
      }));
    }, [snakeKeyValue]);

    return {
      knowledgeInsightPosts: items,
      keyword,
      hasNextPage,
      isLoading: isFetching,
      startDate,
      endDate,
      sort,
      selectableDropdownHasCommentIds,
      selectedDropdownHasCommentId,
      selectableDropdownEmployees,
      selectedDropdownEmployees,
      bookmarkFolderName,
      bookmarkFolderId: knowledgeInsightPostBookmarkFolderId,
      updateIndexKnowledgeInsightPost: updateIndexItem,
      deleteIndexKnowledgeInsightPost: deleteIndexItem,
      fetchNextPage,
      setSort,
      onChangeDropdownHasCommentId,
      onChangeDropdownEmployees,
      onChangeDateRange,
      onChangeKeyword,
      onSearchSubmit,
      onConditionReset,
    };
  };
