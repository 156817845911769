import React, { FC, FormEvent } from "react";

import {
  FormSubmitButton,
  Form,
  DragDropProvider,
  CatchTitleBorder,
} from "~/components/atoms";
import { NameOrderableForm } from "~/components/molecules";
import { Fetch, ParentWithChildDropdownField } from "~/components/organisms";

import { BusinessDivisionType, ParentType, ChildType } from "~/domains";

type ItemType = {
  id: string;
  name: string;
};

type PropsType = {
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  fetch: {
    loading: boolean;
    error: string | null;
    items: ItemType[];
  };
  divisionSection: {
    selectableDivisions: BusinessDivisionType[];
    selectedDivision?: BusinessDivisionType | null;
    onDivisionChange: (newValue: ParentType | null) => void;
    optionSelectableSections: ChildType[];
    optionSelectedSection?: ChildType | null;
    onSectionChange: (newValue: ChildType | null) => void;
  };
  changeDisplayOrder: (
    items: ItemType[],
    beforeIndex: number,
    index: number,
  ) => void;
  catchTitle: string;
  noContentMessage: string;
};

export const CategoryDisplayOrderChange: FC<PropsType> = ({
  onSearchSubmit,
  divisionSection,
  fetch,
  changeDisplayOrder,
  catchTitle,
  noContentMessage,
}: PropsType) => {
  const {
    selectableDivisions,
    selectedDivision,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSection,
    onSectionChange,
  } = divisionSection;

  return (
    <>
      <Form onSubmit={onSearchSubmit} className="space-y-6">
        <>
          <div className="grid miniTablet:grid-cols-2 gap-4">
            <ParentWithChildDropdownField
              allParents={selectableDivisions}
              parentValue={selectedDivision}
              parentLabel="部署"
              parentsOnChange={onDivisionChange}
              allChildren={optionSelectableSections}
              childValue={optionSelectedSection}
              childLabel="課"
              childrenOnChange={onSectionChange}
            />
          </div>
          <div className="text-right">
            <FormSubmitButton value="検索する" color="primary" />
          </div>
        </>
      </Form>
      <Fetch loading={fetch.loading} error={fetch.error} data={fetch.items}>
        {fetch.items.length ? (
          <div className="mt-9">
            <CatchTitleBorder text={catchTitle} />
            <div className="mt-6">
              <DragDropProvider>
                {fetch.items.map((item, index) => (
                  <NameOrderableForm
                    key={`item${item.id}`}
                    name={item.name}
                    index={index}
                    className="border-b py-3"
                    changeDisplayOrder={(
                      beforeIndex: number,
                      newIndex: number,
                    ) => changeDisplayOrder(fetch.items, beforeIndex, newIndex)}
                  />
                ))}
              </DragDropProvider>
            </div>
          </div>
        ) : (
          <p className="text-center mt-9">{noContentMessage}</p>
        )}
      </Fetch>
    </>
  );
};
