import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersInsightPostsBookmarksShowResponseType } from "~/domains";

type RequestDataType = {
  insightPostId: string;
};

const request = async ({
  insightPostId,
}: RequestDataType): Promise<ApiMembersInsightPostsBookmarksShowResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersInsightPostsBookmarksShowResponseType>(
      `/api/members/insight_posts/${insightPostId}/bookmarks`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  insightPostId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersInsightPostsBookmarksShow = ({
  insightPostId,
  config = {},
}: PropsType): UseQueryResult<ApiMembersInsightPostsBookmarksShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersInsightPostsBookmarksShow", insightPostId],
    queryFn: () => request({ insightPostId }),
  });
};
