import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";

import { InfiniteDataType, toDateString } from "~/libs";

import {
  useInput,
  useDateRange,
  useApisManagersKaizenIdeasIndex,
  usePostSortType,
  useMultipleDropdown,
  useArray,
  useApisMembersKaizenIdeaSearchItemsIndex,
  useProvidersCurrentEmployee,
  useMultiDivisionMultiSectionDropdown,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
  useBoolean,
} from "~/hooks";

import { RangeDatePropsType } from "~/components/molecules";

import {
  ApiManagersKaizenIdeasIndexResponseType,
  KaizenIdeaType,
  MultiValueType,
  OptionType,
  SortType,
  ApiManagersKaizenIdeasIndexRequestType,
  AllPriorityRatings,
  ChildType,
  ParentType,
  SingleValueType,
  AvatarAndNameEmployeeType,
} from "~/domains";

type ReturnType = {
  kaizenIdeas: KaizenIdeaType[];
  data?: InfiniteDataType<ApiManagersKaizenIdeasIndexResponseType>;
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  mentionableEmployees: AvatarAndNameEmployeeType[];
  selectableDropdownCompleteStatusIds: OptionType[];
  selectedDropdownCompleteStatusId?: SingleValueType<OptionType>;
  selectableDropdownIsFromClientIds: OptionType[];
  selectedDropdownIsFromClientId?: SingleValueType<OptionType>;
  hasNextPage?: boolean;
  isLoading: boolean;
  sort: SortType;
  isBelongingEmployee: boolean;
  selectableDropdownPriorityRatings: OptionType[];
  selectedDropdownPriorityRatings: MultiValueType<OptionType>;
  selectableDropdownProvidingServices: OptionType[];
  selectedDropdownProvidingServices: MultiValueType<OptionType>;
  selectedDropdownEmployees: ParentType[];
  selectableDropdownEmployees: ParentType[];
  selectedDropdownArchivedEmployees: ParentType[];
  selectableDropdownArchivedEmployees: ParentType[];
  selectableDropdownDivisions: ParentType[];
  selectedDropdownDivisions: ParentType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  onEmployeesChange: (value: ParentType[]) => void;
  onArchivedEmployeesChange: (value: ParentType[]) => void;
  deleteIndexKaizenIdea: (index: number) => void;
  updateIndexKaizenIdea: (newItem: KaizenIdeaType, index: number) => void;
  unshiftKaizenIdea: (newItem: KaizenIdeaType) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeIsBelongingEmployee: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDropdownPriorityRatings: (
    options: MultiValueType<OptionType>,
  ) => void;
  onChangeDropdownProvidingServices: (
    options: MultiValueType<OptionType>,
  ) => void;
  fetchNextPage: () => void;
  setSort: (val: SortType) => void;
  onChangeDropdownCompleteStatusId: (
    value: SingleValueType<OptionType>,
  ) => void;
  onChangeDropdownIsFromClientId: (value: SingleValueType<OptionType>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchManagersKaizenIdeas = (): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const {
    isChecked: isBelongingEmployee,
    onChange: onChangeIsBelongingEmployee,
    setFalse: setBelongingEmployeeFalse,
  } = useBoolean(false);
  const [
    {
      statusValue: completed,
      selectedDropdownCompleteStatusId,
      selectableDropdownCompleteStatusIds,
      onChangeDropdownCompleteStatusId,
    },
  ] = useCompleteStatusDropdown();

  const [
    {
      statusValue: isFromClient,
      selectedDropdownCompleteStatusId: selectedDropdownIsFromClientId,
      selectableDropdownCompleteStatusIds: selectableDropdownIsFromClientIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownIsFromClientId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "顧客からの発信",
      false: "本人からの発信",
    },
  });

  const { sort, snakeKeyValue, setSort } = usePostSortType();
  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
    selectableEmployees,
    selectedEmployees,
    onEmployeesChange,
    selectableArchivedEmployees,
    selectedArchivedEmployees,
    onArchivedEmployeesChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    isSelectEmployee: true,
  });

  const [params, setParams] = useState<ApiManagersKaizenIdeasIndexRequestType>({
    sortType: snakeKeyValue,
  });

  const { items, setItems, deleteIndexItem, updateIndexItem, unshiftItem } =
    useArray<KaizenIdeaType>([]);

  const { items: mentionableEmployees, setItems: setMentionableEmployees } =
    useArray<AvatarAndNameEmployeeType>([]);

  const [
    {
      dropdownValue: selectedDropdownProvidingServices,
      value: selectedProvidingServices,
      onChange: onChangeDropdownProvidingServices,
      dropdownSelectableValue: selectableDropdownProvidingServices,
      setSelectableOption: setSelectableDropdownProvidingServices,
    },
  ] = useMultipleDropdown();

  useApisMembersKaizenIdeaSearchItemsIndex({
    config: {
      onSuccess: (res) => {
        setSelectableDropdownProvidingServices(res.providingServices);
        setMentionableEmployees(res.employees);
      },
    },
  });

  const [
    {
      dropdownValue: selectedDropdownPriorityRatings,
      value: selectedPriorityRatings,
      onChange: onChangeDropdownPriorityRatings,
      dropdownSelectableValue: selectableDropdownPriorityRatings,
    },
  ] = useMultipleDropdown({
    selectableValue: AllPriorityRatings.map((ele) => ({
      id: ele.id.toString(),
      name: "★".repeat(ele.id),
    })),
  });

  const { data, isFetching, fetchNextPage, hasNextPage } =
    useApisManagersKaizenIdeasIndex({
      params,
      config: {
        onSuccess: (data) => {
          setItems(data.pages.map((page) => page.kaizenIdeas).flat());
        },
      },
    });
  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      completed,
      businessDivisionIds: selectedDivisions.map((ele) => ele.id),
      businessSectionIds: optionSelectedSections.map((ele) =>
        ele.id.toString(),
      ),
      isFromClient,
      isBelongingEmployee,
      employeeIds: [...selectedEmployees, ...selectedArchivedEmployees].map(
        (ele) => ele.id,
      ),
      priorityRatingIds: selectedPriorityRatings?.map((ele) => ele.id),
      providingServiceIds: selectedProvidingServices?.map((ele) => ele.id),
      sortType: snakeKeyValue,
      createdAtFrom: startDate ? toDateString(startDate) : "",
      createdAtTo: endDate ? toDateString(endDate) : "",
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownCompleteStatusId(null);
    onChangeDropdownIsFromClientId(null);
    onChangeDropdownPriorityRatings([]);
    onChangeDropdownProvidingServices([]);
    onChangeDateRange([null, null]);
    onEmployeesChange([]);
    onDivisionChange([]);
    onSectionChange([]);
    onArchivedEmployeesChange([]);
    setParams({});
    setFalseRightSidebarMain();
    setBelongingEmployeeFalse();
  };

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      sortType: snakeKeyValue,
    }));
  }, [snakeKeyValue]);

  return {
    kaizenIdeas: items,
    data,
    keyword,
    mentionableEmployees,
    selectableDropdownCompleteStatusIds,
    selectedDropdownCompleteStatusId,
    selectableDropdownIsFromClientIds,
    selectedDropdownIsFromClientId,
    isLoading: isFetching,
    hasNextPage,
    startDate,
    endDate,
    sort,
    isBelongingEmployee,
    selectableDropdownPriorityRatings,
    selectedDropdownPriorityRatings,
    selectableDropdownProvidingServices,
    selectedDropdownProvidingServices,
    selectedDropdownEmployees: selectedEmployees,
    selectableDropdownEmployees: selectableEmployees,
    selectableDropdownDivisions: selectableDivisions,
    selectedDropdownDivisions: selectedDivisions,
    selectableDropdownSections: optionSelectableSections,
    selectedDropdownSections: optionSelectedSections,
    selectedDropdownArchivedEmployees: selectedArchivedEmployees,
    selectableDropdownArchivedEmployees: selectableArchivedEmployees,
    onDivisionChange,
    onSectionChange,
    onEmployeesChange,
    onArchivedEmployeesChange,
    unshiftKaizenIdea: unshiftItem,
    updateIndexKaizenIdea: updateIndexItem,
    deleteIndexKaizenIdea: deleteIndexItem,
    fetchNextPage,
    setSort,
    onChangeDropdownPriorityRatings,
    onChangeDropdownProvidingServices,
    onChangeDateRange,
    onChangeKeyword,
    onChangeIsBelongingEmployee,
    onChangeDropdownCompleteStatusId,
    onChangeDropdownIsFromClientId,
    onSearchSubmit,
    onConditionReset,
  };
};
