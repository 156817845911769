import React, { FC } from "react";

import {
  useApisManagersRoleplayingConditionEmployeesDailyRoleplayingTimesIndex,
  usePrevNextSelectMonth,
} from "~/hooks";

import { ActivityOriginalCalendar } from "~/components/molecules";

type PropsType = {
  className?: string;
  employeeId: string;
  title?: string;
};

export const RoleplayingConditionSelectEmployeeDailyCountCalendar: FC<
  PropsType
> = ({ className = "", employeeId, title }: PropsType) => {
  const {
    targetDate,
    targetDateString,
    handlePrevMonthClick,
    handleNextMonthClick,
  } = usePrevNextSelectMonth();

  const { data, isLoading } =
    useApisManagersRoleplayingConditionEmployeesDailyRoleplayingTimesIndex({
      employeeId,
      params: {
        targetDate: targetDateString,
      },
    });
  return (
    <ActivityOriginalCalendar
      title={title}
      isLoading={isLoading}
      dailyActivity={data?.dailyRoleplayingTimes.map((v) => ({
        date: v.postDate,
        postsCount: v.times,
      }))}
      targetDate={targetDate}
      onPrevMonthClick={handlePrevMonthClick}
      onNextMonthClick={handleNextMonthClick}
      isForRate={false}
      className={className}
    />
  );
};
