import React, { FC, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useDropdown,
  useProvidersCurrentEmployee,
  useApisManagersBusinessSectionsEdit,
  useApisManagersBusinessSectionsUpdate,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { BusinessSectionForm } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const ManagersBusinessSectionsEdit: FC = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const { refetch } = useProvidersCurrentEmployee();

  const [
    { value: businessSectionName, onChange: onChangeBusinessSectionName },
    setBusinessSectionName,
  ] = useInput("");
  const [
    {
      dropdownValue: selectedBusinessDivision,
      onChange: onChangeBusinessDivision,
      handleSetSelectableOption: handleSetSelectableOption,
      dropdownSelectableValue: selectableBusinessDivisions,
    },
    setBusinessDivisionDropdown,
  ] = useDropdown();

  const { isError } = useApisManagersBusinessSectionsEdit({
    id,
    config: {
      onSuccess: (data) => {
        setBusinessSectionName(data.businessSection.name);
        setBusinessDivisionDropdown(data.businessSection.businessDivision);
        handleSetSelectableOption(data.selectableBusinessDivisions);
      },
    },
  });

  const { mutate: updateRequest, isLoading: isSubmitting } =
    useApisManagersBusinessSectionsUpdate();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      name: businessSectionName,
      businessDivisionId: selectedBusinessDivision?.value || "",
    };
    updateRequest(
      {
        body,
        id,
      },
      {
        onSuccess: (data) => {
          navigate("/managers/business_sections");
          toast(data.message);
          refetch();
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "課設定", href: "/managers/business_sections" },
    { label: "編集", href: `/managers/business_sections/${id}/edit` },
  ];

  if (isError) {
    return <NotFoundIndex />;
  }

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <BusinessSectionForm
          businessSectionName={businessSectionName}
          onChangeBusinessSectionName={onChangeBusinessSectionName}
          selectedBusinessDivision={selectedBusinessDivision}
          onChangeBusinessDivision={onChangeBusinessDivision}
          selectableBusinessDivisions={selectableBusinessDivisions}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
