import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisAdminOperatorsOperatorsUpdateRequestType,
  ResponseMessageType,
} from "~/domains";

type RequestDataType = {
  body: ApisAdminOperatorsOperatorsUpdateRequestType;
  id: string;
};

const request = ({ body, id }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseMessageType>(`/api/admin_operators/operators/${id}`, {
      adminOperator: body,
    })
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisAdminOperatorsOperatorsUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType> => {
  return useMutation(request, { ...options });
};
