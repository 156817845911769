import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { useComponentsAdminOperatorsCompaniesUpdateForm } from "~/hooks";

import { Form, FormSubmitButton } from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import { LabelWithTextField } from "~/components/molecules";

export const AdminOperatorsCompaniesEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const {
    name,
    subdomain,
    isSubmitting,
    onChangeSubdomain,
    onChangeName,
    handleSubmit,
  } = useComponentsAdminOperatorsCompaniesUpdateForm(id);

  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: "/admin_operators",
    },
    {
      label: "会社一覧",
      href: "/admin_operators",
    },
    {
      label: "会社詳細",
      href: `/admin_operators/companies/${id}`,
    },
    {
      label: "会社情報編集",
      href: `/admin_operators/companies/${id}/edit`,
    },
  ];

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      <Form onSubmit={handleSubmit} className="space-y-8">
        <LabelWithTextField
          labelText="会社名"
          type="text"
          name="name"
          placeholder="〇〇株式会社"
          required
          value={name}
          onChange={onChangeName}
        />
        <LabelWithTextField
          labelText="会社のサブドメイン"
          type="text"
          name="subdomain"
          placeholder="guideline"
          required
          value={subdomain}
          onChange={onChangeSubdomain}
        />
        <FormSubmitButton
          color="primary"
          value="更新"
          isReadOnly={isSubmitting}
          className="w-full"
        />
      </Form>
    </AdminOperatorLayout>
  );
};
