import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import {
  ApiMembersFocusChallengesMutateType,
  RawDraftContentState,
} from "~/domains";

type RequestDataType = {
  id: string;
  body: {
    content: RawDraftContentState;
    providingServiceId: string | undefined;
    jobCategoryPhaseId: string | undefined;
    challengeStartDate: string;
    challengeFinishDate: string;
    attachFiles: File[];
    focusChallengeEmployeeId: string;
  };
};

const request = ({ id, body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({
    focusChallenge: body,
  });
  return new ApiClient()
    .put<ApiMembersFocusChallengesMutateType>(
      `/api/members/focus_challenges/${id}`,
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersFocusChallengesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiMembersFocusChallengesMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
