import React, { FC } from "react";

import {
  useApisMembersMeetingNotePostCalendarsIndex,
  usePrevNextSelectMonth,
} from "~/hooks";

import { ActivityOriginalCalendar } from "~/components/molecules";

type PropsType = {
  className?: string;
};

export const MeetingNoteDailyCountCalendar: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  const {
    targetDate,
    targetDateString,
    handlePrevMonthClick,
    handleNextMonthClick,
  } = usePrevNextSelectMonth();

  const { data, isLoading } = useApisMembersMeetingNotePostCalendarsIndex({
    params: {
      targetDate: targetDateString,
    },
  });

  return (
    <ActivityOriginalCalendar
      isLoading={isLoading}
      dailyActivity={data?.postCalendars}
      targetDate={targetDate}
      onPrevMonthClick={handlePrevMonthClick}
      onNextMonthClick={handleNextMonthClick}
      isForRate={false}
      className={className}
    />
  );
};
