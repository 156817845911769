import { ChangeEvent, FormEvent, MouseEvent, useState } from "react";

import {
  useInput,
  useApisMembersBusinessSectionsEmployeeSurveysIndex,
  useArray,
  useProvidersEmployeeLayout,
} from "~/hooks";

import {
  ApiMembersSalesConceptsIndexTypeRequestType,
  EmployeeSurveyType,
  EmployeeRole,
} from "~/domains";

type ReturnType = {
  managersEmployeeSurveys: EmployeeSurveyType[];
  ownersEmployeeSurveys: EmployeeSurveyType[];
  keyword: string;
  managerHasNextPage?: boolean;
  managerIsLoading: boolean;
  ownerIsLoading: boolean;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  managerFetchNextPage: () => void;
  ownerFetchNextPage: () => void;
  ownerHasNextPage?: boolean;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

type PropsType = {
  businessSectionId?: string;
};

export const useSearchMembersBusinessSectionsEmployeeSurveys = ({
  businessSectionId = "",
}: PropsType): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const { items: managerItems, setItems: setManagerItems } =
    useArray<EmployeeSurveyType>([]);
  const { items: ownerItems, setItems: setOwnerItems } =
    useArray<EmployeeSurveyType>([]);
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");

  const [params, setParams] =
    useState<ApiMembersSalesConceptsIndexTypeRequestType>();

  const {
    isFetching: isFetchingManager,
    fetchNextPage: fetchNextPageManager,
    hasNextPage: hasNextPageManager,
  } = useApisMembersBusinessSectionsEmployeeSurveysIndex({
    businessSectionId,
    params: { employeeRoleId: EmployeeRole.MANAGER.id, ...params },
    config: {
      enabled: !!businessSectionId,
      onSuccess: (data) => {
        setManagerItems(data.pages.map((page) => page.employeeSurveys).flat());
      },
    },
  });

  const {
    isFetching: isFetchingOwner,
    fetchNextPage: fetchNextPageOwner,
    hasNextPage: hasNextPageOwner,
  } = useApisMembersBusinessSectionsEmployeeSurveysIndex({
    businessSectionId,
    params: { employeeRoleId: EmployeeRole.OWNER.id, ...params },
    config: {
      enabled: !!businessSectionId,
      onSuccess: (data) => {
        setOwnerItems(data.pages.map((page) => page.employeeSurveys).flat());
      },
    },
  });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    setParams(undefined);
    setFalseRightSidebarMain();
  };

  return {
    managersEmployeeSurveys: managerItems,
    ownersEmployeeSurveys: ownerItems,
    keyword,
    managerHasNextPage: hasNextPageManager,
    managerIsLoading: isFetchingManager,
    ownerIsLoading: isFetchingOwner,
    ownerHasNextPage: hasNextPageOwner,
    managerFetchNextPage: fetchNextPageManager,
    ownerFetchNextPage: fetchNextPageOwner,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  };
};
