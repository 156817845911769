import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationStandardSummaryCategoriesIndexResponseType,
  ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationStandardSummaryCategoriesIndexRequestType,
} from "~/domains";

type RequestDataType = {
  companyId: string;
  params?: ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationStandardSummaryCategoriesIndexRequestType;
};

const request = async ({
  params,
  companyId,
}: RequestDataType): Promise<ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationStandardSummaryCategoriesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationStandardSummaryCategoriesIndexResponseType>(
      `/api/partner_consoles/companies/${companyId}/analytics/skill_evaluation_standard_summary_categories`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationStandardSummaryCategoriesIndexRequestType;
  companyId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisPartnerConsolesCompaniesAnalyticsSkillEvaluationStandardSummaryCategoriesIndex =
  ({
    companyId,
    params,
    config = {},
  }: PropsType): UseQueryResult<ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationStandardSummaryCategoriesIndexResponseType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisPartnerConsolesCompaniesAnalyticsSkillEvaluationStandardSummaryCategoriesIndex",
        companyId,
        params,
      ],
      queryFn: () => request({ companyId, params }),
    });
  };
