import React, { FC, FormEvent, MouseEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useProvidersCurrentEmployee,
  useInput,
  useDropdown,
  useMultiDivisionMultiSectionDropdown,
  useApisManagersEmployeesEdit,
  useApisManagersEmployeesUpdate,
  useApisManagersEmployeesReInvitesUpdate,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { EmployeeForm } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

import { EmployeeRole } from "~/domains";

export const ManagersEmployeesEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { currentEmployee, refetch } = useProvidersCurrentEmployee();

  const { data: employeeFetchData, isError } = useApisManagersEmployeesEdit({
    id,
    config: {
      onSuccess: (data) => {
        setLastNameValue(data.lastName);
        setFirstNameValue(data.firstName);
        setKatakanaLastNameValue(data?.katakanaLastName);
        setKatakanaFirstNameValue(data?.katakanaFirstName);
        setJobTitleValue(data?.jobTitle);
        setEmployeeRoleDropdown({
          id: data.employeeRole.id.toString(),
          name: data.employeeRole.name,
        });
        setJobCategoryDropdown(data.jobCategory);
        setSelectedDivisions(data.businessDivisions);
        setSelectedSections(data.businessSections);
      },
    },
  });
  const { mutate: updateEmployee, isLoading: isUpdating } =
    useApisManagersEmployeesUpdate();

  const [lastName, setLastNameValue] = useInput("");
  const [firstName, setFirstNameValue] = useInput("");
  const [katakanaLastName, setKatakanaLastNameValue] = useInput("");
  const [katakanaFirstName, setKatakanaFirstNameValue] = useInput("");
  const [jobTitle, setJobTitleValue] = useInput("");
  const [employeeRoleDropdown, setEmployeeRoleDropdown] = useDropdown();
  const [jobCategoryDropdown, setJobCategoryDropdown] = useDropdown();

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
    setSelectedDivisions,
    setSelectedSections,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });

  const isSelf = currentEmployee?.id === employeeFetchData?.id;
  const judgeReadonly = () => {
    if (isSelf) return false;

    return (
      currentEmployee?.employeeRole.type !== EmployeeRole.OWNER.type &&
      employeeFetchData?.employeeRole.type !== EmployeeRole.MEMBER.type
    );
  };
  const isReadonly = judgeReadonly();
  const { mutate: reInviteRequest, isLoading: isInviting } =
    useApisManagersEmployeesReInvitesUpdate();

  const handleReInvite = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    reInviteRequest(
      {
        employeeId: id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
        },
      },
    );
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (optionSelectedSections.length < 1) {
      toast.error("課が選択されていません");
      return;
    }
    const body = {
      lastName: lastName.value,
      firstName: firstName.value,
      katakanaLastName: katakanaLastName.value,
      katakanaFirstName: katakanaFirstName.value,
      jobTitle: jobTitle.value,
      employeeRoleId: Number(employeeRoleDropdown.value?.id),
      jobCategoryId: jobCategoryDropdown.value?.id.toString() || "",
      businessDivisions: {
        ids: selectedDivisions.map((division) => division.id),
      },
      businessSections: {
        ids: optionSelectedSections.map((section) => section.id),
      },
    };
    updateEmployee(
      {
        id,
        body,
      },
      {
        onSuccess: (data) => {
          navigate("/managers/employees");
          toast(data.message);
          refetch();
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "ユーザー設定", href: "/managers/employees" },
    { label: "編集", href: `/managers/employees/${id}/edit` },
  ];

  if (isError) {
    return <NotFoundIndex />;
  }

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          <EmployeeForm
            id={id}
            handleSubmit={handleSubmit}
            isUpdating={isUpdating}
            isReadonly={isReadonly}
            selectableDivisions={selectableDivisions}
            selectedDivisions={selectedDivisions}
            onDivisionChange={onDivisionChange}
            optionSelectableSections={optionSelectableSections}
            optionSelectedSections={optionSelectedSections}
            onSectionChange={onSectionChange}
            jobCategoryOptions={jobCategoryDropdown.convertToDropdown(
              employeeFetchData?.selectableJobCategories,
            )}
            jobCategoryDropdownValue={jobCategoryDropdown.dropdownValue}
            jobCategoryDropdownOnChange={jobCategoryDropdown.onChange}
            jobTitleValue={jobTitle.value}
            jobTitleOnChange={jobTitle.onChange}
            emailValue={employeeFetchData?.email || ""}
            lastNameValue={lastName.value}
            lastNameOnChange={lastName.onChange}
            firstNameValue={firstName.value}
            firstNameOnChange={firstName.onChange}
            katakanaLastNameValue={katakanaLastName.value}
            katakanaLastNameOnChange={katakanaLastName.onChange}
            katakanaFirstNameValue={katakanaFirstName.value}
            katakanaFirstNameOnChange={katakanaFirstName.onChange}
            employeeRoleDropdownValue={employeeRoleDropdown.dropdownValue}
            employeeRoleDropdownOnChange={employeeRoleDropdown.onChange}
            unauthorized={{
              isInviting,
              handleReInvite,
            }}
            setSelectedDivisions={setSelectedDivisions}
            setSelectedSections={setSelectedSections}
            buttonName="更新"
          />
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
