import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersEmployeeSurveysMutateResponseType,
  ApiManagersEmployeeSurveysMutateRequestType,
} from "~/domains";

type RequestDataType = {
  body: ApiManagersEmployeeSurveysMutateRequestType;
  id: string;
};

const request = ({ id, body }: RequestDataType) => {
  return new ApiClient()
    .put<ApiManagersEmployeeSurveysMutateResponseType>(
      `/api/managers/employee_surveys/${id}`,
      {
        employeeSurvey: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersEmployeeSurveysUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersEmployeeSurveysMutateResponseType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
