import React, { ChangeEvent, FC, FormEvent, MouseEvent } from "react";

import { toast } from "react-toastify";

import { useApisManagersSkillEvaluationTermsDestroy } from "~/hooks";

import {
  Form,
  Button,
  FormSubmitButton,
  GridHeaderCells,
} from "~/components/atoms";
import {
  LabelWithTextField,
  GridRow,
  InfiniteScrollWithMoreButton,
  OpenedMenuInGrid,
  RangeDatePicker,
  LabelWithDropDownField,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import {
  BusinessDivisionType,
  ChildType,
  OptionType,
  ParentType,
  PublicStatus,
  RangeDatePropsType,
  SingleValueType,
  SkillEvaluationTermType,
} from "~/domains";

type PropsType = {
  className?: string;
  skillEvaluationTerms: SkillEvaluationTermType[];
  titleKeyword: string;
  startDate: Date | null;
  endDate: Date | null;
  hasNextPage?: boolean;
  isLoading: boolean;
  selectableDropdownPublicStatusIds: OptionType[];
  selectedDropdownPublicStatusId?: SingleValueType<OptionType>;
  selectableDropdownDivisions: BusinessDivisionType[];
  selectedDropdownDivisions: BusinessDivisionType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  findAndRemove: (func: (item: SkillEvaluationTermType) => boolean) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeDropdownPublicStatusId: (value: SingleValueType<OptionType>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const ManagersSkillEvaluationTermsIndexTemplate: FC<PropsType> = ({
  className = "",
  skillEvaluationTerms,
  titleKeyword,
  isLoading,
  hasNextPage,
  startDate,
  endDate,
  selectableDropdownPublicStatusIds,
  selectedDropdownPublicStatusId,
  selectableDropdownDivisions,
  selectedDropdownDivisions,
  selectableDropdownSections,
  selectedDropdownSections,
  onDivisionChange,
  onSectionChange,
  findAndRemove,
  onChangeDropdownPublicStatusId,
  fetchNextPage,
  onChangeDateRange,
  onChangeKeyword,
  onConditionReset,
  onSearchSubmit,
}: PropsType) => {
  const { mutate: deleteRequest } =
    useApisManagersSkillEvaluationTermsDestroy();

  const handleDelete = (skillEvaluationTerm: SkillEvaluationTermType) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: skillEvaluationTerm.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((item) => item.id === skillEvaluationTerm.id);
        },
      },
    );
  };

  const generateSkillEvaluationTermLists = (
    skillEvaluationTerm: SkillEvaluationTermType,
  ) => {
    return [
      `${skillEvaluationTerm.fromDate}~${skillEvaluationTerm.toDate}`,
      skillEvaluationTerm.title,
      skillEvaluationTerm.archivedSkillEvaluationStandardSummaryCategory.name,
      skillEvaluationTerm.businessDivisions
        .map((division) => division.name)
        .join(","),
      skillEvaluationTerm.businessSections
        .map((section) => section.name)
        .join(","),
      skillEvaluationTerm.publicStatus.name,
    ];
  };

  return (
    <div className={className}>
      <Form onSubmit={onSearchSubmit} className="space-y-6">
        <>
          <div className="grid miniTablet:grid-cols-2 gap-4">
            <MultiParentsWithMultiChildrenDropdownField
              allParents={selectableDropdownDivisions}
              parentsValue={selectedDropdownDivisions}
              parentLabel="部署"
              parentsOnChange={onDivisionChange}
              allChildren={selectableDropdownSections}
              childrenValue={selectedDropdownSections}
              childLabel="課"
              childrenOnChange={onSectionChange}
            />
            <RangeDatePicker
              labelText="期間指定"
              startDate={startDate}
              endDate={endDate}
              onChange={onChangeDateRange}
            />
            <LabelWithDropDownField
              labelText="ステータス"
              name="publicStatus"
              options={selectableDropdownPublicStatusIds}
              value={selectedDropdownPublicStatusId}
              onChange={onChangeDropdownPublicStatusId}
            />
            <LabelWithTextField
              labelText="フリーワード"
              type="text"
              name="titleKeyword"
              placeholder="フリーワード検索"
              value={titleKeyword}
              onChange={onChangeKeyword}
            />
          </div>
          <div className="flex items-center justify-end space-x-4">
            <Button
              color="gray"
              outline
              className="w-full miniTablet:w-auto"
              text="リセット"
              onClick={onConditionReset}
            />
            <FormSubmitButton
              value="検索する"
              color="primary"
              className="w-full miniTablet:w-auto"
            />
          </div>
        </>
      </Form>
      <InfiniteScrollWithMoreButton
        itemsLength={skillEvaluationTerms.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        isLoading={isLoading}
        className="mt-12"
      >
        <div className="grid grid-cols-6">
          <GridHeaderCells
            texts={[
              "期間",
              "タイトル",
              "スキルマップ名",
              "部署",
              "課",
              "ステータス",
            ]}
          />
          {skillEvaluationTerms.map((skillEvaluationTerm) => (
            <GridRow
              key={skillEvaluationTerm.id}
              lists={generateSkillEvaluationTermLists(skillEvaluationTerm)}
              openedMenu={
                <OpenedMenuInGrid
                  cols={6}
                  buttons={[
                    {
                      text: "編集",
                      link: `/managers/skill_evaluation_terms/${skillEvaluationTerm.id}/edit`,
                    },
                    {
                      text: "削除",
                      onClick: () => handleDelete(skillEvaluationTerm),
                    },
                    {
                      text: "チェック",
                      link: `/managers/skill_evaluation_terms/${skillEvaluationTerm.id}`,
                      isHidden:
                        skillEvaluationTerm.publicStatus.id ===
                        PublicStatus.DRAFTED.id,
                    },
                    {
                      text: "確認",
                      link: `/managers/skill_evaluation_terms/${skillEvaluationTerm.id}/results`,
                      isHidden:
                        skillEvaluationTerm.publicStatus.id ===
                        PublicStatus.DRAFTED.id,
                    },
                  ]}
                />
              }
            />
          ))}
        </div>
      </InfiniteScrollWithMoreButton>
    </div>
  );
};
