import { MouseEvent, FormEvent, useState, ChangeEvent } from "react";

import {
  useInput,
  useApisManagersBusinessSectionsIndex,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
  useArray,
} from "~/hooks";

import {
  BusinessDivisionType,
  BusinessSectionWithBusinessDivisionType,
  ChildType,
  ParentType,
} from "~/domains";

type ReturnType = {
  businessSections: BusinessSectionWithBusinessDivisionType[];
  keyword: string;
  isLoading: boolean;
  hasNextPage?: boolean;
  selectableDivisions: BusinessDivisionType[];
  selectedDivisions: BusinessDivisionType[];
  optionSelectableSections: ChildType[];
  optionSelectedSections: ChildType[];
  fetchNextPage: () => void;
  findAndRemove: (
    func: (item: BusinessSectionWithBusinessDivisionType) => boolean,
  ) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onDivisionChange: (newValue: ParentType[]) => void;
  onSectionChange: (newValue: ChildType[]) => void;
};

export const useSearchManagersBusinessSections = (): ReturnType => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [params, setParams] = useState({});
  const {
    items: businessSections,
    setItems: setBusinessSections,
    findAndRemove,
  } = useArray<BusinessSectionWithBusinessDivisionType>([]);

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });

  const { fetchNextPage, hasNextPage, isLoading } =
    useApisManagersBusinessSectionsIndex({
      params,
      config: {
        onSuccess: (data) => {
          setBusinessSections(
            data?.pages.map((page) => page.businessSections).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      businessDivisionIds: selectedDivisions.map((division) => division.id),
      businessSectionIds: optionSelectedSections.map((section) => section.id),
    });
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onSectionChange([]);
    onDivisionChange([]);
    setParams({});
  };

  return {
    businessSections,
    isLoading,
    keyword,
    hasNextPage,
    selectableDivisions,
    selectedDivisions,
    optionSelectableSections,
    optionSelectedSections,
    fetchNextPage,
    findAndRemove,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
    onDivisionChange,
    onSectionChange,
  };
};
