import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useApisManagersSkillEvaluationTermsResultPointsIndex,
  useDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { LabelWithDropDownField } from "~/components/molecules";
import { SkillEvaluationTermDetail } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

import { OptionType, SingleValueType } from "~/domains";

export const ManagersSkillEvaluationTermsResultShow: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const { id = "" } = useParams<{ id: string }>();
  const breadCrumbItems = [
    { label: "スキルチェック", href: "/managers/skill_evaluation_terms" },
    { label: "確認", href: `/managers/skill_evaluation_terms/${id}/results` },
  ];

  const [
    {
      dropdownValue: businessSectionDropdownValue,
      convertToDropdown: businessSectionConvertToDropdown,
      onChange: businessSectionOnChange,
    },
  ] = useDropdown();
  const [
    {
      dropdownValue: employeeDropdownValue,
      convertToDropdown: employeeConvertToDropdown,
      onChange: employeeOnChange,
    },
  ] = useDropdown();
  const { data, isError } =
    useApisManagersSkillEvaluationTermsResultPointsIndex({
      id,
      params: {
        businessSectionId: businessSectionDropdownValue?.value,
        employeeId: employeeDropdownValue?.value,
      },
    });
  const employeeChange = (newValue: SingleValueType<OptionType>) => {
    employeeOnChange(newValue);
  };

  const sectionChange = (newValue: SingleValueType<OptionType>) => {
    businessSectionOnChange(newValue);
    employeeOnChange(null);
  };

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        {data && (
          <>
            <div className="space-y-4">
              <LabelWithDropDownField
                labelText="課"
                required
                name="businessSections"
                options={businessSectionConvertToDropdown(
                  data.selectableBusinessSections,
                )}
                value={businessSectionDropdownValue}
                onChange={sectionChange}
              />
              <LabelWithDropDownField
                name="employees"
                options={employeeConvertToDropdown(data.selectableEmployees)}
                value={employeeDropdownValue}
                onChange={employeeChange}
                labelText="ユーザー"
                required
              />
            </div>
            <SkillEvaluationTermDetail
              resultPoints={
                data.result && {
                  totalPoint: data.result.totalPoint,
                  revieweeSumPoint: data.result.revieweeSumPoint,
                  reviewerSumPoint: data.result.reviewerSumPoint,
                  revieweeAveragePoint: data.result.revieweeAveragePoint,
                  reviewerAveragePoint: data.result.reviewerAveragePoint,
                }
              }
              skillEvaluationTermEmployeeComment={
                data.skillEvaluationTermEmployeeComment
              }
              skillEvaluationTermRevieweeComment={
                data.skillEvaluationTermRevieweeComment
              }
              skillEvaluationTerm={data.skillEvaluationTerm}
              archivedSkillEvaluationStandardSummaryCategory={
                data.archivedSkillEvaluationStandardSummaryCategory
              }
              revieweeEmployeeId={employeeDropdownValue?.value}
              reviewerEmployeeId={currentEmployee?.id}
              selectArchivedSkillEvaluationStandards={
                data.employeeSelectArchivedSkillEvaluations
              }
              employeeRoleType="manager"
              className="mt-6"
            />
          </>
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
