import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersInsightPostObjectivesMutateRequestType,
  ResponseMessageType,
} from "~/domains";

type RequestDataType = {
  body: ApiManagersInsightPostObjectivesMutateRequestType;
};

const request = ({ body }: RequestDataType) => {
  return new ApiClient()
    .post<ResponseMessageType>("/api/managers/insight_post_objectives", {
      insightPostObjective: body,
    })
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersInsightPostObjectivesCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
