import { useCallback } from "react";

import { toast } from "react-toastify";

type ReturnType = {
  clipboardToCopy: (text: string) => void;
};

export const useClipboard = (): ReturnType => {
  const clipboardToCopy = useCallback((text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => toast("コピーしました"))
      .catch((err: string) => toast.error("コピー失敗しました" + err));
  }, []);

  return {
    clipboardToCopy,
  };
};
