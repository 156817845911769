import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMemberInsightPostsInsightPostCommentsIndexType } from "~/domains";

type ParamsType = {
  insightPostId: string;
};

const request = async ({
  insightPostId,
}: ParamsType): Promise<ApiMemberInsightPostsInsightPostCommentsIndexType> => {
  const response =
    await new ApiClient().get<ApiMemberInsightPostsInsightPostCommentsIndexType>(
      `/api/members/insight_posts/${insightPostId}/insight_post_comments`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  insightPostId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersInsightPostsInsightPostCommentsIndex = ({
  insightPostId,
  config = {},
}: PropsType): UseQueryResult<ApiMemberInsightPostsInsightPostCommentsIndexType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisMembersInsightPostsInsightPostCommentsIndex",
      insightPostId,
    ],
    queryFn: () => request({ insightPostId }),
  });
};
