import React, { FC, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useProvidersCurrentEmployee,
  useInput,
  useApisManagersBusinessDivisionsEdit,
  useApisManagersBusinessDivisionsUpdate,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { BusinessDivisionForm } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const ManagersBusinessDivisionsEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { refetch } = useProvidersCurrentEmployee();

  const [
    { value: businessDivisionName, onChange: onChangeBusinessDivisionName },
    setBusinessDivisionName,
  ] = useInput("");
  const { isError } = useApisManagersBusinessDivisionsEdit({
    id,
    config: {
      onSuccess: (res) => {
        setBusinessDivisionName(res.businessDivision.name);
      },
    },
  });

  const { mutate, isLoading: isSubmitting } =
    useApisManagersBusinessDivisionsUpdate();
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = {
      name: businessDivisionName,
    };
    mutate(
      {
        id,
        body,
      },
      {
        onSuccess: (res) => {
          navigate("/managers/business_divisions");
          toast(res.message);
          refetch();
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "部署設定", href: "/managers/business_divisions" },
    { label: "編集", href: `/managers/business_divisions/${id}/edit` },
  ];

  if (isError) {
    return <NotFoundIndex />;
  }

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <BusinessDivisionForm
          businessDivisionName={businessDivisionName}
          onChangeBusinessDivisionName={onChangeBusinessDivisionName}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
