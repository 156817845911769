import React, { useState, FC } from "react";

import {
  useApisManagersInsightPostsNew,
  useApisMembersInsightPostsInsightPostCommentsIndex,
  useInterval,
  useRightSidebarState,
  useSearchManagersInsightPosts,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  FilterIconWithSortMenu,
  RightSidebarToggleIconWithLabel,
  InfiniteScroll,
} from "~/components/molecules";
import {
  InsightPostDetailWithEditForm,
  InsightPostSearchForm,
  InsightPostCommentsWithInsightPost,
} from "~/components/organisms";

import { InsightPostType } from "~/domains";

export const ManagersFeedbackInsightPostsIndex: FC = () => {
  const breadCrumbItems = [
    { label: "インサイト", href: "/managers/feedback/insight_posts" },
  ];

  const [selectGetCommentInsightPostId, setSelectGetCommentInsightPostId] =
    useState<string>("");

  const {
    isSearchOpen,
    isReplyOpen,
    searchOpenToggle,
    setReplyOpen,
    setClose,
    isOpen,
  } = useRightSidebarState("isClose");

  const { data: fetchNewData } = useApisManagersInsightPostsNew();
  const { data: insightPostCommentsIndexData, refetch: commentsRefetch } =
    useApisMembersInsightPostsInsightPostCommentsIndex({
      insightPostId: selectGetCommentInsightPostId,
      config: {
        enabled: Boolean(selectGetCommentInsightPostId),
      },
    });

  const {
    insightPosts,
    hasNextPage,
    isLoading,
    keyword,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    selectableDropdownHasCommentIds,
    selectedDropdownHasCommentId,
    selectableDropdownSharedIds,
    selectedDropdownSharedId,
    startDate,
    endDate,
    sort,
    isBelongingEmployee,
    selectableJobCategories,
    selectedJobCategories,
    optionSelectableJobCategoryPhases,
    optionSelectedJobCategoryPhases,
    selectedProvidingServices,
    selectableProvidingServices,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    selectableDropdownArchivedEmployees,
    selectedDropdownArchivedEmployees,
    onDivisionChange,
    onSectionChange,
    onEmployeesChange,
    onArchivedEmployeesChange,
    updateIndexInsightPost,
    deleteIndexInsightPost,
    onChangeDateRange,
    onChangeProvidingServices,
    onChangeJobCategoryPhases,
    setSort,
    fetchNextPage,
    onChangeKeyword,
    onChangeIsBelongingEmployee,
    onSearchSubmit,
    onChangeJobCategories,
    onChangeDropdownHasBookmarkId,
    onChangeDropdownHasCommentId,
    onChangeDropdownSharedId,
    onConditionReset,
    refetch,
  } = useSearchManagersInsightPosts();

  // NOTE: Refetchしない場合のメモリの使用率をみるため一時的にコメントアウト
  useInterval({ onUpdate: refetch, intervalMs: 30000 });

  useInterval({
    onUpdate: async () => {
      isReplyOpen && selectGetCommentInsightPostId && (await commentsRefetch());
    },
    intervalMs: 1000,
  });

  const openComment = (insightPostId: string) => {
    setSelectGetCommentInsightPostId(insightPostId);
    setReplyOpen();
  };

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
          <FilterIconWithSortMenu
            likeSort={() => setSort("likeCountDesc")}
            bookmarkSort={() => setSort("bookmarkCountDesc")}
            createdAtAscSort={() => setSort("createdAtAsc")}
            createdAtDescSort={() => setSort("createdAtDesc")}
            currentSortType={sort}
            className="mt-6"
          />
          <InfiniteScroll
            itemsLength={insightPosts.length}
            nextFetchFunction={fetchNextPage}
            hasMore={hasNextPage}
            isLoading={isLoading}
            className="border-t-2 border-secondary-200"
          >
            {insightPosts.map((post, index) => (
              <InsightPostDetailWithEditForm
                key={post.id}
                insightPost={post}
                mentions={fetchNewData?.mentionableEmployees || []}
                selectableProvidingServices={
                  fetchNewData?.selectableProvidingServices || []
                }
                selectableJobCategoryPhases={
                  fetchNewData?.selectableJobCategoryPhases || []
                }
                deleteItem={() => {
                  deleteIndexInsightPost(index);
                  if (selectGetCommentInsightPostId === post.id) {
                    setSelectGetCommentInsightPostId("");
                    setClose();
                  }
                }}
                updateItem={(newItem: InsightPostType) =>
                  updateIndexInsightPost(newItem, index)
                }
                openComment={openComment}
              />
            ))}
          </InfiniteScroll>
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          <RightSidebarToggleIconWithLabel
            iconOnClick={searchOpenToggle}
            handleOnClose={setClose}
            label={isSearchOpen ? "検索条件" : "リプライ"}
            isOpen={isOpen}
          >
            {isSearchOpen && (
              <InsightPostSearchForm
                onSubmit={onSearchSubmit}
                onConditionReset={onConditionReset}
                date={{
                  startDate: startDate,
                  endDate: endDate,
                  onChange: onChangeDateRange,
                }}
                providingService={{
                  options: selectableProvidingServices,
                  value: selectedProvidingServices,
                  onChange: onChangeProvidingServices,
                }}
                jobCategoryAndJobCategoryPhase={{
                  selectableJobCategories: selectableJobCategories,
                  selectedJobCategories: selectedJobCategories,
                  selectableJobCategoryPhases:
                    optionSelectableJobCategoryPhases,
                  selectedJobCategoryPhases: optionSelectedJobCategoryPhases,
                  onJobCategoryChange: onChangeJobCategories,
                  onJobCategoryPhaseChange: onChangeJobCategoryPhases,
                }}
                isBelongingEmployee={{
                  isChecked: isBelongingEmployee,
                  onChange: onChangeIsBelongingEmployee,
                }}
                hasComment={{
                  options: selectableDropdownHasCommentIds,
                  value: selectedDropdownHasCommentId,
                  onChange: onChangeDropdownHasCommentId,
                }}
                hasBookmark={{
                  options: selectableDropdownHasBookmarkIds,
                  value: selectedDropdownHasBookmarkId,
                  onChange: onChangeDropdownHasBookmarkId,
                }}
                shared={{
                  options: selectableDropdownSharedIds,
                  value: selectedDropdownSharedId,
                  onChange: onChangeDropdownSharedId,
                }}
                divisionAndSections={{
                  selectableDivisions: selectableDropdownDivisions,
                  selectedDivisions: selectedDropdownDivisions,
                  selectableSections: selectableDropdownSections,
                  selectedSection: selectedDropdownSections,
                  selectableEmployees: selectableDropdownEmployees,
                  selectedEmployees: selectedDropdownEmployees,
                  selectableDropdownArchivedEmployees,
                  selectedDropdownArchivedEmployees,
                  onDivisionChange,
                  onSectionChange,
                  onEmployeesChange,
                  onArchivedEmployeesChange,
                }}
                searchKeyword={{
                  value: keyword,
                  onChange: onChangeKeyword,
                }}
              />
            )}
            {isReplyOpen && insightPostCommentsIndexData && (
              <InsightPostCommentsWithInsightPost
                className="mt-6"
                insightPost={insightPostCommentsIndexData.insightPost}
                mentions={fetchNewData?.mentionableEmployees || []}
                insightPostComments={
                  insightPostCommentsIndexData.insightPostComments || []
                }
                refetch={commentsRefetch}
              />
            )}
          </RightSidebarToggleIconWithLabel>
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
