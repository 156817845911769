import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import {
  ApiManagersKnowledgeInsightPostMutateType,
  PreviewWithFileType,
  RawDraftContentState,
} from "~/domains";

type RequestDataType = {
  body: {
    quotePostId?: string;
    content: RawDraftContentState;
    businessSectionIds: string[];
    attachFiles: PreviewWithFileType[];
    allBusinessSectionsDistribution: boolean;
  };
};

const request = ({ body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ knowledgeInsightPost: body });
  return new ApiClient()
    .post<ApiManagersKnowledgeInsightPostMutateType>(
      "/api/managers/knowledge_insight_posts",
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersKnowledgeInsightPostsCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersKnowledgeInsightPostMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
