import React, { FC, Fragment } from "react";

import { Loading, RangeDatePicker } from "~/components/molecules";
import {
  KpiDashboardByTeam,
  KpiDashboardByEmployee,
  KpiTermHeader,
} from "~/components/organisms";

import {
  KpiObjectiveDashboardType,
  KpiTermType,
  RangeDatePropsType,
} from "~/domains";

type PropsType = {
  loading: boolean;
  kpiTerm?: KpiTermType;
  kpiObjectiveDashboards?: KpiObjectiveDashboardType[];
  className?: string;
  rangeDatePicker: {
    startDate: Date | null;
    endDate: Date | null;
    onChangeDateRange: (value: RangeDatePropsType) => void;
  };
};

export const KpiDashboardsShow: FC<PropsType> = ({
  loading,
  kpiTerm,
  kpiObjectiveDashboards = [],
  rangeDatePicker,
  className = "",
}: PropsType) => {
  return (
    <div className={className}>
      {kpiTerm && <KpiTermHeader kpiTerm={kpiTerm} />}
      <RangeDatePicker
        labelText="期間"
        startDate={rangeDatePicker.startDate}
        endDate={rangeDatePicker.endDate}
        onChange={rangeDatePicker.onChangeDateRange}
        minDate={kpiTerm && new Date(kpiTerm.fromDate)}
        maxDate={kpiTerm && new Date(kpiTerm.toDate)}
        wrapperClassName="mt-4"
      />
      {loading && <Loading />}
      <div className="mt-14 grid grid-cols-1 miniTablet:grid-cols-2 gap-y-9 gap-x-6">
        {kpiObjectiveDashboards.map((dashboard) => (
          <Fragment key={dashboard.kpiObjectiveId}>
            <KpiDashboardByTeam kpiObjectiveDashboard={dashboard} />
            <KpiDashboardByEmployee
              kpiObjectiveDashboard={dashboard}
              selectedDateRange={{
                startDate: rangeDatePicker.startDate,
                endDate: rangeDatePicker.endDate,
              }}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
};
