import React, { FC, useState, FormEvent, MouseEvent } from "react";

import { toast } from "react-toastify";

import {
  useInput,
  useApisManagersJobCategoriesDestroy,
  useApisManagersJobCategoriesIndex,
} from "~/hooks";

import {
  FormSubmitButton,
  Form,
  Button,
  GridHeaderCells,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  GridRow,
  OpenedMenuInGrid,
  LabelWithTextField,
  Loading,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import {
  ApiManagersJobCategoriesIndexRequestType,
  JobCategoryWithJobCategoryPhaseType,
  JobCategoryPhaseWithOutDisplayOrderType,
  JobCategoryType,
  ChildType,
} from "~/domains";

export const ManagersJobCategoriesIndex: FC = () => {
  const [params, setParams] =
    useState<ApiManagersJobCategoriesIndexRequestType>();
  const { isFetching } = useApisManagersJobCategoriesIndex({
    params,
    config: {
      onSuccess: (res) => {
        setJobCategories(res.jobCategories);
        setAllJobCategories(res.selectableJobCategories);
        setAllJobCategoryPhases(res.selectableJobCategoryPhases);
      },
    },
  });

  const { mutate } = useApisManagersJobCategoriesDestroy();

  const [jobCategories, setJobCategories] = useState<
    JobCategoryWithJobCategoryPhaseType[]
  >([]);
  const [selectedJobCategories, setSelectedJobCategories] = useState<
    JobCategoryType[]
  >([]);
  const [selectedJobCategoryPhases, setSelectedJobCategoryPhases] = useState<
    JobCategoryPhaseWithOutDisplayOrderType[]
  >([]);
  const [allJobCategories, setAllJobCategories] = useState<JobCategoryType[]>(
    [],
  );
  const [allJobCategoryPhases, setAllJobCategoryPhases] = useState<
    JobCategoryPhaseWithOutDisplayOrderType[]
  >([]);
  const [keyword, setKeywordValue] = useInput("");

  const jobCategoryPhasesInCellLayout = (
    jobCategoryPhases: JobCategoryPhaseWithOutDisplayOrderType[],
  ) => {
    return jobCategoryPhases
      .map((jobCategoryPhase) => `${jobCategoryPhase.name}`)
      .join(" / ");
  };

  const handleJobCategoryChange = (selectJobCategories: JobCategoryType[]) => {
    const jobCategoryIds = selectJobCategories.map(
      (jobCategory) => jobCategory.id,
    );
    const filteredSections = selectedJobCategoryPhases.filter(
      (jobCategoryPhase) =>
        jobCategoryIds.includes(jobCategoryPhase.jobCategoryId),
    );
    setSelectedJobCategories(selectJobCategories);
    setSelectedJobCategoryPhases(filteredSections);
  };

  const deleteJobCategory = (
    jobCategory: JobCategoryWithJobCategoryPhaseType,
  ) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;
    mutate(
      {
        id: jobCategory.id,
      },
      {
        onSuccess: (res) => {
          const filteredJobCategory = jobCategories.filter(
            (job) => job.id !== jobCategory.id,
          );
          setJobCategories(filteredJobCategory);
          toast(res.message);
        },
      },
    );
  };

  const generateGridLists = (
    jobCategory: JobCategoryWithJobCategoryPhaseType,
  ) => {
    return [
      jobCategory.name,
      jobCategoryPhasesInCellLayout(jobCategory.jobCategoryPhases),
    ];
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      keyword: keyword.value,
      jobCategoryIds: selectedJobCategories.map(
        (jobCategory) => jobCategory.id,
      ),
      jobCategoryPhaseIds: selectedJobCategoryPhases.map(
        (jobCategoryPhase) => jobCategoryPhase.id,
      ),
    };
    setParams(data);
  };

  const handleSearchParamsReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSelectedJobCategories([]);
    setSelectedJobCategoryPhases([]);
    setKeywordValue("");
    setParams({});
  };

  const convertChildToOption = (
    jobCategoryPhases: JobCategoryPhaseWithOutDisplayOrderType[],
  ) => {
    return jobCategoryPhases.map((jobCategoryPhase) => ({
      id: jobCategoryPhase.id,
      name: jobCategoryPhase.name,
      parentId: jobCategoryPhase.jobCategoryId,
    }));
  };

  const convertToJobCategoryPhases = (newValues: ChildType[]) => {
    return newValues.map((value) => ({
      id: value.id,
      name: value.name,
      jobCategoryId: value.parentId,
    }));
  };

  const breadCrumbItems = [
    { label: "職種設定", href: "/managers/job_categories" },
    { label: "一覧", href: "/managers/job_categories" },
  ];

  return (
    <>
      <EmployeeLayout
        withRightSidebar={false}
        breadCrumbItems={breadCrumbItems}
      >
        <EmployeeLayoutMainContent withRightSidebar={false}>
          <Form onSubmit={onSubmit} className="space-y-6">
            <>
              <div className="grid miniTablet:grid-cols-2 gap-4">
                <MultiParentsWithMultiChildrenDropdownField
                  allParents={allJobCategories}
                  parentsValue={selectedJobCategories}
                  parentLabel="職種"
                  parentsOnChange={(newValue: JobCategoryType[]) =>
                    handleJobCategoryChange(newValue)
                  }
                  allChildren={convertChildToOption(allJobCategoryPhases)}
                  childrenValue={convertChildToOption(
                    selectedJobCategoryPhases,
                  )}
                  childLabel="インサイト項目"
                  childrenOnChange={(newValue: ChildType[]) =>
                    setSelectedJobCategoryPhases(
                      convertToJobCategoryPhases(newValue),
                    )
                  }
                />
                <LabelWithTextField
                  labelText="フリーワード"
                  type="text"
                  name="keyword"
                  placeholder="フリーワード検索"
                  value={keyword.value}
                  onChange={keyword.onChange}
                />
              </div>
              <div className="flex items-center justify-end space-x-4">
                <Button
                  color="gray"
                  outline
                  className="w-full miniTablet:w-auto"
                  text="リセット"
                  onClick={handleSearchParamsReset}
                />
                <FormSubmitButton
                  value="検索する"
                  color="primary"
                  className="w-full miniTablet:w-auto"
                />
              </div>
            </>
          </Form>
          <div className="mt-12 grid grid-cols-2">
            <GridHeaderCells texts={["職種", "インサイト項目"]} />
            {isFetching ? (
              <Loading />
            ) : (
              jobCategories.map((jobCategory) => (
                <GridRow
                  key={jobCategory.id}
                  lists={generateGridLists(jobCategory)}
                  openedMenu={
                    <OpenedMenuInGrid
                      cols={2}
                      buttons={[
                        {
                          text: "編集",
                          link: `/managers/job_categories/${jobCategory.id}/edit`,
                        },
                        {
                          text: "無効化",
                          onClick: () => deleteJobCategory(jobCategory),
                        },
                      ]}
                    />
                  }
                />
              ))
            )}
          </div>
        </EmployeeLayoutMainContent>
      </EmployeeLayout>
    </>
  );
};
