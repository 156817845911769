import React, { FC, MouseEvent } from "react";

import { startOfTodayDate, subDateTime } from "~/libs";

import { Button } from "~/components/atoms";
import { RangeDatePicker } from "~/components/molecules";

import { RangeDatePropsType } from "~/domains";

type PropsType = {
  onChange: (date: RangeDatePropsType) => void;
  startDate: Date | null;
  endDate: Date | null;
  className?: string;
};

export const SelectDateRangeButtons: FC<PropsType> = ({
  startDate,
  endDate,
  className = "",
  onChange,
}: PropsType) => {
  const today = startOfTodayDate();
  const handleTodayButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onChange([today, today]);
  };

  const handleYesterdayButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const yesterday = subDateTime(today, { days: 1 });
    onChange([yesterday, today]);
  };

  const handleLastOneWeekButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const lastOneWeek = subDateTime(today, { days: 7 });
    onChange([lastOneWeek, today]);
  };

  const handleLastOneMonthButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const lastOneMount = subDateTime(today, { days: 30 });
    onChange([lastOneMount, today]);
  };

  return (
    <div className={className}>
      <div className="grid gap-2 grid-cols-4">
        <Button
          onClick={handleTodayButtonClick}
          text="今日"
          color="gray"
          outline
          size="sm"
          className="w-full"
        />
        <Button
          onClick={handleYesterdayButtonClick}
          text="昨日"
          color="gray"
          outline
          size="sm"
          className="w-full"
        />
        <Button
          onClick={handleLastOneWeekButtonClick}
          text="1週間"
          color="gray"
          outline
          size="sm"
          className="w-full"
        />
        <Button
          onClick={handleLastOneMonthButtonClick}
          text="1ヶ月"
          color="gray"
          outline
          size="sm"
          className="w-full"
        />
      </div>
      <div className="mt-3.5">
        <RangeDatePicker
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
