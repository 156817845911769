import React, { FormEvent, FC, MouseEvent } from "react";

import { toast } from "react-toastify";

import {
  useApisMembersPrivateQuestionAnswersCreate,
  useBoolean,
  useDropdown,
  useInput,
} from "~/hooks/";

import { Form, FormSubmitButton } from "~/components/atoms";
import {
  LabelWithTextArea,
  LabelWithDropDownField,
  TogglablePostHeader,
  LabelWithTextField,
} from "~/components/molecules";

import { PrivateQuestionAnswerType, ParentType } from "~/domains";

type PropsType = {
  className?: string;
  privateQuestionAnswer?: PrivateQuestionAnswerType;
  selectableProvidingService: ParentType[];
  unshiftItem?: (privateQuestionAnswer: PrivateQuestionAnswerType) => void;
  disabled?: boolean;
};

export const PrivateQuestionAnswerForm: FC<PropsType> = ({
  className = "",
  privateQuestionAnswer,
  selectableProvidingService,
  unshiftItem,
  disabled = false,
}: PropsType) => {
  const [{ value: question, onChange: onChangeQuestion }, setQuestion] =
    useInput(privateQuestionAnswer?.questionContent || "");
  const [{ value: answer, onChange: onChangeAnswer }, setAnswer] = useInput(
    privateQuestionAnswer?.answerContent || "",
  );
  const [{ value: useScene, onChange: onChangeUseScene }, setUseScene] =
    useInput(privateQuestionAnswer?.useScene || "");
  const [
    { value: importantPoint, onChange: onChangeImportantPoint },
    setImportantPoint,
  ] = useInput(privateQuestionAnswer?.importantPoint || "");
  const [{ value: remark, onChange: onChangeRemark }, setRemark] = useInput(
    privateQuestionAnswer?.remark || "",
  );
  const [{ value: link, onChange: onChangeLink }, setLink] = useInput(
    privateQuestionAnswer?.link || "",
  );

  const { isChecked: isToggleOpen, toggle } = useBoolean(false);

  const [providingOptions, setProvidingOptions] = useDropdown({
    initialValue: privateQuestionAnswer?.providingService || {
      id: "",
      name: "すべて",
    },
    selectableValue: [
      { id: "", name: "すべて" },
      ...selectableProvidingService,
    ],
  });

  const { mutate: postRequest, isLoading: isSubmitting } =
    useApisMembersPrivateQuestionAnswersCreate();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      questionContent: question,
      answerContent: answer,
      useScene,
      importantPoint,
      remark,
      link,
      providingServiceId: providingOptions.dropdownValue?.value || "",
    };

    postRequest(
      {
        body,
      },
      {
        onSuccess: (data) => {
          unshiftItem?.(data.privateQuestionAnswer);
          toast(data.message);
          formReset();
        },
      },
    );
  };

  const formReset = () => {
    setQuestion("");
    setAnswer("");
    setUseScene("");
    setImportantPoint("");
    setRemark("");
    setLink("");
    setProvidingOptions(undefined);
  };

  const handleSetToggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    toggle();
  };

  return (
    <Form className={`space-y-6 ${className}`} onSubmit={onSubmit}>
      {privateQuestionAnswer && (
        <TogglablePostHeader
          employeeName={privateQuestionAnswer.employee.name}
          createdAt={privateQuestionAnswer.createdAt}
          avatarUrl={privateQuestionAnswer.employee.avatarUrl}
          handleToggle={handleSetToggle}
          isOpen={isToggleOpen}
        />
      )}
      {(!privateQuestionAnswer || isToggleOpen) && (
        <>
          <LabelWithDropDownField
            labelText="サービス"
            name="providingService"
            options={providingOptions.dropdownSelectableValue}
            value={providingOptions.dropdownValue}
            onChange={providingOptions.onChange}
            isDisabled={disabled}
          />
          <LabelWithTextArea
            labelText="Q (質問)"
            name="question"
            placeholder="Q (質問)"
            required
            rows={1}
            value={question}
            onChange={onChangeQuestion}
            disabled={disabled}
          />
          <LabelWithTextArea
            labelText="A (答え)"
            name="answer"
            placeholder="A (答え)"
            required
            rows={8}
            value={answer}
            onChange={onChangeAnswer}
            disabled={disabled}
          />
          <LabelWithTextField
            type="url"
            labelText="参考リンク"
            name="link"
            placeholder="参考リンク"
            value={link}
            onChange={onChangeLink}
            disabled={disabled}
          />
          <LabelWithTextArea
            labelText="よくある状況"
            name="useScene"
            placeholder="よくある状況"
            rows={8}
            value={useScene}
            onChange={onChangeUseScene}
            disabled={disabled}
          />
          <LabelWithTextArea
            labelText="ポイント"
            name="importantPoint"
            placeholder="ポイント"
            rows={8}
            value={importantPoint}
            onChange={onChangeImportantPoint}
            disabled={disabled}
          />
          <LabelWithTextArea
            labelText="備考"
            name="remark"
            placeholder="備考"
            rows={8}
            value={remark}
            onChange={onChangeRemark}
            disabled={disabled}
          />
          {privateQuestionAnswer && (
            <span className="text-secondary-600 text-xs">
              {privateQuestionAnswer.createdAt}
            </span>
          )}
          {!disabled && (
            <FormSubmitButton
              value="保存"
              color="primary"
              isReadOnly={isSubmitting}
              className="w-full"
            />
          )}
        </>
      )}
    </Form>
  );
};
