import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { MeetingNoteType, ResponseMessageType } from "~/domains";

type RequestDataType = {
  meetingNoteId: string;
};

type ResponseDataType = ResponseMessageType & {
  meetingNote: MeetingNoteType;
};

const request = ({ meetingNoteId }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseDataType>(
      `/api/members/meeting_notes/${meetingNoteId}/completeds`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersMeetingNotesCompletedsUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseDataType, RequestDataType> => {
  return useMutation(request, { ...options });
};
