import React, { FC } from "react";

import { useBoolean } from "~/hooks";

import { ButtonWithIcon, PieChart } from "~/components/atoms";
import { Loading } from "~/components/molecules";

import { PieChartModal } from "./PieChartModal";

type PropsType = {
  isLoading: boolean;
  title: string;
  unitName?: string;
  dataSets?: {
    label: string;
    value: number;
  }[];
  className?: string;
  graphClassName?: string;
  onlyModalTitle?: boolean;
};

export const PieChartBox: FC<PropsType> = ({
  isLoading,
  title,
  dataSets,
  className = "",
  graphClassName = "",
  onlyModalTitle = false,
}: PropsType) => {
  const { isChecked: open, setTrue, setFalse } = useBoolean(false);

  return (
    <div className={`max-w-full ${className}`}>
      {!onlyModalTitle && (
        <p className="font-semibold text-prim ary-600 text-lg text-center">
          {title}
        </p>
      )}
      <div
        className={`shadow mt-4 p-4 rounded-md flex justify-center text-center px-8 ${graphClassName}`}
      >
        {isLoading ? (
          <Loading />
        ) : (
          dataSets && (
            <>
              <PieChart dataSets={dataSets} />
              <ButtonWithIcon
                srOnlyText="グラフを拡大する"
                icon={{
                  icon: "ioScanOutline",
                  color: "text-secondary-600",
                  size: "1.25rem",
                }}
                onClick={setTrue}
                className="flex justify-end"
              />
            </>
          )
        )}
      </div>
      {dataSets && open && (
        <PieChartModal
          onClose={setFalse}
          title={title}
          dataSets={dataSets}
          className="mt-4"
        />
      )}
    </div>
  );
};
