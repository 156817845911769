import React, { FC } from "react";

import { useBoolean } from "~/hooks";

import {
  PostContentEditorWithImages,
  PostHeader,
} from "~/components/organisms";

import {
  AvatarAndNameEmployeeType,
  RawDraftContentState,
  AttachFileType,
} from "~/domains";

type PropsType = {
  blockQuoteEmployee: AvatarAndNameEmployeeType;
  blockQuoteContent: RawDraftContentState;
  blockQuoteFiles: AttachFileType[];
  blockQuoteCreatedAt: string;
  className?: string;
};

export const BlockQuoteEditor: FC<PropsType> = ({
  blockQuoteEmployee,
  blockQuoteContent,
  blockQuoteFiles,
  blockQuoteCreatedAt,
  className = "",
}: PropsType) => {
  const { isChecked: isOpen, setTrue: setOpen } = useBoolean(false);

  return (
    <div
      className={`p-4 border-l-4 border-t border-b border-r text-secondary-600 border-secondary-300 quote not-italic ${className}`}
      onClick={() => !isOpen && setOpen()}
    >
      <PostHeader
        postedEmployee={blockQuoteEmployee}
        miniText={blockQuoteCreatedAt}
        menuItems={[]}
      />
      <PostContentEditorWithImages
        content={blockQuoteContent}
        files={blockQuoteFiles}
        className={`mt-1 ${isOpen ? "" : "line-clamp-3 cursor-pointer"}`}
      />
    </div>
  );
};
