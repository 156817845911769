import {
  useArray,
  useApisManagersEmployeesEmployeeCompetencyReviewsIndex,
} from "~/hooks";

import { EmployeeCompetencyReviewType } from "~/domains";

type ReturnType = {
  employeeCompetencyReviews: EmployeeCompetencyReviewType[];
  hasNextPage?: boolean;
  isLoading: boolean;
  fetchNextPage: () => void;
  refetch: () => void;
  unshiftItem: (item: EmployeeCompetencyReviewType) => void;
};

export const useSearchManagersEmployeesEmployeeCompetencyReviews = (
  employeeId: string,
): ReturnType => {
  const {
    items: employeeCompetencyReviews,
    setItems: setEmployeeCompetencyReviews,
    unshiftItem,
  } = useArray<EmployeeCompetencyReviewType>([]);

  const { fetchNextPage, refetch, hasNextPage, isFetching } =
    useApisManagersEmployeesEmployeeCompetencyReviewsIndex({
      employeeId,
      config: {
        onSuccess: (data) => {
          setEmployeeCompetencyReviews(
            data.pages.map((page) => page.employeeCompetencyReviews).flat(),
          );
        },
      },
    });

  return {
    isLoading: isFetching,
    employeeCompetencyReviews,
    hasNextPage,
    fetchNextPage,
    refetch,
    unshiftItem,
  };
};
