import { SlideNavigationType } from "~/components/molecules";

type ReturnType = {
  navigations: SlideNavigationType[];
};

type PropsType = {
  selectedArray: [boolean, boolean];
  businessSectionId?: string;
};

export const useEmployeeSurveysSlideNavigation = ({
  selectedArray,
  businessSectionId = "",
}: PropsType): ReturnType => {
  const navigations = [
    {
      label: "入力",
      href: `/employee_surveys?business_section_id=${businessSectionId}`,
      selected: selectedArray[0],
    },
    {
      label: "共有",
      href: `/employee_survey_submissions?business_section_id=${businessSectionId}`,
      selected: selectedArray[1],
    },
  ];

  return {
    navigations,
  };
};
