import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  companyId: string;
};

const request = ({ companyId }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseMessageType>(
      `/api/admin_operators/companies/${companyId}/contract_actives`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisAdminOperatorsCompaniesContractActivesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType> => {
  return useMutation(request, { ...options });
};
