import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import { ApiMembersInsightPostsShowType } from "~/domains";

type RequestDataType = {
  id: string;
};

const request = async ({
  id,
}: RequestDataType): Promise<ApiMembersInsightPostsShowType> => {
  const response = await new ApiClient().get<ApiMembersInsightPostsShowType>(
    `/api/members/insight_posts/${id}`,
  );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  id: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersInsightPostsShow = ({
  id,
  config = {},
}: PropsType): UseQueryResult<ApiMembersInsightPostsShowType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersInsightPostsShow", id],
    queryFn: () => request({ id }),
    useErrorBoundary: (error) => {
      return error instanceof ApiResponseError && error.status >= 500;
    },
  });
};
