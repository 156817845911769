import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useApisMembersFocusChallengesNew,
  useApisMembersFocusChallengeEmployeeRepliesShow,
  useRightSidebarState,
  useAnchorScroll,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  FocusChallengeEmployeeDetailWithEditForm,
  FocusChallengeEmployeeRepliesWithFocusChallengeEmployee,
} from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const MembersFocusChallengeEmployeeRepliesShow: FC = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();
  const { isReplyOpen, setReplyOpen } = useRightSidebarState("isReplyOpen");
  const breadCrumbItems = [
    { label: "チャレンジ", href: "/focus_challenges" },
    { label: "詳細", href: `/focus_challenge_employee_replies/${id}` },
  ];

  const { data: fetchNew } = useApisMembersFocusChallengesNew();
  const {
    data: fetchShow,
    refetch,
    isError,
  } = useApisMembersFocusChallengeEmployeeRepliesShow({
    id,
  });

  useEffect(() => {
    setReplyOpen();
  }, [setReplyOpen]);

  useAnchorScroll(!!fetchShow);

  if (isError) return <NotFoundIndex />;

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
          {fetchShow && (
            <FocusChallengeEmployeeDetailWithEditForm
              mentions={fetchNew?.mentionableEmployees || []}
              selectableProvidingServices={
                fetchNew?.selectableProvidingServices || []
              }
              selectableJobCategoryPhases={
                fetchNew?.selectableJobCategoryPhases || []
              }
              focusChallengeEmployee={fetchShow.focusChallengeEmployee}
              openReply={setReplyOpen}
              deleteItem={() => navigate("/focus_challenges")}
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          {isReplyOpen && fetchShow && (
            <>
              <RightSidebarTitle title="リプライ" />
              <FocusChallengeEmployeeRepliesWithFocusChallengeEmployee
                className="mt-6 mx-4"
                mentions={fetchNew?.mentionableEmployees || []}
                focusChallengeEmployee={fetchShow.focusChallengeEmployee}
                openReply={setReplyOpen}
                resetPageFetch={refetch}
                focusChallengeReplies={fetchShow.focusChallengeEmployeeReplies}
                highlightFocusChallengeEmployeeReplyId={id}
              />
            </>
          )}
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
