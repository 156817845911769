import React, { FC, MouseEvent } from "react";

import { ButtonWithIcon } from "~/components/atoms";

type PropsType = {
  onPrevClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onNextClick: (e: MouseEvent<HTMLButtonElement>) => void;
  prevText: string;
  nextText: string;
  className?: string;
};

export const PrevAndNextButtons: FC<PropsType> = ({
  onPrevClick,
  onNextClick,
  prevText,
  nextText,
  className = "",
}: PropsType) => {
  return (
    <div className={`flex items-center ${className}`}>
      <ButtonWithIcon
        withHoverEffect
        srOnlyText={prevText}
        icon={{
          icon: "ioChevronBackOutline",
          color: "text-secondary-600",
          size: "1.25rem",
        }}
        onClick={onPrevClick}
      />
      <ButtonWithIcon
        withHoverEffect
        srOnlyText={nextText}
        icon={{
          icon: "ioChevronForwardOutline",
          color: "text-secondary-600",
          size: "1.25rem",
        }}
        onClick={onNextClick}
      />
    </div>
  );
};
