import React, { FC, useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { useInput, useApisManagersJobCategoriesCreate } from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { JobCategoryForm } from "~/components/organisms";

type JobCategoryPhaseNamesType = {
  id: string;
  name: string;
};

export const ManagersJobCategoriesNew: FC = () => {
  const navigate = useNavigate();

  const [jobCategoryName] = useInput("");
  const [jobCategoryPhaseNames, setJobCategoryPhaseNames] = useState<
    JobCategoryPhaseNamesType[]
  >([]);

  const { mutate, isLoading: isSubmitting } =
    useApisManagersJobCategoriesCreate();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      name: jobCategoryName.value,
      jobCategoryPhase: {
        names: jobCategoryPhaseNames.map((phase) => phase.name),
      },
    };
    mutate(
      { body },
      {
        onSuccess: (res) => {
          toast(res.message);
          navigate("/managers/job_categories");
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "職種設定", href: "/managers/job_categories" },
    { label: "新規作成", href: "/managers/job_categories/new" },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <JobCategoryForm
          jobCategoryName={jobCategoryName.value}
          onChangeJobCategoryName={jobCategoryName.onChange}
          jobCategoryPhases={jobCategoryPhaseNames}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          setJobCategoryPhases={setJobCategoryPhaseNames}
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
