import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMemberKnowledgeInsightPostKnowledgeInsightPostCommentsIndexType } from "~/domains";

type ParamsType = {
  knowledgeInsightPostId: string;
};

const request = async ({
  knowledgeInsightPostId,
}: ParamsType): Promise<ApiMemberKnowledgeInsightPostKnowledgeInsightPostCommentsIndexType> => {
  const response =
    await new ApiClient().get<ApiMemberKnowledgeInsightPostKnowledgeInsightPostCommentsIndexType>(
      `/api/members/knowledge_insight_posts/${knowledgeInsightPostId}/knowledge_insight_post_comments`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  knowledgeInsightPostId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsIndex =
  ({
    knowledgeInsightPostId,
    config = {},
  }: PropsType): UseQueryResult<ApiMemberKnowledgeInsightPostKnowledgeInsightPostCommentsIndexType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsIndex",
        knowledgeInsightPostId,
      ],
      queryFn: () => request({ knowledgeInsightPostId }),
    });
  };
