import React, { FC, FormEvent, MouseEvent } from "react";

import {
  useInput,
  useApisMembersAiChatRoomsCreate,
  useAiChatConversationRequest,
  useDropdown,
  useProvidersCurrentEmployee,
  useOpenAiRequest,
  useBoolean,
} from "~/hooks";

import {
  Form,
  FormSubmitButton,
  DotLoadingAnimation,
} from "~/components/atoms";
import {
  LabelWithDropDownField,
  LabelWithTextArea,
} from "~/components/molecules";

import { FloatingChatInitialFormHeadMessage } from "./FloatingChatInitialFormHeadMessage";

import { OptionType, SingleValueType } from "~/domains";

import {
  AiChatSelectableExpertRoles,
  AiChatSelectablePromptTemplates,
} from "~/constants/aiChatSettings";

type PropsType = {
  onClose: (e: MouseEvent<HTMLButtonElement>) => void;
  setAiChatRoomId: (chatRoomId: string) => void;
  onDisplayChatRooms: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const FloatingChatInitialForm: FC<PropsType> = ({
  onClose,
  setAiChatRoomId,
  onDisplayChatRooms,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const { isChecked: isChatRequesting, setTrue, setFalse } = useBoolean(false);

  const { mutate: postChatRoomRequest, isLoading: isPostChatRoomSubmitting } =
    useApisMembersAiChatRoomsCreate();
  const { aiChatConversationRequest, isEmployeeSubmitting } =
    useAiChatConversationRequest(currentEmployee?.id || "");
  const { openAiRequest: requestSummaryTitle } = useOpenAiRequest(
    currentEmployee?.id || "",
  );

  const [{ value, onChange }, setValue] = useInput("");

  const [
    {
      dropdownValue: selectedExportRole,
      onChange: onChangeExportRole,
      dropdownSelectableValue: selectableExportRoles,
    },
  ] = useDropdown({
    selectableValue: AiChatSelectableExpertRoles,
  });

  const [
    {
      dropdownValue: selectedPromptTemplate,
      onChange: onChangePromptTemplate,
      dropdownSelectableValue: selectablePromptTemplates,
    },
  ] = useDropdown({
    selectableValue: AiChatSelectablePromptTemplates,
  });

  const handleChangePromptTemplate = (
    newValue: SingleValueType<OptionType>,
  ) => {
    if (value && newValue) {
      const isConfirm = confirm("入力中の内容を上書きしてもよろしいですか?");
      if (!isConfirm) return;
    }

    onChangePromptTemplate(newValue);
    if (!newValue) return;

    const selectedTemplate = AiChatSelectablePromptTemplates.find(
      (template) => template.id === newValue.value,
    );
    setValue(selectedTemplate?.template || "");
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setTrue();
    await requestSummaryTitle({
      prompt: `以下のテキストを日本語で10文字程度に要約してください。\n-------\n${value}`,
      onSuccess: ({ chatMessage }) => {
        const content =
          typeof chatMessage?.content === "string" ? chatMessage.content : "";
        postChatRoomRequest(
          {
            body: generateParams(content || value.slice(0, 10)),
          },
          {
            onSuccess: (postChatRoomResponse) => {
              aiChatConversationRequest({
                message: value,
                systemMessage:
                  postChatRoomResponse.aiChatRoom.aiChatRoomSetting
                    ?.aiSystemMessage,
                aiChatRoomId: postChatRoomResponse.aiChatRoom.id,
                onSuccess: () => {
                  setFalse();
                  setAiChatRoomId(postChatRoomResponse.aiChatRoom.id);
                },
              });
            },
            onError: () => {
              setFalse();
            },
          },
        );
      },
      onError: () => {
        setFalse();
      },
    });
  };

  const generateParams = (title: string) => {
    return {
      title,
      aiChatRoomSetting: selectedExportRole
        ? {
            expertRoleName: selectedExportRole.label,
            aiSystemMessage: `あなたは${selectedExportRole.label}として振る舞ってください。`,
          }
        : undefined,
    };
  };

  return (
    <div className="space-y-6">
      <FloatingChatInitialFormHeadMessage
        onClose={onClose}
        onDisplayChatRooms={onDisplayChatRooms}
      />
      <Form className="space-y-6" onSubmit={handleSubmit}>
        <LabelWithDropDownField
          labelText="回答してもらう人物"
          name="exportRole"
          options={selectableExportRoles}
          value={selectedExportRole}
          onChange={onChangeExportRole}
        />
        <LabelWithDropDownField
          labelText="テンプレート選択"
          name="promptTemplate"
          options={selectablePromptTemplates}
          value={selectedPromptTemplate}
          onChange={handleChangePromptTemplate}
        />
        <LabelWithTextArea
          labelText="フリーワード"
          value={value}
          required
          rows={14}
          onChange={onChange}
          placeholder="質問してみましょう"
          name="freeWord"
        />
        {isChatRequesting ? (
          <DotLoadingAnimation className="justify-center" />
        ) : (
          <FormSubmitButton
            value="実行する"
            color="primary"
            className="w-full"
            isReadOnly={isPostChatRoomSubmitting || isEmployeeSubmitting}
          />
        )}
      </Form>
    </div>
  );
};
