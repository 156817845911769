import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";

import { InfiniteDataType, toDateString } from "~/libs";

import {
  useInput,
  useDateRange,
  useApisMembersFocusChallengeEmployeesIndex,
  usePostSortType,
  useMultipleDropdown,
  useArray,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
  useApisMembersFocusChallengeSearchItemsIndex,
} from "~/hooks";

import { RangeDatePropsType } from "~/components/molecules";

import {
  ApiMembersFocusChallengeEmployeesIndexResponseType,
  FocusChallengeEmployeeType,
  MultiValueType,
  OptionType,
  SortType,
  ApiMembersFocusChallengeEmployeesIndexRequestType,
  SingleValueType,
  ID_ALL_TYPE,
} from "~/domains";

type ReturnType = {
  focusChallengeEmployees: FocusChallengeEmployeeType[];
  data?: InfiniteDataType<ApiMembersFocusChallengeEmployeesIndexResponseType>;
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  selectableDropdownCompleteStatusIds: OptionType[];
  selectedDropdownCompleteStatusId?: SingleValueType<OptionType>;
  selectableDropdownHasReplyIds: OptionType[];
  selectedDropdownHasReplyId?: SingleValueType<OptionType>;
  hasNextPage?: boolean;
  isLoading: boolean;
  sort: SortType;
  selectableJobCategoryPhases: OptionType[];
  selectedJobCategoryPhases: MultiValueType<OptionType>;
  selectableDropdownProvidingServices: OptionType[];
  selectedDropdownProvidingServices: MultiValueType<OptionType>;
  deleteIndexFocusChallengeEmployee: (index: number) => void;
  updateIndexFocusChallengeEmployee: (
    newItem: FocusChallengeEmployeeType,
    index: number,
  ) => void;
  unshiftFocusChallengeEmployee: (newItem: FocusChallengeEmployeeType) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeJobCategoryPhases: (options: MultiValueType<OptionType>) => void;
  onChangeDropdownProvidingServices: (
    options: MultiValueType<OptionType>,
  ) => void;
  fetchNextPage: () => void;
  setSort: (val: SortType) => void;
  onChangeDropdownCompleteStatusId: (
    value: SingleValueType<OptionType>,
  ) => void;
  onChangeDropdownHasReplyId: (value: SingleValueType<OptionType>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchMembersFocusChallengeEmployees = (): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const [
    {
      statusValue,
      selectedDropdownCompleteStatusId,
      selectableDropdownCompleteStatusIds,
      onChangeDropdownCompleteStatusId,
    },
  ] = useCompleteStatusDropdown();
  const [
    {
      statusValue: hasReply,
      selectedDropdownCompleteStatusId: selectedDropdownHasReplyId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasReplyIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasReplyId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "リプライあり",
      false: "リプライなし",
    },
  });
  const { sort, snakeKeyValue, setSort } = usePostSortType();
  const [params, setParams] =
    useState<ApiMembersFocusChallengeEmployeesIndexRequestType>({
      sortType: snakeKeyValue,
    });

  const { items, setItems, deleteIndexItem, updateIndexItem, unshiftItem } =
    useArray<FocusChallengeEmployeeType>([]);

  const [
    {
      dropdownValue: selectedJobCategoryPhases,
      dropdownSelectableValue: selectableJobCategoryPhases,
      onChange: onChangeJobCategoryPhases,
      setSelectableOption: setSelectableJobCategoryPhases,
    },
  ] = useMultipleDropdown();
  const [
    {
      dropdownValue: selectedDropdownProvidingServices,
      value: selectedProvidingServices,
      onChange: onChangeDropdownProvidingServices,
      dropdownSelectableValue: selectableDropdownProvidingServices,
      setSelectableOption: setSelectableDropdownProvidingServices,
    },
  ] = useMultipleDropdown();

  useApisMembersFocusChallengeSearchItemsIndex({
    config: {
      onSuccess: (res) => {
        setSelectableDropdownProvidingServices(res.selectableProvidingServices);
        setSelectableJobCategoryPhases([
          ID_ALL_TYPE,
          ...res.selectableJobCategoryPhases,
        ]);
      },
    },
  });

  const { data, isFetching, fetchNextPage, hasNextPage } =
    useApisMembersFocusChallengeEmployeesIndex({
      params,
      config: {
        onSuccess: (data) => {
          setItems(
            data.pages.map((page) => page.focusChallengeEmployees).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      focusCompleted: statusValue,
      hasReply,
      jobCategoryPhaseIds: selectedJobCategoryPhases?.map((ele) => ele.value),
      providingServiceIds: selectedProvidingServices?.map((ele) => ele.id),
      sortType: snakeKeyValue,
      dateFrom: startDate ? toDateString(startDate) : "",
      dateTo: endDate ? toDateString(endDate) : "",
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownCompleteStatusId(null);
    onChangeDropdownHasReplyId(null);
    onChangeDateRange([null, null]);
    onChangeJobCategoryPhases([]);
    onChangeDropdownProvidingServices([]);
    setParams({ sortType: snakeKeyValue });
    setFalseRightSidebarMain();
  };

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      sortType: snakeKeyValue,
    }));
  }, [snakeKeyValue]);

  return {
    focusChallengeEmployees: items,
    data,
    isLoading: isFetching,
    keyword,
    selectableDropdownCompleteStatusIds,
    selectedDropdownCompleteStatusId,
    selectableDropdownHasReplyIds,
    selectedDropdownHasReplyId,
    hasNextPage,
    startDate,
    endDate,
    sort,
    selectableJobCategoryPhases,
    selectedJobCategoryPhases,
    selectableDropdownProvidingServices,
    selectedDropdownProvidingServices,
    unshiftFocusChallengeEmployee: unshiftItem,
    updateIndexFocusChallengeEmployee: updateIndexItem,
    deleteIndexFocusChallengeEmployee: deleteIndexItem,
    fetchNextPage,
    setSort,
    onChangeJobCategoryPhases,
    onChangeDropdownProvidingServices,
    onChangeDateRange,
    onChangeKeyword,
    onChangeDropdownCompleteStatusId,
    onChangeDropdownHasReplyId,
    onSearchSubmit,
    onConditionReset,
  };
};
