import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersKaizenIdeasLikedEmployeesIndexResponseType } from "~/domains";

type RequestDataType = {
  kaizenIdeaId: string;
  page?: number;
};

const request = async ({
  kaizenIdeaId,
  page = 1,
}: RequestDataType): Promise<ApiMembersKaizenIdeasLikedEmployeesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersKaizenIdeasLikedEmployeesIndexResponseType>(
      `/api/members/kaizen_ideas/${kaizenIdeaId}/liked_employees`,
      { page },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  kaizenIdeaId: string;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersKaizenIdeasLikedEmployeesIndex = ({
  kaizenIdeaId,
  config = {},
}: PropsType): UseInfiniteQueryResult<ApiMembersKaizenIdeasLikedEmployeesIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApisMembersKaizenIdeasLikedEmployeesIndex", kaizenIdeaId],
    ({ pageParam }) => {
      return request({ kaizenIdeaId, page: pageParam as number | undefined });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
