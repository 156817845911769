import update from "immutability-helper";
import { toast } from "react-toastify";

import { convertToDropdownOption, convertToValue } from "~/utils";

import {
  useApisManagersQuestionAnswerCategoriesDisplayOrdersUpdate,
  useApisManagersQuestionAnswerCategoriesQuestionAnswersCreate,
  useApisManagersQuestionAnswerCategoriesQuestionAnswersDestroy,
  useApisManagersQuestionAnswerCategoriesQuestionAnswersUpdate,
  useArray,
} from "~/hooks";

import {
  MultiValueType,
  OptionType,
  QuestionAnswerTagType,
  QuestionAnswerType,
} from "~/domains";

export type FormQuestionAnswerType = Pick<
  QuestionAnswerType,
  | "id"
  | "questionAnswerCategoryId"
  | "questionContent"
  | "answerContent"
  | "useScene"
  | "importantPoint"
  | "remark"
  | "link"
  | "displayOrder"
  | "questionAnswerTags"
> & {
  isNew?: boolean;
  isReadOnly?: boolean;
};

type OnChangeStringPropsType = {
  questionAnswer: FormQuestionAnswerType;
  newValue: string;
};

type OnChangeTagsPropsType = {
  questionAnswer: FormQuestionAnswerType;
  newValue: MultiValueType<OptionType>;
};

type ReturnType = {
  isSubmitting: boolean;
  questionAnswers: FormQuestionAnswerType[];
  selectableQuestionAnswerTagOptions: OptionType[];
  onChangeAnswerContent: ({
    questionAnswer,
    newValue,
  }: OnChangeStringPropsType) => void;
  onChangeQuestionContent: ({
    questionAnswer,
    newValue,
  }: OnChangeStringPropsType) => void;
  onChangeUseScene: ({
    questionAnswer,
    newValue,
  }: OnChangeStringPropsType) => void;
  onChangeImportantPoint: ({
    questionAnswer,
    newValue,
  }: OnChangeStringPropsType) => void;
  onChangeRemark: ({
    questionAnswer,
    newValue,
  }: OnChangeStringPropsType) => void;
  onChangeLink: ({ questionAnswer, newValue }: OnChangeStringPropsType) => void;
  addQuestionAnswer: () => void;
  handleTagNameCreate: ({
    questionAnswer,
    newValue,
  }: OnChangeStringPropsType) => void;
  onChangeTags: ({ questionAnswer, newValue }: OnChangeTagsPropsType) => void;
  removeQuestionAnswer: (questionAnswer: FormQuestionAnswerType) => void;
  saveQuestionAnswer: (questionAnswer: FormQuestionAnswerType) => void;
  setNotReadOnly: (questionAnswer: FormQuestionAnswerType) => void;
  setReadOnly: (questionAnswer: FormQuestionAnswerType) => void;
  changeDisplayOrder: (beforeIndex: number, index: number) => void;
};

type PropsType = {
  questionAnswerCategoryId: string;
  questionAnswers: FormQuestionAnswerType[];
  selectableQuestionAnswerTags: QuestionAnswerTagType[];
};

export function useQuestionAnswerForms({
  questionAnswerCategoryId,
  questionAnswers,
  selectableQuestionAnswerTags,
}: PropsType): ReturnType {
  const { mutate: deleteQuestionAnswer } =
    useApisManagersQuestionAnswerCategoriesQuestionAnswersDestroy();
  const { mutate: postQuestionAnswer, isLoading: isCreating } =
    useApisManagersQuestionAnswerCategoriesQuestionAnswersCreate();
  const { mutate: putQuestionAnswer, isLoading: isUpdating } =
    useApisManagersQuestionAnswerCategoriesQuestionAnswersUpdate();
  const { mutate: putQuestionAnswerDisplayOrder } =
    useApisManagersQuestionAnswerCategoriesDisplayOrdersUpdate();

  const defaultItemFormat = (id: string, displayOrder: number) => {
    return {
      id: id,
      questionAnswerCategoryId: questionAnswerCategoryId,
      questionContent: "",
      questionAnswerTags: [],
      answerContent: "",
      useScene: "",
      importantPoint: "",
      remark: "",
      link: "",
      isNew: true,
      displayOrder: displayOrder,
    };
  };

  const { items, findAndReplace, pushItem, findAndRemove, setItems } =
    useArray<FormQuestionAnswerType>(
      questionAnswers.length
        ? questionAnswers.map((questionAnswer) => ({
            ...questionAnswer,
            isNew: false,
            isReadOnly: true,
          }))
        : [defaultItemFormat("1", 1)],
    );

  const tagOptions = useArray<OptionType>(
    selectableQuestionAnswerTags.map((tag) => convertToDropdownOption(tag)),
  );

  const addItem = () => {
    const order = Math.max(...items.map((item) => item.displayOrder)) + 1;
    pushItem(defaultItemFormat(order.toString(), order));
  };

  const changeDisplayOrder = (beforeIndex: number, newIndex: number) => {
    const targetQuestionAnswer = items[beforeIndex];
    if (!targetQuestionAnswer) return;

    const newItems = update(items, {
      $splice: [
        [beforeIndex, 1],
        [newIndex, 0, targetQuestionAnswer],
      ],
    });

    putQuestionAnswerDisplayOrder(
      {
        questionAnswerCategoryId: questionAnswerCategoryId,
        body: generateOrderParams(newItems),
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setItems(newItems);
        },
      },
    );
  };

  const generateOrderParams = (newItems: FormQuestionAnswerType[]) => {
    return newItems
      .filter((item) => !item.isNew)
      .map((item, index) => ({
        id: item.id.toString(),
        displayOrder: index + 1,
      }));
  };

  const removeQuestionAnswer = (questionAnswer: FormQuestionAnswerType) => {
    questionAnswer.isNew
      ? findAndRemove((item) => item.id === questionAnswer.id)
      : deleteQuestionAnswer(
          {
            questionAnswerCategoryId: questionAnswerCategoryId,
            id: questionAnswer.id,
          },
          {
            onSuccess: (data) => {
              findAndRemove((item) => item.id === questionAnswer.id);
              toast(data.message);
            },
          },
        );
  };

  const saveQuestionAnswer = (questionAnswer: FormQuestionAnswerType) => {
    questionAnswer.isNew
      ? handleCreateQuestionAnswer(questionAnswer)
      : handleUpdateQuestionAnswer(questionAnswer);
  };

  const handleCreateQuestionAnswer = (
    questionAnswer: FormQuestionAnswerType,
  ) => {
    postQuestionAnswer(
      {
        questionAnswerCategoryId: questionAnswerCategoryId,
        body: generateParams(questionAnswer),
      },
      {
        onSuccess: (data) => {
          onSuccessAction(data.message, data.questionAnswer.id, questionAnswer);
        },
      },
    );
  };

  const handleUpdateQuestionAnswer = (
    questionAnswer: FormQuestionAnswerType,
  ) => {
    putQuestionAnswer(
      {
        questionAnswerCategoryId: questionAnswerCategoryId,
        id: questionAnswer.id,
        body: generateParams(questionAnswer),
      },
      {
        onSuccess: (data) => {
          onSuccessAction(data.message, data.questionAnswer.id, questionAnswer);
        },
      },
    );
  };

  const onSuccessAction = (
    successMessage: string,
    newQuestionAnswerId: string,
    questionAnswer: FormQuestionAnswerType,
  ) => {
    toast(successMessage);
    const newObject = {
      ...questionAnswer,
      id: newQuestionAnswerId,
      isNew: false,
      isReadOnly: true,
    };
    findAndReplace(newObject, (item) => item.id === questionAnswer.id);
  };

  const generateParams = (questionAnswer: FormQuestionAnswerType) => {
    return {
      questionContent: questionAnswer.questionContent,
      answerContent: questionAnswer.answerContent,
      useScene: questionAnswer.useScene,
      importantPoint: questionAnswer.importantPoint,
      remark: questionAnswer.remark,
      link: questionAnswer.link,
      displayOrder: questionAnswer.displayOrder,
      tagNames: questionAnswer.questionAnswerTags.map((tag) => tag.name),
    };
  };

  const setNotReadOnly = (questionAnswer: FormQuestionAnswerType) => {
    const newObject = { ...questionAnswer, isReadOnly: false };

    findAndReplace(newObject, (item) => item.id === questionAnswer.id);
  };

  const setReadOnly = (questionAnswer: FormQuestionAnswerType) => {
    const newObject = { ...questionAnswer, isReadOnly: true };

    findAndReplace(newObject, (item) => item.id === questionAnswer.id);
  };

  const randomNumber = () => {
    return Math.floor(Math.random() * 10000000).toString();
  };

  const handleTagNameCreate = ({
    questionAnswer,
    newValue,
  }: OnChangeStringPropsType) => {
    const randomNumberId = randomNumber();
    const newTagItem = [
      ...questionAnswer.questionAnswerTags,
      { id: randomNumberId, name: newValue },
    ];
    const newObject = { ...questionAnswer, questionAnswerTags: newTagItem };

    findAndReplace(newObject, (item) => item.id === questionAnswer.id);
    tagOptions.pushItem({ value: randomNumberId, label: newValue });
  };

  const onChangeTags = ({
    questionAnswer,
    newValue,
  }: OnChangeTagsPropsType) => {
    const newTagItem = newValue.map((val) => convertToValue(val));
    const newObject = { ...questionAnswer, questionAnswerTags: newTagItem };

    findAndReplace(newObject, (item) => item.id === questionAnswer.id);
  };

  const onChangeQuestionContent = ({
    questionAnswer,
    newValue,
  }: OnChangeStringPropsType) => {
    const newObject = { ...questionAnswer, questionContent: newValue };

    findAndReplace(newObject, (item) => item.id === questionAnswer.id);
  };

  const onChangeAnswerContent = ({
    questionAnswer,
    newValue,
  }: OnChangeStringPropsType) => {
    const newObject = { ...questionAnswer, answerContent: newValue };

    findAndReplace(newObject, (item) => item.id === questionAnswer.id);
  };

  const onChangeUseScene = ({
    questionAnswer,
    newValue,
  }: OnChangeStringPropsType) => {
    const newObject = { ...questionAnswer, useScene: newValue };

    findAndReplace(newObject, (item) => item.id === questionAnswer.id);
  };

  const onChangeImportantPoint = ({
    questionAnswer,
    newValue,
  }: OnChangeStringPropsType) => {
    const newObject = { ...questionAnswer, importantPoint: newValue };

    findAndReplace(newObject, (item) => item.id === questionAnswer.id);
  };

  const onChangeRemark = ({
    questionAnswer,
    newValue,
  }: OnChangeStringPropsType) => {
    const newObject = { ...questionAnswer, remark: newValue };

    findAndReplace(newObject, (item) => item.id === questionAnswer.id);
  };

  const onChangeLink = ({
    questionAnswer,
    newValue,
  }: OnChangeStringPropsType) => {
    const newObject = { ...questionAnswer, link: newValue };

    findAndReplace(newObject, (item) => item.id === questionAnswer.id);
  };

  return {
    isSubmitting: isCreating || isUpdating,
    questionAnswers: items,
    selectableQuestionAnswerTagOptions: tagOptions.items,
    onChangeQuestionContent,
    onChangeAnswerContent,
    onChangeUseScene,
    onChangeImportantPoint,
    onChangeRemark,
    onChangeLink,
    addQuestionAnswer: addItem,
    removeQuestionAnswer,
    saveQuestionAnswer,
    changeDisplayOrder,
    handleTagNameCreate,
    onChangeTags,
    setNotReadOnly,
    setReadOnly,
  };
}
