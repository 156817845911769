import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersBusinessSectionsBusinessSectionCompetencyReviewsIndexResponseType } from "~/domains";

type RequestDataType = {
  businessSectionId: string;
  page?: number;
};

const request = async ({
  businessSectionId,
  page = 1,
}: RequestDataType): Promise<ApiManagersBusinessSectionsBusinessSectionCompetencyReviewsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersBusinessSectionsBusinessSectionCompetencyReviewsIndexResponseType>(
      `/api/managers/business_sections/${businessSectionId}/business_section_competency_reviews`,
      { page },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  businessSectionId: string;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisManagersBusinessSectionsBusinessSectionCompetencyReviewsIndex =
  ({
    businessSectionId,
    config = {},
  }: PropsType): UseInfiniteQueryResult<ApiManagersBusinessSectionsBusinessSectionCompetencyReviewsIndexResponseType> => {
    return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
      [
        "ApiManagersBusinessSectionsBusinessSectionCompetencyReviewsIndex",
        businessSectionId,
      ],
      ({ pageParam }) => {
        return request({
          businessSectionId,
          page: pageParam as number | undefined,
        });
      },
      {
        ...config,
        getNextPageParam: (data) => {
          return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
        },
      },
    );
  };
