import React, { FC, MouseEvent } from "react";

import { PostDetailWrapper } from "~/components/atoms";
import { PostHeader, TextEditor } from "~/components/organisms";

import {
  AvatarAndNameEmployeeType,
  RawDraftContentState,
  PreviewWithFileType,
  EditorStateType,
  AttachFileType,
} from "~/domains";

type PropsType = {
  submitButton: {
    onSubmit: (e: MouseEvent<HTMLButtonElement>) => void;
    isSubmitting: boolean;
  };
  mentions: AvatarAndNameEmployeeType[];
  post: {
    createdAt: string;
    employee: AvatarAndNameEmployeeType;
    content: RawDraftContentState;
  };
  files: PreviewWithFileType[];
  editorStateContent: EditorStateType;
  blockQuote?: {
    content: RawDraftContentState;
    employee: AvatarAndNameEmployeeType;
    files: AttachFileType[];
    createdAt: string;
  };
  closeEditForm: () => void;
  setFiles: (files: PreviewWithFileType[]) => void;
  setEditorStateContent: (content: EditorStateType) => void;
};

export const CommentEditForm: FC<PropsType> = ({
  submitButton,
  mentions,
  post,
  files,
  editorStateContent,
  blockQuote,
  setFiles,
  closeEditForm,
  setEditorStateContent,
}: PropsType) => {
  const cancelItems = [
    {
      text: "編集をキャンセル",
      onClick: closeEditForm,
      disabled: false,
    },
  ];

  return (
    <PostDetailWrapper skipHoverStyle>
      <PostHeader
        miniText={post.createdAt}
        postedEmployee={post.employee}
        menuItems={cancelItems}
      />
      <TextEditor
        submitButton={submitButton}
        mentions={mentions}
        editorState={editorStateContent}
        setEditorState={setEditorStateContent}
        files={files}
        onChangeFiles={setFiles}
        blockQuote={blockQuote}
        className="mt-4"
      />
    </PostDetailWrapper>
  );
};
