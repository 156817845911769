import React, { FC, MouseEvent } from "react";

import { useBoolean } from "~/hooks";

import { FloatingChatInitialForm } from "./FloatingChatInitialForm";
import { FloatingChatRooms } from "./FloatingChatRooms/FloatingChatRooms";

type PropsType = {
  onClose: (e: MouseEvent<HTMLButtonElement>) => void;
  setAiChatRoomId: (chatRoomId: string) => void;
};

export const FloatingChatInitial: FC<PropsType> = ({
  onClose,
  setAiChatRoomId,
}: PropsType) => {
  const {
    isChecked: isInitialForm,
    setTrue: setDisplayInitialForm,
    setFalse: setDisplayChatRooms,
  } = useBoolean(true);

  const handleOnBack = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setDisplayInitialForm();
  };

  const handleOnDisplayChatRooms = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setDisplayChatRooms();
  };

  return isInitialForm ? (
    <FloatingChatInitialForm
      onClose={onClose}
      setAiChatRoomId={setAiChatRoomId}
      onDisplayChatRooms={handleOnDisplayChatRooms}
    />
  ) : (
    <FloatingChatRooms
      onClose={onClose}
      setAiChatRoomId={setAiChatRoomId}
      onBack={handleOnBack}
    />
  );
};
