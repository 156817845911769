import { MouseEvent } from "react";

import { toast } from "react-toastify";

import { downloadBlob } from "~/utils";

type DownloadType = {
  fileUrl: string;
  fileName: string;
  onSuccess?: () => void;
};

type ReturnType = {
  download: (
    e: MouseEvent<HTMLButtonElement>,
    { fileUrl, fileName, onSuccess }: DownloadType,
  ) => void;
};

export const useDownloadFile = (): ReturnType => {
  const download = async (
    e: MouseEvent<HTMLButtonElement>,
    { fileUrl, fileName, onSuccess }: DownloadType,
  ) => {
    e.preventDefault();

    await fetch(fileUrl)
      .then((response) => {
        void response
          .blob()
          .then((blob) => downloadBlob(blob, fileName))
          .then(() => onSuccess?.());
      })
      .catch(() => {
        toast.error("ファイルのダウンロードに失敗しました。");
      });
  };

  return { download };
};
