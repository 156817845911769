import React, { FC, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useApisAdminOperatorsOperatorsCreate,
  useApisAdminOperatorsOperatorsNew,
  useInput,
  useMultipleDropdown,
} from "~/hooks";

import { Form, FormSubmitButton } from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import {
  DisplayablePasswordFiled,
  LabelWithMultipleDropdownField,
  LabelWithTextField,
} from "~/components/molecules";

export const AdminOperatorsOperatorsNew: FC = () => {
  const navigate = useNavigate();
  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: "/admin_operators",
    },
    {
      label: "運営者一覧",
      href: "/admin_operators/operators",
    },
    {
      label: "新規作成",
      href: "/admin_operators/operators/new",
    },
  ];

  const [{ value: name, onChange: onChangeName }] = useInput("");
  const [{ value: email, onChange: onChangeEmail }] = useInput("");
  const [{ value: password, onChange: onChangePassword }] = useInput("");
  const [
    {
      dropdownSelectableValue: dropdownSelectableCompanies,
      dropdownValue: dropdownCompanies,
      onChange: onChangeDropdownCompanies,
      setSelectableOption: setSelectableCompanies,
    },
  ] = useMultipleDropdown();

  useApisAdminOperatorsOperatorsNew({
    config: {
      onSuccess: (data) => {
        setSelectableCompanies(data.companies);
      },
    },
  });
  const { isLoading: isSubmitting, mutate: createRequest } =
    useApisAdminOperatorsOperatorsCreate();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    createRequest(
      {
        body: {
          name,
          email,
          password,
          companyIds: dropdownCompanies.map((item) => item.value),
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate("/admin_operators/operators");
        },
      },
    );
  };

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      <div className="flex items-center justify-between">
        <h1 className="text-xl">パートナーアカウント新規作成</h1>
      </div>
      <Form className="mt-6 space-y-6" onSubmit={handleSubmit}>
        <LabelWithTextField
          labelText="名前"
          type="text"
          name="name"
          placeholder="名前"
          required
          value={name}
          onChange={onChangeName}
        />
        <LabelWithTextField
          labelText="メールアドレス"
          type="email"
          placeholder="email@example.com"
          name="email"
          autoComplete="email"
          required
          value={email}
          onChange={onChangeEmail}
        />
        <DisplayablePasswordFiled
          label="パスワード"
          name="password"
          autoComplete="current-password"
          onChange={onChangePassword}
          required
        />
        <LabelWithMultipleDropdownField
          labelText="閲覧できる会社"
          required
          name="companyIds"
          options={dropdownSelectableCompanies}
          value={dropdownCompanies}
          onChange={onChangeDropdownCompanies}
        />
        <FormSubmitButton
          value="新規作成する"
          color="primary"
          className="w-full"
          isReadOnly={isSubmitting}
        />
      </Form>
    </AdminOperatorLayout>
  );
};
