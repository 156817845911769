import React, { FC } from "react";

import { CatchTitleBorder } from "~/components/atoms";

import { DocumentFile } from "./DocumentFile";

import { DocumentFileCategoryWithDocumentFileType } from "~/domains";

type PropsType = {
  documentFileCategory: Pick<
    DocumentFileCategoryWithDocumentFileType,
    "name" | "documentFiles"
  >;
};

export const DocumentFileCategoryList: FC<PropsType> = ({
  documentFileCategory,
}: PropsType) => {
  const { name, documentFiles } = documentFileCategory;

  return (
    <div>
      <CatchTitleBorder text={name} count={documentFiles.length} />
      <div className="grid grid-cols-2 miniTablet:grid-cols-3 mt-6 gap-y-6 gap-x-4">
        {documentFiles.map((documentFile) => (
          <DocumentFile key={documentFile.id} documentFile={documentFile} />
        ))}
      </div>
    </div>
  );
};
