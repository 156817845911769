import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiAnalyticsPlansCumulativeRankingsIndexResponseType,
  ApiAnalyticsPlansCumulativeRankingsIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiAnalyticsPlansCumulativeRankingsIndexRequestType;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApiAnalyticsPlansCumulativeRankingsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiAnalyticsPlansCumulativeRankingsIndexResponseType>(
      "/api/analytics_plans/cumulative_rankings",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiAnalyticsPlansCumulativeRankingsIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisAnalyticsPlansCumulativeRankingsIndex = ({
  params,
  config = {},
}: PropsType = {}): UseQueryResult<ApiAnalyticsPlansCumulativeRankingsIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisAnalyticsPlansCumulativeRankingsIndex", params],
    queryFn: () => request({ params }),
  });
};
