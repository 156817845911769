import React, { FC } from "react";
import { Outlet } from "react-router-dom";

import { CurrentAdminOperatorProvider } from "~/components/providers";

export const AdminOperatorLayoutWrapper: FC = () => {
  return (
    <CurrentAdminOperatorProvider>
      <Outlet />
    </CurrentAdminOperatorProvider>
  );
};
