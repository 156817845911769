import React, { FC } from "react";

import Select from "react-select";

import { Label, RequiredHiddenField } from "~/components/atoms";

import { BooleanOptionType, SingleValueType, MultiValueType } from "~/domains";

type PropsType = {
  labelText?: string;
  name: string;
  trueLabel: string;
  falseLabel: string;
  value?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  className?: string;
  onChange?: (newValue?: boolean) => void;
  required?: boolean;
  parentClassName?: string;
};

export const BooleanDropDownField: FC<PropsType> = ({
  name,
  labelText,
  trueLabel,
  falseLabel,
  value,
  isDisabled,
  placeholder = "選択してください",
  className = "",
  parentClassName = "",
  required = false,
  onChange,
}: PropsType) => {
  const options: MultiValueType<BooleanOptionType> = [
    { value: false, label: falseLabel },
    { value: true, label: trueLabel },
  ];

  const handleChange = (newValue: SingleValueType<BooleanOptionType>) => {
    if (!onChange) return;

    onChange(newValue?.value);
  };

  const dropdownValue =
    value === undefined
      ? null
      : options.find((option) => option.value === value) || null;

  return (
    <div
      className={`relative ${
        isDisabled ? "cursor-not-allowed" : ""
      } ${parentClassName}`}
    >
      {labelText && (
        <Label
          htmlFor={name}
          labelText={labelText}
          required={required}
          className="block"
        />
      )}
      <Select
        name={name}
        value={dropdownValue}
        options={options}
        isDisabled={isDisabled}
        isSearchable
        isClearable
        placeholder={placeholder}
        className={`${labelText ? "mt-1.5" : ""} ${className}`}
        classNames={{
          placeholder: () => "!text-secondary-400",
          option: ({ isSelected, isFocused }) =>
            isSelected ? "!bg-primary-600" : isFocused ? "!bg-primary-200" : "",
          control: ({ isDisabled }) =>
            isDisabled ? "!bg-secondary-300 !border-secondary-400" : "",
          singleValue: ({ isDisabled }) =>
            isDisabled ? "!text-secondary-600" : "",
          multiValueRemove: () =>
            "hover:!bg-primary-400 hover:!text-primary-600 !bg-primary-200",
          multiValueLabel: () => "!bg-primary-200",
        }}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 1 }) }}
        onChange={handleChange}
        noOptionsMessage={() => "選択肢がありません"}
      />
      {required && (
        <RequiredHiddenField
          value={dropdownValue === null ? undefined : dropdownValue.label}
        />
      )}
    </div>
  );
};
