import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersQuestionAnswerCategoriesNewType } from "~/domains";

const request =
  async (): Promise<ApiManagersQuestionAnswerCategoriesNewType> => {
    const response =
      await new ApiClient().get<ApiManagersQuestionAnswerCategoriesNewType>(
        "/api/managers/question_answer_categories/new",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersQuestionAnswerCategoriesNew = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiManagersQuestionAnswerCategoriesNewType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersQuestionAnswerCategoriesNew"],
    queryFn: request,
  });
};
