import React, { ComponentProps, FC, MouseEvent } from "react";

import { Icon } from "~/components/atoms";

type PropsType = {
  disabled?: boolean;
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  srOnlyText?: string;
  icon: ComponentProps<typeof Icon>;
  withHoverEffect?: boolean;
};

export const ButtonWithIcon: FC<PropsType> = ({
  className = "",
  srOnlyText = "",
  disabled = false,
  icon,
  withHoverEffect = false,
  onClick,
}: PropsType) => {
  return (
    <button
      type="button"
      className={`relative ${withHoverEffect ? "p-1.5 rounded-full hover:bg-secondary-200" : ""} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {srOnlyText && <span className="sr-only">{srOnlyText}</span>}
      <Icon {...icon} />
    </button>
  );
};
