import React, { FC } from "react";

import { AreaChart, ModalWrapper } from "~/components/atoms";

type PropsType = {
  onClose: () => void;
  title: string;
  className?: string;
  datasets: {
    name: string;
    value: number;
  }[];
};

export const AreaChartModal: FC<PropsType> = ({
  onClose,
  title,
  datasets,
  className = "",
}: PropsType) => {
  return (
    <ModalWrapper onClose={onClose} headerTitle={title} className={className}>
      <div className="mt-4 rounded-md">
        <AreaChart datasets={datasets} />
      </div>
    </ModalWrapper>
  );
};
