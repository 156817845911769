import { useState } from "react";

import { ToSnakeCaseString } from "~/utils";

import { SortType, SnakeCaseSortType } from "~/domains";

type ReturnType = {
  sort: SortType;
  snakeKeyValue: SnakeCaseSortType;
  setSort: (val: SortType) => void;
};

export const usePostSortType = (
  initialValue = "createdAtDesc" as SortType,
): ReturnType => {
  const [sort, setSort] = useState<SortType>(initialValue);
  return {
    sort: sort,
    snakeKeyValue: ToSnakeCaseString(sort) as SnakeCaseSortType,
    setSort: (val: SortType) => setSort(val),
  };
};
