import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersSkillEvaluationTermMutateType } from "~/domains";

type RequestDataType = {
  id: string;
  body: {
    title: string;
    fromDate: string;
    toDate: string;
    publicStatusId: number;
  };
};

const request = ({ id, body }: RequestDataType) => {
  return new ApiClient()
    .put<ApiManagersSkillEvaluationTermMutateType>(
      `/api/managers/skill_evaluation_terms/${id}`,
      {
        skillEvaluationTerm: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersSkillEvaluationTermsUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersSkillEvaluationTermMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
