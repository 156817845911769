import React, { FC, ChangeEvent, MouseEvent } from "react";

import { useBoolean } from "~/hooks";

import { IconButtonWithTextField } from "~/components/molecules";

import { FormSizes } from "~/constants/buttonAndForm";

type PropsType = {
  label: string;
  name: string;
  autoComplete?: string;
  required?: boolean;
  className?: string;
  size?: keyof typeof FormSizes;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const DisplayablePasswordFiled: FC<PropsType> = ({
  label,
  name,
  autoComplete,
  required = false,
  className = "",
  size = "sm",
  onChange,
}: PropsType) => {
  const { isChecked: isPasswordView, toggle: togglePasswordView } =
    useBoolean(false);

  const handleToggledPasswordView = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    togglePasswordView();
  };

  return (
    <IconButtonWithTextField
      icon={isPasswordView ? "hiOutlineEye" : "hiOutlineEyeOff"}
      label={label}
      type={isPasswordView ? "text" : "password"}
      required={required}
      placeholder="password"
      name={name}
      size={size}
      autoComplete={autoComplete}
      onChange={onChange}
      onClick={handleToggledPasswordView}
      className={className}
    />
  );
};
