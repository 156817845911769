import React, { FC } from "react";

import { CatchTitleBorder } from "~/components/atoms";
import { InfiniteScroll } from "~/components/molecules";

import { BusinessSectionMemoReviewDetailWithEditForm } from "./BusinessSectionMemoReviewDetailWithEditForm";

import { BusinessSectionType, BusinessSectionMemoReviewType } from "~/domains";

type PropsType = {
  businessSection: BusinessSectionType;
  businessSectionMemoReviews: BusinessSectionMemoReviewType[];
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isLoading: boolean;
  className?: string;
  updateIndexPost: (
    newItem: BusinessSectionMemoReviewType,
    index: number,
  ) => void;
  deleteIndexPost: (index: number) => void;
};

export const BusinessSectionMemoReviewDetailWithEditForms: FC<PropsType> = ({
  businessSection,
  businessSectionMemoReviews,
  fetchNextPage,
  hasNextPage,
  isLoading,
  deleteIndexPost,
  updateIndexPost,
  className,
}: PropsType) => {
  return (
    <div className={className}>
      <CatchTitleBorder text={`${businessSection.name}の更新履歴`} />
      <InfiniteScroll
        itemsLength={businessSectionMemoReviews.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        isLoading={isLoading}
        className={"mt-9"}
        scrollClassName="space-y-6"
      >
        {businessSectionMemoReviews.map((businessSectionMemoReview, index) => (
          <BusinessSectionMemoReviewDetailWithEditForm
            key={businessSectionMemoReview.id}
            businessSectionMemoReview={businessSectionMemoReview}
            deleteIndexPost={() => deleteIndexPost(index)}
            updateIndexPost={(newItem) => updateIndexPost(newItem, index)}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
