import React, { FC, ReactNode, useState, createContext } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { useApisPartnerConsolesProfilesShow } from "~/hooks";

import { Loading } from "~/components/molecules";

import {
  AdminOperatorType,
  ApisAdminOperatorsProfilesShowResponseType,
} from "~/domains";

export const CurrentPartnerConsoleContext = createContext<{
  currentAdminOperator?: AdminOperatorType;
  refetch: () => void;
}>({
  currentAdminOperator: undefined,
  refetch: () => "",
});

type PropsType = {
  children: ReactNode;
};

export const CurrentPartnerConsoleProvider: FC<PropsType> = ({
  children,
}: PropsType) => {
  const navigate = useNavigate();
  const [currentAdminOperator, setCurrentAdminOperator] =
    useState<AdminOperatorType>();
  const onError = (error: unknown) => {
    error instanceof Error && toast.error(error.message);
    navigate("/partner_consoles/sign_in");
  };

  const onSuccess = (data: ApisAdminOperatorsProfilesShowResponseType) => {
    setCurrentAdminOperator(data.adminOperator);
  };

  const { refetch } = useApisPartnerConsolesProfilesShow({
    config: {
      onSuccess,
      onError,
    },
  });

  if (!currentAdminOperator) return <Loading className="mt-36" />;

  return (
    <CurrentPartnerConsoleContext.Provider
      value={{ currentAdminOperator, refetch }}
    >
      {children}
    </CurrentPartnerConsoleContext.Provider>
  );
};
