import React, { FC } from "react";

type PropsType = {
  text: string;
  className?: string;
  count?: number;
};

export const CatchTitleBorder: FC<PropsType> = ({
  text,
  className = "",
  count,
}: PropsType) => {
  return (
    <div
      className={`flex items-center justify-center before:border-gray-200 before:w-full before:border-t before:mr-4 after:border-gray-200 after:w-full after:border-t after:ml-4 ${className}`}
    >
      <h3 className="text-primary-600 text-center font-semibold text-lg min-w-fit">
        {text}
        {count !== undefined && (
          <span className="text-sm text-secondary-600 border border-solid border-secondary-400 rounded px-1.5 ml-1">
            {count}
          </span>
        )}
      </h3>
    </div>
  );
};
