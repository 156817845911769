import React, { FC, ReactNode } from "react";

import { Helmet } from "react-helmet-async";

import AdminOperatorFavicon from "~/assets/images/logos/admin_operator_favicon.png";

import { Link } from "~/components/atoms";
import { EmployeeLayoutMainContent } from "~/components/layouts";
import { Header } from "~/components/organisms";

import { BreadCrumbItemType } from "~/domains";

type Props = {
  breadCrumbItems: BreadCrumbItemType[];
  className?: string;
  children?: ReactNode;
  withRightSidebar?: boolean;
};

export const AdminOperatorLayout: FC<Props> = ({
  breadCrumbItems,
  children,
  withRightSidebar = false,
  className = "",
}: Props) => {
  const spanClass = withRightSidebar ? "border-r border-gray-300" : "";

  return (
    <>
      <Helmet defer={false}>
        <link
          rel="icon"
          type="image/png"
          href={AdminOperatorFavicon as string}
        />
      </Helmet>
      <Header
        breadCrumbItems={breadCrumbItems}
        withRightSidebar={withRightSidebar}
      />
      <div className="flex text-gray-800 mobile-screen-height miniTablet:miniTablet-screen-height">
        <div className="miniTablet:w-[20%] flex items-start bg-primary-200 h-full">
          <aside className="space-y-4 mt-4 ml-4 ">
            <Link to="/admin_operators" className="block max-w-full">
              会社一覧
            </Link>
            <Link to="/admin_operators/versions" className="block max-w-full">
              バージョン一覧
            </Link>
            <Link to="/admin_operators/operators" className="block max-w-full">
              運営者一覧
            </Link>
            <Link
              to="/admin_operators/setting/profile"
              className="block max-w-full"
            >
              プロフィール編集
            </Link>
          </aside>
        </div>
        <div className={`w-full miniTablet:w-[80%] ${spanClass} ${className}`}>
          <EmployeeLayoutMainContent withRightSidebar={false}>
            {children}
          </EmployeeLayoutMainContent>
        </div>
      </div>
    </>
  );
};
