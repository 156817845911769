import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

const request = () => {
  return new ApiClient()
    .put<ResponseMessageType>("/api/members/mention_notifications")
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersMentionNotificationsUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseType> => {
  return useMutation(request, { ...options });
};
