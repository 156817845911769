import { MouseEvent, ChangeEvent, FormEvent, useEffect } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersSkillEvaluationTermsEmployeesCommentsCreate,
  useApisManagersSkillEvaluationTermsEmployeesCommentsDestroy,
  useApisManagersSkillEvaluationTermsEmployeesCommentsUpdate,
  useBoolean,
  useInput,
} from "~/hooks";

import { SkillEvaluationTermEmployeeCommentType } from "~/domains";

type ReturnType = {
  isReadOnly: boolean;
  comment: string;
  isSubmitting: boolean;
  onChangeComment: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleNotReadonly: (e: MouseEvent<HTMLButtonElement>) => void;
  handleReadonly: (e: MouseEvent<HTMLButtonElement>) => void;
  handleSave: (e: FormEvent<HTMLFormElement>) => void;
  handleDelete: (e: MouseEvent<HTMLButtonElement>) => void;
};

type PropsType = {
  skillEvaluationTermEmployeeComment?: SkillEvaluationTermEmployeeCommentType;
  skillEvaluationTermId: string;
  revieweeEmployeeId?: string;
};

export const useSkillEvaluationTermEmployeeCommentForm = ({
  skillEvaluationTermEmployeeComment,
  skillEvaluationTermId,
  revieweeEmployeeId,
}: PropsType): ReturnType => {
  const { isChecked: isReadOnly, setFalse, setTrue } = useBoolean(true);

  const [{ value: comment, onChange: onChangeComment }, setComment] = useInput(
    skillEvaluationTermEmployeeComment?.comment || "",
  );

  const { mutate: postRequest, isLoading: posting } =
    useApisManagersSkillEvaluationTermsEmployeesCommentsCreate();
  const { mutate: putRequest, isLoading: putting } =
    useApisManagersSkillEvaluationTermsEmployeesCommentsUpdate();
  const { mutate: deleteRequest, isLoading: deleting } =
    useApisManagersSkillEvaluationTermsEmployeesCommentsDestroy();

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!revieweeEmployeeId) return;

    skillEvaluationTermEmployeeComment
      ? putRequest(
          {
            skillEvaluationTermId: skillEvaluationTermId,
            employeeId: revieweeEmployeeId,
            body: {
              comment,
            },
          },
          {
            onSuccess: (data) => {
              toast(data.message);
              setTrue();
            },
          },
        )
      : postRequest(
          {
            skillEvaluationTermId: skillEvaluationTermId,
            employeeId: revieweeEmployeeId,
            body: {
              comment,
            },
          },
          {
            onSuccess: (data) => {
              toast(data.message);
              setTrue();
            },
          },
        );
  };

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!revieweeEmployeeId) return;

    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        skillEvaluationTermId: skillEvaluationTermId,
        employeeId: revieweeEmployeeId,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setTrue();
          setComment("");
        },
      },
    );
  };

  const handleNotReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setFalse();
  };

  const handleReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setTrue();
  };

  useEffect(() => {
    if (skillEvaluationTermEmployeeComment) {
      setComment(skillEvaluationTermEmployeeComment.comment);
    }
  }, [setComment, skillEvaluationTermEmployeeComment]);

  return {
    isReadOnly,
    comment,
    isSubmitting: posting || putting || deleting,
    onChangeComment,
    handleNotReadonly,
    handleReadonly,
    handleSave,
    handleDelete,
  };
};
