import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisPartnerConsolesCompaniesAnalyticsEmployeesPostsCalendarsIndexRequestTypes,
  ApisPartnerConsolesCompaniesAnalyticsEmployeesPostsCalendarsIndexResponseTypes,
} from "~/domains";

type RequestDataType = {
  employeeId: string;
  companyId: string;
  params: ApisPartnerConsolesCompaniesAnalyticsEmployeesPostsCalendarsIndexRequestTypes;
};

const request = async ({
  employeeId,
  companyId,
  params,
}: RequestDataType): Promise<ApisPartnerConsolesCompaniesAnalyticsEmployeesPostsCalendarsIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisPartnerConsolesCompaniesAnalyticsEmployeesPostsCalendarsIndexResponseTypes>(
      `/api/partner_consoles/companies/${companyId}/analytics/employees/${employeeId}/posts_calendars`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params: ApisPartnerConsolesCompaniesAnalyticsEmployeesPostsCalendarsIndexRequestTypes;
  employeeId: string;
  companyId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisPartnerConsolesCompaniesAnalyticsEmployeesPostsCalendarsIndex =
  ({
    params,
    companyId,
    employeeId,
    config = {},
  }: PropsType): UseQueryResult<ApisPartnerConsolesCompaniesAnalyticsEmployeesPostsCalendarsIndexResponseTypes> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisPartnerConsolesCompaniesAnalyticsEmployeesPostsCalendarsIndex",
        companyId,
        employeeId,
        params,
      ],
      queryFn: () => request({ companyId, employeeId, params }),
    });
  };
