import { MouseEvent, ChangeEvent, FormEvent, useState } from "react";

import {
  useInput,
  useApisManagersQuestionAnswerCategoriesIndex,
  useArray,
  useDropdown,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  OptionType,
  ApiManagersQuestionAnswerCategoriesIndexRequestType,
  AllPublicStatus,
  BusinessDivisionType,
  ChildType,
  ParentType,
  SingleValueType,
  QuestionAnswerCategoryType,
} from "~/domains";

type ReturnType = {
  questionAnswerCategories: QuestionAnswerCategoryType[];
  nameKeyword: string;
  hasNextPage?: boolean;
  isLoading: boolean;
  allBusinessSectionsDistribution?: boolean;
  selectableDropdownPublicStatusIds: OptionType[];
  selectedDropdownPublicStatusId?: SingleValueType<OptionType>;
  selectableDropdownDivisions: BusinessDivisionType[];
  selectedDropdownDivisions: BusinessDivisionType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  deleteIndexItem: (index: number) => void;
  unshiftItem: (item: QuestionAnswerCategoryType) => void;
  findAndRemove: (func: (item: QuestionAnswerCategoryType) => boolean) => void;
  onChangeDropdownPublicStatusId: (value: SingleValueType<OptionType>) => void;
  onChangeNameKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  setAllBusinessSectionsDistribution: (value: boolean | undefined) => void;
};

export const useSearchManagersQuestionAnswerCategories = (): ReturnType => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [{ value: nameKeyword, onChange: onChangeNameKeyword }, seNameKeyword] =
    useInput("");
  const [allBusinessSectionsDistribution, setAllBusinessSectionsDistribution] =
    useState<boolean | undefined>();
  const [
    {
      dropdownValue: selectedDropdownPublicStatusId,
      onChange: onChangeDropdownPublicStatusId,
      dropdownSelectableValue: selectableDropdownPublicStatusIds,
    },
    setDropdownPublicStatus,
  ] = useDropdown({
    selectableValue: AllPublicStatus.map((status) => ({
      name: status.name,
      id: status.id.toString(),
    })),
  });
  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });
  const [params, setParams] =
    useState<ApiManagersQuestionAnswerCategoriesIndexRequestType>();

  const { items, setItems, deleteIndexItem, unshiftItem, findAndRemove } =
    useArray<QuestionAnswerCategoryType>([]);

  const { fetchNextPage, hasNextPage, isFetching } =
    useApisManagersQuestionAnswerCategoriesIndex({
      params,
      config: {
        onSuccess: (data) => {
          setItems(
            data.pages.map((page) => page.questionAnswerCategories).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      nameKeyword,
      businessDivisionIds: selectedDivisions.map((division) => division.id),
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      publicStatusId: selectedDropdownPublicStatusId?.value,
      allBusinessSectionsDistribution,
    });
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    seNameKeyword("");
    setDropdownPublicStatus();
    onDivisionChange([]);
    onSectionChange([]);
    setParams({});
    setAllBusinessSectionsDistribution(undefined);
  };

  return {
    questionAnswerCategories: items,
    nameKeyword,
    isLoading: isFetching,
    hasNextPage,
    allBusinessSectionsDistribution,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownDivisions: selectableDivisions,
    selectedDropdownDivisions: selectedDivisions,
    selectableDropdownSections: optionSelectableSections,
    selectedDropdownSections: optionSelectedSections,
    onDivisionChange,
    onSectionChange,
    unshiftItem,
    deleteIndexItem,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    fetchNextPage,
    onChangeNameKeyword,
    onConditionReset,
    onSearchSubmit,
    setAllBusinessSectionsDistribution,
  };
};
