import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  body: {
    headerImage: File;
  };
};

type ApiResponseType = ResponseMessageType & {
  headerImageUrl: string;
};

const request = ({ body }: RequestDataType) => {
  const formData = new FormData();
  formData.append("profile[header_image]", body.headerImage);

  return new ApiClient()
    .put<ApiResponseType>("/api/members/profile/header_image", formData)
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersProfilesHeaderImageUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ApiResponseType, RequestDataType> => {
  return useMutation(request, { ...options });
};
