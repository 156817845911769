import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import {
  ApiMembersMeetingNoteMutateType,
  RawDraftContentState,
} from "~/domains";

type RequestDataType = {
  body: {
    title: string;
    content: RawDraftContentState;
    postDate: string;
    meetingNoteTemplateId?: string;
    attachFiles?: File[];
    participationEmployeeIds: string[];
  };
};

const request = ({ body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ meetingNote: body });
  return new ApiClient()
    .post<ApiMembersMeetingNoteMutateType>(
      "/api/members/meeting_notes",
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersMeetingNotesCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiMembersMeetingNoteMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
