import React, { FC, useState } from "react";

import { useApisMembersRoleplayingConditionsNew } from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { DatePicker } from "~/components/molecules";
import { ManagersRoleplayingConditionForm } from "~/components/organisms";

export const ManagersRoleplayingConditionsNew: FC = () => {
  const [postDate, setPostDate] = useState(new Date());

  const breadCrumbItems = [
    { label: "ロールプレイング", href: "/managers/roleplaying_conditions" },
    { label: "新規作成", href: "/managers/roleplaying_conditions/new" },
  ];

  const { data: roleplayingConditionFetchData } =
    useApisMembersRoleplayingConditionsNew();

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          <DatePicker
            selectDate={postDate}
            onChange={setPostDate}
            parentClassName="mt-6"
          />
          <ManagersRoleplayingConditionForm
            date={postDate}
            employees={roleplayingConditionFetchData?.employees || []}
            providingServices={
              roleplayingConditionFetchData?.providingServices || []
            }
            className="space-y-6 mt-6"
            roleplayingConditionTemplates={
              roleplayingConditionFetchData?.roleplayingConditionTemplates || []
            }
            skillEvaluationStandardSummaryCategories={[]}
          />
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
