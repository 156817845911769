import React, { ChangeEvent, FC, FormEvent } from "react";

import {
  FormSubmitButton,
  Form,
  PasswordRuleMessage,
} from "~/components/atoms";
import { DisplayablePasswordFiled } from "~/components/molecules";

type PropsType = {
  className?: string;
  isLoading: boolean;
  onChangePassword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangePasswordConfirmation: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const SettingPassword: FC<PropsType> = ({
  onChangePassword,
  onChangePasswordConfirmation,
  onSubmit,
  className,
  isLoading,
}: PropsType) => {
  return (
    <div className={`flex items-center ${className}`}>
      <div className="w-full">
        <h2 className="text-2xl text-center text-gray-600">パスワード変更</h2>
        <PasswordRuleMessage className="mt-4" />
        <Form className="mt-8 rounded-lg mx-auto space-y-8" onSubmit={onSubmit}>
          <DisplayablePasswordFiled
            label="パスワード"
            required
            name="password"
            autoComplete="new-password"
            onChange={onChangePassword}
          />
          <DisplayablePasswordFiled
            label="パスワード(確認用)"
            required
            name="passwordConfirmation"
            autoComplete="new-password"
            onChange={onChangePasswordConfirmation}
          />
          <div className="text-right">
            <FormSubmitButton
              value="保存"
              color="primary"
              isReadOnly={isLoading}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};
