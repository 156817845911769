import React, { FC } from "react";

import { useApisAdminOperatorsOperatorsIndex } from "~/hooks";

import { GridHeaderCells, LinkButton } from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import {
  GridRow,
  InfiniteScrollWithMoreButton,
  OpenedMenuInGrid,
} from "~/components/molecules";

export const AdminOperatorsOperatorsIndex: FC = () => {
  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: "/admin_operators",
    },
    {
      label: "運営者一覧",
      href: "/admin_operators/operators",
    },
  ];

  const { data, fetchNextPage, hasNextPage } =
    useApisAdminOperatorsOperatorsIndex();

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      <div className="flex items-center justify-between">
        <h1 className="text-xl">運営者一覧</h1>
        <LinkButton
          path="/admin_operators/operators/new"
          color="primary"
          text="パートナーアカウントを作成"
        />
      </div>
      <InfiniteScrollWithMoreButton
        itemsLength={data?.pages.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        className="mt-6"
      >
        <div className="grid grid-cols-5">
          <GridHeaderCells
            texts={["名前", "email", "権限", "作成日時", "更新日時"]}
          />
          {data?.pages.map((page) =>
            page.adminOperators.map((adminOperator) => (
              <GridRow
                key={adminOperator.id}
                lists={[
                  adminOperator.name,
                  adminOperator.email,
                  adminOperator.adminOperatorRole.name,
                  adminOperator.createdAt,
                  adminOperator.updatedAt,
                ]}
                openedMenu={
                  <OpenedMenuInGrid
                    cols={5}
                    buttons={[
                      {
                        text: "編集",
                        link: `/admin_operators/operators/${adminOperator.id}/edit`,
                        isHidden:
                          adminOperator.adminOperatorRole.type === "owner",
                      },
                    ]}
                  />
                }
              />
            )),
          )}
        </div>
      </InfiniteScrollWithMoreButton>
    </AdminOperatorLayout>
  );
};
