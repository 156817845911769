import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApisMembersAnalysisCurrentInsightPostPerformanceShowResponseTypes } from "~/domains";

const request =
  async (): Promise<ApisMembersAnalysisCurrentInsightPostPerformanceShowResponseTypes> => {
    const response =
      await new ApiClient().get<ApisMembersAnalysisCurrentInsightPostPerformanceShowResponseTypes>(
        "/api/members/analysis/current_insight_post_performance",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersAnalysisCurrentInsightPostPerformancesShow = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApisMembersAnalysisCurrentInsightPostPerformanceShowResponseTypes> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersAnalysisCurrentInsightPostPerformancesShow"],
    queryFn: () => request(),
  });
};
