import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersKnowledgeInsightPostBookmarkFoldersIndexResponseType } from "~/domains";

const request =
  async (): Promise<ApiMembersKnowledgeInsightPostBookmarkFoldersIndexResponseType> => {
    const response =
      await new ApiClient().get<ApiMembersKnowledgeInsightPostBookmarkFoldersIndexResponseType>(
        "/api/members/knowledge_insight_post_bookmark_folders",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersKnowledgeInsightPostBookmarkFoldersIndex = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiMembersKnowledgeInsightPostBookmarkFoldersIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersKnowledgeInsightPostBookmarkFoldersIndex"],
    queryFn: () => request(),
  });
};
