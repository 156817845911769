import React, { FC } from "react";

import { startOfMonth, endOfMonth } from "date-fns";

import {
  useDateRange,
  usePartnerConsolesMultiDivisionMultiSectionDropdown,
  useApisPartnerConsolesCompaniesAnalyticsCumulativeAnalysisIndex,
  usePartnerConsolesCompaniesDropdown,
} from "~/hooks";

import { PartnerConsoleLayout } from "~/components/layouts";
import { LabelWithDropDownField } from "~/components/molecules";
import { AnalyticsCumulativeAnalysisTemplate } from "~/components/templates";

const today = new Date();

export const PartnerConsolesAnalyticsCumulativeAnalysisIndex: FC = () => {
  const query = new URLSearchParams(location.search);
  const companyId = query.get("company_id") || "";
  const breadCrumbItems = [
    {
      label: "パートナー画面",
      href: "/partner_consoles",
    },
    {
      label: "累計分析",
      href: "/partner_consoles/analytics/cumulative_analysis",
    },
  ];

  const [{ onChangeCompany, dropdownSelectableValue, dropdownValue, company }] =
    usePartnerConsolesCompaniesDropdown({
      defaultCompanyId: companyId,
    });

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
    selectableEmployees,
    selectedEmployee,
    onEmployeeChange,
  } = usePartnerConsolesMultiDivisionMultiSectionDropdown({
    companyId: company?.id || "",
    isSelectEmployee: true,
  });

  const [
    {
      startDate,
      formattedStartDate,
      endDate,
      formattedEndDate,
      dateArray,
      onChange: onChangeDateRange,
    },
  ] = useDateRange([startOfMonth(today), endOfMonth(today)]);

  const { data, isLoading } =
    useApisPartnerConsolesCompaniesAnalyticsCumulativeAnalysisIndex({
      companyId: company?.id || "",
      params: {
        businessDivisionIds: selectedDivisions.map((division) => division.id),
        businessSectionIds: optionSelectedSections.map((section) => section.id),
        fromDate: formattedStartDate || undefined,
        toDate: formattedEndDate || undefined,
        employeeIds: selectedEmployee ? [selectedEmployee.id] : undefined,
      },
      config: {
        enabled: !!(formattedStartDate && formattedEndDate && company),
      },
    });

  return (
    <>
      <PartnerConsoleLayout
        breadCrumbItems={breadCrumbItems}
        companyId={company?.id}
      >
        <LabelWithDropDownField
          required
          labelText="会社"
          name="company"
          options={dropdownSelectableValue}
          value={dropdownValue}
          onChange={onChangeCompany}
        />
        <AnalyticsCumulativeAnalysisTemplate
          formattedStartDate={formattedStartDate}
          formattedEndDate={formattedEndDate}
          selectedDivisions={selectedDivisions}
          optionSelectedSections={optionSelectedSections}
          selectedEmployee={selectedEmployee}
          data={data}
          className="mt-8"
          isLoading={isLoading}
          onChangeDateRange={onChangeDateRange}
          onDivisionChange={onDivisionChange}
          onSectionChange={onSectionChange}
          onEmployeeChange={onEmployeeChange}
          selectableDivisions={selectableDivisions}
          optionSelectableSections={optionSelectableSections}
          selectableEmployees={selectableEmployees}
          startDate={startDate}
          endDate={endDate}
          selectedDropdownSections={optionSelectedSections}
          dateArray={dateArray}
        />
      </PartnerConsoleLayout>
    </>
  );
};
