import React, { FC } from "react";

import { TextField } from "~/components/atoms";

import { KpiTermType } from "~/domains";

type PropsType = {
  className?: string;
  kpiTerm: Pick<KpiTermType, "title" | "fromDate" | "toDate">;
};

export const KpiTermHeader: FC<PropsType> = ({
  className = "",
  kpiTerm,
}: PropsType) => {
  return (
    <div className={`grid miniTablet:grid-cols-2 gap-4 ${className}`}>
      <TextField
        type="text"
        name="kpiTitle"
        disabled
        defaultValue={kpiTerm.title}
        className="text-center"
      />
      <TextField
        type="text"
        name="kpiTermRange"
        disabled
        defaultValue={`${kpiTerm.fromDate} ~ ${kpiTerm.toDate}`}
        className="text-center"
      />
    </div>
  );
};
