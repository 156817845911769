export const TextFieldType = {
  id: 1,
  name: "テキストフィールド",
  type: "text_field",
} as const;

export const TextAreaType = {
  id: 10,
  name: "テキストエリア",
  type: "text_area",
} as const;

export const RadioButtonType = {
  id: 20,
  name: "ラジオボタン",
  type: "radio_button",
} as const;

export const CheckBoxType = {
  id: 30,
  name: "チェックボックス",
  type: "check_box",
} as const;

export const SelectBoxType = {
  id: 40,
  name: "セレクトボックス",
  type: "select_box",
} as const;

export const QuestionTypes = [
  TextFieldType,
  TextAreaType,
  RadioButtonType,
  CheckBoxType,
  SelectBoxType,
] as const;

export type QuestionTypeType =
  | typeof TextFieldType
  | typeof TextAreaType
  | typeof RadioButtonType
  | typeof CheckBoxType
  | typeof SelectBoxType;
