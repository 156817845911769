import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersKpiTermsMutateType } from "~/domains";

type RequestDataType = {
  kpiTermId: string;
};

const request = ({ kpiTermId }: RequestDataType) => {
  return new ApiClient()
    .post<ApiManagersKpiTermsMutateType>(
      `/api/managers/kpi_terms/${kpiTermId}/duplicates`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useManagersKpiTermsDuplicatesCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersKpiTermsMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
