import { useState, useCallback } from "react";

import { eachDayOfInterval, eachMonthOfInterval } from "date-fns";

import { toDateString } from "~/libs/";

import { RangeDatePropsType } from "~/components/molecules";

type ReturnType = [
  {
    startDate: Date | null;
    endDate: Date | null;
    dateRange: RangeDatePropsType;
    formattedStartDate: string | null;
    formattedEndDate: string | null;
    dateArray: Date[];
    monthArray: Date[];
    onChange: (value: RangeDatePropsType) => void;
  },
  (value: RangeDatePropsType) => void,
];

export const useDateRange = (
  initial: RangeDatePropsType = null,
): ReturnType => {
  const [dateRange, setDateRange] = useState<RangeDatePropsType>(initial);
  const [startDate, endDate] = Array.isArray(dateRange)
    ? [dateRange[0], dateRange[1]]
    : [null, null];

  const onChange = useCallback(
    (value: RangeDatePropsType) => setDateRange(value),
    [],
  );

  const getDateRangeArray = () => {
    if (!startDate || !endDate) return [];
    return eachDayOfInterval({ start: startDate, end: endDate });
  };

  const getMonthArray = () => {
    if (!startDate || !endDate) return [];
    return eachMonthOfInterval({ start: startDate, end: endDate });
  };

  return [
    {
      startDate: startDate,
      endDate: endDate,
      formattedStartDate: startDate ? toDateString(startDate) : null,
      formattedEndDate: endDate ? toDateString(endDate) : null,
      dateRange,
      dateArray: getDateRangeArray(),
      monthArray: getMonthArray(),
      onChange,
    },
    useCallback((value) => setDateRange(value), []),
  ];
};
