import React, { FC, MouseEvent } from "react";

import { ButtonWithIcon, Icon } from "~/components/atoms";

import { AiChatRoomType } from "~/domains";

type PropsType = {
  aiChatRoom: AiChatRoomType;
  setAiChatRoomId: (chatRoomId: string) => void;
  setEditMode: () => void;
  handleDelete: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const FloatingChatRoomTitle: FC<PropsType> = ({
  aiChatRoom,
  setAiChatRoomId,
  setEditMode,
  handleDelete,
}: PropsType) => {
  const handleSetAiChatRoomId = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAiChatRoomId(aiChatRoom.id);
  };

  const handleEditMode = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setEditMode();
  };

  return (
    <>
      <button
        type="button"
        className="flex items-center space-x-4 truncate w-full"
        onClick={handleSetAiChatRoomId}
      >
        <Icon icon="vscSymbolConstant" size="1.5rem" className="shrink-0" />
        <p className="truncate text-secondary-600">
          {aiChatRoom.title || aiChatRoom.createdAt}
        </p>
      </button>
      <div className="flex items-center space-x-2 ml-4">
        <ButtonWithIcon
          onClick={handleEditMode}
          icon={{
            icon: "ioCreateOutline",
            size: "1.25rem",
            color: "text-secondary-600",
          }}
          srOnlyText="ChatRoomを編集する"
        />
        <ButtonWithIcon
          onClick={handleDelete}
          icon={{
            icon: "ioTrashOutline",
            size: "1.25rem",
            color: "text-secondary-600",
          }}
          srOnlyText="ChatRoomを削除する"
        />
      </div>
    </>
  );
};
