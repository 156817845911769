import React, { FC, MouseEvent } from "react";

import { Icon, IconColorType, ModalWrapper } from "~/components/atoms";
import type { IconSizeType } from "~/components/atoms";
import { PostDetailMenu } from "~/components/molecules";

import { PostDetailMenuItemType } from "~/domains";

type PropsType = {
  menuItems: PostDetailMenuItemType[];
  iconSize?: IconSizeType;
  iconColor?: IconColorType;
  className?: string;
  modal: {
    toggle: () => void;
    close: () => void;
    opened: boolean;
  };
};

export const FloatingMenu: FC<PropsType> = ({
  menuItems,
  iconSize = "1.5rem",
  iconColor = "text-secondary-600",
  modal,
  className = "",
}: PropsType) => {
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div className={`relative ${className}`} onClick={onClick}>
      <span className="cursor-pointer" onClick={modal.toggle}>
        <Icon
          icon="ioEllipsisHorizontalSharp"
          size={iconSize}
          color={iconColor}
        />
      </span>
      {modal.opened && (
        <>
          <ModalWrapper isWhite onClose={modal.close} />
          <PostDetailMenu items={menuItems} />
        </>
      )}
    </div>
  );
};
