import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersKaizenIdeasMutateType } from "~/domains";

type RequestDataType = {
  kaizenIdeaId: string;
};

const request = ({ kaizenIdeaId }: RequestDataType) => {
  return new ApiClient()
    .put<ApiMembersKaizenIdeasMutateType>(
      `/api/managers/kaizen_ideas/${kaizenIdeaId}/complete`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersKaizenIdeasCompletesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiMembersKaizenIdeasMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
