import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisMembersKpiBusinessSectionsPostCalendarIndexRequestTypes,
  ApisMembersKpiBusinessSectionsPostCalendarIndexResponseTypes,
} from "~/domains";

type RequestDataType = {
  businessSectionId: string;
  params: ApisMembersKpiBusinessSectionsPostCalendarIndexRequestTypes;
};

const request = async ({
  businessSectionId,
  params,
}: RequestDataType): Promise<ApisMembersKpiBusinessSectionsPostCalendarIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisMembersKpiBusinessSectionsPostCalendarIndexResponseTypes>(
      `/api/members/kpi/business_sections/${businessSectionId}/post_calendars`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  businessSectionId: string;
  params: ApisMembersKpiBusinessSectionsPostCalendarIndexRequestTypes;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersKpiBusinessSectionsPostCalendarIndex = ({
  businessSectionId,
  params,
  config = {},
}: PropsType): UseQueryResult<ApisMembersKpiBusinessSectionsPostCalendarIndexResponseTypes> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisMembersKpiBusinessSectionsPostCalendarIndex",
      businessSectionId,
      params,
    ],
    queryFn: () => request({ businessSectionId, params }),
  });
};
