import React, { FC, useEffect } from "react";

import {
  useApisMembersMentionNotificationsShow,
  useApisMembersMentionNotificationsUpdate,
} from "~/hooks";

import { NotificationDetailList } from "~/components/organisms";

export const MentionNotificationSidebar: FC = () => {
  const { data, fetchNextPage, hasNextPage, isLoading } =
    useApisMembersMentionNotificationsShow();
  const { mutate } = useApisMembersMentionNotificationsUpdate();

  useEffect(() => {
    return mutate(undefined);
  }, [mutate]);

  return (
    <NotificationDetailList
      items={data?.pages.flatMap((page) => page.notifications) || []}
      nextFetchFunction={fetchNextPage}
      hasMore={hasNextPage}
      isLoading={isLoading}
      noContentMessage="メンション通知はありません"
    />
  );
};
