import React, { FC, MouseEvent } from "react";

import { FloatingChatBoxHeader } from "../FloatingChatBoxHeader";

type PropsType = {
  onClose: (e: MouseEvent<HTMLButtonElement>) => void;
  onDisplayChatRooms: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const FloatingChatInitialFormHeadMessage: FC<PropsType> = ({
  onClose,
  onDisplayChatRooms,
}: PropsType) => {
  return (
    <>
      <FloatingChatBoxHeader onClose={onClose} />
      <span
        className="block text-2xl font-semibold"
        role="img"
        aria-label="手を振って笑顔の顔"
      >
        こんにちは👋😄
      </span>
      <p className="text-secondary-600">
        以下より条件を自由に設定してください。フリーワードだけでの条件設定でも大丈夫です。
        営業のノウハウや心構え、商談準備の調査だけでなく、ちょっとした会話、プライベートでの情報収集、なんでも気軽に相談してください。
      </p>
      <button
        type="button"
        onClick={onDisplayChatRooms}
        className="text-secondary-600 underline"
      >
        過去の相談一覧
      </button>
    </>
  );
};
