import { PostFooterType } from "~/domains";

type ReturnType = {
  footers: PostFooterType[];
};

type PropsType = {
  like?: {
    doneAlready: boolean;
    undoOnClick: () => void;
    doOnClick: () => void;
    textOnClick: () => void;
    count: number;
    disable?: boolean;
  };
  comment?: {
    doneAlready: boolean;
    undoOnClick: () => void;
    doOnClick: () => void;
    count: number;
    disable?: boolean;
  };
  bookmark?: {
    doneAlready: boolean;
    undoOnClick: () => void;
    doOnClick: () => void;
    count?: number;
    disable?: boolean;
  };
  shareable?: {
    doneAlready: boolean;
    undoOnClick: () => void;
    doOnClick: () => void;
    count?: number;
    disable?: boolean;
  };
  bookmarkFolder?: {
    doneAlready: boolean;
    undoOnClick: () => void;
    doOnClick: () => void;
    count?: number;
    disable?: boolean;
  };
};

export const useFooters = ({
  like,
  comment,
  bookmark,
  shareable,
  bookmarkFolder,
}: PropsType): ReturnType => {
  const commentIcon = comment?.count
    ? {
        icon: "ioChatbubbleEllipses",
        color: "text-icon-reply",
        size: "1.25rem",
      }
    : {
        icon: "ioChatbubbleEllipsesOutline",
        color: "text-secondary-600",
        className: "group-hover:text-icon-reply",
        size: "1.25rem",
      };
  const likeFooter = like && {
    doneAlready: like.doneAlready,
    undoIcon: {
      icon: "ioHeart",
      color: "text-icon-heart",
      size: "1.25rem",
    },
    buttonClassName: "hover:bg-icon-hover-heart",
    undoOnClick: like.undoOnClick,
    doIcon: {
      icon: "ioHeartOutline",
      color: "text-secondary-600",
      className: "group-hover:text-icon-heart",
      size: "1.25rem",
    },
    doOnClick: like.doOnClick,
    textOnClick: like.textOnClick,
    count: `${like.count}件`,
    disable: like.disable,
  };

  const commentFooter = comment && {
    doneAlready: comment.doneAlready,
    undoIcon: commentIcon,
    buttonClassName: "hover:bg-icon-hover-reply",
    undoOnClick: comment.doOnClick,
    doIcon: commentIcon,
    doOnClick: comment.undoOnClick,
    count: comment.count,
    disable: comment.disable,
  };

  const bookmarkFooter = bookmark && {
    doneAlready: bookmark.doneAlready,
    undoIcon: {
      icon: "ioBookmark",
      color: "text-icon-bookmark",
      size: "1.25rem",
    },
    buttonClassName: "hover:bg-icon-hover-bookmark",
    undoOnClick: bookmark.undoOnClick,
    doIcon: {
      icon: "ioBookmarkOutline",
      color: "text-secondary-600",
      className: "group-hover:text-icon-bookmark",
      size: "1.25rem",
    },
    doOnClick: bookmark.doOnClick,
    count: bookmark.count,
    disable: bookmark.disable,
  };

  const shareableFooter = shareable && {
    doneAlready: shareable.doneAlready,
    undoIcon: {
      icon: "ioShareSocial",
      color: "text-icon-share",
      size: "1.25rem",
    },
    undoOnClick: shareable.doOnClick,
    buttonClassName: "hover:bg-icon-hover-share",
    doIcon: {
      icon: "ioShareSocialOutline",
      color: "text-secondary-600",
      className: "group-hover:text-icon-share",
      size: "1.25rem",
    },
    doOnClick: shareable.undoOnClick,
    count: shareable.count,
    disable: shareable.disable,
  };

  const bookmarkFolderFooter = bookmarkFolder && {
    doneAlready: bookmarkFolder.doneAlready,
    undoIcon: {
      icon: "ioFolderOpenOutline",
      color: "text-secondary-600",
      size: "1.25rem",
    },
    undoOnClick: bookmarkFolder.doOnClick,
    doIcon: {
      icon: "ioFolderOpenOutline",
      color: "text-secondary-600",
      size: "1.25rem",
    },
    doOnClick: bookmarkFolder.undoOnClick,
    count: bookmarkFolder.count,
    disable: bookmarkFolder.disable,
  };

  const footers = [
    likeFooter,
    commentFooter,
    bookmarkFooter,
    shareableFooter,
    bookmarkFolderFooter,
  ]
    .filter(Boolean)
    .filter((footer) => !footer?.disable) as PostFooterType[];

  return {
    footers: footers,
  };
};
