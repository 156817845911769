import React, { FC, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useMultipleDropdown,
  useProvidersCurrentEmployee,
  useApisManagersProvidingServicesEdit,
  useApisManagersProvidingServicesUpdate,
  useBoolean,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { ProvidingServiceForm } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

import { OwnerRole } from "~/domains";

export const ManagersProvidingServicesEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { currentEmployee } = useProvidersCurrentEmployee();

  const { isError } = useApisManagersProvidingServicesEdit({
    id,
    config: {
      onSuccess: (data) => {
        setProvidingServiceName(data.providingService.name);
        setDropdownBusinessSEctions(data.providingService.businessSections);
        handleChangeAllBusinessSectionsDistribution(
          data.providingService.allBusinessSectionsDistribution,
        );
      },
    },
  });

  const { mutate: updateRequest, isLoading: isSubmitting } =
    useApisManagersProvidingServicesUpdate();

  const [dropdownBusinessSections, setDropdownBusinessSEctions] =
    useMultipleDropdown({
      selectableValue: currentEmployee?.businessSections || [],
    });
  const [providingServiceName, setProvidingServiceName] = useInput("");
  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: onChangeAllBusinessSectionsDistribution,
    handleChange: handleChangeAllBusinessSectionsDistribution,
  } = useBoolean(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!dropdownBusinessSections.value) {
      toast("課が選択されていません");
      return;
    }
    const body = {
      name: providingServiceName.value,
      allBusinessSectionsDistribution,
      businessSectionIds: dropdownBusinessSections.value?.map((val) => val.id),
    };
    updateRequest(
      {
        id,
        body,
      },
      {
        onSuccess: (data) => {
          navigate("/managers/providing_services");
          toast(data.message);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "サービス設定", href: "/managers/providing_services" },
    { label: "編集", href: `/managers/providing_services/${id}/edit` },
  ];

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <ProvidingServiceForm
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          providingServiceName={providingServiceName.value}
          onChangeProvidingServiceName={providingServiceName.onChange}
          selectedBusinessSections={dropdownBusinessSections.dropdownValue}
          selectableBusinessSections={
            dropdownBusinessSections.dropdownSelectableValue
          }
          onChangeBusinessSections={dropdownBusinessSections.onChange}
          allBusinessSectionsDistribution={allBusinessSectionsDistribution}
          onChangeAllBusinessSectionsDistribution={
            onChangeAllBusinessSectionsDistribution
          }
          isOwner={currentEmployee?.employeeRole.id === OwnerRole.id}
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
