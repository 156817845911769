import React, { FC } from "react";

export const UnreadMark: FC = () => {
  return (
    <div
      className={
        "w-2.5 h-2.5 rounded-full bg-icon-heart absolute -top-0.5 -right-1.5"
      }
    />
  );
};
