import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { SignUpResponseType } from "~/domains";

type RequestDataType = {
  id: string;
  body: {
    password: string;
    passwordConfirmation: string;
    token: string;
  };
};

const request = ({ id, body }: RequestDataType) => {
  return new ApiClient()
    .put<SignUpResponseType>(`/api/employee/invites/${id}`, {
      ...body,
    })
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisEmployeeInvitesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<SignUpResponseType, RequestDataType> => {
  return useMutation(request, { ...options });
};
