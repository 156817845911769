import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisAnalyticsPlansEmployeesPostsCalendarsIndexRequestTypes,
  ApisAnalyticsPlansEmployeesPostsCalendarsIndexResponseTypes,
} from "~/domains";

type RequestDataType = {
  employeeId: string;
  params: ApisAnalyticsPlansEmployeesPostsCalendarsIndexRequestTypes;
};

const request = async ({
  employeeId,
  params,
}: RequestDataType): Promise<ApisAnalyticsPlansEmployeesPostsCalendarsIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisAnalyticsPlansEmployeesPostsCalendarsIndexResponseTypes>(
      `/api/analytics_plans/employees/${employeeId}/posts_calendars`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params: ApisAnalyticsPlansEmployeesPostsCalendarsIndexRequestTypes;
  employeeId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisAnalyticsPlansEmployeesPostsCalendarsIndex = ({
  params,
  employeeId,
  config = {},
}: PropsType): UseQueryResult<ApisAnalyticsPlansEmployeesPostsCalendarsIndexResponseTypes> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisAnalyticsPlansEmployeesPostsCalendarsIndex",
      employeeId,
      params,
    ],
    queryFn: () => request({ employeeId, params }),
  });
};
