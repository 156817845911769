import React, { FC } from "react";

type PropsType = {
  tabTexts: string[];
  onClick: (index: number) => void;
  selectedIndex: number;
};

export const TabList: FC<PropsType> = ({
  tabTexts,
  onClick,
  selectedIndex,
}: PropsType) => {
  return (
    <ul className="flex items-center justify-center w-full text-center">
      {tabTexts.map((text, index) => (
        <li
          key={text}
          className={`border-b-4 w-1/2 ${
            index === selectedIndex
              ? "text-primary-600 border-primary-600 font-bold"
              : "text-secondary-600 border-secondary-300"
          }`}
        >
          <button
            type="button"
            className="py-2 px-4 w-full"
            onClick={() => onClick(index)}
          >
            {text}
          </button>
        </li>
      ))}
    </ul>
  );
};
