import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiPartnerConsolesCompaniesAnalyticsKnowledgeInsightPostsIndexResponseType,
  ApiPartnerConsolesCompaniesAnalyticsKnowledgeInsightPostsIndexRequestType,
} from "~/domains";

type RequestDataType = {
  companyId: string;
  params?: ApiPartnerConsolesCompaniesAnalyticsKnowledgeInsightPostsIndexRequestType;
};

const request = async ({
  params,
  companyId,
}: RequestDataType): Promise<ApiPartnerConsolesCompaniesAnalyticsKnowledgeInsightPostsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiPartnerConsolesCompaniesAnalyticsKnowledgeInsightPostsIndexResponseType>(
      `/api/partner_consoles/companies/${companyId}/analytics/knowledge_insight_posts`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiPartnerConsolesCompaniesAnalyticsKnowledgeInsightPostsIndexRequestType;
  companyId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisPartnerConsolesCompaniesAnalyticsKnowledgeInsightPostsIndex =
  ({
    params,
    companyId,
    config = {},
  }: PropsType): UseQueryResult<ApiPartnerConsolesCompaniesAnalyticsKnowledgeInsightPostsIndexResponseType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisPartnerConsolesCompaniesAnalyticsKnowledgeInsightPostsIndex",
        companyId,
        params,
      ],
      queryFn: () => request({ companyId, params }),
    });
  };
