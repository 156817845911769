import { MouseEvent, ChangeEvent, FormEvent, useState } from "react";

import {
  useInput,
  useApisManagersMeetingNoteTemplatesIndex,
  useArray,
  useDropdown,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  MeetingNoteTemplateType,
  OptionType,
  ApiManagersMeetingNoteTemplateIndexRequestType,
  AllPublicStatus,
  BusinessDivisionType,
  ChildType,
  ParentType,
  SingleValueType,
} from "~/domains";

type ReturnType = {
  meetingNoteTemplates: MeetingNoteTemplateType[];
  titleKeyword: string;
  hasNextPage?: boolean;
  isLoading: boolean;
  allBusinessSectionsDistribution?: boolean;
  selectableDropdownPublicStatusIds: OptionType[];
  selectedDropdownPublicStatusId?: SingleValueType<OptionType>;
  selectableDropdownDivisions: BusinessDivisionType[];
  selectedDropdownDivisions: BusinessDivisionType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  deleteIndexItem: (index: number) => void;
  findAndRemove: (func: (item: MeetingNoteTemplateType) => boolean) => void;
  unshiftItem: (item: MeetingNoteTemplateType) => void;
  onChangeDropdownPublicStatusId: (value: SingleValueType<OptionType>) => void;
  onChangeTitleKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  setAllBusinessSectionsDistribution: (value: boolean | undefined) => void;
};

export const useSearchManagersMeetingNoteTemplates = (): ReturnType => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [
    { value: titleKeyword, onChange: onChangeTitleKeyword },
    setTitleKeyword,
  ] = useInput("");
  const [allBusinessSectionsDistribution, setAllBusinessSectionsDistribution] =
    useState<boolean | undefined>();
  const [
    {
      dropdownValue: selectedDropdownPublicStatusId,
      onChange: onChangeDropdownPublicStatusId,
      dropdownSelectableValue: selectableDropdownPublicStatusIds,
    },
    setDropdownPublicStatus,
  ] = useDropdown({
    selectableValue: AllPublicStatus.map((status) => ({
      name: status.name,
      id: status.id.toString(),
    })),
  });
  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });
  const [params, setParams] =
    useState<ApiManagersMeetingNoteTemplateIndexRequestType>();

  const { items, setItems, deleteIndexItem, findAndRemove, unshiftItem } =
    useArray<MeetingNoteTemplateType>([]);

  const { fetchNextPage, hasNextPage, isFetching } =
    useApisManagersMeetingNoteTemplatesIndex({
      params,
      config: {
        onSuccess: (data) => {
          setItems(data.pages.map((page) => page.meetingNoteTemplates).flat());
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      titleKeyword,
      businessDivisionIds: selectedDivisions.map((division) => division.id),
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      publicStatusId: selectedDropdownPublicStatusId?.value,
      allBusinessSectionsDistribution,
    });
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setTitleKeyword("");
    setDropdownPublicStatus();
    onDivisionChange([]);
    onSectionChange([]);
    setParams({});
    setAllBusinessSectionsDistribution(undefined);
  };

  return {
    meetingNoteTemplates: items,
    titleKeyword,
    isLoading: isFetching,
    hasNextPage,
    allBusinessSectionsDistribution,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownDivisions: selectableDivisions,
    selectedDropdownDivisions: selectedDivisions,
    selectableDropdownSections: optionSelectableSections,
    selectedDropdownSections: optionSelectedSections,
    onDivisionChange,
    onSectionChange,
    unshiftItem,
    deleteIndexItem,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    fetchNextPage,
    onChangeTitleKeyword,
    onConditionReset,
    onSearchSubmit,
    setAllBusinessSectionsDistribution,
  };
};
