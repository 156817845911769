import React, { useState, FC } from "react";

import { useRoleplayingConditionSkillEvaluationStandardCheck } from "~/hooks";

import { Button, LinkButton, RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { DatePicker, SlideNavigationType } from "~/components/molecules";
import {
  RoleplayingConditionForm,
  ArchivedSkillEvaluationStandardSummaryCategoryDetail,
  RoleplayingConditionCurrentEmployeeDailyCountCalendar,
} from "~/components/organisms";

import { ApiMembersRoleplayingConditionsEditType } from "~/domains";

type PropsType = {
  fetchData: ApiMembersRoleplayingConditionsEditType;
  navigations: SlideNavigationType[];
};

export const RoleplayingConditionsEditTemplate: FC<PropsType> = ({
  fetchData,
  navigations,
}: PropsType) => {
  const {
    roleplayingCondition,
    roleplayingConditionTemplates,
    skillEvaluationStandardSummaryCategories,
    providingServices,
    archivedSkillEvaluationStandards,
    archivedSkillEvaluationStandardSummaryCategory:
      fetchArchivedSkillEvaluationStandardSummaryCategory,
  } = fetchData;
  const [postDate, setPostDate] = useState(
    new Date(roleplayingCondition.postDate),
  );

  const {
    isReadOnly,
    selectSkillEvaluationStandardIds,
    skillEvaluationStandardSummaryCategory,
    handleNotReadonly,
    handleSkillEvaluationStandardSummaryCategory,
    selectSkillEvaluationStandard,
    handleSave,
  } = useRoleplayingConditionSkillEvaluationStandardCheck({
    roleplayingCondition,
    defaultArchivedSkillEvaluationStandardSummaryCategory:
      fetchArchivedSkillEvaluationStandardSummaryCategory,
    defaultArchivedSkillEvaluationStandards: archivedSkillEvaluationStandards,
  });

  return (
    <>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <DatePicker
          selectDate={postDate}
          onChange={setPostDate}
          parentClassName="mt-6"
        />
        <RoleplayingConditionForm
          roleplayingCondition={roleplayingCondition}
          providingServices={
            [
              {
                id: "",
                name: "すべて",
              },
              ...providingServices,
            ] || []
          }
          employees={fetchData.employees || []}
          date={postDate}
          className="space-y-6 mt-6"
          roleplayingConditionTemplates={roleplayingConditionTemplates || []}
          skillEvaluationStandardSummaryCategories={
            skillEvaluationStandardSummaryCategories || []
          }
          skillCategory={skillEvaluationStandardSummaryCategory}
          setSkillCategory={handleSkillEvaluationStandardSummaryCategory}
        />
        <div className="mt-9">
          <LinkButton
            outline
            path="/roleplaying_conditions/new"
            text="新たな条件で入力"
            color="primary"
            className="w-full block text-center"
          />
        </div>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarTitle title="Information" />
        <RoleplayingConditionCurrentEmployeeDailyCountCalendar className="mt-6 mx-4" />
        {skillEvaluationStandardSummaryCategory && (
          <div className="mx-4">
            <ArchivedSkillEvaluationStandardSummaryCategoryDetail
              archivedSkillEvaluationStandardSummaryCategory={
                skillEvaluationStandardSummaryCategory
              }
              className="mt-9"
              memberSelectedSkillEvaluationStandardIds={
                selectSkillEvaluationStandardIds
              }
              selectSkillEvaluationStandard={
                isReadOnly ? undefined : selectSkillEvaluationStandard
              }
            />
            <div className="mt-8">
              {isReadOnly ? (
                <Button
                  text="編集"
                  color="gray"
                  outline
                  className="w-full"
                  onClick={handleNotReadonly}
                />
              ) : (
                <Button
                  text="保存"
                  color="primary"
                  className="w-full"
                  onClick={handleSave}
                />
              )}
            </div>
          </div>
        )}
      </EmployeeLayoutRightSidebar>
    </>
  );
};
