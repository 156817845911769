import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersBusinessSectionsBusinessSectionCompetencyReviewsMutateResponseType,
  ApiManagersBusinessSectionsBusinessSectionCompetencyReviewsMutateRequestType,
} from "~/domains";

type RequestDataType = {
  businessSectionId: string;
  body: ApiManagersBusinessSectionsBusinessSectionCompetencyReviewsMutateRequestType;
};

const request = ({ businessSectionId, body }: RequestDataType) => {
  return new ApiClient()
    .post<ApiManagersBusinessSectionsBusinessSectionCompetencyReviewsMutateResponseType>(
      `/api/managers/business_sections/${businessSectionId}/business_section_competency_reviews`,
      {
        businessSectionCompetencyReview: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersBusinessSectionsBusinessSectionCompetencyReviewsCreate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ApiManagersBusinessSectionsBusinessSectionCompetencyReviewsMutateResponseType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
