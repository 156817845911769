import React, { ReactNode, FC } from "react";

type PropsType = {
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
};

export const GridCell: FC<PropsType> = ({
  onClick,
  className = "",
  children,
}: PropsType) => {
  return (
    <div
      onClick={onClick}
      className={`border-b border-solid px-3 py-2 ${className}`}
    >
      {children}
    </div>
  );
};
