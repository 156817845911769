import React, { FC } from "react";

type PropsType = {
  htmlFor: string;
  labelText: string;
  required: boolean;
  className?: string;
};

export const Label: FC<PropsType> = ({
  htmlFor,
  labelText,
  required,
  className,
}: PropsType) => {
  return (
    <label
      htmlFor={htmlFor}
      className={`text-secondary-600 ${
        required && "after:content-['*'] after:ml-0.5 after:text-red-500"
      } ${className}`}
    >
      {labelText}
    </label>
  );
};
