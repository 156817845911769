import React, { FC } from "react";

type PropsType = {
  title: string;
  className?: string;
};

export const RightSidebarTitle: FC<PropsType> = ({ title, className = "" }) => {
  return (
    <h2 className={`text-center bg-secondary-300 py-3.5 px-2 ${className}`}>
      {title}
    </h2>
  );
};
