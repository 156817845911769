import React, { FC } from "react";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { RoleplayingConditionTemplateForm } from "~/components/organisms";

export const ManagersRoleplayingConditionTemplatesNew: FC = () => {
  const breadCrumbItems = [
    {
      label: "ロールプレイングテンプレ",
      href: "/managers/roleplaying_condition_templates",
    },
    {
      label: "新規作成",
      href: "/managers/roleplaying_condition_templates/new",
    },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <RoleplayingConditionTemplateForm />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
