import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  employeeSurveySubmissionId: string;
  id: string;
};

const request = ({ employeeSurveySubmissionId, id }: RequestDataType) => {
  return new ApiClient()
    .delete<ResponseMessageType>(
      `/api/members/employee_survey_submissions/${employeeSurveySubmissionId}/employee_survey_submission_replies/${id}`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersEmployeeSurveySubmissionsEmployeeSurveySubmissionRepliesDestroy =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ResponseMessageType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
