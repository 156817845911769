import React, { FC, FormEvent } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { setAuthenticationUidCookies } from "~/utils";

import {
  useApisMembersProfilesNewEmailsUpdate,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { Form, FormSubmitButton } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";

export const MembersSettingEmailEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token") || "";
  const { refetch } = useProvidersCurrentEmployee();
  const { mutate, isLoading } = useApisMembersProfilesNewEmailsUpdate();

  const breadCrumbItems = [
    { label: "マイページ", href: "/profile" },
    { label: "編集", href: "/setting/profile" },
    {
      label: "メールアドレス編集",
      href: `/setting/email/${id}/edit?token=${token}`,
    },
  ];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    mutate(
      {
        id,
        body: {
          token,
        },
      },
      {
        onSuccess: (data) => {
          setAuthenticationUidCookies(data.email);
          refetch();
          toast(data.message);
          navigate("/profile");
        },
      },
    );
  };

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <p className="text-secondary-600">
          新しいアドレスの認証の認証はまだ完了していません。
        </p>
        <p className="text-secondary-600">
          以下のボタンから、新しいアドレスの認証を完了してください。
        </p>
        <Form onSubmit={handleSubmit} className="mt-4">
          <FormSubmitButton
            color="primary"
            value="新しいアドレスの認証を完了する"
            className="w-full"
            isReadOnly={isLoading}
          />
        </Form>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
