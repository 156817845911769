import React, { useState, FC } from "react";

import {
  useSearchMembersSharedInsightPosts,
  useRightSidebarState,
  useApisMembersInsightPostsNew,
  useApisMembersInsightPostsInsightPostCommentsIndex,
  useInterval,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  RightSidebarToggleIconWithLabel,
  InfiniteScroll,
  FilterIconWithSortMenu,
} from "~/components/molecules";
import {
  InsightPostSearchForm,
  InsightPostCommentsWithInsightPost,
  InsightPostDetailWithEditForm,
} from "~/components/organisms";

import { EmployeeRole } from "~/domains";

export const MembersKnowledgeInsightPostsFromTeamsIndex: FC = () => {
  const { data: fetchNewData } = useApisMembersInsightPostsNew();
  const [selectGetCommentInsightPostId, setSelectGetCommentInsightPostId] =
    useState<string>("");
  const { data: insightPostCommentsIndexData, refetch: commentsRefetch } =
    useApisMembersInsightPostsInsightPostCommentsIndex({
      insightPostId: selectGetCommentInsightPostId,
      config: {
        enabled: Boolean(selectGetCommentInsightPostId),
      },
    });

  const {
    isLoading,
    insightPosts,
    keyword,
    hasNextPage,
    startDate,
    endDate,
    sort,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    selectableDropdownHasCommentIds,
    selectedDropdownHasCommentId,
    selectableJobCategories,
    selectedJobCategories,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    optionSelectableJobCategoryPhases,
    optionSelectedJobCategoryPhases,
    selectedProvidingServices,
    selectableProvidingServices,
    fetchNextPage,
    setSort,
    updateIndexInsightPost,
    deleteIndexInsightPost,
    onChangeDropdownHasBookmarkId,
    onChangeDropdownHasCommentId,
    onChangeDropdownEmployees,
    onChangeProvidingServices,
    onChangeJobCategoryPhases,
    onChangeJobCategories,
    onChangeDateRange,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  } = useSearchMembersSharedInsightPosts();
  const { data: insightNewData } = useApisMembersInsightPostsNew();
  const {
    isSearchOpen,
    isReplyOpen,
    searchOpenToggle,
    setReplyOpen,
    setClose,
    isOpen,
  } = useRightSidebarState("isClose");

  const breadCrumbItems = [
    {
      label: "ナレッジシェア",
      href: `/knowledge_post/${EmployeeRole.MEMBER.type}`,
    },
  ];

  const openComment = (insightPostId: string) => {
    setSelectGetCommentInsightPostId(insightPostId);
    setReplyOpen();
  };

  useInterval({
    onUpdate: async () => {
      isReplyOpen && selectGetCommentInsightPostId && (await commentsRefetch());
    },
    intervalMs: 1000,
  });

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
        <FilterIconWithSortMenu
          likeSort={() => setSort("likeCountDesc")}
          bookmarkSort={() => setSort("bookmarkCountDesc")}
          createdAtAscSort={() => setSort("createdAtAsc")}
          createdAtDescSort={() => setSort("createdAtDesc")}
          currentSortType={sort}
          className="mt-6"
        />
        <InfiniteScroll
          itemsLength={insightPosts.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isLoading}
          className="border-t-2 border-secondary-200"
        >
          {insightPosts.map((post, index) => (
            <InsightPostDetailWithEditForm
              key={post.id}
              insightPost={post}
              openComment={openComment}
              mentions={insightNewData?.mentionableEmployees || []}
              selectableProvidingServices={
                insightNewData?.selectableProvidingServices || []
              }
              selectableJobCategoryPhases={
                insightNewData?.selectableJobCategoryPhases || []
              }
              updateItem={(newItem) => updateIndexInsightPost(newItem, index)}
              deleteItem={() => {
                deleteIndexInsightPost(index);
                if (selectGetCommentInsightPostId === post.id) {
                  setSelectGetCommentInsightPostId("");
                  setClose();
                }
              }}
            />
          ))}
        </InfiniteScroll>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={setClose}
          label="検索条件"
          isOpen={isOpen}
        >
          {isSearchOpen && (
            <InsightPostSearchForm
              onSubmit={onSearchSubmit}
              onConditionReset={onConditionReset}
              date={{
                startDate: startDate,
                endDate: endDate,
                onChange: onChangeDateRange,
              }}
              employee={{
                options: selectableDropdownEmployees,
                value: selectedDropdownEmployees,
                onChange: onChangeDropdownEmployees,
              }}
              searchKeyword={{
                value: keyword,
                onChange: onChangeKeyword,
              }}
              providingService={{
                options: selectableProvidingServices,
                value: selectedProvidingServices,
                onChange: onChangeProvidingServices,
              }}
              jobCategoryAndJobCategoryPhase={{
                selectableJobCategories: selectableJobCategories,
                selectedJobCategories: selectedJobCategories,
                selectableJobCategoryPhases: optionSelectableJobCategoryPhases,
                selectedJobCategoryPhases: optionSelectedJobCategoryPhases,
                onJobCategoryChange: onChangeJobCategories,
                onJobCategoryPhaseChange: onChangeJobCategoryPhases,
              }}
              hasComment={{
                options: selectableDropdownHasCommentIds,
                value: selectedDropdownHasCommentId,
                onChange: onChangeDropdownHasCommentId,
              }}
              hasBookmark={{
                options: selectableDropdownHasBookmarkIds,
                value: selectedDropdownHasBookmarkId,
                onChange: onChangeDropdownHasBookmarkId,
              }}
            />
          )}
          {isReplyOpen && insightPostCommentsIndexData && (
            <InsightPostCommentsWithInsightPost
              className="mt-6"
              insightPost={insightPostCommentsIndexData.insightPost}
              mentions={fetchNewData?.mentionableEmployees || []}
              insightPostComments={
                insightPostCommentsIndexData.insightPostComments || []
              }
              refetch={commentsRefetch}
            />
          )}
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
