import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersRoleplayingEvaluationMutateType } from "~/domains";

type RequestDataType = {
  roleplayingConditionId: string;
  id: string;
  body: {
    myEvaluationGoodContent: string;
    myEvaluationImproveContent: string;
    otherUserEvaluationGoodContent: string;
    otherUserEvaluationImproveContent: string;
  };
};

const request = ({ id, roleplayingConditionId, body }: RequestDataType) => {
  return new ApiClient()
    .put<ApiManagersRoleplayingEvaluationMutateType>(
      `/api/managers/roleplaying_conditions/${roleplayingConditionId}/roleplaying_evaluations/${id}`,
      {
        roleplayingEvaluation: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersRoleplayingConditionsRoleplayingEvaluationsUpdate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ApiManagersRoleplayingEvaluationMutateType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
