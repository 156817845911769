import React, { FC } from "react";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { RequiredHiddenField } from "~/components/atoms";

import { OptionType, SingleValueType, MultiValueType } from "~/domains";

type PropsType = {
  name: string;
  options: MultiValueType<OptionType>;
  value?: SingleValueType<OptionType>;
  isDisabled?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  className?: string;
  creatable?: boolean;
  handleCreate?: (newValue: string) => void;
  onChange?: (newValue: SingleValueType<OptionType>) => void;
  required?: boolean;
  parentClassName?: string;
  isClearable?: boolean;
};

export const DropDownField: FC<PropsType> = ({
  name,
  options,
  value = null,
  isDisabled,
  placeholder = "選択してください",
  className = "",
  parentClassName = "",
  required = false,
  creatable,
  isClearable = true,
  handleCreate,
  onChange,
}: PropsType) => {
  return (
    <div
      className={`relative ${
        isDisabled ? "cursor-not-allowed" : ""
      } ${parentClassName}`}
    >
      {creatable ? (
        <CreatableSelect
          name={name}
          value={value}
          options={options}
          isDisabled={isDisabled}
          isSearchable
          isClearable={isClearable}
          placeholder={placeholder}
          className={`${className}`}
          classNames={{
            placeholder: () => "!text-secondary-400",
            option: ({ isSelected, isFocused }) =>
              isSelected
                ? "!bg-primary-600"
                : isFocused
                  ? "!bg-primary-200"
                  : "",
            control: ({ isDisabled }) =>
              isDisabled ? "!bg-secondary-300 !border-secondary-400" : "",
            singleValue: ({ isDisabled }) =>
              isDisabled ? "!text-secondary-600" : "",
            multiValueRemove: () =>
              "hover:!bg-primary-400 hover:!text-primary-600 !bg-primary-200",
            multiValueLabel: () => "!bg-primary-200",
          }}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 1 }) }}
          onChange={onChange}
          onCreateOption={handleCreate}
          noOptionsMessage={() => "選択肢がありません"}
          formatCreateLabel={(inputValue: string) => (
            <>{inputValue} を新規作成</>
          )}
        />
      ) : (
        <Select
          name={name}
          value={value}
          options={options}
          isDisabled={isDisabled}
          isSearchable
          isClearable={isClearable}
          placeholder={placeholder}
          className={`${className}`}
          classNames={{
            placeholder: () => "!text-secondary-400",
            option: ({ isSelected, isFocused }) =>
              isSelected
                ? "!bg-primary-600"
                : isFocused
                  ? "!bg-primary-200"
                  : "",
            control: ({ isDisabled }) =>
              isDisabled ? "!bg-secondary-300 !border-secondary-400" : "",
            singleValue: ({ isDisabled }) =>
              isDisabled ? "!text-secondary-600" : "",
            multiValueRemove: () =>
              "hover:!bg-primary-400 hover:!text-primary-600 !bg-primary-200",
            multiValueLabel: () => "!bg-primary-200",
          }}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 1 }) }}
          onChange={onChange}
          noOptionsMessage={() => "選択肢がありません"}
        />
      )}
      {required && <RequiredHiddenField value={value?.value || ""} />}
    </div>
  );
};
