import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  kpiTermId: string;
  body: {
    id: string;
    displayOrder: number;
  }[];
};

const request = ({ kpiTermId, body }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseMessageType>(
      `/api/managers/kpi_terms/${kpiTermId}/display_order`,
      { kpiTerm: { kpiObjectives: body } },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersKpiTermsDisplayOrdersUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
