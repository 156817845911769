import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useSearchManagersArchivedBusinessSections,
  useApisManagersBusinessSectionsArchivedsDestroy,
} from "~/hooks";

import {
  FormSubmitButton,
  Form,
  Button,
  GridHeaderCells,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  GridRow,
  OpenedMenuInGrid,
  LabelWithTextField,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";

import { BusinessSectionWithBusinessDivisionType } from "~/domains";

export const ManagersArchivedBusinessSectionsIndex: FC = () => {
  const {
    businessSections,
    fetchNextPage,
    isLoading,
    hasNextPage,
    keyword,
    onSearchSubmit,
    onChangeKeyword,
    onConditionReset,
    findAndRemove,
  } = useSearchManagersArchivedBusinessSections();

  const { mutate: createArchivedBusinessSection } =
    useApisManagersBusinessSectionsArchivedsDestroy();

  const handleArchive = (
    businessSection: BusinessSectionWithBusinessDivisionType,
  ) => {
    const result = confirm("本当に無効にしますか？");
    if (!result) return;
    createArchivedBusinessSection(
      {
        businessSectionId: businessSection.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((item) => item.id === businessSection.id);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "課設定", href: "/managers/business_sections" },
    { label: "無効課", href: "/managers/archived_business_sections" },
  ];

  return (
    <>
      <EmployeeLayout
        withRightSidebar={false}
        breadCrumbItems={breadCrumbItems}
      >
        <EmployeeLayoutMainContent withRightSidebar={false}>
          <Form onSubmit={onSearchSubmit} className="space-y-6">
            <>
              <div className="grid miniTablet:grid-cols-2 gap-4">
                <LabelWithTextField
                  labelText="フリーワード"
                  type="text"
                  name="keyword"
                  placeholder="部署名・課名検索"
                  value={keyword}
                  onChange={onChangeKeyword}
                />
              </div>
              <div className="flex items-center justify-end space-x-4">
                <Button
                  color="gray"
                  outline
                  className="w-full miniTablet:w-auto"
                  text="リセット"
                  onClick={onConditionReset}
                />
                <FormSubmitButton
                  value="検索する"
                  color="primary"
                  className="w-full miniTablet:w-auto"
                />
              </div>
            </>
          </Form>
          <InfiniteScrollWithMoreButton
            itemsLength={businessSections.length}
            nextFetchFunction={fetchNextPage}
            isLoading={isLoading}
            hasMore={hasNextPage}
            className="mt-12"
          >
            <div className="grid grid-cols-2">
              <GridHeaderCells texts={["部署", "課"]} />
              {businessSections.map((businessSection) => (
                <GridRow
                  key={businessSection.id}
                  lists={[
                    businessSection.businessDivision.name,
                    businessSection.name,
                  ]}
                  openedMenu={
                    <OpenedMenuInGrid
                      cols={2}
                      buttons={[
                        {
                          text: "有効化",
                          onClick: () => handleArchive(businessSection),
                        },
                      ]}
                    />
                  }
                />
              ))}
            </div>
          </InfiniteScrollWithMoreButton>
        </EmployeeLayoutMainContent>
      </EmployeeLayout>
    </>
  );
};
