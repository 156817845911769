import React, { FC, MouseEvent } from "react";

import { ButtonWithIcon, LogoImage } from "~/components/atoms";

type PropsType = {
  title?: string;
  onBack?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClose: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const FloatingChatBoxHeader: FC<PropsType> = ({
  title = "AIアシスタント",
  onBack,
  onClose,
}: PropsType) => {
  return (
    <div className="flex items-center justify-between">
      {onBack ? (
        <ButtonWithIcon
          onClick={onBack}
          icon={{
            icon: "ioChevronBackOutline",
            size: "2rem",
            color: "text-secondary-600",
          }}
          srOnlyText="Chatを閉じる"
          withHoverEffect
        />
      ) : (
        <LogoImage size="md" />
      )}
      <h4 className="text-primary-600 text-xl font-bold">{title}</h4>
      <ButtonWithIcon
        onClick={onClose}
        icon={{
          icon: "ioCloseOutline",
          size: "2rem",
          color: "text-secondary-600",
        }}
        srOnlyText="Chatを閉じる"
        withHoverEffect
      />
    </div>
  );
};
