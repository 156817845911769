import { OptionType, ParentType, ChildType, ChildOptionType } from "~/domains";

// NOTE: Convert From Option
export const convertOptionToParents = (parents: OptionType[]): ParentType[] => {
  return parents.map((parent) => convertOptionToParent(parent));
};

export const convertOptionToParent = (parent: OptionType): ParentType => {
  return { id: parent.value, name: parent.label };
};

export const convertOptionToChildren = (
  children: ChildOptionType[],
): ChildType[] => {
  return children.map((child) => convertOptionToChild(child));
};

export const convertOptionToChild = (child: ChildOptionType): ChildType => {
  return {
    id: child.value,
    name: child.label,
    parentId: child.parentId,
  };
};

// NOTE: Convert To Option
export const convertParentToOptions = (parents: ParentType[]): OptionType[] => {
  return parents.map((parent) => convertParentToOption(parent));
};

export const convertChildToOptions = (
  children: ChildType[],
): ChildOptionType[] => {
  return children.map((child) => convertChildToOption(child));
};

export const convertParentToOption = (parent: ParentType): OptionType => {
  return { label: parent.name, value: parent.id };
};

export const convertChildToOption = (child: ChildType): ChildOptionType => {
  return {
    label: child.name,
    value: child.id,
    parentId: child.parentId,
  };
};

export const selectableChildren = (
  allChildren: ChildType[],
  currentParent?: null | ParentType | ParentType[],
): ChildType[] => {
  if (!currentParent) {
    return [];
  }

  if (Array.isArray(currentParent)) {
    const selectedParentIds = currentParent.map((Parent) => Parent.id);
    return allChildren.filter((child) =>
      selectedParentIds.includes(child.parentId),
    );
  } else {
    return allChildren.filter((child) => currentParent?.id === child.parentId);
  }
};
