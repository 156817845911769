import React, { FC, ReactNode } from "react";

type PropsType = {
  className?: string;
  children: ReactNode;
};

export const VerticalProgressLine: FC<PropsType> = ({
  className,
  children,
}: PropsType) => {
  return (
    <div
      className={`relative space-y-8 before:absolute before:top-4 before:bottom-0 before:left-[1.1rem] before:z-lowest before:w-1 before:bg-secondary-300 before:rounded-sm ${className}`}
    >
      {children}
    </div>
  );
};
