import { MouseEvent, ChangeEvent, FormEvent, useState } from "react";

import { toDateString } from "~/libs";

import {
  useInput,
  useArray,
  useApisManagersMeetingNotesIndex,
  useDateRange,
  useProvidersEmployeeLayout,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
  useCompleteStatusDropdown,
  useMultipleDropdown,
  useApisManagersMeetingNoteSearchItemsIndex,
  useApisManagersBelongingEmployeesMeetingNotesIndex,
} from "~/hooks";

import {
  ApiManagersMeetingNotesIndexRequestType,
  BusinessDivisionType,
  ChildType,
  MeetingNoteType,
  MultiValueType,
  OptionType,
  ParentType,
  RangeDatePropsType,
  SingleValueType,
} from "~/domains";

type ReturnType = {
  meetingNotes: MeetingNoteType[];
  hasNextPage?: boolean;
  keyword: string;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  selectableDropdownCompleteStatusIds: OptionType[];
  selectedDropdownCompleteStatusId?: SingleValueType<OptionType>;
  selectableDropdownCreatedEmployees: OptionType[];
  selectedDropdownCreatedEmployees: MultiValueType<OptionType>;
  selectableDropdownParticipationEmployees: OptionType[];
  selectedDropdownParticipationEmployees: MultiValueType<OptionType>;
  selectableDropdownDivisions: BusinessDivisionType[];
  selectedDropdownDivisions: BusinessDivisionType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  onChangeDropdownCompleteStatusId: (
    value: SingleValueType<OptionType>,
  ) => void;
  onChangeDropdownCreatedEmployees: (value: MultiValueType<OptionType>) => void;
  onChangeDropdownParticipationEmployees: (
    value: MultiValueType<OptionType>,
  ) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  deleteIndexItem: (index: number) => void;
  unshiftItem: (item: MeetingNoteType) => void;
  updateIndexItem: (item: MeetingNoteType, index: number) => void;
  findAndRemove: (func: (item: MeetingNoteType) => boolean) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

type PropsTYpe = {
  useApisMeetingNotes:
    | typeof useApisManagersMeetingNotesIndex
    | typeof useApisManagersBelongingEmployeesMeetingNotesIndex;
};

export const useSearchManagersMeetingNotes = ({
  useApisMeetingNotes,
}: PropsTYpe): ReturnType => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const [
    {
      dropdownValue: selectedDropdownCreatedEmployees,
      onChange: onChangeDropdownCreatedEmployees,
      dropdownSelectableValue: selectableDropdownCreatedEmployees,
      setSelectableOption: setSelectableDropdownCreatedEmployees,
    },
    setDropdownCreatedEmployees,
  ] = useMultipleDropdown();

  const [
    {
      dropdownValue: selectedDropdownParticipationEmployees,
      onChange: onChangeDropdownParticipationEmployees,
      dropdownSelectableValue: selectableDropdownParticipationEmployees,
      setSelectableOption: setSelectableDropdownParticipationEmployees,
    },
    setDropdownParticipationEmployees,
  ] = useMultipleDropdown();

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });
  const [
    {
      statusValue,
      selectedDropdownCompleteStatusId,
      selectableDropdownCompleteStatusIds,
      onChangeDropdownCompleteStatusId,
    },
  ] = useCompleteStatusDropdown();

  const [params, setParams] =
    useState<ApiManagersMeetingNotesIndexRequestType>();

  const {
    items: meetingNotes,
    setItems: setMeetingNotes,
    deleteIndexItem,
    unshiftItem,
    updateIndexItem,
    findAndRemove,
  } = useArray<MeetingNoteType>([]);

  useApisManagersMeetingNoteSearchItemsIndex({
    config: {
      onSuccess: (res) => {
        setSelectableDropdownCreatedEmployees(res.employees);
        setSelectableDropdownParticipationEmployees(res.employees);
      },
    },
  });

  const { fetchNextPage, hasNextPage, isFetching } = useApisMeetingNotes({
    params,
    config: {
      onSuccess: (data) => {
        setMeetingNotes(data.pages.map((page) => page.meetingNotes).flat());
      },
    },
  });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      completed: statusValue,
      postDateFrom: startDate ? toDateString(startDate) : "",
      postDateTo: endDate ? toDateString(endDate) : "",
      businessDivisionIds: selectedDivisions.map((division) => division.id),
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      createdEmployeeIds: selectedDropdownCreatedEmployees.map(
        (employee) => employee.value,
      ),
      participationEmployeeIds: selectedDropdownParticipationEmployees.map(
        (employee) => employee.value,
      ),
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownCompleteStatusId(null);
    onChangeDateRange([null, null]);
    setParams(undefined);
    setFalseRightSidebarMain();
    setDropdownCreatedEmployees([]);
    setDropdownParticipationEmployees([]);
  };

  return {
    meetingNotes,
    hasNextPage,
    isLoading: isFetching,
    startDate,
    endDate,
    keyword,
    selectableDropdownCompleteStatusIds,
    selectedDropdownCompleteStatusId,
    selectableDropdownCreatedEmployees,
    selectedDropdownCreatedEmployees,
    selectableDropdownParticipationEmployees,
    selectedDropdownParticipationEmployees,
    selectableDropdownDivisions: selectableDivisions,
    selectedDropdownDivisions: selectedDivisions,
    selectableDropdownSections: optionSelectableSections,
    selectedDropdownSections: optionSelectedSections,
    onDivisionChange,
    onSectionChange,
    onChangeDropdownCreatedEmployees,
    onChangeDropdownParticipationEmployees,
    onChangeDropdownCompleteStatusId,
    fetchNextPage,
    onChangeDateRange,
    unshiftItem,
    updateIndexItem,
    deleteIndexItem,
    findAndRemove,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  };
};
