import React, { FC, useState, useEffect } from "react";

import { useFetchBrowserInfo } from "~/hooks";

import { SignInWrapper } from "~/components/atoms";

export const BrowserInfoIndex: FC = () => {
  const { userAgent, browser, engine, os, device } = useFetchBrowserInfo();
  const [screenSize, setScreenSize] = useState({
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <SignInWrapper>
      <div className="gap-y-4 mx-6 px-4 pb-12 pt-11 miniTablet:px-14 max-w-xl w-full bg-white border-secondary-400 border-solid border rounded-lg shadow">
        <div className="">
          <strong>User Agent:</strong> {userAgent}
        </div>
        <div className="">
          <strong>Browser:</strong> {browser.name}
        </div>
        <div className="">
          <strong>Browser Version:</strong> {browser.version}
        </div>
        <div className="">
          <strong>Engine:</strong> {engine.name}
        </div>
        <div className="">
          <strong>Engine Version:</strong> {engine.version}
        </div>
        <div className="">
          <strong>OS:</strong> {os.name}
        </div>
        <div className="">
          <strong>OS Version:</strong> {os.version}
        </div>
        <div className="">
          <strong>Device:</strong> {device.type}
        </div>
        <div className="">
          <strong>Device Vendor:</strong> {device.vendor}
        </div>
        <div className="">
          <strong>Device Model:</strong> {device.model}
        </div>
        <div className="">
          <strong>Screen Width:</strong> {screenSize.screenWidth}px
        </div>
        <div className="">
          <strong>Screen Height:</strong> {screenSize.screenHeight}px
        </div>
        <div className="">
          <strong>App Version:</strong> {process.env.APP_VERSION}
        </div>
      </div>
    </SignInWrapper>
  );
};
