import React, { FC } from "react";

import {
  PostFooter,
  PostContentEditorWithImages,
} from "~/components/organisms";

import {
  RawDraftContentState,
  AttachFileType,
  PostFooterType,
  AvatarAndNameEmployeeType,
} from "~/domains";

type PropsType = {
  className?: string;
  content: RawDraftContentState;
  images: AttachFileType[];
  footers: PostFooterType[];
  rightFooters?: PostFooterType[];
  blockQuote?: {
    content: RawDraftContentState;
    employee: AvatarAndNameEmployeeType;
    files: AttachFileType[];
    createdAt: string;
  };
};

export const PostContent: FC<PropsType> = ({
  content,
  images,
  footers,
  rightFooters,
  className = "",
  blockQuote,
}: PropsType) => {
  return (
    <div className={`w-full ${className}`}>
      <PostContentEditorWithImages
        content={content}
        files={images}
        blockQuote={blockQuote}
      />
      <PostFooter
        iconWithCounters={footers}
        rightIconWithCounters={rightFooters}
        className="mt-3"
      />
    </div>
  );
};
