import React, { FC } from "react";

import {
  useApisAnalyticsPlansEmployeesPostsCalendarsIndex,
  useApisAnalyticsPlansPostsRateCalendarsIndex,
  useMultiDivisionMultiSectionDropdown,
  usePrevNextSelectMonth,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import { AnalyticsPostsCalendarsTemplate } from "~/components/templates";

export const AnalyticsPlansPostsCalendarsIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const breadCrumbItems = [
    {
      label: "インサイト・チャレンジ・改善・相談",
      href: "/analytics_plans/posts_calendars",
    },
  ];

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
    selectableEmployees,
    selectedEmployee,
    onEmployeeChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    isSelectEmployee: true,
  });

  const {
    targetDate,
    targetDateString,
    handlePrevMonthClick,
    handleNextMonthClick,
  } = usePrevNextSelectMonth();

  const { data: employeesData, isLoading: employeesIsLoading } =
    useApisAnalyticsPlansEmployeesPostsCalendarsIndex({
      employeeId: selectedEmployee?.id || "",
      params: {
        targetDate: targetDateString,
      },
      config: {
        enabled: !!selectedEmployee?.id,
      },
    });

  const { data: rateData, isLoading: rateIsLoading } =
    useApisAnalyticsPlansPostsRateCalendarsIndex({
      params: {
        targetDate: targetDateString,
        businessDivisionIds: selectedDivisions.map((division) => division.id),
        businessSectionIds: optionSelectedSections.map((section) => section.id),
        employeeIds: selectedEmployee ? [selectedEmployee.id] : undefined,
      },
      config: {
        enabled: !selectedEmployee?.id,
      },
    });

  const isLoading = selectedEmployee ? employeesIsLoading : rateIsLoading;
  const data = selectedEmployee ? employeesData : rateData;

  return (
    <>
      <EmployeeLayout
        withRightSidebar={false}
        breadCrumbItems={breadCrumbItems}
      >
        <EmployeeLayoutMainContent withRightSidebar={false}>
          <AnalyticsPostsCalendarsTemplate
            selectedDivisions={selectedDivisions}
            optionSelectedSections={optionSelectedSections}
            data={data}
            isLoading={isLoading}
            selectableDivisions={selectableDivisions}
            optionSelectableSections={optionSelectableSections}
            selectedDropdownSections={optionSelectedSections}
            selectedEmployee={selectedEmployee}
            onEmployeeChange={onEmployeeChange}
            selectableEmployees={selectableEmployees}
            targetDate={targetDate}
            handlePrevMonthClick={handlePrevMonthClick}
            handleNextMonthClick={handleNextMonthClick}
            onDivisionChange={onDivisionChange}
            onSectionChange={onSectionChange}
          />
        </EmployeeLayoutMainContent>
      </EmployeeLayout>
    </>
  );
};
