import React, { ChangeEvent, FC, FormEvent, MouseEvent } from "react";

import { FormSubmitButton, Form, Button } from "~/components/atoms";
import {
  LabelWithTextField,
  LabelWithMultipleDropdownField,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import {
  BusinessDivisionType,
  ChildType,
  MultiValueType,
  OptionType,
  ParentType,
} from "~/domains";

type PropsType = {
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onChangeDropdownEmployeeRoleIds: (value: MultiValueType<OptionType>) => void;
  onChangeDropdownJobCategories: (value: MultiValueType<OptionType>) => void;
  onChangeDropdownJobTitles: (value: MultiValueType<OptionType>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  keyword: string;
  selectableDropdownEmployeeRoleIds: OptionType[];
  selectedDropdownEmployeeRoleIds?: MultiValueType<OptionType>;
  selectableDropdownJobTitles: OptionType[];
  selectedDropdownJobTitles?: MultiValueType<OptionType>;
  selectableDropdownJobCategories: OptionType[];
  selectedDropdownJobCategories?: MultiValueType<OptionType>;
  divisionAndSections?: {
    selectableDropdownDivisions: BusinessDivisionType[];
    selectedDropdownDivisions: BusinessDivisionType[];
    selectableDropdownSections: ChildType[];
    selectedDropdownSections: ChildType[];
    onDivisionChange: (value: ParentType[]) => void;
    onSectionChange: (value: ChildType[]) => void;
  };
};

export const EmployeesSearchForm: FC<PropsType> = ({
  onSearchSubmit,
  onConditionReset,
  onChangeDropdownEmployeeRoleIds,
  onChangeDropdownJobCategories,
  onChangeDropdownJobTitles,
  onChangeKeyword,
  selectableDropdownEmployeeRoleIds,
  selectedDropdownEmployeeRoleIds,
  selectableDropdownJobTitles,
  selectedDropdownJobTitles,
  selectableDropdownJobCategories,
  selectedDropdownJobCategories,
  keyword,
  divisionAndSections,
}: PropsType) => {
  return (
    <Form onSubmit={onSearchSubmit} className="space-y-6">
      <div className="grid miniTablet:grid-cols-2 gap-4">
        {divisionAndSections && (
          <MultiParentsWithMultiChildrenDropdownField
            allParents={divisionAndSections.selectableDropdownDivisions}
            parentsValue={divisionAndSections.selectedDropdownDivisions}
            parentLabel="部署"
            parentsOnChange={divisionAndSections.onDivisionChange}
            allChildren={divisionAndSections.selectableDropdownSections}
            childrenValue={divisionAndSections.selectedDropdownSections}
            childLabel="課"
            childrenOnChange={divisionAndSections.onSectionChange}
          />
        )}
        <LabelWithMultipleDropdownField
          labelText="職種"
          options={selectableDropdownJobCategories}
          value={selectedDropdownJobCategories}
          onChange={onChangeDropdownJobCategories}
          name="jobCategories"
        />
        <LabelWithMultipleDropdownField
          labelText="役職"
          options={selectableDropdownJobTitles}
          value={selectedDropdownJobTitles}
          onChange={onChangeDropdownJobTitles}
          name="jobTitles"
        />
        <LabelWithMultipleDropdownField
          labelText="権限"
          name="employeeRoleIds"
          options={selectableDropdownEmployeeRoleIds}
          value={selectedDropdownEmployeeRoleIds}
          onChange={onChangeDropdownEmployeeRoleIds}
        />
        <LabelWithTextField
          labelText="フリーワード"
          type="search"
          name="keyword"
          placeholder="フリーワード検索"
          value={keyword}
          onChange={onChangeKeyword}
        />
      </div>
      <div className="flex items-center justify-end space-x-4">
        <Button
          color="gray"
          outline
          className="w-full miniTablet:w-auto"
          text="リセット"
          onClick={onConditionReset}
        />
        <FormSubmitButton
          value="検索する"
          color="primary"
          className="w-full miniTablet:w-auto"
        />
      </div>
    </Form>
  );
};
