import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { useSearchMembersInsightPostBookmarkFoldersInsightPosts } from "~/hooks";

import { EmployeeLayout } from "~/components/layouts";
import { BookmarkInsightPostsIndexTemplate } from "~/components/templates";

export const MembersBookmarkInsightPostBookmarkFoldersInsightPostsIndex: FC =
  () => {
    const { id = "" } = useParams<{ id: string }>();

    const {
      insightPosts,
      startDate,
      endDate,
      isLoading,
      hasNextPage,
      sort,
      keyword,
      selectableDropdownHasCommentIds,
      selectedDropdownHasCommentId,
      selectableDropdownSharedIds,
      selectedDropdownSharedId,
      selectableJobCategories,
      selectedJobCategories,
      optionSelectableJobCategoryPhases,
      optionSelectedJobCategoryPhases,
      selectedProvidingServices,
      selectableProvidingServices,
      selectableDropdownEmployees,
      selectedDropdownEmployees,
      bookmarkFolderId,
      bookmarkFolderName,
      onChangeProvidingServices,
      onChangeJobCategoryPhases,
      onChangeJobCategories,
      onChangeDropdownHasCommentId,
      onChangeDropdownSharedId,
      onChangeDateRange,
      onChangeKeyword,
      onChangeDropdownEmployees,
      fetchNextPage,
      setSort,
      onSearchSubmit,
      onConditionReset,
      updateIndexInsightPost,
      deleteIndexInsightPost,
    } = useSearchMembersInsightPostBookmarkFoldersInsightPosts({
      insightPostBookmarkFolderId: id,
    });
    const breadCrumbItems = [
      {
        label: "個人・チームへのブクマ",
        href: "/bookmark/insight_posts",
      },
      {
        label: bookmarkFolderName,
        href: `/bookmark/insight_post_bookmark_folders/${bookmarkFolderId}`,
      },
    ];

    return (
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <BookmarkInsightPostsIndexTemplate
          isLoading={isLoading}
          insightPosts={insightPosts}
          startDate={startDate}
          endDate={endDate}
          hasNextPage={hasNextPage}
          sort={sort}
          keyword={keyword}
          selectableDropdownHasCommentIds={selectableDropdownHasCommentIds}
          selectedDropdownHasCommentId={selectedDropdownHasCommentId}
          selectableDropdownSharedIds={selectableDropdownSharedIds}
          selectedDropdownSharedId={selectedDropdownSharedId}
          selectableJobCategories={selectableJobCategories}
          selectedJobCategories={selectedJobCategories}
          optionSelectableJobCategoryPhases={optionSelectableJobCategoryPhases}
          optionSelectedJobCategoryPhases={optionSelectedJobCategoryPhases}
          selectedProvidingServices={selectedProvidingServices}
          selectableProvidingServices={selectableProvidingServices}
          selectableDropdownEmployees={selectableDropdownEmployees}
          selectedDropdownEmployees={selectedDropdownEmployees}
          onChangeProvidingServices={onChangeProvidingServices}
          onChangeJobCategoryPhases={onChangeJobCategoryPhases}
          onChangeJobCategories={onChangeJobCategories}
          onChangeDropdownHasCommentId={onChangeDropdownHasCommentId}
          onChangeDropdownSharedId={onChangeDropdownSharedId}
          onChangeDateRange={onChangeDateRange}
          onChangeKeyword={onChangeKeyword}
          onChangeDropdownEmployees={onChangeDropdownEmployees}
          fetchNextPage={fetchNextPage}
          setSort={setSort}
          onSearchSubmit={onSearchSubmit}
          onConditionReset={onConditionReset}
          updateIndexInsightPost={updateIndexInsightPost}
          deleteIndexInsightPost={deleteIndexInsightPost}
        />
      </EmployeeLayout>
    );
  };
