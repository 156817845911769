import { ChangeEvent, FormEvent, MouseEvent, useState } from "react";

import { toast } from "react-toastify";

import {
  useArray,
  useBoolean,
  useApisMembersKpiKpiObjectivesKpiDailyPerformancesUpdate,
  useApisMembersKpiKpiObjectivesKpiDailyPerformancesCreate,
  useApisMembersKpiKpiObjectivesKpiDailyPerformancesDestroy,
} from "~/hooks";

import {
  KpiDailyPerformanceWithPerformanceClientCompanyType,
  KpiDailyPerformanceClientCompanyType,
} from "~/domains";

type ReturnType = {
  isSubmitting: boolean;
  performance: {
    value: number | "";
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  clientCompanies: PickKpiDailyPerformanceClientCompanyType[];
  readonly: boolean;
  handleNotReadonly: (e: MouseEvent<HTMLButtonElement>) => void;
  addClientCompany: (e: MouseEvent<HTMLButtonElement>) => void;
  deleteIndexClientCompany: (
    e: MouseEvent<HTMLButtonElement>,
    index: number,
  ) => void;
  onChangeClientCompanyName: (index: number, newValue: string) => void;
  onChangeClientCompanyPrice: (index: number, newValue: number | "") => void;
  handleOnSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleOnDelete: (e: MouseEvent<HTMLButtonElement>) => void;
};

type PickKpiDailyPerformanceClientCompanyType = Pick<
  KpiDailyPerformanceClientCompanyType,
  "id" | "clientCompanyName"
> & {
  price?: number | "";
};

type PropsType = {
  defaultKpiDailyPerformance?: KpiDailyPerformanceWithPerformanceClientCompanyType;
  kpiObjectiveId: string;
  formattedDate: string;
};

export function useKpiDailyPerformanceForm({
  defaultKpiDailyPerformance,
  kpiObjectiveId,
  formattedDate,
}: PropsType): ReturnType {
  const [kpiDailyPerformance, setKpiDailyPerformance] = useState(
    defaultKpiDailyPerformance,
  );

  const {
    isChecked: readonly,
    setFalse: setNotReadonly,
    setTrue: setReadonly,
  } = useBoolean(Boolean(kpiDailyPerformance));

  const handleNotReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setNotReadonly();
  };

  const { mutate: createRequest, isLoading: isCreating } =
    useApisMembersKpiKpiObjectivesKpiDailyPerformancesCreate();
  const { mutate: updateRequest, isLoading: isUpdating } =
    useApisMembersKpiKpiObjectivesKpiDailyPerformancesUpdate();
  const { mutate: deleteRequest } =
    useApisMembersKpiKpiObjectivesKpiDailyPerformancesDestroy();

  const [performanceCount, setPerformanceCount] = useState<number | "">(
    kpiDailyPerformance?.performance ?? "",
  );

  const { items, pushItem, updateIndexItem, deleteIndexItem, setItems } =
    useArray<PickKpiDailyPerformanceClientCompanyType>(
      kpiDailyPerformance?.kpiDailyPerformanceClientCompanies || [],
    );

  const pushClientCompany = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    pushItem({ id: pushNewId(), clientCompanyName: "", price: "" });
  };

  const pushNewId = () => {
    return (items.length + 1).toString();
  };

  const onChangeClientCompanyName = (index: number, newValue: string) => {
    const item = items[index];
    if (!item) return;
    const newObject = {
      id: item.id,
      price: item.price,
      clientCompanyName: newValue,
    };
    updateIndexItem(newObject, index);
  };

  const onChangeClientCompanyPrice = (index: number, newValue: number | "") => {
    const item = items[index];
    if (!item) return;
    const newObject = {
      id: item.id,
      price: newValue,
      clientCompanyName: item.clientCompanyName,
    };

    updateIndexItem(newObject, index);
  };

  const handlePostRequest = () => {
    createRequest(
      {
        kpiObjectiveId,
        body: generateParams(),
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setReadonly();
          setKpiDailyPerformance(data.kpiDailyPerformance);
        },
      },
    );
  };

  const handlePutRequest = () => {
    if (!kpiDailyPerformance) return;

    updateRequest(
      {
        kpiObjectiveId,
        id: kpiDailyPerformance.id,
        body: generateParams(),
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setReadonly();
          setKpiDailyPerformance(data.kpiDailyPerformance);
        },
      },
    );
  };

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    kpiDailyPerformance ? handlePutRequest() : handlePostRequest();
  };

  const reset = () => {
    setPerformanceCount(0);
    setItems([]);
  };

  const handleOnDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    kpiDailyPerformance
      ? deleteRequest(
          {
            kpiObjectiveId: kpiObjectiveId,
            id: kpiDailyPerformance.id,
          },
          {
            onSuccess: (data) => {
              toast(data.message);
              reset();
              setKpiDailyPerformance(undefined);
            },
          },
        )
      : reset();
  };

  const generateParams = () => {
    return {
      performanceDate: formattedDate,
      performance: performanceCount as number,
      kpiDailyPerformanceClientCompanies: items.map((item) => ({
        name: item.clientCompanyName,
        price: item.price,
      })),
    };
  };

  const deleteIndexClientCompany = (
    e: MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    e.preventDefault();
    deleteIndexItem(index);
  };

  const handlePerformanceCountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = isNaN(parseInt(e.target.value))
      ? ""
      : parseInt(e.target.value);
    setPerformanceCount(value);
  };

  return {
    isSubmitting: isCreating || isUpdating,
    performance: {
      value: performanceCount,
      onChange: handlePerformanceCountChange,
    },
    clientCompanies: items,
    readonly: readonly,
    addClientCompany: pushClientCompany,
    onChangeClientCompanyName: onChangeClientCompanyName,
    onChangeClientCompanyPrice: onChangeClientCompanyPrice,
    deleteIndexClientCompany,
    handleOnSubmit: handleOnSubmit,
    handleOnDelete: handleOnDelete,
    handleNotReadonly: handleNotReadonly,
  };
}
