import React, { FC } from "react";

import { useComponentsAdminOperatorsCompaniesCreateForm } from "~/hooks";

import {
  Form,
  FormSubmitButton,
  PasswordRuleMessage,
} from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import {
  DisplayablePasswordFiled,
  LabelWithTextField,
} from "~/components/molecules";

export const AdminOperatorsCompaniesNew: FC = () => {
  const {
    isSubmitting,
    onChangeEmail,
    onChangePassword,
    onChangeSubdomain,
    onChangeName,
    handleSubmit,
  } = useComponentsAdminOperatorsCompaniesCreateForm();
  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: "/admin_operators",
    },
    {
      label: "会社新規作成",
      href: "/admin_operators/companies/new",
    },
  ];

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      <Form onSubmit={handleSubmit} className="space-y-8">
        <>
          <LabelWithTextField
            labelText="会社名"
            type="text"
            name="name"
            placeholder="〇〇株式会社"
            required
            onChange={onChangeName}
          />
          <LabelWithTextField
            labelText="会社のサブドメイン"
            type="text"
            name="subdomain"
            placeholder="guideline"
            required
            onChange={onChangeSubdomain}
          />
          <LabelWithTextField
            labelText="初期オーナーのメールアドレス"
            type="email"
            name="email"
            placeholder="email@example.com"
            required
            onChange={onChangeEmail}
          />
          <div>
            <DisplayablePasswordFiled
              label="初期オーナーのパスワード"
              required
              name="password"
              autoComplete="new-password"
              onChange={onChangePassword}
            />
            <PasswordRuleMessage className="mt-1" />
          </div>
          <FormSubmitButton
            color="primary"
            value="新規作成"
            isReadOnly={isSubmitting}
            className="w-full"
          />
        </>
      </Form>
    </AdminOperatorLayout>
  );
};
