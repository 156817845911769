import React, { FC } from "react";

type PropsType = {
  label: string;
};

export const TextWithHr: FC<PropsType> = ({ label }: PropsType) => {
  return (
    <>
      <h3 className="font-medium">{label}</h3>
      <hr className="mt-1" />
    </>
  );
};
