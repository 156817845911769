import React, { ChangeEvent, FormEvent, MouseEvent, FC } from "react";

import { Button, Form, FormSubmitButton } from "~/components/atoms";
import { LabelWithTextArea } from "~/components/molecules";

import { RoleplayingEvaluationType } from "~/domains";

type PickRoleplayingEvaluationType = Pick<
  RoleplayingEvaluationType,
  | "id"
  | "roleplayingConditionId"
  | "myEvaluationGoodContent"
  | "myEvaluationImproveContent"
  | "otherUserEvaluationGoodContent"
  | "otherUserEvaluationImproveContent"
> & {
  isReadOnly?: boolean;
};

type OnChangeStringPropsType = {
  roleplayingEvaluation: PickRoleplayingEvaluationType;
  newValue: string;
};

type PropsType = {
  onChangeMyEvaluationGoodContent: ({
    roleplayingEvaluation,
    newValue,
  }: OnChangeStringPropsType) => void;
  onChangeMyEvaluationImproveContent: ({
    roleplayingEvaluation,
    newValue,
  }: OnChangeStringPropsType) => void;
  onChangeOtherUserEvaluationGoodContent: ({
    roleplayingEvaluation,
    newValue,
  }: OnChangeStringPropsType) => void;
  onChangeOtherUserEvaluationImproveContent: ({
    roleplayingEvaluation,
    newValue,
  }: OnChangeStringPropsType) => void;
  handleSave: (roleplayingEvaluation: PickRoleplayingEvaluationType) => void;
  removeRoleplayingEvaluation: (
    roleplayingEvaluation: PickRoleplayingEvaluationType,
  ) => void;
  className?: string;
  roleplayingEvaluation: PickRoleplayingEvaluationType;
  setNotReadOnly: (
    roleplayingEvaluation: PickRoleplayingEvaluationType,
  ) => void;
  numberSpot: number;
};

export const RoleplayingEvaluationForm: FC<PropsType> = ({
  roleplayingEvaluation,
  className = "",
  onChangeMyEvaluationGoodContent,
  onChangeMyEvaluationImproveContent,
  onChangeOtherUserEvaluationGoodContent,
  onChangeOtherUserEvaluationImproveContent,
  handleSave,
  removeRoleplayingEvaluation,
  setNotReadOnly,
  numberSpot,
}: PropsType) => {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSave(roleplayingEvaluation);
  };

  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    removeRoleplayingEvaluation(roleplayingEvaluation);
  };

  const handleNotReadOnly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setNotReadOnly(roleplayingEvaluation);
  };

  return (
    <>
      <Form className={className} onSubmit={handleSubmit}>
        <LabelWithTextArea
          required
          labelText={`${numberSpot}回目: よかった点(自己評価)`}
          name="myEvaluationGoodContent"
          value={roleplayingEvaluation.myEvaluationGoodContent}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            onChangeMyEvaluationGoodContent({
              roleplayingEvaluation: roleplayingEvaluation,
              newValue: e.target.value,
            })
          }
          placeholder="よかった点(自己評価)"
          disabled={roleplayingEvaluation.isReadOnly}
        />
        <LabelWithTextArea
          required
          labelText={`${numberSpot}回目: 改善点(自己評価)`}
          name="myEvaluationImproveContent"
          value={roleplayingEvaluation.myEvaluationImproveContent}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            onChangeMyEvaluationImproveContent({
              roleplayingEvaluation: roleplayingEvaluation,
              newValue: e.target.value,
            })
          }
          placeholder="改善点(自己評価)"
          disabled={roleplayingEvaluation.isReadOnly}
        />
        <LabelWithTextArea
          required
          labelText={`${numberSpot}回目: よかった点(他者評価)`}
          name="otherUserEvaluationGoodContent"
          value={roleplayingEvaluation.otherUserEvaluationGoodContent}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            onChangeOtherUserEvaluationGoodContent({
              roleplayingEvaluation: roleplayingEvaluation,
              newValue: e.target.value,
            })
          }
          placeholder="よかった点(他者評価)"
          disabled={roleplayingEvaluation.isReadOnly}
        />
        <LabelWithTextArea
          required
          labelText={`${numberSpot}回目: 改善点(他者評価)`}
          name="otherUserEvaluationImproveContent"
          value={roleplayingEvaluation.otherUserEvaluationImproveContent}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            onChangeOtherUserEvaluationImproveContent({
              roleplayingEvaluation: roleplayingEvaluation,
              newValue: e.target.value,
            })
          }
          placeholder="改善点(他者評価)"
          disabled={roleplayingEvaluation.isReadOnly}
        />
        <div className="flex items-center space-x-4 justify-end">
          {roleplayingEvaluation.isReadOnly ? (
            <Button
              text="編集"
              color="gray"
              outline
              className="w-full"
              onClick={handleNotReadOnly}
            />
          ) : (
            <>
              <Button
                text="削除"
                color="gray"
                outline
                className="w-full"
                onClick={handleRemove}
              />
              <FormSubmitButton
                value="保存"
                color="primary"
                className="w-full"
              />
            </>
          )}
        </div>
      </Form>
    </>
  );
};
