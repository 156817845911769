import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  documentFileId: string;
};

const request = ({ documentFileId }: RequestDataType) => {
  return new ApiClient()
    .post<ResponseMessageType>(
      `/api/members/document_files/${documentFileId}/downloads`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersDocumentFilesDownloadsCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
