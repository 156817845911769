import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiAnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndexResponseType,
  ApiAnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiAnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndexRequestType;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApiAnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiAnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndexResponseType>(
      "/api/analytics_plans/skill_evaluation_standard_summary_categories",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiAnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisAnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndex =
  ({
    params,
    config = {},
  }: PropsType = {}): UseQueryResult<ApiAnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndexResponseType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisAnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndex",
        params,
      ],
      queryFn: () => request({ params }),
    });
  };
