import React, { FC, ReactNode } from "react";

import { useBoolean } from "~/hooks";

import { GridCell } from "~/components/atoms";

type PropsType = {
  lists: (string | ReactNode)[];
  className?: string;
  openedMenu?: ReactNode;
};

export const GridRow: FC<PropsType> = ({
  lists,
  className = "",
  openedMenu,
}: PropsType) => {
  const { isChecked: open, toggle } = useBoolean(false);
  return (
    <div
      className="grid hover:bg-primary-300"
      style={{
        gridTemplateColumns: `repeat(${lists.length}, 1fr)`,
        gridColumn: `span ${lists.length} / span ${lists.length}`,
      }}
    >
      {lists.map((list, index) => (
        <GridCell
          key={`gird-cell-${index}`}
          onClick={toggle}
          className={`border-b border-solid px-3 py-2 cursor-pointer ${
            open ? "break-all" : "truncate"
          } ${className}`}
        >
          {list}
        </GridCell>
      ))}
      {open && openedMenu}
    </div>
  );
};
