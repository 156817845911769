import React, { FC } from "react";

import {
  useFooters,
  useBoolean,
  useApisMembersFocusChallengeEmployeesLikesCreate,
  useApisMembersFocusChallengeEmployeesLikesDestroy,
  useCounter,
} from "~/hooks";

import { CompletedMarkMessage } from "~/components/molecules";
import {
  PostHeader,
  PostContent,
  FocusChallengeEmployeeLikedEmployeesModal,
} from "~/components/organisms";

import { FocusChallengeEmployeeType, PostDetailMenuItemType } from "~/domains";

type PropsType = {
  focusChallengeEmployee: FocusChallengeEmployeeType;
  menuItems: PostDetailMenuItemType[];
  openReply: (focusChallengeEmployee: FocusChallengeEmployeeType) => void;
  withFooter: boolean;
};

export const FocusChallengeEmployeeDetail: FC<PropsType> = ({
  focusChallengeEmployee,
  menuItems,
  openReply,
  withFooter,
}: PropsType) => {
  const {
    id,
    focusChallenge,
    focusChallengeEmployeeEffort,
    focusChallengeEmployeeStat,
  } = focusChallengeEmployee;

  const {
    content,
    challengeStartDate,
    challengeFinishDate,
    jobCategory,
    providingService,
    jobCategoryPhase,
    attachFiles,
  } = focusChallenge;
  const isCompleted = Boolean(focusChallengeEmployeeEffort?.completedAt);

  const {
    isChecked: isLikedEmployeesModalOpen,
    setTrue: setLikedEmployeesModalOpen,
    setFalse: setLikedEmployeesModalClose,
  } = useBoolean(false);

  const {
    isChecked: liked,
    setTrue: setLikedTrue,
    setFalse: setLikedFalse,
  } = useBoolean(focusChallengeEmployee.liked);
  const {
    count: likeCount,
    increment: likeIncrement,
    decrement: likeDecrement,
  } = useCounter(focusChallengeEmployeeStat.likesCount);
  const { mutate: likePostRequest } =
    useApisMembersFocusChallengeEmployeesLikesCreate();
  const { mutate: likeDestroyRequest } =
    useApisMembersFocusChallengeEmployeesLikesDestroy();

  const handleLikePostRequest = () => {
    likePostRequest(
      {
        focusChallengeEmployeeId: id,
      },
      {
        onSuccess: () => {
          setLikedTrue();
          likeIncrement();
        },
      },
    );
  };

  const handleLikeDestroyRequest = () => {
    likeDestroyRequest(
      {
        focusChallengeEmployeeId: id,
      },
      {
        onSuccess: () => {
          setLikedFalse();
          likeDecrement();
        },
      },
    );
  };

  const leftFooters = useFooters({
    like: {
      doneAlready: liked,
      undoOnClick: handleLikeDestroyRequest,
      doOnClick: handleLikePostRequest,
      textOnClick: setLikedEmployeesModalOpen,
      count: likeCount,
      disable: false,
    },
    comment: {
      doneAlready: Boolean(focusChallengeEmployeeStat.repliesCount),
      undoOnClick: () => openReply(focusChallengeEmployee),
      doOnClick: () => openReply(focusChallengeEmployee),
      count: focusChallengeEmployeeStat.repliesCount,
      disable: false,
    },
  });

  return (
    <div>
      {isCompleted && <CompletedMarkMessage message="チャレンジ完了済み" />}
      <PostHeader
        className={isCompleted ? "mt-2" : ""}
        miniText={`期間: ${challengeStartDate}~${challengeFinishDate}`}
        postedEmployee={focusChallenge.createdByEmployee}
        menuItems={menuItems}
        underLabel={`${jobCategory.name} - ${
          providingService?.name || "すべて"
        } - ${jobCategoryPhase?.name || "すべて"}`}
      />
      <PostContent
        content={content}
        images={attachFiles}
        footers={withFooter ? leftFooters.footers : []}
        className="mt-2 pl-16"
      />
      {isLikedEmployeesModalOpen && (
        <FocusChallengeEmployeeLikedEmployeesModal
          focusChallengeEmployeeId={id}
          onClose={setLikedEmployeesModalClose}
        />
      )}
    </div>
  );
};
