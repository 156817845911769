import React, { FC } from "react";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);

type PropsType = {
  datasets: {
    name: string;
    lineValue: number;
    barValue: number;
  }[];
  barLabel: string;
  lineLabel: string;
};

export const BarAndLineChart: FC<PropsType> = ({
  barLabel,
  lineLabel,
  datasets,
}) => {
  const data = {
    labels: datasets.map((item) => item.name),
    datasets: [
      {
        type: "line" as const,
        label: lineLabel,
        backgroundColor: "#FFD740",
        borderColor: "#FFD740",
        borderWidth: 2,
        data: datasets.map((item) => item.lineValue),
      },
      {
        type: "bar" as const,
        label: barLabel,
        backgroundColor: "#00A1DF",
        data: datasets.map((item) => item.barValue),
        borderWidth: 0,
      },
    ],
  };
  return <Chart type="bar" data={data} />;
};
