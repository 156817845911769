import { MouseEvent, ChangeEvent, FormEvent, useState } from "react";

import {
  useInput,
  useApisManagersLeftEmployeesIndex,
  useArray,
  useMultipleDropdown,
  useApisManagersEmployeeSearchItemsIndex,
} from "~/hooks";

import {
  ManagersEmployeesIndexEmployeeType,
  OptionType,
  ApiManagersEmployeesIndexRequestType,
  AllRoles,
  MultiValueType,
} from "~/domains";

type ReturnType = {
  employees: ManagersEmployeesIndexEmployeeType[];

  employeesHasNextPage?: boolean;

  keyword: string;
  employeesIsLoading: boolean;

  selectableDropdownEmployeeRoleIds: OptionType[];
  selectedDropdownEmployeeRoleIds?: MultiValueType<OptionType>;
  selectableDropdownJobTitles: OptionType[];
  selectedDropdownJobTitles?: MultiValueType<OptionType>;
  selectableDropdownJobCategories: OptionType[];
  selectedDropdownJobCategories?: MultiValueType<OptionType>;
  findAndRemoveEmployee: (
    func: (item: ManagersEmployeesIndexEmployeeType) => boolean,
  ) => void;
  onChangeDropdownEmployeeRoleIds: (value: MultiValueType<OptionType>) => void;
  onChangeDropdownJobCategories: (value: MultiValueType<OptionType>) => void;
  onChangeDropdownJobTitles: (value: MultiValueType<OptionType>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  employeesFetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const useSearchManagersLeftEmployees = (): ReturnType => {
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [
    {
      dropdownValue: selectedDropdownEmployeeRoleIds,
      onChange: onChangeDropdownEmployeeRoleIds,
      dropdownSelectableValue: selectableDropdownEmployeeRoleIds,
    },
  ] = useMultipleDropdown({
    selectableValue: AllRoles.map((status) => ({
      name: status.name,
      id: status.id.toString(),
    })),
  });

  const [
    {
      dropdownValue: selectedDropdownJobCategories,
      onChange: onChangeDropdownJobCategories,
      dropdownSelectableValue: selectableDropdownJobCategories,
      setSelectableOption: setSelectableDropdownJobCategories,
    },
  ] = useMultipleDropdown();
  const [
    {
      dropdownValue: selectedDropdownJobTitles,
      onChange: onChangeDropdownJobTitles,
      dropdownSelectableValue: selectableDropdownJobTitles,
      setSelectableOption: setSelectableDropdownJobTitles,
    },
  ] = useMultipleDropdown();

  const [params, setParams] = useState<ApiManagersEmployeesIndexRequestType>();

  useApisManagersEmployeeSearchItemsIndex({
    config: {
      onSuccess: (data) => {
        setSelectableDropdownJobCategories(data.jobCategories);
        setSelectableDropdownJobTitles(
          data.jobTitles.map((jobTitle, index) => ({
            id: index.toString(),
            name: jobTitle,
          })),
        );
      },
    },
  });

  const {
    items: employees,
    setItems: setEmployees,
    findAndRemove: findAndRemoveEmployee,
  } = useArray<ManagersEmployeesIndexEmployeeType>([]);

  const {
    fetchNextPage: employeesFetchNextPage,
    hasNextPage: employeesHasNextPage,
    isFetching: employeesIsLoading,
  } = useApisManagersLeftEmployeesIndex({
    params,
    config: {
      onSuccess: (data) => {
        setEmployees(data.pages.map((page) => page.employees).flat());
      },
    },
  });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      jobTitles: selectedDropdownJobTitles.map((jobTitle) => jobTitle.label),
      jobCategoryIds: selectedDropdownJobCategories.map(
        (jobCategory) => jobCategory.value,
      ),
      employeeRoleIds: selectedDropdownEmployeeRoleIds.map(
        (role) => role.value,
      ),
    });
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownEmployeeRoleIds([]);
    onChangeDropdownJobTitles([]);
    onChangeDropdownJobCategories([]);
    setParams({});
  };

  return {
    employees,
    employeesIsLoading,
    keyword,
    employeesHasNextPage,
    selectableDropdownEmployeeRoleIds,
    selectedDropdownEmployeeRoleIds,
    selectableDropdownJobCategories,
    selectedDropdownJobCategories,
    selectableDropdownJobTitles,
    selectedDropdownJobTitles,
    findAndRemoveEmployee,
    onChangeDropdownJobCategories,
    onChangeDropdownJobTitles,
    onChangeDropdownEmployeeRoleIds,
    employeesFetchNextPage,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  };
};
