import React, { FC } from "react";

import { EditorState } from "draft-js";

import { Label } from "~/components/atoms";
import { TextEditor } from "~/components/organisms";

type PropsType = {
  labelText?: string;
  required?: boolean;
  editorState?: EditorState;
  readOnly?: boolean;
  setEditorState: (editorState: EditorState) => void;
  className?: string;
};

export const BlogEditor: FC<PropsType> = ({
  editorState = EditorState.createEmpty(),
  labelText,
  required = false,
  readOnly = false,
  setEditorState,
  className = "",
}: PropsType) => {
  return (
    <div>
      {labelText && (
        <Label htmlFor="" required={required} labelText={labelText} />
      )}
      <TextEditor
        readOnly={readOnly}
        editorState={editorState}
        setEditorState={setEditorState}
        mentions={[]}
        required={required}
        files={[]}
        className={`mt-1.5 ${className}`}
        canFileInText
      />
    </div>
  );
};
