import { useCallback, useState } from "react";

import { useProvidersEmployeeLayout } from "~/hooks";

type ReturnType = {
  isOpen: boolean;
  isReplyOpen: boolean;
  isSearchOpen: boolean;
  isCalendarOpen: boolean;
  currentOpenLabel: "検索条件" | "リプライ" | "Information" | "";
  value: RightSidebarStateType;
  setClose: () => void;
  setSearchOpen: () => void;
  setCalendarOpen: () => void;
  setReplyOpen: () => void;
  searchOpenToggle: () => void;
  searchCalendarOpenToggle: () => void;
  handleChange: (value: RightSidebarStateType) => void;
};

type RightSidebarStateType =
  | "isSearchOpen"
  | "isReplyOpen"
  | "isClose"
  | "isCalendarOpen";

export const useRightSidebarState = (
  initial: RightSidebarStateType = "isClose",
): ReturnType => {
  const { setTrueRightSidebarMain } = useProvidersEmployeeLayout();

  const [rightSidebarState, setRightSidebarState] =
    useState<RightSidebarStateType>(initial);

  const isSearchOpen = rightSidebarState === "isSearchOpen";
  const isReplyOpen = rightSidebarState === "isReplyOpen";
  const isCalendarOpen = rightSidebarState === "isCalendarOpen";
  const isOpen = rightSidebarState !== "isClose";

  const currentOpenLabel = isSearchOpen
    ? "検索条件"
    : isReplyOpen
      ? "リプライ"
      : isCalendarOpen
        ? "Information"
        : "";

  const setClose = useCallback(() => {
    setRightSidebarState("isClose");
  }, []);

  const setCalendarOpen = useCallback(() => {
    setRightSidebarState("isCalendarOpen");
  }, []);

  const setSearchOpen = useCallback(
    () => setRightSidebarState("isSearchOpen"),
    [],
  );
  const setReplyOpen = useCallback(() => {
    setRightSidebarState("isReplyOpen");
    setTrueRightSidebarMain();
  }, [setTrueRightSidebarMain]);

  const searchOpenToggle = useCallback(
    () =>
      setRightSidebarState(() => {
        return isSearchOpen ? "isClose" : "isSearchOpen";
      }),
    [isSearchOpen],
  );

  const searchCalendarOpenToggle = useCallback(
    () =>
      setRightSidebarState(() => {
        return isSearchOpen ? "isCalendarOpen" : "isSearchOpen";
      }),
    [isSearchOpen],
  );

  const handleChange = useCallback(
    (value: RightSidebarStateType) => setRightSidebarState(value),
    [],
  );

  return {
    isSearchOpen,
    isReplyOpen,
    isCalendarOpen,
    isOpen,
    currentOpenLabel,
    value: rightSidebarState,
    setClose,
    setSearchOpen,
    handleChange,
    setReplyOpen,
    setCalendarOpen,
    searchOpenToggle,
    searchCalendarOpenToggle,
  };
};
