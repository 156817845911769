import {
  BusinessDivisionType,
  BusinessSectionType,
  RawDraftContentState,
  PublicStatusType,
  AvatarAndNameEmployeeType,
} from "~/domains";

export type InsightFocusKaizenContentTemplateType = {
  id: string;
  businessDivisions: BusinessDivisionType[];
  businessSections: BusinessSectionType[];
  title: string;
  content: RawDraftContentState;
  publicStatus: PublicStatusType;
  insightFocusKaizenContentType: InsightFocusKaizenContentType;
  allBusinessSectionsDistribution: boolean;
  createdByEmployee: AvatarAndNameEmployeeType;
  createdAt: string;
  updatedAt: string;
};

export type InsightFocusKaizenContentType =
  | typeof InsightPostType
  | typeof FocusChallengeType
  | typeof KaizenIdeaType;

export const InsightPostType = {
  id: 1,
  name: "インサイト",
  type: "InsightPost",
} as const satisfies StatusType;

export const FocusChallengeType = {
  id: 10,
  name: "チャレンジ",
  type: "FocusChallenge",
} as const satisfies StatusType;

export const KaizenIdeaType = {
  id: 20,
  name: "改善・相談",
  type: "KaizenIdea",
} as const satisfies StatusType;

type StatusType = {
  id: 1 | 10 | 20;
  name: "インサイト" | "チャレンジ" | "改善・相談";
  type: "InsightPost" | "FocusChallenge" | "KaizenIdea";
};

export const AllInsightFocusKaizenContentTypes = [
  InsightPostType,
  FocusChallengeType,
  KaizenIdeaType,
];
