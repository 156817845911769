import React, { FC, FormEvent, ChangeEvent, MouseEvent } from "react";

import {
  Form,
  FormSubmitButton,
  TextField,
  MultipleDropDownField,
  Button,
  DropDownField,
} from "~/components/atoms";
import {
  LabelWithCheckBox,
  RangeDatePropsType,
  TitleWithChild,
} from "~/components/molecules";
import {
  DivisionAndSectionAndEmployeesDropdownField,
  MultiParentsWithMultiChildrenDropdownField,
  SelectDateRangeButtons,
} from "~/components/organisms";

import {
  OptionType,
  MultiValueType,
  ChildType,
  ParentType,
  SingleValueType,
} from "~/domains";

type PropsType = {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  date: {
    startDate: Date | null;
    endDate: Date | null;
    onChange: (value: RangeDatePropsType) => void;
  };
  providingService: {
    options: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
  jobCategoryAndJobCategoryPhase: {
    selectableJobCategories: ParentType[];
    selectedJobCategories: ParentType[];
    onJobCategoryChange: (newValue: ParentType[]) => void;
    selectableJobCategoryPhases: ChildType[];
    selectedJobCategoryPhases: ChildType[];
    onJobCategoryPhaseChange: (newValue: ChildType[]) => void;
  };
  isBelongingEmployee?: {
    isChecked: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  divisionAndSections?: {
    selectableDivisions: ParentType[];
    selectedDivisions: ParentType[];
    onDivisionChange: (newValue: ParentType[]) => void;
    selectableSections: ChildType[];
    selectedSection: ChildType[];
    onSectionChange: (newValue: ChildType[]) => void;
    selectableEmployees: ParentType[];
    selectedEmployees: ParentType[];
    onEmployeesChange: (newValue: ParentType[]) => void;
    selectableDropdownArchivedEmployees: ParentType[];
    selectedDropdownArchivedEmployees: ParentType[];
    onArchivedEmployeesChange: (value: ParentType[]) => void;
  };
  employee?: {
    options: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
  hasComment: {
    options: OptionType[];
    value: SingleValueType<OptionType> | undefined;
    onChange: (newValue: SingleValueType<OptionType>) => void;
  };
  hasBookmark?: {
    options: OptionType[];
    value: SingleValueType<OptionType> | undefined;
    onChange: (newValue: SingleValueType<OptionType>) => void;
  };
  shared?: {
    options: OptionType[];
    value: SingleValueType<OptionType> | undefined;
    onChange: (newValue: SingleValueType<OptionType>) => void;
  };
  searchKeyword: {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
};

export const InsightPostSearchForm: FC<PropsType> = ({
  onSubmit,
  onConditionReset,
  date,
  providingService,
  jobCategoryAndJobCategoryPhase,
  isBelongingEmployee,
  divisionAndSections,
  employee,
  hasComment,
  hasBookmark,
  shared,
  searchKeyword,
}: PropsType) => {
  return (
    <Form onSubmit={onSubmit} className="mt-6 space-y-6">
      <>
        <TitleWithChild title="期間指定">
          <SelectDateRangeButtons
            startDate={date.startDate}
            endDate={date.endDate}
            onChange={date.onChange}
            className="mt-3.5"
          />
        </TitleWithChild>
        {divisionAndSections && (
          <TitleWithChild title="部署・課・ユーザー" className="mt-2 space-y-6">
            <DivisionAndSectionAndEmployeesDropdownField
              selectableDropdownDivisions={
                divisionAndSections.selectableDivisions
              }
              selectedDropdownDivisions={divisionAndSections.selectedDivisions}
              onDivisionChange={divisionAndSections.onDivisionChange}
              selectableDropdownSections={
                divisionAndSections.selectableSections
              }
              selectedDropdownSections={divisionAndSections.selectedSection}
              onSectionChange={divisionAndSections.onSectionChange}
              selectableDropdownEmployees={
                divisionAndSections.selectableEmployees
              }
              selectedDropdownEmployees={divisionAndSections.selectedEmployees}
              onEmployeesChange={divisionAndSections.onEmployeesChange}
              selectableDropdownArchivedEmployees={
                divisionAndSections.selectableDropdownArchivedEmployees
              }
              selectedDropdownArchivedEmployees={
                divisionAndSections.selectedDropdownArchivedEmployees
              }
              onArchivedEmployeesChange={
                divisionAndSections.onArchivedEmployeesChange
              }
            />
          </TitleWithChild>
        )}
        {employee && (
          <TitleWithChild title="ユーザー">
            <MultipleDropDownField
              name="employee"
              options={employee.options}
              value={employee.value}
              onChange={employee.onChange}
            />
          </TitleWithChild>
        )}
        <TitleWithChild title="サービス">
          <MultipleDropDownField
            name="providingService"
            options={providingService.options}
            value={providingService.value}
            onChange={providingService.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="職種・インサイト項目" className="mt-2 space-y-6">
          <MultiParentsWithMultiChildrenDropdownField
            allParents={jobCategoryAndJobCategoryPhase.selectableJobCategories}
            parentsValue={jobCategoryAndJobCategoryPhase.selectedJobCategories}
            parentLabel="職種"
            parentsOnChange={jobCategoryAndJobCategoryPhase.onJobCategoryChange}
            allChildren={
              jobCategoryAndJobCategoryPhase.selectableJobCategoryPhases
            }
            childrenValue={
              jobCategoryAndJobCategoryPhase.selectedJobCategoryPhases
            }
            childLabel="インサイト項目"
            childrenOnChange={
              jobCategoryAndJobCategoryPhase.onJobCategoryPhaseChange
            }
          />
        </TitleWithChild>
        <TitleWithChild title="リプライ">
          <DropDownField
            name="hasComment"
            options={hasComment.options}
            value={hasComment.value}
            onChange={hasComment.onChange}
          />
        </TitleWithChild>
        {hasBookmark && (
          <TitleWithChild title="ブックマーク">
            <DropDownField
              name="hasBookmark"
              options={hasBookmark.options}
              value={hasBookmark.value}
              onChange={hasBookmark.onChange}
            />
          </TitleWithChild>
        )}
        {shared && (
          <TitleWithChild title="ナレッジシェア">
            <DropDownField
              name="shared"
              options={shared.options}
              value={shared.value}
              onChange={shared.onChange}
            />
          </TitleWithChild>
        )}
        {isBelongingEmployee && (
          <TitleWithChild title="詳細条件">
            <LabelWithCheckBox
              labelText="ユーザー紐付き"
              labelEnd
              checked={isBelongingEmployee.isChecked}
              name="hasBookmark"
              onChange={isBelongingEmployee.onChange}
            />
          </TitleWithChild>
        )}
        <TitleWithChild title="フリーワード">
          <TextField
            value={searchKeyword.value}
            placeholder="キーワード"
            type="search"
            name="keyword"
            onChange={searchKeyword.onChange}
          />
        </TitleWithChild>
        <div className="space-x-4 flex">
          <Button
            text="リセット"
            color="gray"
            outline
            className="w-full"
            onClick={onConditionReset}
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full"
          />
        </div>
      </>
    </Form>
  );
};
