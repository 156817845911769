import {
  useOpenAiRequest,
  useApisMembersAiChatRoomsAiEmployeeConversationsCreate,
} from "~/hooks";

import {
  AiEmployeeConversationSender,
  AiEmployeeConversationType,
  ApiMembersAiChatRoomsAiEmployeeConversationsMutateResponseType,
} from "~/domains";

type ApiRequestType = {
  message: string;
  systemMessage?: string;
  aiChatRoomId: string;
  alreadyMessages?: AiEmployeeConversationType[];
  onSuccess?: (
    response: ApiMembersAiChatRoomsAiEmployeeConversationsMutateResponseType,
  ) => void;
  sendMessageOnSuccess?: (
    response: ApiMembersAiChatRoomsAiEmployeeConversationsMutateResponseType,
  ) => void;
};

type ReturnType = {
  aiChatConversationRequest: ({
    message,
    systemMessage,
    alreadyMessages,
    aiChatRoomId,
  }: ApiRequestType) => void;
  isEmployeeSubmitting: boolean;
  isChatRequesting: boolean;
};

export const useAiChatConversationRequest = (
  currentEmployeeId: string,
): ReturnType => {
  const { openAiRequest, isChatRequesting } =
    useOpenAiRequest(currentEmployeeId);
  const {
    mutate: postConversationRequest,
    isLoading: isPostConversationSubmitting,
  } = useApisMembersAiChatRoomsAiEmployeeConversationsCreate();

  const aiChatConversationRequest = ({
    message,
    systemMessage,
    aiChatRoomId,
    alreadyMessages = [],
    onSuccess,
    sendMessageOnSuccess,
  }: ApiRequestType) => {
    postConversationRequest(
      {
        aiChatRoomId,
        body: {
          message,
          senderTypeId: AiEmployeeConversationSender.user.id,
        },
      },
      {
        onSuccess: async (conversationResponse) => {
          sendMessageOnSuccess && sendMessageOnSuccess(conversationResponse);

          // NOTE: 既存のメッセージを一時的に逆順にして、最新のメッセージを最後に追加する
          const formattedAlreadyMessages = [...alreadyMessages]
            .reverse()
            .map((alreadyMessage) => ({
              content: alreadyMessage.message,
              role: alreadyMessage.senderType.type,
            }));
          await openAiRequest({
            prompt: message,
            systemMessage,
            alreadyMessages: formattedAlreadyMessages,
            onSuccess: ({ chatMessage, data: openAiResponse }) => {
              const message =
                typeof chatMessage.content === "string"
                  ? chatMessage.content.trim()
                  : "";
              postConversationRequest(
                {
                  aiChatRoomId,
                  body: {
                    message: message,
                    senderTypeId:
                      AiEmployeeConversationSender[
                        chatMessage.role as keyof typeof AiEmployeeConversationSender
                      ].id,
                    aiResponseMetadata: openAiResponse,
                  },
                },
                {
                  onSuccess: (aiResponseSave) => {
                    onSuccess && onSuccess(aiResponseSave);
                  },
                },
              );
            },
          });
        },
      },
    );
  };

  return {
    aiChatConversationRequest,
    isEmployeeSubmitting: isPostConversationSubmitting,
    isChatRequesting,
  };
};
