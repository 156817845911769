import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import {
  MemberInsightPostsMutateType,
  PreviewWithFileType,
  RawDraftContentState,
} from "~/domains";

type RequestDataType = {
  body: {
    content: RawDraftContentState;
    contentText: string;
    postDate: string;
    shareable: boolean;
    attachFiles: PreviewWithFileType[];
    providingServiceId: string | undefined;
    jobCategoryPhaseId: string | undefined;
    quotePostId?: string;
  };
};

const request = ({ body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ insightPost: body });
  return new ApiClient()
    .post<MemberInsightPostsMutateType>("/api/members/insight_posts", formData)
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersInsightPostsCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  MemberInsightPostsMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
