import React, { FC, ReactNode } from "react";

type PropsType = {
  className?: string;
  children: ReactNode;
};

export const SignInWrapper: FC<PropsType> = ({
  className = "",
  children,
}: PropsType) => {
  return (
    <>
      <div
        className={`flex items-center justify-center h-dvh bg-cover bg-no-repeat signInWrapper--background-image ${className}`}
      >
        {children}
      </div>
    </>
  );
};
