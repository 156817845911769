import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  archivedJobCategoryId: string;
};

const request = ({ archivedJobCategoryId }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseMessageType>(
      `/api/managers/archived_job_categories/${archivedJobCategoryId}/restores`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersArchivedJobCategoriesRestoresUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
