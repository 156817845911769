import React, { FC } from "react";

import { DmLink } from "./DmLink";

import { EmployeeRole, EmployeeRoleType } from "~/domains";

type PropsType = {
  className?: string;
  businessSectionNames: string[];
  employeeName: string;
  employeeId: string;
  jobCategoryName: string;
  employeeRole: EmployeeRoleType;
  avatarUrl: string;
  jobTitle?: string;
};

export const AvatarCard: FC<PropsType> = ({
  className = "",
  employeeId,
  employeeName,
  businessSectionNames,
  avatarUrl,
  employeeRole,
  jobCategoryName,
  jobTitle,
}: PropsType) => {
  const isOwner = employeeRole.id === EmployeeRole.OWNER.id;
  return (
    <div
      className={`rounded shadow max-h-96 w-56 overflow-y-auto hidden-scrollbar bg-white ${className}`}
    >
      <div
        className="relative rounded-t h-56 w-full bg-cover bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${avatarUrl})` }}
      >
        <p className="w-full px-2 py-1 truncate absolute bottom-0 bg-primary-600 opacity-80 text-sm text-white font-semibold">
          {jobCategoryName}
        </p>
      </div>
      <div className="mt-3 mb-7 mx-2">
        <div className="space-y-1">
          <p className="font-bold truncate">{employeeName}</p>
          <p className="text-xs">{employeeRole.name}</p>
          <p className="text-xs">
            {isOwner ? "すべて" : businessSectionNames.join(" / ")}
          </p>
          {jobTitle && <p className="text-xs">{jobTitle}</p>}
        </div>
        <DmLink employeeId={employeeId} />
      </div>
    </div>
  );
};
