import React, { CSSProperties, ReactNode, FC } from "react";

type PropsType = {
  children: ReactNode;
  text: string;
  className?: string;
  textClassName?: string;
  textStyle?: CSSProperties;
};

export const HoverTooltip: FC<PropsType> = ({
  text,
  children,
  className = "",
  textClassName = "",
  textStyle = {},
}: PropsType) => {
  return (
    <span className={`relative group ${className}`}>
      <span
        className={`
          block
          bg-black
          px-2
          py-1
          z-modal
          text-center
          text-white
          absolute
          transition
          rounded-3xl
          pointer-events-none
          opacity-0
          bottom-[105%]
          before:content-['']
          before:absolute
          before:rotate-270
          before:right-1/2
          before:top-full
          before:border-4
          before:border-transparent
          before:border-t-black
          group-hover:opacity-75
          ${textClassName}
          `}
        style={textStyle}
      >
        {text}
      </span>
      {children}
    </span>
  );
};
