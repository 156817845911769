import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersInsightPostSearchItemsIndexResponseType } from "~/domains";

const request =
  async (): Promise<ApiMembersInsightPostSearchItemsIndexResponseType> => {
    const response =
      await new ApiClient().get<ApiMembersInsightPostSearchItemsIndexResponseType>(
        "/api/members/insight_post_search_items",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersInsightPostSearchItemsIndex = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiMembersInsightPostSearchItemsIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersInsightPostSearchItemsIndex"],
    queryFn: () => request(),
  });
};
