import { MouseEvent, ChangeEvent, FormEvent, useState } from "react";

import {
  useInput,
  useApisManagersEmployeesIndex,
  useArray,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
  useMultipleDropdown,
  useApisManagersUnauthorizedEmployeesIndex,
  useApisManagersEmployeeSearchItemsIndex,
} from "~/hooks";

import {
  ManagersEmployeesIndexEmployeeType,
  OptionType,
  ApiManagersEmployeesIndexRequestType,
  AllRoles,
  BusinessDivisionType,
  ChildType,
  ParentType,
  MultiValueType,
} from "~/domains";

type ReturnType = {
  employees: ManagersEmployeesIndexEmployeeType[];
  unauthorizedEmployees: ManagersEmployeesIndexEmployeeType[];
  employeesHasNextPage?: boolean;
  unauthorizedEmployeesHasNextPage?: boolean;
  keyword: string;
  employeesIsLoading: boolean;
  unauthorizedEmployeesIsLoading: boolean;
  selectableDropdownEmployeeRoleIds: OptionType[];
  selectedDropdownEmployeeRoleIds?: MultiValueType<OptionType>;
  selectableDropdownJobTitles: OptionType[];
  selectedDropdownJobTitles?: MultiValueType<OptionType>;
  selectableDropdownJobCategories: OptionType[];
  selectedDropdownJobCategories?: MultiValueType<OptionType>;
  selectableDropdownDivisions: BusinessDivisionType[];
  selectedDropdownDivisions: BusinessDivisionType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  findAndRemoveEmployee: (
    func: (item: ManagersEmployeesIndexEmployeeType) => boolean,
  ) => void;
  findAndRemoveUnauthorizedEmployee: (
    func: (item: ManagersEmployeesIndexEmployeeType) => boolean,
  ) => void;
  onChangeDropdownEmployeeRoleIds: (value: MultiValueType<OptionType>) => void;
  onChangeDropdownJobCategories: (value: MultiValueType<OptionType>) => void;
  onChangeDropdownJobTitles: (value: MultiValueType<OptionType>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  employeesFetchNextPage: () => void;
  unauthorizedEmployeesFetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const useSearchManagersEmployees = (): ReturnType => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [
    {
      dropdownValue: selectedDropdownEmployeeRoleIds,
      onChange: onChangeDropdownEmployeeRoleIds,
      dropdownSelectableValue: selectableDropdownEmployeeRoleIds,
    },
  ] = useMultipleDropdown({
    selectableValue: AllRoles.map((status) => ({
      name: status.name,
      id: status.id.toString(),
    })),
  });

  const [
    {
      dropdownValue: selectedDropdownJobCategories,
      onChange: onChangeDropdownJobCategories,
      dropdownSelectableValue: selectableDropdownJobCategories,
      setSelectableOption: setSelectableDropdownJobCategories,
    },
  ] = useMultipleDropdown();
  const [
    {
      dropdownValue: selectedDropdownJobTitles,
      onChange: onChangeDropdownJobTitles,
      dropdownSelectableValue: selectableDropdownJobTitles,
      setSelectableOption: setSelectableDropdownJobTitles,
    },
  ] = useMultipleDropdown();

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });

  const [params, setParams] = useState<ApiManagersEmployeesIndexRequestType>();

  useApisManagersEmployeeSearchItemsIndex({
    config: {
      onSuccess: (data) => {
        setSelectableDropdownJobCategories(data.jobCategories);
        setSelectableDropdownJobTitles(
          data.jobTitles.map((jobTitle, index) => ({
            id: index.toString(),
            name: jobTitle,
          })),
        );
      },
    },
  });

  const {
    items: employees,
    setItems: setEmployees,
    findAndRemove: findAndRemoveEmployee,
  } = useArray<ManagersEmployeesIndexEmployeeType>([]);
  const {
    items: unauthorizedEmployees,
    setItems: setUnauthorizedEmployees,
    findAndRemove: findAndRemoveUnauthorizedEmployee,
  } = useArray<ManagersEmployeesIndexEmployeeType>([]);

  const {
    fetchNextPage: employeesFetchNextPage,
    hasNextPage: employeesHasNextPage,
    isFetching: employeesIsLoading,
  } = useApisManagersEmployeesIndex({
    params,
    config: {
      onSuccess: (data) => {
        setEmployees(data.pages.map((page) => page.employees).flat());
      },
    },
  });
  const {
    fetchNextPage: unauthorizedEmployeesFetchNextPage,
    hasNextPage: unauthorizedEmployeesHasNextPage,
    isFetching: unauthorizedEmployeesIsLoading,
  } = useApisManagersUnauthorizedEmployeesIndex({
    params,
    config: {
      onSuccess: (data) => {
        setUnauthorizedEmployees(
          data.pages.map((page) => page.employees).flat(),
        );
      },
    },
  });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      jobTitles: selectedDropdownJobTitles.map((jobTitle) => jobTitle.label),
      jobCategoryIds: selectedDropdownJobCategories.map(
        (jobCategory) => jobCategory.value,
      ),
      businessDivisionIds: selectedDivisions.map((division) => division.id),
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      employeeRoleIds: selectedDropdownEmployeeRoleIds.map(
        (role) => role.value,
      ),
    });
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownEmployeeRoleIds([]);
    onDivisionChange([]);
    onSectionChange([]);
    onChangeDropdownJobTitles([]);
    onChangeDropdownJobCategories([]);
    setParams({});
  };

  return {
    employees,
    unauthorizedEmployees,
    employeesIsLoading,
    unauthorizedEmployeesIsLoading,
    keyword,
    employeesHasNextPage,
    unauthorizedEmployeesHasNextPage,
    selectableDropdownEmployeeRoleIds,
    selectedDropdownEmployeeRoleIds,
    selectableDropdownJobCategories,
    selectedDropdownJobCategories,
    selectableDropdownJobTitles,
    selectedDropdownJobTitles,
    selectableDropdownDivisions: selectableDivisions,
    selectedDropdownDivisions: selectedDivisions,
    selectableDropdownSections: optionSelectableSections,
    selectedDropdownSections: optionSelectedSections,
    onDivisionChange,
    onSectionChange,
    findAndRemoveUnauthorizedEmployee,
    findAndRemoveEmployee,
    onChangeDropdownJobCategories,
    onChangeDropdownJobTitles,
    onChangeDropdownEmployeeRoleIds,
    employeesFetchNextPage,
    unauthorizedEmployeesFetchNextPage,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  };
};
