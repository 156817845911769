import React, { FC } from "react";

import { DoughnutChart } from "~/components/atoms";
import { Loading } from "~/components/molecules";

import { AnalysisPostRateType } from "~/domains";

type PropsType = {
  isLoading: boolean;
  title: string;
  unitName?: string;
  doughnut?: AnalysisPostRateType;
  className?: string;
};

export const DoughnutChartBox: FC<PropsType> = ({
  isLoading,
  title,
  doughnut,
  unitName,
  className = "",
}: PropsType) => {
  return (
    <div className={`max-w-full ${className}`}>
      <p className="font-semibold text-primary-600 text-lg text-center">
        {title}
      </p>
      <div className="shadow mt-4 px-16 pb-5 pt-4 rounded-md h-80 flex items-center justify-center text-center">
        {isLoading ? (
          <Loading />
        ) : (
          doughnut && (
            <div className="max-w-full">
              <DoughnutChart value={doughnut.rate} />
              <p className="text-2xl space-x-2 mt-4">
                <span>{doughnut.numerator}</span>
                <span>/</span>
                <span>{doughnut.denominator}</span>
                {unitName && <span className="text-base">{unitName}</span>}
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
};
