import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersBusinessSectionsSkillEvaluationStandardSummaryCategoryIndexType } from "~/domains";

export type ApiMembersSkillEvaluationStandardSummaryCategoryIndexParamsType = {
  keyword?: string;
  employeeRoleId?: number;
};

type RequestDataType = {
  params?: ApiMembersSkillEvaluationStandardSummaryCategoryIndexParamsType;
  page?: number;
  businessSectionId: string;
};

const request = async ({
  businessSectionId,
  params,
  page = 1,
}: RequestDataType): Promise<ApiMembersBusinessSectionsSkillEvaluationStandardSummaryCategoryIndexType> => {
  const response =
    await new ApiClient().get<ApiMembersBusinessSectionsSkillEvaluationStandardSummaryCategoryIndexType>(
      `/api/members/business_sections/${businessSectionId}/skill_evaluation_standard_summary_categories`,
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiMembersSkillEvaluationStandardSummaryCategoryIndexParamsType;
  businessSectionId: string;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersBusinessSectionsSkillEvaluationStandardSummaryCategoriesIndex =
  ({
    params,
    businessSectionId,
    config = {},
  }: PropsType): UseInfiniteQueryResult<ApiMembersBusinessSectionsSkillEvaluationStandardSummaryCategoryIndexType> => {
    return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
      [
        "ApisMembersBusinessSectionsSkillEvaluationStandardSummaryCategoriesIndex",
        businessSectionId,
        params,
      ],
      ({ pageParam }) => {
        return request({
          businessSectionId,
          params,
          page: pageParam as number | undefined,
        });
      },
      {
        ...config,
        getNextPageParam: (data) => {
          return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
        },
      },
    );
  };
