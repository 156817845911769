import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

type ResponseType = {
  email: string;
};

const request = async (): Promise<ResponseType> => {
  const response = await new ApiClient().get<ResponseType>(
    "/api/members/profile/current_email",
  );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersProfileCurrentEmailShow = ({
  config = {},
}: PropsType = {}): UseQueryResult<ResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersProfileCurrentEmailShow"],
    queryFn: request,
    cacheTime: 0,
  });
};
