import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersKnowledgeInsightPostsIndexType,
  SnakeCaseSortType,
} from "~/domains";

export type ApiManagersKnowledgeInsightPostsIndexParamsType = {
  keyword?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  hasComment?: boolean;
  hasBookmark?: boolean;
  employeeIds?: string[];
  employeeRoleId?: number;
  sortType?: SnakeCaseSortType;
};

type RequestDataType = {
  params?: ApiManagersKnowledgeInsightPostsIndexParamsType;
  page?: number;
};

const request = async ({
  params,
  page = 1,
}: RequestDataType): Promise<ApiManagersKnowledgeInsightPostsIndexType> => {
  const response =
    await new ApiClient().get<ApiManagersKnowledgeInsightPostsIndexType>(
      "/api/managers/knowledge_insight_posts",
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiManagersKnowledgeInsightPostsIndexParamsType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisManagersKnowledgeInsightPostsIndex = ({
  params,
  config = {},
}: PropsType = {}): UseInfiniteQueryResult<ApiManagersKnowledgeInsightPostsIndexType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApisManagersKnowledgeInsightPostsIndex", params],
    ({ pageParam }) => {
      return request({ params, page: pageParam as number | undefined });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
