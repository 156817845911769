import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiPrivateQuestionAnswersMutate } from "~/domains";

type RequestDataType = {
  body: {
    questionContent: string;
    answerContent: string;
    useScene?: string;
    importantPoint?: string;
    remark?: string;
    link?: string;
    providingServiceId: string;
  };
};

const request = ({ body }: RequestDataType) => {
  return new ApiClient()
    .post<ApiPrivateQuestionAnswersMutate>(
      "/api/members/private_question_answers",
      {
        privateQuestionAnswer: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersPrivateQuestionAnswersCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiPrivateQuestionAnswersMutate,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
