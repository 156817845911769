import React, { FormEvent, useState, FC } from "react";

import { toast } from "react-toastify";

import {
  useDropdown,
  useEditorState,
  useInput,
  useCounter,
  useRightSidebarState,
  useApisMembersKaizenIdeasNew,
  useApisMembersKaizenIdeasCreate,
  useSearchMembersKaizenIdeas,
  useApisMembersKaizenIdeasKaizenIdeaRepliesIndex,
  useInterval,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  FilterIconWithSortMenu,
  InfiniteScroll,
  RightSidebarToggleIconWithLabel,
  TemplateSelectButtonWithModal,
} from "~/components/molecules";
import {
  KaizenIdeaForm,
  KaizenIdeaDetailWithEditForm,
  KaizenIdeaSearchForm,
  KaizenIdeasCountCalendar,
  KaizenIdeaRepliesWithKaizenIdea,
} from "~/components/organisms";

import {
  PreviewWithFileType,
  KaizenIdeaType,
  InsightFocusKaizenContentTemplateType,
} from "~/domains";

export const MembersKaizenIdeasIndex: FC = () => {
  const [editorState, setEditorState] = useEditorState();
  const [selectedTemplate, setSelectedTemplate] =
    useState<
      Pick<InsightFocusKaizenContentTemplateType, "id" | "title" | "content">
    >();
  const [title, setTitle] = useInput("");
  const [clientCompany, setClientCompany] = useInput("");
  const priorityRating = useCounter(0);
  const {
    isSearchOpen,
    isReplyOpen,
    setReplyOpen,
    searchCalendarOpenToggle,
    setCalendarOpen,
    isOpen,
    isCalendarOpen,
    currentOpenLabel,
  } = useRightSidebarState("isCalendarOpen");
  const [selectGetReplyKaizenIdeaId, setSelectGetReplyKaizenIdeaId] =
    useState<string>("");

  const { data: kaizenIdeaRepliesIndexData, refetch: repliesRefetch } =
    useApisMembersKaizenIdeasKaizenIdeaRepliesIndex({
      kaizenIdeaId: selectGetReplyKaizenIdeaId,
      config: {
        enabled: Boolean(selectGetReplyKaizenIdeaId),
      },
    });

  useInterval({
    onUpdate: async () => {
      isReplyOpen && selectGetReplyKaizenIdeaId && (await repliesRefetch());
    },
    intervalMs: 1000,
  });

  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const { data: fetchNewData } = useApisMembersKaizenIdeasNew();
  const { mutate: createRequest, isLoading: isSubmitting } =
    useApisMembersKaizenIdeasCreate();
  const [providingService, setProvidingService] = useDropdown({
    selectableValue: fetchNewData?.providingServices,
  });

  const {
    isLoading,
    kaizenIdeas,
    keyword,
    selectableDropdownCompleteStatusIds,
    selectedDropdownCompleteStatusId,
    selectableDropdownIsFromClientIds,
    selectedDropdownIsFromClientId,
    hasNextPage,
    startDate,
    endDate,
    sort,
    selectableDropdownPriorityRatings,
    selectedDropdownPriorityRatings,
    selectableDropdownProvidingServices,
    selectedDropdownProvidingServices,
    unshiftKaizenIdea,
    updateIndexKaizenIdea,
    deleteIndexKaizenIdea,
    fetchNextPage,
    setSort,
    onChangeDropdownPriorityRatings,
    onChangeDropdownProvidingServices,
    onChangeDateRange,
    onChangeKeyword,
    onChangeDropdownCompleteStatusId,
    onChangeDropdownIsFromClientId,
    onSearchSubmit,
    onConditionReset,
  } = useSearchMembersKaizenIdeas();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!editorState.hasText) {
      toast.error("テキストが空です");
      return;
    }

    const body = {
      title: title.value,
      content: editorState.jsonContent,
      clientCompanyName: clientCompany.value,
      providingServiceId: providingService.value?.id,
      priorityRatingId: priorityRating.count,
      attachFiles: files,
    };

    createRequest(
      {
        body,
      },
      {
        onSuccess: (data) => {
          toast(data?.message);
          formReset();
          unshiftKaizenIdea(data.kaizenIdea);
        },
      },
    );
  };

  const formReset = () => {
    setFiles([]);
    editorState.setPlainText("");
    priorityRating.setValue(0);
    setSelectedTemplate(undefined);
    setTitle("");
    setClientCompany("");
    setProvidingService();
  };

  const openComment = (KaizenIdeaId: string) => {
    setSelectGetReplyKaizenIdeaId(KaizenIdeaId);
    setReplyOpen();
  };

  const breadCrumbItems = [{ label: "改善・相談", href: "/kaizen_ideas" }];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
        {!isSearchOpen && fetchNewData && (
          <>
            <TemplateSelectButtonWithModal
              templates={fetchNewData?.insightFocusKaizenContentTemplates || []}
              setEditorState={setEditorState}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              className="mt-6"
            />
            <KaizenIdeaForm
              isSubmitting={isSubmitting}
              mentions={fetchNewData.mentionableEmployees}
              files={files}
              onChangeFiles={setFiles}
              editorState={{
                value: editorState.value,
                onChange: editorState.onChange,
              }}
              providingService={{
                selectableValue: providingService.dropdownSelectableValue,
                selected: providingService.dropdownValue,
                onChange: providingService.onChange,
              }}
              priorityRating={{
                value: priorityRating.count,
                onChange: priorityRating.setValue,
              }}
              handleSubmit={onSubmit}
              clientCompany={{
                value: clientCompany.value,
                onChange: clientCompany.onChange,
              }}
              title={{
                value: title.value,
                onChange: title.onChange,
              }}
              className="mt-6"
            />
          </>
        )}
        <FilterIconWithSortMenu
          likeSort={() => setSort("likeCountDesc")}
          createdAtAscSort={() => setSort("createdAtAsc")}
          createdAtDescSort={() => setSort("createdAtDesc")}
          currentSortType={sort}
          className={isSearchOpen ? "mt-6" : "mt-12"}
        />
        <InfiniteScroll
          itemsLength={kaizenIdeas.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isLoading}
          className="border-t-2 border-secondary-200"
        >
          {kaizenIdeas.map((post, index) => (
            <KaizenIdeaDetailWithEditForm
              key={post.id}
              kaizenIdea={post}
              openComment={openComment}
              updateItem={(newItem: KaizenIdeaType) =>
                updateIndexKaizenIdea(newItem, index)
              }
              deleteItem={() => deleteIndexKaizenIdea(index)}
            />
          ))}
        </InfiniteScroll>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          label={currentOpenLabel}
          iconOnClick={searchCalendarOpenToggle}
          handleOnClose={isCalendarOpen ? undefined : setCalendarOpen}
          isOpen={isOpen}
        >
          {isCalendarOpen && <KaizenIdeasCountCalendar className="mt-6" />}
          {isSearchOpen && (
            <KaizenIdeaSearchForm
              onSubmit={onSearchSubmit}
              onConditionReset={onConditionReset}
              date={{
                startDate: startDate,
                endDate: endDate,
                onChange: onChangeDateRange,
              }}
              providingService={{
                options: selectableDropdownProvidingServices,
                value: selectedDropdownProvidingServices,
                onChange: onChangeDropdownProvidingServices,
              }}
              priorityRating={{
                options: selectableDropdownPriorityRatings,
                value: selectedDropdownPriorityRatings,
                onChange: onChangeDropdownPriorityRatings,
              }}
              kaizenCompleted={{
                options: selectableDropdownCompleteStatusIds,
                value: selectedDropdownCompleteStatusId,
                onChange: onChangeDropdownCompleteStatusId,
              }}
              isFromClient={{
                options: selectableDropdownIsFromClientIds,
                value: selectedDropdownIsFromClientId,
                onChange: onChangeDropdownIsFromClientId,
              }}
              searchKeyword={{
                value: keyword,
                onChange: onChangeKeyword,
              }}
            />
          )}
          {isReplyOpen && kaizenIdeaRepliesIndexData && (
            <KaizenIdeaRepliesWithKaizenIdea
              className="mt-6"
              kaizenIdea={kaizenIdeaRepliesIndexData.kaizenIdea}
              mentions={fetchNewData?.mentionableEmployees || []}
              kaizenIdeaReplies={
                kaizenIdeaRepliesIndexData.kaizenIdeaReplies || []
              }
              refetch={repliesRefetch}
            />
          )}
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
