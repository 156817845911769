import React, { FC, MouseEvent } from "react";

import { useCounter } from "~/hooks";

import { ButtonWithIcon, Label } from "~/components/atoms";
import type { IconSizeType } from "~/components/atoms";

type PropsType = {
  labelText?: string;
  required?: boolean;
  count: number;
  value: number;
  readonly?: boolean;
  size?: IconSizeType;
  onChange?: (value: number) => void;
};

export const StarRatingField: FC<PropsType> = ({
  labelText,
  required = false,
  count,
  value,
  readonly = false,
  size,
  onChange,
}: PropsType) => {
  const { setValue: setStarValue, count: starValue } = useCounter(-1);

  const onClick = (e: MouseEvent<HTMLButtonElement>, starValue: number) => {
    e.preventDefault();
    if (readonly) return;

    onChange && onChange(starValue);
    setStarValue(-1);
  };

  const onHover = (starValue: number) => {
    if (readonly) return;

    setStarValue(starValue);
  };

  const onMouseLeave = () => {
    if (readonly) return;

    setStarValue(-1);
  };

  const currentValue = starValue >= 0 ? starValue : value;

  return (
    <div>
      {labelText && (
        <Label labelText={labelText} required={required} htmlFor="rate" />
      )}
      <ul className={`flex items-center space-x-1 ${labelText && "mt-1.5"}`}>
        {[...(Array(count) as number[])]
          .map((_, i) => i + 1)
          .map((starNumber) => (
            <li
              title={`${starNumber} star`}
              key={starNumber}
              onMouseOver={() => onHover(starNumber)}
              onMouseLeave={() => onMouseLeave()}
              onFocus={() => onHover(starNumber)}
              onBlur={() => onMouseLeave()}
              className={`flex ${readonly ? "" : "cursor-pointer"}`}
            >
              <ButtonWithIcon
                srOnlyText={`${starNumber}の評価を選択する`}
                onClick={(e) => onClick(e, starNumber)}
                disabled={readonly}
                icon={{
                  icon: "aiFillStar",
                  size,
                  color:
                    starNumber <= currentValue
                      ? "text-yellow-400"
                      : "text-gray-100",
                  className: readonly ? "" : "hover:text-yellow-400",
                }}
              />
            </li>
          ))}
      </ul>
    </div>
  );
};
