import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersTermEndKpiTermsIndexResponseType } from "~/domains";

type RequestDataType = {
  page?: number;
};

const request = async ({
  page = 1,
}: RequestDataType): Promise<ApiMembersTermEndKpiTermsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersTermEndKpiTermsIndexResponseType>(
      "/api/members/kpi/term_end_kpi_terms",
      { page },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersKpiTermEndKpiTermsIndex = ({
  config = {},
}: PropsType = {}): UseInfiniteQueryResult<ApiMembersTermEndKpiTermsIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApisMembersKpiTermEndKpiTermsIndex"],
    ({ pageParam }) => {
      return request({ page: pageParam as number | undefined });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
