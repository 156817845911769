import React, { FC, FormEvent, useState, ChangeEvent } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useApisAdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsCreate,
  useApisAdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsIndex,
} from "~/hooks";

import { Form, FormSubmitButton, GridHeaderCells } from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import {
  GridRow,
  InfiniteScrollWithMoreButton,
  LabelWithNumberField,
} from "~/components/molecules";

export const AdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsIndex: FC =
  () => {
    const { companyId = "" } = useParams<{ companyId: string }>();

    const { data, fetchNextPage, hasNextPage, refetch } =
      useApisAdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsIndex({
        companyId,
      });

    const { mutate: postRequest } =
      useApisAdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsCreate();
    const company = data?.pages[0]?.company;
    const breadCrumbItems = [
      {
        label: "運営者画面",
        href: "/admin_operators",
      },
      {
        label: "会社一覧",
        href: "/admin_operators",
      },
      {
        label: company?.name || "",
        href: `/admin_operators/companies/${companyId}`,
      },
      {
        label: "新しいユーザー上限数一覧",
        href: `/admin_operators/companies/${companyId}/available_employee_upper_limit_logs`,
      },
    ];

    const [upperLimit, setUpperLimit] = useState<number | "">("");

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const isConfirm = confirm(
        "この操作は取り消せません。本当に新しいユーザー上限数を作成しますか？",
      );
      if (!isConfirm) return;

      postRequest(
        {
          companyId,
          body: {
            upperLimit,
          },
        },
        {
          onSuccess: async (res) => {
            toast(res.message);
            setUpperLimit("");
            await refetch();
          },
        },
      );
    };

    return (
      <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
        <h1 className="text-xl">
          <span className="font-bold">{company?.name}</span>のユーザー上限数ログ
        </h1>
        <Form onSubmit={handleSubmit} className="space-y-6 mt-12">
          <LabelWithNumberField
            labelText="新しいユーザー上限数"
            name="upperLimit"
            placeholder="新しいユーザー上限数"
            required
            max={9999}
            min={0}
            value={upperLimit}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setUpperLimit(
                isNaN(parseInt(e.target.value)) ? "" : parseInt(e.target.value),
              )
            }
          />
          <div className="text-right">
            <FormSubmitButton value="新しい上限数を登録する" color="primary" />
          </div>
        </Form>
        <InfiniteScrollWithMoreButton
          itemsLength={data?.pages.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          className="mt-12"
        >
          <div className="grid grid-cols-3">
            <GridHeaderCells texts={["上限数", "作成日時", "更新日時"]} />
            {data?.pages.map((page) =>
              page.companyAvailableEmployeeUpperLimitLogs.map((log) => (
                <GridRow
                  key={log.id}
                  lists={[log.upperLimit, log.createdAt, log.updatedAt]}
                />
              )),
            )}
          </div>
        </InfiniteScrollWithMoreButton>
      </AdminOperatorLayout>
    );
  };
