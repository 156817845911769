import React, { FC } from "react";

import { useEditorState } from "~/hooks";

import { Avatar, Link } from "~/components/atoms";
import { Editor } from "~/components/molecules";

import { ApiNotificationType } from "~/domains";

import { Href } from "~/constants/postUrl";

type PropsType = {
  notification: Pick<
    ApiNotificationType,
    | "sender"
    | "createdAt"
    | "postedContentJsonb"
    | "notifyMessage"
    | "postableType"
    | "postableId"
    | "readAt"
  >;
  className?: string;
};

export const NotificationDetail: FC<PropsType> = ({
  notification,
  className = "",
}: PropsType) => {
  const {
    postableType,
    postableId,
    sender,
    createdAt,
    postedContentJsonb,
    notifyMessage,
    readAt,
  } = notification;

  const [{ value, onChange }] = useEditorState(postedContentJsonb);

  return (
    <Link
      to={Href[postableType](postableId) || Href.default}
      className={`hover:bg-primary-300 block space-y-2 py-4 px-3.5 border-b border-secondary-400 border-solid ${className}`}
    >
      <div className="flex items-center">
        <div className="relative">
          <Avatar src={sender.avatarUrl} size="sm" />
          {!readAt && (
            <span className="absolute top-0 right-0 bg-icon-heart w-2.5 h-2.5 rounded-full" />
          )}
        </div>
        <div className="ml-2 text-sm truncate">
          <p className="truncate font-bold">{sender.name}</p>
          <p className="truncate text-secondary-600">{createdAt}</p>
        </div>
      </div>
      <Editor
        editorState={value}
        onChange={onChange}
        readOnly
        canFileInText={false}
        className="text-sm line-clamp-3"
      />
      <p className="text-xs text-secondary-600">{notifyMessage}</p>
    </Link>
  );
};
