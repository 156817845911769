import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersEmployeeSurveySubmissionRepliesLikedEmployeesIndexResponseType } from "~/domains";

type RequestDataType = {
  employeeSurveySubmissionReplyId: string;
  page?: number;
};

const request = async ({
  employeeSurveySubmissionReplyId,
  page = 1,
}: RequestDataType): Promise<ApiMembersEmployeeSurveySubmissionRepliesLikedEmployeesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersEmployeeSurveySubmissionRepliesLikedEmployeesIndexResponseType>(
      `/api/members/employee_survey_submission_replies/${employeeSurveySubmissionReplyId}/liked_employees`,
      { page },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  employeeSurveySubmissionReplyId: string;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersEmployeeSurveySubmissionRepliesLikedEmployeesIndex =
  ({
    employeeSurveySubmissionReplyId,
    config = {},
  }: PropsType): UseInfiniteQueryResult<ApiMembersEmployeeSurveySubmissionRepliesLikedEmployeesIndexResponseType> => {
    return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
      [
        "ApisMembersEmployeeSurveySubmissionRepliesLikedEmployeesIndex",
        employeeSurveySubmissionReplyId,
      ],
      ({ pageParam }) => {
        return request({
          employeeSurveySubmissionReplyId,
          page: pageParam as number | undefined,
        });
      },
      {
        ...config,
        getNextPageParam: (data) => {
          return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
        },
      },
    );
  };
