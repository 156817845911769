import React, { FC } from "react";

import { StarRatingField } from "~/components/molecules";

type PropsType = {
  title: string;
  rate: number;
  className?: string;
};

export const TitleWithRating: FC<PropsType> = ({
  title,
  rate,
  className = "",
}: PropsType) => {
  return (
    <div className={`miniTablet:flex items-center ${className}`}>
      <StarRatingField count={5} value={rate} size="1.25rem" readonly />
      <p className="miniTablet:ml-2 font-semibold">{title}</p>
    </div>
  );
};
