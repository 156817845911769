import React, { FC, ReactNode } from "react";

import { ButtonWithIcon } from "~/components/atoms";

type PropsType = {
  label: string;
  isOpen: boolean;
  iconOnClick: () => void;
  handleOnClose?: () => void;
  children: ReactNode;
};

export const RightSidebarToggleIconWithLabel: FC<PropsType> = ({
  label,
  isOpen,
  iconOnClick,
  handleOnClose,
  children,
}: PropsType) => {
  return (
    <>
      <div
        className={`${
          isOpen && "bg-secondary-300"
        } flex items-center justify-between px-4 py-3`}
      >
        <ButtonWithIcon
          srOnlyText="検索フォームを表示する"
          icon={{
            icon: "ioSearch",
            color: "text-white",
            className: "bg-primary-600 hover:bg-primary-800 p-2 rounded",
          }}
          onClick={iconOnClick}
        />
        {isOpen && (
          <>
            <div>{label}</div>
            {handleOnClose ? (
              <ButtonWithIcon
                srOnlyText="検索フォームを閉じる"
                icon={{
                  icon: "ioCloseOutline",
                  color: "text-secondary-600",
                }}
                onClick={handleOnClose}
              />
            ) : (
              <div />
            )}
          </>
        )}
      </div>
      {isOpen && <div className="mx-4">{children}</div>}
    </>
  );
};
