import React, { FormEvent, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useDivisionSectionDropdown,
  useDateRange,
  useProvidersCurrentEmployee,
  useApisManagersKpiTermsCreate,
} from "~/hooks";

import { Form, FormSubmitButton } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { LabelWithTextField, RangeDatePicker } from "~/components/molecules";
import { ParentWithChildDropdownField } from "~/components/organisms";

import { PublicStatus } from "~/domains";

export const ManagersKpiTermsNew: FC = () => {
  const navigate = useNavigate();
  const { currentEmployee } = useProvidersCurrentEmployee();

  const { mutate: postKpiTerm, isLoading: isSubmitting } =
    useApisManagersKpiTermsCreate();

  const [title] = useInput("");
  const [dateRange] = useDateRange();

  const {
    selectableDivisions,
    selectedDivision,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSection,
    onSectionChange,
  } = useDivisionSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });

  const breadCrumbItems = [
    { label: "KPI・KGI", href: "/managers/kpi_terms" },
    { label: "新規作成", href: "/managers/kpi_terms/new" },
  ];

  const handleDraftSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = {
      businessSectionId: optionSelectedSection?.id || "",
      title: title.value,
      fromDate: dateRange.formattedStartDate || "",
      toDate: dateRange.formattedEndDate || "",
      publicStatusId: PublicStatus.DRAFTED.id,
    };
    postKpiTerm(
      {
        body,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate(`/managers/kpi_terms/${data.kpiTerm.id}/edit`);
        },
      },
    );
  };

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <Form className="space-y-6" onSubmit={handleDraftSubmit}>
          <ParentWithChildDropdownField
            allParents={selectableDivisions}
            parentValue={selectedDivision}
            parentsOnChange={onDivisionChange}
            parentRequired
            parentLabel="配布先: 部署"
            allChildren={optionSelectableSections}
            childValue={optionSelectedSection}
            childrenOnChange={onSectionChange}
            childRequired
            childLabel="配布先: 課"
          />
          <LabelWithTextField
            labelText="タイトル"
            type="text"
            name="title"
            placeholder="タイトル"
            required
            value={title.value}
            onChange={title.onChange}
          />
          <RangeDatePicker
            labelText="期間指定"
            required={true}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onChange={dateRange.onChange}
          />
          <FormSubmitButton
            value="KPI・KGIの作成"
            color="primary"
            className="w-full"
            isReadOnly={isSubmitting}
          />
        </Form>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
