import React, { FC } from "react";

import {
  useFooters,
  useApisMembersDirectMessagesLikesCreate,
  useApisMembersDirectMessagesLikesDestroy,
  useBoolean,
  useCounter,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import {
  PostHeader,
  PostContent,
  DirectMessageLikedEmployeesModal,
} from "~/components/organisms";

import { DirectMessageType, PostDetailMenuItemType } from "~/domains";

type PropsType = {
  directMessage: DirectMessageType;
  menuItems: PostDetailMenuItemType[];
};

export const DirectMessageDetail: FC<PropsType> = ({
  directMessage,
  menuItems,
}: PropsType) => {
  const { id, content, createdAt, employee, attachFiles, likesCount } =
    directMessage;
  const {
    isChecked: isLikedEmployeesModalOpen,
    setTrue: setLikedEmployeesModalOpen,
    setFalse: setLikedEmployeesModalClose,
  } = useBoolean(false);
  const {
    isChecked: liked,
    setTrue: setLikedTrue,
    setFalse: setLikedFalse,
  } = useBoolean(directMessage.liked);

  const {
    count: likeCount,
    increment: likeIncrement,
    decrement: likeDecrement,
  } = useCounter(likesCount);
  const { mutate: likePostRequest } = useApisMembersDirectMessagesLikesCreate();
  const { mutate: likeDestroyRequest } =
    useApisMembersDirectMessagesLikesDestroy();

  const handleLikePostRequest = () => {
    likePostRequest(
      {
        directMessageId: id,
      },
      {
        onSuccess: () => {
          setLikedTrue();
          likeIncrement();
        },
      },
    );
  };

  const handleLikeDestroyRequest = () => {
    likeDestroyRequest(
      {
        directMessageId: id,
      },
      {
        onSuccess: () => {
          setLikedFalse();
          likeDecrement();
        },
      },
    );
  };

  const leftFooters = useFooters({
    like: {
      doneAlready: liked,
      undoOnClick: handleLikeDestroyRequest,
      doOnClick: handleLikePostRequest,
      textOnClick: setLikedEmployeesModalOpen,
      count: likeCount,
      disable: false,
    },
  });

  return (
    <PostDetailWrapper>
      <PostHeader
        miniText={createdAt}
        postedEmployee={employee}
        menuItems={menuItems}
      />
      <PostContent
        content={content}
        images={attachFiles}
        footers={leftFooters.footers}
        className="mt-2 pl-16"
      />
      {isLikedEmployeesModalOpen && (
        <DirectMessageLikedEmployeesModal
          directMessageId={id}
          onClose={setLikedEmployeesModalClose}
        />
      )}
    </PostDetailWrapper>
  );
};
