import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisMembersAnalysisEmployeeSurveySubmissionsCountCalendarsIndexRequestTypes,
  ApisMembersAnalysisEmployeeSurveySubmissionsCountCalendarsIndexResponseTypes,
} from "~/domains";

type RequestDataType = {
  params: ApisMembersAnalysisEmployeeSurveySubmissionsCountCalendarsIndexRequestTypes;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApisMembersAnalysisEmployeeSurveySubmissionsCountCalendarsIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisMembersAnalysisEmployeeSurveySubmissionsCountCalendarsIndexResponseTypes>(
      "/api/members/analysis/employee_survey_submissions_count_calendars",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params: ApisMembersAnalysisEmployeeSurveySubmissionsCountCalendarsIndexRequestTypes;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersAnalysisEmployeeSurveySubmissionsCountCalendarsIndex =
  ({
    params,
    config = {},
  }: PropsType): UseQueryResult<ApisMembersAnalysisEmployeeSurveySubmissionsCountCalendarsIndexResponseTypes> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisMembersAnalysisEmployeeSurveySubmissionsCountCalendarsIndex",
        params,
      ],
      queryFn: () => request({ params }),
    });
  };
