import React, { FC } from "react";

import {
  useApisPartnerConsolesCompaniesAnalyticsEmployeesPostsCalendarsIndex,
  useApisPartnerConsolesCompaniesAnalyticsPostsRateCalendarsIndex,
  usePartnerConsolesMultiDivisionMultiSectionDropdown,
  usePrevNextSelectMonth,
  usePartnerConsolesCompaniesDropdown,
} from "~/hooks";

import { PartnerConsoleLayout } from "~/components/layouts";
import { LabelWithDropDownField } from "~/components/molecules";
import { AnalyticsPostsCalendarsTemplate } from "~/components/templates";

export const PartnerConsolesAnalyticsPostsCalendarsIndex: FC = () => {
  const query = new URLSearchParams(location.search);
  const companyId = query.get("company_id") || "";
  const breadCrumbItems = [
    {
      label: "パートナー画面",
      href: "/partner_consoles",
    },
    {
      label: "インサイト・チャレンジ・改善・相談",
      href: "/partner_consoles/analytics/posts_calendars",
    },
  ];

  const [{ onChangeCompany, dropdownSelectableValue, dropdownValue, company }] =
    usePartnerConsolesCompaniesDropdown({
      defaultCompanyId: companyId,
    });

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
    selectableEmployees,
    selectedEmployee,
    onEmployeeChange,
  } = usePartnerConsolesMultiDivisionMultiSectionDropdown({
    companyId: company?.id || "",
    isSelectEmployee: true,
  });

  const {
    targetDate,
    targetDateString,
    handlePrevMonthClick,
    handleNextMonthClick,
  } = usePrevNextSelectMonth();

  const { data: employeesData, isLoading: employeesIsLoading } =
    useApisPartnerConsolesCompaniesAnalyticsEmployeesPostsCalendarsIndex({
      employeeId: selectedEmployee?.id || "",
      companyId: company?.id || "",
      params: {
        targetDate: targetDateString,
      },
      config: {
        enabled: !!(company && selectedEmployee),
      },
    });

  const { data: rateData, isLoading: rateIsLoading } =
    useApisPartnerConsolesCompaniesAnalyticsPostsRateCalendarsIndex({
      companyId: company?.id || "",
      params: {
        targetDate: targetDateString,
        businessDivisionIds: selectedDivisions.map((division) => division.id),
        businessSectionIds: optionSelectedSections.map((section) => section.id),
        employeeIds: selectedEmployee ? [selectedEmployee.id] : undefined,
      },
      config: {
        enabled: !!company,
      },
    });

  const isLoading = selectedEmployee ? employeesIsLoading : rateIsLoading;
  const data = selectedEmployee ? employeesData : rateData;

  return (
    <>
      <PartnerConsoleLayout
        breadCrumbItems={breadCrumbItems}
        companyId={company?.id}
      >
        <LabelWithDropDownField
          required
          labelText="会社"
          name="company"
          options={dropdownSelectableValue}
          value={dropdownValue}
          onChange={onChangeCompany}
        />
        <AnalyticsPostsCalendarsTemplate
          selectedDivisions={selectedDivisions}
          optionSelectedSections={optionSelectedSections}
          data={data}
          className="mt-8"
          isLoading={isLoading}
          selectableDivisions={selectableDivisions}
          optionSelectableSections={optionSelectableSections}
          selectedDropdownSections={optionSelectedSections}
          selectedEmployee={selectedEmployee}
          onEmployeeChange={onEmployeeChange}
          selectableEmployees={selectableEmployees}
          targetDate={targetDate}
          handlePrevMonthClick={handlePrevMonthClick}
          handleNextMonthClick={handleNextMonthClick}
          onDivisionChange={onDivisionChange}
          onSectionChange={onSectionChange}
        />
      </PartnerConsoleLayout>
    </>
  );
};
