import {
  format,
  isAfter,
  startOfToday,
  add,
  sub,
  Duration,
  endOfMonth,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfWeek,
  startOfMonth,
} from "date-fns";

export const formatDateTime = (
  date: Date | string,
  dateFormat: string,
): string => {
  return format(new Date(date), dateFormat);
};

export const toDateString = (date: Date): string => {
  return formatDateTime(date, "yyyy-MM-dd");
};

export const toDateTimeString = (date: Date): string => {
  return formatDateTime(date, "yyyy-MM-dd HH:mm");
};
export const currentFormatTimestamp: string = formatDateTime(
  new Date(),
  "yyyyMMddHHmmss",
);

export const isAfterDate = (dateToCompare: Date, date: Date): boolean => {
  return isAfter(dateToCompare, date);
};

export const startOfTodayDate = startOfToday;

export const endOfMonthDate = endOfMonth;
export const startOfMonthDate = startOfMonth;

export const addDateTime = (date: Date, duration: Duration): Date =>
  add(date, duration);
export const subDateTime = (date: Date, duration: Duration): Date =>
  sub(date, duration);

type GetCalendarDatesReturnType = {
  startMonthDate: Date;
  endMonthDate: Date;
  calendar: Date[][];
};
export const getCalendarDates = (date: Date): GetCalendarDatesReturnType => {
  const startMonthDate = startOfMonth(date);
  const endMonthDate = endOfMonth(date);
  const mondays = eachWeekOfInterval(
    {
      start: startMonthDate,
      end: endMonthDate,
    },
    {
      weekStartsOn: 1,
    },
  );

  const calendar = mondays.map((monday) =>
    eachDayOfInterval({
      start: monday,
      end: endOfWeek(monday, { weekStartsOn: 1 }),
    }),
  );
  return {
    startMonthDate,
    endMonthDate,
    calendar,
  };
};
