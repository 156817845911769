import React, { ReactNode, FC, MouseEvent } from "react";

import { useDownloadFile } from "~/hooks";

type PropsType = {
  fileUrl: string;
  fileName: string;
  children?: ReactNode;
  className?: string;
  onSuccess?: () => void;
};

export const DownloadLink: FC<PropsType> = ({
  fileName,
  fileUrl,
  className = "",
  children,
  onSuccess,
}: PropsType) => {
  const { download } = useDownloadFile();

  const handleDownload = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    download(e, { fileUrl, fileName, onSuccess });
  };

  return (
    <button type="button" className={className} onClick={handleDownload}>
      {children}
    </button>
  );
};
