import React, { FC, FormEvent } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useApisAdminOperatorsCompaniesBillingPeriodLogsCreate,
  useApisAdminOperatorsCompaniesBillingPeriodLogsIndex,
  useDateRange,
} from "~/hooks";

import { Form, FormSubmitButton, GridHeaderCells } from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import {
  GridRow,
  InfiniteScrollWithMoreButton,
  RangeDatePicker,
} from "~/components/molecules";

export const AdminOperatorsCompaniesBillingPeriodLogsIndex: FC = () => {
  const { companyId = "" } = useParams<{ companyId: string }>();

  const { data, fetchNextPage, hasNextPage, refetch } =
    useApisAdminOperatorsCompaniesBillingPeriodLogsIndex({
      companyId,
    });

  const { mutate: postRequest } =
    useApisAdminOperatorsCompaniesBillingPeriodLogsCreate();
  const company = data?.pages[0]?.company;
  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: "/admin_operators",
    },
    {
      label: "会社一覧",
      href: "/admin_operators",
    },
    {
      label: company?.name || "",
      href: `/admin_operators/companies/${companyId}`,
    },
    {
      label: "請求期間一覧",
      href: `/admin_operators/companies/${companyId}/billing_period_logs`,
    },
  ];

  const [
    {
      startDate,
      endDate,
      formattedStartDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isConfirm = confirm(
      "この操作は取り消せません。本当に請求期間を作成しますか？",
    );
    if (!isConfirm) return;

    postRequest(
      {
        companyId,
        body: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
      },
      {
        onSuccess: async (res) => {
          toast(res.message);
          onChangeDateRange([null, null]);
          await refetch();
        },
      },
    );
  };

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      <h1 className="text-xl">
        <span className="font-bold">{company?.name}</span> の請求期間ログ
      </h1>
      <Form onSubmit={handleSubmit} className="space-y-6 mt-12">
        <RangeDatePicker
          labelText="期間指定"
          startDate={startDate}
          endDate={endDate}
          required
          onChange={onChangeDateRange}
        />
        <div className="text-right">
          <FormSubmitButton value="請求期間を作成する" color="primary" />
        </div>
      </Form>
      <InfiniteScrollWithMoreButton
        itemsLength={data?.pages.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        className="mt-12"
      >
        <div className="grid grid-cols-4">
          <GridHeaderCells
            texts={["開始日", "終了日", "作成日時", "更新日時"]}
          />
          {data?.pages.map((page) =>
            page.companyBillingPeriodLogs.map((log) => (
              <GridRow
                key={log.id}
                lists={[
                  log.startDate,
                  log.endDate,
                  log.createdAt,
                  log.updatedAt,
                ]}
              />
            )),
          )}
        </div>
      </InfiniteScrollWithMoreButton>
    </AdminOperatorLayout>
  );
};
