import React, { FC } from "react";

import { CatchTitleBorder } from "~/components/atoms";
import { InfiniteScroll } from "~/components/molecules";

import { EmployeeCompetencyReviewDetail } from "./EmployeeCompetencyReviewDetail";

import {
  AvatarAndNameEmployeeType,
  EmployeeCompetencyReviewType,
} from "~/domains";

type PropsType = {
  employee: AvatarAndNameEmployeeType;
  employeeCompetencyReviews: EmployeeCompetencyReviewType[];
  isLoading: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  refetch: () => void;
  className?: string;
};

export const EmployeeCompetencyReviewDetails: FC<PropsType> = ({
  employee,
  employeeCompetencyReviews,
  isLoading,
  hasNextPage,
  fetchNextPage,
  refetch,
  className = "",
}: PropsType) => {
  return (
    <div className={className}>
      <CatchTitleBorder text={`${employee.name}の更新履歴`} />
      <InfiniteScroll
        itemsLength={employeeCompetencyReviews.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        isLoading={isLoading}
        className={"mt-9"}
        scrollClassName="space-y-6"
      >
        {employeeCompetencyReviews.map((employeeCompetencyReview) => (
          <EmployeeCompetencyReviewDetail
            key={employeeCompetencyReview.id}
            employeeCompetencyReview={employeeCompetencyReview}
            refetch={refetch}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
