import React, { FC } from "react";

import { CatchTitleBorder, GridHeaderCells } from "~/components/atoms";
import {
  GridRow,
  InfiniteScrollWithMoreButton,
  OpenedMenuInGrid,
} from "~/components/molecules";

import { SkillEvaluationTermType } from "~/domains";

type PropsType = {
  title: string;
  skillEvaluationTerms: SkillEvaluationTermType[];
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isLoading?: boolean;
  inTerm: boolean;
  className?: string;
};

export const SkillEvaluationTermInfiniteScroll: FC<PropsType> = ({
  title,
  inTerm,
  skillEvaluationTerms,
  hasNextPage,
  isLoading,
  fetchNextPage,
  className = "",
}: PropsType) => {
  return (
    <div className={className}>
      <CatchTitleBorder text={title} />
      <InfiniteScrollWithMoreButton
        itemsLength={skillEvaluationTerms.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        isLoading={isLoading}
        className="mt-6"
      >
        <div className="grid grid-cols-2">
          <GridHeaderCells texts={["名称", "配布期間"]} />
          {skillEvaluationTerms.map((skillEvaluationTerm) => (
            <GridRow
              key={skillEvaluationTerm.id}
              lists={[
                skillEvaluationTerm.title,
                `${skillEvaluationTerm.fromDate}~${skillEvaluationTerm.toDate}`,
              ]}
              openedMenu={
                <OpenedMenuInGrid
                  cols={2}
                  buttons={[
                    {
                      text: "確認",
                      link: inTerm
                        ? `/skill_evaluation_terms/${skillEvaluationTerm.id}`
                        : `/skill_evaluation_terms/${skillEvaluationTerm.id}/result`,
                    },
                  ]}
                />
              }
            />
          ))}
        </div>
      </InfiniteScrollWithMoreButton>
    </div>
  );
};
