import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  id: string;
  body: {
    businessSectionIds: string[];
    name: string;
  };
};

const request = ({ id, body }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseMessageType>(`/api/managers/providing_services/${id}`, {
      providingService: body,
    })
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersProvidingServicesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
