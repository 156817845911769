import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersBusinessSectionsQuestionAnswerCategorySearchItemsIndexResponseType } from "~/domains";

type RequestDataType = {
  businessSectionId: string;
};

const request = async ({
  businessSectionId,
}: RequestDataType): Promise<ApiMembersBusinessSectionsQuestionAnswerCategorySearchItemsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersBusinessSectionsQuestionAnswerCategorySearchItemsIndexResponseType>(
      `/api/members/business_sections/${businessSectionId}/question_answer_category_search_items`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  businessSectionId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersBusinessSectionsQuestionAnswerCategorySearchItemsIndex =
  ({
    businessSectionId,
    config = {},
  }: PropsType): UseQueryResult<ApiMembersBusinessSectionsQuestionAnswerCategorySearchItemsIndexResponseType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisMembersBusinessSectionsQuestionAnswerCategorySearchItemsIndex",
        businessSectionId,
      ],
      queryFn: () => request({ businessSectionId }),
    });
  };
