import React, { MouseEvent, ChangeEvent, FC } from "react";

import { useKpiDailyPerformanceForm } from "~/hooks";

import {
  NumberField,
  TextField,
  Button,
  Form,
  FormSubmitButton,
  ButtonWithIcon,
} from "~/components/atoms";
import { LabelWithNumberField } from "~/components/molecules";

import { KpiObjectiveTypeWithEmployeeKpiDailyPerformanceWithClientCompanies } from "~/domains";

type PropsType = {
  className?: string;
  kpiObjective: Pick<
    KpiObjectiveTypeWithEmployeeKpiDailyPerformanceWithClientCompanies,
    "id" | "title" | "kpiDailyPerformance" | "unitName"
  >;
  formattedDate: string;
};

export const KpiDailyPerformanceForm: FC<PropsType> = ({
  kpiObjective,
  formattedDate,
}: PropsType) => {
  const {
    isSubmitting,
    performance,
    addClientCompany,
    clientCompanies,
    readonly,
    handleNotReadonly,
    onChangeClientCompanyName,
    onChangeClientCompanyPrice,
    deleteIndexClientCompany,
    handleOnSubmit,
    handleOnDelete,
  } = useKpiDailyPerformanceForm({
    defaultKpiDailyPerformance: kpiObjective.kpiDailyPerformance,
    kpiObjectiveId: kpiObjective.id,
    formattedDate: formattedDate,
  });

  return (
    <Form onSubmit={handleOnSubmit}>
      <div className="flex items-end miniTablet:w-1/2">
        <LabelWithNumberField
          labelText={kpiObjective.title}
          name={kpiObjective.title}
          value={performance.value ?? ""}
          onChange={performance.onChange}
          labelClassName="font-semibold"
          className="w-full"
          required
          min={0}
          size="lg"
          onlyBottomBorder
          disabled={readonly}
        />
        <span className="ml-2">{kpiObjective.unitName}</span>
      </div>
      {clientCompanies.map((clientCompany, index) => (
        <div className="flex items-center mt-6" key={clientCompany.id}>
          <TextField
            placeholder="企業名"
            type="text"
            required
            name={`clientCompanyName${index}`}
            value={clientCompany.clientCompanyName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeClientCompanyName(index, e.target.value)
            }
            disabled={readonly}
            size="lg"
            onlyBottomBorder
          />
          <div className="flex items-end">
            <NumberField
              placeholder="金額"
              name={`clientCompanyPrice${index}`}
              value={clientCompany.price ?? ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangeClientCompanyPrice(index, Number(e.target.value) ?? "")
              }
              disabled={readonly}
              className="ml-4"
              size="lg"
              min={0}
              onlyBottomBorder
            />
            <span className="ml-2">円</span>
          </div>
          {!readonly && (
            <ButtonWithIcon
              onClick={(e: MouseEvent<HTMLButtonElement>) =>
                deleteIndexClientCompany(e, index)
              }
              icon={{
                icon: "ioCloseOutline",
                color: "text-secondary-600",
                size: "1.25rem",
                className: "mx-auto",
              }}
              className="ml-2 mt-6"
            />
          )}
        </div>
      ))}
      <div className="flex items-center mt-12 space-x-2">
        {readonly ? (
          <Button
            onClick={handleNotReadonly}
            text="編集"
            color="gray"
            outline
            className="w-full miniTablet:w-auto"
          />
        ) : (
          <>
            <Button
              onClick={addClientCompany}
              text="内訳"
              color="gray"
              outline
              className="w-full miniTablet:w-auto"
            />
            {kpiObjective.kpiDailyPerformance && (
              <Button
                onClick={handleOnDelete}
                text="削除"
                color="gray"
                outline
                className="w-full miniTablet:w-auto"
              />
            )}
            <FormSubmitButton
              value="保存"
              color="primary"
              className="w-full miniTablet:w-auto"
              isReadOnly={isSubmitting}
            />
          </>
        )}
      </div>
    </Form>
  );
};
