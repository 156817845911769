import React, { FC } from "react";

import { Icon } from "~/components/atoms";

type PropsType = {
  className?: string;
};

export const Loading: FC<PropsType> = ({ className = "" }: PropsType) => {
  return (
    <div className={`col-span-7 ${className}`}>
      <Icon
        icon="tbLoaderQuarter"
        className="animate-spin mx-auto"
        size="2.5rem"
        color="text-primary-600"
      />
    </div>
  );
};
