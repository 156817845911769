import React, { Fragment, FC } from "react";

import { Icon, ModalWrapper, TextField } from "~/components/atoms";
import { Fetch } from "~/components/organisms";

import { ApiKpiObjectivesEmployeesClientCompaniesIndex } from "~/domains";

type PropsType = {
  onClose: () => void;
  title: string;
  loading: boolean;
  data?: ApiKpiObjectivesEmployeesClientCompaniesIndex;
};

export const KpiDashboardClientCompaniesModal: FC<PropsType> = ({
  onClose,
  title,
  loading,
  data,
}: PropsType) => {
  return (
    <ModalWrapper
      onClose={onClose}
      headerTitle={`${data?.employee?.name}: ${title}の企業一覧`}
    >
      <div className="min-h-[10rem] py-4">
        <button
          type="button"
          onClick={onClose}
          className="text-primary-600 flex items-center"
        >
          <Icon
            icon="ioChevronBackOutline"
            color="text-primary-600"
            size="1rem"
          />
          <span>チャートに戻る</span>
        </button>
        <Fetch loading={loading} data={data} error={null}>
          <div className="grid grid-cols-2 gap-4 mt-8">
            {data?.kpiDailyPerformanceClientCompanies.map(
              (kpiDailyPerformanceClientCompany) => (
                <Fragment key={kpiDailyPerformanceClientCompany.id}>
                  <TextField
                    disabled
                    value={kpiDailyPerformanceClientCompany.clientCompany.name}
                    type="text"
                    name={`${kpiDailyPerformanceClientCompany.id}_name`}
                  />
                  <TextField
                    disabled
                    type="text"
                    value={
                      kpiDailyPerformanceClientCompany.price
                        ? `${kpiDailyPerformanceClientCompany.price}円`
                        : ""
                    }
                    name={`${kpiDailyPerformanceClientCompany.id}_price`}
                  />
                </Fragment>
              ),
            )}
          </div>
        </Fetch>
      </div>
    </ModalWrapper>
  );
};
