import React, { FC } from "react";

import {
  useProvidersCurrentEmployee,
  useManagerCategoryDisplayOrderSearch,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { CategoryDisplayOrderChange } from "~/components/organisms";

import { ApiManagersSkillEvaluationStandardSummaryCategoriesDisplayOrderIndexType } from "~/domains";

export const ManagersSkillEvaluationStandardSummaryCategoriesDisplayOrderIndex: FC =
  () => {
    const { currentEmployee } = useProvidersCurrentEmployee();

    const fetchUrl = (id: string) => {
      return `/api/managers/business_sections/${id}/skill_evaluation_standard_summary_categories`;
    };

    const updateUrl = (id: string) => {
      return `/api/managers/business_sections/${id}/skill_evaluation_standard_summary_category/display_order`;
    };

    const { onSearchSubmit, fetch, divisionSection, changeDisplayOrder } =
      useManagerCategoryDisplayOrderSearch<ApiManagersSkillEvaluationStandardSummaryCategoriesDisplayOrderIndexType>(
        {
          selectableBusinessDivisions: currentEmployee?.businessDivisions || [],
          selectableBusinessSections: currentEmployee?.businessSections || [],
          fetchUrl: fetchUrl,
          updateUrl: updateUrl,
        },
      );

    const breadCrumbItems = [
      {
        label: "スキルマップ",
        href: "/managers/skill_evaluation_standard_summary_categories",
      },
      {
        label: "並び替え",
        href: "/managers/skill_evaluation_standard_summary_category/display_orders",
      },
    ];

    return (
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          <CategoryDisplayOrderChange
            onSearchSubmit={onSearchSubmit}
            fetch={{
              loading: fetch.loading,
              error: fetch.error,
              items: fetch.data?.skillEvaluationStandardSummaryCategories || [],
            }}
            divisionSection={divisionSection}
            changeDisplayOrder={changeDisplayOrder}
            catchTitle="スキルマップの並び替え"
            noContentMessage="スキルマップがありません"
          />
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    );
  };
