import { SlideNavigationType } from "~/components/molecules";

type ReturnType = {
  navigations: SlideNavigationType[];
};

type PropsType = {
  businessSectionId: string;
  selectedArray: [boolean, boolean];
};

export const useManagersBusinessSectionReviewsNavigation = ({
  businessSectionId,
  selectedArray,
}: PropsType): ReturnType => {
  const navigations = [
    {
      label: "メモ",
      href: `/managers/business_sections/${businessSectionId}/business_section_memo_reviews`,
      selected: selectedArray[0],
    },
    {
      label: "特性",
      href: `/managers/business_sections/${businessSectionId}/business_section_competency_reviews`,
      selected: selectedArray[1],
    },
  ];

  return {
    navigations: navigations,
  };
};
