import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersBelongingEmployeesInsightPostObjectivesIndexRequestType,
  ApiManagersBelongingEmployeesInsightPostObjectivesIndexResponseType,
} from "~/domains";

type RequestDataType = {
  params?: ApiManagersBelongingEmployeesInsightPostObjectivesIndexRequestType;
  page?: number;
};

const request = async ({
  params,
  page = 1,
}: RequestDataType): Promise<ApiManagersBelongingEmployeesInsightPostObjectivesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersBelongingEmployeesInsightPostObjectivesIndexResponseType>(
      "/api/managers/belonging_employees/insight_post_objectives",
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiManagersBelongingEmployeesInsightPostObjectivesIndexRequestType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisManagersBelongingEmployeesInsightPostObjectivesIndex = ({
  params,
  config = {},
}: PropsType = {}): UseInfiniteQueryResult<ApiManagersBelongingEmployeesInsightPostObjectivesIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApisManagersBelongingEmployeesInsightPostObjectivesIndex", params],
    ({ pageParam }) => {
      return request({ params, page: pageParam as number | undefined });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
