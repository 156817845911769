import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  skillEvaluationStandardSummaryCategoryId: string;
  body: {
    id: string;
    displayOrder: number;
  }[];
};

const request = ({
  skillEvaluationStandardSummaryCategoryId,
  body,
}: RequestDataType) => {
  return new ApiClient()
    .put<ResponseMessageType>(
      `/api/managers/skill_evaluation_standard_summary_categories/${skillEvaluationStandardSummaryCategoryId}/display_order`,
      {
        skillEvaluationStandardSummaryCategory: {
          skillEvaluationStandardSummaries: body,
        },
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagerSkillEvaluationStandardSummaryCategoriesDisplayOrdersUpdate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ResponseMessageType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
