import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersSalesConceptCategoryMutateType,
  ApiManagersSalesConceptCategoryMutateRequestType,
} from "~/domains";

type RequestDataType = {
  body: ApiManagersSalesConceptCategoryMutateRequestType;
};

const request = ({ body }: RequestDataType) => {
  return new ApiClient()
    .post<ApiManagersSalesConceptCategoryMutateType>(
      "/api/managers/sales_concept_categories",
      {
        salesConceptCategory: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersSalesConceptCategoriesCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersSalesConceptCategoryMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
