import { FormEvent, ChangeEvent, MouseEvent, useState } from "react";

import { toDateString } from "~/libs";

import {
  useArray,
  useApisMembersPrivatePersonalMemosIndex,
  useDateRange,
  useInput,
  useProvidersEmployeeLayout,
} from "~/hooks";

import {
  ApiMembersPrivatePersonalMemosIndexRequestType,
  PrivatePersonalMemoType,
  RangeDatePropsType,
} from "~/domains";

type ReturnType = {
  privatePersonalMemos: PrivatePersonalMemoType[];
  hasNextPage?: boolean;
  isLoading: boolean;
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  fetchNextPage: () => void;
  refetch: () => void;
  unshiftItem: (item: PrivatePersonalMemoType) => void;
  deleteIndexItem: (index: number) => void;
  updateIndexItem: (item: PrivatePersonalMemoType, index: number) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchMembersPrivatePersonalMemos = (): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const [params, setParams] =
    useState<ApiMembersPrivatePersonalMemosIndexRequestType>();

  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");

  const {
    items: privatePersonalMemos,
    setItems: setPrivatePersonalMemos,
    unshiftItem,
    deleteIndexItem,
    updateIndexItem,
  } = useArray<PrivatePersonalMemoType>([]);

  const { fetchNextPage, refetch, hasNextPage, isFetching } =
    useApisMembersPrivatePersonalMemosIndex({
      params,
      config: {
        onSuccess: (data) => {
          setPrivatePersonalMemos(
            data.pages.map((page) => page.privatePersonalMemos).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      createdAtFrom: startDate ? toDateString(startDate) : "",
      createdAtTo: endDate ? toDateString(endDate) : "",
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDateRange([null, null]);
    setParams(undefined);
    setFalseRightSidebarMain();
  };

  return {
    isLoading: isFetching,
    privatePersonalMemos,
    hasNextPage,
    keyword,
    startDate,
    endDate,
    fetchNextPage,
    refetch,
    unshiftItem,
    deleteIndexItem,
    updateIndexItem,
    onChangeDateRange,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  };
};
