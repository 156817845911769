import React, { FC } from "react";

import { SidebarLink } from "./SidebarLink";

type PropsType = {
  breadCrumbText: string;
};

export const AnalyticsPlanSidebar: FC<PropsType> = ({
  breadCrumbText,
}: PropsType) => {
  const navs = [
    {
      label: "累計ランキング",
      href: "/analytics_plans",
    },
    {
      label: "累計分析",
      href: "/analytics_plans/cumulative_analysis",
    },
    {
      label: "内訳分析",
      href: "/analytics_plans/detail_analysis",
    },
    {
      label: "インサイト・チャレンジ・改善・相談",
      href: "/analytics_plans/posts_calendars",
    },
    {
      label: "フィードバック分析",
      href: "/analytics_plans/feedback_analysis",
    },
    {
      label: "インサイト目標分析",
      href: "/analytics_plans/insight_post_objectives",
    },
    {
      label: "KPI・KGI分析",
      href: "/analytics_plans/kpi_terms",
    },
    {
      label: "ナレッジシェア分析",
      href: "/analytics_plans/knowledge_insight_posts",
    },
    {
      label: "資料・ツール分析",
      href: "/analytics_plans/document_file_categories",
    },
    {
      label: "ロールプレイング分析",
      href: "/analytics_plans/roleplaying_conditions",
    },
    {
      label: "1on1・MTG分析",
      href: "/analytics_plans/meeting_notes",
    },
    {
      label: "Q&A分析",
      href: "/analytics_plans/question_answer_categories",
    },
    {
      label: "スキルマップ分析",
      href: "/analytics_plans/skill_evaluation_standard_summary_categories",
    },
    {
      label: "スキルチェック分析",
      href: "/analytics_plans/skill_evaluation_terms",
    },
    {
      label: "ブログ分析",
      href: "/analytics_plans/sales_concept_categories",
    },
  ];

  return (
    <>
      {navs.map((nav) => (
        <SidebarLink
          key={nav.label}
          label={nav.label}
          href={nav.href}
          breadCrumbText={breadCrumbText}
        />
      ))}
    </>
  );
};
