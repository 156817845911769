import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  id: string;
};

const request = ({ id }: RequestDataType) => {
  return new ApiClient()
    .delete<ResponseMessageType>(
      `/api/members/insight_post_bookmark_folders/${id}`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersInsightPostBookmarkFoldersDestroy = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
