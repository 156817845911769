import { useApisMembersUnreadNotificationCountsIndex } from "~/hooks";
import { useInterval } from "~/hooks";

export const useIntervalFetchNotificationCountsIndex = () => {
  const { data: fetchData, refetch } =
    useApisMembersUnreadNotificationCountsIndex({
      config: {
        useErrorBoundary: () => {
          return false;
        },
      },
    });

  useInterval({ onUpdate: refetch, intervalMs: 10000 });

  return fetchData;
};
