import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisMembersMeetingNotePostCalendarsIndexRequestTypes,
  ApisMembersMeetingNotePostCalendarsIndexResponseTypes,
} from "~/domains";

type RequestDataType = {
  params: ApisMembersMeetingNotePostCalendarsIndexRequestTypes;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApisMembersMeetingNotePostCalendarsIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisMembersMeetingNotePostCalendarsIndexResponseTypes>(
      "/api/members/meeting_note_post_calendars",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params: ApisMembersMeetingNotePostCalendarsIndexRequestTypes;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersMeetingNotePostCalendarsIndex = ({
  params,
  config = {},
}: PropsType): UseQueryResult<ApisMembersMeetingNotePostCalendarsIndexResponseTypes> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersMeetingNotePostCalendarsIndex", params],
    queryFn: () => request({ params }),
  });
};
