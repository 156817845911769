import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisAdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsMutateRequestType,
  ResponseMessageType,
} from "~/domains";

type RequestDataType = {
  companyId: string;
  body: ApisAdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsMutateRequestType;
};

const request = ({ companyId, body }: RequestDataType) => {
  return new ApiClient()
    .post<ResponseMessageType>(
      `/api/admin_operators/companies/${companyId}/available_employee_upper_limit_logs`,
      { companyAvailableEmployeeUpperLimitLog: body },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisAdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsCreate =
  ({
    options = {},
  }: PropsType = {}): UseMutationResult<ResponseMessageType> => {
    return useMutation(request, { ...options });
  };
