import { MouseEvent, FormEvent, useState, ChangeEvent } from "react";

import {
  useInput,
  useApisManagersArchivedBusinessSectionsIndex,
  useArray,
} from "~/hooks";

import { BusinessSectionWithBusinessDivisionType } from "~/domains";

type ReturnType = {
  businessSections: BusinessSectionWithBusinessDivisionType[];
  keyword: string;
  isLoading: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  findAndRemove: (
    func: (item: BusinessSectionWithBusinessDivisionType) => boolean,
  ) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchManagersArchivedBusinessSections = (): ReturnType => {
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [params, setParams] = useState({});
  const {
    items: businessSections,
    setItems: setBusinessSections,
    findAndRemove,
  } = useArray<BusinessSectionWithBusinessDivisionType>([]);

  const { fetchNextPage, hasNextPage, isLoading } =
    useApisManagersArchivedBusinessSectionsIndex({
      params,
      config: {
        onSuccess: (data) => {
          setBusinessSections(
            data?.pages.map((page) => page.businessSections).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
    });
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    setParams({});
  };

  return {
    businessSections,
    isLoading,
    keyword,
    hasNextPage,
    fetchNextPage,
    findAndRemove,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  };
};
