import React, { FC, useState, FormEvent } from "react";

import { toast } from "react-toastify";

import { uniqueObjectArrayById } from "~/utils";

import {
  useBoolean,
  useClipboard,
  useInput,
  useCounter,
  useEditorState,
  useDropdown,
  useApisMembersKaizenIdeasNew,
  useApisMembersKaizenIdeasDestroy,
  useApisMembersKaizenIdeasCompletesUpdate,
  useApisMembersKaizenIdeasUpdate,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import {
  KaizenIdeaDetail,
  PostHeader,
  KaizenIdeaForm,
} from "~/components/organisms";

import {
  KaizenIdeaType,
  PostDetailMenuItemType,
  PreviewWithFileType,
} from "~/domains";

import { Href } from "~/constants/postUrl";

type PropsType = {
  isHighlight?: boolean;
  kaizenIdea: KaizenIdeaType;
  className?: string;
  openComment: (kaizenIdeaId: string) => void;
  deleteItem?: () => void;
  updateItem?: (newItem: KaizenIdeaType) => void;
};

export const KaizenIdeaDetailWithEditForm: FC<PropsType> = ({
  isHighlight = false,
  kaizenIdea,
  className = "",
  deleteItem,
  updateItem,
  openComment,
}: PropsType) => {
  const { clipboardToCopy } = useClipboard();
  const openEditForm = useBoolean(false);

  const { mutate: deleteKaizenRequest } = useApisMembersKaizenIdeasDestroy();
  const { data: fetchNewData } = useApisMembersKaizenIdeasNew();
  const { mutate: updateMutate, isLoading: isSubmitting } =
    useApisMembersKaizenIdeasUpdate();
  const { mutate: updateCompleteMutate } =
    useApisMembersKaizenIdeasCompletesUpdate();

  const [providingService] = useDropdown({
    initialValue: {
      id: kaizenIdea.providingService?.id || "",
      name: kaizenIdea.providingService?.name || "すべて",
    },
    selectableValue: uniqueObjectArrayById(
      kaizenIdea.providingService
        ? [
            kaizenIdea.providingService,
            ...(fetchNewData?.providingServices || []),
          ]
        : fetchNewData?.providingServices || [],
    ),
  });

  const [editorState] = useEditorState(kaizenIdea.content);
  const [title] = useInput(kaizenIdea.title);
  const [clientCompany] = useInput(kaizenIdea.clientCompany?.name || "");
  const priorityRating = useCounter(kaizenIdea.priorityRating.id);

  const [files, setFiles] = useState<PreviewWithFileType[]>([]);

  const deleteRequest = () => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteKaizenRequest(
      {
        id: kaizenIdea.id,
      },
      {
        onSuccess: (res) => {
          toast(res.message);
          deleteItem && deleteItem();
        },
      },
    );
  };

  const updateCompleteRequest = () => {
    updateCompleteMutate(
      {
        kaizenIdeaId: kaizenIdea.id,
      },
      {
        onSuccess: (res) => {
          updateItem?.(res.kaizenIdea);
          toast(res.message);
        },
      },
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = {
      title: title.value,
      content: editorState.jsonContent,
      clientCompanyName: clientCompany.value,
      providingServiceId: providingService.value?.id,
      priorityRatingId: priorityRating.count,
      attachFiles: files,
    };
    updateMutate(
      {
        id: kaizenIdea.id,
        body,
      },
      {
        onSuccess: (res) => {
          toast(res.message);
          updateItem?.(res.kaizenIdea);
          openEditForm.setFalse();
        },
      },
    );
  };

  const detailItems: PostDetailMenuItemType[] = [
    {
      icon: "ioCopyOutline",
      text: "リンクをコピー",
      onClick: () =>
        clipboardToCopy(`${location.origin}${Href.KaizenIdea(kaizenIdea.id)}`),
      disabled: false,
    },
    {
      icon: "ioCreateOutline",
      text: "編集する",
      onClick: openEditForm.toggle,
      disabled: !kaizenIdea.editable,
    },
    {
      icon: kaizenIdea.completedAt
        ? "ioRemoveCircleOutline"
        : "ioCheckmarkCircleOutline",
      text: kaizenIdea.completedAt ? "未完了にする" : "完了済みにする",
      onClick: updateCompleteRequest,
      disabled: !kaizenIdea.completable,
    },
    {
      icon: "ioTrashOutline",
      text: "削除する",
      onClick: deleteRequest,
      disabled: !kaizenIdea.editable,
    },
  ];

  const cancelItems = [
    {
      text: "編集をキャンセル",
      onClick: openEditForm.setFalse,
      disabled: !kaizenIdea.editable,
    },
  ];

  return (
    <div className={className}>
      {openEditForm.isChecked ? (
        <PostDetailWrapper skipHoverStyle>
          <PostHeader
            miniText={kaizenIdea.createdAt}
            postedEmployee={kaizenIdea.employee}
            menuItems={cancelItems}
            underLabel={`${kaizenIdea.providingService?.name || "すべて"} -
            ${kaizenIdea.clientCompany?.name || "本人"}`}
          />
          <KaizenIdeaForm
            isSubmitting={isSubmitting}
            mentions={fetchNewData?.mentionableEmployees || []}
            files={files}
            onChangeFiles={setFiles}
            editorState={{
              value: editorState.value,
              onChange: editorState.onChange,
            }}
            providingService={{
              selectableValue: providingService.dropdownSelectableValue,
              selected: providingService.dropdownValue,
              onChange: providingService.onChange,
            }}
            priorityRating={{
              value: priorityRating.count,
              onChange: priorityRating.setValue,
            }}
            handleSubmit={handleSubmit}
            clientCompany={{
              value: clientCompany.value,
              onChange: clientCompany.onChange,
            }}
            title={{
              value: title.value,
              onChange: title.onChange,
            }}
            submitButtonLabel="更新する"
            className="mt-8"
          />
        </PostDetailWrapper>
      ) : (
        <KaizenIdeaDetail
          kaizenIdea={kaizenIdea}
          menuItems={detailItems}
          isHighlight={isHighlight}
          openReply={() => openComment(kaizenIdea.id)}
        />
      )}
    </div>
  );
};
