import React, { FC, MouseEvent } from "react";

import { useBoolean } from "~/hooks";

import { Button } from "~/components/atoms";
import { SelectItemModal } from "~/components/molecules";

import { RawDraftContentState } from "~/domains";

type TemplateItemType = {
  id: string;
  title: string;
  content: RawDraftContentState;
};

type PropsType = {
  readOnly?: boolean;
  templates: TemplateItemType[];
  setEditorState: (content: RawDraftContentState) => void;
  className?: string;
  selectedTemplate?: TemplateItemType;
  setSelectedTemplate: (template?: TemplateItemType) => void;
};

export const TemplateSelectButtonWithModal: FC<PropsType> = ({
  readOnly = false,
  templates,
  className,
  selectedTemplate,
  setEditorState,
  setSelectedTemplate,
}: PropsType) => {
  const {
    isChecked: isOpenModal,
    setTrue: modalOpen,
    setFalse: modalClose,
  } = useBoolean(false);

  const handleModalOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    modalOpen();
  };

  const handleTemplate = (itemId?: string) => {
    const item = templates.find((v) => v.id === itemId);

    if (!item) {
      setSelectedTemplate(item);
      modalClose();
      return;
    }

    const result = confirm("テンプレートを反映して上書きしますか？");
    if (!result) return;
    setSelectedTemplate(item);
    setEditorState(item.content);
    modalClose();
  };
  return (
    <div className={className}>
      {Boolean(templates.length) && (
        <Button
          readonly={readOnly}
          onClick={handleModalOpen}
          text={selectedTemplate?.title ?? "テンプレートを選択"}
          className="w-full miniTablet:w-1/2 mx-auto font-semibold block"
          outline
          color={!readOnly && selectedTemplate ? "primary" : "gray"}
        />
      )}
      {isOpenModal && (
        <SelectItemModal
          onClose={modalClose}
          items={templates || []}
          handleSelectItemId={handleTemplate}
          title="テンプレートを選択"
        />
      )}
    </div>
  );
};
