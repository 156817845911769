import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiAnalyticsPlansRoleplayingConditionsIndexResponseType,
  ApiAnalyticsPlansRoleplayingConditionsIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiAnalyticsPlansRoleplayingConditionsIndexRequestType;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApiAnalyticsPlansRoleplayingConditionsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiAnalyticsPlansRoleplayingConditionsIndexResponseType>(
      "/api/analytics_plans/roleplaying_conditions",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiAnalyticsPlansRoleplayingConditionsIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisAnalyticsPlansRoleplayingConditionsIndex = ({
  params,
  config = {},
}: PropsType = {}): UseQueryResult<ApiAnalyticsPlansRoleplayingConditionsIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisAnalyticsPlansRoleplayingConditionsIndex", params],
    queryFn: () => request({ params }),
  });
};
