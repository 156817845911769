import React, { FC } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

type PropsType = {
  title?: string;
  datasets: {
    name: string;
    areaValue: number;
    lineValue: number;
  }[];
  areaLabel: string;
  lineLabel: string;
};

export const LineAndAreaChart: FC<PropsType> = ({
  title,
  datasets,
  areaLabel,
  lineLabel,
}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: !!title,
        text: title,
      },
    },
  };
  const data = {
    labels: datasets.map((item) => item.name),
    datasets: [
      {
        fill: false,
        label: lineLabel,
        data: datasets.map((item) => item.lineValue),
        borderColor: "#FFD740",
        backgroundColor: "#FFD740",
        borderWidth: 2,
      },
      {
        fill: true,
        label: areaLabel,
        data: datasets.map((item) => item.areaValue),
        borderColor: "#00A1DF",
        backgroundColor: "#EBF8FD",
        borderWidth: 2,
      },
    ],
  };

  return <Line options={options} data={data} />;
};
