import React, { FC, ChangeEvent } from "react";

import { FormSizes } from "~/constants/buttonAndForm";

type FieldType = "text" | "email" | "password" | "search" | "tel" | "url";

type PropsType = {
  type: FieldType;
  name: string;
  value?: string;
  defaultValue?: string;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  size?: keyof typeof FormSizes;
  onlyBottomBorder?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const TextField: FC<PropsType> = ({
  type = "text",
  name,
  value,
  defaultValue,
  autoComplete,
  placeholder = "",
  required = false,
  disabled = false,
  className = "",
  onlyBottomBorder = false,
  onChange,
  size = "sm",
}: PropsType) => {
  return (
    <input
      type={type}
      name={name}
      value={value}
      defaultValue={defaultValue}
      autoComplete={autoComplete}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      className={`${FormSizes[size]} w-full ${
        onlyBottomBorder ? "border-b rounded-none" : "border rounded"
      } border-solid border-secondary-400 placeholder-secondary-400 focus:outline-none focus:border-secondary-400 ${
        disabled ? "bg-secondary-300 text-secondary-600" : ""
      } ${className}`}
      onChange={onChange}
    />
  );
};
