import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisMembersAnalysisKaizenIdeasCountCalendarsIndexRequestTypes,
  ApisMembersAnalysisKaizenIdeasCountCalendarsIndexResponseTypes,
} from "~/domains";

type RequestDataType = {
  params: ApisMembersAnalysisKaizenIdeasCountCalendarsIndexRequestTypes;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApisMembersAnalysisKaizenIdeasCountCalendarsIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisMembersAnalysisKaizenIdeasCountCalendarsIndexResponseTypes>(
      "/api/members/analysis/kaizen_ideas_count_calendars",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params: ApisMembersAnalysisKaizenIdeasCountCalendarsIndexRequestTypes;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersAnalysisKaizenIdeasCountCalendarsIndex = ({
  params,
  config = {},
}: PropsType): UseQueryResult<ApisMembersAnalysisKaizenIdeasCountCalendarsIndexResponseTypes> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersAnalysisKaizenIdeasCountCalendarsIndex", params],
    queryFn: () => request({ params }),
  });
};
