import React, { FC, MouseEvent } from "react";

import { useBoolean } from "~/hooks";

import {
  Icon,
  DownloadLink,
  BackgroundThumbnailImage,
} from "~/components/atoms";
import { FileContentTypeIcon, FileViewerModal } from "~/components/molecules";

import { AttachFileType } from "~/domains";

type PropsType = {
  className?: string;
  thumbnailImageUrl: string;
  attachFile?: AttachFileType;
  onPreviewsRequest?: () => void;
  title: string;
  tags?: string[];
  bookmark?: {
    request: () => void;
    bookmarked: boolean;
  };
  download?: {
    url: string;
    fileName: string;
    onSuccess?: () => void;
  };
  contentBgColorClassName?: "bg-primary-200" | "bg-transparent";
};

export const Thumbnail: FC<PropsType> = ({
  attachFile,
  thumbnailImageUrl,
  title,
  className = "",
  tags = [],
  bookmark,
  download,
  contentBgColorClassName = "bg-primary-200",
  onPreviewsRequest,
}: PropsType) => {
  const bookmarkIcon = bookmark?.bookmarked
    ? "ioBookmark"
    : "ioBookmarkOutline";

  const {
    isChecked: isModalOpen,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    previewOpen();
  };

  const previewOpen = () => {
    setOpen();
    onPreviewsRequest && onPreviewsRequest();
  };

  return (
    <>
      <div
        className={`group rounded-lg shadow overflow-hidden bg-red relative ${className}`}
      >
        <BackgroundThumbnailImage
          bgImageUrl={thumbnailImageUrl}
          onClick={previewOpen}
        />
        {bookmark && (
          <button
            type="button"
            onClick={bookmark.request}
            className="absolute top-2 left-2"
          >
            <Icon icon={bookmarkIcon} color="text-primary-600" size="2rem" />
          </button>
        )}
        <div className={`px-3 py-2 ${contentBgColorClassName}`}>
          <p className="line-clamp-2 h-11 text-sm text-secondary-600 font-medium">
            {title}
          </p>
          <div className="flex items-center mt-4">
            {attachFile && (
              <button type="button" onClick={handleOpen}>
                <FileContentTypeIcon
                  contentType={attachFile.contentType}
                  iconSize="1.25rem"
                  className="shrink-0"
                />
              </button>
            )}
            <div
              className={`hidden-scrollbar overflow-x-scroll flex items-center space-x-2 text-xs h-6 ${
                attachFile ? "ml-2" : ""
              }`}
            >
              {tags.map((tag) => (
                <div
                  key={tag}
                  className="rounded-3xl bg-secondary-300 px-2 py-0.5 whitespace-nowrap"
                >
                  {tag}
                </div>
              ))}
            </div>
          </div>
        </div>
        {download && (
          <DownloadLink
            fileName={download.fileName}
            fileUrl={download.url}
            onSuccess={download.onSuccess}
            className="opacity-0 group-hover:opacity-100 absolute bottom-2 right-2 bg-white p-2 border rounded"
          >
            <Icon
              icon="riDownloadCloudLine"
              color="text-primary-600"
              size="2rem"
            />
          </DownloadLink>
        )}
      </div>
      {attachFile && isModalOpen && (
        <FileViewerModal
          onClose={setClose}
          attachedFile={attachFile}
          title={title}
        />
      )}
    </>
  );
};
