import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApisAdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsIndexResponseType } from "~/domains";

type RequestDataType = {
  page?: number;
  companyId: string;
};

const request = async ({
  page = 1,
  companyId,
}: RequestDataType): Promise<ApisAdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApisAdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsIndexResponseType>(
      `/api/admin_operators/companies/${companyId}/available_employee_upper_limit_logs`,
      { page },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  config?: InfiniteQueryConfigType<QueryFnType>;
  companyId: string;
};

export const useApisAdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsIndex =
  ({
    companyId,
    config = {},
  }: PropsType): UseInfiniteQueryResult<ApisAdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsIndexResponseType> => {
    return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
      [
        "ApisAdminOperatorsCompaniesAvailableEmployeeUpperLimitLogsIndex",
        companyId,
      ],
      ({ pageParam }) => {
        return request({ companyId, page: pageParam as number | undefined });
      },
      {
        ...config,
        getNextPageParam: (data) => {
          return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
        },
      },
    );
  };
