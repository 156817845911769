import React, { FC, FormEvent, MouseEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { setAuthenticationUidCookies, SignOut } from "~/utils";

import {
  useApisAdminOperatorsProfilesUpdate,
  useInput,
  useProvidersCurrentAdminOperator,
} from "~/hooks";

import { Button, Form, FormSubmitButton, Link } from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import { LabelWithTextField } from "~/components/molecules";

export const AdminOperatorsSettingProfileShow: FC = () => {
  const navigate = useNavigate();
  const { currentAdminOperator, refetch } = useProvidersCurrentAdminOperator();
  const [{ value: email, onChange: onChangeEmail }, setEmail] = useInput("");
  const [{ value: name, onChange: onChangeName }, setName] = useInput("");
  const { mutate, isLoading: isUpdating } =
    useApisAdminOperatorsProfilesUpdate();
  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: "/admin_operators",
    },
    {
      label: "プロフィール編集",
      href: "/admin_operators/setting/profile",
    },
  ];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      {
        body: {
          email,
          name,
        },
      },
      {
        onSuccess: (data) => {
          setAuthenticationUidCookies(email);
          refetch();
          toast(data.message);
          navigate("/admin_operators");
        },
      },
    );
  };

  const handleLogout = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = confirm("本当にログアウトしますか？");
    if (!result) return;

    await SignOut({
      isAdmin: true,
      onSuccess: () => {
        toast("ログアウトしました");
        navigate("/admin_operators/sign_in");
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });
  };

  useEffect(() => {
    if (currentAdminOperator) {
      setEmail(currentAdminOperator.email);
      setName(currentAdminOperator.name);
    }
  }, [currentAdminOperator, setEmail, setName]);

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      <Form className="mt-4 space-y-4" onSubmit={handleSubmit}>
        <LabelWithTextField
          labelText="名前"
          type="text"
          name="name"
          autoComplete="name"
          placeholder="名前"
          required
          onChange={onChangeName}
          value={name}
        />
        <LabelWithTextField
          labelText="メールアドレス"
          type="email"
          name="email"
          autoComplete="email"
          placeholder="email@example.com"
          required
          onChange={onChangeEmail}
          value={email}
        />
        <div className="flex justify-end items-center space-x-4">
          <FormSubmitButton
            color="primary"
            value="更新"
            isReadOnly={isUpdating}
          />
        </div>
      </Form>
      <div className="mt-10 text-center">
        <Link
          to="/admin_operators/setting/password"
          className="text-primary-600 underline visited:text-purple-600 block"
        >
          パスワード変更
        </Link>
        <Button
          onClick={handleLogout}
          text="ログアウト"
          color="primary"
          className="mt-2 w-fit"
          outline
        />
      </div>
    </AdminOperatorLayout>
  );
};
