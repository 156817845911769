import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersBusinessSectionsBusinessSectionMemoReviewsMutateResponseType } from "~/domains";

type RequestDataType = {
  businessSectionId: string;
  businessSectionMemoReviewId: string;
};

const request = ({
  businessSectionId,
  businessSectionMemoReviewId,
}: RequestDataType) => {
  return new ApiClient()
    .put<ApiManagersBusinessSectionsBusinessSectionMemoReviewsMutateResponseType>(
      `/api/managers/business_sections/${businessSectionId}/business_section_memo_reviews/${businessSectionMemoReviewId}/completeds`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersBusinessSectionsBusinessSectionMemoReviewsCompletedsUpdate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ApiManagersBusinessSectionsBusinessSectionMemoReviewsMutateResponseType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
