import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisPartnerConsolesCompaniesBusinessSectionRelationEmployeesIndexRequestType,
  ApisPartnerConsolesCompaniesBusinessSectionRelationEmployeesIndexResponseType,
} from "~/domains";

type RequestDataType = {
  companyId: string;
  params?: ApisPartnerConsolesCompaniesBusinessSectionRelationEmployeesIndexRequestType;
};

const request = async ({
  companyId,
  params,
}: RequestDataType): Promise<ApisPartnerConsolesCompaniesBusinessSectionRelationEmployeesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApisPartnerConsolesCompaniesBusinessSectionRelationEmployeesIndexResponseType>(
      `/api/partner_consoles/companies/${companyId}/business_section_relation_employees`,
      { ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  companyId: string;
  params?: ApisPartnerConsolesCompaniesBusinessSectionRelationEmployeesIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisPartnerConsolesCompaniesBusinessSectionRelationEmployeesIndex =
  ({
    companyId,
    params,
    config = {},
  }: PropsType): UseQueryResult<ApisPartnerConsolesCompaniesBusinessSectionRelationEmployeesIndexResponseType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisPartnerConsolesCompaniesBusinessSectionRelationEmployeesIndex",
        companyId,
        params,
      ],
      queryFn: () => request({ companyId, params }),
    });
  };
