import React, { FC } from "react";

import {
  useApisAnalyticsPlansEmployeesMeetingNoteCalendarsIndex,
  usePrevNextSelectMonth,
} from "~/hooks";

import { ActivityOriginalCalendar } from "~/components/molecules";

type PropsType = {
  employeeId: string;
  className?: string;
};

export const MeetingNoteEmployeeDailyCountCalendar: FC<PropsType> = ({
  employeeId,
  className = "",
}: PropsType) => {
  const {
    targetDate,
    targetDateString,
    handlePrevMonthClick,
    handleNextMonthClick,
  } = usePrevNextSelectMonth();

  const { data, isLoading } =
    useApisAnalyticsPlansEmployeesMeetingNoteCalendarsIndex({
      employeeId,
      params: {
        targetDate: targetDateString,
      },
      config: {
        enabled: !!employeeId,
      },
    });

  return (
    <ActivityOriginalCalendar
      title="実施カレンダー"
      isLoading={isLoading}
      dailyActivity={data?.postCalendars}
      targetDate={targetDate}
      onPrevMonthClick={handlePrevMonthClick}
      onNextMonthClick={handleNextMonthClick}
      isForRate={false}
      className={className}
    />
  );
};
