import { FormEvent, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useApisAdminOperatorsCompaniesEdit,
  useApisAdminOperatorsCompaniesUpdate,
  useInput,
} from "~/hooks";

type ReturnType = {
  isSubmitting: boolean;
  name: string;
  subdomain: string;
  onChangeName: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeSubdomain: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const useComponentsAdminOperatorsCompaniesUpdateForm = (
  id: string,
): ReturnType => {
  const navigate = useNavigate();

  const [{ value: subdomain, onChange: onChangeSubdomain }, setSubdomain] =
    useInput("");
  const [{ value: name, onChange: onChangeName }, setName] = useInput("");

  useApisAdminOperatorsCompaniesEdit({
    id,
    config: {
      onSuccess: (data) => {
        setSubdomain(data.company.subdomain);
        setName(data.company.name);
      },
    },
  });
  const { mutate, isLoading: isSubmitting } =
    useApisAdminOperatorsCompaniesUpdate();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      {
        id,
        body: {
          subdomain,
          name,
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate(`/admin_operators/companies/${id}`);
        },
      },
    );
  };
  return {
    subdomain,
    name,
    isSubmitting,
    onChangeSubdomain,
    onChangeName,
    handleSubmit,
  };
};
