import React, { useState, FC, MouseEvent } from "react";

import { QueryObserverResult } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  useEditorState,
  useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesCreate,
} from "~/hooks";

import {
  FocusChallengeEmployeeReplyDetailWithEditForm,
  FocusChallengeEmployeeDetail,
  TextEditor,
} from "~/components/organisms";

import {
  ApiMembersFocusChallengeRepliesIndexType,
  AvatarAndNameEmployeeType,
  FocusChallengeEmployeeReplyType,
  FocusChallengeEmployeeType,
  PreviewWithFileType,
} from "~/domains";

type PropsType = {
  highlightFocusChallengeEmployeeReplyId?: string;
  mentions: AvatarAndNameEmployeeType[];
  focusChallengeEmployee: FocusChallengeEmployeeType;
  focusChallengeReplies: FocusChallengeEmployeeReplyType[];
  className?: string;
  openReply: (focusChallengeEmployee: FocusChallengeEmployeeType) => void;
  resetPageFetch: () => Promise<
    QueryObserverResult<ApiMembersFocusChallengeRepliesIndexType, unknown>
  >;
};

export const FocusChallengeEmployeeRepliesWithFocusChallengeEmployee: FC<
  PropsType
> = ({
  highlightFocusChallengeEmployeeReplyId = "",
  mentions,
  focusChallengeEmployee,
  focusChallengeReplies,
  className = "",
  openReply,
  resetPageFetch,
}: PropsType) => {
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const [
    {
      value: editorState,
      onChange: setEditorState,
      jsonContent,
      hasText,
      setPlainText,
    },
  ] = useEditorState();

  const { mutate, isLoading } =
    useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesCreate();

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!hasText) {
      toast.error("テキストが空です");
      return;
    }
    const body = {
      content: jsonContent,
      attachFiles: files,
    };

    mutate(
      {
        focusChallengeEmployeeId: focusChallengeEmployee.id,
        body,
      },
      {
        onSuccess: async () => {
          setPlainText("");
          setFiles([]);
          await resetPageFetch();
        },
      },
    );
  };
  return (
    <div className={className}>
      <div className="space-y-6">
        <FocusChallengeEmployeeDetail
          focusChallengeEmployee={focusChallengeEmployee}
          menuItems={[]}
          openReply={() => openReply(focusChallengeEmployee)}
          withFooter
        />
        <div>
          {focusChallengeReplies.map((focusReply) => (
            <FocusChallengeEmployeeReplyDetailWithEditForm
              key={focusReply.id}
              focusChallengeEmployeeReply={focusReply}
              mentions={mentions}
              refetch={resetPageFetch}
              isHighlight={
                highlightFocusChallengeEmployeeReplyId === focusReply.id
              }
            />
          ))}
        </div>
        <TextEditor
          submitButton={{
            isSubmitting: isLoading,
            onSubmit: handleSubmit,
          }}
          mentions={mentions}
          editorState={editorState}
          setEditorState={setEditorState}
          files={files}
          onChangeFiles={setFiles}
          isEmojiPickerTop
        />
      </div>
    </div>
  );
};
