import React, { useState, FC } from "react";

import {
  useRightSidebarState,
  useApisManagersFocusChallengesNew,
  useSearchManagersFocusChallengeEmployees,
  useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesIndex,
  useInterval,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  RightSidebarToggleIconWithLabel,
  InfiniteScroll,
  FilterIconWithSortMenu,
} from "~/components/molecules";
import {
  FocusChallengeEmployeeDetailWithEditForm,
  FocusChallengeEmployeeSearchForm,
  FocusChallengeEmployeeRepliesWithFocusChallengeEmployee,
} from "~/components/organisms";

import { FocusChallengeEmployeeType } from "~/domains";

export const ManagersFeedbackFocusChallengesIndex: FC = () => {
  const {
    isSearchOpen,
    isReplyOpen,
    searchOpenToggle,
    setReplyOpen,
    setClose,
    isOpen,
  } = useRightSidebarState("isClose");
  const [selectFocusChallengeEmployee, setSelectFocusChallengeEmployee] =
    useState<FocusChallengeEmployeeType>();

  const { data: fetchNewData } = useApisManagersFocusChallengesNew();

  const {
    focusChallengeEmployees,
    keyword,
    selectableDropdownCompleteStatusIds,
    selectedDropdownCompleteStatusId,
    selectableDropdownHasReplyIds,
    selectedDropdownHasReplyId,
    isLoading,
    hasNextPage,
    startDate,
    endDate,
    sort,
    isBelongingEmployee,
    selectableJobCategoryPhases,
    selectedJobCategoryPhases,
    selectableDropdownProvidingServices,
    selectedDropdownProvidingServices,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    selectableDropdownArchivedEmployees,
    selectedDropdownArchivedEmployees,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    onDivisionChange,
    onSectionChange,
    onEmployeesChange,
    onArchivedEmployeesChange,
    updateIndexFocusChallengeEmployee,
    deleteIndexFocusChallengeEmployee,
    fetchNextPage,
    setSort,
    onChangeIsBelongingEmployee,
    onChangeJobCategoryPhases,
    onChangeDropdownProvidingServices,
    onChangeDateRange,
    onChangeKeyword,
    onChangeDropdownCompleteStatusId,
    onChangeDropdownHasReplyId,
    onSearchSubmit,
    onConditionReset,
  } = useSearchManagersFocusChallengeEmployees();

  const { data: replayFetch, refetch } =
    useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesIndex({
      focusChallengeEmployeeId: selectFocusChallengeEmployee?.id || "",
      config: {
        enabled: !!selectFocusChallengeEmployee?.id,
      },
    });

  const breadCrumbItems = [
    {
      label: "チャレンジ確認",
      href: "/managers/feedback/focus_challenges",
    },
  ];

  const openReply = (focusChallengeEmployee: FocusChallengeEmployeeType) => {
    setSelectFocusChallengeEmployee(focusChallengeEmployee);
    setReplyOpen();
  };

  useInterval({
    onUpdate: async () => {
      isReplyOpen && selectFocusChallengeEmployee && (await refetch());
    },
    intervalMs: 1000,
  });

  const mentions = fetchNewData?.employees || [];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
        <FilterIconWithSortMenu
          likeSort={() => setSort("likeCountDesc")}
          createdAtAscSort={() => setSort("createdAtAsc")}
          createdAtDescSort={() => setSort("createdAtDesc")}
          currentSortType={sort}
          className="mt-6"
        />
        <InfiniteScroll
          itemsLength={focusChallengeEmployees.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isLoading}
          className="border-t-2 border-secondary-200"
        >
          {focusChallengeEmployees.map((post, index) => (
            <FocusChallengeEmployeeDetailWithEditForm
              key={post.id}
              mentions={mentions}
              selectableProvidingServices={
                fetchNewData?.selectableProvidingServices || []
              }
              selectableJobCategoryPhases={
                fetchNewData?.selectableJobCategoryPhases || []
              }
              focusChallengeEmployee={post}
              deleteItem={() => deleteIndexFocusChallengeEmployee(index)}
              updateItem={(newItem: FocusChallengeEmployeeType) =>
                updateIndexFocusChallengeEmployee(newItem, index)
              }
              openReply={openReply}
            />
          ))}
        </InfiniteScroll>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={setClose}
          label={isSearchOpen ? "検索条件" : "リプライ"}
          isOpen={isOpen}
        >
          {isSearchOpen && (
            <FocusChallengeEmployeeSearchForm
              onSubmit={onSearchSubmit}
              onConditionReset={onConditionReset}
              date={{
                startDate: startDate,
                endDate: endDate,
                onChange: onChangeDateRange,
              }}
              providingService={{
                options: selectableDropdownProvidingServices,
                value: selectedDropdownProvidingServices,
                onChange: onChangeDropdownProvidingServices,
              }}
              jobCategoryPhase={{
                options: selectableJobCategoryPhases,
                value: selectedJobCategoryPhases,
                onChange: onChangeJobCategoryPhases,
              }}
              isBelongingEmployee={{
                isChecked: isBelongingEmployee,
                onChange: onChangeIsBelongingEmployee,
              }}
              hasReply={{
                options: selectableDropdownHasReplyIds,
                value: selectedDropdownHasReplyId,
                onChange: onChangeDropdownHasReplyId,
              }}
              focusCompleted={{
                options: selectableDropdownCompleteStatusIds,
                value: selectedDropdownCompleteStatusId,
                onChange: onChangeDropdownCompleteStatusId,
              }}
              divisionAndSections={{
                selectableDivisions: selectableDropdownDivisions,
                selectedDivisions: selectedDropdownDivisions,
                selectableSections: selectableDropdownSections,
                selectedSection: selectedDropdownSections,
                selectableEmployees: selectableDropdownEmployees,
                selectedEmployees: selectedDropdownEmployees,
                selectableDropdownArchivedEmployees,
                selectedDropdownArchivedEmployees,
                onDivisionChange,
                onSectionChange,
                onEmployeesChange,
                onArchivedEmployeesChange,
              }}
              searchKeyword={{
                value: keyword,
                onChange: onChangeKeyword,
              }}
            />
          )}
          {isReplyOpen && selectFocusChallengeEmployee && (
            <FocusChallengeEmployeeRepliesWithFocusChallengeEmployee
              className="mt-6"
              mentions={mentions}
              focusChallengeEmployee={selectFocusChallengeEmployee}
              openReply={openReply}
              resetPageFetch={refetch}
              focusChallengeReplies={
                replayFetch?.focusChallengeEmployeeReplies || []
              }
            />
          )}
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
