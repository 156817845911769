import React, { FC } from "react";

import { LabelWithDropDownField } from "~/components/molecules";

import {
  convertParentToOptions,
  convertChildToOption,
  convertChildToOptions,
  convertOptionToParent,
  convertOptionToChild,
  selectableChildren,
  convertParentToOption,
} from "./CommonFunctions";

import {
  ParentType,
  ChildType,
  OptionType,
  DropDownOnChangeType,
  ChildOptionType,
} from "~/domains";

type PropsType = {
  allParents: ParentType[];
  parentValue?: ParentType | null;
  parentLabel?: string;
  parentRequired?: boolean;
  parentsOnChange: (newValue: ParentType | null) => void;
  allChildren: ChildType[];
  childValue?: ChildType | null;
  childLabel?: string;
  childRequired?: boolean;
  childrenOnChange: (newValue: ChildType | null) => void;
};

export const ParentWithChildDropdownField: FC<PropsType> = ({
  allParents,
  parentValue,
  parentLabel = "部署",
  parentsOnChange,
  parentRequired = false,
  allChildren,
  childValue,
  childLabel = "課",
  childrenOnChange,
  childRequired = false,
}: PropsType) => {
  const handleChangeParent = (parent: OptionType | null) => {
    const selectParent = parent ? convertOptionToParent(parent) : null;
    parentsOnChange(selectParent);
  };

  const handleChangeChildren = (child: ChildOptionType | null) => {
    const selectChildren = child ? convertOptionToChild(child) : null;
    childrenOnChange(selectChildren);
  };

  const children = selectableChildren(allChildren, parentValue);
  const parentDropDownItems = convertParentToOptions(allParents);
  const childDropDownItems = convertChildToOptions(children);

  return (
    <>
      <div>
        <LabelWithDropDownField
          required={parentRequired}
          labelText={parentLabel}
          name="parents"
          options={parentDropDownItems}
          value={parentValue && convertParentToOption(parentValue)}
          onChange={(newValue: DropDownOnChangeType<OptionType>) =>
            handleChangeParent(newValue as OptionType)
          }
        />
      </div>
      <div>
        <LabelWithDropDownField
          required={childRequired}
          labelText={childLabel}
          name="children"
          options={childDropDownItems}
          value={childValue && convertChildToOption(childValue)}
          onChange={(newValue: DropDownOnChangeType<OptionType>) =>
            handleChangeChildren(newValue as ChildOptionType)
          }
        />
      </div>
    </>
  );
};
