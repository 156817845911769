import { toDateString } from "~/libs";

import { SlideNavigationType } from "~/components/molecules";

type ReturnType = {
  navigations: SlideNavigationType[];
};

export const useMeetingNoteSlideNavigation = (
  date: Date,
  selectedArray: [boolean, boolean],
): ReturnType => {
  const stringDate = toDateString(date);
  const navigations = [
    {
      label: "入力",
      href: `/meeting_notes/new?date=${stringDate}`,
      selected: selectedArray[0],
    },
    {
      label: "一覧",
      href: `/meeting_notes?date=${stringDate}`,
      selected: selectedArray[1],
    },
  ];

  return {
    navigations: navigations,
  };
};
