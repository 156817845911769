import { useState, useCallback, useEffect } from "react";

import {
  BusinessDivisionType,
  BusinessSectionType,
  ParentType,
  ChildType,
} from "~/domains";

type PropsType = {
  selectableDivisions?: BusinessDivisionType[];
  defaultDivision?: BusinessDivisionType | null;
  selectableSections?: BusinessSectionType[];
  defaultSection?: BusinessSectionType | null;
};

type ReturnType = {
  selectableDivisions: BusinessDivisionType[];
  selectedDivision?: BusinessDivisionType | null;
  onDivisionChange: (newValue: ParentType | null) => void;
  selectableSections: BusinessSectionType[];
  selectedSection?: BusinessSectionType | null;
  onSectionChange: (newValue: ChildType | null) => void;
  optionSelectableSections: ChildType[];
  optionSelectedSection?: ChildType | null;
  setSelectedDivision: (newValue: BusinessDivisionType) => void;
  setSelectedSection: (newValue: BusinessSectionType) => void;
};

export const useDivisionSectionDropdown = ({
  selectableDivisions = [],
  defaultDivision = null,
  selectableSections = [],
  defaultSection = null,
}: PropsType): ReturnType => {
  const [selectedDivision, setSelectedDivision] = useState(defaultDivision);
  const [selectedSection, setSelectedSection] = useState(defaultSection);

  const convertChildrenToOption = useCallback(
    (sections: BusinessSectionType[]) => {
      return sections.map((section) => convertSectionToOption(section));
    },
    [],
  );

  const convertSectionToOption = (section: BusinessSectionType) => {
    return {
      id: section.id,
      name: section.name,
      parentId: section.businessDivisionId,
    };
  };

  const onBusinessDivisionChange = (
    newDivision: BusinessDivisionType | null,
  ) => {
    if (!newDivision) {
      setSelectedDivision(null);
      setSelectedSection(null);
      return;
    }
    const unSelectable =
      selectedSection?.businessDivisionId !== newDivision?.id;

    setSelectedDivision(newDivision);
    unSelectable && setSelectedSection(null);
  };

  const onBusinessSectionChange = (newSection: ChildType | null) => {
    if (!newSection) {
      setSelectedSection(null);
      return;
    }
    setSelectedSection({
      id: newSection.id,
      name: newSection.name,
      businessDivisionId: newSection.parentId,
    });
  };

  const optionSelectableSections = convertChildrenToOption(selectableSections);
  const optionSelectedSection =
    selectedSection && convertSectionToOption(selectedSection);

  useEffect(() => {
    defaultDivision && setSelectedDivision(defaultDivision);
  }, [defaultDivision]);

  useEffect(() => {
    defaultSection && setSelectedSection(defaultSection);
  }, [defaultSection]);

  return {
    selectableDivisions: selectableDivisions,
    selectedDivision: selectedDivision,
    onDivisionChange: onBusinessDivisionChange,
    selectableSections: selectableSections,
    selectedSection: selectedSection,
    onSectionChange: onBusinessSectionChange,
    optionSelectableSections: optionSelectableSections,
    optionSelectedSection: optionSelectedSection,
    setSelectedDivision: useCallback(
      (newValue: BusinessDivisionType) => setSelectedDivision(newValue),
      [],
    ),
    setSelectedSection: useCallback(
      (newValue: BusinessSectionType) => setSelectedSection(newValue),
      [],
    ),
  };
};
