import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersEmployeesLatestEmployeeCompetencyReviewsShowResponseType } from "~/domains";

type RequestDataType = {
  employeeId: string;
};

const request = async ({
  employeeId,
}: RequestDataType): Promise<ApiManagersEmployeesLatestEmployeeCompetencyReviewsShowResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersEmployeesLatestEmployeeCompetencyReviewsShowResponseType>(
      `/api/managers/employees/${employeeId}/latest_employee_competency_review`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  employeeId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersEmployeesLatestEmployeeCompetencyReviewsShow = ({
  employeeId,
  config = {},
}: PropsType): UseQueryResult<ApiManagersEmployeesLatestEmployeeCompetencyReviewsShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisManagersEmployeesLatestEmployeeCompetencyReviewShow",
      employeeId,
    ],
    queryFn: () => request({ employeeId }),
    cacheTime: 0,
  });
};
