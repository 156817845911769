import React, { FC } from "react";

import {
  useApisMembersRoleplayingConditionDailyRoleplayingTimesIndex,
  usePrevNextSelectMonth,
} from "~/hooks";

import { RoleplayingConditionChart } from "./RoleplayingConditionChart";

type PropsType = {
  title: string;
  chartType: "Bar" | "Area";
};

export const RoleplayingConditionCurrentEmployeeChartContainer: FC<
  PropsType
> = ({ title, chartType }: PropsType) => {
  const {
    targetDate,
    targetDateString,
    handlePrevMonthClick,
    handleNextMonthClick,
  } = usePrevNextSelectMonth();

  const { data } = useApisMembersRoleplayingConditionDailyRoleplayingTimesIndex(
    {
      params: {
        targetDate: targetDateString,
      },
    },
  );

  return (
    <RoleplayingConditionChart
      title={title}
      chartType={chartType}
      dailyRoleplayingTimes={data?.dailyRoleplayingTimes || []}
      targetDate={targetDate}
      handlePrevMonthClick={handlePrevMonthClick}
      handleNextMonthClick={handleNextMonthClick}
    />
  );
};
