import React, { FC } from "react";

import { LabelWithMultipleDropdownField } from "~/components/molecules";

import {
  convertParentToOptions,
  convertOptionToParents,
} from "./CommonFunctions";
import { MultiParentsWithMultiChildrenDropdownField } from "./MultiParentsWithMultiChildrenDropdownField";

import {
  ParentType,
  ChildType,
  OptionType,
  DropDownOnChangeType,
} from "~/domains";

type PropsType = {
  selectableDropdownDivisions: ParentType[];
  selectedDropdownDivisions: ParentType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  selectableDropdownEmployees: ParentType[];
  selectedDropdownEmployees: ParentType[];
  selectableDropdownArchivedEmployees: ParentType[];
  selectedDropdownArchivedEmployees: ParentType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  onEmployeesChange: (value: ParentType[]) => void;
  onArchivedEmployeesChange: (value: ParentType[]) => void;
};

export const DivisionAndSectionAndEmployeesDropdownField: FC<PropsType> = ({
  selectableDropdownDivisions,
  selectedDropdownDivisions,
  selectableDropdownSections,
  selectedDropdownSections,
  selectableDropdownEmployees,
  selectedDropdownEmployees,
  selectableDropdownArchivedEmployees,
  selectedDropdownArchivedEmployees,
  onDivisionChange,
  onSectionChange,
  onEmployeesChange,
  onArchivedEmployeesChange,
}: PropsType) => {
  return (
    <>
      <MultiParentsWithMultiChildrenDropdownField
        allParents={selectableDropdownDivisions}
        parentsValue={selectedDropdownDivisions}
        parentLabel="部署"
        parentsOnChange={onDivisionChange}
        allChildren={selectableDropdownSections}
        childrenValue={selectedDropdownSections}
        childLabel="課"
        childrenOnChange={onSectionChange}
      />
      <LabelWithMultipleDropdownField
        required={false}
        labelText="ユーザー"
        name="employees"
        options={convertParentToOptions(selectableDropdownEmployees || [])}
        value={convertParentToOptions(selectedDropdownEmployees || [])}
        onChange={(newValue: DropDownOnChangeType<OptionType>) => {
          onEmployeesChange(convertOptionToParents(newValue as OptionType[]));
        }}
      />
      <LabelWithMultipleDropdownField
        required={false}
        labelText="無効化ユーザー"
        name="archivedEmployees"
        options={convertParentToOptions(
          selectableDropdownArchivedEmployees || [],
        )}
        value={convertParentToOptions(selectedDropdownArchivedEmployees || [])}
        onChange={(newValue: DropDownOnChangeType<OptionType>) => {
          onArchivedEmployeesChange(
            convertOptionToParents(newValue as OptionType[]),
          );
        }}
      />
    </>
  );
};
