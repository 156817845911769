import React, { FC, FormEvent } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { toast } from "react-toastify";

import LogoLandscape from "~/assets/images/logos/insight_landscape.png";

import { SignInRequest } from "~/utils";

import { useApisEmployeeInvitesUpdate, useInput } from "~/hooks";

import {
  FormSubmitButton,
  Form,
  PasswordRuleMessage,
  SignInWrapper,
} from "~/components/atoms";
import { DisplayablePasswordFiled } from "~/components/molecules";

export const SignUpIndex: FC = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { mutate, isLoading } = useApisEmployeeInvitesUpdate();

  const [password] = useInput("");
  const [passwordConfirmation] = useInput("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      password: password.value,
      passwordConfirmation: passwordConfirmation.value,
      token: query.get("token") || "",
    };
    mutate(
      {
        id,
        body,
      },
      {
        onSuccess: async (data) => {
          const signInParams = {
            email: data.email,
            password: body.password,
          };
          await SignInRequest({
            isAdmin: false,
            signInParams: signInParams,
            onSuccess: () => {
              toast("ログインしました");
              navigate("/");
            },
            onError: (err) => {
              toast.error(err.message);
            },
          });
        },
      },
    );
  };

  return (
    <SignInWrapper>
      <div className="px-4 pb-14 pt-11 miniTablet:px-14 max-w-xl w-full bg-white border-secondary-400 border-solid border rounded-lg  shadow">
        <div className="flex justify-center w-8/12 mx-auto">
          <img
            src={LogoLandscape as string}
            alt="Logo"
            width="269"
            height="58.5"
          />
        </div>
        <h2 className="text-lg text-center text-secondary-600 mt-9">
          アカウント登録
        </h2>
        <Form className="mt-9 rounded-lg mx-auto" onSubmit={handleSubmit}>
          <DisplayablePasswordFiled
            label="パスワード"
            name="password"
            autoComplete="new-password"
            onChange={password.onChange}
            size="md"
          />
          <DisplayablePasswordFiled
            label="パスワード(確認用)"
            name="passwordConfirmation"
            autoComplete="new-password"
            onChange={passwordConfirmation.onChange}
            size="md"
            className="mt-6"
          />
          <PasswordRuleMessage className="mt-9" />
          <FormSubmitButton
            value="登録する"
            color="primary"
            isReadOnly={isLoading}
            size="lg"
            className="w-full mt-9"
          />
        </Form>
      </div>
    </SignInWrapper>
  );
};
