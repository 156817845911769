import React, {
  FC,
  useCallback,
  MouseEvent,
  FormEvent,
  ChangeEvent,
} from "react";

import update from "immutability-helper";

import {
  FormSubmitButton,
  Form,
  DragDropProvider,
  CatchTitleBorder,
} from "~/components/atoms";
import {
  LabelWithTextField,
  RemoveButtonWithTextField,
  AddFormFieldButton,
} from "~/components/molecules";

type NullAbleJobCategoryPhaseType = {
  id: string;
  name: string;
  isNewPhase?: boolean;
};

type PropsType = {
  isSubmitting: boolean;
  jobCategoryName: string;
  onChangeJobCategoryName: (e: ChangeEvent<HTMLInputElement>) => void;
  deleteJobCategoryPhaseIds?: string[];
  setDeleteJobCategoryPhaseIds?: (newValue: string[]) => void;
  jobCategoryPhases: NullAbleJobCategoryPhaseType[];
  setJobCategoryPhases: (newValue: NullAbleJobCategoryPhaseType[]) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const JobCategoryForm: FC<PropsType> = ({
  isSubmitting,
  jobCategoryName,
  onChangeJobCategoryName,
  deleteJobCategoryPhaseIds,
  setDeleteJobCategoryPhaseIds,
  jobCategoryPhases,
  setJobCategoryPhases,
  handleSubmit,
}: PropsType) => {
  const handleJobCategoryPhasesChange = (name: string, id: string) => {
    const phasesNames = jobCategoryPhases;
    const targetPhase = phasesNames.find((phase) => phase.id === id);
    if (!targetPhase) return;

    targetPhase.name = name;
    setJobCategoryPhases(phasesNames);
  };

  const handleMoveJobCategoryPhase = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = jobCategoryPhases[dragIndex];
      if (!dragCard) return;

      const newPhases = update(jobCategoryPhases, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      setJobCategoryPhases(newPhases);
    },
    [jobCategoryPhases, setJobCategoryPhases],
  );

  const addJobCategoryPhaseForm = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const newJobCategory = {
      id: (jobCategoryPhases.length + 1).toString(),
      name: "",
      isNewPhase: true,
    };
    setJobCategoryPhases([...jobCategoryPhases, newJobCategory]);
  };

  const removeJobCategoryPhaseForm = (
    e: MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    e.preventDefault();
    const phasesNames = jobCategoryPhases;
    const filteredPhase = phasesNames.filter((phase) => phase.id !== id);
    setJobCategoryPhases(filteredPhase);
    setDeleteJobCategoryPhaseIds &&
      setDeleteJobCategoryPhaseIds([...(deleteJobCategoryPhaseIds || []), id]);
  };

  return (
    <Form onSubmit={handleSubmit} className="space-y-6">
      <LabelWithTextField
        labelText="職種名"
        type="text"
        name="joCategoryName"
        required
        placeholder="職種名"
        value={jobCategoryName}
        onChange={onChangeJobCategoryName}
      />
      <CatchTitleBorder text="職種に紐づくインサイト項目" />
      <DragDropProvider>
        <div className="space-y-4">
          {jobCategoryPhases.map((phase, index) => (
            <RemoveButtonWithTextField
              key={`phase-${phase.id}-index-${index}`}
              name="jobCategoryPhases[]"
              placeholder="インサイト項目"
              index={index}
              id={phase.id}
              defaultValue={phase.name}
              onChange={handleJobCategoryPhasesChange}
              handleMove={handleMoveJobCategoryPhase}
              removeButtonOnClick={removeJobCategoryPhaseForm}
            />
          ))}
        </div>
      </DragDropProvider>
      <AddFormFieldButton onClick={addJobCategoryPhaseForm} />
      <FormSubmitButton
        color="primary"
        value="保存"
        className="w-full"
        isReadOnly={isSubmitting}
      />
    </Form>
  );
};
