import React, { FC } from "react";

import { useRoleplayingConditionSlideNavigation } from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  RoleplayingConditionCurrentEmployeeChartContainer,
  RoleplayingConditionCurrentEmployeeDailyCountCalendar,
} from "~/components/organisms";

export const MembersRoleplayingConditionDashboardShow: FC = () => {
  const { navigations } = useRoleplayingConditionSlideNavigation([
    false,
    true,
    false,
  ]);

  const breadCrumbItems = [
    { label: "ロールプレイング", href: "/roleplaying_conditions/new" },
    { label: "チャート", href: "/roleplaying_condition/dashboard" },
  ];

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
          <div className="space-y-9 mt-6">
            <RoleplayingConditionCurrentEmployeeChartContainer
              title="日毎のロールプレイング実施状況"
              chartType="Bar"
            />
            <RoleplayingConditionCurrentEmployeeChartContainer
              title="累計のロールプレイング実施状況"
              chartType="Area"
            />
          </div>
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          <RightSidebarTitle title="Information" />
          <RoleplayingConditionCurrentEmployeeDailyCountCalendar className="mt-6 mx-4" />
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
