import React, { FC, MouseEvent } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersEmployeesEmployeeCompetencyReviewsDestroy,
  useBoolean,
} from "~/hooks";

import { Button } from "~/components/atoms";
import { LabelWithTextArea, TogglablePostHeader } from "~/components/molecules";

import { EmployeeCompetencyReviewType } from "~/domains";

type PropsType = {
  employeeCompetencyReview: EmployeeCompetencyReviewType;
  className?: string;
  refetch: () => void;
};

export const EmployeeCompetencyReviewDetail: FC<PropsType> = ({
  employeeCompetencyReview,
  className,
  refetch,
}: PropsType) => {
  const { isChecked, toggle } = useBoolean(false);
  const { mutate: deleteRequest } =
    useApisManagersEmployeesEmployeeCompetencyReviewsDestroy();

  const handleToggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    toggle();
  };

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (confirm("本当に削除しますか？")) {
      deleteRequest(
        {
          id: employeeCompetencyReview.id,
          employeeId: employeeCompetencyReview.employee.id,
        },
        {
          onSuccess: () => {
            toast.success("削除しました");
            refetch();
          },
        },
      );
    }
  };

  return (
    <div className={`${className}`}>
      <div className="space-y-6">
        <TogglablePostHeader
          avatarUrl={employeeCompetencyReview.reviewer.avatarUrl}
          employeeName={employeeCompetencyReview.reviewer.name}
          createdAt={employeeCompetencyReview.createdAt}
          handleToggle={handleToggle}
          isOpen={isChecked}
        />
        {isChecked && (
          <>
            <LabelWithTextArea
              labelText="目標"
              name="goal"
              placeholder="目標"
              rows={6}
              value={employeeCompetencyReview.goal}
              disabled
            />
            <LabelWithTextArea
              labelText="強み"
              name="strength"
              placeholder="強み"
              rows={6}
              value={employeeCompetencyReview.strength}
              disabled
            />
            <LabelWithTextArea
              labelText="弱み"
              name="weakness"
              placeholder="弱み"
              rows={6}
              value={employeeCompetencyReview.weakness}
              disabled
            />
            <LabelWithTextArea
              labelText="特徴"
              name="competency"
              placeholder="特徴"
              rows={6}
              value={employeeCompetencyReview.competency}
              disabled
            />
            <LabelWithTextArea
              labelText="キャリアビジョン"
              name="careerVision"
              placeholder="キャリアビジョン"
              rows={6}
              value={employeeCompetencyReview.careerVision}
              disabled
            />
            <LabelWithTextArea
              labelText="プライベート"
              name="privateLife"
              placeholder="プライベート"
              rows={6}
              value={employeeCompetencyReview.privateLife}
              disabled
            />
            <LabelWithTextArea
              labelText="必要なサポート"
              name="needSupport"
              placeholder="必要なサポート"
              rows={6}
              value={employeeCompetencyReview.needSupport}
              disabled
            />
            <LabelWithTextArea
              labelText="備考"
              name="remark"
              placeholder="備考"
              rows={6}
              value={employeeCompetencyReview.remark}
              disabled
            />
            <div className="flex justify-end">
              <Button
                onClick={handleDelete}
                size="sm"
                color="red"
                text="削除する"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
