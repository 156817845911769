import React, { MouseEvent, FC } from "react";

import { ButtonWithIcon } from "~/components/atoms";

type PropsType = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

export const AddFormFieldButton: FC<PropsType> = ({
  onClick,
  className = "",
}: PropsType) => {
  return (
    <ButtonWithIcon
      className={`mx-auto block ${className}`}
      onClick={onClick}
      srOnlyText="フォームを追加する"
      icon={{
        icon: "ioAddCircleOutline",
        color: "text-secondary-600",
        className: "mx-auto",
      }}
    />
  );
};
