import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersEmployeeLimitSummariesResponseType } from "~/domains";

const request =
  async (): Promise<ApiManagersEmployeeLimitSummariesResponseType> => {
    const response =
      await new ApiClient().get<ApiManagersEmployeeLimitSummariesResponseType>(
        "/api/managers/employee/limit_summaries",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersEmployeeLimitSummariesShow = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiManagersEmployeeLimitSummariesResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersEmployeeLimitSummariesShow"],
    queryFn: request,
  });
};
