import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import {
  ApiManagersInsightPostsIndexResponseType,
  ApiManagersInsightPostsIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiManagersInsightPostsIndexRequestType;
  page?: number;
};

const request = async ({
  params,
  page = 1,
}: RequestDataType): Promise<ApiManagersInsightPostsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersInsightPostsIndexResponseType>(
      "/api/managers/insight_posts",
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiManagersInsightPostsIndexRequestType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisManagersInsightPostsIndex = ({
  params,
  config = {},
}: PropsType = {}): UseInfiniteQueryResult<ApiManagersInsightPostsIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApisManagersInsightPostsIndex", params],
    ({ pageParam }) => {
      return request({ params, page: pageParam as number | undefined });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
      useErrorBoundary: (error) => {
        return error instanceof ApiResponseError && error.status >= 500;
      },
    },
  );
};
