import React, { FC } from "react";

import { endOfMonth, startOfMonth } from "date-fns";

import {
  useApisMembersAnalysisDetailOfPostsIndex,
  useDateRange,
} from "~/hooks";

import { RangeDatePicker } from "~/components/molecules";
import { AnalysisDetailOfPostGraph } from "~/components/organisms";

type PropsType = {
  className?: string;
};

const today = new Date();

export const AnalysisDetailOfPosts: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  const [
    {
      startDate,
      formattedStartDate,
      endDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange([startOfMonth(today), endOfMonth(today)]);
  const { data, isLoading } = useApisMembersAnalysisDetailOfPostsIndex({
    params: {
      postDateFrom: formattedStartDate || "",
      postDateTo: formattedEndDate || "",
    },
    config: {
      enabled: !!(formattedStartDate && formattedEndDate),
    },
  });

  return (
    <div className={`${className}`}>
      <RangeDatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={onChangeDateRange}
        maxDate={today}
      />
      <div className="mt-9 space-y-9">
        <AnalysisDetailOfPostGraph
          title="インサイト"
          isLoading={isLoading}
          postAnalysis={data?.insightPostAnalysis}
        />
        <AnalysisDetailOfPostGraph
          title="チャレンジ"
          isLoading={isLoading}
          postAnalysis={data?.focusChallengeAnalysis}
        />
        <AnalysisDetailOfPostGraph
          title="改善・相談"
          isLoading={isLoading}
          postAnalysis={data?.kaizenIdeaAnalysis}
        />
        <AnalysisDetailOfPostGraph
          title="ナレッジシェアされた数"
          isLoading={isLoading}
          postAnalysis={data?.sharedInsightPostAnalysis}
        />
      </div>
    </div>
  );
};
