import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";

import { toDateString } from "~/libs";

import {
  useInput,
  useDateRange,
  useApisMembersSharedInsightPostsIndex,
  usePostSortType,
  useMultipleDropdown,
  useArray,
  useMultiDivisionMultiSectionDropdown,
  useApisMembersInsightPostSearchItemsIndex,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
  useMultiJobCategoryMultiJobCategoryPhaseDropdown,
} from "~/hooks";

import { RangeDatePropsType } from "~/components/molecules";

import {
  InsightPostType,
  MultiValueType,
  OptionType,
  SortType,
  ApiMembersInsightPostsIndexRequestType,
  ChildType,
  ParentType,
  SingleValueType,
  ID_ALL_TYPE,
} from "~/domains";

type ReturnType = {
  insightPosts: InsightPostType[];
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  hasNextPage?: boolean;
  isLoading: boolean;
  sort: SortType;
  selectableDropdownHasCommentIds: OptionType[];
  selectedDropdownHasCommentId?: SingleValueType<OptionType>;
  selectableDropdownHasBookmarkIds: OptionType[];
  selectedDropdownHasBookmarkId?: SingleValueType<OptionType>;
  selectableJobCategories: ParentType[];
  selectedJobCategories: ParentType[];
  optionSelectableJobCategoryPhases: ChildType[];
  optionSelectedJobCategoryPhases: ChildType[];
  selectedProvidingServices: MultiValueType<OptionType>;
  selectableProvidingServices: OptionType[];
  selectedDropdownEmployees: ParentType[];
  selectableDropdownEmployees: ParentType[];
  selectedDropdownArchivedEmployees: ParentType[];
  selectableDropdownArchivedEmployees: ParentType[];
  selectableDropdownDivisions: ParentType[];
  selectedDropdownDivisions: ParentType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  onEmployeesChange: (value: ParentType[]) => void;
  onArchivedEmployeesChange: (value: ParentType[]) => void;
  onChangeProvidingServices: (options: MultiValueType<OptionType>) => void;
  onChangeJobCategoryPhases: (options: ChildType[]) => void;
  onChangeJobCategories: (options: ParentType[]) => void;
  deleteIndexInsightPost: (index: number) => void;
  updateIndexInsightPost: (newItem: InsightPostType, index: number) => void;
  onChangeDropdownHasCommentId: (value: SingleValueType<OptionType>) => void;
  onChangeDropdownHasBookmarkId: (value: SingleValueType<OptionType>) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  setSort: (val: SortType) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchManagersSharedInsightPosts = (): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const { sort, snakeKeyValue, setSort } = usePostSortType();

  const { data: fetchSearchItemData } =
    useApisMembersInsightPostSearchItemsIndex({
      config: {
        onSuccess: (data) => {
          setSelectableProvidingServices([
            ID_ALL_TYPE,
            ...data.providingServices,
          ]);
        },
      },
    });
  const [
    {
      statusValue: hasComment,
      selectedDropdownCompleteStatusId: selectedDropdownHasCommentId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasCommentIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasCommentId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "リプライあり",
      false: "リプライなし",
    },
  });

  const {
    selectableJobCategories,
    selectedJobCategories,
    onChangeJobCategories,
    optionSelectableJobCategoryPhases,
    optionSelectedJobCategoryPhases,
    onChangeJobCategoryPhases,
  } = useMultiJobCategoryMultiJobCategoryPhaseDropdown({
    selectableJobCategories: fetchSearchItemData?.jobCategories,
    selectableJobCategoryPhases: fetchSearchItemData?.jobCategoryPhases,
    withAllJobCategoryPhase: true,
  });

  const [
    {
      statusValue: hasBookmark,
      selectedDropdownCompleteStatusId: selectedDropdownHasBookmarkId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasBookmarkIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasBookmarkId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "ブックマークあり",
      false: "ブックマークなし",
    },
  });
  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
    selectableEmployees,
    selectedEmployees,
    onEmployeesChange,
    selectableArchivedEmployees,
    selectedArchivedEmployees,
    onArchivedEmployeesChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: fetchSearchItemData?.businessDivisions,
    selectableSections: fetchSearchItemData?.businessSections,
    isSelectEmployee: true,
  });
  const [params, setParams] = useState<ApiMembersInsightPostsIndexRequestType>({
    sortType: snakeKeyValue,
  });

  const { items, setItems, deleteIndexItem, updateIndexItem } =
    useArray<InsightPostType>([]);

  const [
    {
      dropdownValue: selectedProvidingServices,
      dropdownSelectableValue: selectableProvidingServices,
      onChange: onChangeProvidingServices,
      setSelectableOption: setSelectableProvidingServices,
    },
  ] = useMultipleDropdown();

  const { fetchNextPage, hasNextPage, isFetching } =
    useApisMembersSharedInsightPostsIndex({
      params: { ...params },
      config: {
        onSuccess: (data) => {
          setItems(data.pages.map((page) => page.insightPosts).flat());
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      employeeIds: [...selectedEmployees, ...selectedArchivedEmployees].map(
        (ele) => ele.id,
      ),
      hasComment,
      hasBookmark,
      jobCategoryIds: selectedJobCategories?.map(
        (jobCategory) => jobCategory.id,
      ),
      jobCategoryPhaseIds: optionSelectedJobCategoryPhases?.map(
        (ele) => ele.id,
      ),
      providingServiceIds: selectedProvidingServices?.map((ele) => ele.value),
      sortType: snakeKeyValue,
      sharedBusinessDivisionIds: selectedDivisions.map((ele) => ele.id),
      sharedBusinessSectionIds: optionSelectedSections.map((ele) => ele.id),
      createdAtFrom: startDate ? toDateString(startDate) : "",
      createdAtTo: endDate ? toDateString(endDate) : "",
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownHasBookmarkId(null);
    onChangeDropdownHasCommentId(null);
    onChangeDateRange([null, null]);
    onChangeJobCategories([]);
    onChangeJobCategoryPhases([]);
    onChangeProvidingServices([]);
    onDivisionChange([]);
    onSectionChange([]);
    onEmployeesChange([]);
    onArchivedEmployeesChange([]);
    setParams({ sortType: snakeKeyValue });
    setFalseRightSidebarMain();
  };

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      sortType: snakeKeyValue,
    }));
  }, [snakeKeyValue]);

  return {
    insightPosts: items,
    isLoading: isFetching,
    keyword,
    hasNextPage,
    startDate,
    endDate,
    sort,
    selectableDropdownHasCommentIds,
    selectedDropdownHasCommentId,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    selectableJobCategories,
    selectedJobCategories,
    optionSelectableJobCategoryPhases,
    optionSelectedJobCategoryPhases,
    selectedProvidingServices,
    selectableProvidingServices,
    selectedDropdownEmployees: selectedEmployees,
    selectableDropdownEmployees: selectableEmployees,
    selectableDropdownDivisions: selectableDivisions,
    selectedDropdownDivisions: selectedDivisions,
    selectableDropdownSections: optionSelectableSections,
    selectedDropdownSections: optionSelectedSections,
    selectableDropdownArchivedEmployees: selectableArchivedEmployees,
    selectedDropdownArchivedEmployees: selectedArchivedEmployees,
    onDivisionChange,
    onSectionChange,
    onEmployeesChange,
    onArchivedEmployeesChange,
    updateIndexInsightPost: updateIndexItem,
    deleteIndexInsightPost: deleteIndexItem,
    fetchNextPage,
    setSort,
    onChangeDropdownHasCommentId,
    onChangeDropdownHasBookmarkId,
    onChangeJobCategories,
    onChangeProvidingServices,
    onChangeJobCategoryPhases,
    onChangeDateRange,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  };
};
