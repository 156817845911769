import { ChangeEvent, FormEvent, MouseEvent, useState } from "react";

import { toDateString } from "~/libs";

import {
  ApiMembersTermEndSkillEvaluationTermsIndexParamsType,
  useInput,
  useApisMembersTermEndSkillEvaluationTermsIndex,
  useDateRange,
  useArray,
  useProvidersEmployeeLayout,
} from "~/hooks";

import { RangeDatePropsType } from "~/components/molecules";

import { EmployeeRole, SkillEvaluationTermType } from "~/domains";

type ReturnType = {
  managersSkillEvaluationTerms: SkillEvaluationTermType[];
  isManagersDataLoading: boolean;
  ownersSkillEvaluationTerms: SkillEvaluationTermType[];
  isOwnersDataLoading: boolean;
  titleKeyword: string;
  startDate: Date | null;
  endDate: Date | null;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeTitleKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  managersHasNextPage?: boolean;
  managersFetchNextPage: () => void;
  ownersHasNextPage?: boolean;
  ownersFetchNextPage: () => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchMembersTermEndSkillEvaluationTerms = (): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const [
    { value: titleKeyword, onChange: onChangeTitleKeyword },
    setTitleKeyword,
  ] = useInput("");
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const [params, setParams] =
    useState<ApiMembersTermEndSkillEvaluationTermsIndexParamsType>();

  const {
    items: managersSkillEvaluationTerms,
    setItems: setManagersSkillEvaluationTerms,
  } = useArray<SkillEvaluationTermType>([]);

  const {
    items: ownersSkillEvaluationTerms,
    setItems: setOwnersSkillEvaluationTerms,
  } = useArray<SkillEvaluationTermType>([]);

  const {
    fetchNextPage: managersFetchNextPage,
    hasNextPage: managersHasNextPage,
    isFetching: isManagersDataLoading,
  } = useApisMembersTermEndSkillEvaluationTermsIndex({
    params: { employeeRoleId: EmployeeRole.MANAGER.id, ...params },
    config: {
      onSuccess: (data) => {
        setManagersSkillEvaluationTerms(
          data.pages.map((page) => page.skillEvaluationTerms).flat(),
        );
      },
    },
  });

  const {
    fetchNextPage: ownersFetchNextPage,
    hasNextPage: ownersHasNextPage,
    isFetching: isOwnersDataLoading,
  } = useApisMembersTermEndSkillEvaluationTermsIndex({
    params: { employeeRoleId: EmployeeRole.OWNER.id, ...params },
    config: {
      onSuccess: (data) => {
        setOwnersSkillEvaluationTerms(
          data.pages.map((page) => page.skillEvaluationTerms).flat(),
        );
      },
    },
  });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      titleKeyword,
      fromDate: startDate ? toDateString(startDate) : "",
      toDate: endDate ? toDateString(endDate) : "",
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setTitleKeyword("");
    onChangeDateRange([null, null]);
    setParams(undefined);
    setFalseRightSidebarMain();
  };

  return {
    managersSkillEvaluationTerms,
    titleKeyword,
    managersHasNextPage,
    managersFetchNextPage,
    ownersSkillEvaluationTerms,
    ownersHasNextPage,
    startDate,
    endDate,
    isManagersDataLoading,
    isOwnersDataLoading,
    onChangeDateRange,
    ownersFetchNextPage,
    onChangeTitleKeyword,
    onSearchSubmit,
    onConditionReset,
  };
};
