import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiKpiObjectivesEmployeesClientCompaniesIndex } from "~/domains";

type RequestDataType = {
  kpiObjectiveId: string;
  employeeId: string;
  params?: {
    fromDate?: string;
    toDate?: string;
  };
};

const request = async ({
  kpiObjectiveId,
  employeeId,
  params,
}: RequestDataType): Promise<ApiKpiObjectivesEmployeesClientCompaniesIndex> => {
  const response =
    await new ApiClient().get<ApiKpiObjectivesEmployeesClientCompaniesIndex>(
      `/api/members/kpi/kpi_objectives/${kpiObjectiveId}/employees/${employeeId}/client_companies`,
      { ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = RequestDataType & {
  params?: {
    fromDate?: string;
    toDate?: string;
  };
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersKpiKpiObjectivesEmployeesClientCompaniesIndex = ({
  params,
  kpiObjectiveId,
  employeeId,
  config = {},
}: PropsType): UseQueryResult<ApiKpiObjectivesEmployeesClientCompaniesIndex> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisMembersKpiKpiObjectivesEmployeesClientCompaniesIndex",
      kpiObjectiveId,
      employeeId,
      params,
    ],
    queryFn: () => request({ kpiObjectiveId, employeeId, params }),
  });
};
