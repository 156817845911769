import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersEmployeeSurveysSubmissionsCsvShowResponseType } from "~/domains";

type RequestDataType = {
  employeeSurveyId: string;
  params?: {
    startDate?: string;
    endDate?: string;
    employeeId?: string;
  };
};

const request = async ({
  employeeSurveyId,
  params,
}: RequestDataType): Promise<ApiManagersEmployeeSurveysSubmissionsCsvShowResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersEmployeeSurveysSubmissionsCsvShowResponseType>(
      `/api/managers/employee_surveys/${employeeSurveyId}/submissions_csv`,
      { ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  employeeSurveyId: string;
  params?: {
    startDate?: string;
    endDate?: string;
    employeeId?: string;
  };
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersEmployeeSurveysSubmissionSubmissionsCsvShow = ({
  employeeSurveyId,
  params,
  config = {},
}: PropsType): UseQueryResult<ApiManagersEmployeeSurveysSubmissionsCsvShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisManagersEmployeeSurveysSubmissionsCsvShow",
      employeeSurveyId,
      params,
    ],
    queryFn: () => request({ employeeSurveyId, params }),
  });
};
