import React, { FC } from "react";

import { NoContentMessage } from "~/components/atoms";
import { Loading } from "~/components/molecules";
import { InsightPostDetail } from "~/components/organisms";

import { InsightPostType } from "~/domains";

type PropsType = {
  title: string;
  isLoading: boolean;
  insightPosts?: InsightPostType[];
  className?: string;
};

export const RankingInsightPostList: FC<PropsType> = ({
  title,
  isLoading,
  insightPosts = [],
  className = "",
}: PropsType) => {
  return (
    <div className={className}>
      <h3 className="text-lg font-bold text-center">{title}</h3>
      {isLoading ? (
        <Loading />
      ) : insightPosts.length ? (
        <div className="mt-6 space-y-4">
          {insightPosts.map((post, index) => (
            <div key={post.id}>
              <span className="text-primary-600 font-black text-lg">
                {index + 1}.
              </span>
              <InsightPostDetail
                insightPost={post}
                openComment={() => undefined}
                menuItems={[]}
              />
            </div>
          ))}
        </div>
      ) : (
        <NoContentMessage text="データがありません" className="mt-4" />
      )}
    </div>
  );
};
