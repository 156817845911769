import React, { FC } from "react";

import { toDateString } from "~/libs";

import {
  useApisMembersKpiBusinessSectionsDashboardsShow,
  useDateRange,
  useDropdown,
  useKpiSlideNavigation,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { DropDownField, RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  KpiDailyPerformanceCalendar,
  KpiDashboardsShow,
} from "~/components/organisms";

const date = new Date();
export const MembersKpiBusinessSectionsDashboardShow: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";

  const [
    {
      dropdownValue: selectedBusinessSection,
      dropdownSelectableValue: selectableBusinessSections,
      onChange: onChangeBusinessSection,
    },
  ] = useDropdown({
    selectableValue: currentEmployee?.businessSections || [],
    initialValue: currentEmployee?.businessSections.find(
      (businessSection) => businessSection.id === businessSectionId,
    ),
  });
  const [
    {
      startDate,
      formattedStartDate,
      endDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange();

  const { navigations } = useKpiSlideNavigation({
    selectedArray: [false, true, false],
    businessSectionId: selectedBusinessSection?.value,
    date: date,
  });

  const breadCrumbItems = [
    {
      label: "KPI・KGI",
      href: `/kpi_daily_performances/${toDateString(
        date,
      )}?business_section_id=${selectedBusinessSection?.value}`,
    },
    {
      label: "チャート",
      href: `/kpi_business_section_dashboard?business_section_id=${selectedBusinessSection?.value}`,
    },
  ];

  const { data, isFetching } = useApisMembersKpiBusinessSectionsDashboardsShow({
    businessSectionId: selectedBusinessSection?.value || "",
    params: {
      fromDate: formattedStartDate || undefined,
      toDate: formattedEndDate || undefined,
    },
    config: {
      enabled: Boolean(selectedBusinessSection),
    },
  });

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <DropDownField
          placeholder="課を選択"
          name="businessSectionId"
          value={selectedBusinessSection}
          options={selectableBusinessSections}
          onChange={onChangeBusinessSection}
          parentClassName="w-full mt-6"
        />
        <KpiDashboardsShow
          rangeDatePicker={{
            startDate,
            endDate,
            onChangeDateRange,
          }}
          kpiTerm={data?.kpiTerm}
          kpiObjectiveDashboards={data?.kpiObjectiveDashboards}
          loading={isFetching}
          className="mt-6"
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        {selectedBusinessSection && (
          <>
            <RightSidebarTitle title="Information" />
            <KpiDailyPerformanceCalendar
              className="mx-4 mt-6"
              businessSectionId={selectedBusinessSection.value}
            />
          </>
        )}
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
