export type CompleteStatusType = typeof UnComplete | typeof Completed;

type StatusType = {
  id: 1 | 10 | 0;
  name: "未完了" | "完了" | "未設定";
  value: undefined | boolean;
};

export const UnComplete = {
  id: 1,
  name: "未完了",
  value: false,
} as const satisfies StatusType;

export const Completed = {
  id: 10,
  name: "完了",
  value: true,
} as const satisfies StatusType;

export const CompleteStatus = {
  UN_COMPLETE: UnComplete,
  COMPLETED: Completed,
} as const satisfies {
  UN_COMPLETE: StatusType;
  COMPLETED: StatusType;
};

export const AllCompleteStatus = [UnComplete, Completed];
