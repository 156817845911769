import React, { FC, ReactNode } from "react";

type PropsType = {
  className?: string;
  isHighlight?: boolean;
  children: ReactNode;
  skipHoverStyle?: boolean;
};

export const PostDetailWrapper: FC<PropsType> = ({
  className = "",
  isHighlight = false,
  children,
  skipHoverStyle = false,
}: PropsType) => {
  return (
    <div
      className={`py-4 border-b border-secondary-200 border-solid ${
        isHighlight
          ? "bg-yellow-50"
          : !skipHoverStyle && "hover:bg-secondary-100"
      } ${className}`}
    >
      {children}
    </div>
  );
};
