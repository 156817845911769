import React, { MouseEvent, useState, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useMultiDivisionMultiSectionDropdown,
  useQuestionAnswerForms,
  useDropdown,
  useApisManagersQuestionAnswerCategoriesUpdate,
  useBoolean,
} from "~/hooks";

import { Button, DragDropProvider, NoContentMessage } from "~/components/atoms";
import { VerticalProgressLine } from "~/components/atoms";
import {
  LabelWithTextField,
  TabList,
  AddFormFieldButton,
  LabelWithDropDownField,
  NameOrderableForm,
} from "~/components/molecules";
import { AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { QuestionAnswerForm } from "./QuestionAnswerForm";

import {
  QuestionAnswerCategoryWithQuestionAnswerType,
  PublicStatus,
  ProvidingServiceType,
  EmployeeProfileType,
  QuestionAnswerTagType,
  MultiValueType,
  OptionType,
} from "~/domains";

type PropsType = {
  currentEmployee: EmployeeProfileType;
  selectableProvidingServices: Pick<ProvidingServiceType, "id" | "name">[];
  questionAnswerCategory: QuestionAnswerCategoryWithQuestionAnswerType;
  selectableQuestionAnswerTags: QuestionAnswerTagType[];
};

export const QuestionAnswerCategoryWithQuestionAnswersForm: FC<PropsType> = ({
  currentEmployee,
  selectableProvidingServices,
  questionAnswerCategory,
  selectableQuestionAnswerTags,
}: PropsType) => {
  const navigate = useNavigate();
  const { mutate: updateRequest, isLoading: isUpdating } =
    useApisManagersQuestionAnswerCategoriesUpdate();

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const [{ value: name, onChange: onChangeName }] = useInput(
    questionAnswerCategory.name,
  );

  const [providingOptions] = useDropdown({
    selectableValue: selectableProvidingServices,
    initialValue: questionAnswerCategory.providingService || {
      id: "",
      name: "すべて",
    },
  });

  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: onChangeAllBusinessSectionsDistribution,
  } = useBoolean(questionAnswerCategory.allBusinessSectionsDistribution);

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee.businessDivisions || [],
    selectableSections: currentEmployee.businessSections || [],
    defaultDivisions: questionAnswerCategory.businessDivisions,
    defaultSections: questionAnswerCategory.businessSections,
  });

  const {
    isSubmitting,
    questionAnswers,
    selectableQuestionAnswerTagOptions,
    onChangeQuestionContent,
    onChangeAnswerContent,
    onChangeUseScene,
    onChangeImportantPoint,
    onChangeRemark,
    onChangeLink,
    saveQuestionAnswer,
    addQuestionAnswer,
    removeQuestionAnswer,
    changeDisplayOrder,
    setNotReadOnly,
    setReadOnly,
    handleTagNameCreate,
    onChangeTags,
  } = useQuestionAnswerForms({
    questionAnswerCategoryId: questionAnswerCategory.id,
    questionAnswers: questionAnswerCategory.questionAnswers,
    selectableQuestionAnswerTags,
  });
  const handleDraftSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isConfirm = confirm(
      "作成・編集した「各項目」を「保存」しなければ反映されません。配布を実行してよろしいですか？",
    );
    if (!isConfirm) return;

    handleSubmit(PublicStatus.DRAFTED.id);
  };

  const handlePublishSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isConfirm = confirm(
      "作成・編集した「各項目」を「保存」しなければ反映されません。配布を実行してよろしいですか？",
    );
    if (!isConfirm) return;

    handleSubmit(PublicStatus.PUBLISHED.id);
  };

  const handleSubmit = (publicStatusId: number) => {
    const body = {
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      name: name,
      providingServiceId: providingOptions.dropdownValue?.value || "",
      publicStatusId: publicStatusId,
      allBusinessSectionsDistribution,
    };
    updateRequest(
      {
        id: questionAnswerCategory.id,
        body,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate("/managers/question_answer_categories");
        },
      },
    );
  };

  const isCurrentQuestionAnswer = selectedTabIndex === 0;
  const savedQuestionAnswers = questionAnswers.filter(
    (questionAnswer) => !questionAnswer.isNew,
  );
  return (
    <>
      <div className="space-y-6">
        <AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentsOnChange={onDivisionChange}
          parentRequired={true}
          parentLabel="配布先: 部署"
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childrenOnChange={onSectionChange}
          childRequired={true}
          childLabel="配布先: 課"
          allBusinessSectionsDistribution={allBusinessSectionsDistribution}
          onChangeAllBusinessSectionsDistribution={
            onChangeAllBusinessSectionsDistribution
          }
          currentEmployeeRole={currentEmployee.employeeRole}
        />
        <LabelWithTextField
          labelText="カテゴリ名"
          type="text"
          name="name"
          placeholder="カテゴリ名"
          required
          value={name}
          onChange={onChangeName}
        />
        <LabelWithDropDownField
          labelText="サービス"
          name="providingService"
          options={providingOptions.dropdownSelectableValue}
          onChange={providingOptions.onChange}
          value={providingOptions.dropdownValue}
        />
        <TabList
          tabTexts={["作成", "並び替え"]}
          onClick={(index: number) => setSelectedTabIndex(index)}
          selectedIndex={selectedTabIndex}
        />
        {isCurrentQuestionAnswer ? (
          questionAnswers.length ? (
            <VerticalProgressLine>
              {questionAnswers.map((questionAnswer) => (
                <QuestionAnswerForm
                  isSubmitting={isSubmitting}
                  defaultAccordionOpen={Boolean(questionAnswer.isNew)}
                  key={questionAnswer.id}
                  questionAnswer={questionAnswer}
                  remove={() => removeQuestionAnswer(questionAnswer)}
                  save={() => saveQuestionAnswer(questionAnswer)}
                  setNotReadOnly={() => setNotReadOnly(questionAnswer)}
                  setReadOnly={() => setReadOnly(questionAnswer)}
                  onChangeQuestionContent={(newValue: string) =>
                    onChangeQuestionContent({
                      questionAnswer,
                      newValue,
                    })
                  }
                  onChangeAnswerContent={(newValue: string) =>
                    onChangeAnswerContent({
                      questionAnswer,
                      newValue,
                    })
                  }
                  onChangeUseScene={(newValue: string) =>
                    onChangeUseScene({
                      questionAnswer,
                      newValue,
                    })
                  }
                  onChangeImportantPoint={(newValue: string) =>
                    onChangeImportantPoint({
                      questionAnswer,
                      newValue,
                    })
                  }
                  onChangeRemark={(newValue: string) =>
                    onChangeRemark({
                      questionAnswer,
                      newValue,
                    })
                  }
                  handleTagNameCreate={(newValue: string) =>
                    handleTagNameCreate({
                      questionAnswer,
                      newValue,
                    })
                  }
                  onChangeTags={(newValue: MultiValueType<OptionType>) =>
                    onChangeTags({
                      questionAnswer,
                      newValue,
                    })
                  }
                  onChangeLink={(newValue: string) =>
                    onChangeLink({
                      questionAnswer,
                      newValue,
                    })
                  }
                  selectableQuestionAnswerTagOptions={
                    selectableQuestionAnswerTagOptions
                  }
                />
              ))}
            </VerticalProgressLine>
          ) : (
            <NoContentMessage text="Q&Aがありません" className="mt-4" />
          )
        ) : savedQuestionAnswers.length ? (
          <DragDropProvider>
            <VerticalProgressLine>
              {savedQuestionAnswers.map((questionAnswer, index) => (
                <NameOrderableForm
                  key={`questionAnswer${questionAnswer.id}`}
                  name={questionAnswer.questionContent}
                  index={index}
                  changeDisplayOrder={(beforeIndex: number, newIndex: number) =>
                    changeDisplayOrder(beforeIndex, newIndex)
                  }
                />
              ))}
            </VerticalProgressLine>
          </DragDropProvider>
        ) : (
          <NoContentMessage text="保存されたQ&Aがありません" className="mt-4" />
        )}
        {isCurrentQuestionAnswer && (
          <AddFormFieldButton onClick={addQuestionAnswer} />
        )}
        <div className="flex items-center space-x-4 mx-auto">
          <Button
            text="下書き保存"
            color="gray"
            outline
            className="w-full"
            onClick={handleDraftSubmit}
            readonly={isUpdating}
          />
          <Button
            text="配布をする"
            color="primary"
            className="w-full"
            onClick={handlePublishSubmit}
            readonly={isUpdating}
          />
        </div>
      </div>
    </>
  );
};
