import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { useApisManagersKpiTermsDashboardsShow, useDateRange } from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { KpiDashboardsShow } from "~/components/organisms";

export const ManagersKpiTermsDashboardShow: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const breadCrumbItems = [
    { label: "KPI・KGI", href: "/managers/kpi_terms" },
    {
      label: "状況確認",
      href: `/managers/kpi_terms/${id}/dashboard`,
    },
  ];
  const [
    {
      startDate,
      formattedStartDate,
      endDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange();

  const { data, isFetching } = useApisManagersKpiTermsDashboardsShow({
    kpiTermId: id,
    params: {
      fromDate: formattedStartDate || undefined,
      endDate: formattedEndDate || undefined,
    },
  });

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          <KpiDashboardsShow
            rangeDatePicker={{
              startDate,
              endDate,
              onChangeDateRange,
            }}
            kpiTerm={data?.kpiTerm}
            kpiObjectiveDashboards={data?.kpiObjectiveDashboards}
            loading={isFetching}
          />
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
