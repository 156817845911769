import React, { FC } from "react";

import {
  useSearchMembersTermEndSkillEvaluationTerms,
  useSkillEvaluationStandardSummaryCategorySlideNavigation,
} from "~/hooks";

import { EmployeeLayout } from "~/components/layouts";
import { SkillEvaluationTermsIndexTemplate } from "~/components/templates";

export const MembersSkillEvaluationTermsResultIndex: FC = () => {
  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";

  const breadCrumbItems = [
    {
      label: "スキルマップ",
      href: "/skill_evaluation_standard_summary_categories",
    },
    { label: "結果", href: "/skill_evaluation_term/results" },
  ];

  const { navigations } =
    useSkillEvaluationStandardSummaryCategorySlideNavigation({
      businessSectionId,
      selectedArray: [false, false, true],
    });

  const {
    managersSkillEvaluationTerms,
    ownersSkillEvaluationTerms,
    titleKeyword,
    startDate,
    endDate,
    isManagersDataLoading,
    isOwnersDataLoading,
    onChangeDateRange,
    onChangeTitleKeyword,
    managersHasNextPage,
    managersFetchNextPage,
    ownersHasNextPage,
    ownersFetchNextPage,
    onSearchSubmit,
    onConditionReset,
  } = useSearchMembersTermEndSkillEvaluationTerms();

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <SkillEvaluationTermsIndexTemplate
        managersSkillEvaluationTerms={managersSkillEvaluationTerms}
        ownersSkillEvaluationTerms={ownersSkillEvaluationTerms}
        titleKeyword={titleKeyword}
        startDate={startDate}
        endDate={endDate}
        isManagersDataLoading={isManagersDataLoading}
        isOwnersDataLoading={isOwnersDataLoading}
        onChangeDateRange={onChangeDateRange}
        onChangeTitleKeyword={onChangeTitleKeyword}
        managersHasNextPage={managersHasNextPage}
        managersFetchNextPage={managersFetchNextPage}
        ownersHasNextPage={ownersHasNextPage}
        ownersFetchNextPage={ownersFetchNextPage}
        onSearchSubmit={onSearchSubmit}
        onConditionReset={onConditionReset}
        navigations={navigations}
        inTerm={false}
      />
    </EmployeeLayout>
  );
};
