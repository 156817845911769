import React, { FC } from "react";

type PropsType = {
  className?: string;
  title: string;
  count: number;
  denominator?: number;
  unitName?: string;
};

export const CountDisplayBox: FC<PropsType> = ({
  className = "",
  title,
  count,
  denominator,
  unitName = "件",
}: PropsType) => {
  return (
    <div className={`mx-auto w-full ${className}`}>
      <h4 className="font-semibold text-primary-600 text-lg text-center">
        {title}
      </h4>
      <div className="relative shadow mt-4 px-16 pb-5 pt-4 rounded-md h-80 flex items-center justify-center text-center">
        <div className="flex items-end justify-center">
          <p className="text-6xl">{count}</p>
          <span className="ml-2">{unitName}</span>
          {denominator && (
            <div className="absolute bottom-4 right-4 text-right text-2xl">
              /<span className="ml-1">{denominator}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
