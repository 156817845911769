import React, { FC } from "react";

import { useEditorState } from "~/hooks";

import { Editor, PostedFilePreviews } from "~/components/molecules";
import { PostFooter } from "~/components/organisms";

import { PostFooterType } from "~/domains";
import { KnowledgeInsightPostType } from "~/domains";

type PropsType = {
  knowledgeInsightPost: KnowledgeInsightPostType;
  footers: PostFooterType[];
  rightFooters?: PostFooterType[];
};

export const KnowledgeInsightPostContent: FC<PropsType> = ({
  knowledgeInsightPost,
  footers,
  rightFooters,
}: PropsType) => {
  const [{ value, onChange }] = useEditorState(knowledgeInsightPost.content);
  return (
    <div className="pl-16 w-full mt-2">
      <Editor
        editorState={value}
        onChange={onChange}
        canFileInText={false}
        readOnly
        blockQuote={
          knowledgeInsightPost.knowledgeInsightPostQuotePost
            ? {
                content:
                  knowledgeInsightPost.knowledgeInsightPostQuotePost?.content,
                employee:
                  knowledgeInsightPost.knowledgeInsightPostQuotePost?.employee,
                files:
                  knowledgeInsightPost.knowledgeInsightPostQuotePost
                    ?.attachFiles,
                createdAt:
                  knowledgeInsightPost.knowledgeInsightPostQuotePost?.createdAt,
              }
            : undefined
        }
      />
      {Boolean(knowledgeInsightPost.attachFiles.length) && (
        <PostedFilePreviews
          files={knowledgeInsightPost.attachFiles}
          className="mt-4"
        />
      )}
      <PostFooter
        iconWithCounters={footers}
        rightIconWithCounters={rightFooters}
        className="mt-2"
      />
    </div>
  );
};
