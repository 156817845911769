import React, { FC } from "react";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { MeetingNoteTemplateForm } from "~/components/organisms";

export const ManagersMeetingNoteTemplatesNew: FC = () => {
  const breadCrumbItems = [
    { label: "1on1・MTGテンプレ", href: "/managers/meeting_note_templates" },
    { label: "新規作成", href: "/managers/meeting_note_templates/new" },
  ];

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          <MeetingNoteTemplateForm />
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
