import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import { ApiManagersInsightPostObjectivesEmployeeAndInsightPostObjectiveShowResponseType } from "~/domains";

type ParamsType = {
  insightPostObjectiveId: string;
  params?: {
    employeeId?: string;
  };
};

const request = async ({
  insightPostObjectiveId,
  params,
}: ParamsType): Promise<ApiManagersInsightPostObjectivesEmployeeAndInsightPostObjectiveShowResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersInsightPostObjectivesEmployeeAndInsightPostObjectiveShowResponseType>(
      `/api/managers/insight_post_objectives/${insightPostObjectiveId}/employee_and_insight_post_objective`,
      { ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  insightPostObjectiveId: string;
  params?: {
    employeeId?: string;
  };
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersInsightPostObjectivesEmployeeAndInsightPostObjectiveShow =
  ({
    insightPostObjectiveId,
    params,
    config = {},
  }: PropsType): UseQueryResult<ApiManagersInsightPostObjectivesEmployeeAndInsightPostObjectiveShowResponseType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisManagersInsightPostObjectivesEmployeeAndInsightPostObjectiveShow",
        insightPostObjectiveId,
        params,
      ],
      queryFn: () => request({ insightPostObjectiveId, params }),
      useErrorBoundary: (error) => {
        return error instanceof ApiResponseError && error.status >= 500;
      },
    });
  };
