import React, { FC, FormEvent, MouseEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useApisAdminOperatorsOperatorsEdit,
  useApisAdminOperatorsOperatorsUpdate,
  useApisAdminOperatorsOperatorsDestroy,
  useInput,
  useMultipleDropdown,
} from "~/hooks";

import { Button, Form, FormSubmitButton } from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import {
  LabelWithMultipleDropdownField,
  LabelWithTextField,
} from "~/components/molecules";

export const AdminOperatorsOperatorsEdit: FC = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();

  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: "/admin_operators",
    },
    {
      label: "運営者一覧",
      href: "/admin_operators/operators",
    },
    {
      label: "編集",
      href: `/admin_operators/operators/${id}/edit`,
    },
  ];

  const [{ value: name, onChange: onChangeName }, setName] = useInput("");
  const [{ value: email, onChange: onChangeEmail }, setEmail] = useInput("");
  const [
    {
      dropdownSelectableValue: dropdownSelectableCompanies,
      dropdownValue: dropdownCompanies,
      onChange: onChangeDropdownCompanies,
      setSelectableOption: setSelectableCompanies,
    },
  ] = useMultipleDropdown();

  useApisAdminOperatorsOperatorsEdit({
    id,
    config: {
      onSuccess: (data) => {
        setName(data.adminOperator.name);
        setEmail(data.adminOperator.email);
        setSelectableCompanies(data.companies);
        onChangeDropdownCompanies(
          data.adminOperator.companies.map((company) => ({
            label: company.name,
            value: company.id,
          })),
        );
      },
    },
  });

  const { isLoading: isSubmitting, mutate: updateRequest } =
    useApisAdminOperatorsOperatorsUpdate();

  const { isLoading: isDestroying, mutate: destroyRequest } =
    useApisAdminOperatorsOperatorsDestroy();

  const handleUpdate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    updateRequest(
      {
        id,
        body: {
          name,
          email,
          companyIds: dropdownCompanies.map((company) => company.value),
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate("/admin_operators/operators");
        },
      },
    );
  };

  const handleDestroy = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isConfirmed = confirm("本当に削除しますか？");
    if (!isConfirmed) return;

    destroyRequest(
      {
        id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate("/admin_operators/operators");
        },
      },
    );
  };

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      <div className="flex items-center justify-between">
        <h1 className="text-xl">パートナーアカウント更新</h1>
      </div>
      <Form className="mt-6 space-y-6" onSubmit={handleUpdate}>
        <LabelWithTextField
          labelText="名前"
          type="text"
          name="name"
          placeholder="名前"
          required
          value={name}
          onChange={onChangeName}
        />
        <LabelWithTextField
          labelText="メールアドレス"
          type="email"
          placeholder="email@example.com"
          name="email"
          autoComplete="email"
          required
          value={email}
          onChange={onChangeEmail}
        />
        <LabelWithMultipleDropdownField
          labelText="閲覧できる会社"
          required
          name="companyIds"
          options={dropdownSelectableCompanies}
          value={dropdownCompanies}
          onChange={onChangeDropdownCompanies}
        />
        <FormSubmitButton
          value="更新する"
          color="primary"
          className="w-full"
          isReadOnly={isSubmitting}
        />
      </Form>
      <div className="mt-6 text-right">
        <Button
          text="削除する"
          color="primary"
          onClick={handleDestroy}
          readonly={isDestroying}
          outline
          size="sm"
        />
      </div>
    </AdminOperatorLayout>
  );
};
