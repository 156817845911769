import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiMembersRoleplayingConditionsIndexResponseType,
  ApiMembersRoleplayingConditionsIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiMembersRoleplayingConditionsIndexRequestType;
  page?: number;
};

const request = async ({
  params,
  page = 1,
}: RequestDataType): Promise<ApiMembersRoleplayingConditionsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersRoleplayingConditionsIndexResponseType>(
      "/api/members/roleplaying_conditions",
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiMembersRoleplayingConditionsIndexRequestType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersRoleplayingConditionsIndex = ({
  params,
  config = {},
}: PropsType = {}): UseInfiniteQueryResult<ApiMembersRoleplayingConditionsIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApisMembersRoleplayingConditionsIndex", params],
    ({ pageParam }) => {
      return request({ params, page: pageParam as number | undefined });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
