import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import { ApiManagersDocumentFileMutateType } from "~/domains";

type RequestDataType = {
  documentFileCategoryId: string;
  body: {
    name: string;
    displayOrder: number;
    attachFile?: File;
    thumbnailImage?: File;
    tagNames: string[];
  };
};

const request = ({ documentFileCategoryId, body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ documentFile: body });
  return new ApiClient()
    .post<ApiManagersDocumentFileMutateType>(
      `/api/managers/document_file_categories/${documentFileCategoryId}/document_files`,
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersDocumentFileCategoriesDocumentFilesCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersDocumentFileMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
