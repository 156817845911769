import React, { FC, ReactNode, createContext } from "react";

import { useBoolean } from "~/hooks";

export const EmployeeLayoutContext = createContext<{
  isRightSidebarMain: boolean;
  setTrueRightSidebarMain: () => void;
  setFalseRightSidebarMain: () => void;
  toggleRightSidebarMain: () => void;
}>({
  isRightSidebarMain: false,
  setTrueRightSidebarMain: () => "",
  setFalseRightSidebarMain: () => "",
  toggleRightSidebarMain: () => "",
});

type PropsType = {
  children: ReactNode;
};

export const EmployeeLayoutProvider: FC<PropsType> = ({
  children,
}: PropsType) => {
  const {
    isChecked: isRightSidebarMain,
    toggle: toggleRightSidebarMain,
    setTrue: setTrueRightSidebarMain,
    setFalse: setFalseRightSidebarMain,
  } = useBoolean(false);

  return (
    <EmployeeLayoutContext.Provider
      value={{
        isRightSidebarMain,
        toggleRightSidebarMain,
        setTrueRightSidebarMain,
        setFalseRightSidebarMain,
      }}
    >
      {children}
    </EmployeeLayoutContext.Provider>
  );
};
