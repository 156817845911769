import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersMeetingNoteTemplateIndexRequestType,
  ApiManagersMeetingNoteTemplateIndexResponseType,
} from "~/domains";

type RequestDataType = {
  params?: ApiManagersMeetingNoteTemplateIndexRequestType;
  page?: number;
};

const request = async ({
  params,
  page = 1,
}: RequestDataType): Promise<ApiManagersMeetingNoteTemplateIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersMeetingNoteTemplateIndexResponseType>(
      "/api/managers/meeting_note_templates",
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiManagersMeetingNoteTemplateIndexRequestType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisManagersMeetingNoteTemplatesIndex = ({
  params,
  config = {},
}: PropsType = {}): UseInfiniteQueryResult<ApiManagersMeetingNoteTemplateIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApisManagersMeetingNoteTemplatesIndex", params],
    ({ pageParam }) => {
      return request({ params, page: pageParam as number | undefined });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
