import React, { FC, FormEvent, useState } from "react";

import { toast } from "react-toastify";

import {
  useEditorState,
  useApisMembersPrivatePersonalMemosCreate,
  useSearchMembersPrivatePersonalMemos,
  useRightSidebarState,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { RightSidebarToggleIconWithLabel } from "~/components/molecules";
import {
  PrivatePersonalMemosDetailWithEditForms,
  PrivatePersonalMemosForm,
  PrivatePersonalMemosSearchForm,
} from "~/components/organisms";

import { PreviewWithFileType } from "~/domains";

export const MembersPrivatePersonalMemosIndex: FC = () => {
  const { searchOpenToggle, setClose, isOpen } =
    useRightSidebarState("isClose");
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const { mutate: postRequest, isLoading: isSubmitting } =
    useApisMembersPrivatePersonalMemosCreate();

  const {
    privatePersonalMemos,
    isLoading,
    hasNextPage,
    keyword,
    startDate,
    endDate,
    fetchNextPage,
    unshiftItem,
    deleteIndexItem,
    updateIndexItem,
    onChangeDateRange,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  } = useSearchMembersPrivatePersonalMemos();

  const breadCrumbItems = [
    {
      label: "キャンパス",
      href: "/members/personal_private_memos",
    },
  ];

  const [
    {
      jsonContent: editorStateJsonContent,
      value: editorState,
      setPlainText,
      onChange: onChangeEditorState,
    },
  ] = useEditorState();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    postRequest(
      {
        body: {
          content: editorStateJsonContent,
          attachFiles: files,
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setPlainText("");
          setFiles([]);
          unshiftItem(data.privatePersonalMemo);
        },
      },
    );
  };

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <p className="rounded px-4 py-3 border border-solid border-primary-600 text-secondary-600">
          キャンパスは「誰にも見られることのない」個人用のメモ帳です。インサイトの下書き、タスクのメモ、ブレストなど、用途は自由ですので、あなた専用のメモ帳としてご活用ください。
        </p>
        <PrivatePersonalMemosForm
          editorState={editorState}
          onChangeEditorState={onChangeEditorState}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          files={files}
          onChangeFiles={setFiles}
          className="mt-9"
        />
        <PrivatePersonalMemosDetailWithEditForms
          privatePersonalMemos={privatePersonalMemos}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          deleteIndexPost={deleteIndexItem}
          updateIndexPost={updateIndexItem}
          className="mt-9"
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={setClose}
          label={"検索条件"}
          isOpen={isOpen}
        >
          {isOpen && (
            <PrivatePersonalMemosSearchForm
              date={{
                startDate: startDate,
                endDate: endDate,
                onChange: onChangeDateRange,
              }}
              searchKeyword={{
                value: keyword,
                onChange: onChangeKeyword,
              }}
              onSubmit={onSearchSubmit}
              onConditionReset={onConditionReset}
            />
          )}
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
