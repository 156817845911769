import React, { FC, MouseEvent } from "react";

import {
  useProvidersCurrentEmployee,
  useProvidersEmployeeLayout,
} from "~/hooks";

import { ButtonWithIcon } from "~/components/atoms";
import { BreadCrumbs } from "~/components/molecules";

import { ButtonWithDeliveryNotifications } from "./ButtonWithDeliveryNotifications";
import { LogoWithHeaderMenu } from "./LogoWithHeaderMenu";

import {
  BreadCrumbItemType,
  ApiMembersUnreadNotificationCountsType,
} from "~/domains";

type PropsType = {
  withRightSidebar: boolean;
  breadCrumbItems: BreadCrumbItemType[];
  unreadNotification?: ApiMembersUnreadNotificationCountsType;
};

export const Header: FC<PropsType> = ({
  withRightSidebar,
  breadCrumbItems,
  unreadNotification,
}: PropsType) => {
  const { isRightSidebarMain, toggleRightSidebarMain } =
    useProvidersEmployeeLayout();
  const { currentEmployee } = useProvidersCurrentEmployee();

  const handleToggleRightSidebarMain = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleRightSidebarMain();
  };

  return (
    <header className="sticky top-0 z-modal bg-white flex items-center border-b-2 border-solid border-secondary-200">
      <LogoWithHeaderMenu
        breadCrumbItems={breadCrumbItems}
        currentEmployee={currentEmployee}
        unreadNotification={unreadNotification}
        className="w-[20%] py-2.5 miniTablet:min-w-[250px] min-w-min"
      />
      <div className="w-[80%] flex items-center justify-between">
        <BreadCrumbs
          breadCrumbItems={breadCrumbItems}
          className={`pl-2 miniTablet:pl-6  ${
            withRightSidebar ? "w-full miniTablet:w-[62.5%]" : "w-[62.5%]"
          }`}
        />
        <div className="flex items-center justify-end py-4 pr-4 space-x-4 w-[37.5%] miniTablet:border-l border-solid border-secondary-200">
          {currentEmployee && (
            <div className="text-secondary-600 min-w-fit hidden miniTablet:block">
              {currentEmployee.company.name}
            </div>
          )}
          <ButtonWithDeliveryNotifications
            hasUnreadDeliveryNotification={
              !!unreadNotification?.deliveryNotifications.totalCount
            }
          />
          {handleToggleRightSidebarMain && (
            <ButtonWithIcon
              srOnlyText="メニューを開く"
              icon={{
                icon: "ioSwapHorizontalOutline",
                size: "1.5rem",
                color: isRightSidebarMain
                  ? "text-primary-600"
                  : "text-secondary-600",
              }}
              onClick={handleToggleRightSidebarMain}
              className="block miniTablet:hidden"
            />
          )}
        </div>
      </div>
    </header>
  );
};
