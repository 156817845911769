import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiMembersBookmarkedKnowledgeInsightPostsIndexResponseType,
  ApiMembersBookmarkedKnowledgeInsightPostsIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiMembersBookmarkedKnowledgeInsightPostsIndexRequestType;
  page?: number;
};

const request = async ({
  params,
  page = 1,
}: RequestDataType): Promise<ApiMembersBookmarkedKnowledgeInsightPostsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersBookmarkedKnowledgeInsightPostsIndexResponseType>(
      "/api/members/bookmarked_knowledge_insight_posts",
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiMembersBookmarkedKnowledgeInsightPostsIndexRequestType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersBookmarkedKnowledgeInsightPostsIndex = ({
  params,
  config = {},
}: PropsType = {}): UseInfiniteQueryResult<ApiMembersBookmarkedKnowledgeInsightPostsIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApisMembersBookmarkedKnowledgeInsightPostsIndex", params],
    ({ pageParam }) => {
      return request({ params, page: pageParam as number | undefined });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
