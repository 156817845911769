import update from "immutability-helper";
import { toast } from "react-toastify";

import {
  useApisManagersKpiTermsDisplayOrdersUpdate,
  useApisManagersKpiTermsKpiObjectivesCreate,
  useApisManagersKpiTermsKpiObjectivesDestroy,
  useApisManagersKpiTermsKpiObjectivesUpdate,
  useArray,
  useManagersKpiTermsKpiObjectivesDuplicatesCreate,
} from "~/hooks";

import {
  KpiObjectiveType,
  EmployeeKpiObjectiveType,
  AvatarAndNameEmployeeType,
} from "~/domains";

export type FormEmployeeKpiObjectiveType = Pick<
  EmployeeKpiObjectiveType,
  "employee"
> & {
  objectivePerformance: number | "";
  isReadOnly?: boolean;
};
export type FormKpiObjectiveType = Pick<
  KpiObjectiveType,
  "id" | "kpiTermId" | "title" | "unitName" | "displayOrder"
> & {
  employeeKpiObjectives: FormEmployeeKpiObjectiveType[];
  isNew?: boolean;
  isReadOnly?: boolean;
  objectivePerformance: number | "";
};

type OnChangeTitlePropsType = {
  kpiObjective: FormKpiObjectiveType;
  newValue: string;
};
type onChangeObjectivePerformanceType = {
  kpiObjective: FormKpiObjectiveType;
  newValue: number | "";
};
type OnChangeUnitNamePropsType = {
  kpiObjective: FormKpiObjectiveType;
  newValue: string;
};
type OnChangeEmployeeCountPropsType = {
  kpiObjective: FormKpiObjectiveType;
  employeeKpiObjective: FormEmployeeKpiObjectiveType;
  newValue: number | "";
};

type NewEmployeeProps = {
  kpiObjective: FormKpiObjectiveType;
  newEmployee: FormEmployeeKpiObjectiveType;
};

type onChangeSetEmployeePropsType = {
  kpiObjective: FormKpiObjectiveType;
  employeeKpiObjective: FormEmployeeKpiObjectiveType;
};

type ReturnType = {
  isSubmitting: boolean;
  kpiObjectives: FormKpiObjectiveType[];
  onChangeTitle: ({ kpiObjective, newValue }: OnChangeTitlePropsType) => void;
  onChangeUnsetEmployee: ({
    kpiObjective,
    employeeKpiObjective,
  }: onChangeSetEmployeePropsType) => void;
  onChangeSetEmployee: ({
    kpiObjective,
    employeeKpiObjective,
  }: onChangeSetEmployeePropsType) => void;
  onChangeObjectivePerformance: ({
    kpiObjective,
    newValue,
  }: onChangeObjectivePerformanceType) => void;
  onChangeUnitName: ({
    kpiObjective,
    newValue,
  }: OnChangeUnitNamePropsType) => void;
  onChangeEmployeeCount: ({
    kpiObjective,
    employeeKpiObjective,
    newValue,
  }: OnChangeEmployeeCountPropsType) => void;
  setNotReadOnly: (kpiObjective: FormKpiObjectiveType) => void;
  setReadOnly: (kpiObjective: FormKpiObjectiveType) => void;
  addKpiObjective: () => void;
  removeKpiObjective: (kpiObjective: FormKpiObjectiveType) => void;
  saveKpiObjective: (kpiObjective: FormKpiObjectiveType) => void;
  changeDisplayOrder: (beforeIndex: number, index: number) => void;
  employeeCountEvenUp: (kpi: FormKpiObjectiveType) => void;
  handleDuplicate: (kpiObjective: FormKpiObjectiveType) => void;
};

type PropsType = {
  kpiTermId: string;
  employees: AvatarAndNameEmployeeType[];
  kpiObjectives: FormKpiObjectiveType[];
};

export function useKpiObjectiveForms({
  employees,
  kpiTermId,
  kpiObjectives,
}: PropsType): ReturnType {
  const { mutate: deleteKpiObjective } =
    useApisManagersKpiTermsKpiObjectivesDestroy();
  const { mutate: postKpiObjective, isLoading: isCreating } =
    useApisManagersKpiTermsKpiObjectivesCreate();
  const { mutate: putKpiObjective, isLoading: isUpdating } =
    useApisManagersKpiTermsKpiObjectivesUpdate();
  const { mutate: putKpiObjectiveDisplayOrder } =
    useApisManagersKpiTermsDisplayOrdersUpdate();
  const { mutate: createKpiObjectiveDuplicate } =
    useManagersKpiTermsKpiObjectivesDuplicatesCreate();

  const defaultEmployeeNumbers = employees.map((employee) => ({
    employee,
    objectivePerformance: 0,
    isReadOnly: false,
  }));

  const defaultItemFormat = (id: string, displayOrder: number) => {
    return {
      id: id,
      kpiTermId,
      title: "目標名",
      unitName: "件",
      objectivePerformance: 0,
      employeeKpiObjectives: defaultEmployeeNumbers,
      isNew: true,
      isReadOnly: false,
      displayOrder: displayOrder,
    };
  };

  // NOTE: KPIに紐づく従業員に加えて、KPIに紐づかない従業員も含めた従業員一覧を生成する
  const generateEmployeeKpiObjective = (
    kpiObjective: FormKpiObjectiveType,
  ): FormEmployeeKpiObjectiveType[] => {
    const employeeIds = employees.map((employee) => employee.id);
    const registeredEmployeeKpiObjectives =
      kpiObjective.employeeKpiObjectives.map((employeeKpiObjective) => ({
        ...employeeKpiObjective,
        isReadOnly: !employeeIds.includes(employeeKpiObjective.employee.id),
      }));
    const registeredEmployeeIds = registeredEmployeeKpiObjectives.map(
      (employeeKpiObjective) => employeeKpiObjective.employee.id,
    );
    const unRegisteredEmployees = employees.filter(
      (employee) => !registeredEmployeeIds.includes(employee.id),
    );
    const unRegisteredEmployeeKpiObjectives: FormEmployeeKpiObjectiveType[] =
      unRegisteredEmployees.map((employee) => ({
        employee,
        objectivePerformance: "",
        isReadOnly: true,
      }));

    return [
      ...registeredEmployeeKpiObjectives,
      ...unRegisteredEmployeeKpiObjectives,
    ];
  };

  const { items, findAndReplace, pushItem, findAndRemove, setItems } =
    useArray<FormKpiObjectiveType>(
      kpiObjectives.length
        ? kpiObjectives.map((kpiObjective) => ({
            ...kpiObjective,
            isNew: false,
            isReadOnly: true,
            employeeKpiObjectives: generateEmployeeKpiObjective(kpiObjective),
          }))
        : [defaultItemFormat("1", 1)],
    );

  const addItem = () => {
    const order = Math.max(...items.map((item) => item.displayOrder)) + 1;
    pushItem(defaultItemFormat(order.toString(), order));
  };

  const changeDisplayOrder = (beforeIndex: number, newIndex: number) => {
    const targetKpiObjective = items[beforeIndex];
    if (!targetKpiObjective) return;

    const newItems = update(items, {
      $splice: [
        [beforeIndex, 1],
        [newIndex, 0, targetKpiObjective],
      ],
    });

    putKpiObjectiveDisplayOrder(
      {
        kpiTermId,
        body: generateOrderParams(newItems),
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setItems(newItems);
        },
      },
    );
  };

  const generateOrderParams = (newItems: FormKpiObjectiveType[]) => {
    return newItems
      .filter((item) => !item.isNew)
      .map((item, index) => ({
        id: item.id,
        displayOrder: index + 1,
      }));
  };

  const removeKpiObjective = (kpiObjective: FormKpiObjectiveType) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    kpiObjective.isNew
      ? findAndRemove((item) => item.id === kpiObjective.id)
      : deleteKpiObjective(
          {
            kpiTermId,
            id: kpiObjective.id,
          },
          {
            onSuccess: (data) => {
              findAndRemove((item) => item.id === kpiObjective.id);
              toast(data.message);
            },
          },
        );
  };

  const saveKpiObjective = (kpiObjective: FormKpiObjectiveType) => {
    kpiObjective.isNew
      ? handleCreate(kpiObjective)
      : handleUpdate(kpiObjective);
  };

  const handleCreate = (kpiObjective: FormKpiObjectiveType) => {
    postKpiObjective(
      {
        kpiTermId,
        body: generateParams(kpiObjective),
      },
      {
        onSuccess: (data) => {
          onSuccessAction(data.message, data.kpiObjective.id, kpiObjective);
        },
      },
    );
  };

  const handleDuplicate = (kpiObjective: FormKpiObjectiveType) => {
    const isConfirm = confirm("本当に複製しますか？");
    if (!isConfirm) return;

    createKpiObjectiveDuplicate(
      {
        kpiTermId,
        kpiObjectiveId: kpiObjective.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          pushItem({
            ...data.kpiObjective,
            isNew: false,
            isReadOnly: true,
            employeeKpiObjectives: generateEmployeeKpiObjective(kpiObjective),
          });
        },
      },
    );
  };

  const handleUpdate = (kpiObjective: FormKpiObjectiveType) => {
    putKpiObjective(
      {
        kpiTermId,
        id: kpiObjective.id,
        body: generateParams(kpiObjective),
      },
      {
        onSuccess: (data) => {
          onSuccessAction(data.message, data.kpiObjective.id, kpiObjective);
        },
      },
    );
  };

  const onSuccessAction = (
    successMessage: string,
    newKpiObjectiveId: string,
    kpiObjective: FormKpiObjectiveType,
  ) => {
    toast(successMessage);
    const newObject = {
      ...kpiObjective,
      id: newKpiObjectiveId,
      isNew: false,
      isReadOnly: true,
    };
    findAndReplace(newObject, (item) => item.id === kpiObjective.id);
  };

  const generateParams = (kpiObjective: FormKpiObjectiveType) => {
    return {
      title: kpiObjective.title,
      unitName: kpiObjective.unitName,
      objectivePerformance: Number(kpiObjective.objectivePerformance),
      displayOrder: kpiObjective.displayOrder,
      employeeKpiObjectives: kpiObjective.employeeKpiObjectives
        .filter((item) => !item.isReadOnly)
        .map((employeeKpiObjective) => ({
          employeeId: employeeKpiObjective.employee.id,
          objectivePerformance: Number(
            employeeKpiObjective.objectivePerformance,
          ),
        })),
    };
  };

  const onChangeTitle = ({
    kpiObjective,
    newValue,
  }: OnChangeTitlePropsType) => {
    const newObject = { ...kpiObjective, title: newValue };

    findAndReplace(newObject, (item) => item.id === kpiObjective.id);
  };

  const onChangeObjectivePerformance = ({
    kpiObjective,
    newValue,
  }: onChangeObjectivePerformanceType) => {
    const newObject = { ...kpiObjective, objectivePerformance: newValue };

    findAndReplace(newObject, (item) => item.id === kpiObjective.id);
  };

  const onChangeUnitName = ({
    kpiObjective,
    newValue,
  }: OnChangeUnitNamePropsType) => {
    const newObject = { ...kpiObjective, unitName: newValue };

    findAndReplace(newObject, (item) => item.id === kpiObjective.id);
  };

  const setNotReadOnly = (kpiObjective: FormKpiObjectiveType) => {
    const newObject = { ...kpiObjective, isReadOnly: false };

    findAndReplace(newObject, (item) => item.id === kpiObjective.id);
  };

  const setReadOnly = (kpiObjective: FormKpiObjectiveType) => {
    const newObject = { ...kpiObjective, isReadOnly: true };

    findAndReplace(newObject, (item) => item.id === kpiObjective.id);
  };

  const employeeCountEvenUp = (kpiObjective: FormKpiObjectiveType) => {
    if (!kpiObjective.employeeKpiObjectives?.length) return;
    if (!kpiObjective.objectivePerformance) return;

    const { objectivePerformance, employeeKpiObjectives } = kpiObjective;

    const copyEmployeeKpiObjectives = [...employeeKpiObjectives];

    copyEmployeeKpiObjectives.forEach(
      (employeeKpiObjective, index) =>
        (copyEmployeeKpiObjectives[index] = {
          ...employeeKpiObjective,
          objectivePerformance: employeeKpiObjective.isReadOnly
            ? ""
            : Math.ceil(
                objectivePerformance /
                  employeeKpiObjectives.filter((item) => !item.isReadOnly)
                    .length,
              ),
        }),
    );

    const newObject = {
      ...kpiObjective,
      employeeKpiObjectives: copyEmployeeKpiObjectives,
    };
    findAndReplace(newObject, (item) => item.id === kpiObjective.id);
  };

  const onChangeEmployeeCount = ({
    kpiObjective,
    employeeKpiObjective,
    newValue,
  }: OnChangeEmployeeCountPropsType) => {
    const newEmployee = {
      ...employeeKpiObjective,
      objectivePerformance: newValue,
    };
    newEmployeeKpiObjectiveValues({
      kpiObjective,
      newEmployee,
    });
  };

  const onChangeUnsetEmployee = ({
    kpiObjective,
    employeeKpiObjective,
  }: onChangeSetEmployeePropsType) => {
    const newEmployee: FormEmployeeKpiObjectiveType = {
      ...employeeKpiObjective,
      isReadOnly: true,
      objectivePerformance: "",
    };
    newEmployeeKpiObjectiveValues({
      kpiObjective,
      newEmployee,
    });
  };

  const onChangeSetEmployee = ({
    kpiObjective,
    employeeKpiObjective,
  }: onChangeSetEmployeePropsType) => {
    const newEmployee: FormEmployeeKpiObjectiveType = {
      ...employeeKpiObjective,
      isReadOnly: false,
      objectivePerformance: 0,
    };
    newEmployeeKpiObjectiveValues({
      kpiObjective,
      newEmployee,
    });
  };

  const newEmployeeKpiObjectiveValues = ({
    kpiObjective,
    newEmployee,
  }: NewEmployeeProps) => {
    const copy = [...kpiObjective.employeeKpiObjectives];
    const index = copy.findIndex(
      (item) => item.employee.id === newEmployee.employee.id,
    );

    if (index <= -1) return;

    copy[index] = newEmployee;

    const newObject = { ...kpiObjective, employeeKpiObjectives: copy };
    findAndReplace(newObject, (item) => item.id === kpiObjective.id);
  };

  return {
    isSubmitting: isCreating || isUpdating,
    kpiObjectives: items,
    onChangeUnsetEmployee,
    onChangeSetEmployee,
    setReadOnly,
    setNotReadOnly,
    onChangeTitle,
    onChangeObjectivePerformance,
    onChangeUnitName,
    onChangeEmployeeCount,
    addKpiObjective: addItem,
    removeKpiObjective,
    saveKpiObjective,
    employeeCountEvenUp,
    changeDisplayOrder,
    handleDuplicate,
  };
}
