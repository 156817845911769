import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiPartnerConsolesCompaniesAnalyticsSalesConceptCategoriesIndexResponseType,
  ApiPartnerConsolesCompaniesAnalyticsSalesConceptCategoriesIndexRequestType,
} from "~/domains";

type RequestDataType = {
  companyId: string;
  params?: ApiPartnerConsolesCompaniesAnalyticsSalesConceptCategoriesIndexRequestType;
};

const request = async ({
  params,
  companyId,
}: RequestDataType): Promise<ApiPartnerConsolesCompaniesAnalyticsSalesConceptCategoriesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiPartnerConsolesCompaniesAnalyticsSalesConceptCategoriesIndexResponseType>(
      `/api/partner_consoles/companies/${companyId}/analytics/sales_concept_categories`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiPartnerConsolesCompaniesAnalyticsSalesConceptCategoriesIndexRequestType;
  companyId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisPartnerConsolesCompaniesAnalyticsSalesConceptCategoriesIndex =
  ({
    params,
    companyId,
    config = {},
  }: PropsType): UseQueryResult<ApiPartnerConsolesCompaniesAnalyticsSalesConceptCategoriesIndexResponseType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisPartnerConsolesCompaniesAnalyticsSalesConceptCategoriesIndex",
        companyId,
        params,
      ],
      queryFn: () => request({ companyId, params }),
    });
  };
