import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersRoleplayingConditionsNewType } from "~/domains";

const request = async (): Promise<ApiMembersRoleplayingConditionsNewType> => {
  const response =
    await new ApiClient().get<ApiMembersRoleplayingConditionsNewType>(
      "/api/members/roleplaying_conditions/new",
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersRoleplayingConditionsNew = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiMembersRoleplayingConditionsNewType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersRoleplayingConditionsNew"],
    queryFn: request,
  });
};
