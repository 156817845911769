import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useApisMembersSkillEvaluationStandardSummaryCategoriesPageViewsCreate,
  useApisMembersSkillEvaluationStandardSummaryCategoriesShow,
  useSkillEvaluationStandardSummaryCategorySlideNavigation,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { SkillEvaluationStandardSummaryCategoryDetail } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const MembersSkillEvaluationStandardSummaryCategoriesShow: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";

  const { mutate: pageViewRequest } =
    useApisMembersSkillEvaluationStandardSummaryCategoriesPageViewsCreate();

  const { data, isError } =
    useApisMembersSkillEvaluationStandardSummaryCategoriesShow({
      id,
      config: {
        onSuccess: () => {
          pageViewRequest({ skillEvaluationStandardSummaryCategoryId: id });
        },
      },
    });
  const { navigations } =
    useSkillEvaluationStandardSummaryCategorySlideNavigation({
      businessSectionId,
      selectedArray: [true, false, false],
    });

  const breadCrumbItems = [
    {
      label: "スキルマップ",
      href: `/skill_evaluation_standard_summary_categories?business_section_id=${businessSectionId}`,
    },
    {
      label: "詳細",
      href: `/skill_evaluation_standard_summary_categories/${id}?business_section_id=${businessSectionId}`,
    },
  ];

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        {data && (
          <SkillEvaluationStandardSummaryCategoryDetail
            skillEvaluationStandardSummaryCategory={
              data.skillEvaluationStandardSummaryCategory
            }
            className="mt-6"
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
