import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import {
  ApiManagersEmployeeSurveyQuestionsMutateResponseType,
  ApiManagersEmployeeSurveyQuestionsMutateRequestType,
} from "~/domains";

type RequestDataType = {
  id: string;
  employeeSurveyId: string;
  body: ApiManagersEmployeeSurveyQuestionsMutateRequestType;
};

const request = ({ id, employeeSurveyId, body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ employeeSurveyQuestion: body });
  return new ApiClient()
    .put<ApiManagersEmployeeSurveyQuestionsMutateResponseType>(
      `/api/managers/employee_surveys/${employeeSurveyId}/employee_survey_questions/${id}`,
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersEmployeeSurveysEmployeeSurveyQuestionsUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersEmployeeSurveyQuestionsMutateResponseType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
