import React, { FC, useMemo } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

type PropsType = {
  dataSets: {
    label: string;
    value: number;
  }[];
};

// ランダムなカラーコードを生成
const generateColors = (count: number) => {
  return Array.from({ length: count }, (_, i) => {
    const hue = (i * 137.508) % 360; // 黄金角を使用してより均一な分布を得る
    const saturation = 65 + Math.random() * 10; // 彩度にわずかなランダム性を追加
    const lightness = 55 + Math.random() * 10; // 明度にわずかなランダム性を追加
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  });
};

export const PieChart: FC<PropsType> = ({ dataSets }) => {
  const colors = useMemo(
    () => generateColors(dataSets.length),
    [dataSets.length],
  );

  const labels = dataSets.map((dataSet) => dataSet.label);
  const data = {
    labels,
    datasets: [
      {
        labels,
        data: dataSets.map((dataSet) => dataSet.value),
        backgroundColor: colors,
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };
  return (
    <div className="w-full min-h-[250px]">
      <Pie data={data} width={170} />
    </div>
  );
};
