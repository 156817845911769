import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import {
  ApiMembersFocusChallengesMutateType,
  RawDraftContentState,
} from "~/domains";

type RequestDataType = {
  focusChallengeEmployeeId: string;
  body: {
    content: RawDraftContentState;
    attachFiles: File[];
    completedAt?: string;
  };
};

const request = ({ focusChallengeEmployeeId, body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({
    focusChallengeEmployeeEffort: body,
  });
  return new ApiClient()
    .post<ApiMembersFocusChallengesMutateType>(
      `/api/members/focus_challenge_employees/${focusChallengeEmployeeId}/focus_challenge_employee_efforts`,
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeEffortsCreate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ApiMembersFocusChallengesMutateType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
