import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApisPartnerConsolesCompaniesAnalyticsEmployeesInsightPostObjectivesIndexResponseTypes } from "~/domains";

type RequestDataType = {
  employeeId: string;
  companyId: string;
  page?: number;
};

const request = async ({
  employeeId,
  companyId,
  page = 1,
}: RequestDataType): Promise<ApisPartnerConsolesCompaniesAnalyticsEmployeesInsightPostObjectivesIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisPartnerConsolesCompaniesAnalyticsEmployeesInsightPostObjectivesIndexResponseTypes>(
      `/api/partner_consoles/companies/${companyId}/analytics/employees/${employeeId}/insight_post_objectives`,
      { page },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  employeeId: string;
  companyId: string;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisPartnerConsolesCompaniesAnalyticsEmployeesInsightPostObjectivesIndex =
  ({
    employeeId,
    companyId,
    config = {},
  }: PropsType): UseInfiniteQueryResult<ApisPartnerConsolesCompaniesAnalyticsEmployeesInsightPostObjectivesIndexResponseTypes> => {
    return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
      [
        "ApisPartnerConsolesCompaniesAnalyticsEmployeesInsightPostObjectivesIndex",
        companyId,
        employeeId,
      ],
      ({ pageParam }) => {
        return request({
          companyId,
          employeeId,
          page: pageParam as number | undefined,
        });
      },
      {
        ...config,
        getNextPageParam: (data) => {
          return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
        },
      },
    );
  };
