import React, { FC, FormEvent } from "react";

import { Form, FormSubmitButton } from "~/components/atoms";
import {
  TextEditor,
  AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField,
} from "~/components/organisms";

import {
  EditorStateType,
  AvatarAndNameEmployeeType,
  PreviewWithFileType,
  ParentType,
  BusinessDivisionType,
  ChildType,
  AttachFileType,
  RawDraftContentState,
  EmployeeRoleType,
} from "~/domains";

type PropsType = {
  isSubmitting: boolean;
  className?: string;
  mentions: AvatarAndNameEmployeeType[];
  files: PreviewWithFileType[];
  onChangeFiles: (files: PreviewWithFileType[]) => void;
  editorState: EditorStateType;
  readOnly?: boolean;
  setEditorState: (editorState: EditorStateType) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  blockQuote?: {
    content: RawDraftContentState;
    employee: AvatarAndNameEmployeeType;
    files: AttachFileType[];
    createdAt: string;
  };
  dropdownField: {
    selectableDivisions: BusinessDivisionType[];
    selectedDivisions?: BusinessDivisionType[];
    onDivisionChange: (newValue: ParentType[]) => void;
    optionSelectableSections: ChildType[];
    optionSelectedSections?: ChildType[];
    onSectionChange: (newValue: ChildType[]) => void;
    allBusinessSectionsDistribution: boolean;
    onChangeAllBusinessSectionsDistribution: (newValue: boolean) => void;
    currentEmployeeRole?: EmployeeRoleType;
  };
  required?: boolean;
  submitButtonLabel?: string;
};

export const KnowledgeInsightPostForm: FC<PropsType> = ({
  isSubmitting,
  className = "",
  mentions,
  files,
  onChangeFiles,
  editorState,
  readOnly = false,
  setEditorState,
  dropdownField,
  handleSubmit,
  required = false,
  submitButtonLabel = "投稿する",
  blockQuote,
}: PropsType) => {
  return (
    <Form className={`space-y-6 ${className}`} onSubmit={handleSubmit}>
      <AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField
        allParents={dropdownField.selectableDivisions}
        parentsValue={dropdownField.selectedDivisions}
        parentsOnChange={dropdownField.onDivisionChange}
        parentRequired
        allChildren={dropdownField.optionSelectableSections}
        childrenValue={dropdownField.optionSelectedSections}
        childrenOnChange={dropdownField.onSectionChange}
        childRequired
        allBusinessSectionsDistribution={
          dropdownField.allBusinessSectionsDistribution
        }
        onChangeAllBusinessSectionsDistribution={
          dropdownField.onChangeAllBusinessSectionsDistribution
        }
        currentEmployeeRole={dropdownField.currentEmployeeRole}
      />
      <TextEditor
        labelText="内容"
        required={required}
        readOnly={readOnly}
        mentions={mentions}
        editorState={editorState}
        setEditorState={setEditorState}
        files={files}
        onChangeFiles={onChangeFiles}
        blockQuote={blockQuote}
      />
      <FormSubmitButton
        color="primary"
        value={submitButtonLabel}
        isReadOnly={isSubmitting}
        className="w-full"
      />
    </Form>
  );
};
