import { useState, useCallback } from "react";

import {
  useApisPartnerConsolesCompaniesBusinessSectionRelationEmployeesIndex,
  useApisPartnerConsolesCompaniesBusinessDivisionsAndSectionsIndex,
  useArray,
} from "~/hooks";

import {
  BusinessDivisionType,
  BusinessSectionType,
  ParentType,
  ChildType,
  EmployeeRoleType,
} from "~/domains";

type PropsType = {
  companyId: string;
  isSelectEmployee?: boolean;
  selectableEmployeeRoles?: EmployeeRoleType[];
};

type ReturnType = {
  selectableDivisions: BusinessDivisionType[];
  selectedDivisions: BusinessDivisionType[];
  onDivisionChange: (newValue: ParentType[]) => void;
  selectableSections: BusinessSectionType[];
  selectedSections: BusinessSectionType[];
  onSectionChange: (newValue: ChildType[]) => void;
  optionSelectableSections: ChildType[];
  optionSelectedSections: ChildType[];
  setSelectedDivisions: (newValue: BusinessDivisionType[]) => void;
  setSelectedSections: (newValue: BusinessSectionType[]) => void;
  selectableEmployees: ParentType[];
  selectedEmployees: ParentType[];
  selectedEmployee: ParentType | undefined;
  onEmployeesChange: (newValue: ParentType[]) => void;
  onEmployeeChange: (newValue: ParentType | undefined) => void;
};

export const usePartnerConsolesMultiDivisionMultiSectionDropdown = ({
  companyId,
  isSelectEmployee = false,
  selectableEmployeeRoles = [],
}: PropsType): ReturnType => {
  const { items: selectedDivisions, setItems: setSelectedDivisions } =
    useArray<BusinessDivisionType>([]);
  const { items: selectedSections, setItems: setSelectedSections } =
    useArray<BusinessSectionType>([]);
  const { items: selectableDivisions, setItems: setSelectableDivisions } =
    useArray<BusinessDivisionType>([]);
  const { items: selectableSections, setItems: setSelectableSections } =
    useArray<BusinessSectionType>([]);

  useApisPartnerConsolesCompaniesBusinessDivisionsAndSectionsIndex({
    companyId,
    config: {
      onSuccess: (res) => {
        setSelectableDivisions(res.businessDivisions);
        setSelectableSections(res.businessSections);
      },
      enabled: !!companyId,
    },
  });

  const { items: selectableEmployees, setItems: setSelectableEmployees } =
    useArray<ParentType>([]);
  const { items: selectedEmployees, setItems: setSelectedEmployees } =
    useArray<ParentType>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<ParentType>();
  useApisPartnerConsolesCompaniesBusinessSectionRelationEmployeesIndex({
    companyId,
    params: {
      businessSectionIds: selectedSections.map((section) => section.id),
      employeeRoleIds: selectableEmployeeRoles.map((role) => role.id),
    },
    config: {
      enabled: !!companyId && isSelectEmployee,
      onSuccess: (res) => {
        setSelectableEmployees(res.employees);
        const filteredEmployees = selectedEmployees.filter((selectedEmployee) =>
          res.employees.some(
            (resEmployee) => resEmployee.id === selectedEmployee.id,
          ),
        );
        setSelectedEmployees(filteredEmployees);

        const filetedEmployee = res.employees.some(
          (resEmployee) => resEmployee.id === selectedEmployee?.id,
        );
        !filetedEmployee && setSelectedEmployee(undefined);
      },
    },
  });

  const convertChildToOption = useCallback(
    (sections: BusinessSectionType[]) => {
      return sections.map((section) => ({
        id: section.id,
        name: section.name,
        parentId: section.businessDivisionId,
      }));
    },
    [],
  );

  const convertToSections = useCallback((newValues: ChildType[]) => {
    return newValues.map((value) => ({
      id: value.id,
      name: value.name,
      businessDivisionId: value.parentId,
    }));
  }, []);

  const onBusinessDivisionChange = (newDivisions: BusinessDivisionType[]) => {
    const divisionIds = newDivisions.map((division) => division.id);
    const filteredSections = selectedSections.filter((section) =>
      divisionIds.includes(section.businessDivisionId),
    );
    setSelectedDivisions(newDivisions);
    setSelectedSections(filteredSections);
  };

  const onBusinessSectionChange = (newSections: ChildType[]) => {
    setSelectedSections(convertToSections(newSections));
  };

  const optionSelectableSections = convertChildToOption(
    selectableSections || [],
  );
  const optionSelectedSections = convertChildToOption(selectedSections || []);

  return {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange: onBusinessDivisionChange,
    selectableSections,
    selectedSections,
    onSectionChange: onBusinessSectionChange,
    optionSelectableSections,
    optionSelectedSections,
    setSelectedDivisions,
    setSelectedSections,
    selectableEmployees,
    selectedEmployees,
    onEmployeesChange: setSelectedEmployees,
    selectedEmployee,
    onEmployeeChange: setSelectedEmployee,
  };
};
