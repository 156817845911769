import React, { FC } from "react";

import {
  useApisManagersRoleplayingConditionsIndex,
  useSearchManagersRoleplayingConditions,
} from "~/hooks";

import { TabItemLinks } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import { ManagersRoleplayingConditionsIndexTemplate } from "~/components/templates";

export const ManagersRoleplayingConditionsIndex: FC = () => {
  const breadCrumbItems = [
    { label: "ロールプレイング", href: "/managers/roleplaying_conditions" },
    { label: "一覧", href: "/managers/roleplaying_conditions" },
  ];

  const {
    roleplayingConditions,
    hasNextPage,
    isLoading,
    keyword,
    startDate,
    endDate,
    selectableDropdownCreatedEmployees,
    selectedDropdownCreatedEmployees,
    selectableDropdownParticipationEmployees,
    selectedDropdownParticipationEmployees,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    onDivisionChange,
    onSectionChange,
    onChangeDropdownCreatedEmployees,
    onChangeDropdownParticipationEmployees,
    onChangeDateRange,
    fetchNextPage,
    findAndRemove,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  } = useSearchManagersRoleplayingConditions({
    useApisRoleplayingConditions: useApisManagersRoleplayingConditionsIndex,
  });

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <TabItemLinks
          items={[
            {
              name: "チームに蓄積",
              href: "/managers/roleplaying_conditions",
              isActive: true,
            },
            {
              name: "ユーザー紐付き",
              href: "/managers/belonging_employees/roleplaying_conditions",
              isActive: false,
            },
          ]}
        />
        <ManagersRoleplayingConditionsIndexTemplate
          className="mt-8"
          roleplayingConditions={roleplayingConditions}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          keyword={keyword}
          startDate={startDate}
          endDate={endDate}
          selectableDropdownCreatedEmployees={
            selectableDropdownCreatedEmployees
          }
          selectedDropdownCreatedEmployees={selectedDropdownCreatedEmployees}
          selectableDropdownParticipationEmployees={
            selectableDropdownParticipationEmployees
          }
          selectedDropdownParticipationEmployees={
            selectedDropdownParticipationEmployees
          }
          selectableDropdownDivisions={selectableDropdownDivisions}
          selectedDropdownDivisions={selectedDropdownDivisions}
          selectableDropdownSections={selectableDropdownSections}
          selectedDropdownSections={selectedDropdownSections}
          onDivisionChange={onDivisionChange}
          onSectionChange={onSectionChange}
          onChangeDropdownCreatedEmployees={onChangeDropdownCreatedEmployees}
          onChangeDropdownParticipationEmployees={
            onChangeDropdownParticipationEmployees
          }
          onChangeDateRange={onChangeDateRange}
          fetchNextPage={fetchNextPage}
          findAndRemove={findAndRemove}
          onChangeKeyword={onChangeKeyword}
          onSearchSubmit={onSearchSubmit}
          onConditionReset={onConditionReset}
        />
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
