import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersRoleplayingConditionEmployeesDailyRoleplayingTimesIndexRequestType,
  ApiManagersRoleplayingConditionEmployeesDailyRoleplayingTimesIndexResponseType,
} from "~/domains";

type RequestDataType = {
  employeeId: string;
  params: ApiManagersRoleplayingConditionEmployeesDailyRoleplayingTimesIndexRequestType;
};

const request = async ({
  employeeId,
  params,
}: RequestDataType): Promise<ApiManagersRoleplayingConditionEmployeesDailyRoleplayingTimesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersRoleplayingConditionEmployeesDailyRoleplayingTimesIndexResponseType>(
      `/api/managers/roleplaying_condition/employees/${employeeId}/daily_roleplaying_times`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiManagersRoleplayingConditionEmployeesDailyRoleplayingTimesIndexRequestType;
  employeeId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersRoleplayingConditionEmployeesDailyRoleplayingTimesIndex =
  ({
    params = {},
    config = {},
    employeeId,
  }: PropsType): UseQueryResult<ApiManagersRoleplayingConditionEmployeesDailyRoleplayingTimesIndexResponseType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisManagersRoleplayingConditionEmployeesDailyRoleplayingTimesIndex",
        employeeId,
        params,
      ],
      queryFn: () => request({ employeeId, params }),
    });
  };
