import React, { FC, FormEvent, MouseEvent, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useManagersEmployeeReviewsNavigation,
  useApisManagersEmployeesEmployeeCompetencyReviewsCreate,
  useApisManagersEmployeesLatestEmployeeCompetencyReviewsShow,
  useSearchManagersEmployeesEmployeeCompetencyReviews,
  useBoolean,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  EmployeeCompetencyReviewDetails,
  EmployeeCompetencyReviewForm,
} from "~/components/organisms";

import { AvatarAndNameEmployeeType } from "~/domains";

export const ManagersEmployeesEmployeeCompetencyReviewsIndex: FC = () => {
  const { employeeId = "" } = useParams<{ employeeId: string }>();
  const [employee, setEmployee] = useState<AvatarAndNameEmployeeType>();

  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";

  const {
    employeeCompetencyReviews,
    isLoading,
    hasNextPage,
    fetchNextPage,
    unshiftItem,
    refetch,
  } = useSearchManagersEmployeesEmployeeCompetencyReviews(employeeId);

  const { navigations } = useManagersEmployeeReviewsNavigation({
    employeeId,
    businessSectionId,
    selectedArray: [false, true],
  });

  const breadCrumbItems = [
    {
      label: "メンバーメモ",
      href: `/managers/business_section_reviews?business_section_id=${businessSectionId}`,
    },
    {
      label: `${employee?.name || ""}の特性`,
      href: `/managers/employees/${employeeId}/employee_competency_reviews?business_section_id=${businessSectionId}`,
    },
  ];

  const {
    isChecked: isReadOnly,
    setTrue: setReadonly,
    setFalse: setNotReadonly,
  } = useBoolean(true);
  const [{ value: goal, onChange: onChangeGoal }, setGoal] = useInput("");
  const [{ value: weakness, onChange: onChangeWeakness }, setWeakness] =
    useInput("");
  const [{ value: strength, onChange: onChangeStrength }, setStrength] =
    useInput("");
  const [{ value: competency, onChange: onChangeCompetency }, setCompetency] =
    useInput("");
  const [
    { value: careerVision, onChange: onChangeCareerVision },
    setCareerVision,
  ] = useInput("");
  const [
    { value: privateLife, onChange: onChangePrivateLife },
    setPrivateLife,
  ] = useInput("");
  const [
    { value: needSupport, onChange: onChangeNeedSupport },
    setNeedSupport,
  ] = useInput("");
  const [{ value: remark, onChange: onChangeRemark }, setRemark] = useInput("");

  const { mutate: postRequest, isLoading: isSubmitting } =
    useApisManagersEmployeesEmployeeCompetencyReviewsCreate();

  useApisManagersEmployeesLatestEmployeeCompetencyReviewsShow({
    employeeId,
    config: {
      onSuccess: (data) => {
        setEmployee(data.employee);
        setReadonly();
        if (!data.employeeCompetencyReview) return;

        setGoal(data.employeeCompetencyReview.goal);
        setWeakness(data.employeeCompetencyReview.weakness);
        setStrength(data.employeeCompetencyReview.strength);
        setCompetency(data.employeeCompetencyReview.competency);
        setCareerVision(data.employeeCompetencyReview.careerVision);
        setPrivateLife(data.employeeCompetencyReview.privateLife);
        setNeedSupport(data.employeeCompetencyReview.needSupport);
        setRemark(data.employeeCompetencyReview.remark);
      },
    },
  });

  const handleReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setReadonly();
  };

  const handleNotReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setNotReadonly();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = {
      goal,
      weakness,
      strength,
      competency,
      careerVision,
      privateLife,
      needSupport,
      remark,
    };

    postRequest(
      {
        employeeId,
        body,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          unshiftItem(data.employeeCompetencyReview);
          setReadonly();
        },
      },
    );
  };

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <EmployeeCompetencyReviewForm
          goal={{ value: goal, onChange: onChangeGoal }}
          weakness={{ value: weakness, onChange: onChangeWeakness }}
          strength={{ value: strength, onChange: onChangeStrength }}
          competency={{ value: competency, onChange: onChangeCompetency }}
          careerVision={{ value: careerVision, onChange: onChangeCareerVision }}
          privateLife={{ value: privateLife, onChange: onChangePrivateLife }}
          needSupport={{ value: needSupport, onChange: onChangeNeedSupport }}
          remark={{ value: remark, onChange: onChangeRemark }}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          handleReadonly={handleReadonly}
          handleNotReadonly={handleNotReadonly}
          isReadOnly={isReadOnly}
          className="mt-9"
        />
        {employee && (
          <EmployeeCompetencyReviewDetails
            employeeCompetencyReviews={employeeCompetencyReviews}
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            refetch={refetch}
            employee={employee}
            className="mt-9"
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
