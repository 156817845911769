import React, { FC } from "react";

import { useIsStartPathPage, useProvidersCurrentEmployee } from "~/hooks";

import { AnalyticsPlanSidebar } from "./AnalyticsPlanSidebar";
import { ManagerSidebar } from "./ManagerSidebar";
import { MemberSidebar } from "./MemberSidebar";

import {
  ManagerOrOwnerRoles,
  ApiMembersUnreadNotificationCountsType,
} from "~/domains";

type PropsType = {
  breadCrumbText: string;
  unreadNotification?: ApiMembersUnreadNotificationCountsType;
};

export const HomeSidebar: FC<PropsType> = ({
  breadCrumbText,
  unreadNotification,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const isManagerPage = useIsStartPathPage("managers");
  const isAnalyticsPlans = useIsStartPathPage("analytics_plans");
  const isManagerOver = ManagerOrOwnerRoles.some(
    (role) => role.id === currentEmployee?.employeeRole?.id,
  );
  const managerSidebarViewable = isManagerPage && isManagerOver;
  return (
    <div className="miniTablet:mb-16">
      {isAnalyticsPlans ? (
        <AnalyticsPlanSidebar breadCrumbText={breadCrumbText} />
      ) : managerSidebarViewable ? (
        <ManagerSidebar
          breadCrumbText={breadCrumbText}
          unreadNotification={unreadNotification}
        />
      ) : (
        <MemberSidebar
          breadCrumbText={breadCrumbText}
          unreadNotification={unreadNotification}
        />
      )}
    </div>
  );
};
