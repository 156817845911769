import React, { FC } from "react";

import {
  useApisManagersPrivateQuestionAnswersIndex,
  useSearchManagersPrivateQuestionAnswers,
} from "~/hooks";

import { TabItemLinks } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import { ManagersPrivateQuestionAnswersIndexTemplate } from "~/components/templates";

export const ManagersPrivateQuestionAnswersIndex: FC = () => {
  const {
    privateQuestionAnswers,
    keyword,
    isLoading,
    hasNextPage,
    startDate,
    endDate,
    selectableDropdownProvidingServices,
    selectedDropdownProvidingServices,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    onChangeDropdownProvidingServices,
    onChangeDropdownEmployees,
    fetchNextPage,
    onChangeDateRange,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  } = useSearchManagersPrivateQuestionAnswers({
    useApisPrivateQuestions: useApisManagersPrivateQuestionAnswersIndex,
  });

  const breadCrumbItems = [
    { label: "Q&A", href: "/managers/question_answer_categories" },
    { label: "メンバーの投稿", href: "/managers/private_question_answers" },
  ];

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <TabItemLinks
          items={[
            {
              name: "チームに蓄積",
              href: "/managers/private_question_answers",
              isActive: true,
            },
            {
              name: "ユーザー紐付き",
              href: "/managers/belonging_employees/private_question_answers",
              isActive: false,
            },
          ]}
        />
        <ManagersPrivateQuestionAnswersIndexTemplate
          className="mt-8"
          privateQuestionAnswers={privateQuestionAnswers}
          keyword={keyword}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          startDate={startDate}
          endDate={endDate}
          selectableDropdownProvidingServices={
            selectableDropdownProvidingServices
          }
          selectedDropdownProvidingServices={selectedDropdownProvidingServices}
          selectableDropdownEmployees={selectableDropdownEmployees}
          selectedDropdownEmployees={selectedDropdownEmployees}
          onChangeDropdownProvidingServices={onChangeDropdownProvidingServices}
          onChangeDropdownEmployees={onChangeDropdownEmployees}
          fetchNextPage={fetchNextPage}
          onChangeDateRange={onChangeDateRange}
          onChangeKeyword={onChangeKeyword}
          onConditionReset={onConditionReset}
          onSearchSubmit={onSearchSubmit}
        />
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
