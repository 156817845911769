import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisAnalyticsPlansPostsRateCalendarsIndexRequestTypes,
  ApisAnalyticsPlansPostsRateCalendarsIndexResponseTypes,
} from "~/domains";

type RequestDataType = {
  params: ApisAnalyticsPlansPostsRateCalendarsIndexRequestTypes;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApisAnalyticsPlansPostsRateCalendarsIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisAnalyticsPlansPostsRateCalendarsIndexResponseTypes>(
      "/api/analytics_plans/posts_rate_calendars",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params: ApisAnalyticsPlansPostsRateCalendarsIndexRequestTypes;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisAnalyticsPlansPostsRateCalendarsIndex = ({
  params,
  config = {},
}: PropsType): UseQueryResult<ApisAnalyticsPlansPostsRateCalendarsIndexResponseTypes> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisAnalyticsPlansPostsRateCalendarsIndex", params],
    queryFn: () => request({ params }),
  });
};
