import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiPartnerConsolesCompaniesAnalyticsKpiTermsIndexResponseType,
  ApiPartnerConsolesCompaniesAnalyticsKpiTermsIndexRequestType,
} from "~/domains";

type RequestDataType = {
  companyId: string;
  params?: ApiPartnerConsolesCompaniesAnalyticsKpiTermsIndexRequestType;
};

const request = async ({
  params,
  companyId,
}: RequestDataType): Promise<ApiPartnerConsolesCompaniesAnalyticsKpiTermsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiPartnerConsolesCompaniesAnalyticsKpiTermsIndexResponseType>(
      `/api/partner_consoles/companies/${companyId}/analytics/kpi_terms`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiPartnerConsolesCompaniesAnalyticsKpiTermsIndexRequestType;
  companyId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisPartnerConsolesCompaniesAnalyticsKpiTermsIndex = ({
  params,
  companyId,
  config = {},
}: PropsType): UseQueryResult<ApiPartnerConsolesCompaniesAnalyticsKpiTermsIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisPartnerConsolesCompaniesAnalyticsKpiTermsIndex",
      companyId,
      params,
    ],
    queryFn: () => request({ companyId, params }),
  });
};
