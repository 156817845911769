import { FormEvent, ChangeEvent, MouseEvent, useState } from "react";

import { toDateString } from "~/libs";

import {
  useArray,
  useApisManagersEmployeesEmployeeMemoReviewsIndex,
  useDateRange,
  useInput,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
} from "~/hooks";

import {
  ApiManagersEmployeesEmployeeMemoReviewsIndexRequestType,
  AvatarAndNameEmployeeType,
  EmployeeMemoReviewType,
  OptionType,
  RangeDatePropsType,
  SingleValueType,
} from "~/domains";

type ReturnType = {
  employeeMemoReviews: EmployeeMemoReviewType[];
  hasNextPage?: boolean;
  isLoading: boolean;
  employee?: AvatarAndNameEmployeeType;
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  selectableDropdownCompleteStatusIds: OptionType[];
  selectedDropdownCompleteStatusId?: SingleValueType<OptionType>;
  fetchNextPage: () => void;
  refetch: () => void;
  unshiftItem: (item: EmployeeMemoReviewType) => void;
  deleteIndexItem: (index: number) => void;
  updateIndexItem: (item: EmployeeMemoReviewType, index: number) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDropdownCompleteStatusId: (
    value: SingleValueType<OptionType>,
  ) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchManagersEmployeesEmployeeMemoReviews = (
  employeeId: string,
): ReturnType => {
  const [employee, setEmployee] = useState<AvatarAndNameEmployeeType>();
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const [params, setParams] =
    useState<ApiManagersEmployeesEmployeeMemoReviewsIndexRequestType>();
  const [
    {
      statusValue: completed,
      selectedDropdownCompleteStatusId,
      selectableDropdownCompleteStatusIds,
      onChangeDropdownCompleteStatusId,
    },
  ] = useCompleteStatusDropdown();
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");

  const {
    items: employeeMemoReviews,
    setItems: setEmployeeMemoReviews,
    unshiftItem,
    deleteIndexItem,
    updateIndexItem,
  } = useArray<EmployeeMemoReviewType>([]);

  const { fetchNextPage, refetch, hasNextPage, isFetching } =
    useApisManagersEmployeesEmployeeMemoReviewsIndex({
      employeeId,
      params,
      config: {
        onSuccess: (data) => {
          data.pages[0] && setEmployee(data.pages[0].employee);
          setEmployeeMemoReviews(
            data.pages.map((page) => page.employeeMemoReviews).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      completed,
      createdAtFrom: startDate ? toDateString(startDate) : "",
      createdAtTo: endDate ? toDateString(endDate) : "",
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownCompleteStatusId(null);
    onChangeDateRange([null, null]);
    setParams(undefined);
    setFalseRightSidebarMain();
  };

  return {
    isLoading: isFetching,
    employeeMemoReviews,
    hasNextPage,
    employee,
    keyword,
    startDate,
    endDate,
    selectableDropdownCompleteStatusIds,
    selectedDropdownCompleteStatusId,
    fetchNextPage,
    refetch,
    unshiftItem,
    deleteIndexItem,
    updateIndexItem,
    onChangeDateRange,
    onChangeKeyword,
    onChangeDropdownCompleteStatusId,
    onSearchSubmit,
    onConditionReset,
  };
};
