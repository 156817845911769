import React, { FC, useEffect } from "react";

import {
  useApisMembersLikeNotificationsShow,
  useApisMembersLikeNotificationsUpdate,
} from "~/hooks";

import { NotificationDetailList } from "~/components/organisms";

export const LikeNotificationSidebar: FC = () => {
  const { data, fetchNextPage, hasNextPage, isLoading } =
    useApisMembersLikeNotificationsShow();
  const { mutate } = useApisMembersLikeNotificationsUpdate();

  useEffect(() => {
    return mutate(undefined);
  }, [mutate]);

  return (
    <NotificationDetailList
      items={data?.pages.flatMap((page) => page.notifications) || []}
      nextFetchFunction={fetchNextPage}
      hasMore={hasNextPage}
      isLoading={isLoading}
      noContentMessage="いいね通知はありません"
    />
  );
};
