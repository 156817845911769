import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiMembersAiChatRoomsMutateResponseType,
  ApiMembersAiChatRoomsUpdateRequestType,
} from "~/domains";

type RequestDataType = {
  id: string;
  body: ApiMembersAiChatRoomsUpdateRequestType;
};

const request = ({ id, body }: RequestDataType) => {
  return new ApiClient()
    .put<ApiMembersAiChatRoomsMutateResponseType>(
      `/api/members/ai_chat_rooms/${id}`,
      {
        aiChatRoom: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersAiChatRoomsUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ApiMembersAiChatRoomsMutateResponseType> => {
  return useMutation(request, { ...options });
};
