import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersRoleplayingConditionBookmarkType } from "~/domains";

type RequestDataType = {
  roleplayingConditionId: string;
};

const request = ({ roleplayingConditionId }: RequestDataType) => {
  return new ApiClient()
    .post<ApiMembersRoleplayingConditionBookmarkType>(
      `/api/members/roleplaying_conditions/${roleplayingConditionId}/bookmarks`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersRoleplayingConditionsBookmarksCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiMembersRoleplayingConditionBookmarkType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
