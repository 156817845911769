import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import { ResponseMessageType, RawDraftContentState } from "~/domains";

type RequestDataType = {
  knowledgeInsightPostId: string;
  id: string;
  body: {
    content: RawDraftContentState;
    attachFiles: File[];
    quotePostId?: string;
  };
};

const request = ({ knowledgeInsightPostId, id, body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ reply: body });
  return new ApiClient()
    .put<ResponseMessageType>(
      `/api/members/knowledge_insight_posts/${knowledgeInsightPostId}/knowledge_insight_post_comments/${id}`,
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsUpdate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ResponseMessageType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
