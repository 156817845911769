import React, { ChangeEvent, FormEvent, MouseEvent, FC } from "react";

import { useRightSidebarState } from "~/hooks";

import { Button, Form, FormSubmitButton, TextField } from "~/components/atoms";
import {
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  RangeDatePropsType,
  RightSidebarToggleIconWithLabel,
  SlideNavigationType,
  TitleWithChild,
} from "~/components/molecules";
import {
  SkillEvaluationTermInfiniteScroll,
  SelectDateRangeButtons,
} from "~/components/organisms";

import { SkillEvaluationTermType } from "~/domains";

type PropsType = {
  navigations: SlideNavigationType[];
  inTerm: boolean;
  managersSkillEvaluationTerms: SkillEvaluationTermType[];
  ownersSkillEvaluationTerms: SkillEvaluationTermType[];
  isManagersDataLoading: boolean;
  isOwnersDataLoading: boolean;
  titleKeyword: string;
  startDate: Date | null;
  endDate: Date | null;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeTitleKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  managersHasNextPage?: boolean;
  managersFetchNextPage: () => void;
  ownersHasNextPage?: boolean;
  ownersFetchNextPage: () => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const SkillEvaluationTermsIndexTemplate: FC<PropsType> = ({
  navigations,
  managersSkillEvaluationTerms,
  ownersSkillEvaluationTerms,
  titleKeyword,
  startDate,
  endDate,
  isManagersDataLoading,
  isOwnersDataLoading,
  onChangeDateRange,
  onChangeTitleKeyword,
  managersHasNextPage,
  managersFetchNextPage,
  ownersHasNextPage,
  ownersFetchNextPage,
  onSearchSubmit,
  onConditionReset,
  inTerm = false,
}: PropsType) => {
  const { searchOpenToggle, setClose, isOpen } =
    useRightSidebarState("isClose");
  return (
    <>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <SkillEvaluationTermInfiniteScroll
          title="本部の発信"
          skillEvaluationTerms={ownersSkillEvaluationTerms}
          fetchNextPage={ownersFetchNextPage}
          hasNextPage={ownersHasNextPage}
          inTerm={inTerm}
          isLoading={isOwnersDataLoading}
          className="mt-6"
        />
        <SkillEvaluationTermInfiniteScroll
          title="部署の発信"
          skillEvaluationTerms={managersSkillEvaluationTerms}
          fetchNextPage={managersFetchNextPage}
          hasNextPage={managersHasNextPage}
          inTerm={inTerm}
          isLoading={isManagersDataLoading}
          className="mt-9"
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={setClose}
          label="検索条件"
          isOpen={isOpen}
        >
          <Form onSubmit={onSearchSubmit} className="mt-6 space-y-6">
            <TitleWithChild title="期間指定">
              <SelectDateRangeButtons
                startDate={startDate}
                endDate={endDate}
                onChange={onChangeDateRange}
                className="my-2"
              />
            </TitleWithChild>
            <TitleWithChild title="フリーワード">
              <TextField
                value={titleKeyword}
                placeholder="キーワード"
                type="search"
                name="keyword"
                onChange={onChangeTitleKeyword}
              />
            </TitleWithChild>
            <div className="space-x-4 flex">
              <Button
                text="リセット"
                color="gray"
                outline
                className="w-full"
                onClick={onConditionReset}
              />
              <FormSubmitButton
                value="検索する"
                color="primary"
                className="w-full"
              />
            </div>
          </Form>
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </>
  );
};
