import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApisAdminOperatorsCompaniesEmployeesIndexResponseType } from "~/domains";

type RequestDataType = {
  page?: number;
  companyId: string;
};

const request = async ({
  page = 1,
  companyId,
}: RequestDataType): Promise<ApisAdminOperatorsCompaniesEmployeesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApisAdminOperatorsCompaniesEmployeesIndexResponseType>(
      `/api/admin_operators/companies/${companyId}/employees`,
      { page },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  config?: InfiniteQueryConfigType<QueryFnType>;
  companyId: string;
};

export const useApisAdminOperatorsCompaniesEmployeesIndex = ({
  companyId,
  config = {},
}: PropsType): UseInfiniteQueryResult<ApisAdminOperatorsCompaniesEmployeesIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApisAdminOperatorsCompaniesEmployeesIndex", companyId],
    ({ pageParam }) => {
      return request({ companyId, page: pageParam as number | undefined });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
