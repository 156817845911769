import { SlideNavigationType } from "~/components/molecules";

import { EmployeeRoleType, ManagerRole, OwnerRole } from "~/domains";

type ReturnType = {
  navigations: SlideNavigationType[];
};

type PropsType = {
  selectedRole?: EmployeeRoleType;
  businessSectionId?: string;
};

export const useSalesConceptCategoriesSlideNavigation = ({
  selectedRole,
  businessSectionId = "",
}: PropsType): ReturnType => {
  const navigations = [
    {
      label: "本部の発信",
      href: `/sales_concept_category/owner?business_section_id=${businessSectionId}`,
      selected: selectedRole === OwnerRole,
    },
    {
      label: "部署の発信",
      href: `/sales_concept_category/manager?business_section_id=${businessSectionId}`,
      selected: selectedRole === ManagerRole,
    },
  ];

  return {
    navigations,
  };
};
