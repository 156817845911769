import React, { FC } from "react";

import { BooleanDropDownField } from "~/components/atoms";

import { MultiParentsWithMultiChildrenDropdownField } from "./MultiParentsWithMultiChildrenDropdownField";

import { ParentType, ChildType, EmployeeRoleType, OwnerRole } from "~/domains";

type PropsType = {
  allParents: ParentType[];
  parentsValue?: ParentType[];
  parentLabel?: string;
  parentRequired?: boolean;
  parentsOnChange: (newValue: ParentType[]) => void;
  allChildren: ChildType[];
  childrenValue?: ChildType[];
  childLabel?: string;
  childRequired?: boolean;
  childrenOnChange: (newValue: ChildType[]) => void;
  isDisabled?: boolean;
  onChangeAllBusinessSectionsDistribution: (newValue: boolean) => void;
  allBusinessSectionsDistribution: boolean;
  currentEmployeeRole?: EmployeeRoleType;
};

export const AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField: FC<
  PropsType
> = ({
  allParents,
  parentsValue,
  parentLabel = "部署",
  parentRequired = false,
  parentsOnChange,
  allChildren,
  childrenValue,
  childLabel = "課",
  childrenOnChange,
  childRequired = false,
  isDisabled = false,
  allBusinessSectionsDistribution,
  onChangeAllBusinessSectionsDistribution,
  currentEmployeeRole,
}: PropsType) => {
  const isOwner = currentEmployeeRole?.id === OwnerRole.id;

  const handleChangeAllBusinessSectionsDistribution = (newValue?: boolean) => {
    if (!isOwner) return;
    onChangeAllBusinessSectionsDistribution(newValue || false);
    if (newValue) {
      parentsOnChange(allParents);
      childrenOnChange(allChildren);
    } else {
      childrenOnChange([]);
      parentsOnChange([]);
    }
  };

  return (
    <>
      <BooleanDropDownField
        name="isAllBusinessSections"
        trueLabel="すべての課に配布する"
        falseLabel="個別の課に配布する"
        labelText="配布種別の選択"
        value={allBusinessSectionsDistribution}
        isDisabled={!isOwner}
        onChange={handleChangeAllBusinessSectionsDistribution}
        required
      />
      {!allBusinessSectionsDistribution && (
        <MultiParentsWithMultiChildrenDropdownField
          allParents={allParents}
          parentsValue={parentsValue}
          parentLabel={parentLabel}
          parentRequired={parentRequired}
          parentsOnChange={parentsOnChange}
          allChildren={allChildren}
          childrenValue={childrenValue}
          childLabel={childLabel}
          childrenOnChange={childrenOnChange}
          childRequired={childRequired}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
};
