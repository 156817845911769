import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersKaizenIdeaSearchItemsIndexResponseType } from "~/domains";

const request =
  async (): Promise<ApiMembersKaizenIdeaSearchItemsIndexResponseType> => {
    const response =
      await new ApiClient().get<ApiMembersKaizenIdeaSearchItemsIndexResponseType>(
        "/api/members/kaizen_idea_search_items",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersKaizenIdeaSearchItemsIndex = ({
  config = {},
}: PropsType): UseQueryResult<ApiMembersKaizenIdeaSearchItemsIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersKaizenIdeaSearchItemsIndex"],
    queryFn: () => request(),
  });
};
