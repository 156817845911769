import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useDocumentFileCategoriesSlideNavigation,
  useDropdown,
  useProvidersCurrentEmployee,
  useRightSidebarState,
  useSearchMembersBusinessSectionsDocumentFileCategories,
} from "~/hooks";

import { DropDownField } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  Loading,
  RightSidebarToggleIconWithLabel,
} from "~/components/molecules";
import {
  DocumentFileCategoryList,
  DocumentFileCategorySearchForm,
} from "~/components/organisms";

import { AllRoles, ManagerRole } from "~/domains";

export const MembersDocumentFileCategoriesIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";
  const { employeeRole } = useParams<{
    employeeRole: string;
  }>();
  const role =
    AllRoles.find((role) => role.type === employeeRole) || ManagerRole;

  const [
    {
      dropdownValue: selectedBusinessSection,
      dropdownSelectableValue: selectableBusinessSections,
      onChange: onChangeBusinessSection,
    },
  ] = useDropdown({
    selectableValue: currentEmployee?.businessSections || [],
    initialValue: currentEmployee?.businessSections.find(
      (businessSection) => businessSection.id === businessSectionId,
    ),
  });

  const {
    documentFileCategories,
    selectableTags,
    selectedTags,
    selectableProvidingServices,
    selectedProvidingServices,
    selectableDocumentFileCategories,
    selectedDocumentFileCategories,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    keyword,
    isLoading,
    selectableFileExtends,
    selectedFileExtends,
    onChangeFileExtends,
    onSearchSubmit,
    onChangeDocumentFileCategories,
    onChangeTags,
    onChangeProvidingServices,
    onChangeKeyword,
    onChangeDropdownHasBookmarkId,
    onConditionReset,
  } = useSearchMembersBusinessSectionsDocumentFileCategories({
    businessSectionId: selectedBusinessSection?.value,
    employeeRoleId: role.id,
  });
  const { navigations } = useDocumentFileCategoriesSlideNavigation({
    businessSectionId: selectedBusinessSection?.value,
    selectedRole: role,
  });

  const { searchOpenToggle, setClose, isOpen } =
    useRightSidebarState("isClose");
  const breadCrumbItems = [
    {
      label: "資料・ツール",
      href: `/document_file_category/${ManagerRole.type}`,
    },
    {
      label: role?.name || "",
      href: `/document_file_category/${employeeRole}`,
    },
  ];
  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
          <DropDownField
            placeholder="課を選択"
            name="businessSectionId"
            className="mt-9"
            value={selectedBusinessSection}
            options={selectableBusinessSections}
            onChange={onChangeBusinessSection}
          />
          {isLoading ? (
            <Loading className="mt-8" />
          ) : (
            <div className="mt-8 space-y-9">
              {documentFileCategories.map((documentFileCategory) => (
                <DocumentFileCategoryList
                  key={documentFileCategory.id}
                  documentFileCategory={documentFileCategory}
                />
              ))}
            </div>
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          <RightSidebarToggleIconWithLabel
            iconOnClick={searchOpenToggle}
            handleOnClose={setClose}
            label="検索条件"
            isOpen={isOpen}
          >
            <DocumentFileCategorySearchForm
              searchKeyword={{
                value: keyword,
                onChange: onChangeKeyword,
              }}
              documentFileCategory={{
                option: selectableDocumentFileCategories,
                value: selectedDocumentFileCategories,
                onChange: onChangeDocumentFileCategories,
              }}
              providingService={{
                option: selectableProvidingServices,
                value: selectedProvidingServices,
                onChange: onChangeProvidingServices,
              }}
              tag={{
                option: selectableTags,
                value: selectedTags,
                onChange: onChangeTags,
              }}
              fileExtend={{
                option: selectableFileExtends,
                value: selectedFileExtends,
                onChange: onChangeFileExtends,
              }}
              hasBookmark={{
                options: selectableDropdownHasBookmarkIds,
                value: selectedDropdownHasBookmarkId,
                onChange: onChangeDropdownHasBookmarkId,
              }}
              onSubmit={onSearchSubmit}
              onConditionReset={onConditionReset}
            />
          </RightSidebarToggleIconWithLabel>
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
