import React, { FC } from "react";

import { CatchTitleBorder, Link } from "~/components/atoms";
import { InfiniteScrollWithMoreButton } from "~/components/molecules";

import { SkillEvaluationStandardSummaryCategoryType } from "~/domains";

type PropsType = {
  title: string;
  skillEvaluationStandardSummaryCategories: SkillEvaluationStandardSummaryCategoryType[];
  fetchNextPage: () => void;
  hasMore?: boolean;
  className?: string;
  businessSectionId?: string;
};

export const SkillEvaluationStandardSummaryCategoryTitleInfinitiesScroll: FC<
  PropsType
> = ({
  title,
  skillEvaluationStandardSummaryCategories,
  hasMore,
  className = "",
  businessSectionId = "",
  fetchNextPage,
}: PropsType) => {
  return (
    <div className={className}>
      <CatchTitleBorder text={title} />
      <InfiniteScrollWithMoreButton
        itemsLength={skillEvaluationStandardSummaryCategories.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasMore}
        className="mt-6"
        scrollClassName="space-y-6"
      >
        {skillEvaluationStandardSummaryCategories.map((item) => (
          <Link
            key={item.id}
            to={`/skill_evaluation_standard_summary_categories/${item.id}?business_section_id=${businessSectionId}`}
            className="block p-4 border border-gray-300 rounded font-semibold text-secondary-600"
          >
            {item.name}
          </Link>
        ))}
      </InfiniteScrollWithMoreButton>
    </div>
  );
};
