import { MutationConfigType, useMutation, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  knowledgeInsightPostId: string;
  body: {
    bookmarkFolderId?: string;
  };
};

const request = async ({
  knowledgeInsightPostId,
  body,
}: RequestDataType): Promise<ResponseMessageType> => {
  const response = await new ApiClient().put<ResponseMessageType>(
    `/api/members/bookmarked_knowledge_insight_posts/${knowledgeInsightPostId}/bookmark_folder_moves`,
    { ...body },
  );
  return response.data;
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersBookmarkedKnowledgeInsightPostsBookmarkFolderMovesUpdate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ResponseMessageType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
