import React, { FC, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { useApisPasswordResetsUpdate, useInput } from "~/hooks";

import {
  FormSubmitButton,
  Form,
  LogoImage,
  Link,
  PasswordRuleMessage,
} from "~/components/atoms";
import { DisplayablePasswordFiled } from "~/components/molecules";

export const PasswordEdit: FC = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const resetPasswordToken = query.get("reset_password_token");
  const { mutate, isLoading } = useApisPasswordResetsUpdate();
  const [password] = useInput("");
  const [passwordConfirmation] = useInput("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    mutate(
      {
        body: {
          password: password.value,
          passwordConfirmation: passwordConfirmation.value,
          resetPasswordToken: resetPasswordToken || "",
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate("/sign_in");
        },
      },
    );
  };

  return (
    <div className="flex items-center justify-center h-dvh ">
      <div className="px-4 py-6 miniTablet:px-16 shadow max-w-xl w-full">
        <div className="flex justify-center mt-6">
          <LogoImage size="lg" />
        </div>
        <h2 className="text-3xl text-center text-gray-600">パスワード再設定</h2>
        <PasswordRuleMessage className="mt-4 text-center" />
        <Form
          className="mt-8 rounded-lg mx-auto space-y-8"
          onSubmit={handleSubmit}
        >
          <DisplayablePasswordFiled
            label="パスワード"
            required
            name="password"
            autoComplete="new-password"
            onChange={password.onChange}
          />
          <DisplayablePasswordFiled
            label="パスワード(確認用)"
            required
            name="passwordConfirmation"
            autoComplete="new-password"
            onChange={passwordConfirmation.onChange}
          />
          <div className="text-center">
            <FormSubmitButton
              value="保存"
              color="primary"
              isReadOnly={isLoading}
            />
          </div>
          <div className="text-center">
            <Link to="/sign_in" className="text-primary-600">
              ログインページへ戻る
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
};
