import React, { FC, Fragment } from "react";

import { Button, LinkButton } from "~/components/atoms";

type PropsType = {
  cols: number;
  className?: string;
  buttons: {
    text: string;
    onClick?: () => void;
    link?: string;
    isHidden?: boolean;
    readonly?: boolean;
  }[];
};

export const OpenedMenuInGrid: FC<PropsType> = ({
  cols,
  className = "",
  buttons,
}: PropsType) => {
  return (
    <div
      className={`my-4 mx-auto text-center grid gap-4 ${
        buttons.length > 1 ? "grid-cols-2" : ""
      } ${className}`}
      style={{
        gridColumn: `span ${cols} / span ${cols}`,
      }}
    >
      {buttons
        .filter((button) => !button.isHidden)
        .map((button) => (
          <Fragment key={button.text}>
            {button?.link && (
              <LinkButton
                path={button.link}
                color="gray"
                outline
                text={button.text}
              />
            )}
            {button?.onClick && (
              <Button
                onClick={button.onClick}
                color="gray"
                outline
                text={button.text}
                readonly={button.readonly}
              />
            )}
          </Fragment>
        ))}
    </div>
  );
};
