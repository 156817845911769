import React, { FC, ChangeEvent } from "react";

import { TextArea, Label } from "~/components/atoms";

type PropsType = {
  labelText: string;
  name: string;
  value?: string;
  defaultValue?: string;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  rows?: number;
  textAreaClassName?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
};

export const LabelWithTextArea: FC<PropsType> = ({
  labelText,
  name,
  value,
  defaultValue,
  autoComplete = "",
  placeholder = "",
  required = false,
  disabled = false,
  rows,
  textAreaClassName = "",
  onChange,
}: PropsType) => {
  return (
    <div>
      <Label
        htmlFor={name}
        labelText={labelText}
        required={required}
        className="block"
      />
      <TextArea
        name={name}
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        rows={rows}
        onChange={onChange}
        wrapperClassName="mt-1.5"
        className={textAreaClassName}
      />
    </div>
  );
};
