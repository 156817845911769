import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersFocusChallengeSearchItemsIndexResponseType } from "~/domains";

const request =
  async (): Promise<ApiMembersFocusChallengeSearchItemsIndexResponseType> => {
    const response =
      await new ApiClient().get<ApiMembersFocusChallengeSearchItemsIndexResponseType>(
        "/api/members/focus_challenge_search_items",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersFocusChallengeSearchItemsIndex = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiMembersFocusChallengeSearchItemsIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersFocusChallengeSearchItemsIndex"],
    queryFn: () => request(),
  });
};
