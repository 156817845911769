import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";

import { toDateString } from "~/libs";

import {
  useInput,
  useDateRange,
  usePostSortType,
  useMultipleDropdown,
  useArray,
  useApisMembersInsightPostBookmarkFoldersInsightPostsIndex,
  useApisMembersInsightPostSearchItemsIndex,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
  useMultiJobCategoryMultiJobCategoryPhaseDropdown,
} from "~/hooks";

import { RangeDatePropsType } from "~/components/molecules";

import {
  InsightPostType,
  MultiValueType,
  OptionType,
  SortType,
  ApiMembersInsightPostBookmarkFoldersInsightPostsIndexRequestType,
  SingleValueType,
  ID_ALL_TYPE,
  ChildType,
  ParentType,
} from "~/domains";

type ReturnType = {
  insightPosts: InsightPostType[];
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  hasNextPage?: boolean;
  isLoading: boolean;
  sort: SortType;
  bookmarkFolderName: string;
  bookmarkFolderId: string;
  selectableDropdownHasCommentIds: OptionType[];
  selectedDropdownHasCommentId?: SingleValueType<OptionType>;
  selectableDropdownSharedIds: OptionType[];
  selectedDropdownSharedId?: SingleValueType<OptionType>;
  selectableDropdownEmployees: OptionType[];
  selectedDropdownEmployees: MultiValueType<OptionType>;
  selectableJobCategories: ParentType[];
  selectedJobCategories: ParentType[];
  optionSelectableJobCategoryPhases: ChildType[];
  optionSelectedJobCategoryPhases: ChildType[];
  selectedProvidingServices: MultiValueType<OptionType>;
  selectableProvidingServices: OptionType[];
  onChangeProvidingServices: (options: MultiValueType<OptionType>) => void;
  onChangeJobCategoryPhases: (options: ChildType[]) => void;
  onChangeJobCategories: (options: ParentType[]) => void;
  deleteIndexInsightPost: (index: number) => void;
  updateIndexInsightPost: (newItem: InsightPostType, index: number) => void;
  onChangeDropdownHasCommentId: (value: SingleValueType<OptionType>) => void;
  onChangeDropdownSharedId: (value: SingleValueType<OptionType>) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDropdownEmployees: (options: MultiValueType<OptionType>) => void;
  fetchNextPage: () => void;
  setSort: (val: SortType) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

type PropsType = {
  insightPostBookmarkFolderId: string;
};

export const useSearchMembersInsightPostBookmarkFoldersInsightPosts = ({
  insightPostBookmarkFolderId,
}: PropsType): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [bookmarkFolderName, setBookmarkFolderName] = useState("");
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const [
    {
      statusValue: hasComment,
      selectedDropdownCompleteStatusId: selectedDropdownHasCommentId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasCommentIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasCommentId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "リプライあり",
      false: "リプライなし",
    },
  });
  const [
    {
      statusValue: shared,
      selectedDropdownCompleteStatusId: selectedDropdownSharedId,
      selectableDropdownCompleteStatusIds: selectableDropdownSharedIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownSharedId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "シェアあり",
      false: "シェアなし",
    },
  });
  const { sort, snakeKeyValue, setSort } = usePostSortType();
  const [params, setParams] =
    useState<ApiMembersInsightPostBookmarkFoldersInsightPostsIndexRequestType>({
      sortType: snakeKeyValue,
    });

  const { items, setItems, deleteIndexItem, updateIndexItem } =
    useArray<InsightPostType>([]);

  const [
    {
      dropdownValue: selectedDropdownEmployees,
      value: selectedEmployees,
      onChange: onChangeDropdownEmployees,
      dropdownSelectableValue: selectableDropdownEmployees,
      setSelectableOption: setSelectableDropdownEmployees,
    },
  ] = useMultipleDropdown();

  const [
    {
      dropdownValue: selectedProvidingServices,
      dropdownSelectableValue: selectableProvidingServices,
      onChange: onChangeProvidingServices,
      setSelectableOption: setSelectableProvidingServices,
    },
  ] = useMultipleDropdown();

  const { data: fetchSearchItemData } =
    useApisMembersInsightPostSearchItemsIndex({
      config: {
        onSuccess: (data) => {
          setSelectableProvidingServices([
            ID_ALL_TYPE,
            ...data.providingServices,
          ]);
          setSelectableDropdownEmployees(data.employees);
        },
      },
    });

  const {
    selectableJobCategories,
    selectedJobCategories,
    onChangeJobCategories,
    optionSelectableJobCategoryPhases,
    optionSelectedJobCategoryPhases,
    onChangeJobCategoryPhases,
  } = useMultiJobCategoryMultiJobCategoryPhaseDropdown({
    selectableJobCategories: fetchSearchItemData?.jobCategories,
    selectableJobCategoryPhases: fetchSearchItemData?.jobCategoryPhases,
    withAllJobCategoryPhase: true,
  });

  const { fetchNextPage, hasNextPage, isFetching } =
    useApisMembersInsightPostBookmarkFoldersInsightPostsIndex({
      insightPostBookmarkFolderId,
      params: { ...params },
      config: {
        onSuccess: (data) => {
          setItems(data.pages.map((page) => page.insightPosts).flat());
          data.pages[0]?.bookmarkFolder &&
            setBookmarkFolderName(data.pages[0]?.bookmarkFolder.name);
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      employeeIds: selectedEmployees?.map((employee) => employee.id),
      hasComment,
      shared,
      jobCategoryIds: selectedJobCategories?.map(
        (jobCategory) => jobCategory.id,
      ),
      jobCategoryPhaseIds: optionSelectedJobCategoryPhases?.map(
        (ele) => ele.id,
      ),
      providingServiceIds: selectedProvidingServices?.map((ele) => ele.value),
      sortType: snakeKeyValue,
      createdAtFrom: startDate ? toDateString(startDate) : "",
      createdAtTo: endDate ? toDateString(endDate) : "",
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownHasCommentId(null);
    onChangeDropdownSharedId(null);
    onChangeDateRange([null, null]);
    onChangeDropdownEmployees([]);
    onChangeJobCategories([]);
    onChangeJobCategoryPhases([]);
    onChangeProvidingServices([]);
    setSort("createdAtAsc");
    setParams({ sortType: snakeKeyValue });
    setFalseRightSidebarMain();
  };

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      sortType: snakeKeyValue,
    }));
  }, [snakeKeyValue]);

  return {
    insightPosts: items,
    keyword,
    isLoading: isFetching,
    hasNextPage,
    startDate,
    endDate,
    selectableDropdownHasCommentIds,
    selectedDropdownHasCommentId,
    selectableDropdownSharedIds,
    selectedDropdownSharedId,
    sort,
    selectableJobCategories,
    selectedJobCategories,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    bookmarkFolderName,
    bookmarkFolderId: insightPostBookmarkFolderId,
    optionSelectableJobCategoryPhases,
    optionSelectedJobCategoryPhases,
    selectedProvidingServices,
    selectableProvidingServices,
    updateIndexInsightPost: updateIndexItem,
    deleteIndexInsightPost: deleteIndexItem,
    fetchNextPage,
    setSort,
    onChangeDropdownHasCommentId,
    onChangeDropdownSharedId,
    onChangeDropdownEmployees,
    onChangeProvidingServices,
    onChangeJobCategoryPhases,
    onChangeJobCategories,
    onChangeDateRange,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  };
};
