import { SlideNavigationType } from "~/components/molecules";

type ReturnType = {
  navigations: SlideNavigationType[];
};

export const useRoleplayingConditionSlideNavigation = (
  selectedArray: [boolean, boolean, boolean],
): ReturnType => {
  const navigations = [
    {
      label: "入力",
      href: "/roleplaying_conditions/new",
      selected: selectedArray[0],
    },
    {
      label: "チャート",
      href: "/roleplaying_condition/dashboard",
      selected: selectedArray[1],
    },
    {
      label: "一覧",
      href: "/roleplaying_conditions",
      selected: selectedArray[2],
    },
  ];

  return {
    navigations: navigations,
  };
};
