import React, { FC } from "react";

import { useBoolean } from "~/hooks";

import { BarChart, ButtonWithIcon } from "~/components/atoms";
import { Loading } from "~/components/molecules";

import { BarChartModal } from "./BarChartModal";

type PropsType = {
  className?: string;
  modalClassName?: string;
  graphWrapperClassName?: string;
  graphClassName?: string;
  title: string;
  perforManceData: {
    name: string;
    value: number;
    stackValue?: number;
  }[];
  isLoading: boolean;
  onlyModalTitle?: boolean;
  handleBarClick?: (index?: { index: number }) => void;
};

export const ScalableBarGraph: FC<PropsType> = ({
  className = "",
  graphWrapperClassName = "",
  modalClassName = "",
  graphClassName = "",
  title,
  perforManceData,
  isLoading,
  onlyModalTitle = false,
  handleBarClick,
}: PropsType) => {
  const { isChecked, setTrue, setFalse } = useBoolean(false);

  return (
    <>
      <div className={`mx-auto w-full ${className}`}>
        {!onlyModalTitle && (
          <p className="font-semibold text-primary-600 text-lg text-center">
            {title}
          </p>
        )}
        <div
          className={`shadow mt-4 p-4 rounded-md flex justify-center text-center ${graphWrapperClassName}`}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <BarChart
                perforManceData={perforManceData}
                className={graphClassName}
              />
              <ButtonWithIcon
                srOnlyText="グラフを拡大する"
                icon={{
                  icon: "ioScanOutline",
                  color: "text-secondary-600",
                  size: "1.25rem",
                }}
                onClick={setTrue}
                className="flex justify-end"
              />
            </>
          )}
        </div>
      </div>
      {isChecked && (
        <BarChartModal
          onClose={setFalse}
          title={title}
          graphData={perforManceData}
          handleBarClick={handleBarClick}
          className={modalClassName}
        />
      )}
    </>
  );
};
