import React, { FC } from "react";

import { startOfMonth, endOfMonth } from "date-fns";

import {
  useApisPartnerConsolesCompaniesAnalyticsSkillEvaluationStandardSummaryCategoriesIndex,
  useDateRange,
  usePartnerConsolesCompaniesDropdown,
  usePartnerConsolesMultiDivisionMultiSectionDropdown,
} from "~/hooks";

import { PartnerConsoleLayout } from "~/components/layouts";
import { LabelWithDropDownField } from "~/components/molecules";
import { AnalyticsPlansCountDisplayBoxes } from "~/components/organisms";

const today = new Date();
export const PartnerConsolesAnalyticsSkillEvaluationStandardSummaryCategoriesIndex: FC =
  () => {
    const query = new URLSearchParams(location.search);
    const companyId = query.get("company_id") || "";
    const breadCrumbItems = [
      {
        label: "パートナー画面",
        href: "/partner_consoles",
      },
      {
        label: "スキルマップ分析",
        href: "/partner_consoles/analytics/skill_evaluation_standard_summary_categories",
      },
    ];
    const [
      { onChangeCompany, dropdownSelectableValue, dropdownValue, company },
    ] = usePartnerConsolesCompaniesDropdown({
      defaultCompanyId: companyId,
    });

    const {
      selectableDivisions,
      selectedDivisions,
      onDivisionChange,
      optionSelectableSections,
      optionSelectedSections,
      onSectionChange,
    } = usePartnerConsolesMultiDivisionMultiSectionDropdown({
      companyId: company?.id || "",
    });

    const [
      {
        startDate,
        formattedStartDate,
        endDate,
        formattedEndDate,
        onChange: onChangeDateRange,
      },
    ] = useDateRange([startOfMonth(today), endOfMonth(today)]);

    const { data, isLoading } =
      useApisPartnerConsolesCompaniesAnalyticsSkillEvaluationStandardSummaryCategoriesIndex(
        {
          companyId: company?.id || "",
          params: {
            businessDivisionIds: selectedDivisions.map(
              (division) => division.id,
            ),
            businessSectionIds: optionSelectedSections.map(
              (section) => section.id,
            ),
            fromDate: formattedStartDate || undefined,
            toDate: formattedEndDate || undefined,
          },
          config: {
            enabled: !!(formattedStartDate && formattedEndDate && company),
          },
        },
      );

    return (
      <>
        <PartnerConsoleLayout
          breadCrumbItems={breadCrumbItems}
          companyId={company?.id}
        >
          <LabelWithDropDownField
            required
            labelText="会社"
            name="company"
            options={dropdownSelectableValue}
            value={dropdownValue}
            onChange={onChangeCompany}
          />
          <AnalyticsPlansCountDisplayBoxes
            selectableDivisions={selectableDivisions}
            selectedDivisions={selectedDivisions}
            onDivisionChange={onDivisionChange}
            optionSelectableSections={optionSelectableSections}
            optionSelectedSections={optionSelectedSections}
            onSectionChange={onSectionChange}
            startDate={startDate}
            endDate={endDate}
            onChangeDateRange={onChangeDateRange}
            data={
              data
                ? {
                    managersPostsCount: data.managersPostsCount,
                    ownersPostsCount: data.ownersPostsCount,
                    others: [
                      {
                        count: data.postsPageViewsCount,
                        title: "ページビュー数",
                      },
                    ],
                  }
                : undefined
            }
            rankings={[
              {
                title: "ユーザー別ページビュー数",
                data: data?.postsPageViewsEmployeesRankings,
              },
              {
                title: "スキルマップ別ページビュー数",
                data: data?.postsPageViewsSkillEvaluationStandardSummaryCategoriesRankings,
              },
            ]}
            className="mt-8"
            isLoading={isLoading}
          />
        </PartnerConsoleLayout>
      </>
    );
  };
