import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";

import { toDateString } from "~/libs";

import {
  useInput,
  useDateRange,
  useApisMembersSharedInsightPostsIndex,
  usePostSortType,
  useMultipleDropdown,
  useArray,
  useApisMembersInsightPostSearchItemsIndex,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
  useMultiJobCategoryMultiJobCategoryPhaseDropdown,
} from "~/hooks";

import { RangeDatePropsType } from "~/components/molecules";

import {
  InsightPostType,
  MultiValueType,
  OptionType,
  SortType,
  ApiMembersInsightPostsIndexRequestType,
  SingleValueType,
  ID_ALL_TYPE,
  ChildType,
  ParentType,
} from "~/domains";

type ReturnType = {
  insightPosts: InsightPostType[];
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  hasNextPage?: boolean;
  isLoading: boolean;
  sort: SortType;
  selectableDropdownHasCommentIds: OptionType[];
  selectedDropdownHasCommentId?: SingleValueType<OptionType>;
  selectableDropdownHasBookmarkIds: OptionType[];
  selectedDropdownHasBookmarkId?: SingleValueType<OptionType>;
  selectableDropdownEmployees: OptionType[];
  selectedDropdownEmployees: MultiValueType<OptionType>;
  selectableJobCategories: ParentType[];
  selectedJobCategories: ParentType[];
  optionSelectableJobCategoryPhases: ChildType[];
  optionSelectedJobCategoryPhases: ChildType[];
  selectedProvidingServices: MultiValueType<OptionType>;
  selectableProvidingServices: OptionType[];
  onChangeProvidingServices: (options: MultiValueType<OptionType>) => void;
  onChangeJobCategoryPhases: (options: ChildType[]) => void;
  onChangeJobCategories: (options: ParentType[]) => void;
  deleteIndexInsightPost: (index: number) => void;
  updateIndexInsightPost: (newItem: InsightPostType, index: number) => void;
  onChangeDropdownHasCommentId: (value: SingleValueType<OptionType>) => void;
  onChangeDropdownHasBookmarkId: (value: SingleValueType<OptionType>) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDropdownEmployees: (options: MultiValueType<OptionType>) => void;
  fetchNextPage: () => void;
  setSort: (val: SortType) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchMembersSharedInsightPosts = (): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const { sort, snakeKeyValue, setSort } = usePostSortType();
  const { data: fetchData } = useApisMembersInsightPostSearchItemsIndex({
    config: {
      onSuccess: (data) => {
        setSelectableProvidingServices([
          ID_ALL_TYPE,
          ...data.providingServices,
        ]);
        setSelectableDropdownEmployees(data.employees);
      },
    },
  });

  const {
    selectableJobCategories,
    selectedJobCategories,
    onChangeJobCategories,
    optionSelectableJobCategoryPhases,
    optionSelectedJobCategoryPhases,
    onChangeJobCategoryPhases,
  } = useMultiJobCategoryMultiJobCategoryPhaseDropdown({
    selectableJobCategories: fetchData?.jobCategories,
    selectableJobCategoryPhases: fetchData?.jobCategoryPhases,
    withAllJobCategoryPhase: true,
  });

  const [
    {
      statusValue: hasComment,
      selectedDropdownCompleteStatusId: selectedDropdownHasCommentId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasCommentIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasCommentId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "リプライあり",
      false: "リプライなし",
    },
  });
  const [
    {
      statusValue: hasBookmark,
      selectedDropdownCompleteStatusId: selectedDropdownHasBookmarkId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasBookmarkIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasBookmarkId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "ブックマークあり",
      false: "ブックマークなし",
    },
  });

  const [params, setParams] = useState<ApiMembersInsightPostsIndexRequestType>({
    sortType: snakeKeyValue,
  });

  const { items, setItems, deleteIndexItem, updateIndexItem } =
    useArray<InsightPostType>([]);

  const [
    {
      dropdownValue: selectedDropdownEmployees,
      value: selectedEmployees,
      onChange: onChangeDropdownEmployees,
      dropdownSelectableValue: selectableDropdownEmployees,
      setSelectableOption: setSelectableDropdownEmployees,
    },
  ] = useMultipleDropdown();

  const [
    {
      dropdownValue: selectedProvidingServices,
      dropdownSelectableValue: selectableProvidingServices,
      onChange: onChangeProvidingServices,
      setSelectableOption: setSelectableProvidingServices,
    },
  ] = useMultipleDropdown();

  const { fetchNextPage, hasNextPage, isFetching } =
    useApisMembersSharedInsightPostsIndex({
      params: { ...params },
      config: {
        onSuccess: (data) => {
          setItems(data.pages.map((page) => page.insightPosts).flat());
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      employeeIds: selectedEmployees?.map((employee) => employee.id),
      hasComment,
      hasBookmark,
      jobCategoryIds: selectedJobCategories?.map(
        (jobCategory) => jobCategory.id,
      ),
      jobCategoryPhaseIds: optionSelectedJobCategoryPhases?.map(
        (ele) => ele.id,
      ),
      providingServiceIds: selectedProvidingServices?.map((ele) => ele.value),
      sortType: snakeKeyValue,
      createdAtFrom: startDate ? toDateString(startDate) : "",
      createdAtTo: endDate ? toDateString(endDate) : "",
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownHasBookmarkId(null);
    onChangeDropdownHasCommentId(null);
    onChangeDateRange([null, null]);
    onChangeDropdownEmployees([]);
    onChangeJobCategories([]);
    onChangeJobCategoryPhases([]);
    onChangeProvidingServices([]);
    setParams({ sortType: snakeKeyValue });
    setFalseRightSidebarMain();
  };

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      sortType: snakeKeyValue,
    }));
  }, [snakeKeyValue]);

  return {
    insightPosts: items,
    isLoading: isFetching,
    keyword,
    hasNextPage,
    startDate,
    endDate,
    sort,
    selectableDropdownHasCommentIds,
    selectedDropdownHasCommentId,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    selectableJobCategories,
    selectedJobCategories,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    optionSelectableJobCategoryPhases,
    optionSelectedJobCategoryPhases,
    selectedProvidingServices,
    selectableProvidingServices,
    updateIndexInsightPost: updateIndexItem,
    deleteIndexInsightPost: deleteIndexItem,
    fetchNextPage,
    setSort,
    onChangeDropdownHasCommentId,
    onChangeDropdownHasBookmarkId,
    onChangeDropdownEmployees,
    onChangeJobCategories,
    onChangeProvidingServices,
    onChangeJobCategoryPhases,
    onChangeDateRange,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  };
};
