import { FormEvent, ChangeEvent, MouseEvent, useState } from "react";

import { toDateString } from "~/libs";

import {
  useArray,
  useApisManagersBusinessSectionsBusinessSectionMemoReviewsIndex,
  useDateRange,
  useInput,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
} from "~/hooks";

import {
  BusinessSectionType,
  BusinessSectionMemoReviewType,
  ApiManagersBusinessSectionsBusinessSectionMemoReviewsIndexRequestType,
  RangeDatePropsType,
  OptionType,
  SingleValueType,
} from "~/domains";

type ReturnType = {
  businessSectionMemoReviews: BusinessSectionMemoReviewType[];
  hasNextPage?: boolean;
  isLoading: boolean;
  businessSection?: BusinessSectionType;
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  selectableDropdownCompleteStatusIds: OptionType[];
  selectedDropdownCompleteStatusId?: SingleValueType<OptionType>;
  fetchNextPage: () => void;
  refetch: () => void;
  onChangeDropdownCompleteStatusId: (
    value: SingleValueType<OptionType>,
  ) => void;
  unshiftItem: (item: BusinessSectionMemoReviewType) => void;
  deleteIndexItem: (index: number) => void;
  updateIndexItem: (item: BusinessSectionMemoReviewType, index: number) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchManagersBusinessSectionsBusinessSectionMemoReviews = (
  businessSectionId: string,
): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const [businessSection, setBusinessSection] = useState<BusinessSectionType>();
  const [params, setParams] =
    useState<ApiManagersBusinessSectionsBusinessSectionMemoReviewsIndexRequestType>();
  const [
    {
      statusValue: completed,
      selectedDropdownCompleteStatusId,
      selectableDropdownCompleteStatusIds,
      onChangeDropdownCompleteStatusId,
    },
  ] = useCompleteStatusDropdown();
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");

  const {
    items: businessSectionMemoReviews,
    setItems: setBusinessSectionMemoReviews,
    unshiftItem,
    deleteIndexItem,
    updateIndexItem,
  } = useArray<BusinessSectionMemoReviewType>([]);

  const { fetchNextPage, refetch, hasNextPage, isFetching } =
    useApisManagersBusinessSectionsBusinessSectionMemoReviewsIndex({
      businessSectionId,
      params,
      config: {
        onSuccess: (data) => {
          data.pages[0] && setBusinessSection(data.pages[0].businessSection);
          setBusinessSectionMemoReviews(
            data.pages.map((page) => page.businessSectionMemoReviews).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      completed,
      createdAtFrom: startDate ? toDateString(startDate) : "",
      createdAtTo: endDate ? toDateString(endDate) : "",
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownCompleteStatusId(null);
    onChangeDateRange([null, null]);
    setParams(undefined);
    setFalseRightSidebarMain();
  };

  return {
    isLoading: isFetching,
    businessSectionMemoReviews,
    hasNextPage,
    businessSection,
    keyword,
    startDate,
    endDate,
    selectableDropdownCompleteStatusIds,
    selectedDropdownCompleteStatusId,
    fetchNextPage,
    refetch,
    unshiftItem,
    deleteIndexItem,
    updateIndexItem,
    onChangeDateRange,
    onChangeKeyword,
    onChangeDropdownCompleteStatusId,
    onSearchSubmit,
    onConditionReset,
  };
};
