import {
  DeliveryEventNotificationHistoryPostableType,
  EmployeeRole,
} from "~/domains";

type UrlReadMutateMappingType = {
  [key: string]: {
    postableType: DeliveryEventNotificationHistoryPostableType;
    employeeRoleId?:
      | typeof EmployeeRole.MANAGER.id
      | typeof EmployeeRole.OWNER.id;
  };
};

export const urlReadMutateMapping: UrlReadMutateMappingType = {
  "/": {
    postableType: "InsightPost",
  },
  "/insight_posts": {
    postableType: "InsightPost",
  },
  "/managers": {
    postableType: "InsightPost",
  },
  "/managers/feedback/insight_posts": {
    postableType: "InsightPost",
  },
  "/focus_challenges": {
    postableType: "FocusChallenge",
  },
  "/managers/feedback/focus_challenges": {
    postableType: "FocusChallenge",
  },
  "/kaizen_ideas": {
    postableType: "KaizenIdea",
  },
  "/managers/feedback/kaizen_ideas": {
    postableType: "KaizenIdea",
  },
  "/kpi_terms": {
    postableType: "KpiTerm",
  },
  "/knowledge_post/team": {
    postableType: "InsightPostSharedEmployee",
  },
  "/managers/knowledge_post/team": {
    postableType: "InsightPostSharedEmployee",
  },
  "/knowledge_post/manager": {
    postableType: "KnowledgeInsightPost",
    employeeRoleId: EmployeeRole.MANAGER.id,
  },
  "/managers/knowledge_post/manager": {
    postableType: "KnowledgeInsightPost",
    employeeRoleId: EmployeeRole.MANAGER.id,
  },
  "/knowledge_post/owner": {
    postableType: "KnowledgeInsightPost",
    employeeRoleId: EmployeeRole.OWNER.id,
  },
  "/managers/knowledge_post/owner": {
    postableType: "KnowledgeInsightPost",
    employeeRoleId: EmployeeRole.OWNER.id,
  },
  "/document_file_category/manager": {
    postableType: "DocumentFileCategory",
    employeeRoleId: EmployeeRole.MANAGER.id,
  },
  "/document_file_category/owner": {
    postableType: "DocumentFileCategory",
    employeeRoleId: EmployeeRole.OWNER.id,
  },
  "/question_answer_category/manager": {
    postableType: "QuestionAnswerCategory",
    employeeRoleId: EmployeeRole.MANAGER.id,
  },
  "/question_answer_category/owner": {
    postableType: "QuestionAnswerCategory",
    employeeRoleId: EmployeeRole.OWNER.id,
  },
  "/skill_evaluation_standard_summary_categories": {
    postableType: "SkillEvaluationStandardSummaryCategory",
  },
  "/skill_evaluation_terms": {
    postableType: "SkillEvaluationTerm",
  },
  "/sales_concept_categories/manager": {
    postableType: "SalesConceptCategory",
    employeeRoleId: EmployeeRole.MANAGER.id,
  },
  "/sales_concept_categories/owner": {
    postableType: "SalesConceptCategory",
    employeeRoleId: EmployeeRole.OWNER.id,
  },
} as const;
