import React, { FC, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { SignInRequest } from "~/utils";

import { useInput } from "~/hooks";

import { SignIn } from "~/components/organisms";

export const AdminOperatorsSignInIndex: FC = () => {
  const navigate = useNavigate();
  const [{ value: email, onChange: onChangeEmail }] = useInput("");
  const [{ value: password, onChange: onChangePassword }] = useInput("");

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const signInParams = {
      email: email,
      password: password,
    };
    await SignInRequest({
      signInParams,
      isAdmin: true,
      onSuccess: () => {
        toast("ログインしました");
        navigate("/admin_operators");
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });
  };

  return (
    <SignIn
      handleSubmit={handleSubmit}
      onChangeEmail={onChangeEmail}
      onChangePassword={onChangePassword}
      loginText="運営者ログイン"
    />
  );
};
