import { ChangeEvent, FormEvent, MouseEvent, useState } from "react";

import {
  useInput,
  useMultipleDropdown,
  useArray,
  useProvidersEmployeeLayout,
  useApisMembersPrivateQuestionAnswersIndex,
  useDateRange,
} from "~/hooks";

import {
  ApiMembersPrivateQuestionAnswersIndexRequestType,
  ID_ALL_TYPE,
  MultiValueType,
  OptionType,
  ParentType,
  PrivateQuestionAnswerType,
  RangeDatePropsType,
} from "~/domains";

type ReturnType = {
  privateQuestionAnswers: PrivateQuestionAnswerType[];
  keyword: string;
  selectableDropdownProvidingServices: OptionType[];
  selectableProvidingServices: ParentType[];
  selectedProvidingServices: MultiValueType<OptionType>;
  startDate: Date | null;
  endDate: Date | null;
  hasNextPage?: boolean;
  isLoading: boolean;
  onChangeProvidingServices: (newValue: MultiValueType<OptionType>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  unshiftPrivateQuestionAnswer: (newItem: PrivateQuestionAnswerType) => void;
  fetchNextPage: () => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchMembersPrivateQuestionAnswers = (): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const {
    items: privateQuestionAnswers,
    setItems: setPrivateQuestionAnswers,
    unshiftItem: unshiftPrivateQuestionAnswer,
  } = useArray<PrivateQuestionAnswerType>([]);
  const [
    {
      startDate,
      endDate,
      formattedStartDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange();

  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");

  const [
    {
      dropdownSelectableValue: selectableDropdownProvidingServices,
      dropdownValue: selectedProvidingServices,
      selectableOption: selectableProvidingServices,
      onChange: onChangeProvidingServices,
      setSelectableOption: setSelectableProvidingServices,
    },
  ] = useMultipleDropdown();

  const [params, setParams] =
    useState<ApiMembersPrivateQuestionAnswersIndexRequestType>();

  const { fetchNextPage, hasNextPage, isLoading } =
    useApisMembersPrivateQuestionAnswersIndex({
      params,
      config: {
        onSuccess: (data) => {
          setPrivateQuestionAnswers(
            data.pages.map((page) => page.privateQuestionAnswers).flat(),
          );
          data.pages[0] &&
            setSelectableProvidingServices([
              ID_ALL_TYPE,
              ...data.pages[0].selectableProvidingServices,
            ]);
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      providingServiceIds: selectedProvidingServices.map(
        (service) => service.value,
      ),
      postDateFrom: formattedStartDate || undefined,
      postDateTo: formattedEndDate || undefined,
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeProvidingServices([]);
    setParams(undefined);
    onChangeDateRange([null, null]);
    setFalseRightSidebarMain();
  };

  return {
    privateQuestionAnswers,
    keyword,
    hasNextPage,
    selectableDropdownProvidingServices,
    selectableProvidingServices,
    selectedProvidingServices,
    startDate,
    endDate,
    isLoading,
    onChangeProvidingServices,
    unshiftPrivateQuestionAnswer,
    fetchNextPage,
    onChangeKeyword,
    onChangeDateRange,
    onSearchSubmit,
    onConditionReset,
  };
};
