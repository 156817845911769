import { useState, useCallback, useEffect } from "react";

import { convertToDropdownOption } from "~/utils";

import { ParentType, OptionType, SingleValueType } from "~/domains";

type PropsType = {
  initialValue?: ParentType;
  selectableValue?: ParentType[];
};

type ReturnType = [
  {
    value?: ParentType;
    dropdownValue?: SingleValueType<OptionType>;
    dropdownSelectableValue: OptionType[];
    onChange: (newValue: SingleValueType<OptionType> | null) => void;
    convertToDropdown: (value?: ParentType[]) => OptionType[];
    handleSetSelectableOption: (newValue: ParentType[]) => void;
  },
  (value?: ParentType) => void,
];

export const useDropdown = ({
  initialValue,
  selectableValue = [],
}: PropsType = {}): ReturnType => {
  const [value, setValue] = useState(initialValue);
  const [dropdownSelectableValue, setDropdownSelectableValue] = useState<
    OptionType[]
  >(selectableValue?.map((val) => convertToDropdownOption(val)) || []);
  const convertToDropdownRecords = useCallback((records: ParentType[]) => {
    return records.map((record) => convertToDropdownOption(record));
  }, []);

  const convertToValue = useCallback((record?: SingleValueType<OptionType>) => {
    if (!record) return undefined;
    return {
      id: record.value,
      name: record.label,
    };
  }, []);

  const onChangeDropDown = useCallback(
    (newValue: SingleValueType<OptionType> | null) => {
      const val = newValue ? convertToValue(newValue) : undefined;
      setValue(val);
    },
    [convertToValue],
  );

  const dropdownValue = value ? convertToDropdownOption(value) : undefined;

  const handleSetSelectableOption = useCallback((newValue: ParentType[]) => {
    const options = newValue?.map((val) => convertToDropdownOption(val)) || [];
    setDropdownSelectableValue(options);
  }, []);

  useEffect(() => {
    selectableValue.length && handleSetSelectableOption(selectableValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectableValue.length]);

  return [
    {
      value,
      dropdownValue,
      dropdownSelectableValue,
      handleSetSelectableOption,
      onChange: (newValue: SingleValueType<OptionType> | null) =>
        onChangeDropDown(newValue),
      convertToDropdown: useCallback(
        (value?: ParentType[]) => convertToDropdownRecords(value || []),
        [convertToDropdownRecords],
      ),
    },
    useCallback((value?: ParentType) => setValue(value), []),
  ];
};
