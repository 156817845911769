import React, { FC, useState, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useProvidersCurrentEmployee,
  useInput,
  useApisManagersJobCategoriesEdit,
  useApisManagersJobCategoriesUpdate,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { JobCategoryForm } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

type NullAbleJobCategoryPhaseType = {
  id: string;
  name: string;
  isNewPhase?: boolean;
};

type JobCategoryPhaseParamsType = {
  id: string | null;
  name: string;
  displayOrder: number;
  isNewPhase: boolean;
};

export const ManagersJobCategoriesEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { refetch } = useProvidersCurrentEmployee();

  const { isError } = useApisManagersJobCategoriesEdit({
    id,
    config: {
      onSuccess: (res) => {
        setJobCategoryName(res.name);
        setJobCategoryPhases(res.jobCategoryPhases);
      },
    },
  });

  const { mutate, isLoading: isSubmitting } =
    useApisManagersJobCategoriesUpdate();

  const [jobCategoryName, setJobCategoryName] = useInput("");
  const [deleteJobCategoryPhaseIds, setDeleteJobCategoryPhaseIds] = useState<
    string[]
  >([]);
  const [jobCategoryPhases, setJobCategoryPhases] = useState<
    NullAbleJobCategoryPhaseType[]
  >([]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      { id, body: generateJobCategoryCreateParams() },
      {
        onSuccess: (res) => {
          toast(res.message);
          navigate("/managers/job_categories");
          refetch();
        },
      },
    );
  };

  const generateUpdateJobCategoryPhases = (
    orderedJobCategoryPhases: JobCategoryPhaseParamsType[],
  ) => {
    return orderedJobCategoryPhases
      .filter((phase) => !phase.isNewPhase)
      .map((phase) => ({
        id: phase.id || null,
        name: phase.name,
        displayOrder: phase.displayOrder,
      }));
  };

  const generateCreateJobCategoryPhases = (
    orderedJobCategoryPhases: JobCategoryPhaseParamsType[],
  ) => {
    return orderedJobCategoryPhases
      .filter((phase) => phase.isNewPhase)
      .map((phase) => ({
        name: phase.name,
        displayOrder: phase.displayOrder,
      }));
  };

  const generateJobCategoryCreateParams = () => {
    const orderedJobCategoryPhases: JobCategoryPhaseParamsType[] =
      jobCategoryPhases.map((phase, index) => ({
        id: phase.id,
        name: phase.name,
        displayOrder: index + 1,
        isNewPhase: phase.isNewPhase || false,
      }));

    return {
      name: jobCategoryName.value,
      deleteJobCategoryPhaseIds: deleteJobCategoryPhaseIds.map((id) => id),
      updateJobCategoryPhases: generateUpdateJobCategoryPhases(
        orderedJobCategoryPhases,
      ),
      createJobCategoryPhases: generateCreateJobCategoryPhases(
        orderedJobCategoryPhases,
      ),
    };
  };

  const breadCrumbItems = [
    { href: "/managers/job_categories", label: "職種設定" },
    { href: `/managers/job_categories/${id}/edit`, label: "編集" },
  ];

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <JobCategoryForm
          jobCategoryName={jobCategoryName.value}
          jobCategoryPhases={jobCategoryPhases}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          onChangeJobCategoryName={jobCategoryName.onChange}
          deleteJobCategoryPhaseIds={deleteJobCategoryPhaseIds}
          setDeleteJobCategoryPhaseIds={setDeleteJobCategoryPhaseIds}
          setJobCategoryPhases={setJobCategoryPhases}
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
