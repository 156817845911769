import React, { FC } from "react";

import {
  useProvidersCurrentEmployee,
  useManagerCategoryDisplayOrderSearch,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { CategoryDisplayOrderChange } from "~/components/organisms";

import { ApiManagersQuestionAnswerCategoriesDisplayOrderIndexType } from "~/domains";

export const ManagersQuestionAnswerCategoriesDisplayOrderIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const fetchUrl = (id: string) => {
    return `/api/managers/business_sections/${id}/question_answer_categories`;
  };

  const updateUrl = (id: string) => {
    return `/api/managers/business_sections/${id}/question_answer_category/display_order`;
  };

  const { onSearchSubmit, fetch, divisionSection, changeDisplayOrder } =
    useManagerCategoryDisplayOrderSearch<ApiManagersQuestionAnswerCategoriesDisplayOrderIndexType>(
      {
        selectableBusinessDivisions: currentEmployee?.businessDivisions || [],
        selectableBusinessSections: currentEmployee?.businessSections || [],
        fetchUrl: fetchUrl,
        updateUrl: updateUrl,
      },
    );

  const breadCrumbItems = [
    { label: "Q&A", href: "/managers/question_answer_categories" },
    {
      label: "並び替え",
      href: "/managers/question_answer_category/display_orders",
    },
  ];

  const items = fetch.data?.questionAnswerCategories || [];
  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <CategoryDisplayOrderChange
          onSearchSubmit={onSearchSubmit}
          fetch={{
            loading: fetch.loading,
            error: fetch.error,
            items: items,
          }}
          divisionSection={divisionSection}
          changeDisplayOrder={changeDisplayOrder}
          catchTitle="Q&Aの並び替え"
          noContentMessage="Q&Aがありません"
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
