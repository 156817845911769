import React, { FC, useState, MouseEvent, useEffect } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useApisMembersDirectMessageRoomsDirectMessagesCreate,
  useApisMembersDirectMessageRoomsReadsUpdate,
  useApisMembersEmployeesDirectMessageRoomsShow,
  useArray,
  useEditorState,
  useInterval,
  useSearchMembersEmployees,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { InfiniteScroll } from "~/components/molecules";
import {
  DirectMessageDetailWithEditForm,
  TextEditor,
} from "~/components/organisms";

import {
  AvatarAndNameEmployeeType,
  DirectMessageRoomType,
  DirectMessageType,
  PreviewWithFileType,
} from "~/domains";

export const MembersEmployeesDirectMessageRoomsShow: FC = () => {
  const { mutate: readRequest } = useApisMembersDirectMessageRoomsReadsUpdate();
  const { refetch: membersRefetch } = useSearchMembersEmployees();
  const [directMessageRoom, setDirectMessageRoom] =
    useState<DirectMessageRoomType>();
  const [employee, setEmployee] = useState<AvatarAndNameEmployeeType>();
  const {
    items: directMessages,
    setItems: setDirectMessages,
    unshiftItem: addDirectMessage,
    updateIndexItem: updateDirectMessage,
    deleteIndexItem: deleteDirectMessage,
  } = useArray<DirectMessageType>([]);
  const [{ value, jsonContent, onChange: onEditorChange, setPlainText }] =
    useEditorState();
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const { employeeId = "" } = useParams<{ employeeId: string }>();
  const { isLoading, hasNextPage, fetchNextPage, refetch } =
    useApisMembersEmployeesDirectMessageRoomsShow({
      employeeId,
      config: {
        onSuccess: (data) => {
          const page = data.pages[0];
          page && setDirectMessageRoom(page.directMessageRoom);
          page && setEmployee(page.employee);
          setDirectMessages(
            data.pages.map((page) => page.directMessages).flat(),
          );
        },
      },
    });
  const { mutate: postRequest, isLoading: isSubmitting } =
    useApisMembersDirectMessageRoomsDirectMessagesCreate();

  const breadCrumbItems = [
    {
      label: "メッセージ",
      href: `/employees/${employeeId}/direct_message_rooms`,
    },
    {
      label: employee?.name || "",
      href: `/employees/${employeeId}/direct_message_rooms`,
    },
  ];

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const body = {
      content: jsonContent,
      attachFiles: files,
    };
    postRequest(
      {
        directMessageRoomId: directMessageRoom?.id || "",
        body,
      },
      {
        onSuccess: (data) => {
          addDirectMessage(data.directMessage);
          setFiles([]);
          setPlainText("");
          toast(data.message);
        },
      },
    );
  };

  useInterval({ onUpdate: refetch, intervalMs: 1000 });

  useEffect(() => {
    if (!directMessageRoom) return;

    readRequest(
      {
        directMessageRoomId: directMessageRoom.id,
      },
      {
        onSuccess: () => {
          membersRefetch();
        },
      },
    );
  }, [directMessageRoom, membersRefetch, readRequest]);

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent
          withRightSidebar
          className="pb-0 flex flex-col justify-between miniTablet-screen-height"
        >
          <InfiniteScroll
            itemsLength={directMessages.length}
            nextFetchFunction={fetchNextPage}
            hasMore={hasNextPage}
            inverse
            isLoading={isLoading}
            scrollClassName="pb-20"
          >
            {directMessages.map((directMessage, index) => (
              <DirectMessageDetailWithEditForm
                key={directMessage.id}
                directMessage={directMessage}
                updateItem={(newItem: DirectMessageType) =>
                  updateDirectMessage(newItem, index)
                }
                deleteItem={() => deleteDirectMessage(index)}
              />
            ))}
          </InfiniteScroll>
          {employee && (
            <TextEditor
              mentions={[employee]}
              editorState={value}
              setEditorState={onEditorChange}
              files={files}
              onChangeFiles={setFiles}
              isEmojiPickerTop
              forBottom
              submitButton={{
                isSubmitting: isSubmitting,
                onSubmit: handleSubmit,
              }}
              className="bg-white sticky bottom-20 miniTablet:bottom-10 flex flex-col grow justify-end"
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
