import { formatDateTime } from "~/libs";

import { SlideNavigationType } from "~/components/molecules";

type ReturnType = {
  navigations: SlideNavigationType[];
};
type PropsType = {
  selectedArray: [boolean, boolean, boolean];
  businessSectionId?: string;
  date: Date;
};

export const useKpiSlideNavigation = ({
  selectedArray,
  businessSectionId = "",
  date,
}: PropsType): ReturnType => {
  const navigations = [
    {
      label: "入力",
      href: `/kpi_daily_performances/${formatDateTime(
        date,
        "yyyy-MM-dd",
      )}?business_section_id=${businessSectionId}`,
      selected: selectedArray[0],
    },
    {
      label: "チャート",
      href: `/kpi_business_section_dashboard?business_section_id=${businessSectionId}`,
      selected: selectedArray[1],
    },
    {
      label: "過去",
      href: `/kpi_terms?business_section_id=${businessSectionId}`,
      selected: selectedArray[2],
    },
  ];

  return {
    navigations,
  };
};
