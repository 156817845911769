import React, { FC, FormEvent, useState } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersBusinessSectionsBusinessSectionMemoReviewsCompletedsUpdate,
  useApisManagersBusinessSectionsBusinessSectionMemoReviewsDestroy,
  useApisManagersBusinessSectionsBusinessSectionMemoReviewsUpdate,
  useBoolean,
  useEditorState,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import { CompletedMarkMessage } from "~/components/molecules";
import {
  EmployeeAndBusinessSectionMemoReviewForm,
  PostContentEditorWithImages,
  PostHeader,
} from "~/components/organisms";

import {
  BusinessSectionMemoReviewType,
  PostDetailMenuItemType,
  PreviewWithFileType,
} from "~/domains";

type PropsType = {
  businessSectionMemoReview: BusinessSectionMemoReviewType;
  updateIndexPost: (newItem: BusinessSectionMemoReviewType) => void;
  deleteIndexPost: () => void;
  className?: string;
};

export const BusinessSectionMemoReviewDetailWithEditForm: FC<PropsType> = ({
  businessSectionMemoReview,
  updateIndexPost,
  deleteIndexPost,
  className,
}: PropsType) => {
  const { isChecked, setTrue, setFalse } = useBoolean(false);
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const [
    {
      jsonContent: editorStateJsonContent,
      value: editorState,
      onChange: onChangeEditorState,
    },
  ] = useEditorState(businessSectionMemoReview.content);

  const { mutate: updateRequest, isLoading: isUpdateSubmitting } =
    useApisManagersBusinessSectionsBusinessSectionMemoReviewsUpdate();
  const { mutate: completeRequest, isLoading: isCompleteSubmitting } =
    useApisManagersBusinessSectionsBusinessSectionMemoReviewsCompletedsUpdate();
  const { mutate: deleteRequest } =
    useApisManagersBusinessSectionsBusinessSectionMemoReviewsDestroy();

  const handleUpdateRequest = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    updateRequest(
      {
        businessSectionId: businessSectionMemoReview.businessSection.id,
        id: businessSectionMemoReview.id,
        body: {
          content: editorStateJsonContent,
          ...(files.length > 0 ? { attachFiles: files } : {}),
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          updateIndexPost(data.businessSectionMemoReview);
          setFalse();
        },
      },
    );
  };

  const handleCompleteRequest = () => {
    const isConfirm = confirm("本当に更新にしますか？");
    if (!isConfirm) return;

    completeRequest(
      {
        businessSectionId: businessSectionMemoReview.businessSection.id,
        businessSectionMemoReviewId: businessSectionMemoReview.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          updateIndexPost(data.businessSectionMemoReview);
          setFalse();
        },
      },
    );
  };

  const handleDeleteRequest = () => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        businessSectionId: businessSectionMemoReview.businessSection.id,
        id: businessSectionMemoReview.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          deleteIndexPost();
        },
      },
    );
  };

  const cancelItems = [
    {
      text: "編集をキャンセル",
      onClick: () => setFalse(),
    },
  ];

  const menuItems: PostDetailMenuItemType[] = [
    {
      icon: "ioCreateOutline",
      text: "編集する",
      onClick: () => setTrue(),
    },
    {
      icon: businessSectionMemoReview.completedAt
        ? "ioRemoveCircleOutline"
        : "ioCheckmarkCircleOutline",
      text: businessSectionMemoReview.completedAt
        ? "未完了にする"
        : "完了済みにする",
      onClick: () => handleCompleteRequest(),
    },
    {
      icon: "ioTrashOutline",
      text: "削除する",
      onClick: () => handleDeleteRequest(),
    },
  ];

  return (
    <div className={`${className}`}>
      {isChecked ? (
        <PostDetailWrapper skipHoverStyle>
          <PostHeader
            miniText={businessSectionMemoReview.createdAt}
            postedEmployee={businessSectionMemoReview.reviewer}
            menuItems={cancelItems}
            underLabel={businessSectionMemoReview.reviewer.name}
          />
          <EmployeeAndBusinessSectionMemoReviewForm
            editorState={editorState}
            onChangeEditorState={onChangeEditorState}
            handleSubmit={handleUpdateRequest}
            isSubmitting={isUpdateSubmitting || isCompleteSubmitting}
            files={files}
            onChangeFiles={setFiles}
            className="mt-1 pl-16"
          />
        </PostDetailWrapper>
      ) : (
        <PostDetailWrapper>
          {businessSectionMemoReview.completedAt && (
            <CompletedMarkMessage message="完了済み" />
          )}
          <PostHeader
            miniText={businessSectionMemoReview.createdAt}
            postedEmployee={businessSectionMemoReview.reviewer}
            menuItems={menuItems}
            underLabel={businessSectionMemoReview.reviewer.name}
            className={businessSectionMemoReview.completedAt ? "mt-2" : ""}
          />
          <PostContentEditorWithImages
            content={businessSectionMemoReview.content}
            files={businessSectionMemoReview.attachFiles}
            className="mt-1 pl-16"
          />
        </PostDetailWrapper>
      )}
    </div>
  );
};
