import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiKpiDailyPerformanceMutate } from "~/domains";

type RequestDataType = {
  kpiObjectiveId: string;
  body: {
    performanceDate: string;
    performance: number;
    kpiDailyPerformanceClientCompanies: {
      name: string;
      price?: number | "";
    }[];
  };
};

const request = ({ kpiObjectiveId, body }: RequestDataType) => {
  return new ApiClient()
    .post<ApiKpiDailyPerformanceMutate>(
      `/api/members/kpi/kpi_objectives/${kpiObjectiveId}/kpi_daily_performances`,
      {
        kpiDailyPerformance: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersKpiKpiObjectivesKpiDailyPerformancesCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiKpiDailyPerformanceMutate,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
