import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersKEmployeeSurveySubmissionsLikedEmployeesIndexResponseType } from "~/domains";

type RequestDataType = {
  employeeSurveySubmissionId: string;
  page?: number;
};

const request = async ({
  employeeSurveySubmissionId,
  page = 1,
}: RequestDataType): Promise<ApiMembersKEmployeeSurveySubmissionsLikedEmployeesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersKEmployeeSurveySubmissionsLikedEmployeesIndexResponseType>(
      `/api/members/employee_survey_submissions/${employeeSurveySubmissionId}/liked_employees`,
      { page },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  employeeSurveySubmissionId: string;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersEmployeeSurveySubmissionsLikedEmployeesIndex = ({
  employeeSurveySubmissionId,
  config = {},
}: PropsType): UseInfiniteQueryResult<ApiMembersKEmployeeSurveySubmissionsLikedEmployeesIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    [
      "ApisMembersEmployeeSurveySubmissionsLikedEmployeesIndex",
      employeeSurveySubmissionId,
    ],
    ({ pageParam }) => {
      return request({
        employeeSurveySubmissionId,
        page: pageParam as number | undefined,
      });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
