import React, { FC, MouseEvent } from "react";

import { ButtonColor, ButtonSizes } from "~/constants/buttonAndForm";

type PropsType = {
  text: string;
  size?: keyof typeof ButtonSizes;
  color: "primary" | "gray" | "red";
  className?: string;
  outline?: boolean;
  readonly?: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const Button: FC<PropsType> = ({
  text,
  color,
  size = "md",
  className = "",
  outline = false,
  readonly = false,
  onClick,
}: PropsType) => {
  return (
    <button
      type="button"
      disabled={readonly}
      className={`${ButtonSizes[size]} border border-solid rounded ${
        readonly ? "cursor-not-allowed opacity-80" : "cursor-pointer"
      }  ${ButtonColor[outline ? "outline" : "contain"][color]} ${className}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};
