import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersSkillEvaluationTermsIndexRequestType,
  ApiManagersSkillEvaluationTermsIndexResponseType,
} from "~/domains";

type RequestDataType = {
  params?: ApiManagersSkillEvaluationTermsIndexRequestType;
  page?: number;
};

const request = async ({
  params,
  page = 1,
}: RequestDataType): Promise<ApiManagersSkillEvaluationTermsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersSkillEvaluationTermsIndexResponseType>(
      "/api/managers/skill_evaluation_terms",
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiManagersSkillEvaluationTermsIndexRequestType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisManagersSkillEvaluationTermsIndex = ({
  params,
  config = {},
}: PropsType = {}): UseInfiniteQueryResult<ApiManagersSkillEvaluationTermsIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApisManagersSkillEvaluationTermsIndex", params],
    ({ pageParam }) => {
      return request({ params, page: pageParam as number | undefined });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
