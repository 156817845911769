import React, { FC, MouseEvent } from "react";

import { useBoolean } from "~/hooks";

import { FloatingChatBox } from "./FloatingChatBox/FloatingChatBox";
import { FloatingChatIcon } from "./FloatingChatIcon";

export const ChatAiContainer: FC = () => {
  const {
    isChecked: isChatOpen,
    setTrue: openChat,
    setFalse: closeChat,
  } = useBoolean(false);

  const handleOpenChat = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openChat();
  };

  return (
    <div className="miniTablet:hidden">
      {isChatOpen ? (
        <FloatingChatBox onClose={closeChat} />
      ) : (
        <FloatingChatIcon onClick={handleOpenChat} />
      )}
    </div>
  );
};
