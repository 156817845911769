import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisMembersKpiTermEndKpiTermsDashboardsShowRequestType,
  ApisMembersKpiTermEndKpiTermsDashboardsShowResponseType,
} from "~/domains";

type RequestDataType = {
  termEndKpiTermId: string;
  params: ApisMembersKpiTermEndKpiTermsDashboardsShowRequestType;
};

const request = async ({
  termEndKpiTermId,
  params,
}: RequestDataType): Promise<ApisMembersKpiTermEndKpiTermsDashboardsShowResponseType> => {
  const response =
    await new ApiClient().get<ApisMembersKpiTermEndKpiTermsDashboardsShowResponseType>(
      `/api/members/kpi/term_end_kpi_terms/${termEndKpiTermId}/dashboard`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  termEndKpiTermId: string;
  params: ApisMembersKpiTermEndKpiTermsDashboardsShowRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersKpiTermEndKpiTermsDashboardsShow = ({
  termEndKpiTermId,
  params,
  config = {},
}: PropsType): UseQueryResult<ApisMembersKpiTermEndKpiTermsDashboardsShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisMembersKpiTermEndKpiTermsDashboardsShow",
      termEndKpiTermId,
      params,
    ],
    queryFn: () => request({ termEndKpiTermId, params }),
  });
};
