import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiMembersInsightPostBookmarkFoldersInsightPostsIndexResponseType,
  ApiMembersInsightPostBookmarkFoldersInsightPostsIndexRequestType,
} from "~/domains";

type RequestDataType = {
  insightPostBookmarkFolderId: string;
  params?: ApiMembersInsightPostBookmarkFoldersInsightPostsIndexRequestType;
  page?: number;
};

const request = async ({
  insightPostBookmarkFolderId,
  params,
  page = 1,
}: RequestDataType): Promise<ApiMembersInsightPostBookmarkFoldersInsightPostsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersInsightPostBookmarkFoldersInsightPostsIndexResponseType>(
      `/api/members/insight_post_bookmark_folders/${insightPostBookmarkFolderId}/insight_posts`,
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  insightPostBookmarkFolderId: string;
  params?: ApiMembersInsightPostBookmarkFoldersInsightPostsIndexRequestType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersInsightPostBookmarkFoldersInsightPostsIndex = ({
  insightPostBookmarkFolderId,
  params,
  config = {},
}: PropsType): UseInfiniteQueryResult<ApiMembersInsightPostBookmarkFoldersInsightPostsIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    [
      "ApisMembersInsightPostBookmarkFoldersInsightPostsIndex",
      insightPostBookmarkFolderId,
      params,
    ],
    ({ pageParam }) => {
      return request({
        insightPostBookmarkFolderId,
        params,
        page: pageParam as number | undefined,
      });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
