import React, { FC } from "react";

import { MenuType } from "~/hooks";

import { Icon, IconType, UnreadMark } from "~/components/atoms";

import { SidebarMenuWrapper } from "./SidebarMenuWrapper";

import { ApiMembersUnreadNotificationCountsType } from "~/domains";

type PropsType = {
  menuType: MenuType;
  currentMenuType: MenuType;
  href?: string;
  unreadNotification?: ApiMembersUnreadNotificationCountsType;
  selectMenu: (menuType: MenuType) => void;
};

type NotificationsMappingType = {
  [key in MenuType]: number | undefined;
};

export const SidebarMenu: FC<PropsType> = ({
  menuType,
  currentMenuType,
  href,
  unreadNotification,
  selectMenu,
}: PropsType) => {
  const getIconName = (menuType: MenuType, isCurrentMenu: boolean): IconType =>
    (isCurrentMenu ? `io${menuType}` : `io${menuType}Outline`) as IconType;

  const NotificationsMapping: NotificationsMappingType = {
    Notifications: unreadNotification?.mentionNotificationCount,
    Heart: unreadNotification?.likeNotificationCount,
    ChatbubbleEllipses: unreadNotification?.replyNotificationCount,
    Mail: unreadNotification?.directMessageNotificationCount,
    Home: undefined,
    Bookmark: undefined,
  };

  const isCurrentMenu = currentMenuType === menuType;
  const iconName = getIconName(menuType, isCurrentMenu);
  const displayUnreadMark = !!NotificationsMapping[menuType];

  return (
    <>
      <SidebarMenuWrapper
        href={href}
        matchMenu={isCurrentMenu}
        menuType={menuType}
        selectMenu={selectMenu}
      >
        <div className="relative">
          <Icon
            icon={iconName}
            color={isCurrentMenu ? "text-primary-600" : "text-secondary-600"}
            size="1.85rem"
            className="group-hover:text-primary-600"
          />
          {displayUnreadMark && <UnreadMark />}
        </div>
      </SidebarMenuWrapper>
    </>
  );
};
