import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { useApisManagersRoleplayingConditionsEdit } from "~/hooks";

import { EmployeeLayout } from "~/components/layouts";
import { NotFoundIndex } from "~/components/pages";
import { ManagersRoleplayingConditionsEditTemplate } from "~/components/templates";

export const ManagersRoleplayingConditionsEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data: roleplayingData, isError } =
    useApisManagersRoleplayingConditionsEdit({
      id,
    });

  const breadCrumbItems = [
    { label: "ロールプレイング", href: "/managers/roleplaying_conditions" },
    { label: "編集", href: `/managers/roleplaying_conditions/${id}/edit` },
  ];

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      {roleplayingData && (
        <ManagersRoleplayingConditionsEditTemplate
          fetchData={roleplayingData}
        />
      )}
    </EmployeeLayout>
  );
};
