export const Href = {
  InsightPost: (postableId: string): string =>
    `/insight_posts/${postableId}#${postableId}`,
  InsightPostComment: (postableId: string): string =>
    `/insight_post_comments/${postableId}#${postableId}`,
  KnowledgeInsightPost: (postableId: string): string =>
    `/knowledge_insight_posts/${postableId}#${postableId}`,
  KnowledgeInsightPostComment: (postableId: string): string =>
    `/knowledge_insight_post_comments/${postableId}#${postableId}`,
  FocusChallengeEmployee: (postableId: string): string =>
    `/focus_challenges/${postableId}#${postableId}`,
  FocusChallengeEmployeeReply: (postableId: string): string =>
    `/focus_challenge_employee_replies/${postableId}#${postableId}`,
  FocusChallengeEmployeeEffort: (postableId: string): string =>
    `/focus_challenge_employee_efforts/${postableId}#${postableId}`,
  KaizenIdea: (postableId: string): string =>
    `/kaizen_ideas/${postableId}#${postableId}`,
  KaizenIdeaReply: (postableId: string): string =>
    `/kaizen_idea_replies/${postableId}#${postableId}`,
  EmployeeSurveySubmission: (postableId: string): string =>
    `/employee_survey_submissions/${postableId}#${postableId}`,
  EmployeeSurveySubmissionReply: (postableId: string): string =>
    `/employee_survey_submission_replies/${postableId}#${postableId}`,
  default: "",
};
