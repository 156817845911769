import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useApisMembersKnowledgeInsightPostsShow, useBoolean } from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  KnowledgeInsightPostDetailWithEditForm,
  KnowledgeInsightPostCommentsWithKnowledgeInsightPost,
} from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

import { ManagerRole } from "~/domains";

export const MembersKnowledgeInsightPostsShow: FC = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();
  const { isChecked: replayOpen, toggle } = useBoolean(true);
  const { data, refetch, isError } = useApisMembersKnowledgeInsightPostsShow({
    id,
  });

  const type = data?.knowledgeInsightPost.employeeRole.type || ManagerRole.type;

  const breadCrumbItems = [
    {
      label: type === ManagerRole.type ? "部署の発信" : "本部の発信",
      href: `/knowledge_post/${type}`,
    },
    { label: "詳細", href: `/knowledge_insight_post_comments/${id}` },
  ];

  if (isError) return <NotFoundIndex />;

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
          {data && (
            <KnowledgeInsightPostDetailWithEditForm
              knowledgeInsightPost={data.knowledgeInsightPost}
              openComment={toggle}
              deleteItem={() => navigate("/knowledge_post/team")}
              isHighlight={data.knowledgeInsightPost.id === id}
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          {replayOpen && data && (
            <>
              <RightSidebarTitle title="リプライ" />
              <KnowledgeInsightPostCommentsWithKnowledgeInsightPost
                className="mt-6 mx-4"
                refetch={refetch}
                mentions={data.mentionableEmployees}
                knowledgeInsightPost={data.knowledgeInsightPost}
                knowledgeInsightPostComments={data.knowledgeInsightPostComments}
              />
            </>
          )}
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
