import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMemberKaizenIdeasKaizenIdeaRepliesIndexType } from "~/domains";

type ParamsType = {
  kaizenIdeaId: string;
};

const request = async ({
  kaizenIdeaId,
}: ParamsType): Promise<ApiMemberKaizenIdeasKaizenIdeaRepliesIndexType> => {
  const response =
    await new ApiClient().get<ApiMemberKaizenIdeasKaizenIdeaRepliesIndexType>(
      `/api/members/kaizen_ideas/${kaizenIdeaId}/kaizen_idea_replies`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  kaizenIdeaId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersKaizenIdeasKaizenIdeaRepliesIndex = ({
  kaizenIdeaId,
  config = {},
}: PropsType): UseQueryResult<ApiMemberKaizenIdeasKaizenIdeaRepliesIndexType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersKaizenIdeasKaizenIdeaRepliesIndex", kaizenIdeaId],
    queryFn: () => request({ kaizenIdeaId }),
  });
};
