import {
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
  useQuery,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiDocumentFileCategoriesIndexRequestType,
  ApiBusinessSectionDocumentFileCategoriesIndexResponseType,
} from "~/domains";

type RequestDataType = {
  params?: ApiDocumentFileCategoriesIndexRequestType;
  businessSectionId: string;
  page?: number;
};

const request = async ({
  businessSectionId,
  params,
}: RequestDataType): Promise<ApiBusinessSectionDocumentFileCategoriesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiBusinessSectionDocumentFileCategoriesIndexResponseType>(
      `/api/members/business_sections/${businessSectionId}/document_file_categories`,
      { ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  businessSectionId: string;
  params?: ApiDocumentFileCategoriesIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersBusinessSectionsDocumentFileCategoriesIndex = ({
  businessSectionId,
  params,
  config = {},
}: PropsType): UseQueryResult<ApiBusinessSectionDocumentFileCategoriesIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisMembersBusinessSectionsDocumentFileCategoriesIndex",
      businessSectionId,
      params,
    ],
    queryFn: () => request({ businessSectionId, params }),
  });
};
