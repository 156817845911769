import React, { FC, MouseEvent } from "react";

import { useApisMembersAiChatRoomsIndex, useArray } from "~/hooks";

import { InfiniteScroll } from "~/components/molecules";

import { FloatingChatRoomDetail } from "./FloatingChatRoomDetail";
import { FloatingChatBoxHeader } from "../../FloatingChatBoxHeader";

import { AiChatRoomType } from "~/domains";

type PropsType = {
  onBack: (e: MouseEvent<HTMLButtonElement>) => void;
  onClose: (e: MouseEvent<HTMLButtonElement>) => void;
  setAiChatRoomId: (chatRoomId: string) => void;
};

export const FloatingChatRooms: FC<PropsType> = ({
  onClose,
  onBack,
  setAiChatRoomId,
}: PropsType) => {
  const { fetchNextPage, hasNextPage, isLoading } =
    useApisMembersAiChatRoomsIndex({
      config: {
        onSuccess: (data) => {
          setItems(data.pages.map((page) => page.aiChatRooms).flat());
        },
      },
    });
  const { items, setItems, deleteIndexItem, updateIndexItem } =
    useArray<AiChatRoomType>();

  return (
    <div className="space-y-6">
      <FloatingChatBoxHeader
        title="過去の相談一覧"
        onClose={onClose}
        onBack={onBack}
      />
      <InfiniteScroll
        itemsLength={items.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        isLoading={isLoading}
        scrollClassName="space-y-4"
      >
        {items.map((item, index) => (
          <FloatingChatRoomDetail
            key={item.id}
            aiChatRoom={item}
            setAiChatRoomId={setAiChatRoomId}
            deleteAiChatRoom={() => deleteIndexItem(index)}
            updateAiChatRoom={(newItem) => updateIndexItem(newItem, index)}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
