import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiMembersPrivateQuestionAnswersIndexRequestType,
  ApiMembersPrivateQuestionAnswersIndexResponseType,
} from "~/domains";

type RequestDataType = {
  page?: number;
  params?: ApiMembersPrivateQuestionAnswersIndexRequestType;
};

const request = async ({
  page = 1,
  params,
}: RequestDataType): Promise<ApiMembersPrivateQuestionAnswersIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersPrivateQuestionAnswersIndexResponseType>(
      "/api/members/private_question_answers",
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  config?: InfiniteQueryConfigType<QueryFnType>;
  params?: ApiMembersPrivateQuestionAnswersIndexRequestType;
};

export const useApisMembersPrivateQuestionAnswersIndex = ({
  config = {},
  params,
}: PropsType): UseInfiniteQueryResult<ApiMembersPrivateQuestionAnswersIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApisMembersPrivateQuestionAnswersIndex", params],
    ({ pageParam }) => {
      return request({ page: pageParam as number | undefined, params });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
