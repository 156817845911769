import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersKpiObjectiveMutateType } from "~/domains";

type RequestDataType = {
  id: string;
  kpiTermId: string;
  body: {
    title: string;
    unitName: string;
    objectivePerformance: number;
    displayOrder: number;
    employeeKpiObjectives: {
      employeeId: string;
      objectivePerformance: number;
    }[];
  };
};

const request = ({ id, kpiTermId, body }: RequestDataType) => {
  return new ApiClient()
    .put<ApiManagersKpiObjectiveMutateType>(
      `/api/managers/kpi_terms/${kpiTermId}/kpi_objectives/${id}`,
      {
        kpiObjective: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersKpiTermsKpiObjectivesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersKpiObjectiveMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
