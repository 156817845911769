import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import { ApiMembersEmployeeSurveySubmissionsShowResponseType } from "~/domains";

type RequestDataType = {
  id: string;
};

const request = async ({
  id,
}: RequestDataType): Promise<ApiMembersEmployeeSurveySubmissionsShowResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersEmployeeSurveySubmissionsShowResponseType>(
      `/api/members/employee_survey_submissions/${id}`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  id: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersEmployeeSurveySubmissionsShow = ({
  id,
  config = {},
}: PropsType): UseQueryResult<ApiMembersEmployeeSurveySubmissionsShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersEmployeeSurveySubmissionsShow", id],
    queryFn: () => request({ id }),
    useErrorBoundary: (error) => {
      return error instanceof ApiResponseError && error.status >= 500;
    },
  });
};
