import React, { FC } from "react";

import {
  LinkButton,
  Avatar,
  BackgroundThumbnailImage,
} from "~/components/atoms";
import {
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";

import { ProfilesShowDashboards } from "./ProfilesShowDashboards";

import { EmployeeProfileType, EmployeeRole } from "~/domains";

type PropsType = {
  currentEmployee: EmployeeProfileType;
};

export const ProfilesShowTemplate: FC<PropsType> = ({
  currentEmployee,
}: PropsType) => {
  return (
    <>
      <EmployeeLayoutMainContent withRightSidebar>
        <>
          {currentEmployee.objective && (
            <h2 className="bg-primary-200 text-primary-600 font-bold py-3 px-4 rounded border border-solid border-gray-200">
              {currentEmployee.objective}
            </h2>
          )}
          <div className="relative mt-6">
            <BackgroundThumbnailImage
              bgImageUrl={currentEmployee.headerImageUrl}
            />
            <div className="absolute -bottom-16 left-4">
              <Avatar
                src={currentEmployee.avatarUrl}
                size="lg"
                className="border-2 border-solid border-primary-600"
              />
            </div>
          </div>
          <div className="mt-6 flex justify-end">
            <LinkButton
              path="/setting/profile"
              color="primary"
              outline
              text="プロフィール編集"
              size="sm"
            />
          </div>
          <div className="mt-8 space-y-1 text-sm">
            <h1 className="font-bold text-lg truncate">
              {currentEmployee.lastName}
              {currentEmployee.firstName}
            </h1>
            <div>
              {currentEmployee.employeeRole.id === EmployeeRole.OWNER.id
                ? "すべての課"
                : currentEmployee.businessSections
                    .map((section) => section.name)
                    .join(" / ")}
            </div>
            <p>{currentEmployee.jobTitle}</p>
          </div>
          <ProfilesShowDashboards className="mt-12" />
        </>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </>
  );
};
