import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersProvidingServicesDestroy,
  useSearchManagersProvidingServices,
} from "~/hooks";

import {
  FormSubmitButton,
  Form,
  Button,
  GridHeaderCells,
  BooleanDropDownField,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  GridRow,
  OpenedMenuInGrid,
  LabelWithTextField,
  LabelWithMultipleDropdownField,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";

import { ProvidingServiceWithBusinessSectionsType } from "~/domains";

export const ManagersProvidingServicesIndex: FC = () => {
  const {
    providingServices,
    hasNextPage,
    isLoading,
    allBusinessSectionsDistribution,
    selectableDropdownProvidingServices,
    selectedDropdownProvidingServices,
    selectableDropdownBusinessSections,
    selectedDropdownBusinessSections,
    keyword,
    fetchNextPage,
    onChangeDropdownProvidingServices,
    onChangeDropdownBusinessSections,
    findAndRemove,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
    setAllBusinessSectionsDistribution,
  } = useSearchManagersProvidingServices();
  const { mutate: deleteRequest } = useApisManagersProvidingServicesDestroy();

  const handleDelete = (
    providingService: ProvidingServiceWithBusinessSectionsType,
  ) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;
    deleteRequest(
      {
        id: providingService.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((service) => service.id === providingService.id);
        },
      },
    );
  };

  const generateGridCell = (
    providingService: ProvidingServiceWithBusinessSectionsType,
  ) => {
    return [
      providingService.name,
      providingService.allBusinessSectionsDistribution
        ? "すべての課"
        : providingService.businessSections
            .map((section) => section.name)
            .join(" / "),
    ];
  };

  const breadCrumbItems = [
    { label: "サービス設定", href: "/managers/providing_services" },
    { label: "一覧", href: "/managers/providing_services" },
  ];

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <Form onSubmit={onSearchSubmit} className="space-y-6">
          <div className="grid miniTablet:grid-cols-2 gap-4">
            <LabelWithMultipleDropdownField
              labelText="サービス"
              name="providingServiceIds"
              options={selectableDropdownProvidingServices}
              value={selectedDropdownProvidingServices}
              placeholder="選択してください"
              onChange={onChangeDropdownProvidingServices}
            />
            <LabelWithMultipleDropdownField
              labelText="課"
              name="businessSectionIds"
              options={selectableDropdownBusinessSections}
              value={selectedDropdownBusinessSections}
              placeholder="選択してください"
              onChange={onChangeDropdownBusinessSections}
            />
            <BooleanDropDownField
              name="isAllBusinessSections"
              trueLabel="すべての課に配布する"
              falseLabel="個別の課に配布する"
              labelText="配布種別の選択"
              value={allBusinessSectionsDistribution}
              onChange={setAllBusinessSectionsDistribution}
            />
            <LabelWithTextField
              labelText="フリーワード"
              type="search"
              name="keyword"
              placeholder="フリーワード検索"
              value={keyword}
              onChange={onChangeKeyword}
            />
          </div>
          <div className="flex items-center justify-end space-x-4">
            <Button
              color="gray"
              outline
              className="w-full miniTablet:w-auto"
              text="リセット"
              onClick={onConditionReset}
            />
            <FormSubmitButton
              value="検索する"
              color="primary"
              className="w-full miniTablet:w-auto"
            />
          </div>
        </Form>
        <InfiniteScrollWithMoreButton
          itemsLength={providingServices.length}
          nextFetchFunction={fetchNextPage}
          isLoading={isLoading}
          hasMore={hasNextPage}
          className="mt-12"
        >
          <div className="grid grid-cols-2">
            <GridHeaderCells texts={["サービス", "課"]} />
            <>
              {providingServices.map((providingService) => (
                <GridRow
                  key={providingService.id}
                  lists={generateGridCell(providingService)}
                  openedMenu={
                    <OpenedMenuInGrid
                      cols={2}
                      buttons={[
                        {
                          text: "編集",
                          link: `/managers/providing_services/${providingService.id}/edit`,
                        },
                        {
                          text: "無効化",
                          onClick: () => handleDelete(providingService),
                        },
                      ]}
                    />
                  }
                />
              ))}
            </>
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
