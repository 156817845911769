import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersSkillEvaluationTermsIndexType } from "~/domains";

export type ApiMembersSkillEvaluationTermsIndexParamsType = {
  titleKeyword?: string;
  employeeRoleId?: number;
  fromDate?: string;
  toDate?: string;
  inTerm?: boolean;
};

type RequestDataType = {
  params?: ApiMembersSkillEvaluationTermsIndexParamsType;
  page?: number;
};

const request = async ({
  params,
  page = 1,
}: RequestDataType): Promise<ApiMembersSkillEvaluationTermsIndexType> => {
  const response =
    await new ApiClient().get<ApiMembersSkillEvaluationTermsIndexType>(
      "/api/members/skill_evaluation_terms",
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiMembersSkillEvaluationTermsIndexParamsType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersSkillEvaluationTermsIndex = ({
  params,
  config = {},
}: PropsType = {}): UseInfiniteQueryResult<ApiMembersSkillEvaluationTermsIndexType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApisMembersSkillEvaluationStandardSummaryCategoriesIndex", params],
    ({ pageParam }) => {
      return request({ params, page: pageParam as number | undefined });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
