import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisAdminOperatorsOperatorsCreateRequestType,
  ResponseMessageType,
} from "~/domains";

type RequestDataType = {
  body: ApisAdminOperatorsOperatorsCreateRequestType;
};

const request = ({ body }: RequestDataType) => {
  return new ApiClient()
    .post<ResponseMessageType>("/api/admin_operators/operators", {
      adminOperator: body,
    })
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisAdminOperatorsOperatorsCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType> => {
  return useMutation(request, { ...options });
};
