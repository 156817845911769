import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersEmployeesEmployeeMemoReviewsIndexRequestType,
  ApiManagersEmployeesEmployeeMemoReviewsIndexResponseType,
} from "~/domains";

type RequestDataType = {
  employeeId: string;
  params?: ApiManagersEmployeesEmployeeMemoReviewsIndexRequestType;
  page?: number;
};

const request = async ({
  employeeId,
  params,
  page = 1,
}: RequestDataType): Promise<ApiManagersEmployeesEmployeeMemoReviewsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersEmployeesEmployeeMemoReviewsIndexResponseType>(
      `/api/managers/employees/${employeeId}/employee_memo_reviews`,
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  employeeId: string;
  params?: ApiManagersEmployeesEmployeeMemoReviewsIndexRequestType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisManagersEmployeesEmployeeMemoReviewsIndex = ({
  employeeId,
  params,
  config = {},
}: PropsType): UseInfiniteQueryResult<ApiManagersEmployeesEmployeeMemoReviewsIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApiManagersEmployeesEmployeeMemoReviewsIndex", employeeId, params],
    ({ pageParam }) => {
      return request({
        params,
        employeeId,
        page: pageParam as number | undefined,
      });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
