import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useApisManagersKpiTermsEdit,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { KpiTermWithObjectiveForm } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const ManagersKpiTermsEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { currentEmployee } = useProvidersCurrentEmployee();
  const { data: fetchEditData, isError } = useApisManagersKpiTermsEdit({ id });

  const breadCrumbItems = [
    { label: "KPI・KGI", href: "/managers/kpi_terms" },
    { label: "編集", href: `/managers/kpi_terms/${id}/edit` },
  ];

  if (isError) return <NotFoundIndex />;
  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          {fetchEditData && (
            <KpiTermWithObjectiveForm
              kpiTerm={fetchEditData.kpiTerm}
              employees={fetchEditData.employees}
              selectableBusinessDivisions={
                currentEmployee?.businessDivisions || []
              }
              selectableBusinessSections={
                currentEmployee?.businessSections || []
              }
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
