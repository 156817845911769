import { MouseEvent, ChangeEvent, FormEvent, useState } from "react";

import { toDateString } from "~/libs";

import {
  useInput,
  useApisManagersPrivateQuestionAnswersIndex,
  useArray,
  useMultipleDropdown,
  useDateRange,
  useApisManagersBelongingEmployeesPrivateQuestionAnswersIndex,
} from "~/hooks";

import {
  OptionType,
  ApiManagersPrivateQuestionAnswersIndexRequestType,
  MultiValueType,
  PrivateQuestionAnswerType,
  RangeDatePropsType,
} from "~/domains";

type ReturnType = {
  privateQuestionAnswers: PrivateQuestionAnswerType[];
  keyword: string;
  hasNextPage?: boolean;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  selectableDropdownProvidingServices: OptionType[];
  selectedDropdownProvidingServices: MultiValueType<OptionType>;
  selectableDropdownEmployees: OptionType[];
  selectedDropdownEmployees: MultiValueType<OptionType>;
  onChangeDropdownProvidingServices: (
    value: MultiValueType<OptionType>,
  ) => void;
  onChangeDropdownEmployees: (value: MultiValueType<OptionType>) => void;
  deleteIndexItem: (index: number) => void;
  unshiftItem: (item: PrivateQuestionAnswerType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

type PropsType = {
  useApisPrivateQuestions:
    | typeof useApisManagersPrivateQuestionAnswersIndex
    | typeof useApisManagersBelongingEmployeesPrivateQuestionAnswersIndex;
};

export const useSearchManagersPrivateQuestionAnswers = ({
  useApisPrivateQuestions,
}: PropsType): ReturnType => {
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [{ startDate, endDate, onChange: onChangeDateRange }, setDateRange] =
    useDateRange();
  const [
    {
      dropdownValue: selectedDropdownProvidingServices,
      onChange: onChangeDropdownProvidingServices,
      dropdownSelectableValue: selectableDropdownProvidingServices,
      setSelectableOption: setSelectableDropdownProvidingServices,
    },
    setDropdownProvidingServices,
  ] = useMultipleDropdown();
  const [
    {
      dropdownValue: selectedDropdownEmployees,
      onChange: onChangeDropdownEmployees,
      dropdownSelectableValue: selectableDropdownEmployees,
      setSelectableOption: setSelectableDropdownEmployees,
    },
    setDropdownEmployees,
  ] = useMultipleDropdown();

  const [params, setParams] =
    useState<ApiManagersPrivateQuestionAnswersIndexRequestType>();

  const { items, setItems, deleteIndexItem, unshiftItem } =
    useArray<PrivateQuestionAnswerType>([]);

  const { fetchNextPage, hasNextPage, isFetching } = useApisPrivateQuestions({
    params,
    config: {
      onSuccess: (data) => {
        setSelectableDropdownProvidingServices(
          data.pages[0]?.selectableProvidingServices || [],
        );
        setSelectableDropdownEmployees(
          data.pages[0]?.selectableEmployees || [],
        );
        setItems(data.pages.map((page) => page.privateQuestionAnswers).flat());
      },
    },
  });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      providingServiceIds: selectedDropdownProvidingServices.map(
        (option) => option.value,
      ),
      employeeIds: selectedDropdownEmployees.map((option) => option.value),
      postDateFrom: startDate ? toDateString(startDate) : "",
      postDateTo: endDate ? toDateString(endDate) : "",
    });
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    setDropdownProvidingServices([]);
    setDropdownEmployees([]);
    setDateRange([null, null]);
    setParams({});
  };

  return {
    privateQuestionAnswers: items,
    keyword,
    isLoading: isFetching,
    hasNextPage,
    startDate,
    endDate,
    selectableDropdownProvidingServices,
    selectedDropdownProvidingServices,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    onChangeDropdownProvidingServices,
    onChangeDropdownEmployees,
    unshiftItem,
    deleteIndexItem,
    fetchNextPage,
    onChangeDateRange,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  };
};
