import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationTermsIndexResponseType,
  ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationTermsIndexRequestType,
} from "~/domains";

type RequestDataType = {
  companyId: string;
  params?: ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationTermsIndexRequestType;
};

const request = async ({
  params,
  companyId,
}: RequestDataType): Promise<ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationTermsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationTermsIndexResponseType>(
      `/api/partner_consoles/companies/${companyId}/analytics/skill_evaluation_terms`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationTermsIndexRequestType;
  companyId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisPartnerConsolesCompaniesAnalyticsSkillEvaluationTermsIndex =
  ({
    params,
    companyId,
    config = {},
  }: PropsType): UseQueryResult<ApiPartnerConsolesCompaniesAnalyticsSkillEvaluationTermsIndexResponseType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisPartnerConsolesCompaniesAnalyticsSkillEvaluationTermsIndex",
        companyId,
        params,
      ],
      queryFn: () => request({ companyId, params }),
    });
  };
