import React, { FC, useEffect } from "react";

import {
  useApisMembersReplyNotificationsShow,
  useApisMembersReplyNotificationsUpdate,
} from "~/hooks";

import { NotificationDetailList } from "~/components/organisms";

export const ReplyNotificationSidebar: FC = () => {
  const { data, fetchNextPage, hasNextPage, isLoading } =
    useApisMembersReplyNotificationsShow();
  const { mutate } = useApisMembersReplyNotificationsUpdate();

  useEffect(() => {
    return mutate(undefined);
  }, [mutate]);

  return (
    <NotificationDetailList
      items={data?.pages.flatMap((page) => page.notifications) || []}
      nextFetchFunction={fetchNextPage}
      hasMore={hasNextPage}
      isLoading={isLoading}
      noContentMessage="リプライ通知はありません"
    />
  );
};
