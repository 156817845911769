import React, { FC, ReactNode, useId } from "react";

import ReactInfiniteScrollComponent from "react-infinite-scroll-component";

import { NoContentMessage } from "~/components/atoms";
import { Loading } from "~/components/molecules";

type PropsType = {
  itemsLength: number | undefined;
  nextFetchFunction: () => void;
  hasMore: boolean | undefined;
  children: ReactNode;
  className?: string;
  scrollClassName?: string;
  noContentMessage?: string;
  inverse?: boolean;
  scrollableTarget?: string;
  isLoading?: boolean;
  height?: string;
};

export const InfiniteScroll: FC<PropsType> = ({
  itemsLength = 0,
  nextFetchFunction,
  hasMore = false,
  children,
  inverse = false,
  isLoading = false,
  className = "",
  scrollClassName = "",
  scrollableTarget,
  noContentMessage = "データがありません",
  height,
}: PropsType) => {
  const uniqueId = useId();
  return (
    <div
      id={scrollableTarget || uniqueId}
      className={`max-h-screen overflow-y-auto hidden-scrollbar ${
        inverse ? "flex flex-col-reverse" : ""
      } ${className}`}
    >
      <ReactInfiniteScrollComponent
        dataLength={itemsLength}
        next={nextFetchFunction}
        hasMore={hasMore}
        loader={<Loading className="mt-4" />}
        scrollableTarget={scrollableTarget || uniqueId}
        inverse={inverse}
        height={height}
        className={`flex hidden-scrollbar ${
          inverse
            ? "flex-col-reverse !overflow-auto"
            : "flex-col !overflow-visible"
        } ${scrollClassName}`}
      >
        {children}
        {itemsLength < 1 &&
          (isLoading ? (
            <Loading className="mt-4" />
          ) : (
            !hasMore && (
              <NoContentMessage text={noContentMessage} className="mt-4" />
            )
          ))}
      </ReactInfiniteScrollComponent>
    </div>
  );
};
