import React, { FC } from "react";
import { Link } from "react-router-dom";

import {
  useProvidersCurrentEmployee,
  useArray,
  useApisMembersBusinessSectionRelationEmployeesIndex,
  useDropdown,
} from "~/hooks";

import { BackgroundThumbnailImage, LinkButton } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { LabelWithDropDownField } from "~/components/molecules";

import { AvatarAndNameEmployeeType, EmployeeRole } from "~/domains";

export const ManagersBusinessSectionReviewsIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";

  const { items: employees, setItems: setEmployees } =
    useArray<AvatarAndNameEmployeeType>();
  const [
    {
      dropdownValue: selectedBusinessSection,
      dropdownSelectableValue: selectableBusinessSections,
      onChange: onChangeBusinessSection,
    },
  ] = useDropdown({
    selectableValue: currentEmployee?.businessSections || [],
    initialValue: currentEmployee?.businessSections.find(
      (businessSection) => businessSection.id === businessSectionId,
    ),
  });

  const breadCrumbItems = [
    {
      label: "メンバーメモ",
      href: `/managers/business_section_reviews?business_section_id=${selectedBusinessSection?.value}`,
    },
  ];

  useApisMembersBusinessSectionRelationEmployeesIndex({
    params: {
      businessSectionIds: [selectedBusinessSection?.value || ""],
      employeeRoleIds: [EmployeeRole.MEMBER.id, EmployeeRole.MANAGER.id],
    },
    config: {
      enabled: !!selectedBusinessSection,
      onSuccess: (res) => {
        setEmployees(res.employees);
      },
    },
  });

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <LabelWithDropDownField
          required
          labelText="課"
          placeholder="課を選択"
          name="businessSectionId"
          value={selectedBusinessSection}
          options={selectableBusinessSections}
          onChange={onChangeBusinessSection}
        />
        {selectedBusinessSection && (
          <>
            <LinkButton
              path={`/managers/business_sections/${selectedBusinessSection.value}/business_section_memo_reviews`}
              text="メンバーメモを作成する"
              color="gray"
              outline
              className="block mt-9 text-center"
            />
            <div className="mt-9 grid miniTablet:grid-cols-3 grid-cols-2 gap-6">
              {employees.map((employee) => (
                <Link
                  to={`/managers/employees/${employee.id}/employee_memo_reviews?business_section_id=${selectedBusinessSection.value}`}
                  key={employee.id}
                  className={
                    "group rounded shadow max-h-96 overflow-y-auto hidden-scrollbar bg-white"
                  }
                >
                  <BackgroundThumbnailImage
                    bgImageUrl={employee.avatarUrl}
                    height={100}
                  />
                  <p className="pt-2 pb-7 px-2 font-bold truncate">
                    {employee.name}
                  </p>
                </Link>
              ))}
            </div>
          </>
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
