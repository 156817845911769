import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useApisMembersKaizenIdeaRepliesKaizenIdeaWithRepliesShow,
  useApisMembersKaizenIdeasNew,
  useRightSidebarState,
  useAnchorScroll,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  KaizenIdeaDetailWithEditForm,
  KaizenIdeaRepliesWithKaizenIdea,
} from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const MembersKaizenIdeaRepliesShow: FC = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();
  const { isReplyOpen, setReplyOpen } = useRightSidebarState("isReplyOpen");
  const breadCrumbItems = [
    { label: "改善・相談", href: "/kaizen_ideas" },
    { label: "詳細", href: `/kaizen_idea_replies/${id}` },
  ];

  const { data: fetchNewData } = useApisMembersKaizenIdeasNew();
  const { data, refetch, isError } =
    useApisMembersKaizenIdeaRepliesKaizenIdeaWithRepliesShow({
      kaizenIdeaReplyId: id,
    });

  useEffect(() => {
    setReplyOpen();
  }, [setReplyOpen]);

  useAnchorScroll(!!data);

  if (isError) return <NotFoundIndex />;

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
          {data && (
            <KaizenIdeaDetailWithEditForm
              kaizenIdea={data.kaizenIdea}
              openComment={setReplyOpen}
              deleteItem={() => navigate("/")}
              className="mt-2"
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          {isReplyOpen && data && (
            <>
              <RightSidebarTitle title="リプライ" />
              <KaizenIdeaRepliesWithKaizenIdea
                className="mt-6 mx-4"
                kaizenIdea={data.kaizenIdea}
                mentions={fetchNewData?.mentionableEmployees || []}
                kaizenIdeaReplies={data.kaizenIdeaReplies}
                refetch={refetch}
                highlightKaizenIdeaReplyId={id}
              />
            </>
          )}
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
