import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ManagersBusinessSectionsIndexType } from "~/domains";

type ParamsType = {
  keyword?: string;
};

type RequestDataType = {
  params?: {
    keyword?: string;
  };
  page?: number;
};

const request = async ({
  params,
  page = 1,
}: RequestDataType): Promise<ManagersBusinessSectionsIndexType> => {
  const response = await new ApiClient().get<ManagersBusinessSectionsIndexType>(
    "/api/managers/archived_business_sections",
    { page, ...params },
  );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ParamsType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisManagersArchivedBusinessSectionsIndex = ({
  params,
  config = {},
}: PropsType = {}): UseInfiniteQueryResult<ManagersBusinessSectionsIndexType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    ["ApisManagersArchivedBusinessSectionsIndex", params],
    ({ pageParam }) => {
      return request({ params, page: pageParam as number | undefined });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
