import React, { FC } from "react";

import { formatDateTime, endOfMonthDate, startOfMonthDate } from "~/libs";

import {
  useApisMembersAnalysisCumulativeOfPostsIndex,
  useDateRange,
} from "~/hooks";

import { RangeDatePicker } from "~/components/molecules";
import { AnalysisCumulativeOfPostGraph } from "~/components/organisms";

type PropsType = {
  className?: string;
};

const today = new Date();

export const AnalysisCumulativeOfPosts: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  const [
    {
      startDate,
      formattedStartDate,
      endDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange([startOfMonthDate(today), endOfMonthDate(today)]);
  const { data, isLoading } = useApisMembersAnalysisCumulativeOfPostsIndex({
    params: {
      postDateFrom: formattedStartDate || "",
      postDateTo: formattedEndDate || "",
    },
    config: {
      enabled: !!(formattedStartDate && formattedEndDate),
    },
  });

  return (
    <div className={`${className}`}>
      <RangeDatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={onChangeDateRange}
        maxDate={today}
      />
      <div className="mt-9 space-y-9">
        <AnalysisCumulativeOfPostGraph
          title="インサイト"
          isLoading={isLoading}
          postAnalysis={data?.insightPostAnalysis.map((post) => ({
            ...post,
            name: formatDateTime(post.name, "MM-dd"),
          }))}
        />
        <AnalysisCumulativeOfPostGraph
          title="インサイトへのいいね数"
          isLoading={isLoading}
          postAnalysis={data?.insightPostLikeAnalysis.map((post) => ({
            ...post,
            name: formatDateTime(post.name, "MM-dd"),
          }))}
        />
        <AnalysisCumulativeOfPostGraph
          title="インサイトへのブックマーク数"
          isLoading={isLoading}
          postAnalysis={data?.insightPostBookmarkAnalysis.map((post) => ({
            ...post,
            name: formatDateTime(post.name, "MM-dd"),
          }))}
        />
        <AnalysisCumulativeOfPostGraph
          title="チャレンジ"
          isLoading={isLoading}
          postAnalysis={data?.focusChallengeAnalysis.map((post) => ({
            ...post,
            name: formatDateTime(post.name, "MM-dd"),
          }))}
        />
        <AnalysisCumulativeOfPostGraph
          title="チャレンジへのいいね数"
          isLoading={isLoading}
          postAnalysis={data?.focusChallengeEmployeeLikeAnalysis.map(
            (post) => ({
              ...post,
              name: formatDateTime(post.name, "MM-dd"),
            }),
          )}
        />
        <AnalysisCumulativeOfPostGraph
          title="改善・相談"
          isLoading={isLoading}
          postAnalysis={data?.kaizenIdeaAnalysis.map((post) => ({
            ...post,
            name: formatDateTime(post.name, "MM-dd"),
          }))}
        />
        <AnalysisCumulativeOfPostGraph
          title="改善・相談へのいいね数"
          isLoading={isLoading}
          postAnalysis={data?.kaizenIdeaLikeAnalysis.map((post) => ({
            ...post,
            name: formatDateTime(post.name, "MM-dd"),
          }))}
        />
        <AnalysisCumulativeOfPostGraph
          title="ナレッジシェアされた数"
          isLoading={isLoading}
          postAnalysis={data?.sharedInsightPostAnalysis.map((post) => ({
            ...post,
            name: formatDateTime(post.name, "MM-dd"),
          }))}
        />
      </div>
    </div>
  );
};
