import React, {
  FC,
  forwardRef,
  LegacyRef,
  MouseEventHandler,
  createElement,
  MouseEvent,
} from "react";

import { addDays, subDays, isSameDay, isToday } from "date-fns";
import { ja } from "date-fns/locale/ja";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import { Button, Icon, RequiredHiddenField } from "~/components/atoms";
import { PrevAndNextButtons } from "~/components/molecules";

import "react-datepicker/dist/react-datepicker.css";
import { FormSizes } from "~/constants/buttonAndForm";

registerLocale("ja", ja);

type PropsType = {
  selectDate: Date;
  readOnly?: boolean;
  className?: string;
  onChange: (date: Date) => void;
  dateFormat?: string;
  showMonthYearPicker?: boolean;
  required?: boolean;
  parentClassName?: string;
  size?: keyof typeof FormSizes;
};

type ForwardRefProps = {
  onClick: MouseEventHandler<HTMLDivElement>;
  className: string;
  value: string;
};

export const DatePicker: FC<PropsType> = ({
  selectDate,
  readOnly = false,
  className = "",
  parentClassName = "",
  onChange,
  dateFormat = "yyyy/MM/dd",
  showMonthYearPicker = false,
  required = false,
  size = "sm",
}: PropsType) => {
  const CustomInput = forwardRef(
    (
      { onClick, className, value }: ForwardRefProps,
      ref: LegacyRef<HTMLDivElement>,
    ) => (
      <div ref={ref} onClick={onClick} className={className}>
        <div />
        <span className="">{value}</span>
        <Icon icon="ioCalendarClearOutline" size="1.5rem" />
      </div>
    ),
  );
  CustomInput.displayName = "CustomInput";

  const handleTodayClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onChange(new Date());
  };

  const handlePrevClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onChange(subDays(selectDate, 1));
  };

  const handleNextClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onChange(addDays(selectDate, 1));
  };

  const dayClassName = (date: Date) => {
    if (!selectDate && isToday(date))
      return "hover:bg-primary-800 bg-primary-600 border-primary-600 text-white";
    return isSameDay(date, selectDate)
      ? "hover:bg-primary-800 bg-primary-600 border-primary-600 text-white"
      : null;
  };

  return (
    <div className={`relative flex items-center space-x-3 ${parentClassName}`}>
      <Button
        text="今日"
        color="gray"
        outline
        className="shrink-0"
        size="sm"
        onClick={handleTodayClick}
      />
      <PrevAndNextButtons
        onPrevClick={handlePrevClick}
        onNextClick={handleNextClick}
        prevText="前日に移動"
        nextText="翌日に移動"
      />
      <ReactDatePicker
        locale="ja"
        selected={selectDate}
        onChange={onChange}
        dateFormat={dateFormat}
        readOnly={readOnly}
        showMonthYearPicker={showMonthYearPicker}
        popperClassName="z-layout"
        calendarStartDay={1}
        wrapperClassName="w-full"
        className={`${
          FormSizes[size]
        } justify-between flex items-center border border-solid border-secondary-400 rounded ${
          readOnly ? "bg-secondary-300 text-gray-600" : "cursor-pointer "
        } ${className}`}
        dayClassName={(date) => dayClassName(date)}
        customInput={createElement(CustomInput)}
      />
      {required && <RequiredHiddenField value={selectDate.toLocaleString()} />}
    </div>
  );
};
