import { useContext } from "react";

import { CurrentEmployeeContext } from "~/components/providers/";

import { EmployeeProfileType } from "~/domains";

type Props = {
  currentEmployee?: EmployeeProfileType;
  refetch: () => void;
};

export const useProvidersCurrentEmployee = (): Props => {
  const { currentEmployee, refetch } = useContext(CurrentEmployeeContext);

  return { currentEmployee, refetch };
};
