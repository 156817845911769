import React, { FC } from "react";

import {
  useQuestionAnswerNavigation,
  useRightSidebarState,
  useSearchMembersPrivateQuestionAnswers,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  InfiniteScrollWithMoreButton,
  RightSidebarToggleIconWithLabel,
} from "~/components/molecules";
import {
  PrivateQuestionAnswerForm,
  PrivateQuestionAnswerSearchForm,
} from "~/components/organisms";

import { ManagerRole } from "~/domains";
import { MemberRole } from "~/domains";
export const MembersPrivateQuestionAnswerCategoriesIndex: FC = () => {
  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";

  const { navigations } = useQuestionAnswerNavigation({
    businessSectionId: businessSectionId || "",
    selectedRole: MemberRole,
  });

  const { searchOpenToggle, setClose, isOpen } =
    useRightSidebarState("isClose");

  const {
    privateQuestionAnswers,
    keyword,
    selectableDropdownProvidingServices,
    selectableProvidingServices,
    selectedProvidingServices,
    startDate,
    endDate,
    hasNextPage,
    isLoading,
    onChangeProvidingServices,
    onChangeKeyword,
    fetchNextPage,
    onChangeDateRange,
    onSearchSubmit,
    onConditionReset,
    unshiftPrivateQuestionAnswer,
  } = useSearchMembersPrivateQuestionAnswers();

  const breadCrumbItems = [
    {
      label: "Q&A",
      href: `/question_answer_category/${ManagerRole.type}`,
    },
    {
      label: MemberRole.name,
      href: `/question_answer_category/${MemberRole.type}`,
    },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <PrivateQuestionAnswerForm
          unshiftItem={unshiftPrivateQuestionAnswer}
          className="mt-6"
          selectableProvidingService={selectableProvidingServices}
        />
        <InfiniteScrollWithMoreButton
          itemsLength={privateQuestionAnswers.length}
          nextFetchFunction={fetchNextPage}
          isLoading={isLoading}
          hasMore={hasNextPage}
          className="mt-9"
          scrollClassName="space-y-9"
        >
          {privateQuestionAnswers.map((questionAnswer) => (
            <PrivateQuestionAnswerForm
              key={questionAnswer.id}
              privateQuestionAnswer={questionAnswer}
              selectableProvidingService={selectableProvidingServices}
              disabled
            />
          ))}
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={setClose}
          label="検索条件"
          isOpen={isOpen}
        >
          <PrivateQuestionAnswerSearchForm
            searchKeyword={{
              value: keyword,
              onChange: onChangeKeyword,
            }}
            date={{
              startDate,
              endDate,
              onChange: onChangeDateRange,
            }}
            providingService={{
              value: selectedProvidingServices,
              onChange: onChangeProvidingServices,
              option: selectableDropdownProvidingServices,
            }}
            onSubmit={onSearchSubmit}
            onConditionReset={onConditionReset}
          />
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
