export type CompanyType = {
  id: string;
  subdomain: string;
  name: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
};

export type CompanyProfileType = {
  id: string;
  name: string;
  contractPlan: CompanyContractPlanType;
  createdAt: string;
  updatedAt: string;
};

export type CompanyContractLogType = {
  id: string;
  startAt: string;
  endAt?: string;
  createdAt: string;
  updatedAt: string;
};

export type CompanyContractPlanLogType = {
  id: string;
  companyContractPlan: CompanyContractPlanType;
  createdAt: string;
  updatedAt: string;
};

export const NormalPlanType = {
  id: 1,
  name: "ノーマルプラン",
  type: "normal",
} as const satisfies CompanyContractPlanType;

export const AnalyticsPlanType = {
  id: 10,
  name: "アナリティクスプラン",
  type: "analytics",
} as const satisfies CompanyContractPlanType;

export type CompanyContractPlanType = {
  id: 1 | 10;
  name: "ノーマルプラン" | "アナリティクスプラン";
  type: "normal" | "analytics";
};

export type CompanyBillingPeriodType = {
  id: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
};

export type CompanyAvailableEmployeeUpperLimitLogsType = {
  id: string;
  upperLimit: number;
  createdAt: string;
  updatedAt: string;
};
