import React, { ChangeEvent, MouseEvent, FormEvent, FC } from "react";

import { FileRejection } from "react-dropzone";
import { toast } from "react-toastify";

import { FormEmployeeSurveyQuestionType, useBoolean } from "~/hooks";

import {
  Form,
  Icon,
  DropzoneFileField,
  Button,
  ButtonWithIcon,
  NumberField,
  TextField,
} from "~/components/atoms";
import {
  EditAndFormSubmitButton,
  LabelWithTextField,
  FolderIconWithTitle,
  LabelWithDropDownField,
} from "~/components/molecules";

import {
  CheckBoxType,
  OptionType,
  QuestionTypes,
  RadioButtonType,
  SelectBoxType,
  SingleValueType,
} from "~/domains";

import {
  DOCUMENT_FILE_AVAILABLE_TYPE,
  DOCUMENT_FILE_MAX_BYTE_SIZE,
} from "~/constants/file";

type PropsType = {
  isSubmitting: boolean;
  defaultAccordionOpen: boolean;
  employeeSurveyQuestion: FormEmployeeSurveyQuestionType;
  onChangeQuestion: (newValue: string) => void;
  onChangeFile: (newValue: File) => void;
  onChangeQuestionType: (newValue: SingleValueType<OptionType>) => void;
  addEmployeeSurveyQuestionOption: () => void;
  removeEmployeeSurveyQuestionOption: (index: number) => void;
  onChangeEmployeeSurveyQuestionOptionText: (
    index: number,
    newValue: string,
  ) => void;
  onChangeEmployeeSurveyQuestionOptionPoint: (
    index: number,
    newValue: number,
  ) => void;
  removeEmployeeSurveyQuestion: () => void;
  saveEmployeeSurveyQuestion: () => void;
  onChangeSetNotReadOnly: () => void;
  onChangeSetReadOnly: () => void;
};

export const EmployeeSurveyQuestionForm: FC<PropsType> = ({
  isSubmitting,
  defaultAccordionOpen,
  employeeSurveyQuestion,
  removeEmployeeSurveyQuestion,
  saveEmployeeSurveyQuestion,
  onChangeQuestion,
  onChangeFile,
  onChangeQuestionType,
  addEmployeeSurveyQuestionOption,
  removeEmployeeSurveyQuestionOption,
  onChangeEmployeeSurveyQuestionOptionText,
  onChangeEmployeeSurveyQuestionOptionPoint,
  onChangeSetNotReadOnly,
  onChangeSetReadOnly,
}: PropsType) => {
  const accordionOpen = useBoolean(defaultAccordionOpen);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveEmployeeSurveyQuestion();
  };

  const handleNotReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onChangeSetNotReadOnly();
  };

  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    removeEmployeeSurveyQuestion();
  };

  const handleAttachFileChange = (
    acceptedFile: File[],
    fileRejections: FileRejection[],
  ) => {
    if (fileRejections.length) {
      fileRejections.map(({ file, errors }) => {
        if (!errors[0]) return;
        toast.error(`${file.name} - ${errors[0].message}`);
      });
      return;
    }
    acceptedFile[0] && onChangeFile(acceptedFile[0]);
  };

  const accordionToggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    accordionOpen.toggle();
  };

  const dropzoneClassName = `${
    employeeSurveyQuestion.isReadOnly
      ? "bg-secondary-300"
      : "bg-primary-200 cursor-pointer"
  } rounded-3xl text-gray-500 flex justify-center items-center py-2 px-2`;

  const canAddQuestionOption =
    RadioButtonType.id === employeeSurveyQuestion.questionType.id ||
    SelectBoxType.id === employeeSurveyQuestion.questionType.id ||
    CheckBoxType.id === employeeSurveyQuestion.questionType.id;

  return (
    <Form className="space-y-4" onSubmit={onSubmit}>
      <FolderIconWithTitle
        title={employeeSurveyQuestion.question}
        onClick={accordionToggle}
      />
      {accordionOpen.isChecked && (
        <div className="space-y-6 ml-9">
          <LabelWithTextField
            labelText="質問"
            type="text"
            name="name"
            placeholder="質問"
            required
            value={employeeSurveyQuestion.question}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeQuestion(e.target.value)
            }
            disabled={employeeSurveyQuestion.isReadOnly}
          />
          <DropzoneFileField
            readonly={employeeSurveyQuestion.isReadOnly}
            label="画像"
            maxFileByte={DOCUMENT_FILE_MAX_BYTE_SIZE}
            acceptFileTypes={DOCUMENT_FILE_AVAILABLE_TYPE}
            onDropFiles={handleAttachFileChange}
            fileClassName={dropzoneClassName}
          >
            <Icon
              icon="ioImageOutline"
              size="1.25rem"
              className="shrink-0"
              color="text-gray-500"
            />
            <p className="ml-2">
              {employeeSurveyQuestion.file
                ? employeeSurveyQuestion.file.name
                : "JPEG / PNG"}
            </p>
          </DropzoneFileField>
          <LabelWithDropDownField
            labelText="解答の種類"
            required
            name="questionTypeId"
            isDisabled={employeeSurveyQuestion.isReadOnly}
            options={QuestionTypes.map((type) => ({
              value: type.id.toString(),
              label: type.name,
            }))}
            value={{
              value: employeeSurveyQuestion.questionType.id.toString(),
              label: employeeSurveyQuestion.questionType.name,
            }}
            onChange={onChangeQuestionType}
          />
          {canAddQuestionOption && (
            <div className="space-y-4">
              {employeeSurveyQuestion.employeeSurveyQuestionOptions?.map(
                (option, index) => (
                  <div key={index} className="flex space-x-4">
                    <ButtonWithIcon
                      onClick={() => removeEmployeeSurveyQuestionOption(index)}
                      icon={{
                        icon: "ioCloseOutline",
                        size: "1.5rem",
                      }}
                      srOnlyText="削除"
                      disabled={employeeSurveyQuestion.isReadOnly}
                    />
                    <TextField
                      type="text"
                      name="option"
                      placeholder="選択肢"
                      onlyBottomBorder
                      required
                      value={option.option}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onChangeEmployeeSurveyQuestionOptionText(
                          index,
                          e.target.value,
                        )
                      }
                      disabled={employeeSurveyQuestion.isReadOnly}
                    />
                    <NumberField
                      className="w-min"
                      name="point"
                      placeholder="ポイント"
                      required
                      value={option.point}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onChangeEmployeeSurveyQuestionOptionPoint(
                          index,
                          parseInt(e.target.value, 10),
                        )
                      }
                      disabled={employeeSurveyQuestion.isReadOnly}
                    />
                  </div>
                ),
              )}
              <div className="flex justify-end">
                <Button
                  text="選択肢を追加"
                  onClick={addEmployeeSurveyQuestionOption}
                  color="gray"
                  outline
                  readonly={employeeSurveyQuestion.isReadOnly}
                />
              </div>
            </div>
          )}
          <EditAndFormSubmitButton
            isSubmitting={isSubmitting}
            isReadOnly={employeeSurveyQuestion.isReadOnly}
            handleNotReadonly={handleNotReadonly}
            handleReadonly={onChangeSetReadOnly}
            handleRemove={handleRemove}
          />
        </div>
      )}
    </Form>
  );
};
