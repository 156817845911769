import { FormEvent } from "react";

import update from "immutability-helper";
import { toast } from "react-toastify";

import { useFetch, useDivisionSectionDropdown, usePost } from "~/hooks";

import {
  BusinessDivisionType,
  BusinessSectionType,
  ParentType,
  ChildType,
} from "~/domains";

type ReturnType<T> = {
  fetch: {
    data: T | null;
    loading: boolean;
    error: string | null;
  };
  divisionSection: {
    selectableDivisions: BusinessDivisionType[];
    selectedDivision?: BusinessDivisionType | null;
    onDivisionChange: (newValue: ParentType | null) => void;
    optionSelectableSections: ChildType[];
    optionSelectedSection?: ChildType | null;
    onSectionChange: (newValue: ChildType | null) => void;
  };
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  changeDisplayOrder: (
    items: ItemType[],
    beforeIndex: number,
    index: number,
  ) => void;
};

type ItemType = {
  id: string;
  name: string;
};

type PropsType = {
  selectableBusinessDivisions: BusinessDivisionType[];
  selectableBusinessSections: BusinessSectionType[];
  fetchUrl: (id: string) => string;
  updateUrl: (id: string) => string;
};

export function useManagerCategoryDisplayOrderSearch<T>({
  selectableBusinessDivisions,
  selectableBusinessSections,
  fetchUrl,
  updateUrl,
}: PropsType): ReturnType<T> {
  const {
    selectableDivisions,
    selectedDivision,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSection,
    onSectionChange,
  } = useDivisionSectionDropdown({
    selectableDivisions: selectableBusinessDivisions,
    selectableSections: selectableBusinessSections,
  });

  const { refetch, error, data, loading } = useFetch<T>({
    url: fetchUrl(optionSelectedSection?.id || ""),
    shouldSkipInitialFetch: true,
  });

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!optionSelectedSection) return;

    refetchRequest(optionSelectedSection.id);
  };

  const refetchRequest = (id: string) => {
    refetch({
      refetchUrl: fetchUrl(id),
    });
  };

  const { doRequest } = usePost({
    url: updateUrl(optionSelectedSection?.id || ""),
    method: "put",
  });

  const changeDisplayOrder = async (
    items: ItemType[],
    beforeIndex: number,
    newIndex: number,
  ) => {
    if (!optionSelectedSection) return;
    if (!items.length) return;

    const target = items[beforeIndex];
    if (!target) return;
    const newItems = update(items, {
      $splice: [
        [beforeIndex, 1],
        [newIndex, 0, target],
      ],
    });

    await doRequest({
      body: generateOrderParams(newItems),
      onSuccess: (data) => {
        toast(data.message);
        refetchRequest(optionSelectedSection.id);
      },
    });
  };

  const generateOrderParams = (items: ItemType[]) => {
    return {
      category: {
        categories: items.map((item, index) => ({
          id: item.id,
          displayOrder: index + 1,
        })),
      },
    };
  };

  return {
    divisionSection: {
      selectableDivisions,
      selectedDivision,
      onDivisionChange,
      optionSelectableSections,
      optionSelectedSection,
      onSectionChange,
    },
    fetch: {
      data,
      loading,
      error,
    },
    onSearchSubmit: onSubmit,
    changeDisplayOrder: changeDisplayOrder,
  };
}
