import React, { FormEvent, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
  useDropdown,
  useApisManagersQuestionAnswerCategoriesCreate,
  useApisManagersQuestionAnswerCategoriesNew,
  useBoolean,
} from "~/hooks";

import { Form, FormSubmitButton } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  LabelWithTextField,
  LabelWithDropDownField,
} from "~/components/molecules";
import { AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { PublicStatus } from "~/domains";

export const ManagersQuestionAnswerCategoriesNew: FC = () => {
  const navigate = useNavigate();
  const [providingOptions] = useDropdown({
    initialValue: {
      id: "",
      name: "すべて",
    },
  });

  useApisManagersQuestionAnswerCategoriesNew({
    config: {
      onSuccess: (data) => {
        providingOptions.handleSetSelectableOption([
          {
            id: "",
            name: "すべて",
          },
          ...data.selectableProvidingServices,
        ]);
      },
    },
  });
  const { mutate: postQuestionAnswerCategory, isLoading: isSubmitting } =
    useApisManagersQuestionAnswerCategoriesCreate();

  const { currentEmployee } = useProvidersCurrentEmployee();
  const [{ value: name, onChange: onChangeName }] = useInput("");
  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: onChangeAllBusinessSectionsDistribution,
  } = useBoolean(false);

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });
  const breadCrumbItems = [
    { label: "Q&A", href: "/managers/question_answer_categories" },
    { label: "新規作成", href: "/managers/question_answer_categories/new" },
  ];

  const handleDraftSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = {
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      name: name,
      providingServiceId: providingOptions.dropdownValue?.value || "",
      publicStatusId: PublicStatus.DRAFTED.id,
      allBusinessSectionsDistribution,
    };
    postQuestionAnswerCategory(
      {
        body,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate(
            `/managers/question_answer_categories/${data.questionAnswerCategory.id}/edit`,
          );
        },
      },
    );
  };

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <Form className="space-y-6" onSubmit={handleDraftSubmit}>
          <AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField
            allParents={selectableDivisions}
            parentsValue={selectedDivisions}
            parentsOnChange={onDivisionChange}
            parentRequired={true}
            parentLabel="配布先: 部署"
            allChildren={optionSelectableSections}
            childrenValue={optionSelectedSections}
            childrenOnChange={onSectionChange}
            childRequired={true}
            childLabel="配布先: 課"
            allBusinessSectionsDistribution={allBusinessSectionsDistribution}
            onChangeAllBusinessSectionsDistribution={
              onChangeAllBusinessSectionsDistribution
            }
            currentEmployeeRole={currentEmployee?.employeeRole}
          />
          <LabelWithTextField
            labelText="カテゴリ名"
            type="text"
            name="name"
            placeholder="カテゴリ名"
            required
            value={name}
            onChange={onChangeName}
          />
          <LabelWithDropDownField
            labelText="サービス"
            name="providingService"
            options={providingOptions.dropdownSelectableValue}
            onChange={providingOptions.onChange}
            value={providingOptions.dropdownValue}
          />
          <FormSubmitButton
            value="Q&Aカテゴリ作成"
            color="primary"
            className="w-full"
            isReadOnly={isSubmitting}
          />
        </Form>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
