import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersKpiTermsKpiObjectivesDuplicateMutateType } from "~/domains";

type RequestDataType = {
  kpiTermId: string;
  kpiObjectiveId: string;
};

const request = ({ kpiTermId, kpiObjectiveId }: RequestDataType) => {
  return new ApiClient()
    .post<ApiManagersKpiTermsKpiObjectivesDuplicateMutateType>(
      `/api/managers/kpi_terms/${kpiTermId}/kpi_objectives/${kpiObjectiveId}/duplicates`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useManagersKpiTermsKpiObjectivesDuplicatesCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersKpiTermsKpiObjectivesDuplicateMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
