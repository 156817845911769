import React, { ChangeEvent, FC, FormEvent, MouseEvent } from "react";

import { toast } from "react-toastify";

import { isAfterDate, startOfTodayDate } from "~/libs";

import {
  useApisManagersKpiTermsDestroy,
  useManagersKpiTermsDuplicatesCreate,
} from "~/hooks";

import {
  FormSubmitButton,
  Form,
  Button,
  GridHeaderCells,
} from "~/components/atoms";
import {
  GridRow,
  OpenedMenuInGrid,
  LabelWithTextField,
  InfiniteScrollWithMoreButton,
  RangeDatePicker,
  LabelWithDropDownField,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import {
  BusinessDivisionType,
  ChildType,
  KpiTermType,
  OptionType,
  ParentType,
  PublicStatus,
  RangeDatePropsType,
  SingleValueType,
} from "~/domains";

type PropsType = {
  className?: string;
  kpiTerms: KpiTermType[];
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  hasNextPage?: boolean;
  isLoading: boolean;
  selectableDropdownPublicStatusIds: OptionType[];
  selectedDropdownPublicStatusId?: SingleValueType<OptionType>;
  selectableDropdownDivisions: BusinessDivisionType[];
  selectedDropdownDivisions: BusinessDivisionType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  unshiftItem: (item: KpiTermType) => void;
  findAndRemove: (func: (item: KpiTermType) => boolean) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeDropdownPublicStatusId: (value: SingleValueType<OptionType>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const ManagersKpiTermsIndexTemplate: FC<PropsType> = ({
  className,
  kpiTerms,
  keyword,
  isLoading,
  hasNextPage,
  startDate,
  endDate,
  selectableDropdownPublicStatusIds,
  selectedDropdownPublicStatusId,
  selectableDropdownDivisions,
  selectedDropdownDivisions,
  selectableDropdownSections,
  selectedDropdownSections,
  onDivisionChange,
  onSectionChange,
  unshiftItem,
  findAndRemove,
  onChangeDropdownPublicStatusId,
  fetchNextPage,
  onChangeDateRange,
  onChangeKeyword,
  onConditionReset,
  onSearchSubmit,
}: PropsType) => {
  const { mutate: destroyRequest } = useApisManagersKpiTermsDestroy();
  const { mutate: duplicateRequest } = useManagersKpiTermsDuplicatesCreate();

  const today = startOfTodayDate();
  const deleteKpiTerm = (targetKpiTerm: KpiTermType) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;
    destroyRequest(
      {
        id: targetKpiTerm.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((kpiTerm) => kpiTerm.id === targetKpiTerm.id);
        },
      },
    );
  };

  const duplicateRequestKpiTerm = (targetKpiTerm: KpiTermType) => {
    const isConfirm = confirm("本当に複製しますか？");
    if (!isConfirm) return;
    duplicateRequest(
      {
        kpiTermId: targetKpiTerm.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          unshiftItem(data.kpiTerm);
        },
      },
    );
  };

  const generateKpiTermLists = (kpiTerm: KpiTermType) => {
    return [
      `${kpiTerm.fromDate}~${kpiTerm.toDate}`,
      kpiTerm.title,
      kpiTerm.businessDivision.name,
      kpiTerm.businessSection.name,
      kpiTerm.publicStatus.name,
    ];
  };

  const isUnEditable = (kpiTerm: KpiTermType) => {
    const isExpiredTerm = isAfterDate(today, new Date(kpiTerm.toDate));
    const isPublic = kpiTerm.publicStatus.type === PublicStatus.PUBLISHED.type;
    return isExpiredTerm && isPublic;
  };

  return (
    <div className={className}>
      <Form onSubmit={onSearchSubmit} className="space-y-6">
        <div className="grid miniTablet:grid-cols-2 gap-4">
          <MultiParentsWithMultiChildrenDropdownField
            allParents={selectableDropdownDivisions}
            parentsValue={selectedDropdownDivisions}
            parentLabel="部署"
            parentsOnChange={onDivisionChange}
            allChildren={selectableDropdownSections}
            childrenValue={selectedDropdownSections}
            childLabel="課"
            childrenOnChange={onSectionChange}
          />
          <RangeDatePicker
            labelText="期間指定"
            startDate={startDate}
            endDate={endDate}
            onChange={onChangeDateRange}
          />
          <LabelWithDropDownField
            labelText="ステータス"
            name="publicStatus"
            options={selectableDropdownPublicStatusIds}
            value={selectedDropdownPublicStatusId}
            onChange={onChangeDropdownPublicStatusId}
          />
          <LabelWithTextField
            labelText="フリーワード"
            type="search"
            name="keyword"
            placeholder="フリーワード検索"
            value={keyword}
            onChange={onChangeKeyword}
          />
        </div>
        <div className="flex items-center justify-end space-x-4">
          <Button
            color="gray"
            outline
            className="w-full miniTablet:w-auto"
            text="リセット"
            onClick={onConditionReset}
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full miniTablet:w-auto"
          />
        </div>
      </Form>
      <InfiniteScrollWithMoreButton
        itemsLength={kpiTerms.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        isLoading={isLoading}
        className="mt-12"
      >
        <div className="grid grid-cols-5">
          <GridHeaderCells
            texts={["期間", "タイトル", "部署", "課", "ステータス"]}
          />
          {kpiTerms.map((kpiTerm) => (
            <GridRow
              key={kpiTerm.id}
              lists={generateKpiTermLists(kpiTerm)}
              openedMenu={
                <OpenedMenuInGrid
                  cols={5}
                  buttons={[
                    {
                      text: "編集",
                      link: `/managers/kpi_terms/${kpiTerm.id}/edit`,
                      isHidden: isUnEditable(kpiTerm),
                    },
                    {
                      text: "削除",
                      onClick: () => deleteKpiTerm(kpiTerm),
                      isHidden: isUnEditable(kpiTerm),
                    },
                    {
                      text: "複製",
                      onClick: () => duplicateRequestKpiTerm(kpiTerm),
                    },
                    {
                      text: "状況確認",
                      link: `/managers/kpi_terms/${kpiTerm.id}/dashboard`,
                    },
                  ]}
                />
              }
            />
          ))}
        </div>
      </InfiniteScrollWithMoreButton>
    </div>
  );
};
