import React, { FC, FormEvent, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useEditorState,
  useManagersEmployeeReviewsNavigation,
  useApisManagersEmployeesEmployeeMemoReviewsCreate,
  useSearchManagersEmployeesEmployeeMemoReviews,
  useRightSidebarState,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { RightSidebarToggleIconWithLabel } from "~/components/molecules";
import {
  EmployeeMemoReviewDetailWithEditForms,
  EmployeeAndBusinessSectionMemoReviewForm,
  EmployeeAndBusinessSectionMemoReviewSearchForm,
} from "~/components/organisms";

import { PreviewWithFileType } from "~/domains";

export const ManagersEmployeesEmployeeMemoReviewsIndex: FC = () => {
  const { employeeId = "" } = useParams<{ employeeId: string }>();
  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";

  const { searchOpenToggle, setClose, isOpen } =
    useRightSidebarState("isClose");
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const { mutate: postRequest, isLoading: isSubmitting } =
    useApisManagersEmployeesEmployeeMemoReviewsCreate();

  const {
    employeeMemoReviews,
    isLoading,
    hasNextPage,
    employee,
    keyword,
    startDate,
    endDate,
    selectableDropdownCompleteStatusIds,
    selectedDropdownCompleteStatusId,
    fetchNextPage,
    unshiftItem,
    deleteIndexItem,
    updateIndexItem,
    onChangeDateRange,
    onChangeKeyword,
    onChangeDropdownCompleteStatusId,
    onSearchSubmit,
    onConditionReset,
  } = useSearchManagersEmployeesEmployeeMemoReviews(employeeId);

  const breadCrumbItems = [
    {
      label: "メンバーメモ",
      href: `/managers/business_section_reviews?business_section_id=${businessSectionId}`,
    },
    {
      label: `${employee?.name || ""}のメモ`,
      href: `/managers/employees/${employeeId}/employee_memo_reviews?business_section_id=${businessSectionId}`,
    },
  ];

  const { navigations } = useManagersEmployeeReviewsNavigation({
    employeeId,
    businessSectionId,
    selectedArray: [true, false],
  });

  const [
    {
      jsonContent: editorStateJsonContent,
      value: editorState,
      setPlainText,
      onChange: onChangeEditorState,
    },
  ] = useEditorState();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    postRequest(
      {
        employeeId,
        body: {
          content: editorStateJsonContent,
          attachFiles: files,
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setPlainText("");
          setFiles([]);
          unshiftItem(data.employeeMemoReview);
        },
      },
    );
  };

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <EmployeeAndBusinessSectionMemoReviewForm
          editorState={editorState}
          onChangeEditorState={onChangeEditorState}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          files={files}
          onChangeFiles={setFiles}
          className="mt-9"
        />
        {employee && (
          <EmployeeMemoReviewDetailWithEditForms
            employeeMemoReviews={employeeMemoReviews}
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            employee={employee}
            fetchNextPage={fetchNextPage}
            deleteIndexPost={deleteIndexItem}
            updateIndexPost={updateIndexItem}
            className="mt-9"
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={setClose}
          label={"検索条件"}
          isOpen={isOpen}
        >
          {isOpen && (
            <EmployeeAndBusinessSectionMemoReviewSearchForm
              date={{
                startDate: startDate,
                endDate: endDate,
                onChange: onChangeDateRange,
              }}
              searchKeyword={{
                value: keyword,
                onChange: onChangeKeyword,
              }}
              completed={{
                options: selectableDropdownCompleteStatusIds,
                value: selectedDropdownCompleteStatusId,
                onChange: onChangeDropdownCompleteStatusId,
              }}
              onSubmit={onSearchSubmit}
              onConditionReset={onConditionReset}
            />
          )}
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
