import React, { FC } from "react";

type PropsType = {
  className?: string;
  url: string;
  title?: string;
};

export const FileViewerIframe: FC<PropsType> = ({
  className = "",
  url,
  title = "添付ファイル",
}: PropsType) => {
  return (
    <iframe
      className={className}
      title={title}
      loading="lazy"
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        url,
      )}`}
      width="100%"
      height={400}
    />
  );
};
