import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiMembersSalesConceptCategoriesIndexRequestType,
  ApiMembersBusinessSectionSalesConceptCategoriesIndexResponseType,
} from "~/domains";

type RequestDataType = {
  params?: ApiMembersSalesConceptCategoriesIndexRequestType;
  businessSectionId: string;
  page?: number;
};

const request = async ({
  params,
  businessSectionId,
  page = 1,
}: RequestDataType): Promise<ApiMembersBusinessSectionSalesConceptCategoriesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersBusinessSectionSalesConceptCategoriesIndexResponseType>(
      `/api/members/business_sections/${businessSectionId}/sales_concept_categories`,
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  businessSectionId: string;
  params?: ApiMembersSalesConceptCategoriesIndexRequestType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersBusinessSectionsSalesConceptCategoriesIndex = ({
  businessSectionId,
  params,
  config = {},
}: PropsType): UseInfiniteQueryResult<ApiMembersBusinessSectionSalesConceptCategoriesIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    [
      "ApisMembersBusinessSectionsSalesConceptCategoriesIndex",
      businessSectionId,
      params,
    ],
    ({ pageParam }) => {
      return request({
        businessSectionId,
        params,
        page: pageParam as number | undefined,
      });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
