import React, { FC, FormEvent } from "react";

import { toast } from "react-toastify";

import {
  useApisMembersBookmarkedInsightPostsBookmarkFolderMovesUpdate,
  useApisMembersInsightPostsBookmarksShow,
  useApisMembersInsightPostBookmarkFoldersIndex,
  useInput,
} from "~/hooks";

import { BookmarkMoveModal } from "./BookmarkMoveModal";

type PropsType = {
  onClose: () => void;
  insightPostId: string;
};

export const InsightPostBookmarkMoveModal: FC<PropsType> = ({
  onClose,
  insightPostId,
}: PropsType) => {
  const { data: bookmarkFoldersIndex } =
    useApisMembersInsightPostBookmarkFoldersIndex();
  useApisMembersInsightPostsBookmarksShow({
    insightPostId: insightPostId,
    config: {
      onSuccess: (data) => {
        setBookmarkFolderId(data.bookmarkFolder?.id);
      },
    },
  });
  const [
    { value: bookmarkFolderId, onChange: onChangeBookmarkFolderId },
    setBookmarkFolderId,
  ] = useInput("");

  const { mutate } =
    useApisMembersBookmarkedInsightPostsBookmarkFolderMovesUpdate();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      {
        insightPostId: insightPostId,
        body: { bookmarkFolderId },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          onClose();
        },
      },
    );
  };

  return (
    <BookmarkMoveModal
      onClose={onClose}
      bookmarkFolders={bookmarkFoldersIndex?.bookmarkFolders || []}
      bookmarkFolderId={bookmarkFolderId}
      onChangeBookmarkFolderId={onChangeBookmarkFolderId}
      onSubmit={onSubmit}
    />
  );
};
