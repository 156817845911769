import React, { FC } from "react";

import { useSearchMembersBookmarkedInsightPosts } from "~/hooks";

import { EmployeeLayout } from "~/components/layouts";
import { BookmarkInsightPostsIndexTemplate } from "~/components/templates";

export const MembersBookmarkedInsightPostsIndex: FC = () => {
  const {
    insightPosts,
    startDate,
    endDate,
    hasNextPage,
    isLoading,
    sort,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    keyword,
    selectableDropdownHasCommentIds,
    selectedDropdownHasCommentId,
    selectableDropdownSharedIds,
    selectedDropdownSharedId,
    selectableJobCategories,
    selectedJobCategories,
    optionSelectableJobCategoryPhases,
    optionSelectedJobCategoryPhases,
    selectedProvidingServices,
    selectableProvidingServices,
    onChangeDropdownHasCommentId,
    onChangeDropdownSharedId,
    onChangeJobCategoryPhases,
    onChangeProvidingServices,
    onChangeJobCategories,
    onChangeDateRange,
    onChangeKeyword,
    onChangeDropdownEmployees,
    fetchNextPage,
    setSort,
    onSearchSubmit,
    onConditionReset,
    updateIndexInsightPost,
    deleteIndexInsightPost,
  } = useSearchMembersBookmarkedInsightPosts();
  const breadCrumbItems = [
    {
      label: "個人・チームへのブクマ",
      href: "/bookmark/insight_posts",
    },
  ];
  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <BookmarkInsightPostsIndexTemplate
        isLoading={isLoading}
        insightPosts={insightPosts}
        startDate={startDate}
        endDate={endDate}
        hasNextPage={hasNextPage}
        sort={sort}
        keyword={keyword}
        selectableDropdownHasCommentIds={selectableDropdownHasCommentIds}
        selectedDropdownHasCommentId={selectedDropdownHasCommentId}
        selectableDropdownSharedIds={selectableDropdownSharedIds}
        selectedDropdownSharedId={selectedDropdownSharedId}
        selectableJobCategories={selectableJobCategories}
        selectedJobCategories={selectedJobCategories}
        optionSelectableJobCategoryPhases={optionSelectableJobCategoryPhases}
        optionSelectedJobCategoryPhases={optionSelectedJobCategoryPhases}
        selectedProvidingServices={selectedProvidingServices}
        selectableProvidingServices={selectableProvidingServices}
        selectableDropdownEmployees={selectableDropdownEmployees}
        selectedDropdownEmployees={selectedDropdownEmployees}
        onChangeDropdownHasCommentId={onChangeDropdownHasCommentId}
        onChangeDropdownSharedId={onChangeDropdownSharedId}
        onChangeJobCategoryPhases={onChangeJobCategoryPhases}
        onChangeProvidingServices={onChangeProvidingServices}
        onChangeJobCategories={onChangeJobCategories}
        onChangeDateRange={onChangeDateRange}
        onChangeKeyword={onChangeKeyword}
        onChangeDropdownEmployees={onChangeDropdownEmployees}
        fetchNextPage={fetchNextPage}
        setSort={setSort}
        onSearchSubmit={onSearchSubmit}
        onConditionReset={onConditionReset}
        updateIndexInsightPost={updateIndexInsightPost}
        deleteIndexInsightPost={deleteIndexInsightPost}
      />
    </EmployeeLayout>
  );
};
