import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { useApisManagersRoleplayingConditionTemplatesEdit } from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { RoleplayingConditionTemplateForm } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const ManagersRoleplayingConditionTemplatesEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data, isError } = useApisManagersRoleplayingConditionTemplatesEdit({
    id,
  });

  const breadCrumbItems = [
    {
      label: "ロールプレイングテンプレ",
      href: "/managers/roleplaying_condition_templates",
    },
    {
      label: "編集",
      href: `/managers/roleplaying_condition_templates/${id}/edit`,
    },
  ];

  if (isError) return <NotFoundIndex />;

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          {data && (
            <RoleplayingConditionTemplateForm
              roleplayingConditionTemplate={data.roleplayingConditionTemplate}
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
