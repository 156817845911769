import React, { FC } from "react";

import {
  useApisMembersKpiBusinessSectionsPostCalendarIndex,
  usePrevNextSelectMonth,
} from "~/hooks";

import { ActivityOriginalCalendar } from "~/components/molecules";

type PropsType = {
  businessSectionId: string;
  className?: string;
};

export const KpiDailyPerformanceCalendar: FC<PropsType> = ({
  businessSectionId,
  className = "",
}: PropsType) => {
  const {
    targetDate,
    targetDateString,
    handlePrevMonthClick,
    handleNextMonthClick,
  } = usePrevNextSelectMonth();

  const { data, isLoading } =
    useApisMembersKpiBusinessSectionsPostCalendarIndex({
      businessSectionId,
      params: {
        targetDate: targetDateString,
      },
      config: {
        enabled: !!businessSectionId,
      },
    });
  return (
    <ActivityOriginalCalendar
      isLoading={isLoading}
      dailyActivity={data?.postCalendars.map((item) => ({
        date: item.date,
        postsCount: item.postsRate,
      }))}
      targetDate={targetDate}
      isForRate
      onPrevMonthClick={handlePrevMonthClick}
      onNextMonthClick={handleNextMonthClick}
      className={className}
    />
  );
};
