import React, { FC, useState } from "react";

import { ModalWrapper } from "~/components/atoms";

import { FloatingChatForm } from "./FloatingChatForm/FloatingChatForm";
import { FloatingChatInitial } from "./FloatingChatInitial/FloatingChatInitial";

type PropsType = {
  onClose: () => void;
};

export const FloatingChatBox: FC<PropsType> = ({ onClose }: PropsType) => {
  const [aiChatRoomId, setAiChatRoomId] = useState<string | null>(null);
  return (
    <>
      <ModalWrapper isWhite onClose={onClose} />
      <div className="fixed bottom-20 miniTablet:left-6 miniTablet:bottom-8 h-5/6 w-full miniTablet:w-5/12 bg-white z-modal shadow px-6 py-8 rounded overflow-y-auto">
        {aiChatRoomId ? (
          <FloatingChatForm
            onClose={onClose}
            setAiChatRoomId={setAiChatRoomId}
            aiChatRoomId={aiChatRoomId}
          />
        ) : (
          <FloatingChatInitial
            onClose={onClose}
            setAiChatRoomId={setAiChatRoomId}
          />
        )}
      </div>
    </>
  );
};
