import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersInsightPostsNewResponseType } from "~/domains";

const request = async (): Promise<ApiManagersInsightPostsNewResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersInsightPostsNewResponseType>(
      "/api/managers/insight_posts/new",
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersInsightPostsNew = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiManagersInsightPostsNewResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersInsightPostsNew"],
    queryFn: request,
  });
};
