import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisMembersRankingInsightPostsIndexRequestTypes,
  ApisMembersRankingInsightPostsIndexResponseTypes,
} from "~/domains";

type RequestDataType = {
  params: ApisMembersRankingInsightPostsIndexRequestTypes;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApisMembersRankingInsightPostsIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisMembersRankingInsightPostsIndexResponseTypes>(
      "/api/members/ranking/insight_posts",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params: ApisMembersRankingInsightPostsIndexRequestTypes;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersRankingInsightPostsIndex = ({
  params,
  config = {},
}: PropsType): UseQueryResult<ApisMembersRankingInsightPostsIndexResponseTypes> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersRankingInsightPostsIndex", params],
    queryFn: () => request({ params }),
  });
};
