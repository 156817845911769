import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  documentFileCategoryId: string;
  body: {
    id: string;
    displayOrder: number;
  }[];
};

const request = ({ documentFileCategoryId, body }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseMessageType>(
      `/api/managers/document_file_categories/${documentFileCategoryId}/display_order`,
      {
        documentFileCategory: { documentFiles: body },
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersDocumentFileCategoriesDisplayOrdersUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
