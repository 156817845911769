import React, { FC, MouseEvent } from "react";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Modifier, EditorState, RichUtils } from "draft-js";
import update from "immutability-helper";
import Dropzone, { FileRejection } from "react-dropzone";
import { toast } from "react-toastify";

import "@draft-js-plugins/mention/lib/plugin.css";

import { useBoolean } from "~/hooks";

import {
  Icon,
  Label,
  ModalWrapper,
  DropzoneFileField,
  ButtonWithIcon,
} from "~/components/atoms";
import { Editor, PostedFilePreviews } from "~/components/molecules";

import {
  AttachFileType,
  AvatarAndNameEmployeeType,
  EditorStateType,
  PreviewWithFileType,
  RawDraftContentState,
} from "~/domains";

import { ACCEPT_ALL_FILE_TYPES, FILE_MAX_BYTE_SIZE } from "~/constants/file";

type PropsType = {
  labelText?: string;
  required?: boolean;
  mentions: AvatarAndNameEmployeeType[];
  files: PreviewWithFileType[];
  onChangeFiles?: (files: PreviewWithFileType[]) => void;
  editorState: EditorStateType;
  readOnly?: boolean;
  blockQuote?: {
    content: RawDraftContentState;
    employee: AvatarAndNameEmployeeType;
    files: AttachFileType[];
    createdAt: string;
  };
  setEditorState: (editorState: EditorStateType) => void;
  submitButton?: {
    onSubmit: (e: MouseEvent<HTMLButtonElement>) => void;
    isSubmitting: boolean;
  };
  className?: string;
  defaultAutoFucus?: boolean;
  canFileInText?: boolean;
  forBottom?: boolean;
  isEmojiPickerTop?: boolean;
  isCompactSize?: boolean;
};

const MAX_FILES = 4;

export const TextEditor: FC<PropsType> = ({
  labelText = "",
  required = false,
  mentions,
  files = [],
  onChangeFiles,
  editorState,
  readOnly = false,
  blockQuote,
  setEditorState,
  submitButton,
  className = "",
  defaultAutoFucus = false,
  canFileInText = false,
  forBottom = false,
  isEmojiPickerTop = false,
}: PropsType) => {
  const emojiPickerOpen = useBoolean(false);
  const onHandleImageDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    if (!onChangeFiles) return;

    if (acceptedFiles.length + files.length > MAX_FILES) {
      toast.error("画像は4枚まで貼付可能です");
      return;
    }
    if (fileRejections.length) {
      fileRejections.map(({ file, errors }) => {
        if (!errors[0]) return;
        toast.error(`${file.name} - ${errors[0].message}`);
      });
      return;
    }
    const newFiles = acceptedFiles.map((file) =>
      Object.assign(file, { previewPath: URL.createObjectURL(file) }),
    );
    onChangeFiles(update(files, { $push: newFiles }));
  };

  const handleToggleClick = (
    e: MouseEvent<HTMLButtonElement>,
    inlineStyle: string,
  ) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const handleToggleBlockType = (
    e: MouseEvent<HTMLButtonElement>,
    blockStyle: string,
  ) => {
    e.preventDefault();

    setEditorState(RichUtils.toggleBlockType(editorState, blockStyle));
  };

  const handleMention = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!mentions.length) return;

    addText(" @");
  };

  const addText = (text: string) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const modifierText = Modifier.insertText(contentState, selection, text);
    const nextEditorState = EditorState.push(
      editorState,
      modifierText,
      "insert-characters",
    );
    setEditorState(nextEditorState);
  };

  const removeFile = (fileIndex: number) => {
    if (!onChangeFiles) return;

    const file = files[fileIndex];
    if (!file) return;
    URL.revokeObjectURL(file.previewPath);
    onChangeFiles(update(files, { $splice: [[fileIndex, 1]] }));
  };

  const handleEmojiModalToggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    emojiPickerOpen.toggle();
  };

  const isDisabled =
    submitButton?.isSubmitting || !editorState.getCurrentContent().hasText();

  return (
    <div className={className}>
      {labelText && (
        <Label
          labelText={labelText}
          required={required}
          htmlFor="contentText"
        />
      )}
      <DropzoneFileField
        noClick
        onDropFiles={onHandleImageDrop}
        acceptFileTypes={ACCEPT_ALL_FILE_TYPES}
        multiple
        maxFileByte={FILE_MAX_BYTE_SIZE}
        maxFiles={MAX_FILES}
        readonly={readOnly}
      >
        <div
          className={`${readOnly ? "bg-secondary-300" : "cursor-text"} ${
            labelText && "mt-1.5"
          } ${
            forBottom ? "forBottom min-h-[5rem]" : "min-h-[9rem]"
          } border border-secondary-400 border-solid rounded pt-2 pb-1 px-3`}
        >
          <div className="w-full">
            <Editor
              editorState={editorState}
              onChange={setEditorState}
              readOnly={readOnly}
              mentions={mentions}
              required={required}
              canFileInText={canFileInText}
              defaultAutoFucus={defaultAutoFucus}
              blockQuote={blockQuote}
            />
          </div>
          {Boolean(files.length) && (
            <PostedFilePreviews
              files={files.map((file) => ({
                name: file.name,
                url: file.previewPath,
                contentType: file.type,
              }))}
              removeFileIndex={removeFile}
              className="mt-2"
            />
          )}
          {!readOnly && (
            <>
              <div className="flex items-center justify-end mt-4 pt-1 space-x-2 border-t">
                {Boolean(onChangeFiles) && (
                  <Dropzone
                    onDrop={onHandleImageDrop}
                    accept={ACCEPT_ALL_FILE_TYPES}
                    multiple
                    maxSize={FILE_MAX_BYTE_SIZE}
                    maxFiles={MAX_FILES}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="cursor-pointer p-1.5 rounded-full hover:bg-secondary-200"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <Icon
                          icon="ioDuplicateOutline"
                          size="1.5rem"
                          color="text-primary-600"
                        />
                      </div>
                    )}
                  </Dropzone>
                )}
                {Boolean(mentions.length) && (
                  <ButtonWithIcon
                    srOnlyText="メンションを追加する"
                    withHoverEffect
                    onClick={handleMention}
                    icon={{
                      icon: "ioAtOutline",
                      size: "1.5rem",
                      color: "text-primary-600",
                    }}
                  />
                )}
                <ButtonWithIcon
                  srOnlyText="太字にする"
                  onClick={(e) => handleToggleClick(e, "BOLD")}
                  withHoverEffect
                  icon={{
                    icon: "fiBold",
                    size: "1.5rem",
                    color: "text-primary-600",
                  }}
                />
                <ButtonWithIcon
                  srOnlyText="文字を大きくする"
                  onClick={(e) => handleToggleBlockType(e, "header-one")}
                  withHoverEffect
                  icon={{
                    icon: "ioTextOutline",
                    size: "1.5rem",
                    color: "text-primary-600",
                  }}
                />
                <ButtonWithIcon
                  srOnlyText="絵文字を追加する"
                  withHoverEffect
                  onClick={handleEmojiModalToggle}
                  icon={{
                    icon: "ioHappyOutline",
                    size: "1.5rem",
                    color: "text-primary-600",
                  }}
                />
                {submitButton && (
                  <ButtonWithIcon
                    srOnlyText="保存する"
                    disabled={isDisabled}
                    onClick={submitButton.onSubmit}
                    withHoverEffect
                    icon={{
                      icon: "ioSend",
                      size: "1.5rem",
                      className: `${isDisabled ? "cursor-not-allowed" : ""}`,
                      color: isDisabled
                        ? "text-secondary-400"
                        : "text-primary-600",
                    }}
                  />
                )}
              </div>
              {emojiPickerOpen.isChecked && (
                <>
                  <ModalWrapper isWhite onClose={emojiPickerOpen.setFalse} />
                  <div className="relative">
                    <div
                      className={`flex justify-end absolute z-modal right-0 ${
                        isEmojiPickerTop ? "bottom-10" : "-top-1"
                      }`}
                    >
                      <Picker
                        data={data}
                        onEmojiSelect={(emoji: { native: string }) =>
                          addText(` ${emoji.native} `)
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </DropzoneFileField>
    </div>
  );
};
