import React, { FC } from "react";

import { Icon } from "~/components/atoms";

type PropsType = {
  toggle: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  displayOrder: number;
  title: string;
  maxPoint: number;
  isChecked: boolean;
};

export const SkillEvaluationStandardSummaryDetailTitle: FC<PropsType> = ({
  toggle,
  displayOrder,
  title,
  maxPoint,
  isChecked,
}: PropsType) => {
  return (
    <button
      type="button"
      onClick={toggle}
      className="font-semibold text-center flex items-center justify-between border-b border-gray-300 pb-2 w-full"
    >
      <div className="flex items-center text-primary-600">
        <span>{displayOrder.toString().padStart(2, "0")}</span>
        <h3 className="ml-4 text-left">{title}</h3>
      </div>
      <div className="flex items-center space-x-2 shrink-0 text-secondary-600">
        <span className="text-lg">/ {maxPoint}</span>
        <Icon icon={isChecked ? "ioRemoveOutline" : "ioAdd"} size="1.25rem" />
      </div>
    </button>
  );
};
