import { useState, useCallback, useEffect } from "react";

import {
  JobCategoryType,
  JobCategoryPhaseType,
  ParentType,
  ChildType,
  ID_ALL_TYPE,
} from "~/domains";

type PickedJobCategoryPhaseType = Pick<
  JobCategoryPhaseType,
  "id" | "name" | "jobCategoryId"
>;

type PropsType = {
  selectableJobCategories?: JobCategoryType[];
  defaultJobCategories?: JobCategoryType[];
  selectableJobCategoryPhases?: PickedJobCategoryPhaseType[];
  defaultJobCategoryPhases?: PickedJobCategoryPhaseType[];
  withAllJobCategoryPhase?: boolean;
};

type ReturnType = {
  selectableJobCategories: JobCategoryType[];
  selectedJobCategories: JobCategoryType[];
  onChangeJobCategories: (newValue: ParentType[]) => void;
  selectableJobCategoryPhases: PickedJobCategoryPhaseType[];
  selectedJobCategoryPhases: PickedJobCategoryPhaseType[];
  onChangeJobCategoryPhases: (newValue: ChildType[]) => void;
  optionSelectableJobCategoryPhases: ChildType[];
  optionSelectedJobCategoryPhases: ChildType[];
  setSelectedJobCategories: (newValue: JobCategoryType[]) => void;
  setSelectedJobCategoryPhases: (
    newValue: PickedJobCategoryPhaseType[],
  ) => void;
};

export const useMultiJobCategoryMultiJobCategoryPhaseDropdown = ({
  selectableJobCategories = [],
  defaultJobCategories = [],
  selectableJobCategoryPhases = [],
  defaultJobCategoryPhases = [],
  withAllJobCategoryPhase = false,
}: PropsType = {}): ReturnType => {
  const [selectedJobCategories, setSelectedJobCategories] =
    useState(defaultJobCategories);
  const [selectedJobCategoryPhases, setSelectedJobCategoryPhases] = useState(
    defaultJobCategoryPhases,
  );

  const convertChildToOption = useCallback(
    (jobCategoryPhases: PickedJobCategoryPhaseType[]) => {
      return jobCategoryPhases.map((jobCategoryPhase) => ({
        id: jobCategoryPhase.id,
        name: jobCategoryPhase.name,
        parentId: jobCategoryPhase.jobCategoryId,
      }));
    },
    [],
  );

  const convertToJObCategoryPhases = useCallback((newValues: ChildType[]) => {
    return newValues.map((value) => ({
      id: value.id,
      name: value.name,
      jobCategoryId: value.parentId,
    }));
  }, []);

  const onChangeJobCategories = (newJobCategories: JobCategoryType[]) => {
    const jobCategoryIds = newJobCategories.map(
      (jobCategory) => jobCategory.id,
    );

    const filteredSections = selectedJobCategoryPhases.filter((section) =>
      jobCategoryIds.includes(section.jobCategoryId),
    );

    setSelectedJobCategories(newJobCategories);
    setSelectedJobCategoryPhases(filteredSections);
  };

  const onChangeJobCategoryPhases = (newJobCategoryPhases: ChildType[]) => {
    setSelectedJobCategoryPhases(
      convertToJObCategoryPhases(newJobCategoryPhases),
    );
  };

  const selectableJobCategoryPhasesWithAll = withAllJobCategoryPhase
    ? [
        {
          ...ID_ALL_TYPE,
          jobCategoryId: selectedJobCategories.at(0)?.id || "0",
        },
        ...selectableJobCategoryPhases,
      ]
    : selectableJobCategoryPhases;
  const optionSelectableJobCategoryPhases = convertChildToOption(
    selectableJobCategoryPhasesWithAll,
  );
  const optionSelectedJobCategoryPhases = convertChildToOption(
    selectedJobCategoryPhases,
  );

  useEffect(() => {
    defaultJobCategories.length &&
      setSelectedJobCategories(defaultJobCategories);
  }, [defaultJobCategories]);

  useEffect(() => {
    defaultJobCategoryPhases.length &&
      setSelectedJobCategoryPhases(defaultJobCategoryPhases);
  }, [defaultJobCategoryPhases]);

  return {
    selectableJobCategories,
    selectedJobCategories,
    onChangeJobCategories,
    selectableJobCategoryPhases: selectableJobCategoryPhasesWithAll,
    selectedJobCategoryPhases,
    onChangeJobCategoryPhases,
    optionSelectableJobCategoryPhases,
    optionSelectedJobCategoryPhases,
    setSelectedJobCategories,
    setSelectedJobCategoryPhases,
  };
};
