export const ForcedSharedOption = {
  id: 1,
  name: "強制共有",
  type: "forced_shared",
} as const;

export const SelectedOption = {
  id: 10,
  name: "選択共有",
  type: "selected",
} as const;

export const NotSharedOption = {
  id: 20,
  name: "非共有",
  type: "not_shared",
} as const;

export const AnswerShareOption = {
  FORCED_SHARED: ForcedSharedOption,
  SELECTED: SelectedOption,
  NOT_SHARED: NotSharedOption,
} as const;

export const AllAnswerShareOptions = [
  AnswerShareOption.FORCED_SHARED,
  AnswerShareOption.SELECTED,
  AnswerShareOption.NOT_SHARED,
] as const;

export type AnswerShareOptionType =
  | typeof ForcedSharedOption
  | typeof SelectedOption
  | typeof NotSharedOption;
