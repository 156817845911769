import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersQuestionAnswerCategoriesMutateRequestType,
  ApiManagersQuestionAnswerCategoryMutateType,
} from "~/domains";

type RequestDataType = {
  id: string;
  body: ApiManagersQuestionAnswerCategoriesMutateRequestType;
};

const request = ({ id, body }: RequestDataType) => {
  return new ApiClient()
    .put<ApiManagersQuestionAnswerCategoryMutateType>(
      `/api/managers/question_answer_categories/${id}`,
      {
        questionAnswerCategory: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersQuestionAnswerCategoriesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersQuestionAnswerCategoryMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
