import React, { FC, MouseEvent } from "react";

import { useBoolean } from "~/hooks";

import { DownloadLink, Icon } from "~/components/atoms";
import {
  FilePreviewByContentType,
  FileViewerModal,
} from "~/components/molecules";

import { AttachFileType } from "~/domains";

type PropsType = {
  file: AttachFileType;
  removeFileIndex?: (index: number) => void;
  index: number;
};

export const PostedFilePreview: FC<PropsType> = ({
  file,
  index,
  removeFileIndex,
}: PropsType) => {
  const handleRemoveFileIndex = (
    e: MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    e.preventDefault();
    removeFileIndex?.(index);
  };

  const {
    isChecked: isModalOpen,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setOpen();
  };

  return (
    <>
      <div className="w-full group relative p-2 flex items-center rounded border-secondary-400 border border-solid">
        <button
          type="button"
          className="flex items-center w-full"
          onClick={handleOpen}
        >
          {removeFileIndex && (
            <span
              onClick={(e: MouseEvent<HTMLButtonElement>) =>
                handleRemoveFileIndex(e, index)
              }
              className="opacity-0 group-hover:opacity-100 absolute -top-2 -right-2 cursor-pointer rounded-full border-secondary-400 border border-solid bg-gray-200 p-0.5"
            >
              <Icon icon="ioCloseOutline" size="0.8rem" className="mx-auto" />
            </span>
          )}
          <FilePreviewByContentType
            fileName={file.name}
            fileContentType={file.contentType}
            filePreviewPath={file.url}
            className="w-12 h-12 rounded shrink-0"
            imageClassName=""
          />
          <div className="flex flex-col h-full justify-between ml-2 truncate">
            <span className="block truncate font-medium">{file.name}</span>
            <span className="text-left text-sm text-secondary-600">
              {file.name.split(".").pop()}
            </span>
          </div>
        </button>
        <DownloadLink
          fileName={file.name}
          fileUrl={file.url}
          className="opacity-0 group-hover:opacity-100 absolute bottom-2 right-2 bg-white p-2 border rounded"
        >
          <Icon
            icon="riDownloadCloudLine"
            color="text-primary-600"
            size="2rem"
          />
        </DownloadLink>
      </div>
      {isModalOpen && (
        <FileViewerModal onClose={setClose} attachedFile={file} />
      )}
    </>
  );
};
