import React, { FC, MouseEvent } from "react";

import { Avatar, ButtonWithIcon } from "~/components/atoms";
type PropsType = {
  avatarUrl: string;
  employeeName: string;
  createdAt: string;
  className?: string;
  isOpen: boolean;
  handleToggle: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const TogglablePostHeader: FC<PropsType> = ({
  avatarUrl,
  employeeName,
  createdAt,
  className = "",
  isOpen,
  handleToggle,
}: PropsType) => {
  return (
    <div className={`flex items-start justify-between ${className}`}>
      <div className="flex items-start space-x-4">
        <Avatar src={avatarUrl} size={"sm"} />
        <div>
          <p className="font-semibold">{employeeName}</p>
          <span className="text-sm">{createdAt}</span>
        </div>
      </div>
      <ButtonWithIcon
        icon={{ icon: isOpen ? "ioCloseOutline" : "ioAdd", size: "2rem" }}
        srOnlyText={isOpen ? "閉じる" : "開く"}
        onClick={handleToggle}
      />
    </div>
  );
};
