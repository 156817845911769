import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useDropdown,
  useProvidersCurrentEmployee,
  useQuestionAnswerNavigation,
  useRightSidebarState,
  useSearchMembersBusinessSectionsQuestionAnswerCategories,
} from "~/hooks";

import { DropDownField } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  RightSidebarToggleIconWithLabel,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";
import {
  QuestionAnswerCategoryList,
  QuestionAnswerCategorySearchForm,
} from "~/components/organisms";

import { AllRoles, ManagerRole } from "~/domains";

export const MembersQuestionAnswerCategoriesIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";
  const { employeeRole } = useParams<{
    employeeRole: string;
  }>();
  const role =
    AllRoles.find((role) => role.type === employeeRole) || ManagerRole;

  const [
    {
      dropdownValue: selectedBusinessSection,
      dropdownSelectableValue: selectableBusinessSections,
      onChange: onChangeBusinessSection,
    },
  ] = useDropdown({
    selectableValue: currentEmployee?.businessSections || [],
    initialValue: currentEmployee?.businessSections.find(
      (businessSection) => businessSection.id === businessSectionId,
    ),
  });

  const { navigations } = useQuestionAnswerNavigation({
    businessSectionId: selectedBusinessSection?.value,
    selectedRole: role,
  });

  const breadCrumbItems = [
    {
      label: "Q&A",
      href: `/question_answer_category/${ManagerRole.type}?business_section_id=${selectedBusinessSection?.value}`,
    },
    {
      label: role?.name || "",
      href: `/question_answer_category/${employeeRole}?business_section_id=${selectedBusinessSection?.value}`,
    },
  ];

  const {
    questionAnswerCategories,
    questionContentKeyword,
    selectableProvidingServices,
    selectedProvidingServices,
    selectableQuestionAnswerCategories,
    selectedQuestionAnswerCategories,
    hasNextPage,
    selectableTags,
    selectedTags,
    fetchNextPage,
    onChangeProvidingServices,
    onChangeQuestionAnswerCategories,
    onChangeQuestionContentKeyword,
    onSearchSubmit,
    onConditionReset,
    onChangeTags,
  } = useSearchMembersBusinessSectionsQuestionAnswerCategories({
    businessSectionId: selectedBusinessSection?.value,
    employeeRoleId: role.id,
  });
  const { searchOpenToggle, setClose, isOpen } =
    useRightSidebarState("isClose");

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <DropDownField
          placeholder="課を選択"
          name="businessSectionId"
          value={selectedBusinessSection}
          options={selectableBusinessSections}
          onChange={onChangeBusinessSection}
          className="mt-9"
        />
        <InfiniteScrollWithMoreButton
          itemsLength={questionAnswerCategories.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          className="mt-6"
          scrollClassName="space-y-9"
        >
          {questionAnswerCategories.map((questionAnswerCategory) => (
            <QuestionAnswerCategoryList
              key={questionAnswerCategory.id}
              questionAnswerCategory={questionAnswerCategory}
            />
          ))}
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={setClose}
          label="検索条件"
          isOpen={isOpen}
        >
          <QuestionAnswerCategorySearchForm
            searchKeyword={{
              value: questionContentKeyword,
              onChange: onChangeQuestionContentKeyword,
            }}
            questionAnswerCategory={{
              option: selectableQuestionAnswerCategories,
              value: selectedQuestionAnswerCategories,
              onChange: onChangeQuestionAnswerCategories,
            }}
            service={{
              option: selectableProvidingServices,
              value: selectedProvidingServices,
              onChange: onChangeProvidingServices,
            }}
            tag={{
              option: selectableTags,
              value: selectedTags,
              onChange: onChangeTags,
            }}
            onSubmit={onSearchSubmit}
            onConditionReset={onConditionReset}
          />
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
