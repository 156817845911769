import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersSkillEvaluationStandardSummaryCategoryMutateRequestType,
  ApiManagersSkillEvaluationStandardSummaryCategoryMutateType,
} from "~/domains";

type RequestDataType = {
  id: string;
  body: ApiManagersSkillEvaluationStandardSummaryCategoryMutateRequestType;
};

const request = ({ id, body }: RequestDataType) => {
  return new ApiClient()
    .put<ApiManagersSkillEvaluationStandardSummaryCategoryMutateType>(
      `/api/managers/skill_evaluation_standard_summary_categories/${id}`,
      {
        skillEvaluationStandardSummaryCategory: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersSkillEvaluationStandardSummaryCategoriesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersSkillEvaluationStandardSummaryCategoryMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
