import React, { FC, FormEvent, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useEditorState,
  useManagersBusinessSectionReviewsNavigation,
  useApisManagersBusinessSectionsBusinessSectionMemoReviewsCreate,
  useSearchManagersBusinessSectionsBusinessSectionMemoReviews,
  useRightSidebarState,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { RightSidebarToggleIconWithLabel } from "~/components/molecules";
import {
  BusinessSectionMemoReviewDetailWithEditForms,
  EmployeeAndBusinessSectionMemoReviewForm,
  EmployeeAndBusinessSectionMemoReviewSearchForm,
} from "~/components/organisms";

import { PreviewWithFileType } from "~/domains";

export const ManagersBusinessSectionsBusinessSectionMemoReviewsIndex: FC =
  () => {
    const { businessSectionId = "" } = useParams<{
      businessSectionId: string;
    }>();
    const { searchOpenToggle, setClose, isOpen } =
      useRightSidebarState("isClose");

    const [files, setFiles] = useState<PreviewWithFileType[]>([]);
    const { mutate: postRequest, isLoading: isSubmitting } =
      useApisManagersBusinessSectionsBusinessSectionMemoReviewsCreate();

    const {
      businessSectionMemoReviews,
      isLoading,
      hasNextPage,
      businessSection,
      keyword,
      startDate,
      endDate,
      selectableDropdownCompleteStatusIds,
      selectedDropdownCompleteStatusId,
      fetchNextPage,
      unshiftItem,
      deleteIndexItem,
      updateIndexItem,
      onChangeDateRange,
      onChangeKeyword,
      onChangeDropdownCompleteStatusId,
      onSearchSubmit,
      onConditionReset,
    } =
      useSearchManagersBusinessSectionsBusinessSectionMemoReviews(
        businessSectionId,
      );

    const breadCrumbItems = [
      {
        label: "メンバーメモ",
        href: `/managers/business_section_reviews?business_section_id=${businessSectionId}`,
      },
      {
        label: `${businessSection?.name || ""}のメモ`,
        href: `/managers/business_sections/${businessSectionId}/business_section_memo_reviews`,
      },
    ];

    const { navigations } = useManagersBusinessSectionReviewsNavigation({
      businessSectionId,
      selectedArray: [true, false],
    });

    const [
      {
        jsonContent: editorStateJsonContent,
        value: editorState,
        setPlainText,
        onChange: onChangeEditorState,
      },
    ] = useEditorState();

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      postRequest(
        {
          businessSectionId,
          body: {
            content: editorStateJsonContent,
            attachFiles: files,
          },
        },
        {
          onSuccess: (data) => {
            toast(data.message);
            setPlainText("");
            setFiles([]);
            unshiftItem(data.businessSectionMemoReview);
          },
        },
      );
    };

    return (
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
          <EmployeeAndBusinessSectionMemoReviewForm
            editorState={editorState}
            onChangeEditorState={onChangeEditorState}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            files={files}
            onChangeFiles={setFiles}
            className="mt-9"
          />
          {businessSection && (
            <BusinessSectionMemoReviewDetailWithEditForms
              businessSectionMemoReviews={businessSectionMemoReviews}
              isLoading={isLoading}
              hasNextPage={hasNextPage}
              businessSection={businessSection}
              fetchNextPage={fetchNextPage}
              deleteIndexPost={deleteIndexItem}
              updateIndexPost={updateIndexItem}
              className="mt-9"
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          <RightSidebarToggleIconWithLabel
            iconOnClick={searchOpenToggle}
            handleOnClose={setClose}
            label={"検索条件"}
            isOpen={isOpen}
          >
            {isOpen && (
              <EmployeeAndBusinessSectionMemoReviewSearchForm
                date={{
                  startDate: startDate,
                  endDate: endDate,
                  onChange: onChangeDateRange,
                }}
                searchKeyword={{
                  value: keyword,
                  onChange: onChangeKeyword,
                }}
                completed={{
                  options: selectableDropdownCompleteStatusIds,
                  value: selectedDropdownCompleteStatusId,
                  onChange: onChangeDropdownCompleteStatusId,
                }}
                onSubmit={onSearchSubmit}
                onConditionReset={onConditionReset}
              />
            )}
          </RightSidebarToggleIconWithLabel>
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    );
  };
