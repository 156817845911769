import React, { ChangeEvent, FC } from "react";

import { useInsightPostObjectiveForms } from "~/hooks";

import { Button, ButtonWithIcon } from "~/components/atoms";
import {
  LabelWithTextField,
  RangeDatePicker,
  LabelWithNumberField,
} from "~/components/molecules";
import { ParentWithChildDropdownField } from "~/components/organisms";

import { InsightPostObjectiveType } from "~/domains";

type PropsType = {
  insightPostObjective?: InsightPostObjectiveType;
};

export const InsightPostObjectiveForm: FC<PropsType> = ({
  insightPostObjective,
}: PropsType) => {
  const {
    title,
    onChangeTitle,
    objectivePerformance,
    setObjectivePerformance,
    startDate,
    endDate,
    onChangeDateRange,
    handlePublishSubmit,
    handleDraftSubmit,
    isSubmitting,
    selectableDivisions,
    selectedDivision,
    onDivisionChange,
    onSectionChange,
    optionSelectableSections,
    optionSelectedSection,
    employeeAndInsightPostObjectives,
    onChangeEmployeeCount,
    onChangeUnsetEmployee,
    onChangeSetEmployee,
    handleEvenUp,
    employeeSumPoints,
  } = useInsightPostObjectiveForms({
    insightPostObjective,
  });

  return (
    <>
      <div className="space-y-6">
        <ParentWithChildDropdownField
          allParents={selectableDivisions}
          parentValue={selectedDivision}
          parentsOnChange={onDivisionChange}
          parentRequired={true}
          parentLabel="配布先: 部署"
          allChildren={optionSelectableSections}
          childValue={optionSelectedSection}
          childrenOnChange={onSectionChange}
          childRequired={true}
          childLabel="配布先: 課"
        />
        <LabelWithTextField
          labelText="タイトル"
          type="text"
          name="title"
          placeholder="タイトル"
          required
          value={title}
          onChange={onChangeTitle}
        />
        <RangeDatePicker
          labelText="期間指定"
          required={true}
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeDateRange}
        />
        <LabelWithNumberField
          labelText="目標数字"
          name="objectivePerformance"
          placeholder="目標数字"
          required
          max={999999999999}
          min={0}
          value={objectivePerformance}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setObjectivePerformance(
              isNaN(parseInt(e.target.value)) ? "" : parseInt(e.target.value),
            )
          }
        />
        {Boolean(employeeAndInsightPostObjectives.length) && (
          <div className="flex items-center space-x-4">
            <Button
              text="数字を均等に割り振る"
              onClick={handleEvenUp}
              color="gray"
              outline
            />
            <p
              className={`font-semibold ${
                (objectivePerformance || 0) > employeeSumPoints &&
                "text-icon-heart"
              } `}
            >
              {`${employeeSumPoints.toLocaleString()} / ${objectivePerformance.toLocaleString()}`}
            </p>
          </div>
        )}
        {Boolean(employeeAndInsightPostObjectives.length) && (
          <div className="grid grid-cols-2 gap-4">
            {employeeAndInsightPostObjectives.map(
              (employeeAndInsightPostObjective) => (
                <div
                  key={employeeAndInsightPostObjective.employee.id}
                  className="flex items-center"
                >
                  <LabelWithNumberField
                    className="w-full"
                    labelClassName="truncate"
                    disabled={employeeAndInsightPostObjective.isReadOnly}
                    labelText={employeeAndInsightPostObjective.employee.name}
                    name="objectivePerformance"
                    placeholder="目標数字"
                    max={999999999999}
                    min={0}
                    value={employeeAndInsightPostObjective.objectivePerformance}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onChangeEmployeeCount({
                        employeeAndInsightPostObjective,
                        newValue: isNaN(parseInt(e.target.value))
                          ? ""
                          : parseInt(e.target.value),
                      })
                    }
                  />
                  {employeeAndInsightPostObjective.isReadOnly ? (
                    <ButtonWithIcon
                      onClick={() =>
                        onChangeSetEmployee(employeeAndInsightPostObjective)
                      }
                      icon={{
                        icon: "ioAdd",
                        size: "1.5rem",
                      }}
                      srOnlyText="追加"
                    />
                  ) : (
                    <ButtonWithIcon
                      onClick={() =>
                        onChangeUnsetEmployee(employeeAndInsightPostObjective)
                      }
                      icon={{
                        icon: "ioCloseOutline",
                        size: "1.5rem",
                      }}
                      srOnlyText="削除"
                    />
                  )}
                </div>
              ),
            )}
          </div>
        )}
        <div className="flex items-center space-x-4">
          <Button
            text="下書き保存"
            color="gray"
            outline
            className="w-full"
            onClick={handleDraftSubmit}
            readonly={isSubmitting}
          />
          <Button
            text="配布をする"
            color="primary"
            className="w-full"
            readonly={isSubmitting}
            onClick={handlePublishSubmit}
          />
        </div>
      </div>
    </>
  );
};
