import React, { FC, ReactNode } from "react";

import { SlideNavigation, SlideNavigationType } from "~/components/molecules";

type Props = {
  withRightSidebar: boolean;
  navigations?: SlideNavigationType[];
  children?: ReactNode;
  className?: string;
};

export const EmployeeLayoutMainContent: FC<Props> = ({
  withRightSidebar,
  navigations,
  children,
  className = "",
}: Props) => {
  return (
    <div
      className={`flex-shrink-0 hidden-scrollbar px-6 pb-6 ${
        withRightSidebar ? "w-full miniTablet:w-[62.5%]" : "w-full"
      } ${navigations ? "" : "pt-6"} ${className}`}
    >
      {navigations && <SlideNavigation navigations={navigations} />}
      {children}
    </div>
  );
};
