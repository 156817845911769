import React, { ChangeEvent, FC, FormEvent, MouseEvent, useState } from "react";

import {
  Form,
  Button,
  FormSubmitButton,
  GridHeaderCells,
  ModalWrapper,
} from "~/components/atoms";
import {
  LabelWithTextField,
  GridRow,
  InfiniteScrollWithMoreButton,
  OpenedMenuInGrid,
  RangeDatePicker,
  LabelWithMultipleDropdownField,
} from "~/components/molecules";
import { PrivateQuestionAnswerForm } from "~/components/organisms";

import {
  MultiValueType,
  OptionType,
  PrivateQuestionAnswerType,
  RangeDatePropsType,
} from "~/domains";

type PropsType = {
  className?: string;
  privateQuestionAnswers: PrivateQuestionAnswerType[];
  keyword: string;
  hasNextPage?: boolean;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  selectableDropdownProvidingServices: OptionType[];
  selectedDropdownProvidingServices: MultiValueType<OptionType>;
  selectableDropdownEmployees: OptionType[];
  selectedDropdownEmployees: MultiValueType<OptionType>;
  onChangeDropdownProvidingServices: (
    value: MultiValueType<OptionType>,
  ) => void;
  onChangeDropdownEmployees: (value: MultiValueType<OptionType>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const ManagersPrivateQuestionAnswersIndexTemplate: FC<PropsType> = ({
  className = "",
  privateQuestionAnswers,
  keyword,
  isLoading,
  hasNextPage,
  startDate,
  endDate,
  selectableDropdownProvidingServices,
  selectedDropdownProvidingServices,
  selectableDropdownEmployees,
  selectedDropdownEmployees,
  onChangeDropdownProvidingServices,
  onChangeDropdownEmployees,
  fetchNextPage,
  onChangeDateRange,
  onChangeKeyword,
  onConditionReset,
  onSearchSubmit,
}: PropsType) => {
  const [openModalPrivateQuestion, setOpenModalPrivateQuestion] =
    useState<PrivateQuestionAnswerType | null>(null);

  return (
    <div className={className}>
      <Form onSubmit={onSearchSubmit} className="space-y-6 mt-8">
        <div className="grid miniTablet:grid-cols-2 gap-4">
          <LabelWithMultipleDropdownField
            name="providingService"
            options={selectableDropdownProvidingServices}
            value={selectedDropdownProvidingServices}
            onChange={onChangeDropdownProvidingServices}
            labelText="サービス"
          />
          <LabelWithMultipleDropdownField
            name="employees"
            options={selectableDropdownEmployees}
            value={selectedDropdownEmployees}
            onChange={onChangeDropdownEmployees}
            labelText="ユーザー"
          />
          <RangeDatePicker
            labelText="期間指定"
            startDate={startDate}
            endDate={endDate}
            onChange={onChangeDateRange}
          />
          <LabelWithTextField
            labelText="フリーワード"
            type="search"
            name="keyword"
            placeholder="質問の検索"
            value={keyword}
            onChange={onChangeKeyword}
          />
        </div>
        <div className="flex items-center justify-end space-x-4">
          <Button
            color="gray"
            outline
            className="w-full miniTablet:w-auto"
            text="リセット"
            onClick={onConditionReset}
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full miniTablet:w-auto"
          />
        </div>
      </Form>
      <InfiniteScrollWithMoreButton
        itemsLength={privateQuestionAnswers.length}
        nextFetchFunction={fetchNextPage}
        isLoading={isLoading}
        hasMore={hasNextPage}
        className="mt-12"
      >
        <div className="grid grid-cols-5">
          <GridHeaderCells
            texts={["作成日", "サービス", "質問", "回答", "ユーザー名"]}
          />
          {privateQuestionAnswers.map((questionAnswer) => (
            <GridRow
              key={questionAnswer.id}
              lists={[
                questionAnswer.createdAt,
                questionAnswer.providingService?.name || "すべて",
                questionAnswer.questionContent,
                questionAnswer.answerContent,
                questionAnswer.employee.name,
              ]}
              openedMenu={
                <OpenedMenuInGrid
                  cols={5}
                  buttons={[
                    {
                      text: "確認",
                      onClick: () =>
                        setOpenModalPrivateQuestion(questionAnswer),
                    },
                  ]}
                />
              }
            />
          ))}
        </div>
      </InfiniteScrollWithMoreButton>
      {openModalPrivateQuestion && (
        <ModalWrapper
          onClose={() => setOpenModalPrivateQuestion(null)}
          headerTitle="Q&Aの確認"
        >
          <PrivateQuestionAnswerForm
            privateQuestionAnswer={openModalPrivateQuestion}
            selectableProvidingService={[]}
            disabled
            className="mt-4"
          />
        </ModalWrapper>
      )}
    </div>
  );
};
