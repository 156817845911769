import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toDateString } from "~/libs";

import {
  useApisMembersKpiBusinessSectionsKpiDailyPerformancesShow,
  useDropdown,
  useKpiSlideNavigation,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  DropDownField,
  NoContentMessage,
  RightSidebarTitle,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { DatePicker } from "~/components/molecules";
import {
  Fetch,
  KpiTermHeader,
  KpiDailyPerformanceForm,
  KpiDailyPerformanceCalendar,
} from "~/components/organisms";

export const MembersKpiDailyPerformancesShow: FC = () => {
  const navigate = useNavigate();
  const { currentEmployee } = useProvidersCurrentEmployee();
  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";

  const { date = "" } = useParams<{ date: string }>();

  const [
    {
      dropdownValue: selectedBusinessSection,
      dropdownSelectableValue: selectableBusinessSections,
      onChange: onChangeBusinessSection,
    },
  ] = useDropdown({
    selectableValue: currentEmployee?.businessSections || [],
    initialValue: currentEmployee?.businessSections.find(
      (businessSection) => businessSection.id === businessSectionId,
    ),
  });

  const {
    data: fetchData,
    isFetching,
    error,
  } = useApisMembersKpiBusinessSectionsKpiDailyPerformancesShow({
    businessSectionId: selectedBusinessSection?.value || "",
    date,
    config: {
      enabled: Boolean(selectedBusinessSection) && Boolean(date),
    },
  });
  const { navigations } = useKpiSlideNavigation({
    selectedArray: [true, false, false],
    businessSectionId: selectedBusinessSection?.value || "",
    date: date ? new Date(date) : new Date(),
  });

  const breadCrumbItems = [
    {
      label: "KPI・KGI",
      href: `/kpi_daily_performances/${date}?business_section_id=${selectedBusinessSection?.value}`,
    },
    {
      label: "入力",
      href: `/kpi_daily_performances/${date}?business_section_id=${selectedBusinessSection?.value}`,
    },
  ];

  const handleOnChangeDate = (date: Date) => {
    const formattedDate = toDateString(date);
    navigate(
      `/kpi_daily_performances/${formattedDate}?business_section_id=${selectedBusinessSection?.value}`,
    );
  };

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <DropDownField
          placeholder="課を選択"
          name="businessSectionId"
          value={selectedBusinessSection}
          options={selectableBusinessSections}
          onChange={onChangeBusinessSection}
          parentClassName="w-full mt-6"
        />
        <DatePicker
          selectDate={new Date(date)}
          onChange={handleOnChangeDate}
          parentClassName="w-full mt-6"
        />
        <Fetch
          loading={isFetching}
          error={error instanceof Error ? error.message : ""}
          data={fetchData}
        >
          {fetchData?.kpiTerm && (
            <KpiTermHeader kpiTerm={fetchData.kpiTerm} className="mt-6" />
          )}
          {fetchData?.kpiObjectives.length ? (
            <div className="space-y-14 mt-14">
              {fetchData.kpiObjectives.map((kpiObjective) => (
                <div key={kpiObjective.id}>
                  <KpiDailyPerformanceForm
                    kpiObjective={kpiObjective}
                    formattedDate={date}
                  />
                </div>
              ))}
            </div>
          ) : (
            <NoContentMessage
              text="目標が設定されていません"
              className="mt-4"
            />
          )}
        </Fetch>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        {selectedBusinessSection && (
          <>
            <RightSidebarTitle title="Information" />
            <KpiDailyPerformanceCalendar
              className="mx-4 mt-6"
              businessSectionId={selectedBusinessSection.value}
            />
          </>
        )}
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
