import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiAnalyticsPlansSkillEvaluationTermsIndexResponseType,
  ApiAnalyticsPlansSkillEvaluationTermsIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiAnalyticsPlansSkillEvaluationTermsIndexRequestType;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApiAnalyticsPlansSkillEvaluationTermsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiAnalyticsPlansSkillEvaluationTermsIndexResponseType>(
      "/api/analytics_plans/skill_evaluation_terms",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiAnalyticsPlansSkillEvaluationTermsIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisAnalyticsPlansSkillEvaluationTermsIndex = ({
  params,
  config = {},
}: PropsType = {}): UseQueryResult<ApiAnalyticsPlansSkillEvaluationTermsIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisAnalyticsPlansSkillEvaluationTermsIndex", params],
    queryFn: () => request({ params }),
  });
};
