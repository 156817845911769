import React, { FC } from "react";

import { startOfMonth, endOfMonth } from "date-fns";

import {
  useApisAnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndex,
  useDateRange,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import { AnalyticsPlansCountDisplayBoxes } from "~/components/organisms";

const today = new Date();
export const AnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndex: FC =
  () => {
    const { currentEmployee } = useProvidersCurrentEmployee();

    const breadCrumbItems = [
      {
        label: "スキルマップ分析",
        href: "/analytics_plans/skill_evaluation_standard_summary_categories",
      },
    ];

    const {
      selectableDivisions,
      selectedDivisions,
      onDivisionChange,
      optionSelectableSections,
      optionSelectedSections,
      onSectionChange,
    } = useMultiDivisionMultiSectionDropdown({
      selectableDivisions: currentEmployee?.businessDivisions,
      selectableSections: currentEmployee?.businessSections,
    });

    const [
      {
        startDate,
        formattedStartDate,
        endDate,
        formattedEndDate,
        onChange: onChangeDateRange,
      },
    ] = useDateRange([startOfMonth(today), endOfMonth(today)]);

    const { data, isLoading } =
      useApisAnalyticsPlansSkillEvaluationStandardSummaryCategoriesIndex({
        params: {
          businessDivisionIds: selectedDivisions.map((division) => division.id),
          businessSectionIds: optionSelectedSections.map(
            (section) => section.id,
          ),
          fromDate: formattedStartDate || undefined,
          toDate: formattedEndDate || undefined,
        },
        config: {
          enabled: !!(formattedStartDate && formattedEndDate),
        },
      });

    return (
      <>
        <EmployeeLayout
          withRightSidebar={false}
          breadCrumbItems={breadCrumbItems}
        >
          <EmployeeLayoutMainContent withRightSidebar={false}>
            <AnalyticsPlansCountDisplayBoxes
              selectableDivisions={selectableDivisions}
              selectedDivisions={selectedDivisions}
              onDivisionChange={onDivisionChange}
              optionSelectableSections={optionSelectableSections}
              optionSelectedSections={optionSelectedSections}
              onSectionChange={onSectionChange}
              startDate={startDate}
              endDate={endDate}
              onChangeDateRange={onChangeDateRange}
              data={
                data
                  ? {
                      managersPostsCount: data.managersPostsCount,
                      ownersPostsCount: data.ownersPostsCount,
                      others: [
                        {
                          count: data.postsPageViewsCount,
                          title: "ページビュー数",
                        },
                      ],
                    }
                  : undefined
              }
              rankings={[
                {
                  title: "ユーザー別ページビュー数",
                  data: data?.postsPageViewsEmployeesRankings,
                },
                {
                  title: "スキルマップ別ページビュー数",
                  data: data?.postsPageViewsSkillEvaluationStandardSummaryCategoriesRankings,
                },
              ]}
              isLoading={isLoading}
            />
          </EmployeeLayoutMainContent>
        </EmployeeLayout>
      </>
    );
  };
