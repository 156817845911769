import React, { FC } from "react";

import { Icon, IconColorType, ICONS } from "~/components/atoms";
import type { IconSizeType } from "~/components/atoms";

type PropsType = {
  contentType: string;
  className?: string;
  iconSize?: IconSizeType;
};

export const FileContentTypeIcon: FC<PropsType> = ({
  contentType,
  iconSize,
  className = "",
}: PropsType) => {
  const getIconStyle = (): {
    icon: (typeof ICONS)[number];
    color: IconColorType;
  } => {
    switch (contentType) {
      case "application/pdf":
        return { icon: "filePdf", color: "text-icon-pdf" };
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return { icon: "fileWord", color: "text-icon-word" };
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "text/csv":
        return { icon: "fileExcel", color: "text-icon-excel" };
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return { icon: "filePowerpoint", color: "text-icon-powerPoint" };
      case "text/plain":
        return { icon: "fileText", color: "text-icon-image" };
      case "video/mpeg":
      case "video/x-msvideo":
      case "video/mp4":
      case "video/quicktime":
      case "video/x-flv":
      case "video/x-ms-wmv":
        return { icon: "fileVideo", color: "text-icon-video" };
      case "audio/mpeg":
      case "audio/aac":
      case "audio/x-ms-wma":
        return { icon: "fileAudio", color: "text-icon-audio" };
      case "image/jpeg":
      case "image/png":
      case "image/gif":
        return { icon: "fileImage", color: "text-icon-image" };
      default:
        return { icon: "fileText", color: "text-icon-image" };
    }
  };

  const iconStyle = getIconStyle();

  return (
    <Icon
      icon={iconStyle.icon}
      color={iconStyle.color}
      size={iconSize}
      className={className}
    />
  );
};
