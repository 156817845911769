import React, { ChangeEvent, FC, FormEvent, useId } from "react";

import {
  Form,
  FormSubmitButton,
  DropDownField,
  RoundedRadioButtonField,
} from "~/components/atoms";
import { RangeDatePicker, RangeDatePropsType } from "~/components/molecules";
import { TextEditor } from "~/components/organisms";

import {
  EditorStateType,
  AvatarAndNameEmployeeType,
  PreviewWithFileType,
  OptionType,
  SingleValueType,
  JobCategoryPhaseType,
} from "~/domains";

type PropsType = {
  isSubmitting: boolean;
  mentions: AvatarAndNameEmployeeType[];
  files: PreviewWithFileType[];
  onChangeFiles: (files: PreviewWithFileType[]) => void;
  editorState: EditorStateType;
  readOnly?: boolean;
  setEditorState: (editorState: EditorStateType) => void;
  selectableProvidingServices: OptionType[];
  selectedProvidingService?: SingleValueType<OptionType>;
  setProvidingService: (newValue: SingleValueType<OptionType>) => void;
  selectableJobCategoryPhases: JobCategoryPhaseType[];
  selectedJobCategoryPhase?: {
    label: string;
    value: string;
  };
  onChangeJobCategoryPhase: (e: ChangeEvent<HTMLInputElement>) => void;
  date: {
    startDate: Date | null;
    endDate: Date | null;
    onChange: (value: RangeDatePropsType) => void;
  };
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  submitButtonLabel?: string;
  className?: string;
};

export const FocusChallengeEmployeeForm: FC<PropsType> = ({
  isSubmitting,
  mentions,
  files,
  onChangeFiles,
  editorState,
  readOnly = false,
  setEditorState,
  selectableProvidingServices,
  selectedProvidingService,
  setProvidingService,
  selectableJobCategoryPhases,
  selectedJobCategoryPhase,
  onChangeJobCategoryPhase,
  date,
  handleSubmit,
  submitButtonLabel = "投稿する",
  className = "",
}: PropsType) => {
  const withAllSelectableJobCategoryPhases = [
    { id: "", name: "すべて", jobCategoryId: "", displayOrder: 0 },
    ...selectableJobCategoryPhases,
  ];

  const uniqueId = useId();

  return (
    <Form className={`space-y-6 ${className}`} onSubmit={handleSubmit}>
      <TextEditor
        readOnly={readOnly}
        mentions={mentions}
        editorState={editorState}
        setEditorState={setEditorState}
        files={files}
        onChangeFiles={onChangeFiles}
        required
      />
      <DropDownField
        name="providingServiceId"
        options={selectableProvidingServices}
        placeholder="全サービス共通(サービス選択)"
        value={selectedProvidingService}
        onChange={setProvidingService}
      />
      {/* HACK: RoundedRadioButtonFieldのクラスのmt-3にあわせて強制的にmt-3をつけているが、同じスタイルのままここのmt-3は削除したい*/}
      <div className="flex flex-wrap !mt-3">
        {withAllSelectableJobCategoryPhases.map((jobCategoryPhase) => (
          <RoundedRadioButtonField
            key={jobCategoryPhase.id}
            name={`jobCategoryPhaseId-${uniqueId}-${jobCategoryPhase.id}`}
            value={jobCategoryPhase.id}
            labelText={jobCategoryPhase.name}
            checked={selectedJobCategoryPhase?.value === jobCategoryPhase.id}
            onChange={onChangeJobCategoryPhase}
            className="mr-3 mt-3"
          />
        ))}
      </div>
      <RangeDatePicker
        labelText="チャレンジ期間指定"
        required={true}
        startDate={date.startDate}
        endDate={date.endDate}
        onChange={date.onChange}
      />
      <FormSubmitButton
        color="primary"
        value={submitButtonLabel}
        isReadOnly={isSubmitting}
        className="w-full"
      />
    </Form>
  );
};
