import React, { FC } from "react";

type PropsType = {
  text: string;
  className?: string;
};

export const NoContentMessage: FC<PropsType> = ({
  text,
  className,
}: PropsType) => {
  return (
    <p className={`text-center font-medium text-secondary-400 ${className}`}>
      {text}
    </p>
  );
};
