import React, { FC, FormEvent, MouseEvent } from "react";

import { toast } from "react-toastify";

import {
  useApisMembersAiChatRoomsDestroy,
  useApisMembersAiChatRoomsUpdate,
  useBoolean,
  useInput,
} from "~/hooks";

import { FloatingChatRoomTitle } from "./FloatingChatRoomTitle";
import { FloatingChatRoomTitleForm } from "./FloatingChatRoomTitleForm";

import { AiChatRoomType } from "~/domains";

type PropsType = {
  aiChatRoom: AiChatRoomType;
  setAiChatRoomId: (chatRoomId: string) => void;
  deleteAiChatRoom: () => void;
  updateAiChatRoom: (newItem: AiChatRoomType) => void;
};

export const FloatingChatRoomDetail: FC<PropsType> = ({
  aiChatRoom,
  setAiChatRoomId,
  deleteAiChatRoom,
  updateAiChatRoom,
}: PropsType) => {
  const {
    isChecked: isEditMode,
    setTrue: setEditMode,
    setFalse: setDisplayMode,
  } = useBoolean(false);

  const [{ value, onChange }] = useInput(aiChatRoom.title);

  const { mutate: deleteRequest } = useApisMembersAiChatRoomsDestroy();
  const { mutate: updateRequest } = useApisMembersAiChatRoomsUpdate();

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: aiChatRoom.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          deleteAiChatRoom();
          setDisplayMode();
        },
      },
    );
  };

  const handleUpdate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateRequest(
      {
        id: aiChatRoom.id,
        body: {
          title: value,
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          updateAiChatRoom(data.aiChatRoom);
          setDisplayMode();
        },
      },
    );
  };

  return (
    <div className={"flex items-center justify-between"}>
      {isEditMode ? (
        <FloatingChatRoomTitleForm
          value={value}
          onChange={onChange}
          onSubmit={handleUpdate}
          onFormClose={setDisplayMode}
        />
      ) : (
        <FloatingChatRoomTitle
          aiChatRoom={aiChatRoom}
          setAiChatRoomId={setAiChatRoomId}
          setEditMode={setEditMode}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};
