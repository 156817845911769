import {
  ConnectDropTarget,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from "react-dnd";

export const useReactDrag = useDrag;

// NOTE: ReactDndのuseDropのpropsの型定義
type HandlerPropsType = {
  handlerId: string | symbol | null;
  isOver: boolean;
};
type DragItem = {
  index: number;
  id: string;
  type: string;
};

type ReactDropPropsType = {
  accept: string;
  collect?: (monitor: DropTargetMonitor<DragItem, void>) => HandlerPropsType;
  drop?: (item: DragItem, monitor: DropTargetMonitor<DragItem, void>) => void;
};

export const useReactDrop = ({
  accept,
  collect,
  drop,
}: ReactDropPropsType): [HandlerPropsType, ConnectDropTarget] => {
  return useDrop<DragItem, void, HandlerPropsType>({
    accept: accept,
    collect: collect,
    drop: drop,
  });
};
