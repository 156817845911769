import React, { FC, FormEvent, ChangeEvent, MouseEvent } from "react";

import {
  Form,
  FormSubmitButton,
  TextField,
  MultipleDropDownField,
  Button,
  DropDownField,
} from "~/components/atoms";
import { RangeDatePropsType, TitleWithChild } from "~/components/molecules";
import { SelectDateRangeButtons } from "~/components/organisms";

import { OptionType, MultiValueType, SingleValueType } from "~/domains";

type PropsType = {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  date: {
    startDate: Date | null;
    endDate: Date | null;
    onChange: (value: RangeDatePropsType) => void;
  };
  employee: {
    options: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
  hasComment: {
    options: OptionType[];
    value: SingleValueType<OptionType> | undefined;
    onChange: (newValue: SingleValueType<OptionType>) => void;
  };
  hasBookmark?: {
    options: OptionType[];
    value: SingleValueType<OptionType> | undefined;
    onChange: (newValue: SingleValueType<OptionType>) => void;
  };
  searchKeyword: {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
};

export const KnowledgeInsightPostSearchForm: FC<PropsType> = ({
  onSubmit,
  onConditionReset,
  date,
  employee,
  hasComment,
  hasBookmark,
  searchKeyword,
}: PropsType) => {
  return (
    <Form onSubmit={onSubmit} className="mt-6 space-y-6">
      <>
        <TitleWithChild title="期間指定">
          <SelectDateRangeButtons
            startDate={date.startDate}
            endDate={date.endDate}
            onChange={date.onChange}
            className="my-2"
          />
        </TitleWithChild>
        <TitleWithChild title="ユーザー">
          <MultipleDropDownField
            name="providingService"
            options={employee.options}
            value={employee.value}
            onChange={employee.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="リプライ">
          <DropDownField
            name="hasComment"
            options={hasComment.options}
            value={hasComment.value}
            onChange={hasComment.onChange}
          />
        </TitleWithChild>
        {hasBookmark && (
          <TitleWithChild title="ブックマーク">
            <DropDownField
              name="hasBookmark"
              options={hasBookmark.options}
              value={hasBookmark.value}
              onChange={hasBookmark.onChange}
            />
          </TitleWithChild>
        )}
        <TitleWithChild title="フリーワード">
          <>
            <TextField
              placeholder="キーワード"
              type="search"
              name="keyword"
              value={searchKeyword.value}
              onChange={searchKeyword.onChange}
            />
          </>
        </TitleWithChild>
        <div className="space-x-4 flex">
          <Button
            color="gray"
            outline
            text="リセット"
            onClick={onConditionReset}
            className="w-full"
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full"
          />
        </div>
      </>
    </Form>
  );
};
