import React, { FC, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useDropdown,
  useProvidersCurrentEmployee,
  useApisManagersBusinessSectionsNew,
  useApisManagersBusinessSectionsCreate,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { BusinessSectionForm } from "~/components/organisms";

import { ResponseMessageType } from "~/domains";

export const ManagersBusinessSectionsNew: FC = () => {
  const navigate = useNavigate();
  const { refetch } = useProvidersCurrentEmployee();

  const { mutate: postBusinessSection, isLoading: isSubmitting } =
    useApisManagersBusinessSectionsCreate();
  const [
    { value: businessSectionName, onChange: onChangeBusinessSectionName },
  ] = useInput("");
  const [
    {
      dropdownValue: selectedBusinessDivision,
      onChange: onChangeBusinessDivision,
      handleSetSelectableOption: handleSetSelectableOption,
      dropdownSelectableValue: selectableBusinessDivisions,
    },
  ] = useDropdown();

  useApisManagersBusinessSectionsNew({
    config: {
      onSuccess: (data) => {
        handleSetSelectableOption(data.selectableBusinessDivisions);
      },
    },
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      name: businessSectionName,
      businessDivisionId: selectedBusinessDivision?.value || "",
    };
    postBusinessSection(
      {
        body,
      },
      {
        onSuccess: (data: ResponseMessageType) => {
          navigate("/managers/business_sections");
          toast(data?.message);
          refetch();
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "課設定", href: "/managers/business_sections" },
    { label: "新規作成", href: "/managers/business_sections/new" },
  ];

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          <BusinessSectionForm
            businessSectionName={businessSectionName}
            onChangeBusinessSectionName={onChangeBusinessSectionName}
            selectedBusinessDivision={selectedBusinessDivision}
            onChangeBusinessDivision={onChangeBusinessDivision}
            selectableBusinessDivisions={selectableBusinessDivisions}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
