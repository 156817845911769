import React, { FC } from "react";

import {
  useApisManagersRoleplayingConditionEmployeesIndex,
  useDropdown,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { LabelWithDropDownField } from "~/components/molecules";
import {
  RoleplayingConditionSelectEmployeeDailyCountCalendar,
  RoleplayingConditionSelectEmployeeChartContainer,
} from "~/components/organisms";

export const ManagersRoleplayingConditionEmployeesDashboardShow: FC = () => {
  const breadCrumbItems = [
    {
      label: "ロールプレイング",
      href: "/managers/roleplaying_condition_templates",
    },
    {
      label: "状況確認",
      href: "/managers/roleplaying_condition/employees/dashboard",
    },
  ];
  const [
    {
      dropdownValue: selectedEmployee,
      onChange: onChangeEmployee,
      handleSetSelectableOption: handleSetSelectableOption,
      dropdownSelectableValue: selectableEmployees,
    },
  ] = useDropdown();

  useApisManagersRoleplayingConditionEmployeesIndex({
    config: {
      onSuccess(data) {
        handleSetSelectableOption(data.employees);
      },
    },
  });

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          <LabelWithDropDownField
            labelText="ユーザー"
            required
            name="employees"
            options={selectableEmployees}
            value={selectedEmployee}
            onChange={onChangeEmployee}
          />
          {selectedEmployee && (
            <div className="space-y-9 mt-6">
              <RoleplayingConditionSelectEmployeeChartContainer
                title="日毎のロールプレイング実施状況"
                chartType="Bar"
                employeeId={selectedEmployee.value}
              />
              <RoleplayingConditionSelectEmployeeChartContainer
                title="累計のロールプレイング実施状況"
                chartType="Area"
                employeeId={selectedEmployee.value}
              />
            </div>
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          <RightSidebarTitle title="Information" />
          {selectedEmployee && (
            <RoleplayingConditionSelectEmployeeDailyCountCalendar
              employeeId={selectedEmployee.value}
              className="mt-6 mx-4"
            />
          )}
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
