import React, { FC } from "react";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { NotFoundContent } from "~/components/organisms";

export const NotFoundIndex: FC = () => {
  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={[]}>
      <EmployeeLayoutMainContent withRightSidebar>
        <NotFoundContent className="" />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
