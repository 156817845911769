export const FILE_MAX_BYTE_SIZE = 1000 * 1000 * 10; // 10MB
export const DOCUMENT_FILE_MAX_BYTE_SIZE = 1000 * 1000 * 50; // 50MB
export const ACCEPT_IMAGE_FILE_TYPES = {
  "image/jpeg": [".jpeg"],
  "image/png": [".png"],
  "image/jpg": [".jpg"],
};
export const IMAGE_VALIDATE_MESSAGE =
  "拡張子はpng, jpg, jpegである必要がります。画像は5MBまでアップロード可能です。";

export const ACCEPT_APPLICATION_FILE_TYPES = {
  "application/pdf": [".pdf"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
};

export const ACCEPT_TEXT_FILE_TYPES = {
  "text/csv": [".csv"],
  "text/plain": [".txt"],
};

export const ACCEPT_VIDEO_FILE_TYPES = {
  "video/mp4": [".mp4"],
  "video/quicktime": [".mov"],
  "video/x-msvideo": [".avi"],
  "video/x-ms-wmv": [".wmv"],
  "video/x-flv": [".flv"],
};

export const ACCEPT_AUDIO_FILE_TYPES = {
  "audio/mpeg": [".mp3"],
  "audio/aac": [".aac"],
  "audio/x-ms-wma": [".wma"],
};

export const DOCUMENT_FILE_AVAILABLE_TYPE = {
  ...ACCEPT_IMAGE_FILE_TYPES,
  ...ACCEPT_APPLICATION_FILE_TYPES,
  ...ACCEPT_TEXT_FILE_TYPES,
};

export const ACCEPT_ALL_FILE_TYPES = {
  ...ACCEPT_IMAGE_FILE_TYPES,
  ...ACCEPT_APPLICATION_FILE_TYPES,
  ...ACCEPT_TEXT_FILE_TYPES,
  ...ACCEPT_VIDEO_FILE_TYPES,
  ...ACCEPT_AUDIO_FILE_TYPES,
};
