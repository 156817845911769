import React, { FC } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

type PropsType = {
  value: number;
};

export const DoughnutChart: FC<PropsType> = ({ value }) => {
  const data = {
    datasets: [
      {
        data: [value, Math.max(100 - value, 0)],
        backgroundColor: ["#00A1DF", "#EBF8FD"],
        borderWidth: 0,
      },
    ],
  };
  return (
    <div className="relative">
      <Doughnut data={data} width={170} />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <span className="text-xl font-bold text-center">{value}%</span>
      </div>
    </div>
  );
};
