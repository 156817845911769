import React, { FC } from "react";
import { Outlet } from "react-router-dom";

import { CurrentPartnerConsoleProvider } from "~/components/providers";

export const PartnerConsoleLayoutWrapper: FC = () => {
  return (
    <CurrentPartnerConsoleProvider>
      <Outlet />
    </CurrentPartnerConsoleProvider>
  );
};
