import { ChangeEvent, FormEvent, MouseEvent, useState } from "react";

import {
  useInput,
  useMultipleDropdown,
  useApisMembersBusinessSectionsDocumentFileCategorySearchItemsIndex,
  useArray,
  useApisMembersBusinessSectionsDocumentFileCategoriesIndex,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
} from "~/hooks";

import {
  ApiDocumentFileCategoriesIndexRequestType,
  DocumentFileCategoryWithDocumentFileType,
  ID_ALL_TYPE,
  MultiValueType,
  OptionType,
  SingleValueType,
} from "~/domains";

import { DOCUMENT_FILE_AVAILABLE_TYPE } from "~/constants/file";

type ReturnType = {
  documentFileCategories: DocumentFileCategoryWithDocumentFileType[];
  keyword: string;
  selectableTags: OptionType[];
  selectedTags: MultiValueType<OptionType>;
  selectedProvidingServices: MultiValueType<OptionType>;
  selectableProvidingServices: OptionType[];
  selectableDocumentFileCategories: OptionType[];
  selectedDocumentFileCategories: MultiValueType<OptionType>;
  isLoading: boolean;
  selectableDropdownHasBookmarkIds: OptionType[];
  selectedDropdownHasBookmarkId?: SingleValueType<OptionType>;
  selectableFileExtends: OptionType[];
  selectedFileExtends: MultiValueType<OptionType>;
  onChangeFileExtends: (options: MultiValueType<OptionType>) => void;
  onChangeTags: (newValue: MultiValueType<OptionType>) => void;
  onChangeProvidingServices: (newValue: MultiValueType<OptionType>) => void;
  onChangeDocumentFileCategories: (
    newValue: MultiValueType<OptionType>,
  ) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDropdownHasBookmarkId: (value: SingleValueType<OptionType>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

type PropsType = {
  businessSectionId?: string;
  employeeRoleId: number;
};

export const useSearchMembersBusinessSectionsDocumentFileCategories = ({
  businessSectionId = "",
  employeeRoleId,
}: PropsType): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const { items, setItems } =
    useArray<DocumentFileCategoryWithDocumentFileType>([]);
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [
    {
      statusValue: hasBookmark,
      selectedDropdownCompleteStatusId: selectedDropdownHasBookmarkId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasBookmarkIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasBookmarkId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "ブックマークあり",
      false: "ブックマークなし",
    },
  });

  const [
    {
      dropdownSelectableValue: selectableTags,
      dropdownValue: selectedTags,
      onChange: onChangeTags,
      setSelectableOption: setSelectableTags,
    },
  ] = useMultipleDropdown();
  const [
    {
      dropdownSelectableValue: selectableProvidingServices,
      dropdownValue: selectedProvidingServices,
      onChange: onChangeProvidingServices,
      setSelectableOption: setSelectableProvidingServices,
    },
  ] = useMultipleDropdown();
  const [
    {
      dropdownSelectableValue: selectableFileExtends,
      dropdownValue: selectedFileExtends,
      onChange: onChangeFileExtends,
    },
  ] = useMultipleDropdown({
    selectableValue: Object.values(DOCUMENT_FILE_AVAILABLE_TYPE).map(
      (value, index) => ({
        id: index.toString(),
        name: value[0] || "",
      }),
    ),
  });
  const [
    {
      dropdownSelectableValue: selectableDocumentFileCategories,
      dropdownValue: selectedDocumentFileCategories,
      onChange: onChangeDocumentFileCategories,
      setSelectableOption: setSelectableDocumentFileCategories,
    },
  ] = useMultipleDropdown();
  const [params, setParams] =
    useState<ApiDocumentFileCategoriesIndexRequestType>();

  useApisMembersBusinessSectionsDocumentFileCategorySearchItemsIndex({
    businessSectionId,
    config: {
      enabled: !!businessSectionId,
      onSuccess: (data) => {
        setSelectableTags(data.documentFileTags);
        setSelectableDocumentFileCategories(data.documentFileCategories);
        setSelectableProvidingServices([
          ID_ALL_TYPE,
          ...data.providingServices,
        ]);
      },
    },
  });

  const { isFetching } =
    useApisMembersBusinessSectionsDocumentFileCategoriesIndex({
      businessSectionId,
      params: { employeeRoleId, ...params },
      config: {
        enabled: !!businessSectionId,
        onSuccess: (data) => {
          setItems(data.documentFileCategories);
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      employeeRoleId,
      hasBookmark,
      categoryIds: selectedDocumentFileCategories.map(
        (category) => category.value,
      ),
      fileExtensions: selectedFileExtends.map((fileExtend) => fileExtend.label),
      tagIds: selectedTags.map((tag) => tag.value),
      providingServiceIds: selectedProvidingServices.map(
        (providingService) => providingService.value,
      ),
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownHasBookmarkId(null);
    onChangeTags([]);
    onChangeProvidingServices([]);
    onChangeFileExtends([]);
    onChangeDocumentFileCategories([]);
    setParams(undefined);
    setFalseRightSidebarMain();
  };

  return {
    documentFileCategories: items,
    keyword,
    isLoading: isFetching,
    selectableTags,
    selectedTags,
    selectableProvidingServices,
    selectedProvidingServices,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    selectableDocumentFileCategories,
    selectedDocumentFileCategories,
    selectableFileExtends,
    selectedFileExtends,
    onChangeFileExtends,
    onChangeTags,
    onChangeProvidingServices,
    onChangeDocumentFileCategories,
    onChangeKeyword,
    onChangeDropdownHasBookmarkId,
    onSearchSubmit,
    onConditionReset,
  };
};
