import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ResponseMessageType,
  ApiManagersSalesConceptCategoryMutateRequestType,
} from "~/domains";

type RequestDataType = {
  id: string;
  body: ApiManagersSalesConceptCategoryMutateRequestType;
};

const request = ({ id, body }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseMessageType>(`/api/managers/sales_concept_categories/${id}`, {
      salesConceptCategory: body,
    })
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersSalesConceptCategoriesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
