import React, { FC, MouseEvent } from "react";

import { format } from "date-fns";

import { PrevAndNextButtons } from "~/components/molecules";

type PropsType = {
  targetDate: Date;
  onPrevMonthClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onNextMonthClick: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

export const PrevNextSelectMonthly: FC<PropsType> = ({
  targetDate,
  onPrevMonthClick,
  onNextMonthClick,
  className = "",
}: PropsType) => {
  return (
    <div className={`w-full flex justify-between items-center ${className}`}>
      <PrevAndNextButtons
        onPrevClick={onPrevMonthClick}
        onNextClick={onNextMonthClick}
        prevText="前の月を見る"
        nextText="次の月を見る"
      />
      <span className="text-secondary-600">{format(targetDate, "y/MM")}</span>
    </div>
  );
};
