import React, { FC, FormEvent, MouseEvent, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useManagersBusinessSectionReviewsNavigation,
  useApisManagersBusinessSectionsBusinessSectionCompetencyReviewsCreate,
  useApisManagersBusinessSectionsLatestBusinessSectionCompetencyReviewsShow,
  useSearchManagersBusinessSectionsBusinessSectionCompetencyReviews,
  useBoolean,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  BusinessSectionCompetencyReviewDetails,
  BusinessSectionCompetencyReviewForm,
} from "~/components/organisms";

import { BusinessSectionType } from "~/domains";

export const ManagersBusinessSectionsBusinessSectionCompetencyReviewsIndex: FC =
  () => {
    const { businessSectionId = "" } = useParams<{
      businessSectionId: string;
    }>();
    const [businessSection, setBusinessSection] =
      useState<BusinessSectionType>();

    const {
      businessSectionCompetencyReviews,
      isLoading,
      hasNextPage,
      fetchNextPage,
      unshiftItem,
      refetch,
    } =
      useSearchManagersBusinessSectionsBusinessSectionCompetencyReviews(
        businessSectionId,
      );

    const { navigations } = useManagersBusinessSectionReviewsNavigation({
      businessSectionId,
      selectedArray: [false, true],
    });

    const breadCrumbItems = [
      {
        label: "メンバーメモ",
        href: `/managers/business_section_reviews?business_section_id=${businessSectionId}`,
      },
      {
        label: `${businessSection?.name || ""}の特性`,
        href: `/managers/business_sections/${businessSectionId}/business_section_competency_reviews`,
      },
    ];

    const {
      isChecked: isReadOnly,
      setTrue: setReadonly,
      setFalse: setNotReadonly,
    } = useBoolean(true);
    const [{ value: goal, onChange: onChangeGoal }, setGoal] = useInput("");
    const [{ value: weakness, onChange: onChangeWeakness }, setWeakness] =
      useInput("");
    const [{ value: strength, onChange: onChangeStrength }, setStrength] =
      useInput("");
    const [{ value: competency, onChange: onChangeCompetency }, setCompetency] =
      useInput("");
    const [{ value: strategy, onChange: onChangeStrategy }, setStrategy] =
      useInput("");
    const [{ value: remark, onChange: onChangeRemark }, setRemark] =
      useInput("");

    const { mutate: postRequest, isLoading: isSubmitting } =
      useApisManagersBusinessSectionsBusinessSectionCompetencyReviewsCreate();

    useApisManagersBusinessSectionsLatestBusinessSectionCompetencyReviewsShow({
      businessSectionId,
      config: {
        onSuccess: (data) => {
          setBusinessSection(data.businessSection);
          setReadonly();
          if (!data.businessSectionCompetencyReview) return;

          setGoal(data.businessSectionCompetencyReview.goal);
          setWeakness(data.businessSectionCompetencyReview.weakness);
          setStrength(data.businessSectionCompetencyReview.strength);
          setCompetency(data.businessSectionCompetencyReview.competency);
          setStrategy(data.businessSectionCompetencyReview.strategy);
          setRemark(data.businessSectionCompetencyReview.remark);
        },
      },
    });

    const handleReadonly = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      setReadonly();
    };

    const handleNotReadonly = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      setNotReadonly();
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const body = {
        goal,
        weakness,
        strength,
        competency,
        strategy,
        remark,
      };

      postRequest(
        {
          businessSectionId,
          body,
        },
        {
          onSuccess: (data) => {
            toast(data.message);
            unshiftItem(data.businessSectionCompetencyReview);
            setReadonly();
          },
        },
      );
    };

    return (
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
          <BusinessSectionCompetencyReviewForm
            goal={{ value: goal, onChange: onChangeGoal }}
            weakness={{ value: weakness, onChange: onChangeWeakness }}
            strength={{ value: strength, onChange: onChangeStrength }}
            competency={{ value: competency, onChange: onChangeCompetency }}
            strategy={{ value: strategy, onChange: onChangeStrategy }}
            remark={{ value: remark, onChange: onChangeRemark }}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            handleReadonly={handleReadonly}
            handleNotReadonly={handleNotReadonly}
            isReadOnly={isReadOnly}
            className="mt-9"
          />
          {businessSection && (
            <BusinessSectionCompetencyReviewDetails
              businessSectionCompetencyReviews={
                businessSectionCompetencyReviews
              }
              isLoading={isLoading}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              businessSection={businessSection}
              refetch={refetch}
              className="mt-9"
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    );
  };
