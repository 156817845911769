import React, { ChangeEvent, MouseEvent, FormEvent, FC } from "react";

import { FormSkillEvaluationStandardSummaryType } from "~/hooks";
import { FormSkillEvaluationStandardType, useBoolean } from "~/hooks/";

import { Form, Button, FormSubmitButton, Label } from "~/components/atoms";
import {
  LabelWithTextField,
  AddFormFieldButton,
  FolderIconWithTitle,
} from "~/components/molecules";
import { BlogEditor } from "~/components/organisms";

import { SkillEvaluationStandardForm } from "./SkillEvaluationStandardForm";

import { EditorStateType } from "~/domains";

type PropsType = {
  isSubmitting: boolean;
  defaultAccordionOpen: boolean;
  skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType;
  onChangeSkillEvaluationStandardSummaryTitle: (newValue: string) => void;
  onChangeSkillEvaluationStandardSummaryDescription: (
    newValue: EditorStateType,
  ) => void;
  onChangeSkillEvaluationStandardTitle: (
    skillEvaluationStandard: FormSkillEvaluationStandardType,
    newValue: string,
  ) => void;
  onChangeSkillEvaluationStandardDescription: (
    skillEvaluationStandard: FormSkillEvaluationStandardType,
    newValue: string,
  ) => void;
  onChangeSkillEvaluationStandardPoint: (
    skillEvaluationStandard: FormSkillEvaluationStandardType,
    newValue: number,
  ) => void;
  removeSkillEvaluationStandard: (
    skillEvaluationStandard: FormSkillEvaluationStandardType,
  ) => void;
  addSkillEvaluationStandard: () => void;
  canAddSkillEvaluationStandard: (
    skillEvaluationStandardSummary: FormSkillEvaluationStandardSummaryType,
  ) => boolean;
  remove: () => void;
  save: () => void;
  onChangeSetNotReadOnly: () => void;
  onChangeSetReadOnly: () => void;
  handleDuplicate: () => void;
};

export const SkillEvaluationStandardSummaryForm: FC<PropsType> = ({
  isSubmitting,
  defaultAccordionOpen,
  skillEvaluationStandardSummary,
  remove,
  save,
  onChangeSetNotReadOnly,
  onChangeSetReadOnly,
  removeSkillEvaluationStandard,
  addSkillEvaluationStandard,
  canAddSkillEvaluationStandard,
  onChangeSkillEvaluationStandardSummaryTitle,
  onChangeSkillEvaluationStandardSummaryDescription,
  onChangeSkillEvaluationStandardTitle,
  onChangeSkillEvaluationStandardDescription,
  onChangeSkillEvaluationStandardPoint,
  handleDuplicate,
}: PropsType) => {
  const accordionOpen = useBoolean(defaultAccordionOpen);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    save();
  };

  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    remove();
  };

  const handleNotReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onChangeSetNotReadOnly();
  };

  const handleReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onChangeSetReadOnly();
  };

  const handleOnDuplicate = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    handleDuplicate();
  };

  return (
    <Form className="space-y-4" onSubmit={onSubmit}>
      <FolderIconWithTitle
        title={skillEvaluationStandardSummary.title}
        onClick={accordionOpen.toggle}
      />
      {accordionOpen.isChecked && (
        <div className="space-y-6 ml-9">
          <LabelWithTextField
            labelText="タイトル"
            type="text"
            name="question"
            placeholder="タイトル"
            required
            disabled={skillEvaluationStandardSummary.readOnly}
            value={skillEvaluationStandardSummary.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeSkillEvaluationStandardSummaryTitle(e.target.value)
            }
          />
          <BlogEditor
            labelText="説明"
            editorState={skillEvaluationStandardSummary.contentEditorState}
            setEditorState={onChangeSkillEvaluationStandardSummaryDescription}
            readOnly={skillEvaluationStandardSummary.readOnly}
          />
          <div>
            <Label
              labelText="評価基準の入力"
              required
              htmlFor="skill-evaluation-standard"
            />
            <div className="flex items-center overflow-x-scroll w-full space-x-4 snap-mandatory snap-x pb-4">
              <div className="flex space-x-4 items-start snap-mandatory snap-x ">
                {skillEvaluationStandardSummary.skillEvaluationStandards.map(
                  (standard) => (
                    <SkillEvaluationStandardForm
                      key={`skill-standard-${standard.id}`}
                      skillEvaluationStandard={standard}
                      onChangeSkillEvaluationStandardTitle={(
                        newValue: string,
                      ) =>
                        onChangeSkillEvaluationStandardTitle(standard, newValue)
                      }
                      onChangeSkillEvaluationStandardDescription={(
                        newValue: string,
                      ) =>
                        onChangeSkillEvaluationStandardDescription(
                          standard,
                          newValue,
                        )
                      }
                      onChangeSkillEvaluationStandardPoint={(
                        newValue: number,
                      ) =>
                        onChangeSkillEvaluationStandardPoint(standard, newValue)
                      }
                      remove={() => removeSkillEvaluationStandard(standard)}
                      className="mt-4 snap-start"
                      readonly={
                        skillEvaluationStandardSummary.readOnly || false
                      }
                    />
                  ),
                )}
              </div>
              {!skillEvaluationStandardSummary.readOnly &&
                canAddSkillEvaluationStandard(
                  skillEvaluationStandardSummary,
                ) && (
                  <AddFormFieldButton
                    onClick={addSkillEvaluationStandard}
                    className="w-min snap-start"
                  />
                )}
            </div>
          </div>
          <div className="flex items-center space-x-4 justify-end w-full">
            {skillEvaluationStandardSummary.readOnly ? (
              <>
                <Button
                  text="削除"
                  onClick={handleRemove}
                  color="gray"
                  outline
                  size="sm"
                />
                <Button
                  text="複製"
                  onClick={handleOnDuplicate}
                  color="gray"
                  outline
                  size="sm"
                />
                <Button
                  text="編集"
                  onClick={handleNotReadonly}
                  color="gray"
                  outline
                  size="sm"
                />
              </>
            ) : (
              <>
                <Button
                  text="キャンセル"
                  onClick={handleReadonly}
                  color="gray"
                  outline
                  size="sm"
                />
                <FormSubmitButton
                  value="保存"
                  color="primary"
                  size="sm"
                  isReadOnly={
                    skillEvaluationStandardSummary.readOnly || isSubmitting
                  }
                />
              </>
            )}
          </div>
        </div>
      )}
    </Form>
  );
};
