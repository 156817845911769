import React, { FC } from "react";

import { Icon, ModalWrapper } from "~/components/atoms";

type ItemType = {
  id: string;
  title: string;
};

type PropsType = {
  onClose: () => void;
  handleSelectItemId: (itemId?: string) => void;
  items: ItemType[];
  title: string;
};

export const SelectItemModal: FC<PropsType> = ({
  onClose,
  items,
  handleSelectItemId,
  title,
}: PropsType) => {
  return (
    <>
      <ModalWrapper onClose={onClose} headerTitle={title}>
        <button
          type="button"
          className="flex items-center mt-8 hover:bg-secondary-100 py-2 px-1 rounded w-full"
          onClick={() => handleSelectItemId(undefined)}
        >
          <p>選択を解除</p>
        </button>
        <div className="mt-2">
          {items.map((item) => (
            <button
              type="button"
              className="text-left flex items-center hover:bg-secondary-100 py-2 px-1 rounded w-full"
              key={item.id}
              onClick={() => handleSelectItemId(item.id)}
            >
              <Icon
                icon="ioDocumentTextOutline"
                size="1.25rem"
                className="shrink-0"
              />
              <p className="ml-2">{item.title}</p>
            </button>
          ))}
        </div>
      </ModalWrapper>
    </>
  );
};
