import React, { FC } from "react";

import { PostedFilePreview } from "./PostedFilePreview";

import { AttachFileType } from "~/domains";

type PropsType = {
  files: AttachFileType[];
  className?: string;
  removeFileIndex?: (index: number) => void;
};

export const PostedFilePreviews: FC<PropsType> = ({
  files,
  className = "",
  removeFileIndex,
}: PropsType) => {
  return (
    <div className={`grid grid-cols-2 gap-2 ${className}`}>
      {files.map((file, index) => (
        <PostedFilePreview
          key={`${index}-${file.name}`}
          file={file}
          index={index}
          removeFileIndex={removeFileIndex}
        />
      ))}
    </div>
  );
};
