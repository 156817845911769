import React, { FC, useState } from "react";

import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

import { Loading } from "~/components/molecules";

import type { PDFDocumentProxy } from "pdfjs-dist";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import "./PdfFileViewer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

type PropsType = {
  url: string;
  className?: string;
};

export const PdfFileViewer: FC<PropsType> = ({
  url,
  className = "",
}: PropsType) => {
  const [numPages, setNumPages] = useState<number>(0);

  const onDocumentLoadSuccess = ({
    numPages: nextNumPages,
  }: PDFDocumentProxy) => {
    setNumPages(nextNumPages);
  };

  return (
    <Document
      file={url}
      onLoadSuccess={onDocumentLoadSuccess}
      className={className}
      loading={<Loading />}
    >
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          className="mx-auto my-4 overflow-hidden"
        />
      ))}
    </Document>
  );
};
