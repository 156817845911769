import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersLeftEmployeesHiddensUpdate,
  useProvidersCurrentEmployee,
  useSearchManagersLeftEmployees,
} from "~/hooks";

import { GridHeaderCells } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  GridRow,
  OpenedMenuInGrid,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";
import { EmployeesSearchForm } from "~/components/organisms";

import { ManagersEmployeesIndexEmployeeType, OwnerRole } from "~/domains";

export const ManagersLeftEmployeesIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const {
    employees,
    employeesIsLoading,
    keyword,
    employeesHasNextPage,
    selectableDropdownJobCategories,
    selectedDropdownJobCategories,
    selectableDropdownJobTitles,
    selectedDropdownJobTitles,
    selectableDropdownEmployeeRoleIds,
    selectedDropdownEmployeeRoleIds,
    findAndRemoveEmployee,
    onChangeDropdownJobCategories,
    onChangeDropdownJobTitles,
    onChangeDropdownEmployeeRoleIds,
    employeesFetchNextPage,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  } = useSearchManagersLeftEmployees();

  const { mutate: doHiddenRequest } =
    useApisManagersLeftEmployeesHiddensUpdate();

  const handleHiddenEmployee = (
    employee: ManagersEmployeesIndexEmployeeType,
  ) => {
    const isConfirm = confirm(
      "この操作は取り消せません。本当に非表示にしますか？",
    );
    if (!isConfirm) return;
    doHiddenRequest(
      {
        employeeId: employee.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemoveEmployee((item) => item.id === employee.id);
        },
      },
    );
  };

  const generateGridLists = (employee: ManagersEmployeesIndexEmployeeType) => {
    return [
      `${employee.lastName}${employee.firstName}`,
      employee.jobCategory.name,
      employee.jobTitle,
      employee.employeeRole.name,
    ];
  };

  const isMenuHidden = OwnerRole.id !== currentEmployee?.employeeRole?.id;

  const breadCrumbItems = [
    { label: "ユーザー設定", href: "/managers/employees" },
    { label: "無効ユーザー", href: "/managers/left_employees" },
  ];

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <EmployeesSearchForm
          onConditionReset={onConditionReset}
          onSearchSubmit={onSearchSubmit}
          selectableDropdownJobCategories={selectableDropdownJobCategories}
          selectedDropdownJobCategories={selectedDropdownJobCategories}
          onChangeDropdownJobCategories={onChangeDropdownJobCategories}
          selectableDropdownJobTitles={selectableDropdownJobTitles}
          selectedDropdownJobTitles={selectedDropdownJobTitles}
          onChangeDropdownJobTitles={onChangeDropdownJobTitles}
          selectableDropdownEmployeeRoleIds={selectableDropdownEmployeeRoleIds}
          selectedDropdownEmployeeRoleIds={selectedDropdownEmployeeRoleIds}
          onChangeDropdownEmployeeRoleIds={onChangeDropdownEmployeeRoleIds}
          keyword={keyword}
          onChangeKeyword={onChangeKeyword}
        />
        <InfiniteScrollWithMoreButton
          itemsLength={employees.length}
          nextFetchFunction={employeesFetchNextPage}
          hasMore={employeesHasNextPage}
          isLoading={employeesIsLoading}
          className="mt-12"
        >
          <div className="grid grid-cols-4">
            <GridHeaderCells texts={["氏名", "職種", "役職", "権限"]} />
            <>
              {employees.map((employee) => (
                <GridRow
                  key={employee.id}
                  lists={generateGridLists(employee)}
                  openedMenu={
                    <OpenedMenuInGrid
                      cols={4}
                      buttons={[
                        {
                          text: "非表示",
                          onClick: () => handleHiddenEmployee(employee),
                          isHidden: isMenuHidden,
                        },
                        {
                          text: "有効化",
                          link: `/managers/left_employees/${employee.id}/edit`,
                          isHidden: isMenuHidden,
                        },
                      ]}
                    />
                  }
                />
              ))}
            </>
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
