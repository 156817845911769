import React, { MouseEvent, useState, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  FormSkillEvaluationStandardType,
  useInput,
  useApisManagersSkillEvaluationStandardSummaryCategoriesUpdate,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
  useSkillEvaluationStandardSummaryForms,
  useBoolean,
} from "~/hooks";

import {
  Button,
  DragDropProvider,
  NoContentMessage,
  VerticalProgressLine,
} from "~/components/atoms";
import {
  AddFormFieldButton,
  LabelWithTextField,
  TabList,
  NameOrderableForm,
} from "~/components/molecules";
import { AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { SkillEvaluationStandardSummaryForm } from "./SkillEvaluationStandardSummaryForm";

import {
  EditorStateType,
  PublicStatus,
  SkillEvaluationStandardSummaryCategoryWithSummariesType,
} from "~/domains";

type PropsType = {
  skillEvaluationStandardSummaryCategory: SkillEvaluationStandardSummaryCategoryWithSummariesType;
};

export const SkillEvaluationStandardSummaryCategoryForm: FC<PropsType> = ({
  skillEvaluationStandardSummaryCategory,
}: PropsType) => {
  const navigate = useNavigate();
  const { currentEmployee } = useProvidersCurrentEmployee();

  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: onChangeAllBusinessSectionsDistribution,
  } = useBoolean(
    skillEvaluationStandardSummaryCategory?.allBusinessSectionsDistribution,
  );

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [name] = useInput(skillEvaluationStandardSummaryCategory.name);
  const { mutate: putRequest, isLoading: isSubmitting } =
    useApisManagersSkillEvaluationStandardSummaryCategoriesUpdate();
  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    defaultDivisions: skillEvaluationStandardSummaryCategory.businessDivisions,
    defaultSections: skillEvaluationStandardSummaryCategory.businessSections,
  });

  const {
    isSubmitting: isSkillSummarySubmitting,
    skillEvaluationStandardSummaries,
    onChangeDescription,
    onChangeTitle,
    onChangeSkillTitle,
    onChangeSkillDescription,
    onChangeSkillPoint,
    saveSkillEvaluationStandardSummary,
    removeSkillEvaluationStandardSummary,
    addSkillEvaluationStandardSummary,
    removeSkillEvaluationStandard,
    addSkillEvaluationStandard,
    canAddSkillEvaluationStandard,
    changeDisplayOrder,
    onChangeSetNotReadOnly,
    onChangeSetReadOnly,
    handleDuplicate,
  } = useSkillEvaluationStandardSummaryForms({
    skillEvaluationStandardSummaryCategoryId:
      skillEvaluationStandardSummaryCategory.id,
    skillEvaluationStandardSummaries:
      skillEvaluationStandardSummaryCategory.skillEvaluationStandardSummaries,
  });

  const handleDraftSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm(
      "作成・編集した「各項目」を「保存」しなければ反映されません。配布を実行してよろしいですか？",
    );
    if (!isConfirm) return;

    handleSubmit(PublicStatus.DRAFTED.id);
  };

  const handlePublishSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm(
      "作成・編集した「各項目」を「保存」しなければ反映されません。配布を実行してよろしいですか？",
    );
    if (!isConfirm) return;

    handleSubmit(PublicStatus.PUBLISHED.id);
  };

  const handleSubmit = (publicStatusId: number) => {
    const body = {
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      name: name.value,
      publicStatusId: publicStatusId,
      allBusinessSectionsDistribution,
    };
    putRequest(
      {
        id: skillEvaluationStandardSummaryCategory.id,
        body,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate("/managers/skill_evaluation_standard_summary_categories");
        },
      },
    );
  };
  const isCurrentSkillEvaluationForm = selectedTabIndex === 0;
  const savedSkillEvaluations = skillEvaluationStandardSummaries.filter(
    (summary) => !summary.isNew,
  );
  return (
    <>
      <div className="space-y-6">
        <AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentsOnChange={onDivisionChange}
          parentRequired={true}
          parentLabel="配布先: 部署"
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childrenOnChange={onSectionChange}
          childRequired={true}
          childLabel="配布先: 課"
          allBusinessSectionsDistribution={allBusinessSectionsDistribution}
          onChangeAllBusinessSectionsDistribution={
            onChangeAllBusinessSectionsDistribution
          }
          currentEmployeeRole={currentEmployee?.employeeRole}
        />
        <LabelWithTextField
          labelText="名前"
          type="text"
          name="name"
          placeholder="名前"
          required
          value={name.value}
          onChange={name.onChange}
        />
        <TabList
          tabTexts={["作成", "並び替え"]}
          onClick={(index: number) => setSelectedTabIndex(index)}
          selectedIndex={selectedTabIndex}
        />
        {isCurrentSkillEvaluationForm ? (
          <VerticalProgressLine>
            {skillEvaluationStandardSummaries.map(
              (skillEvaluationStandardSummary) => (
                <div key={skillEvaluationStandardSummary.id}>
                  <SkillEvaluationStandardSummaryForm
                    isSubmitting={isSkillSummarySubmitting}
                    defaultAccordionOpen={Boolean(
                      skillEvaluationStandardSummary.isNew,
                    )}
                    skillEvaluationStandardSummary={
                      skillEvaluationStandardSummary
                    }
                    onChangeSkillEvaluationStandardSummaryTitle={(
                      newValue: string,
                    ) =>
                      onChangeTitle({
                        skillEvaluationStandardSummary,
                        newValue,
                      })
                    }
                    onChangeSkillEvaluationStandardSummaryDescription={(
                      newValue: EditorStateType,
                    ) =>
                      onChangeDescription({
                        skillEvaluationStandardSummary,
                        newValue,
                      })
                    }
                    onChangeSkillEvaluationStandardTitle={(
                      skillEvaluationStandard: FormSkillEvaluationStandardType,
                      newValue: string,
                    ) =>
                      onChangeSkillTitle({
                        skillEvaluationStandardSummary,
                        skillEvaluationStandard,
                        newValue,
                      })
                    }
                    onChangeSkillEvaluationStandardDescription={(
                      skillEvaluationStandard: FormSkillEvaluationStandardType,
                      newValue: string,
                    ) =>
                      onChangeSkillDescription({
                        skillEvaluationStandardSummary,
                        skillEvaluationStandard,
                        newValue,
                      })
                    }
                    onChangeSkillEvaluationStandardPoint={(
                      skillEvaluationStandard: FormSkillEvaluationStandardType,
                      newValue: number,
                    ) =>
                      onChangeSkillPoint({
                        skillEvaluationStandardSummary,
                        skillEvaluationStandard,
                        newValue,
                      })
                    }
                    removeSkillEvaluationStandard={(
                      skillEvaluationStandard: FormSkillEvaluationStandardType,
                    ) =>
                      removeSkillEvaluationStandard({
                        skillEvaluationStandardSummary,
                        skillEvaluationStandard,
                      })
                    }
                    addSkillEvaluationStandard={() =>
                      addSkillEvaluationStandard({
                        skillEvaluationStandardSummary,
                      })
                    }
                    canAddSkillEvaluationStandard={
                      canAddSkillEvaluationStandard
                    }
                    remove={() =>
                      removeSkillEvaluationStandardSummary(
                        skillEvaluationStandardSummary,
                      )
                    }
                    save={() =>
                      saveSkillEvaluationStandardSummary(
                        skillEvaluationStandardSummary,
                      )
                    }
                    onChangeSetNotReadOnly={() =>
                      onChangeSetNotReadOnly(skillEvaluationStandardSummary)
                    }
                    onChangeSetReadOnly={() =>
                      onChangeSetReadOnly(skillEvaluationStandardSummary)
                    }
                    handleDuplicate={() =>
                      handleDuplicate(skillEvaluationStandardSummary)
                    }
                  />
                </div>
              ),
            )}
          </VerticalProgressLine>
        ) : savedSkillEvaluations.length ? (
          <DragDropProvider>
            <VerticalProgressLine>
              {savedSkillEvaluations.map((summary, index) => (
                <NameOrderableForm
                  key={`summary${summary.id}`}
                  name={summary.title}
                  index={index}
                  changeDisplayOrder={(beforeIndex: number, newIndex: number) =>
                    changeDisplayOrder(beforeIndex, newIndex)
                  }
                />
              ))}
            </VerticalProgressLine>
          </DragDropProvider>
        ) : (
          <NoContentMessage
            text="保存されたスキルマップ項目はありません"
            className="mt-4"
          />
        )}
        {isCurrentSkillEvaluationForm && (
          <AddFormFieldButton onClick={addSkillEvaluationStandardSummary} />
        )}
        <div className="flex items-center space-x-4 mx-auto">
          <Button
            text="下書き保存"
            onClick={handleDraftSubmit}
            color="gray"
            outline
            className="w-full"
            readonly={isSubmitting}
          />
          <Button
            text="配布する"
            onClick={handlePublishSubmit}
            color="primary"
            className="w-full"
            readonly={isSubmitting}
          />
        </div>
      </div>
    </>
  );
};
