type ColorType = "primary" | "gray" | "red";

export const ButtonColor: Record<
  "outline" | "contain",
  Record<ColorType, string>
> = {
  outline: {
    primary:
      "border-primary-600 text-primary-600 bg-white hover:bg-primary-200",
    gray: "border-secondary-400 text-secondary-600 bg-white hover:bg-secondary-300",
    red: "border-red-500 text-red-500 bg-white hover:bg-red-200",
  },
  contain: {
    primary:
      "border-primary-600 text-white bg-primary-600 hover:bg-primary-800 hover:border-primary-800",
    gray: "border-secondary-400 text-white bg-secondary-400 hover:bg-gray-500 hover:border-gray-500",
    red: "border-red-500 text-white bg-red-500 hover:bg-red-600 hover:border-red-600",
  },
};

export const ButtonSizes = {
  sm: "px-5 py-2",
  md: "px-10 py-2",
  lg: "px-10 py-3.5",
};

export const FormSizes = {
  sm: "px-3 py-2",
  md: "px-3 py-3.5",
  lg: "px-3 py-4 text-xl",
};
