import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  skillEvaluationTermId: string;
  body: {
    skillEvaluationTermRevieweeComment?: string;
    employeeSelectArchivedSkillEvaluations: {
      skillEvaluationTermId: string;
      revieweeEmployeeId: string;
      reviewerEmployeeId?: string;
      revieweeSelectArchivedSkillEvaluationStandardId: string;
      reviewerSelectArchivedSkillEvaluationStandardId?: string;
    }[];
  };
};

const request = ({ skillEvaluationTermId, body }: RequestDataType) => {
  return new ApiClient()
    .post<ResponseMessageType>(
      `/api/members/skill_evaluation_terms/${skillEvaluationTermId}/employee_select_skill_evaluations`,
      {
        employeeSelectArchivedSkillEvaluation: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersSkillEvaluationTermsEmployeeSelectSkillEvaluationsCreate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ResponseMessageType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
