import React, { FC, useRef, ChangeEvent, MouseEvent } from "react";

import { useReactDrag, useReactDrop } from "~/libs";

import { TextField, Icon, ButtonWithIcon } from "~/components/atoms";

type PropsType = {
  name: string;
  defaultValue?: string;
  placeholder: string;
  id: string;
  index: number;
  onChange: (value: string, id: string) => void;
  handleMove: (prevOrder: number, nextOrder: number) => void;
  removeButtonOnClick: (
    e: MouseEvent<HTMLButtonElement>,
    index: string,
  ) => void;
};

type DragItem = {
  index: number;
  id: string;
  type: string;
};

export const RemoveButtonWithTextField: FC<PropsType> = ({
  name,
  defaultValue,
  placeholder,
  id,
  index,
  onChange,
  handleMove,
  removeButtonOnClick,
}: PropsType) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId, isOver }, drop] = useReactDrop({
    accept: "jobCategoryPhase",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: !!monitor.isOver(),
      };
    },
    drop(item: DragItem) {
      handleMove(item.index, index);
    },
  });

  const [{ isDragging }, drag] = useReactDrag({
    type: "jobCategoryPhase",
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      key={`phaseId-${id}-index-${index}`}
      className={`
        flex
        items-center
        justify-between
        cursor-move
        ${isDragging ? "opacity-50" : "opacity-100"}
        ${isOver ? "bg-primary-200" : ""}
      `}
      data-handler-id={handlerId}
    >
      <>
        <ButtonWithIcon
          onClick={(e: MouseEvent<HTMLButtonElement>) =>
            removeButtonOnClick(e, id.toString())
          }
          srOnlyText="フォームを削除する"
          icon={{
            icon: "ioCloseOutline",
            size: "1.5rem",
            color: "text-secondary-600",
          }}
        />
        <TextField
          type="text"
          defaultValue={defaultValue}
          name={name}
          required
          onlyBottomBorder
          placeholder={placeholder}
          className="ml-1"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value, id)
          }
        />
        <Icon
          icon="ioSwapVerticalOutline"
          color="text-secondary-600"
          className="ml-1.5"
          size="1.5rem"
        />
      </>
    </div>
  );
};
