import { ChangeEvent, FormEvent, MouseEvent, useState } from "react";

import {
  ApiMembersSkillEvaluationStandardSummaryCategoryIndexParamsType,
  useInput,
  useApisMembersBusinessSectionsSkillEvaluationStandardSummaryCategoriesIndex,
  useArray,
  useProvidersEmployeeLayout,
} from "~/hooks";

import {
  EmployeeRole,
  SkillEvaluationStandardSummaryCategoryType,
} from "~/domains";

type ReturnType = {
  managersSkillEvaluationStandardSummaryCategories: SkillEvaluationStandardSummaryCategoryType[];
  ownersSkillEvaluationStandardSummaryCategories: SkillEvaluationStandardSummaryCategoryType[];
  keyword: string;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  managersHasNextPage?: boolean;
  managersFetchNextPage: () => void;
  ownersHasNextPage?: boolean;
  ownersFetchNextPage: () => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

type PropsType = {
  businessSectionId?: string;
};

export const useMembersBusinessSectionsSkillEvaluationStandardSummaryCategoriesSearch =
  ({ businessSectionId = "" }: PropsType = {}): ReturnType => {
    const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
    const {
      items: managersSkillEvaluationStandardSummaryCategories,
      setItems: setManagersSkillEvaluationStandardSummaryCategories,
    } = useArray<SkillEvaluationStandardSummaryCategoryType>([]);
    const {
      items: ownersSkillEvaluationStandardSummaryCategories,
      setItems: setOwnersSkillEvaluationStandardSummaryCategories,
    } = useArray<SkillEvaluationStandardSummaryCategoryType>([]);

    const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
      useInput("");
    const [params, setParams] =
      useState<ApiMembersSkillEvaluationStandardSummaryCategoryIndexParamsType>();

    const {
      fetchNextPage: managersFetchNextPage,
      hasNextPage: managersHasNextPage,
    } =
      useApisMembersBusinessSectionsSkillEvaluationStandardSummaryCategoriesIndex(
        {
          businessSectionId,
          params: { employeeRoleId: EmployeeRole.MANAGER.id, ...params },
          config: {
            enabled: !!businessSectionId,
            onSuccess: (data) => {
              setManagersSkillEvaluationStandardSummaryCategories(
                data.pages
                  .map((page) => page.skillEvaluationStandardSummaryCategories)
                  .flat(),
              );
            },
          },
        },
      );

    const {
      fetchNextPage: ownersFetchNextPage,
      hasNextPage: ownersHasNextPage,
    } =
      useApisMembersBusinessSectionsSkillEvaluationStandardSummaryCategoriesIndex(
        {
          businessSectionId,
          params: { employeeRoleId: EmployeeRole.OWNER.id, ...params },
          config: {
            enabled: !!businessSectionId,
            onSuccess: (data) => {
              setOwnersSkillEvaluationStandardSummaryCategories(
                data.pages
                  .map((page) => page.skillEvaluationStandardSummaryCategories)
                  .flat(),
              );
            },
          },
        },
      );

    const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setParams({
        keyword,
      });
      setFalseRightSidebarMain();
    };

    const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setKeyword("");
      setParams(undefined);
      setFalseRightSidebarMain();
    };

    return {
      managersSkillEvaluationStandardSummaryCategories,
      keyword,
      managersHasNextPage,
      managersFetchNextPage,
      ownersSkillEvaluationStandardSummaryCategories,
      ownersHasNextPage,
      ownersFetchNextPage,
      onChangeKeyword,
      onSearchSubmit,
      onConditionReset,
    };
  };
