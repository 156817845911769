import React, { FC } from "react";

import { startOfMonth, endOfMonth } from "date-fns";

import {
  useApisAnalyticsPlansDetailAnalysisIndex,
  useDateRange,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import { AnalyticsDetailAnalysisTemplate } from "~/components/templates";

const today = new Date();

export const AnalyticsPlansDetailAnalysisIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const breadCrumbItems = [
    {
      label: "内訳分析",
      href: "/analytics_plans/detail_analysis",
    },
  ];

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
    selectableEmployees,
    selectedEmployee,
    onEmployeeChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    isSelectEmployee: true,
  });

  const [
    {
      startDate,
      formattedStartDate,
      endDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange([startOfMonth(today), endOfMonth(today)]);

  const { data, isLoading } = useApisAnalyticsPlansDetailAnalysisIndex({
    params: {
      businessDivisionIds: selectedDivisions.map((division) => division.id),
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      fromDate: formattedStartDate || undefined,
      toDate: formattedEndDate || undefined,
      employeeIds: selectedEmployee ? [selectedEmployee.id] : undefined,
    },
    config: {
      enabled: !!(formattedStartDate && formattedEndDate),
    },
  });

  return (
    <>
      <EmployeeLayout
        withRightSidebar={false}
        breadCrumbItems={breadCrumbItems}
      >
        <EmployeeLayoutMainContent withRightSidebar={false}>
          <AnalyticsDetailAnalysisTemplate
            formattedStartDate={formattedStartDate}
            formattedEndDate={formattedEndDate}
            selectedDivisions={selectedDivisions}
            optionSelectedSections={optionSelectedSections}
            selectedEmployee={selectedEmployee}
            data={data}
            isLoading={isLoading}
            onChangeDateRange={onChangeDateRange}
            onDivisionChange={onDivisionChange}
            onSectionChange={onSectionChange}
            onEmployeeChange={onEmployeeChange}
            selectableDivisions={selectableDivisions}
            optionSelectableSections={optionSelectableSections}
            selectableEmployees={selectableEmployees}
            startDate={startDate}
            endDate={endDate}
            selectedDropdownSections={optionSelectedSections}
          />
        </EmployeeLayoutMainContent>
      </EmployeeLayout>
    </>
  );
};
