import React, { FormEvent, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useApisManagersDocumentFileCategoriesCreate,
  useBoolean,
  useInput,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { Form, FormSubmitButton } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { LabelWithTextField } from "~/components/molecules";
import { AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { PublicStatus } from "~/domains";

export const ManagersDocumentFileCategoriesNew: FC = () => {
  const navigate = useNavigate();

  const { currentEmployee } = useProvidersCurrentEmployee();
  const { mutate: createRequest, isLoading: isSubmitting } =
    useApisManagersDocumentFileCategoriesCreate();
  const [{ value: name, onChange: onChangeName }] = useInput("");

  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: onChangeAllBusinessSectionsDistribution,
  } = useBoolean(false);

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });

  const breadCrumbItems = [
    { label: "資料・ツール", href: "/managers/document_file_categories" },
    { label: "新規作成", href: "/managers/document_file_categories/new" },
  ];

  const handleDraftSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = {
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      name,
      publicStatusId: PublicStatus.DRAFTED.id,
      allBusinessSectionsDistribution,
    };
    createRequest(
      { body },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate(
            `/managers/document_file_categories/${data.documentFileCategory.id}/edit`,
          );
        },
      },
    );
  };

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <Form className="space-y-6" onSubmit={handleDraftSubmit}>
          <AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField
            allParents={selectableDivisions}
            parentsValue={selectedDivisions}
            parentsOnChange={onDivisionChange}
            parentRequired={true}
            parentLabel="配布先: 部署"
            allChildren={optionSelectableSections}
            childrenValue={optionSelectedSections}
            childrenOnChange={onSectionChange}
            childRequired={true}
            childLabel="配布先: 課"
            allBusinessSectionsDistribution={allBusinessSectionsDistribution}
            onChangeAllBusinessSectionsDistribution={
              onChangeAllBusinessSectionsDistribution
            }
            currentEmployeeRole={currentEmployee?.employeeRole}
          />
          <LabelWithTextField
            labelText="名前"
            type="text"
            name="name"
            placeholder="名前"
            required
            value={name}
            onChange={onChangeName}
          />
          <FormSubmitButton
            value="資料カテゴリの作成"
            color="primary"
            className="w-full"
            isReadOnly={isSubmitting}
          />
        </Form>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
