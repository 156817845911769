import { useState, ChangeEvent, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { sumPoints } from "~/utils";

import {
  useApisManagersInsightPostObjectivesCreate,
  useApisManagersInsightPostObjectivesUpdate,
  useApisMembersBusinessSectionRelationEmployeesIndex,
  useArray,
  useDateRange,
  useDivisionSectionDropdown,
  useInput,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  BusinessDivisionType,
  BusinessSectionType,
  ChildType,
  EmployeeRole,
  InsightPostObjectiveType,
  ParentType,
  PublicStatus,
  RangeDatePropsType,
} from "~/domains";

type ReturnType = {
  title: string;
  onChangeTitle: (e: ChangeEvent<HTMLInputElement>) => void;
  objectivePerformance: number | "";
  setObjectivePerformance: (value: number | "") => void;
  formattedStartDate: string | null;
  formattedEndDate: string | null;
  startDate: Date | null;
  endDate: Date | null;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  handlePublishSubmit: (e: MouseEvent<HTMLButtonElement>) => void;
  handleDraftSubmit: (e: MouseEvent<HTMLButtonElement>) => void;
  isSubmitting: boolean;
  selectableDivisions: BusinessDivisionType[];
  selectedDivision?: BusinessDivisionType | null;
  onDivisionChange: (newValue: ParentType | null) => void;
  selectedSection?: BusinessSectionType | null;
  onSectionChange: (newValue: ChildType | null) => void;
  optionSelectableSections: ChildType[];
  optionSelectedSection?: ChildType | null;
  employeeAndInsightPostObjectives: FormEmployeeAndInsightPostObjectiveType[];
  onChangeEmployeeCount: ({
    employeeAndInsightPostObjective,
    newValue,
  }: {
    employeeAndInsightPostObjective: FormEmployeeAndInsightPostObjectiveType;
    newValue: number | "";
  }) => void;
  onChangeUnsetEmployee: (
    employeeAndInsightPostObjective: FormEmployeeAndInsightPostObjectiveType,
  ) => void;
  onChangeSetEmployee: (
    employeeAndInsightPostObjective: FormEmployeeAndInsightPostObjectiveType,
  ) => void;
  handleEvenUp: (e: MouseEvent<HTMLButtonElement>) => void;
  employeeSumPoints: number;
};

type FormEmployeeAndInsightPostObjectiveType = {
  employee: {
    id: string;
    name: string;
  };
  objectivePerformance: number | "";
  isReadOnly: boolean;
};

type PropsType = {
  insightPostObjective?: InsightPostObjectiveType;
};

export function useInsightPostObjectiveForms({
  insightPostObjective,
}: PropsType): ReturnType {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const navigate = useNavigate();

  const {
    items: employeeAndInsightPostObjectives,
    setItems: setEmployeeAndInsightPostObjectives,
    findAndReplace: findAndReplaceEmployeeAndInsightPostObjectives,
  } = useArray<FormEmployeeAndInsightPostObjectiveType>(
    insightPostObjective?.employeeAndInsightPostObjectives.map(
      (employeeAndInsightPostObjective) => ({
        ...employeeAndInsightPostObjective,
        isReadOnly: false,
      }),
    ),
  );

  const { mutate: putInsightPostObjective, isLoading: isUpdating } =
    useApisManagersInsightPostObjectivesUpdate();

  const { mutate: postInsightPostObjective, isLoading: isCreating } =
    useApisManagersInsightPostObjectivesCreate();

  const [{ value: title, onChange: onChangeTitle }] = useInput(
    insightPostObjective?.title,
  );
  const [objectivePerformance, setObjectivePerformance] = useState<number | "">(
    insightPostObjective?.objectivePerformance || "",
  );

  const {
    selectableDivisions,
    selectedDivision,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSection,
    onSectionChange,
  } = useDivisionSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    defaultDivision: insightPostObjective?.businessDivision,
    defaultSection: insightPostObjective?.businessSection,
  });

  const [
    {
      formattedStartDate,
      formattedEndDate,
      startDate,
      endDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange(
    insightPostObjective
      ? [
          new Date(insightPostObjective?.startDate),
          new Date(insightPostObjective?.endDate),
        ]
      : null,
  );

  useApisMembersBusinessSectionRelationEmployeesIndex({
    params: {
      businessSectionIds: [optionSelectedSection?.id || ""],
      employeeRoleIds: [EmployeeRole.MEMBER.id, EmployeeRole.MANAGER.id],
    },
    config: {
      enabled: !!optionSelectedSection,
      onSuccess: (res) => {
        setEmployeeAndInsightPostObjectives(
          res.employees.map((employee) => {
            const findEmployeeAndInsightPostObjective =
              employeeAndInsightPostObjectives.find(
                (employeeAndInsightPostObjective) =>
                  employeeAndInsightPostObjective.employee.id === employee.id,
              );
            return {
              employee: {
                id: employee.id,
                name: employee.name,
              },
              objectivePerformance:
                findEmployeeAndInsightPostObjective?.objectivePerformance ?? "",
              isReadOnly: false,
            };
          }),
        );
      },
    },
  });

  const handlePublishSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    handleSubmit(PublicStatus.PUBLISHED.id);
  };

  const handleDraftSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    handleSubmit(PublicStatus.DRAFTED.id);
  };

  const onChangeEmployeeCount = ({
    employeeAndInsightPostObjective,
    newValue,
  }: {
    employeeAndInsightPostObjective: FormEmployeeAndInsightPostObjectiveType;
    newValue: number | "";
  }) => {
    const newObject = {
      ...employeeAndInsightPostObjective,
      objectivePerformance: newValue,
    };

    findAndReplaceEmployeeAndInsightPostObjectives(
      newObject,
      (item) =>
        item.employee.id === employeeAndInsightPostObjective.employee.id,
    );
  };

  const onChangeUnsetEmployee = (
    employeeAndInsightPostObjective: FormEmployeeAndInsightPostObjectiveType,
  ) => {
    const newObject = {
      ...employeeAndInsightPostObjective,
      isReadOnly: true,
      objectivePerformance: "" as number | "",
    };

    findAndReplaceEmployeeAndInsightPostObjectives(
      newObject,
      (item) =>
        item.employee.id === employeeAndInsightPostObjective.employee.id,
    );
  };

  const onChangeSetEmployee = (
    employeeAndInsightPostObjective: FormEmployeeAndInsightPostObjectiveType,
  ) => {
    const newObject = {
      ...employeeAndInsightPostObjective,
      isReadOnly: false,
      objectivePerformance: 0,
    };

    findAndReplaceEmployeeAndInsightPostObjectives(
      newObject,
      (item) =>
        item.employee.id === employeeAndInsightPostObjective.employee.id,
    );
  };

  const handleEvenUp = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const notReadOnlyCount = employeeAndInsightPostObjectives.filter(
      (employeeAndInsightPostObjective) =>
        !employeeAndInsightPostObjective.isReadOnly,
    ).length;

    const evenUpValue = Math.ceil(
      Number(objectivePerformance) / notReadOnlyCount,
    );

    const newEmployeeAndInsightPostObjectives =
      employeeAndInsightPostObjectives.map(
        (employeeAndInsightPostObjective) => {
          if (employeeAndInsightPostObjective.isReadOnly)
            return employeeAndInsightPostObjective;
          return {
            ...employeeAndInsightPostObjective,
            objectivePerformance: evenUpValue,
          };
        },
      );
    setEmployeeAndInsightPostObjectives(newEmployeeAndInsightPostObjectives);
  };

  const employeeSumPoints = sumPoints(
    employeeAndInsightPostObjectives.map((employeeAndInsightPostObjective) =>
      Number(employeeAndInsightPostObjective.objectivePerformance),
    ),
  );

  const handleSubmit = (publicStatusId: number) => {
    const body = {
      businessSectionId: optionSelectedSection?.id || "",
      title,
      objectivePerformance: Number(objectivePerformance),
      startDate: formattedStartDate || "",
      endDate: formattedEndDate || "",
      publicStatusId,
      employeeAndInsightPostObjectives: employeeAndInsightPostObjectives
        .filter(
          (employeeAndInsightPostObjective) =>
            !employeeAndInsightPostObjective.isReadOnly,
        )
        .map((employeeAndInsightPostObjective) => ({
          employeeId: employeeAndInsightPostObjective.employee.id,
          objectivePerformance: Number(
            employeeAndInsightPostObjective.objectivePerformance,
          ),
        })),
    };

    insightPostObjective
      ? putInsightPostObjective(
          {
            id: insightPostObjective.id,
            body,
          },
          {
            onSuccess: (data) => {
              toast(data.message);
              navigate("/managers/insight_post_objectives");
            },
          },
        )
      : postInsightPostObjective(
          {
            body,
          },
          {
            onSuccess: (data) => {
              toast(data.message);
              navigate("/managers/insight_post_objectives");
            },
          },
        );
  };

  return {
    title,
    onChangeTitle,
    objectivePerformance,
    setObjectivePerformance,
    formattedStartDate,
    formattedEndDate,
    startDate,
    endDate,
    onChangeDateRange,
    handlePublishSubmit,
    handleDraftSubmit,
    isSubmitting: isUpdating || isCreating,
    selectableDivisions,
    selectedDivision,
    onDivisionChange,
    onSectionChange,
    optionSelectableSections,
    optionSelectedSection,
    employeeAndInsightPostObjectives,
    onChangeEmployeeCount,
    onChangeUnsetEmployee,
    onChangeSetEmployee,
    handleEvenUp,
    employeeSumPoints,
  };
}
