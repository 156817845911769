import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiAnalyticsPlansFeedbackAnalysisIndexResponseType,
  ApiAnalyticsPlansFeedbackAnalysisIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiAnalyticsPlansFeedbackAnalysisIndexRequestType;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApiAnalyticsPlansFeedbackAnalysisIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiAnalyticsPlansFeedbackAnalysisIndexResponseType>(
      "/api/analytics_plans/feedback_analysis",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiAnalyticsPlansFeedbackAnalysisIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisAnalyticsPlansFeedbackAnalysisIndex = ({
  params,
  config = {},
}: PropsType = {}): UseQueryResult<ApiAnalyticsPlansFeedbackAnalysisIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisAnalyticsPlansFeedbackAnalysisIndex", params],
    queryFn: () => request({ params }),
  });
};
