import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMemberEmployeeSurveySubmissionRepliesIndexResponseType } from "~/domains";

type ParamsType = {
  employeeSurveySubmissionId: string;
};

const request = async ({
  employeeSurveySubmissionId,
}: ParamsType): Promise<ApiMemberEmployeeSurveySubmissionRepliesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMemberEmployeeSurveySubmissionRepliesIndexResponseType>(
      `/api/members/employee_survey_submissions/${employeeSurveySubmissionId}/employee_survey_submission_replies`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  employeeSurveySubmissionId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersEmployeeSurveySubmissionsEmployeeSurveySubmissionRepliesIndex =
  ({
    employeeSurveySubmissionId,
    config = {},
  }: PropsType): UseQueryResult<ApiMemberEmployeeSurveySubmissionRepliesIndexResponseType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisMembersEmployeeSurveySubmissionsEmployeeSurveySubmissionRepliesIndex",
        employeeSurveySubmissionId,
      ],
      queryFn: () => request({ employeeSurveySubmissionId }),
    });
  };
