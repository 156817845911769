import React, { FC } from "react";

import { MenuType, useMenus } from "~/hooks";

import { SidebarMenu } from "~/components/organisms";

import { ApiMembersUnreadNotificationCountsType } from "~/domains";

type PropsType = {
  className?: string;
  unreadNotification?: ApiMembersUnreadNotificationCountsType;
  currentMenu: MenuType;
  setCurrentMenu: (menu: MenuType) => void;
};

export const Footer: FC<PropsType> = ({
  className = "",
  unreadNotification,
  currentMenu,
  setCurrentMenu,
}: PropsType) => {
  const { menus } = useMenus();
  return (
    <footer
      className={`sticky bottom-0 border-t border-solid border-secondary-300 px-4 pb-2 bg-white z-dialog flex items-center justify-between miniTablet:hidden ${className}`}
    >
      {menus.map((menu) => (
        <SidebarMenu
          key={menu.type}
          menuType={menu.type}
          href={menu.href}
          unreadNotification={unreadNotification}
          selectMenu={setCurrentMenu}
          currentMenuType={currentMenu}
        />
      ))}
    </footer>
  );
};
