import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersKnowledgeInsightPostCommentsLikedEmployeesIndexResponseType } from "~/domains";

type RequestDataType = {
  knowledgeInsightPostCommentId: string;
  page?: number;
};

const request = async ({
  knowledgeInsightPostCommentId,
  page = 1,
}: RequestDataType): Promise<ApiMembersKnowledgeInsightPostCommentsLikedEmployeesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersKnowledgeInsightPostCommentsLikedEmployeesIndexResponseType>(
      `/api/members/knowledge_insight_post_comments/${knowledgeInsightPostCommentId}/liked_employees`,
      { page },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  knowledgeInsightPostCommentId: string;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersKnowledgeInsightPostCommentsLikedEmployeesIndex = ({
  knowledgeInsightPostCommentId,
  config = {},
}: PropsType): UseInfiniteQueryResult<ApiMembersKnowledgeInsightPostCommentsLikedEmployeesIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    [
      "ApisMembersKnowledgeInsightPostCommentsLikedEmployeesIndex",
      knowledgeInsightPostCommentId,
    ],
    ({ pageParam }) => {
      return request({
        knowledgeInsightPostCommentId,
        page: pageParam as number | undefined,
      });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
