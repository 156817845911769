import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import {
  ResponseMessageType,
  RawDraftContentState,
  PreviewWithFileType,
} from "~/domains";

type RequestDataType = {
  insightPostId: string;
  body: {
    content: RawDraftContentState;
    attachFiles: PreviewWithFileType[];
    quotePostId?: string;
  };
};

const request = ({ insightPostId, body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ reply: body });
  return new ApiClient()
    .post<ResponseMessageType>(
      `/api/members/insight_posts/${insightPostId}/insight_post_comments`,
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersInsightPostsInsightPostCommentsCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
