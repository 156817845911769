import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import { ApiMembersFocusChallengeRepliesIndexType } from "~/domains";

type ParamsType = {
  focusChallengeEmployeeId: string;
};

const request = async ({
  focusChallengeEmployeeId,
}: ParamsType): Promise<ApiMembersFocusChallengeRepliesIndexType> => {
  const response =
    await new ApiClient().get<ApiMembersFocusChallengeRepliesIndexType>(
      `/api/members/focus_challenge_employees/${focusChallengeEmployeeId}/focus_challenge_employee_replies`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  focusChallengeEmployeeId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesIndex =
  ({
    focusChallengeEmployeeId,
    config = {},
  }: PropsType): UseQueryResult<ApiMembersFocusChallengeRepliesIndexType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesIndex",
        focusChallengeEmployeeId,
      ],
      queryFn: () => request({ focusChallengeEmployeeId }),
      useErrorBoundary: (error) => {
        return error instanceof ApiResponseError && error.status >= 500;
      },
    });
  };
