import OpenAI from "openai";
import { toast } from "react-toastify";

import { useBoolean } from "~/hooks";

type OnSuccessType = {
  chatMessage: OpenAI.Chat.Completions.ChatCompletionAssistantMessageParam;
  data: OpenAI.Chat.Completions.ChatCompletion;
};

type OpenAIRequestType = {
  prompt: string;
  systemMessage?: string;
  alreadyMessages?: OpenAI.Chat.Completions.ChatCompletionMessageParam[];
  onSuccess?: ({ data, chatMessage }: OnSuccessType) => void;
  onError?: (error: Error) => void;
};

type ReturnType = {
  openAiRequest: ({
    prompt,
    systemMessage,
    alreadyMessages,
    onSuccess,
    onError,
  }: OpenAIRequestType) => Promise<void>;
  isChatRequesting: boolean;
};

export const useOpenAiRequest = (currentEmployeeId: string): ReturnType => {
  const openai = new OpenAI({
    apiKey: process.env.OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });
  const { isChecked: isChatRequesting, setTrue, setFalse } = useBoolean(false);

  const openAiRequest = async ({
    prompt,
    systemMessage,
    alreadyMessages = [],
    onSuccess,
    onError,
  }: OpenAIRequestType) => {
    setTrue();
    const messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [
      ...alreadyMessages,
      { role: "user", content: prompt },
    ];
    if (systemMessage) {
      messages.unshift({
        role: "system",
        content: systemMessage,
      });
    }
    try {
      const completion = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: messages,
        user: currentEmployeeId,
        max_tokens: 2048,
      });
      setFalse();
      const message = completion.choices.at(-1)?.message;
      if (!message) {
        throw new Error("メッセージがありません");
      }
      onSuccess &&
        onSuccess({
          data: completion,
          chatMessage: message,
        });
    } catch (error) {
      setFalse();
      if (error instanceof Error) {
        onError && onError(error);
        toast.error(error.message);
      } else {
        toast.error("エラーが発生しました");
      }
    }
  };

  return {
    openAiRequest,
    isChatRequesting,
  };
};
