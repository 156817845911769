import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  employeeSurveyId: string;
  body: {
    id: string;
    displayOrder: number;
  }[];
};

const request = ({ employeeSurveyId, body }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseMessageType>(
      `/api/managers/employee_surveys/${employeeSurveyId}/display_order`,
      {
        employeeSurvey: { employeeSurveyQuestions: body },
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersEmployeeSurveysDisplayOrdersUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
