import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import { ResponseMessageType } from "~/domains";

type UploadResponseType = ResponseMessageType & {
  imageUrl: string;
};

type RequestDataType = {
  body: {
    image: File | Blob;
  };
};

const request = ({ body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({
    uploadImage: body,
  });
  return new ApiClient()
    .post<UploadResponseType>("/api/members/upload_images", formData)
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersUploadImagesCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<UploadResponseType, RequestDataType> => {
  return useMutation(request, { ...options });
};
