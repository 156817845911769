import React, { FC } from "react";

import { useBoolean } from "~/hooks";

import {
  BarAndLineChart,
  ButtonWithIcon,
  ModalWrapper,
} from "~/components/atoms";
import { Loading } from "~/components/molecules";

import { AnalysisPostCountType } from "~/domains";

type PropsType = {
  isLoading: boolean;
  title: string;
  postAnalysis?: AnalysisPostCountType[];
  className?: string;
};

export const AnalysisDetailOfPostGraph: FC<PropsType> = ({
  isLoading,
  title,
  postAnalysis,
  className = "",
}: PropsType) => {
  const { isChecked, setTrue, setFalse } = useBoolean(false);

  return (
    <div className={`${className}`}>
      <p className="font-semibold text-primary-600 text-lg text-center">
        {title}
      </p>
      <div className="shadow mt-4 p-4 rounded-md flex justify-center text-center">
        {isLoading ? (
          <Loading />
        ) : (
          postAnalysis && (
            <>
              <BarAndLineChart
                barLabel="投稿数"
                lineLabel="課の平均投稿数"
                datasets={postAnalysis.map((item) => ({
                  name: item.name,
                  barValue: item.postsTotalCount,
                  lineValue: item.postsAverageCount,
                }))}
              />
              <ButtonWithIcon
                srOnlyText="グラフを拡大する"
                icon={{
                  icon: "ioScanOutline",
                  color: "text-secondary-600",
                  size: "1.25rem",
                }}
                onClick={setTrue}
                className="flex justify-end"
              />
            </>
          )
        )}
      </div>
      {postAnalysis && isChecked && (
        <ModalWrapper
          onClose={setFalse}
          headerTitle={title}
          className={className}
        >
          <div className="mt-4 rounded-md">
            <BarAndLineChart
              barLabel="投稿数"
              lineLabel="課の平均投稿数"
              datasets={postAnalysis.map((item) => ({
                name: item.name,
                barValue: item.postsTotalCount,
                lineValue: item.postsAverageCount,
              }))}
            />
          </div>
        </ModalWrapper>
      )}
    </div>
  );
};
