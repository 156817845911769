import React, { FC } from "react";

import { IconPropsType } from "~/components/atoms";
import { IconWithCounter } from "~/components/molecules";

type PropsType = {
  doneAlready: boolean;
  doIcon: IconPropsType;
  undoIcon: IconPropsType;
  doOnClick: () => void;
  undoOnClick: () => void;
  textOnClick?: () => void;
  count: number | string;
  className?: string;
  buttonClassName?: string;
};

export const ToggleIconWithCounter: FC<PropsType> = ({
  doneAlready,
  doIcon,
  undoIcon,
  doOnClick,
  undoOnClick,
  textOnClick,
  count,
  className = "",
  buttonClassName = "",
}: PropsType) => {
  return (
    <>
      {doneAlready ? (
        <IconWithCounter
          icon={undoIcon}
          onClick={undoOnClick}
          count={count}
          textOnClick={textOnClick}
          className={className}
          buttonClassName={buttonClassName}
        />
      ) : (
        <IconWithCounter
          icon={doIcon}
          onClick={doOnClick}
          count={count}
          textOnClick={textOnClick}
          className={className}
          buttonClassName={buttonClassName}
        />
      )}
    </>
  );
};
