import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersSkillEvaluationStandardSummaryCategoriesDestroy,
  useApisManagersSkillEvaluationStandardSummaryCategoriesDuplicatesCreate,
  useSearchManagersSkillEvaluationStandardSummaryCategories,
} from "~/hooks";

import {
  Form,
  Button,
  FormSubmitButton,
  GridHeaderCells,
  BooleanDropDownField,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  LabelWithDropDownField,
  LabelWithTextField,
  GridRow,
  OpenedMenuInGrid,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { SkillEvaluationStandardSummaryCategoryType } from "~/domains";

export const ManagersSkillEvaluationStandardSummaryCategoriesIndex: FC = () => {
  const { mutate: deleteRequest } =
    useApisManagersSkillEvaluationStandardSummaryCategoriesDestroy();
  const { mutate: duplicateRequest } =
    useApisManagersSkillEvaluationStandardSummaryCategoriesDuplicatesCreate();
  const {
    skillEvaluationStandardSummaryCategories,
    nameKeyword,
    isLoading,
    hasNextPage,
    allBusinessSectionsDistribution,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    onDivisionChange,
    onSectionChange,
    unshiftItem,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    fetchNextPage,
    onChangeNameKeyword,
    onConditionReset,
    onSearchSubmit,
    setAllBusinessSectionsDistribution,
  } = useSearchManagersSkillEvaluationStandardSummaryCategories();
  const breadCrumbItems = [
    {
      label: "スキルマップ",
      href: "/managers/skill_evaluation_standard_summary_categories",
    },
    {
      label: "一覧",
      href: "/managers/skill_evaluation_standard_summary_categories",
    },
  ];

  const handleDelete = (
    skillEvaluationStandardSummaryCategory: SkillEvaluationStandardSummaryCategoryType,
  ) => {
    const result = confirm("本当に削除しますか？");
    if (!result) return;

    deleteRequest(
      {
        id: skillEvaluationStandardSummaryCategory.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove(
            (item) => item.id === skillEvaluationStandardSummaryCategory.id,
          );
        },
      },
    );
  };

  const duplicateRequestSkillRequest = (
    skillEvaluationStandardSummaryCategory: SkillEvaluationStandardSummaryCategoryType,
  ) => {
    const isConfirm = confirm("本当に複製しますか？");
    if (!isConfirm) return;
    duplicateRequest(
      {
        skillEvaluationStandardSummaryCategoryId:
          skillEvaluationStandardSummaryCategory.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          unshiftItem(data.skillEvaluationStandardSummaryCategory);
        },
      },
    );
  };

  const generateSkillEvaluationStandardSummaryCategoryLists = (
    skillEvaluationStandardSummaryCategory: SkillEvaluationStandardSummaryCategoryType,
  ) => {
    return [
      skillEvaluationStandardSummaryCategory.name,
      skillEvaluationStandardSummaryCategory.allBusinessSectionsDistribution
        ? "すべての部署"
        : skillEvaluationStandardSummaryCategory.businessDivisions
            .map((division) => division.name)
            .join(","),
      skillEvaluationStandardSummaryCategory.allBusinessSectionsDistribution
        ? "すべての課"
        : skillEvaluationStandardSummaryCategory.businessSections
            .map((section) => section.name)
            .join(","),
      skillEvaluationStandardSummaryCategory.publicStatus.name,
    ];
  };
  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <Form onSubmit={onSearchSubmit} className="space-y-6">
          <div className="grid miniTablet:grid-cols-2 gap-4">
            <MultiParentsWithMultiChildrenDropdownField
              allParents={selectableDropdownDivisions}
              parentsValue={selectedDropdownDivisions}
              parentLabel="部署"
              parentsOnChange={onDivisionChange}
              allChildren={selectableDropdownSections}
              childrenValue={selectedDropdownSections}
              childLabel="課"
              childrenOnChange={onSectionChange}
            />
            <BooleanDropDownField
              name="isAllBusinessSections"
              trueLabel="すべての課に配布する"
              falseLabel="個別の課に配布する"
              labelText="配布種別の選択"
              value={allBusinessSectionsDistribution}
              onChange={setAllBusinessSectionsDistribution}
            />
            <LabelWithDropDownField
              labelText="ステータス"
              name="publicStatus"
              options={selectableDropdownPublicStatusIds}
              value={selectedDropdownPublicStatusId}
              onChange={onChangeDropdownPublicStatusId}
            />
            <LabelWithTextField
              labelText="フリーワード"
              type="search"
              name="keyword"
              placeholder="フリーワード検索"
              value={nameKeyword}
              onChange={onChangeNameKeyword}
            />
          </div>
          <div className="flex items-center justify-end space-x-4">
            <Button
              color="gray"
              outline
              className="w-full miniTablet:w-auto"
              text="リセット"
              onClick={onConditionReset}
            />
            <FormSubmitButton
              value="検索する"
              color="primary"
              className="w-full miniTablet:w-auto"
            />
          </div>
        </Form>
        <InfiniteScrollWithMoreButton
          itemsLength={skillEvaluationStandardSummaryCategories.length}
          nextFetchFunction={fetchNextPage}
          isLoading={isLoading}
          hasMore={hasNextPage}
          className="mt-9"
        >
          <div className="grid grid-cols-4">
            <GridHeaderCells texts={["タイトル", "部署", "課", "ステータス"]} />
            {skillEvaluationStandardSummaryCategories.map(
              (skillEvaluationStandardSummaryCategory) => (
                <GridRow
                  key={skillEvaluationStandardSummaryCategory.id}
                  lists={generateSkillEvaluationStandardSummaryCategoryLists(
                    skillEvaluationStandardSummaryCategory,
                  )}
                  openedMenu={
                    <OpenedMenuInGrid
                      cols={4}
                      buttons={[
                        {
                          text: "編集",
                          link: `/managers/skill_evaluation_standard_summary_categories/${skillEvaluationStandardSummaryCategory.id}/edit`,
                        },
                        {
                          text: "削除",
                          onClick: () =>
                            handleDelete(
                              skillEvaluationStandardSummaryCategory,
                            ),
                        },
                        {
                          text: "複製",
                          onClick: () =>
                            duplicateRequestSkillRequest(
                              skillEvaluationStandardSummaryCategory,
                            ),
                        },
                      ]}
                    />
                  }
                />
              ),
            )}
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
