import React, { FC, MouseEvent } from "react";

import { currentFormatTimestamp, formatDateTime, toDateString } from "~/libs";

import { downloadBlob, arrayToCsv } from "~/utils";

import { Button } from "~/components/atoms";
import {
  ActivityOriginalCalendar,
  RangeDatePicker,
} from "~/components/molecules";
import {
  AnalysisCumulativeOfPostGraph,
  AnalysisDetailOfPostGraph,
  DivisionAndSectionAndEmployeeDropdownField,
  RoleplayingConditionSelectEmployeeDailyCountCalendar,
} from "~/components/organisms";

import {
  BusinessDivisionType,
  ParentType,
  ApiAnalyticsPlansRoleplayingConditionsIndexResponseType,
  RangeDatePropsType,
  ChildType,
} from "~/domains";

type PropsType = {
  formattedStartDate: string | null;
  formattedEndDate: string | null;
  selectedDivisions: BusinessDivisionType[];
  optionSelectedSections: ChildType[];
  data?: ApiAnalyticsPlansRoleplayingConditionsIndexResponseType;
  className?: string;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onDivisionChange: (newValue: ParentType[]) => void;
  onSectionChange: (newValue: ChildType[]) => void;
  selectableDivisions: BusinessDivisionType[];
  optionSelectableSections: ChildType[];
  selectedDropdownSections: ChildType[];
  selectedEmployee?: ParentType;
  onEmployeeChange: (newValue: ParentType | undefined) => void;
  selectableEmployees: ParentType[];
  dateArray: Date[];
  targetDate: Date;
  handlePrevMonthClick: (e: MouseEvent<HTMLButtonElement>) => void;
  handleNextMonthClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

const today = new Date();

export const AnalyticsRoleplayingConditionsTemplate: FC<PropsType> = ({
  formattedStartDate,
  formattedEndDate,
  selectedDivisions,
  optionSelectedSections,
  isLoading,
  className = "",
  startDate,
  endDate,
  onChangeDateRange,
  onDivisionChange,
  onSectionChange,
  selectableDivisions,
  optionSelectableSections,
  data,
  dateArray,
  selectedEmployee,
  onEmployeeChange,
  selectableEmployees,
  targetDate,
  handlePrevMonthClick,
  handleNextMonthClick,
}: PropsType) => {
  const handleExportCsv = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    downloadBlob(
      arrayToCsv(generateCsvData()),
      `${currentFormatTimestamp}-roleplaying_analysis.csv`,
    );
  };

  const generateCsvData = () => {
    return [
      ["期間", `${formattedStartDate} ~ ${formattedEndDate}`],
      [
        "部署",
        selectedDivisions.map((division) => division.name).join(", ") ||
          "すべての部署",
      ],
      [
        "課",
        optionSelectedSections?.length
          ? optionSelectedSections.map((section) => section.name).join(", ")
          : selectedDivisions?.length
            ? "選択された部署に属するすべての課"
            : "すべての課",
      ],
      [
        "日",
        "日毎の実施状況",
        "日毎の実施状況(平均)",
        "累計の実施",
        "累計の実施(平均)",
      ],
      ...dateArray.map((date) => {
        const dateString = toDateString(date);
        const dailyPostsCount = data?.dailyPostsCounts.find(
          (item) => item.name === dateString,
        );
        const dailyCumulativePostsCount = data?.dailyCumulativePostsCounts.find(
          (item) => item.name === dateString,
        );
        return [
          dateString,
          dailyPostsCount?.postsTotalCount,
          dailyPostsCount?.postsAverageCount,
          dailyCumulativePostsCount?.postsTotalCount,
          dailyCumulativePostsCount?.postsAverageCount,
        ];
      }),
    ];
  };
  return (
    <div className={className}>
      <div className="grid miniTablet:grid-cols-2 gap-4">
        <DivisionAndSectionAndEmployeeDropdownField
          selectableDropdownDivisions={selectableDivisions}
          selectedDropdownDivisions={selectedDivisions}
          onDivisionChange={onDivisionChange}
          selectableDropdownSections={optionSelectableSections}
          selectedDropdownSections={optionSelectedSections}
          onSectionChange={onSectionChange}
          selectableDropdownEmployees={selectableEmployees}
          selectedDropdownEmployee={selectedEmployee}
          onEmployeeChange={onEmployeeChange}
        />
        <RangeDatePicker
          labelText="期間"
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeDateRange}
          maxDate={today}
        />
      </div>
      <div className="mt-6 text-right">
        <Button
          outline
          text="CSVエクスポート"
          onClick={handleExportCsv}
          color="primary"
          readonly={isLoading}
        />
      </div>
      <div className="grid miniTablet:grid-cols-2 gap-6 mt-6">
        <AnalysisDetailOfPostGraph
          title="日毎の実施状況"
          isLoading={isLoading}
          postAnalysis={data?.dailyPostsCounts.map((item) => ({
            ...item,
            name: formatDateTime(item.name, "MM-dd"),
          }))}
        />
        <AnalysisCumulativeOfPostGraph
          title="累計の実施"
          isLoading={isLoading}
          postAnalysis={data?.dailyCumulativePostsCounts.map((item) => ({
            ...item,
            name: formatDateTime(item.name, "MM-dd"),
          }))}
        />
        {selectedEmployee ? (
          <RoleplayingConditionSelectEmployeeDailyCountCalendar
            employeeId={selectedEmployee.id}
            title="実施カレンダー"
          />
        ) : (
          <ActivityOriginalCalendar
            title="実施カレンダー"
            isLoading={isLoading}
            dailyActivity={data?.postsCalendar}
            targetDate={targetDate}
            onPrevMonthClick={handlePrevMonthClick}
            onNextMonthClick={handleNextMonthClick}
            isForRate
          />
        )}
      </div>
    </div>
  );
};
