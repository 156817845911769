import { MutationConfigType, useMutation, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  insightPostId: string;
  body: {
    bookmarkFolderId?: string;
  };
};

const request = async ({
  insightPostId,
  body,
}: RequestDataType): Promise<ResponseMessageType> => {
  const response = await new ApiClient().put<ResponseMessageType>(
    `/api/members/bookmarked_insight_posts/${insightPostId}/bookmark_folder_moves`,
    { ...body },
  );
  return response.data;
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersBookmarkedInsightPostsBookmarkFolderMovesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
