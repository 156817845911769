import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useApisMembersEmployeeSurveySubmissionsShow,
  useEmployeeSurveysSlideNavigation,
  useBoolean,
  useApisMembersEmployeeSurveySubmissionsEmployeeSurveySubmissionRepliesIndex,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  EmployeeSurveySubmissionDetail,
  EmployeeSurveySubmissionRepliesWithEmployeeSurveySubmission,
} from "~/components/organisms";

export const MembersEmployeeSurveySubmissionsShow: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { isChecked: replayOpen, toggle } = useBoolean(true);

  const breadCrumbItems = [
    {
      label: "チェックアウト",
      href: "/employee_surveys",
    },
    {
      label: "共有",
      href: "/employee_survey_submissions",
    },
  ];

  const { navigations } = useEmployeeSurveysSlideNavigation({
    selectedArray: [false, true],
  });

  const { data } = useApisMembersEmployeeSurveySubmissionsShow({ id });
  const { data: repliesIndexData, refetch: repliesRefetch } =
    useApisMembersEmployeeSurveySubmissionsEmployeeSurveySubmissionRepliesIndex(
      {
        employeeSurveySubmissionId: id,
      },
    );

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        {data && (
          <EmployeeSurveySubmissionDetail
            employeeSurveySubmission={data.employeeSurveySubmission}
            openReply={toggle}
            className="mt-12"
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        {replayOpen && repliesIndexData && (
          <>
            <RightSidebarTitle title="リプライ" />
            <EmployeeSurveySubmissionRepliesWithEmployeeSurveySubmission
              className="mt-6"
              employeeSurveySubmission={
                repliesIndexData.employeeSurveySubmission
              }
              mentions={[]}
              employeeSurveySubmissionReplies={
                repliesIndexData.employeeSurveySubmissionReplies || []
              }
              refetch={repliesRefetch}
            />
          </>
        )}
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
