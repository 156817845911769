import React, { FC, ReactNode } from "react";

import { ChatAiContainer } from "~/components/organisms";

type Props = {
  children?: ReactNode;
};

export const EmployeeLayoutRightSidebar: FC<Props> = ({ children }: Props) => {
  return (
    <div className="sticky z-sidebar top-header-offset h-full overflow-x-hidden snap-start pb-16 flex-shrink-0 miniTablet:block border-l border-secondary-200 w-full miniTablet:w-[37.5%] hidden-scrollbar mobile-screen-height miniTablet:miniTablet-screen-height">
      {children}
      <ChatAiContainer />
    </div>
  );
};
