import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  knowledgeInsightPostId: string;
  id: string;
};

const request = ({ knowledgeInsightPostId, id }: RequestDataType) => {
  return new ApiClient()
    .delete<ResponseMessageType>(
      `/api/members/knowledge_insight_posts/${knowledgeInsightPostId}/knowledge_insight_post_comments/${id}`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsDestroy =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ResponseMessageType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
