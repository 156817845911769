import { useState, useEffect, useCallback, useMemo } from "react";

import { toast } from "react-toastify";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type ReturnType<T> = {
  loading: boolean;
  data: T | null;
  error: Error | null;
  doRequest: doRequestType<T>;
};

type BodyType = Record<string, unknown> | FormData;

type PostRequestType<T> = {
  requestUrl?: string;
  body?: BodyType;
  onSuccess?: (data: T) => void;
  onError?: (err: Error) => void;
};

type Error = {
  message: string;
};

type doRequestType<T> = ({
  requestUrl,
  body,
  onSuccess,
  onError,
}: PostRequestType<T>) => Promise<void>;

export type MethodsType = "post" | "put" | "delete";

type PropsType = {
  method: MethodsType;
  url: string;
};

export function usePost<T = ResponseMessageType>({
  method,
  url,
}: PropsType): ReturnType<T> {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<T | null>(null);

  const memoizeUrl = useMemo(() => url, [url]);

  const httpRequest = useCallback(
    (propsUrl = memoizeUrl, body?: BodyType) => {
      const apiClient = new ApiClient();
      switch (method) {
        case "post":
          return apiClient.post<T>(propsUrl || "", body ?? {});
        case "put":
          return apiClient.put<T>(propsUrl || "", body ?? {});
        case "delete":
          return apiClient.delete<T>(propsUrl || "");
      }
    },
    [memoizeUrl, method],
  );

  const doRequest = useCallback<doRequestType<T>>(
    ({
      requestUrl,
      body,
      onSuccess,
      onError,
    }: PostRequestType<T>): Promise<void> => {
      setLoading(true);

      return httpRequest(requestUrl, body)
        .then((response) => {
          setData(response.data || null);
          onSuccess?.(response.data);
        })
        .catch((err: Error) => {
          onError ? onError(err) : toast.error(err.message);
          setError(err);
        })
        .finally(() => setLoading(false));
    },
    [httpRequest],
  );

  const clear = useCallback(() => {
    setData(null);
    setLoading(false);
    setError(null);
  }, []);

  useEffect(() => () => clear(), [clear]);

  return {
    loading,
    data,
    error,
    doRequest,
  };
}
