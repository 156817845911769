import { useState, useCallback, useEffect } from "react";

import { ParentType, OptionType, MultiValueType } from "~/domains";

type PropsType = {
  initialValue?: ParentType[];
  selectableValue?: ParentType[];
};

type ReturnType = [
  {
    value?: ParentType[];
    dropdownValue: MultiValueType<OptionType>;
    dropdownSelectableValue: OptionType[];
    selectableOption: ParentType[];
    onChange: (newValue: MultiValueType<OptionType>) => void;
    convertToDropdown: (value?: ParentType[]) => OptionType[];
    handleOptionCreate: (newValue: string) => void;
    setSelectableOption: (newValue: ParentType[]) => void;
  },
  (value: ParentType[]) => void,
];

export const useMultipleDropdown = ({
  initialValue = [],
  selectableValue = [],
}: PropsType = {}): ReturnType => {
  const [value, setValue] = useState(initialValue);
  const [selectableOption, setSelectableOption] = useState(selectableValue);
  const convertToDropdown = (records: ParentType[]) => {
    return records.map((record) => ({
      value: record.id,
      label: record.name,
    }));
  };

  const convertToValue = useCallback((records: MultiValueType<OptionType>) => {
    return records.map((record) => ({
      id: record.value,
      name: record.label,
    }));
  }, []);

  const onChangeDropDown = useCallback(
    (newValues: MultiValueType<OptionType>) => {
      setValue(convertToValue(newValues));
    },
    [convertToValue],
  );

  const handleOptionCreate = (newValue: string) => {
    const newId = Math.floor(Math.random() * 10000000).toString();
    const newItem = { id: newId, name: newValue };

    setSelectableOption((prevItems) => [...prevItems, newItem]);
    setValue((prevItems) => [...prevItems, newItem]);
  };

  const dropdownValue = convertToDropdown(value || []);
  const dropdownSelectableValue = convertToDropdown(selectableOption || []);
  useEffect(() => {
    selectableValue.length && setSelectableOption(selectableValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectableValue.length]);

  return [
    {
      value,
      dropdownValue,
      dropdownSelectableValue,
      selectableOption,
      onChange: useCallback(
        (newValue: MultiValueType<OptionType>) => onChangeDropDown(newValue),
        [onChangeDropDown],
      ),
      convertToDropdown: useCallback(
        (value?: ParentType[]) => convertToDropdown(value || []),
        [],
      ),
      handleOptionCreate,
      setSelectableOption,
    },
    useCallback((value: ParentType[]) => setValue(value), []),
  ];
};
