import {
  BusinessDivisionType,
  BusinessSectionType,
  CompanyProfileType,
  JobCategoryType,
} from "~/domains";

export type AvatarAndNameEmployeeType = {
  id: string;
  name: string;
  avatarUrl: string;
};

export type EmployeeProfileType = {
  id: string;
  lastName: string;
  firstName: string;
  katakanaLastName: string;
  katakanaFirstName: string;
  jobTitle?: string;
  objective?: string;
  employeeRole: EmployeeRoleType;
  jobCategory: JobCategoryType;
  businessDivisions: BusinessDivisionType[];
  businessSections: BusinessSectionType[];
  company: CompanyProfileType;
  headerImageUrl: string;
  avatarUrl: string;
};

type RoleType = {
  id: 1 | 10 | 20;
  name: "メンバー" | "マネージャー" | "オーナー";
  type: "member" | "manager" | "owner";
};

export const MemberRole = {
  id: 1,
  name: "メンバー",
  type: "member",
} as const satisfies RoleType;
export const ManagerRole = {
  id: 10,
  name: "マネージャー",
  type: "manager",
} as const satisfies RoleType;
export const OwnerRole = {
  id: 20,
  name: "オーナー",
  type: "owner",
} as const satisfies RoleType;

export const EmployeeRole = {
  MEMBER: MemberRole,
  MANAGER: ManagerRole,
  OWNER: OwnerRole,
} as const satisfies {
  MEMBER: RoleType;
  MANAGER: RoleType;
  OWNER: RoleType;
};

export const AllRoles = [
  EmployeeRole.MEMBER,
  EmployeeRole.MANAGER,
  EmployeeRole.OWNER,
] satisfies RoleType[];
export const ManagerOrOwnerRoles = [
  EmployeeRole.MANAGER,
  EmployeeRole.OWNER,
] satisfies RoleType[];

export type EmployeeRoleType =
  | typeof MemberRole
  | typeof ManagerRole
  | typeof OwnerRole;
