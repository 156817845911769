import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import { ApisExternalJogpResponseType } from "~/domains";

type RequestDataType = {
  params?: {
    url: string;
  };
};

const request = async ({
  params,
}: RequestDataType): Promise<ApisExternalJogpResponseType> => {
  const response = await new ApiClient(
    "https://jogp.hatsu38.workers.dev/",
  ).get<ApisExternalJogpResponseType>("", params);
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: {
    url: string;
  };
  config?: QueryConfigType<QueryFnType>;
};

export const useApisJogpShow = ({
  params,
  config = {},
}: PropsType = {}): UseQueryResult<ApisExternalJogpResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisJogpShow", params],
    queryFn: () => request({ params }),
    useErrorBoundary: (error) => {
      return error instanceof ApiResponseError && error.status >= 500;
    },
  });
};
