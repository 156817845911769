import { MouseEvent, ChangeEvent, FormEvent, useState } from "react";

import { toDateString } from "~/libs";

import {
  useInput,
  useDateRange,
  useApisManagersFocusChallengesIndex,
  useArray,
  useDropdown,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { RangeDatePropsType } from "~/components/molecules";

import {
  FocusChallengeType,
  OptionType,
  ApiManagersFocusChallengesIndexRequestType,
  AllPublicStatus,
  BusinessDivisionType,
  ChildType,
  ParentType,
  SingleValueType,
} from "~/domains";

type ReturnType = {
  focusChallenges: FocusChallengeType[];
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  hasNextPage?: boolean;
  isLoading: boolean;
  selectableDropdownPublicStatusIds: OptionType[];
  selectedDropdownPublicStatusId?: SingleValueType<OptionType>;
  selectableDropdownDivisions: BusinessDivisionType[];
  selectedDropdownDivisions: BusinessDivisionType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  deleteIndexFocusChallenge: (index: number) => void;
  findAndRemove: (func: (item: FocusChallengeType) => boolean) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeDropdownPublicStatusId: (value: SingleValueType<OptionType>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const useSearchManagersFocusChallenges = (): ReturnType => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const [
    {
      dropdownValue: selectedDropdownPublicStatusId,
      onChange: onChangeDropdownPublicStatusId,
      dropdownSelectableValue: selectableDropdownPublicStatusIds,
    },
    setDropdownPublicStatus,
  ] = useDropdown({
    selectableValue: AllPublicStatus.map((status) => ({
      name: status.name,
      id: status.id.toString(),
    })),
  });
  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });
  const [params, setParams] =
    useState<ApiManagersFocusChallengesIndexRequestType>({
      employeeRoleId: currentEmployee?.employeeRole.id,
    });

  const { items, setItems, deleteIndexItem, findAndRemove } =
    useArray<FocusChallengeType>([]);

  const { fetchNextPage, hasNextPage, isFetching } =
    useApisManagersFocusChallengesIndex({
      params,
      config: {
        onSuccess: (data) => {
          setItems(data.pages.map((page) => page.focusChallenges).flat());
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      businessDivisionIds: selectedDivisions.map((division) => division.id),
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      publicStatusId: selectedDropdownPublicStatusId?.value,
      employeeRoleId: currentEmployee?.employeeRole.id,
      dateFrom: startDate ? toDateString(startDate) : "",
      dateTo: endDate ? toDateString(endDate) : "",
    });
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDateRange([null, null]);
    setDropdownPublicStatus(undefined);
    onDivisionChange([]);
    onSectionChange([]);
    setParams({});
  };

  return {
    focusChallenges: items,
    isLoading: isFetching,
    keyword,
    hasNextPage,
    startDate,
    endDate,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownDivisions: selectableDivisions,
    selectedDropdownDivisions: selectedDivisions,
    selectableDropdownSections: optionSelectableSections,
    selectedDropdownSections: optionSelectedSections,
    onDivisionChange,
    onSectionChange,
    deleteIndexFocusChallenge: deleteIndexItem,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    fetchNextPage,
    onChangeDateRange,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  };
};
