const rootFontSize = parseFloat(
  getComputedStyle(document.documentElement).fontSize,
);

export const getCssVarInPx = (varName: string): number => {
  return (
    parseFloat(
      getComputedStyle(document.documentElement).getPropertyValue(varName),
    ) * rootFontSize
  );
};

export const setCssVar = (varName: string, value: string): void => {
  document.documentElement.style.setProperty(varName, value);
};
