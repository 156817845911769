import React, { FC, ReactNode } from "react";

import { NoContentMessage } from "~/components/atoms";
import { Loading } from "~/components/molecules";

type PropsType = {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  data?: null | Array<unknown> | unknown;
  loading: boolean;
  noContentMessage?: string;
  error: string | null;
  children: ReactNode;
};

export const Fetch: FC<PropsType> = ({
  data,
  loading,
  noContentMessage,
  error,
  children,
}: PropsType) => {
  const hasContent = Array.isArray(data) ? data.length : data;

  if (loading) return <Loading className="mt-8" />;
  if (error) return <pre>{error}</pre>;
  if (!hasContent)
    return <NoContentMessage text={noContentMessage || "データがありません"} />;

  return <>{children}</>;
};
