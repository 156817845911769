import React, { FC } from "react";

import { Icon } from "~/components/atoms";

import { PostDetailMenuItemType } from "~/domains";
type PropsType = {
  items: PostDetailMenuItemType[];
  className?: string;
};

export const PostDetailMenu: FC<PropsType> = ({
  items,
  className = "",
}: PropsType) => {
  return (
    <div
      className={`absolute right-0 z-modal min-w-max shadow
      p-3.5 bg-white border border-solid
      border-gray-200 rounded-lg ${className}`}
    >
      {items
        .filter((item) => !item.disabled)
        .map((item, index) => (
          <div
            key={`${item.text}-${index}`}
            onClick={item.onClick}
            className="flex items-center cursor-pointer hover:bg-secondary-100 py-1.5 px-1 rounded w-full"
          >
            {item.icon && (
              <Icon
                icon={item.icon}
                size="1.25rem"
                color="text-secondary-600"
              />
            )}
            <span className="ml-2 text-secondary-600">{item.text}</span>
          </div>
        ))}
    </div>
  );
};
