import React, { FC } from "react";

import {
  useFooters,
  useApisMembersKaizenIdeasLikesCreate,
  useApisMembersKaizenIdeasLikesDestroy,
  useBoolean,
  useCounter,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import { CompletedMarkMessage, TitleWithRating } from "~/components/molecules";
import {
  PostHeader,
  PostContent,
  KaizenIdeaLikedEmployeesModal,
} from "~/components/organisms";

import { KaizenIdeaType, PostDetailMenuItemType } from "~/domains";

type PropsType = {
  isHighlight?: boolean;
  kaizenIdea: KaizenIdeaType;
  menuItems: PostDetailMenuItemType[];
  openReply: (value: string) => void;
};

export const KaizenIdeaDetail: FC<PropsType> = ({
  kaizenIdea,
  menuItems,
  isHighlight = false,
  openReply,
}: PropsType) => {
  const {
    id,
    content,
    title,
    completedAt,
    priorityRating,
    createdAt,
    employee,
    providingService,
    clientCompany,
    attachFiles,
    kaizenIdeaStat,
  } = kaizenIdea;

  const {
    isChecked: isLikedEmployeesModalOpen,
    setTrue: setLikedEmployeesModalOpen,
    setFalse: setLikedEmployeesModalClose,
  } = useBoolean(false);

  const {
    isChecked: liked,
    setTrue: setLikedTrue,
    setFalse: setLikedFalse,
  } = useBoolean(kaizenIdea.liked);

  const {
    count: likeCount,
    increment: likeIncrement,
    decrement: likeDecrement,
  } = useCounter(kaizenIdeaStat.likesCount);
  const { mutate: likePostRequest } = useApisMembersKaizenIdeasLikesCreate();
  const { mutate: likeDestroyRequest } =
    useApisMembersKaizenIdeasLikesDestroy();

  const handleLikePostRequest = () => {
    likePostRequest(
      {
        kaizenIdeaId: id,
      },
      {
        onSuccess: () => {
          setLikedTrue();
          likeIncrement();
        },
      },
    );
  };

  const handleLikeDestroyRequest = () => {
    likeDestroyRequest(
      {
        kaizenIdeaId: id,
      },
      {
        onSuccess: () => {
          setLikedFalse();
          likeDecrement();
        },
      },
    );
  };

  const leftFooters = useFooters({
    like: {
      doneAlready: liked,
      undoOnClick: handleLikeDestroyRequest,
      doOnClick: handleLikePostRequest,
      textOnClick: setLikedEmployeesModalOpen,
      count: likeCount,
      disable: false,
    },
    comment: {
      doneAlready: Boolean(kaizenIdea.kaizenIdeaStat.repliesCount),
      undoOnClick: () => openReply(kaizenIdea.id),
      doOnClick: () => openReply(kaizenIdea.id),
      count: kaizenIdea.kaizenIdeaStat.repliesCount,
    },
  });

  return (
    <PostDetailWrapper isHighlight={isHighlight}>
      {completedAt && <CompletedMarkMessage message="改善・相談完了済み" />}
      <PostHeader
        className={completedAt ? "mt-2" : ""}
        miniText={createdAt}
        postedEmployee={employee}
        menuItems={menuItems}
        underLabel={`${providingService?.name || "すべて"} - ${
          clientCompany?.name || "本人"
        }`}
      />
      <TitleWithRating
        title={title}
        rate={priorityRating.id}
        className="mt-2 pl-16"
      />
      <PostContent
        content={content}
        images={attachFiles}
        footers={leftFooters.footers}
        className="mt-2 pl-16"
      />
      {isLikedEmployeesModalOpen && (
        <KaizenIdeaLikedEmployeesModal
          kaizenIdeaId={id}
          onClose={setLikedEmployeesModalClose}
        />
      )}
    </PostDetailWrapper>
  );
};
