import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisManagersKpiTermsDashboardsShowRequestType,
  ApisManagersKpiTermsDashboardsShowResponseType,
} from "~/domains";

type RequestDataType = {
  kpiTermId: string;
  params: ApisManagersKpiTermsDashboardsShowRequestType;
};

const request = async ({
  kpiTermId,
  params,
}: RequestDataType): Promise<ApisManagersKpiTermsDashboardsShowResponseType> => {
  const response =
    await new ApiClient().get<ApisManagersKpiTermsDashboardsShowResponseType>(
      `/api/managers/kpi_terms/${kpiTermId}/dashboard`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  kpiTermId: string;
  params: ApisManagersKpiTermsDashboardsShowRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersKpiTermsDashboardsShow = ({
  kpiTermId,
  params,
  config = {},
}: PropsType): UseQueryResult<ApisManagersKpiTermsDashboardsShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersKpiTermsDashboardsShow", kpiTermId, params],
    queryFn: () => request({ kpiTermId, params }),
  });
};
