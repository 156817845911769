import { extractLinks } from "@draft-js-plugins/linkify";

import { rawDraftContentStateToDraftJsContent } from "~/utils";

import {
  useApisMembersInsightPostCommentsShow,
  useApisMembersInsightPostsShow,
} from "~/hooks";

import {
  AttachFileType,
  AvatarAndNameEmployeeType,
  EditorStateType,
  InsightPostCommentType,
  InsightPostType,
  RawDraftContentState,
} from "~/domains";

type ReturnType = {
  links: string[] | null;
  fetchedData?: {
    id: string;
    content: RawDraftContentState;
    employee: AvatarAndNameEmployeeType;
    files: AttachFileType[];
    createdAt: string;
    contentText: string;
  };
};

type PropsType = {
  watchEditorState: EditorStateType;
  isWatch: boolean;
};

export const useEditorStateLinkContentGetter = ({
  watchEditorState,
  isWatch,
}: PropsType): ReturnType => {
  const links = extractLinks(
    watchEditorState.getCurrentContent().getPlainText(),
  );

  // NOTE: InsightPostのFetch
  const fetchInsightPostTargetLink = `${location.origin}/insight_posts/`;
  const fetchInsightPostTargetLinks = links?.filter((link: { url: string }) =>
    link.url.startsWith(fetchInsightPostTargetLink),
  );
  const fetchInsightPostTargetLinkIds = fetchInsightPostTargetLinks?.map(
    (link: { url: string }) => link.url.replace(fetchInsightPostTargetLink, ""),
  );
  const { data: fetchedInsightPostsShow } = useApisMembersInsightPostsShow({
    id: fetchInsightPostTargetLinkIds?.[0] || "",
    config: {
      enabled: isWatch && !!fetchInsightPostTargetLinkIds?.[0],
      useErrorBoundary: () => {
        return false;
      },
    },
  });

  // NOTE: InsightPostCommentのFetch
  const fetchInsightPostCommentTargetLink = `${location.origin}/insight_post_comments/`;
  const fetchInsightPostCommentTargetLinks = links?.filter(
    (link: { url: string }) =>
      link.url.startsWith(fetchInsightPostCommentTargetLink),
  );
  const fetchInsightPostCommentTargetLinkIds =
    fetchInsightPostCommentTargetLinks?.map((link: { url: string }) =>
      link.url.replace(fetchInsightPostCommentTargetLink, ""),
    );
  const { data: fetchedInsightPostCommentsShow } =
    useApisMembersInsightPostCommentsShow({
      id: fetchInsightPostCommentTargetLinkIds?.[0] || "",
      config: {
        enabled: isWatch && !!fetchInsightPostCommentTargetLinkIds?.[0],
        useErrorBoundary: () => {
          return false;
        },
      },
    });

  const formatObject = (post: InsightPostType | InsightPostCommentType) => {
    return {
      id: post.id,
      content: post.content,
      employee: post.employee,
      files: post.attachFiles,
      createdAt: post.createdAt,
      contentText: rawDraftContentStateToDraftJsContent(post.content)
        .getCurrentContent()
        .getPlainText(),
    };
  };

  const fetchedData = fetchedInsightPostsShow
    ? formatObject(fetchedInsightPostsShow.insightPost)
    : fetchedInsightPostCommentsShow
      ? formatObject(fetchedInsightPostCommentsShow.insightPostComment)
      : undefined;

  return {
    links,
    fetchedData,
  };
};
