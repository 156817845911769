import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersMeetingNoteSearchItemsIndexResponseType } from "~/domains";

const request =
  async (): Promise<ApiManagersMeetingNoteSearchItemsIndexResponseType> => {
    const response =
      await new ApiClient().get<ApiManagersMeetingNoteSearchItemsIndexResponseType>(
        "/api/managers/meeting_note_search_items",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersMeetingNoteSearchItemsIndex = ({
  config = {},
}: PropsType): UseQueryResult<ApiManagersMeetingNoteSearchItemsIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersMeetingNoteSearchItemsIndex"],
    queryFn: () => request(),
  });
};
