import React, { FC } from "react";

import { CatchTitleBorder } from "~/components/atoms";
import { InfiniteScroll } from "~/components/molecules";

import { BusinessSectionCompetencyReviewDetail } from "./BusinessSectionCompetencyReviewDetail";

import {
  BusinessSectionType,
  BusinessSectionCompetencyReviewType,
} from "~/domains";

type PropsType = {
  businessSection: BusinessSectionType;
  businessSectionCompetencyReviews: BusinessSectionCompetencyReviewType[];
  isLoading: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  refetch: () => void;
  className?: string;
};

export const BusinessSectionCompetencyReviewDetails: FC<PropsType> = ({
  businessSection,
  businessSectionCompetencyReviews,
  isLoading,
  hasNextPage,
  fetchNextPage,
  refetch,
  className = "",
}: PropsType) => {
  return (
    <div className={className}>
      <CatchTitleBorder text={`${businessSection.name}の更新履歴`} />
      <InfiniteScroll
        itemsLength={businessSectionCompetencyReviews.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        isLoading={isLoading}
        className={"mt-9"}
        scrollClassName="space-y-6"
      >
        {businessSectionCompetencyReviews.map(
          (businessSectionCompetencyReview) => (
            <BusinessSectionCompetencyReviewDetail
              key={businessSectionCompetencyReview.id}
              businessSectionCompetencyReview={businessSectionCompetencyReview}
              refetch={refetch}
            />
          ),
        )}
      </InfiniteScroll>
    </div>
  );
};
