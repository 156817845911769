import React, { FC, MouseEvent } from "react";

import { currentFormatTimestamp, toDateString } from "~/libs";

import { downloadBlob, arrayToCsv } from "~/utils";

import { Button, CountDisplayBox } from "~/components/atoms";
import { Loading, RangeDatePicker } from "~/components/molecules";
import {
  MultiParentsWithMultiChildrenDropdownField,
  ScalableBarGraph,
} from "~/components/organisms";

import {
  BusinessDivisionType,
  ParentType,
  ChildType,
  PostRankingType,
  RangeDatePropsType,
} from "~/domains";

type PropsType = {
  className?: string;
  selectableDivisions: BusinessDivisionType[];
  selectedDivisions: BusinessDivisionType[];
  onDivisionChange: (newValue: ParentType[]) => void;
  onSectionChange: (newValue: ChildType[]) => void;
  optionSelectableSections: ChildType[];
  optionSelectedSections: ChildType[];
  startDate: Date | null;
  endDate: Date | null;
  onChangeDateRange: (date: RangeDatePropsType) => void;
  isLoading: boolean;
  data?: {
    managersPostsCount: number;
    ownersPostsCount: number;
    others: {
      count: number;
      title: string;
    }[];
  };
  rankings?: {
    title: string;
    data?: PostRankingType[];
  }[];
};

export const AnalyticsPlansCountDisplayBoxes: FC<PropsType> = ({
  className = "",
  selectableDivisions,
  selectedDivisions,
  onDivisionChange,
  onSectionChange,
  optionSelectableSections,
  optionSelectedSections,
  startDate,
  endDate,
  onChangeDateRange,
  isLoading,
  data,
  rankings,
}: PropsType) => {
  const gridsColsClass = () => {
    const num = 2 + (data?.others.length || 0);
    if (num === 3) {
      return "miniTablet:grid-cols-3";
    } else if (num === 4) {
      return "miniTablet:grid-cols-4";
    }
    return "miniTablet:grid-cols-3";
  };

  const handleExportCsv = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    downloadBlob(
      arrayToCsv(generateCsvData()),
      `${currentFormatTimestamp}-analysis.csv`,
    );
  };

  const generateCsvData = () => {
    return [
      [
        "部署",
        selectedDivisions.map((division) => division.name).join(", ") ||
          "すべての部署",
      ],
      [
        "課",
        optionSelectedSections.map((section) => section.name).join(", ") ||
          "すべての課",
      ],
      ["マネージャー配布", data?.managersPostsCount],
      ["オーナー配布", data?.ownersPostsCount],
      [
        "期間",
        `${startDate && toDateString(startDate)} 〜 ${
          endDate && toDateString(endDate)
        }`,
      ],
      ...(data?.others || []).map((other) => [other.title, other.count]),
      ...(rankings || []).flatMap((ranking) => [
        [
          `${ranking.title}(名前)`,
          ...(ranking.data || []).map((data) => data.name),
        ],
        [
          `${ranking.title}(数)`,
          ...(ranking.data || []).map((data) => data.postsCount),
        ],
      ]),
    ];
  };

  return (
    <div className={className}>
      <div className="grid miniTablet:grid-cols-2 gap-4">
        <MultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentLabel="部署"
          parentsOnChange={onDivisionChange}
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childLabel="課"
          childrenOnChange={onSectionChange}
        />
        <RangeDatePicker
          labelText="期間"
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeDateRange}
        />
      </div>
      <div className="mt-6 text-right">
        <Button
          outline
          text="CSVエクスポート"
          onClick={handleExportCsv}
          color="primary"
          readonly={isLoading}
        />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {data && (
            <div className={`grid gap-6 grid-cols-2 ${gridsColsClass()} mt-6`}>
              <CountDisplayBox
                title="マネージャー配布"
                count={data.managersPostsCount}
                unitName="件"
              />
              <CountDisplayBox
                title="オーナー配布"
                count={data.ownersPostsCount}
                unitName="件"
              />
              {data.others.map((other) => (
                <CountDisplayBox
                  key={other.title}
                  title={other.title}
                  count={other.count}
                  unitName="件"
                />
              ))}
            </div>
          )}
          {rankings && (
            <div className="grid miniTablet:grid-cols-2 gap-6 mt-6">
              {rankings.map((ranking) => (
                <ScalableBarGraph
                  key={ranking.title}
                  isLoading={isLoading}
                  perforManceData={
                    ranking?.data?.map((post) => ({
                      name: post.name,
                      value: post.postsCount,
                    })) || []
                  }
                  title={ranking.title}
                  graphWrapperClassName="h-80"
                  graphClassName="w-72 miniTablet:w-full"
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};
