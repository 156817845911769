import React, { FC, ChangeEvent, MouseEvent } from "react";

import { Icon, IconType, TextArea } from "~/components/atoms";

import { FormSizes } from "~/constants/buttonAndForm";

type PropsType = {
  icon: IconType;
  name: string;
  value?: string;
  defaultValue?: string;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  rows?: number;
  size?: keyof typeof FormSizes;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const IconButtonWithTextArea: FC<PropsType> = ({
  icon,
  name,
  value,
  defaultValue,
  autoComplete,
  placeholder = "",
  required = false,
  disabled = false,
  className = "",
  onChange,
  onClick,
  rows = 7,
  size = "sm",
}: PropsType) => {
  return (
    <div className={`relative ${className}`}>
      <TextArea
        name={name}
        value={value}
        rows={rows}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={onChange}
        size={size}
        className="pr-12"
      />
      <button
        type="button"
        onClick={onClick}
        disabled={disabled}
        className="absolute top-3.5 right-0 px-2"
      >
        <Icon
          icon={icon}
          size="1.5rem"
          color={value ? "text-primary-600" : "text-secondary-400"}
        />
      </button>
    </div>
  );
};
