import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiKpiBusinessSectionsKpiDailyPerformancesShowResponseType } from "~/domains";

type RequestDataType = {
  businessSectionId: string;
  date: string;
};

const request = async ({
  businessSectionId,
  date,
}: RequestDataType): Promise<ApiKpiBusinessSectionsKpiDailyPerformancesShowResponseType> => {
  const response =
    await new ApiClient().get<ApiKpiBusinessSectionsKpiDailyPerformancesShowResponseType>(
      `/api/members/kpi/business_sections/${businessSectionId}/kpi_daily_performances/${date}`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  businessSectionId: string;
  date: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersKpiBusinessSectionsKpiDailyPerformancesShow = ({
  businessSectionId,
  date,
  config = {},
}: PropsType): UseQueryResult<ApiKpiBusinessSectionsKpiDailyPerformancesShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersSkillEvaluationTermsShow", businessSectionId, date],
    queryFn: () => request({ businessSectionId, date }),
  });
};
