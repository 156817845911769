import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { toDateString } from "~/libs";

import {
  useApisMembersKpiTermEndKpiTermsDashboardsShow,
  useDateRange,
  useKpiSlideNavigation,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  KpiDailyPerformanceCalendar,
  KpiDashboardsShow,
} from "~/components/organisms";

const date = new Date();
export const MembersTermEndKpiTermsDashboardShow: FC = () => {
  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";
  const { id = "" } = useParams<{ id: string }>();
  const { navigations } = useKpiSlideNavigation({
    selectedArray: [false, true, false],
    businessSectionId: businessSectionId || "",
    date: date,
  });
  const breadCrumbItems = [
    {
      label: "KPI・KGI",
      href: `/kpi_daily_performances/${toDateString(
        date,
      )}?business_section_id=${businessSectionId}`,
    },
    {
      label: "チャート",
      href: `/term_end_kpi_terms/${id}/dashboard?business_section_id=${businessSectionId}`,
    },
  ];

  const [
    {
      startDate,
      formattedStartDate,
      endDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange();

  const { data, isFetching } = useApisMembersKpiTermEndKpiTermsDashboardsShow({
    termEndKpiTermId: id,
    params: {
      fromDate: formattedStartDate || undefined,
      toDate: formattedEndDate || undefined,
    },
  });

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
          <KpiDashboardsShow
            rangeDatePicker={{
              startDate,
              endDate,
              onChangeDateRange,
            }}
            kpiTerm={data?.kpiTerm}
            kpiObjectiveDashboards={data?.kpiObjectiveDashboards}
            loading={isFetching}
            className="mt-6"
          />
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          {businessSectionId && (
            <>
              <RightSidebarTitle title="Information" />
              <KpiDailyPerformanceCalendar
                className="mx-4 mt-6"
                businessSectionId={businessSectionId}
              />
            </>
          )}
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
