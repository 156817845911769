import React, { FC } from "react";

import { useProvidersCurrentEmployee } from "~/hooks";

import { Link, Icon } from "~/components/atoms";

type PropsType = {
  employeeId: string;
};

export const DmLink: FC<PropsType> = ({ employeeId }: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  if (currentEmployee?.id === employeeId) return null;

  return (
    <Link
      to={`/employees/${employeeId}/direct_message_rooms`}
      color="gray"
      className="bg-white w-max mt-3 inline-flex items-center text-xs rounded text-secondary-600 font-semibold border border-solid border-secondary-400 px-2 py-1.5 space-x-1"
    >
      <Icon icon="ioMailOutline" size="1.25rem" />
      <span>DMを送る</span>
    </Link>
  );
};
