import { MouseEvent, ChangeEvent, FormEvent, useState } from "react";

import { toDateString } from "~/libs";

import {
  useInput,
  useArray,
  useApisMembersMeetingNotesIndex,
  useDateRange,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
} from "~/hooks";

import {
  ApiMembersMeetingNotesIndexRequestType,
  MeetingNoteType,
  OptionType,
  RangeDatePropsType,
  SingleValueType,
} from "~/domains";

type ReturnType = {
  meetingNotes: MeetingNoteType[];
  hasNextPage?: boolean;
  keyword: string;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  selectableDropdownHasBookmarkIds: OptionType[];
  selectedDropdownHasBookmarkId?: SingleValueType<OptionType>;
  postDate?: Date;
  setPostDateSearch: (date?: Date) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  deleteIndexItem: (index: number) => void;
  unshiftItem: (item: MeetingNoteType) => void;
  updateIndexItem: (item: MeetingNoteType, index: number) => void;
  findAndRemove: (func: (item: MeetingNoteType) => boolean) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDropdownHasBookmarkId: (value: SingleValueType<OptionType>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

type PropsTYpe = {
  defaultPostDate?: Date;
};

export const useSearchMembersMeetingNotes = ({
  defaultPostDate,
}: PropsTYpe = {}): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const [postDate, setPostDate] = useState(defaultPostDate);
  const [
    {
      statusValue: hasBookmark,
      selectedDropdownCompleteStatusId: selectedDropdownHasBookmarkId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasBookmarkIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasBookmarkId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "ブックマークあり",
      false: "ブックマークなし",
    },
  });
  const [params, setParams] =
    useState<ApiMembersMeetingNotesIndexRequestType>();

  const {
    items: meetingNotes,
    setItems: setMeetingNotes,
    deleteIndexItem,
    unshiftItem,
    updateIndexItem,
    findAndRemove,
  } = useArray<MeetingNoteType>([]);

  const { fetchNextPage, hasNextPage, isFetching } =
    useApisMembersMeetingNotesIndex({
      params,
      config: {
        onSuccess: (data) => {
          setMeetingNotes(data.pages.map((page) => page.meetingNotes).flat());
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      postDate: postDate ? toDateString(postDate) : "",
      postDateFrom: startDate ? toDateString(startDate) : "",
      postDateTo: endDate ? toDateString(endDate) : "",
      hasBookmark: hasBookmark,
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDateRange([null, null]);
    onChangeDropdownHasBookmarkId(null);
    setParams(undefined);
    setFalseRightSidebarMain();
  };

  const setPostDateSearch = (date?: Date) => {
    setPostDate(date);
    setParams({
      ...params,
      postDate: postDate ? toDateString(postDate) : "",
    });
  };

  return {
    meetingNotes,
    hasNextPage,
    isLoading: isFetching,
    startDate,
    endDate,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    keyword,
    postDate,
    setPostDateSearch,
    fetchNextPage,
    onChangeDropdownHasBookmarkId,
    onChangeDateRange,
    unshiftItem,
    updateIndexItem,
    deleteIndexItem,
    findAndRemove,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  };
};
