import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  id: string;
  body: {
    token: string;
  };
};

type ResponseType = ResponseMessageType & {
  email: string;
};

const request = ({ id, body }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseType>(`/api/members/profile/new_emails/${id}`, {
      employeeAuthenticationEmail: body,
    })
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersProfilesNewEmailsUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseType, RequestDataType> => {
  return useMutation(request, { ...options });
};
