import { SlideNavigationType } from "~/components/molecules";

type ReturnType = {
  navigations: SlideNavigationType[];
};

type PropsType = {
  employeeId: string;
  businessSectionId?: string;
  selectedArray: [boolean, boolean];
};

export const useManagersEmployeeReviewsNavigation = ({
  employeeId,
  businessSectionId,
  selectedArray,
}: PropsType): ReturnType => {
  const navigations = [
    {
      label: "メモ",
      href: `/managers/employees/${employeeId}/employee_memo_reviews?business_section_id=${businessSectionId}`,
      selected: selectedArray[0],
    },
    {
      label: "特性",
      href: `/managers/employees/${employeeId}/employee_competency_reviews?business_section_id=${businessSectionId}`,
      selected: selectedArray[1],
    },
  ];

  return {
    navigations: navigations,
  };
};
