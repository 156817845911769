import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApisPartnerConsolesCompaniesIndexResponseType } from "~/domains";

const request =
  async (): Promise<ApisPartnerConsolesCompaniesIndexResponseType> => {
    const response =
      await new ApiClient().get<ApisPartnerConsolesCompaniesIndexResponseType>(
        "/api/partner_consoles/companies",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisPartnerConsolesCompaniesIndex = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApisPartnerConsolesCompaniesIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisPartnerConsolesCompaniesBusinessSectionRelationEmployeesIndex",
    ],
    queryFn: () => request(),
  });
};
