import React, { FC } from "react";

import { Link } from "~/components/atoms";

import { ApiMembersUnreadNotificationCountsType } from "~/domains";

type PropsType = {
  label: string;
  href: string;
  breadCrumbText: string;
  unreadNotification?: ApiMembersUnreadNotificationCountsType;
};

export const getUnreadCount = (
  label: string,
  unreadNotification: ApiMembersUnreadNotificationCountsType | undefined,
) => {
  switch (label) {
    case "インサイト":
    case "フィードバック":
      return unreadNotification
        ? unreadNotification.deliveryNotifications
            .insightPostNotificationCount +
            unreadNotification.deliveryNotifications
              .focusChallengePostNotificationCount +
            unreadNotification.deliveryNotifications.kaizenIdeaNotificationCount
        : 0;
    case "KPI・KGI":
      return unreadNotification?.deliveryNotifications.kpiTermNotificationCount;
    case "ナレッジシェア":
      return unreadNotification
        ? unreadNotification.deliveryNotifications
            .knowledgeInsightPostNotificationCount +
            unreadNotification.deliveryNotifications
              .insightPostSharedEmployeeNotificationCount
        : 0;
    case "資料・ツール":
      return unreadNotification?.deliveryNotifications
        .documentFileCategoryNotificationCount;
    case "ロールプレイング":
      return 0;
    case "1on1・MTG":
      return 0;
    case "Q&A":
      return unreadNotification?.deliveryNotifications
        .questionAnswerCategoryNotificationCount;
    case "スキルマップ":
      return unreadNotification
        ? unreadNotification.deliveryNotifications
            .skillEvaluationStandardSummaryCategoryNotificationCount +
            unreadNotification.deliveryNotifications
              .skillEvaluationTermNotificationCount
        : 0;
    case "ブログ":
      return unreadNotification?.deliveryNotifications
        .salesConceptCategoryNotificationCount;
    default:
      return 0;
  }
};

export const SidebarLink: FC<PropsType> = ({
  breadCrumbText,
  unreadNotification,
  label,
  href,
}: PropsType) => {
  const unreadCount = getUnreadCount(label, unreadNotification);
  return (
    <Link
      key={label}
      to={href}
      className={`flex items-center justify-between space-x-1 font-medium px-4 py-2 relative ${
        label === breadCrumbText
          ? "bg-primary-900 text-white"
          : "hover:bg-primary-300"
      }`}
    >
      {label === breadCrumbText && (
        <div className="contents before:content-[''] before:w-[5px] before:h-full before:bg-primary-600 before:absolute before:top-0 before:left-0" />
      )}
      <span className="shrink truncate">{label}</span>
      {!!unreadCount && (
        <span className="w-6 h-6 flex items-center justify-center rounded-full bg-icon-heart text-white text-sm">
          {unreadCount}
        </span>
      )}
    </Link>
  );
};
