import { ChangeEvent } from "react";

import { useInput, useArray, useApisMembersEmployeesIndex } from "~/hooks";

import { AvatarAndNameEmployeeType } from "~/domains";

type EmployeeType = AvatarAndNameEmployeeType & {
  unreadDirectMessagesCount: number;
};

type ReturnType = {
  employees: EmployeeType[];
  hasNextPage?: boolean;
  isLoading: boolean;
  nameKeyword: string;
  fetchNextPage: () => void;
  refetch: () => void;
  onChangeNameKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const useSearchMembersEmployees = (): ReturnType => {
  const { items: employees, setItems: setEmployees } = useArray<EmployeeType>(
    [],
  );

  const [{ value: nameKeyword, onChange: onChangeNameKeyword }] = useInput("");
  const { fetchNextPage, refetch, hasNextPage, isLoading } =
    useApisMembersEmployeesIndex({
      params: {
        nameKeyword,
      },
      config: {
        onSuccess: (data) => {
          setEmployees(data.pages.map((page) => page.employees).flat());
        },
      },
    });

  return {
    isLoading,
    employees,
    hasNextPage,
    nameKeyword,
    fetchNextPage,
    refetch,
    onChangeNameKeyword,
  };
};
