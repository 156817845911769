import { MouseEvent, ChangeEvent, FormEvent, useState } from "react";

import {
  useInput,
  useArray,
  useMultipleDropdown,
  useApisManagersProvidingServicesIndex,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  ProvidingServiceWithBusinessSectionsType,
  OptionType,
  ApiManagersProvidingServicesIndexRequestType,
  MultiValueType,
} from "~/domains";

type ReturnType = {
  providingServices: ProvidingServiceWithBusinessSectionsType[];
  hasNextPage?: boolean;
  keyword: string;
  isLoading: boolean;
  allBusinessSectionsDistribution?: boolean;
  selectableDropdownProvidingServices: OptionType[];
  selectedDropdownProvidingServices: MultiValueType<OptionType>;
  selectableDropdownBusinessSections: OptionType[];
  selectedDropdownBusinessSections: MultiValueType<OptionType>;
  onChangeDropdownProvidingServices: (
    value: MultiValueType<OptionType>,
  ) => void;
  onChangeDropdownBusinessSections: (value: MultiValueType<OptionType>) => void;
  deleteIndexItem: (index: number) => void;
  findAndRemove: (
    func: (item: ProvidingServiceWithBusinessSectionsType) => boolean,
  ) => void;
  unshiftItem: (item: ProvidingServiceWithBusinessSectionsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  setAllBusinessSectionsDistribution: (value: boolean | undefined) => void;
};

export const useSearchManagersProvidingServices = (): ReturnType => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [allBusinessSectionsDistribution, setAllBusinessSectionsDistribution] =
    useState<boolean | undefined>();
  const [
    {
      dropdownValue: selectedDropdownProvidingServices,
      onChange: onChangeDropdownProvidingServices,
      dropdownSelectableValue: selectableDropdownProvidingServices,
      setSelectableOption: setSelectableDropdownProvidingServices,
    },
  ] = useMultipleDropdown();

  const [
    {
      dropdownValue: selectedDropdownBusinessSections,
      onChange: onChangeDropdownBusinessSections,
      dropdownSelectableValue: selectableDropdownBusinessSections,
    },
  ] = useMultipleDropdown({
    selectableValue: currentEmployee?.businessSections || [],
  });

  const [params, setParams] =
    useState<ApiManagersProvidingServicesIndexRequestType>();
  const {
    items: providingServices,
    setItems: setProvidingServices,
    deleteIndexItem,
    unshiftItem,
    findAndRemove,
  } = useArray<ProvidingServiceWithBusinessSectionsType>([]);

  const { fetchNextPage, hasNextPage, isFetching } =
    useApisManagersProvidingServicesIndex({
      params,
      config: {
        onSuccess: (data) => {
          setSelectableDropdownProvidingServices(
            data.pages[0]?.selectableProvidingServices || [],
          );
          setProvidingServices(
            data.pages.map((page) => page.providingServices).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      providingServiceIds: selectedDropdownProvidingServices.map(
        (option) => option.value,
      ),
      businessSectionIds: selectedDropdownBusinessSections.map(
        (option) => option.value,
      ),
      allBusinessSectionsDistribution,
    });
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownBusinessSections([]);
    onChangeDropdownProvidingServices([]);
    setParams({});
    setAllBusinessSectionsDistribution(undefined);
  };

  return {
    providingServices,
    hasNextPage,
    isLoading: isFetching,
    allBusinessSectionsDistribution,
    selectableDropdownProvidingServices,
    selectedDropdownProvidingServices,
    selectableDropdownBusinessSections,
    selectedDropdownBusinessSections,
    keyword,
    fetchNextPage,
    onChangeDropdownProvidingServices,
    onChangeDropdownBusinessSections,
    unshiftItem,
    deleteIndexItem,
    findAndRemove,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
    setAllBusinessSectionsDistribution,
  };
};
