import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useRoleplayingConditionSlideNavigation,
  useApisMembersRoleplayingConditionsEdit,
} from "~/hooks";

import { EmployeeLayout } from "~/components/layouts";
import { NotFoundIndex } from "~/components/pages";
import { RoleplayingConditionsEditTemplate } from "~/components/templates";

export const MembersRoleplayingConditionsEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();

  const query = new URLSearchParams(location.search);

  const paramsDate = query.get("date");
  const { navigations } = useRoleplayingConditionSlideNavigation([
    true,
    false,
    false,
  ]);

  const { data: roleplayingData, isError } =
    useApisMembersRoleplayingConditionsEdit({
      id,
    });

  const breadCrumbItems = [
    { label: "ロールプレイング", href: "/roleplaying_conditions/new" },
    {
      label: "編集",
      href: `/roleplaying_conditions/${id}/edit?date=${paramsDate}`,
    },
  ];

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      {roleplayingData && (
        <RoleplayingConditionsEditTemplate
          fetchData={roleplayingData}
          navigations={navigations}
        />
      )}
    </EmployeeLayout>
  );
};
