import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersInsightPostBookmarkFoldersIndexResponseType } from "~/domains";

const request =
  async (): Promise<ApiMembersInsightPostBookmarkFoldersIndexResponseType> => {
    const response =
      await new ApiClient().get<ApiMembersInsightPostBookmarkFoldersIndexResponseType>(
        "/api/members/insight_post_bookmark_folders",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersInsightPostBookmarkFoldersIndex = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiMembersInsightPostBookmarkFoldersIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersInsightPostBookmarkFoldersIndex"],
    queryFn: () => request(),
  });
};
