import React, { FC } from "react";

import { getDay, isAfter, isBefore, getDate } from "date-fns";

import { getCalendarDates, toDateString } from "~/libs";

import { HoverTooltip } from "~/components/atoms";
import { Loading } from "~/components/molecules";

import { PostsCalendarType } from "~/domains";

type PropsType = {
  isLoading?: boolean;
  dailyActivity?: PostsCalendarType[];
  targetDate: Date;
  className?: string;
  isForRate: boolean;
};

export const OriginalCalendar: FC<PropsType> = ({
  dailyActivity,
  isLoading = false,
  targetDate,
  isForRate,
  className = "",
}: PropsType) => {
  const { calendar, startMonthDate, endMonthDate } =
    getCalendarDates(targetDate);

  const calendarBgColor = (date: Date) => {
    if (isBefore(date, startMonthDate) || isAfter(date, endMonthDate))
      return "";

    const hasActivity = dailyActivity?.find(
      (v) => v.date === toDateString(date),
    );
    if (!hasActivity) return "bg-calendar-50";
    if (isForRate) {
      if (hasActivity.postsCount >= 100) return "bg-calendar-800";
      if (hasActivity.postsCount >= 75) return "bg-calendar-600";
      if (hasActivity.postsCount >= 50) return "bg-calendar-400";
      if (hasActivity.postsCount >= 25) return "bg-calendar-200";
      return "bg-calendar-50";
    } else {
      if (hasActivity.postsCount <= 0) return "bg-calendar-50";
      if (hasActivity.postsCount <= 1) return "bg-calendar-200";
      if (hasActivity.postsCount <= 2) return "bg-calendar-400";
      if (hasActivity.postsCount <= 3) return "bg-calendar-600";
      return "bg-calendar-800";
    }
  };

  const tooltipText = (date: Date) => {
    const activity = dailyActivity?.find((v) => v.date === toDateString(date));
    return `${getDate(date)}日: ${activity?.postsCount || 0}${
      isForRate ? "%" : "件"
    }`;
  };

  const dayOfWeeks = ["月", "火", "水", "木", "金", "土", "日"];

  return (
    <div className={`relative ${className}`}>
      {isLoading && <Loading className="absolute inset-1/2" />}
      <table className="w-full table-auto border-separate border-spacing-1">
        <thead>
          <tr>
            {dayOfWeeks.map((dayOfWeek) => (
              <th className="px-1 pb-1 text-secondary-600" key={dayOfWeek}>
                {dayOfWeek}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {calendar.map((weekRow, weekIndex) => (
            <tr key={`week-${weekIndex}`}>
              {weekRow.map((date, dayIndex) => (
                <td
                  key={`day-${getDay(date)}-${dayIndex}-${weekIndex}`}
                  className={`rounded p-1 text-center
                    ${calendarBgColor(date)}`}
                >
                  <HoverTooltip
                    text={`${tooltipText(date)}`}
                    className="block"
                    textClassName="text-xs min-w-[4.2rem]"
                  >
                    <span className="block w-6 h-6" />
                  </HoverTooltip>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex space-x-1 items-center justify-end mt-1">
        <span className="text-xs">少ない</span>
        <span className="bg-calendar-50 w-4 h-4 rounded" />
        <span className="bg-calendar-200 w-4 h-4 rounded" />
        <span className="bg-calendar-400 w-4 h-4 rounded" />
        <span className="bg-calendar-600 w-4 h-4 rounded" />
        <span className="bg-calendar-800 w-4 h-4 rounded" />
        <span className="text-xs">多い</span>
      </div>
    </div>
  );
};
