import { ChangeEvent, FormEvent, MouseEvent, useState } from "react";

import {
  useInput,
  useMultipleDropdown,
  useArray,
  useApisMembersBusinessSectionsQuestionAnswerCategorySearchItemsIndex,
  useApisMembersBusinessSectionsQuestionAnswerCategoriesIndex,
  useProvidersEmployeeLayout,
} from "~/hooks";

import {
  ApiMembersQuestionAnswerCategoriesIndexRequestType,
  QuestionAnswerCategoryWithQuestionAnswerType,
  MultiValueType,
  OptionType,
  ID_ALL_TYPE,
} from "~/domains";

type ReturnType = {
  questionAnswerCategories: QuestionAnswerCategoryWithQuestionAnswerType[];
  questionContentKeyword: string;
  selectableQuestionAnswerCategories: OptionType[];
  selectedQuestionAnswerCategories: MultiValueType<OptionType>;
  selectableTags: OptionType[];
  selectedTags: MultiValueType<OptionType>;
  selectableProvidingServices: OptionType[];
  selectedProvidingServices: MultiValueType<OptionType>;
  hasNextPage?: boolean;
  onChangeQuestionAnswerCategories: (
    newValue: MultiValueType<OptionType>,
  ) => void;
  onChangeTags: (newValue: MultiValueType<OptionType>) => void;
  onChangeProvidingServices: (newValue: MultiValueType<OptionType>) => void;
  onChangeQuestionContentKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

type PropsType = {
  businessSectionId?: string;
  employeeRoleId: number;
};

export const useSearchMembersBusinessSectionsQuestionAnswerCategories = ({
  businessSectionId = "",
  employeeRoleId,
}: PropsType): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const { items, setItems } =
    useArray<QuestionAnswerCategoryWithQuestionAnswerType>([]);
  const [
    { value: questionContentKeyword, onChange: onChangeQuestionContentKeyword },
    setQuestionContentKeyword,
  ] = useInput("");
  const [
    {
      dropdownSelectableValue: selectableTags,
      dropdownValue: selectedTags,
      onChange: onChangeTags,
      setSelectableOption: setSelectableTags,
    },
  ] = useMultipleDropdown();
  const [
    {
      dropdownSelectableValue: selectableQuestionAnswerCategories,
      dropdownValue: selectedQuestionAnswerCategories,
      onChange: onChangeQuestionAnswerCategories,
      setSelectableOption: setSelectableQuestionAnswerCategories,
    },
  ] = useMultipleDropdown();
  const [
    {
      dropdownSelectableValue: selectableProvidingServices,
      dropdownValue: selectedProvidingServices,
      onChange: onChangeProvidingServices,
      setSelectableOption: setSelectableProvidingServices,
    },
  ] = useMultipleDropdown();

  const [params, setParams] =
    useState<ApiMembersQuestionAnswerCategoriesIndexRequestType>();

  useApisMembersBusinessSectionsQuestionAnswerCategorySearchItemsIndex({
    businessSectionId,
    config: {
      enabled: !!businessSectionId,
      onSuccess: (data) => {
        setSelectableTags(data.questionAnswerTags);
        setSelectableProvidingServices([
          ID_ALL_TYPE,
          ...data.providingServices,
        ]);
        setSelectableQuestionAnswerCategories(data.questionAnswerCategories);
      },
    },
  });

  const { fetchNextPage, hasNextPage } =
    useApisMembersBusinessSectionsQuestionAnswerCategoriesIndex({
      businessSectionId,
      params: { employeeRoleId, ...params },
      config: {
        enabled: !!businessSectionId,
        onSuccess: (data) => {
          setItems(
            data.pages.map((page) => page.questionAnswerCategories).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      questionContentKeyword,
      employeeRoleId,
      categoryIds: selectedQuestionAnswerCategories.map(
        (category) => category.value,
      ),
      providingServiceIds: selectedProvidingServices.map(
        (service) => service.value,
      ),
      tagIds: selectedTags.map((tag) => tag.value),
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setQuestionContentKeyword("");
    onChangeProvidingServices([]);
    onChangeTags([]);
    onChangeQuestionAnswerCategories([]);
    setParams(undefined);
    setFalseRightSidebarMain();
  };

  return {
    questionAnswerCategories: items,
    questionContentKeyword,
    hasNextPage,
    selectableTags,
    selectedTags,
    selectableQuestionAnswerCategories,
    selectedQuestionAnswerCategories,
    selectableProvidingServices,
    selectedProvidingServices,
    onChangeQuestionAnswerCategories,
    onChangeProvidingServices,
    onChangeTags,
    fetchNextPage,
    onChangeQuestionContentKeyword,
    onSearchSubmit,
    onConditionReset,
  };
};
