import React, { FC, useState, FormEvent } from "react";

import { toast } from "react-toastify";

import {
  useBoolean,
  useEditorState,
  useMultiDivisionMultiSectionDropdown,
  useClipboard,
  useProvidersCurrentEmployee,
  useApisManagersKnowledgeInsightPostsDestroy,
  useApisManagersKnowledgeInsightPostsUpdate,
  useApisManagersKnowledgeInsightPostsEdit,
  useEditorStateLinkContentGetter,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import {
  KnowledgeInsightPostDetail,
  KnowledgeInsightPostForm,
  PostHeader,
} from "~/components/organisms";

import {
  KnowledgeInsightPostType,
  PostDetailMenuItemType,
  PreviewWithFileType,
} from "~/domains";

import { Href } from "~/constants/postUrl";

type PropsType = {
  isHighlight?: boolean;
  openComment: (id: string) => void;
  knowledgeInsightPost: KnowledgeInsightPostType;
  deleteItem?: () => void;
  updateItem?: (newItem: KnowledgeInsightPostType) => void;
};

export const KnowledgeInsightPostDetailWithEditForm: FC<PropsType> = ({
  openComment,
  deleteItem,
  updateItem,
  knowledgeInsightPost,
  isHighlight = false,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const { clipboardToCopy } = useClipboard();
  const [
    {
      value: editorState,
      onChange: onEditorStateChange,
      jsonContent: editorJsonContent,
    },
  ] = useEditorState(knowledgeInsightPost.content);
  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: onChangeAllBusinessSectionsDistribution,
  } = useBoolean(knowledgeInsightPost.allBusinessSectionsDistribution);
  const {
    isChecked: isEditOpen,
    setTrue: setEditOpen,
    setFalse: setEditClose,
  } = useBoolean(false);
  const { fetchedData } = useEditorStateLinkContentGetter({
    watchEditorState: editorState,
    isWatch: isEditOpen,
  });
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const { data: editKnowledgeInsightPost } =
    useApisManagersKnowledgeInsightPostsEdit({
      id: knowledgeInsightPost.id,
      config: {
        enabled: isEditOpen,
      },
    });
  const { mutate: updateKnowledgeInsightPost, isLoading: isSubmitting } =
    useApisManagersKnowledgeInsightPostsUpdate();
  const { mutate: deleteKnowledgeInsightPost } =
    useApisManagersKnowledgeInsightPostsDestroy();

  const dropdownField = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    defaultDivisions:
      editKnowledgeInsightPost?.knowledgeInsightPost.businessDivisions,
    defaultSections:
      editKnowledgeInsightPost?.knowledgeInsightPost.businessSections,
  });

  const deleteKnowledgeInsightPostRequest = () => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;
    deleteKnowledgeInsightPost(
      {
        id: knowledgeInsightPost.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          deleteItem?.();
        },
      },
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      quotePostId: fetchedData?.id,
      content: editorJsonContent,
      attachFiles: files,
      allBusinessSectionsDistribution,
      businessSectionIds: dropdownField.selectedSections.map(
        (section) => section.id,
      ),
    };
    updateKnowledgeInsightPost(
      {
        id: knowledgeInsightPost.id,
        body,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          updateItem?.(data.knowledgeInsightPost);
          setEditClose();
        },
      },
    );
  };

  const menuItems: PostDetailMenuItemType[] = [
    {
      icon: "ioCopyOutline",
      text: "リンクをコピー",
      onClick: () =>
        clipboardToCopy(
          `${location.origin}${Href.KnowledgeInsightPost(
            knowledgeInsightPost.id,
          )}`,
        ),
      disabled: false,
    },
    {
      icon: "ioChatbubbleEllipsesOutline",
      text: "リプライする",
      onClick: () => openComment(knowledgeInsightPost.id),
      disabled: false,
    },
    {
      icon: "ioCreateOutline",
      text: "編集する",
      onClick: setEditOpen,
      disabled: !knowledgeInsightPost.editable,
    },
    {
      icon: "ioTrashOutline",
      text: "削除する",
      onClick: () => deleteKnowledgeInsightPostRequest(),
      disabled: !knowledgeInsightPost.editable,
    },
  ];

  const cancelItems = [
    {
      text: "編集をキャンセル",
      onClick: setEditClose,
      disabled: !knowledgeInsightPost.editable,
    },
  ];

  return (
    <div>
      {isEditOpen ? (
        <PostDetailWrapper skipHoverStyle>
          <PostHeader
            miniText={knowledgeInsightPost.createdAt}
            postedEmployee={knowledgeInsightPost.employee}
            menuItems={cancelItems}
          />
          <KnowledgeInsightPostForm
            isSubmitting={isSubmitting}
            mentions={editKnowledgeInsightPost?.mentionableEmployees || []}
            files={files}
            onChangeFiles={setFiles}
            editorState={editorState}
            readOnly={false}
            setEditorState={onEditorStateChange}
            blockQuote={fetchedData}
            dropdownField={{
              ...dropdownField,
              allBusinessSectionsDistribution,
              onChangeAllBusinessSectionsDistribution,
              currentEmployeeRole: currentEmployee?.employeeRole,
            }}
            handleSubmit={handleSubmit}
            required
            className="mt-8"
          />
        </PostDetailWrapper>
      ) : (
        <KnowledgeInsightPostDetail
          knowledgeInsightPost={knowledgeInsightPost}
          menuItems={menuItems}
          openComment={() => openComment(knowledgeInsightPost.id)}
          isHighlight={!isEditOpen && isHighlight}
        />
      )}
    </div>
  );
};
