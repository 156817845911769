import React, { FC } from "react";

import {
  useApisMembersAnalysisEmployeeSurveySubmissionsCountCalendarsIndex,
  usePrevNextSelectMonth,
} from "~/hooks";

import { ActivityOriginalCalendar } from "~/components/molecules";

type PropsType = {
  className?: string;
};

export const EmployeeSurveySubmissionsCountCalendar: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  const {
    targetDate,
    targetDateString,
    handlePrevMonthClick,
    handleNextMonthClick,
  } = usePrevNextSelectMonth();
  const { data, isLoading } =
    useApisMembersAnalysisEmployeeSurveySubmissionsCountCalendarsIndex({
      params: {
        targetDate: targetDateString,
      },
    });

  console.log({ data });

  return (
    <ActivityOriginalCalendar
      isLoading={isLoading}
      dailyActivity={data?.employeeSurveySubmissionsCalendar}
      targetDate={targetDate}
      onPrevMonthClick={handlePrevMonthClick}
      onNextMonthClick={handleNextMonthClick}
      isForRate={false}
      className={className}
    />
  );
};
