import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiMembersRoleplayingConditionMutateType,
  RawDraftContentState,
} from "~/domains";

type RequestDataType = {
  id: string;
  body: {
    title: string;
    content: RawDraftContentState;
    postDate: string;
    participationEmployeeIds: string[];
    providingServiceId?: string;
    skillEvaluationStandardSummaryCategoryId?: string;
    roleplayingConditionTemplateId?: string;
  };
};

const request = ({ id, body }: RequestDataType) => {
  return new ApiClient()
    .put<ApiMembersRoleplayingConditionMutateType>(
      `/api/members/roleplaying_conditions/${id}`,
      {
        roleplayingCondition: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersRoleplayingConditionsUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiMembersRoleplayingConditionMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
