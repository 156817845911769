import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersMeetingNoteTemplateMutateType,
  ApiManagersMeetingNoteTemplateMutateRequestType,
} from "~/domains";

type RequestDataType = {
  id: string;
  body: ApiManagersMeetingNoteTemplateMutateRequestType;
};

const request = ({ id, body }: RequestDataType) => {
  return new ApiClient()
    .put<ApiManagersMeetingNoteTemplateMutateType>(
      `/api/managers/meeting_note_templates/${id}`,
      {
        meetingNoteTemplate: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersMeetingNoteTemplatesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersMeetingNoteTemplateMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
