import update from "immutability-helper";
import { toast } from "react-toastify";

import {
  useApisManagersEmployeeSurveysDisplayOrdersUpdate,
  useApisManagersEmployeeSurveysEmployeeSurveyQuestionsCreate,
  useApisManagersEmployeeSurveysEmployeeSurveyQuestionsDestroy,
  useApisManagersEmployeeSurveysEmployeeSurveyQuestionsUpdate,
  useArray,
} from "~/hooks";

import {
  EmployeeSurveyQuestionOptionType,
  EmployeeSurveyQuestionType,
  OptionType,
  QuestionTypeType,
  SingleValueType,
  TextAreaType,
  TextFieldType,
} from "~/domains";

export type FormEmployeeSurveyQuestionType = Pick<
  EmployeeSurveyQuestionType,
  "id" | "employeeSurveyId" | "question" | "displayOrder" | "file"
> & {
  questionType: Pick<QuestionTypeType, "id" | "name">;
  employeeSurveyQuestionOptions?: Pick<
    EmployeeSurveyQuestionOptionType,
    "option" | "point"
  >[];
  isNew?: boolean;
  newFile?: File;
  isReadOnly?: boolean;
};

type OnChangeQuestionPropsType = {
  employeeSurveyQuestion: FormEmployeeSurveyQuestionType;
  newValue: string;
};

type OnChangeQuestionTypePropsType = {
  employeeSurveyQuestion: FormEmployeeSurveyQuestionType;
  newValue: SingleValueType<OptionType>;
};

type OnChangeFilePropsType = {
  employeeSurveyQuestion: FormEmployeeSurveyQuestionType;
  newValue: File;
};

type ReturnType = {
  isSubmitting: boolean;
  employeeSurveyQuestions: FormEmployeeSurveyQuestionType[];
  onChangeQuestion: ({
    employeeSurveyQuestion,
    newValue,
  }: OnChangeQuestionPropsType) => void;
  onChangeFile: ({
    employeeSurveyQuestion,
    newValue,
  }: OnChangeFilePropsType) => void;
  onChangeQuestionType: ({
    employeeSurveyQuestion,
    newValue,
  }: OnChangeQuestionTypePropsType) => void;
  addEmployeeSurveyQuestion: () => void;
  removeEmployeeSurveyQuestion: (
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType,
  ) => void;
  saveEmployeeSurveyQuestion: (
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType,
  ) => void;
  onChangeSetNotReadOnly: (
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType,
  ) => void;
  onChangeSetReadOnly: (
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType,
  ) => void;
  changeDisplayOrder: (beforeIndex: number, index: number) => void;
  addEmployeeSurveyQuestionOption: (
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType,
  ) => void;
  removeEmployeeSurveyQuestionOption: ({
    employeeSurveyQuestion,
    index,
  }: {
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType;
    index: number;
  }) => void;
  onChangeEmployeeSurveyQuestionOptionText: ({
    employeeSurveyQuestion,
    index,
    newValue,
  }: {
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType;
    index: number;
    newValue: string;
  }) => void;
  onChangeEmployeeSurveyQuestionOptionPoint: ({
    employeeSurveyQuestion,
    index,
    newValue,
  }: {
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType;
    index: number;
    newValue: number;
  }) => void;
};

type PropsType = {
  employeeSurveyId: string;
  employeeSurveyQuestions: FormEmployeeSurveyQuestionType[];
};

export function useEmployeeSurveyQuestionForms({
  employeeSurveyId,
  employeeSurveyQuestions,
}: PropsType): ReturnType {
  const { mutate: deleteEmployeeSurveyQuestion } =
    useApisManagersEmployeeSurveysEmployeeSurveyQuestionsDestroy();
  const { mutate: createEmployeeSurveyQuestion, isLoading: isCreating } =
    useApisManagersEmployeeSurveysEmployeeSurveyQuestionsCreate();
  const { mutate: updateEmployeeSurveyQuestion, isLoading: isUpdating } =
    useApisManagersEmployeeSurveysEmployeeSurveyQuestionsUpdate();
  const { mutate: putEmployeeSurveyDisplayOrder } =
    useApisManagersEmployeeSurveysDisplayOrdersUpdate();

  const defaultItemFormat = (id: string, displayOrder: number) => {
    return {
      id: id,
      employeeSurveyId,
      question: "",
      questionType: {
        id: TextFieldType.id,
        name: TextFieldType.name,
      },
      file: { name: "", url: "", contentType: "image/jpeg" },
      employeeSurveyQuestionOptions: [],
      newFile: undefined,
      isNew: true,
      isReadOnly: false,
      displayOrder,
    };
  };

  const { items, findAndReplace, pushItem, findAndRemove, setItems } =
    useArray<FormEmployeeSurveyQuestionType>(
      employeeSurveyQuestions.length
        ? employeeSurveyQuestions.map((employeeSurveyQuestion) => ({
            ...employeeSurveyQuestion,
            employeeSurveyQuestionOptions:
              employeeSurveyQuestion.employeeSurveyQuestionOptions || [],
            isNew: false,
            isReadOnly: true,
          }))
        : [defaultItemFormat("1", 1)],
    );

  const addItem = () => {
    const order = Math.max(...items.map((item) => item.displayOrder)) + 1;
    pushItem(defaultItemFormat(order.toString(), order));
  };

  const changeDisplayOrder = (beforeIndex: number, newIndex: number) => {
    const targetEmployeeSurveyQuestion = items[beforeIndex];
    if (!targetEmployeeSurveyQuestion) return;
    const newItems = update(items, {
      $splice: [
        [beforeIndex, 1],
        [newIndex, 0, targetEmployeeSurveyQuestion],
      ],
    });

    putEmployeeSurveyDisplayOrder(
      {
        employeeSurveyId,
        body: generateOrderParams(newItems),
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setItems(newItems);
        },
      },
    );
  };

  const generateOrderParams = (newItems: FormEmployeeSurveyQuestionType[]) => {
    return newItems
      .filter((item) => !item.isNew)
      .map((item, index) => ({
        id: item.id.toString(),
        displayOrder: index + 1,
      }));
  };

  const removeEmployeeSurveyQuestion = (
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType,
  ) => {
    employeeSurveyQuestion.isNew
      ? findAndRemove((item) => item.id === employeeSurveyQuestion.id)
      : deleteEmployeeSurveyQuestion(
          {
            employeeSurveyId,
            id: employeeSurveyQuestion.id,
          },
          {
            onSuccess: (data) => {
              findAndRemove((item) => item.id === employeeSurveyQuestion.id);
              toast(data.message);
            },
          },
        );
  };

  const saveEmployeeSurveyQuestion = (
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType,
  ) => {
    employeeSurveyQuestion.isNew
      ? handleCreateEmployeeSurveyQuestion(employeeSurveyQuestion)
      : handleUpdateEmployeeSurveyQuestion(employeeSurveyQuestion);
  };

  const handleCreateEmployeeSurveyQuestion = (
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType,
  ) => {
    createEmployeeSurveyQuestion(
      {
        employeeSurveyId,
        body: generateParams(employeeSurveyQuestion),
      },
      {
        onSuccess: (data) => {
          onSuccessAction({
            employeeSurveyQuestion,
            successMessage: data.message,
            newEmployeeSurveyQuestionId: data.employeeSurveyQuestion.id,
          });
        },
      },
    );
  };

  const handleUpdateEmployeeSurveyQuestion = (
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType,
  ) => {
    updateEmployeeSurveyQuestion(
      {
        employeeSurveyId,
        id: employeeSurveyQuestion.id,
        body: generateParams(employeeSurveyQuestion),
      },
      {
        onSuccess: (data) => {
          onSuccessAction({
            employeeSurveyQuestion,
            successMessage: data.message,
            newEmployeeSurveyQuestionId: data.employeeSurveyQuestion.id,
          });
        },
      },
    );
  };

  const addEmployeeSurveyQuestionOption = (
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType,
  ) => {
    const newObject = {
      ...employeeSurveyQuestion,
      employeeSurveyQuestionOptions: [
        ...(employeeSurveyQuestion.employeeSurveyQuestionOptions || []),
        {
          option: "",
          point: 0,
        },
      ],
    };

    findAndReplace(newObject, (item) => item.id === employeeSurveyQuestion.id);
  };

  const removeEmployeeSurveyQuestionOption = ({
    employeeSurveyQuestion,
    index,
  }: {
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType;
    index: number;
  }) => {
    const newObject = {
      ...employeeSurveyQuestion,
      employeeSurveyQuestionOptions:
        employeeSurveyQuestion.employeeSurveyQuestionOptions?.filter(
          (_, i) => i !== index,
        ),
    };

    findAndReplace(newObject, (item) => item.id === employeeSurveyQuestion.id);
  };

  const onChangeEmployeeSurveyQuestionOptionText = ({
    employeeSurveyQuestion,
    index,
    newValue,
  }: {
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType;
    index: number;
    newValue: string;
  }) => {
    const newObject = {
      ...employeeSurveyQuestion,
      employeeSurveyQuestionOptions:
        employeeSurveyQuestion.employeeSurveyQuestionOptions?.map(
          (option, i) =>
            i === index ? { ...option, option: newValue } : option,
        ),
    };

    findAndReplace(newObject, (item) => item.id === employeeSurveyQuestion.id);
  };

  const onChangeEmployeeSurveyQuestionOptionPoint = ({
    employeeSurveyQuestion,
    index,
    newValue,
  }: {
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType;
    index: number;
    newValue: number;
  }) => {
    const newObject = {
      ...employeeSurveyQuestion,
      employeeSurveyQuestionOptions:
        employeeSurveyQuestion.employeeSurveyQuestionOptions?.map(
          (option, i) =>
            i === index ? { ...option, point: newValue } : option,
        ),
    };

    findAndReplace(newObject, (item) => item.id === employeeSurveyQuestion.id);
  };

  const onSuccessAction = ({
    employeeSurveyQuestion,
    successMessage,
    newEmployeeSurveyQuestionId,
  }: {
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType;
    successMessage: string;
    newEmployeeSurveyQuestionId: string;
  }) => {
    toast(successMessage);
    const newObject = {
      ...employeeSurveyQuestion,
      id: newEmployeeSurveyQuestionId,
      isNew: false,
      isReadOnly: true,
    };

    findAndReplace(newObject, (item) => item.id === employeeSurveyQuestion.id);
  };

  const generateParams = (
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType,
  ) => {
    return {
      question: employeeSurveyQuestion.question,
      displayOrder: employeeSurveyQuestion.displayOrder,
      file: employeeSurveyQuestion.newFile,
      questionTypeId: employeeSurveyQuestion.questionType.id,
      employeeSurveyQuestionOptions:
        employeeSurveyQuestion.employeeSurveyQuestionOptions?.map((option) => ({
          option: option.option,
          point: option.point,
        })),
    };
  };

  const onChangeQuestion = ({
    employeeSurveyQuestion,
    newValue,
  }: OnChangeQuestionPropsType) => {
    const newObject = { ...employeeSurveyQuestion, question: newValue };

    findAndReplace(newObject, (item) => item.id === employeeSurveyQuestion.id);
  };

  const onChangeQuestionType = ({
    employeeSurveyQuestion,
    newValue,
  }: OnChangeQuestionTypePropsType) => {
    const questionType = {
      id: parseInt(newValue?.value || "1"),
      name: newValue?.label,
    } as QuestionTypeType;
    if (
      questionType.id === TextFieldType.id ||
      questionType.id === TextAreaType.id
    ) {
      const newObject = {
        ...employeeSurveyQuestion,
        questionType,
        employeeSurveyQuestionOptions: [],
      };
      findAndReplace(
        newObject,
        (item) => item.id === employeeSurveyQuestion.id,
      );
    } else {
      const newObject = {
        ...employeeSurveyQuestion,
        questionType,
        employeeSurveyQuestionOptions: [{ option: "", point: 0 }],
      };
      findAndReplace(
        newObject,
        (item) => item.id === employeeSurveyQuestion.id,
      );
    }
  };

  const onChangeSetNotReadOnly = (
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType,
  ) => {
    const newObject = { ...employeeSurveyQuestion, isReadOnly: false };

    findAndReplace(newObject, (item) => item.id === employeeSurveyQuestion.id);
  };

  const onChangeSetReadOnly = (
    employeeSurveyQuestion: FormEmployeeSurveyQuestionType,
  ) => {
    const newObject = { ...employeeSurveyQuestion, isReadOnly: true };

    findAndReplace(newObject, (item) => item.id === employeeSurveyQuestion.id);
  };

  const onChangeFile = ({
    employeeSurveyQuestion,
    newValue,
  }: OnChangeFilePropsType) => {
    const newObject = {
      ...employeeSurveyQuestion,
      file: fileToNewFile(newValue),
      newFile: newValue,
    };

    findAndReplace(newObject, (item) => item.id === employeeSurveyQuestion.id);
  };

  const fileToNewFile = (file: File) => {
    return {
      url: URL.createObjectURL(file),
      name: file.name,
      contentType: file.type,
    };
  };

  return {
    isSubmitting: isCreating || isUpdating,
    employeeSurveyQuestions: items,
    onChangeQuestion,
    addEmployeeSurveyQuestion: addItem,
    removeEmployeeSurveyQuestion,
    saveEmployeeSurveyQuestion,
    changeDisplayOrder,
    onChangeFile,
    onChangeQuestionType,
    onChangeSetNotReadOnly,
    onChangeSetReadOnly,
    addEmployeeSurveyQuestionOption,
    removeEmployeeSurveyQuestionOption,
    onChangeEmployeeSurveyQuestionOptionText,
    onChangeEmployeeSurveyQuestionOptionPoint,
  };
}
