import React, { FC } from "react";

type PropsType = {
  className?: string;
  totalPoint: number;
  point: number;
  title: string;
};

export const InnerTitleCountDisplayBox: FC<PropsType> = ({
  totalPoint,
  point,
  title,
  className = "",
}: PropsType) => {
  return (
    <div
      className={`border rounded w-full h-40 p-2 grid content-between ${className}`}
    >
      <h4 className="text-center text-sm truncate">{title}</h4>
      <div className="text-center text-7xl font-medium">{point}</div>
      <div className="text-right text-lg">
        /<span className="ml-1">{totalPoint}</span>
      </div>
    </div>
  );
};
