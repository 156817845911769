import React, { FC } from "react";

import { CheckBox } from "~/components/atoms";

type PropsType = {
  message: string;
  className?: string;
};

export const CompletedMarkMessage: FC<PropsType> = ({
  message,
  className = "",
}: PropsType) => {
  return (
    <div className={`flex items-center ${className}`}>
      <CheckBox
        readOnly
        checked
        name="focusComplete"
        className="!accent-icon-heart w-4 h-4"
      />
      <p className="text-icon-heart text-sm ml-2 font-medium">{message}</p>
    </div>
  );
};
