import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { useApisManagersMeetingNoteTemplatesEdit } from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { MeetingNoteTemplateForm } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const ManagersMeetingNoteTemplatesEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data, isError } = useApisManagersMeetingNoteTemplatesEdit({
    id,
  });

  const breadCrumbItems = [
    { label: "1on1・MTGテンプレ", href: "/managers/meeting_note_templates" },
    { label: "編集", href: `/managers/meeting_note_templates/${id}/edit` },
  ];

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        {data && (
          <MeetingNoteTemplateForm
            meetingNoteTemplate={data.meetingNoteTemplate}
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
