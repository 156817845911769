import React, { FC } from "react";

import { InfiniteScrollWithMoreButton } from "~/components/molecules";

import { NotificationDetail } from "./NotificationDetail";

import { ApiNotificationType } from "~/domains";

type PropsType = {
  nextFetchFunction: () => void;
  hasMore?: boolean;
  isLoading: boolean;
  items: ApiNotificationType[];
  noContentMessage: string;
};

export const NotificationDetailList: FC<PropsType> = ({
  nextFetchFunction,
  hasMore,
  isLoading,
  items,
  noContentMessage,
}: PropsType) => {
  return (
    <div className="miniTablet:mb-16">
      <InfiniteScrollWithMoreButton
        itemsLength={items.length}
        nextFetchFunction={nextFetchFunction}
        hasMore={hasMore}
        isLoading={isLoading}
        noContentMessage={noContentMessage}
        scrollClassName=""
      >
        {items.map((item) => (
          <NotificationDetail notification={item} key={item.id} />
        ))}
      </InfiniteScrollWithMoreButton>
    </div>
  );
};
