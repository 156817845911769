import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  body: {
    name: string;
  };
};

const request = ({ body }: RequestDataType) => {
  return new ApiClient()
    .post<ResponseMessageType>(
      "/api/members/knowledge_insight_post_bookmark_folders",
      {
        bookmarkFolder: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersKnowledgeInsightPostBookmarkFoldersCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
