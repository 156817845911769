import React, { FC, ChangeEvent, FormEvent, MouseEvent } from "react";

import { Button, Form, FormSubmitButton } from "~/components/atoms";
import { LabelWithTextArea } from "~/components/molecules";

type PropsType = {
  goal: {
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  };
  weakness: {
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  };
  strength: {
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  };
  competency: {
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  };
  strategy: {
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  };
  remark: {
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  };
  isSubmitting: boolean;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleReadonly: (e: MouseEvent<HTMLButtonElement>) => void;
  handleNotReadonly: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  isReadOnly?: boolean;
};

export const BusinessSectionCompetencyReviewForm: FC<PropsType> = ({
  goal,
  weakness,
  strength,
  competency,
  strategy,
  remark,
  isSubmitting,
  className,
  isReadOnly,
  handleSubmit,
  handleReadonly,
  handleNotReadonly,
}: PropsType) => {
  return (
    <Form className={`space-y-6 ${className}`} onSubmit={handleSubmit}>
      <LabelWithTextArea
        labelText="目標"
        name="goal"
        placeholder="目標"
        rows={6}
        value={goal.value}
        onChange={goal.onChange}
        disabled={isReadOnly || isSubmitting}
      />
      <LabelWithTextArea
        labelText="強み"
        name="strength"
        placeholder="強み"
        rows={6}
        value={strength.value}
        onChange={strength.onChange}
        disabled={isReadOnly || isSubmitting}
      />
      <LabelWithTextArea
        labelText="弱み"
        name="weakness"
        placeholder="弱み"
        rows={6}
        value={weakness.value}
        onChange={weakness.onChange}
        disabled={isReadOnly || isSubmitting}
      />
      <LabelWithTextArea
        labelText="特徴"
        name="competency"
        placeholder="特徴"
        rows={6}
        value={competency.value}
        onChange={competency.onChange}
        disabled={isReadOnly || isSubmitting}
      />
      <LabelWithTextArea
        labelText="方針"
        name="strategy"
        placeholder="方針"
        rows={6}
        value={strategy.value}
        onChange={strategy.onChange}
        disabled={isReadOnly || isSubmitting}
      />
      <LabelWithTextArea
        labelText="備考"
        name="remark"
        placeholder="備考"
        rows={6}
        value={remark.value}
        onChange={remark.onChange}
        disabled={isReadOnly || isSubmitting}
      />
      <div className={`flex items-center space-x-4 w-full ${className}`}>
        {isReadOnly ? (
          <Button
            text="新しく投稿する"
            onClick={handleNotReadonly}
            color="gray"
            outline
            className="w-full"
          />
        ) : (
          <>
            <Button
              text="キャンセル"
              onClick={handleReadonly}
              color="gray"
              outline
              className="w-full"
            />
            <FormSubmitButton
              color="primary"
              value="投稿する"
              isReadOnly={isSubmitting}
              className="w-full"
            />
          </>
        )}
      </div>
    </Form>
  );
};
