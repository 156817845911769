import { useState, useCallback } from "react";

import { convertToDropdownOption } from "~/utils";

import { useArray } from "~/hooks";

import { ParentType, OptionType, SingleValueType } from "~/domains";

import { useApisPartnerConsolesCompaniesIndex } from "~/hooks/apis/PartnerConsoles/Companies/useApisPartnerConsolesCompaniesIndex";

type PropsType = {
  defaultCompanyId?: string;
};

type ReturnType = [
  {
    company?: ParentType;
    dropdownValue?: SingleValueType<OptionType>;
    dropdownSelectableValue: OptionType[];
    onChangeCompany: (newValue: SingleValueType<OptionType> | null) => void;
  },
  (value?: ParentType) => void,
];

export const usePartnerConsolesCompaniesDropdown = ({
  defaultCompanyId = "",
}: PropsType = {}): ReturnType => {
  const [company, setCompany] = useState<ParentType>();
  const {
    items: dropdownSelectableValue,
    setItems: setDropdownSelectableValue,
  } = useArray<OptionType>();

  useApisPartnerConsolesCompaniesIndex({
    config: {
      onSuccess: (res) => {
        setDropdownSelectableValue(
          res.companies.map((val) => convertToDropdownOption(val)),
        );
        const defaultCompany = defaultCompanyId
          ? res.companies.find((val) => val.id === defaultCompanyId)
          : res.companies[0];
        setCompany(defaultCompany);
      },
    },
  });

  const convertToValue = useCallback((record?: SingleValueType<OptionType>) => {
    if (!record) return undefined;
    return {
      id: record.value,
      name: record.label,
    };
  }, []);

  const onChangeDropDown = useCallback(
    (newValue: SingleValueType<OptionType> | null) => {
      const val = newValue ? convertToValue(newValue) : undefined;
      setCompany(val);
    },
    [convertToValue],
  );

  const dropdownValue = company ? convertToDropdownOption(company) : undefined;

  return [
    {
      company,
      dropdownValue,
      dropdownSelectableValue,
      onChangeCompany: (newValue: SingleValueType<OptionType> | null) =>
        onChangeDropDown(newValue),
    },
    useCallback((value?: ParentType) => setCompany(value), []),
  ];
};
