import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiAnalyticsPlansQuestionAnswerCategoriesIndexResponseType,
  ApiAnalyticsPlansQuestionAnswerCategoriesIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiAnalyticsPlansQuestionAnswerCategoriesIndexRequestType;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApiAnalyticsPlansQuestionAnswerCategoriesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiAnalyticsPlansQuestionAnswerCategoriesIndexResponseType>(
      "/api/analytics_plans/question_answer_categories",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiAnalyticsPlansQuestionAnswerCategoriesIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisAnalyticsPlansQuestionAnswerCategoriesIndex = ({
  params,
  config = {},
}: PropsType = {}): UseQueryResult<ApiAnalyticsPlansQuestionAnswerCategoriesIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisAnalyticsPlansQuestionAnswerCategoriesIndex", params],
    queryFn: () => request({ params }),
  });
};
