import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersKnowledgeInsightPostSearchItemsIndexResponseType } from "~/domains";

type ParamsType = {
  employeeRoleId: number;
};

const request = async ({
  employeeRoleId,
}: ParamsType): Promise<ApiManagersKnowledgeInsightPostSearchItemsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersKnowledgeInsightPostSearchItemsIndexResponseType>(
      "/api/managers/knowledge_insight_post_search_items",
      { employeeRoleId },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  employeeRoleId: number;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersKnowledgeInsightPostSearchItemsIndex = ({
  employeeRoleId,
  config = {},
}: PropsType): UseQueryResult<ApiManagersKnowledgeInsightPostSearchItemsIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisManagersKnowledgeInsightPostSearchItemsIndex",
      employeeRoleId,
    ],
    queryFn: () => request({ employeeRoleId }),
  });
};
