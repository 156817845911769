import React, { useEffect, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { toDateString } from "~/libs";

import {
  useMeetingNoteSlideNavigation,
  useApisMembersMeetingNotesNew,
  useSearchMembersMeetingNotes,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  DatePicker,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";
import {
  MeetingNoteForm,
  MeetingNoteDetail,
  MeetingNoteDailyCountCalendar,
} from "~/components/organisms";

import {
  ApiMembersMeetingNoteMutateType,
  ResponseMessageType,
} from "~/domains";

export const MembersMeetingNotesNew: FC = () => {
  const query = new URLSearchParams(location.search);
  const paramsDate = query.get("date");

  const navigate = useNavigate();
  const { data: meetingNoteFetchData } = useApisMembersMeetingNotesNew();
  const {
    meetingNotes,
    hasNextPage,
    isLoading,
    postDate,
    setPostDateSearch,
    fetchNextPage,
    unshiftItem,
    updateIndexItem,
    deleteIndexItem,
  } = useSearchMembersMeetingNotes({
    defaultPostDate: paramsDate ? new Date(paramsDate) : new Date(),
  });
  const currentPostDate = postDate || new Date();
  const strPostDate = toDateString(currentPostDate);
  const { navigations } = useMeetingNoteSlideNavigation(currentPostDate, [
    true,
    false,
  ]);

  const postRequestOnSuccess = (data: ApiMembersMeetingNoteMutateType) => {
    toast(data.message);
    unshiftItem(data.meetingNote);
  };

  const putRequestOnSuccess = (
    index: number,
    data: ApiMembersMeetingNoteMutateType,
  ) => {
    updateIndexItem(data.meetingNote, index);
  };

  const deleteRequestOnSuccess = (index: number, data: ResponseMessageType) => {
    toast(data.message);
    deleteIndexItem(index);
  };

  useEffect(() => {
    navigate({
      pathname: window.location.pathname,
      search: `?date=${strPostDate}`,
    });
    setPostDateSearch(postDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postDate]);

  const breadCrumbItems = [
    { label: "1on1・MTG", href: `/meeting_notes/new?date=${strPostDate}` },
    { label: "入力", href: `/meeting_notes/new?date=${strPostDate}` },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <DatePicker
          selectDate={currentPostDate}
          onChange={setPostDateSearch}
          parentClassName="mt-6"
        />
        <MeetingNoteForm
          date={currentPostDate}
          className="mt-6"
          mutateRequestOnSuccess={postRequestOnSuccess}
          meetingNoteTemplates={meetingNoteFetchData?.meetingNoteTemplates}
          selectableEmployees={meetingNoteFetchData?.selectableEmployees}
        />
        <InfiniteScrollWithMoreButton
          itemsLength={meetingNotes.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isLoading}
          className="mt-9"
        >
          {meetingNotes.map((item, index) => (
            <MeetingNoteDetail
              key={item.id}
              meetingNote={item}
              defaultReadOnly
              selectableEmployees={meetingNoteFetchData?.selectableEmployees}
              putRequestOnSuccess={(data) => putRequestOnSuccess(index, data)}
              deleteRequestOnSuccess={(data) =>
                deleteRequestOnSuccess(index, data)
              }
            />
          ))}
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarTitle title="Information" />
        <MeetingNoteDailyCountCalendar className="mt-6 mx-4" />
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
