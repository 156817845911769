import React, { FC } from "react";

import {
  useApisMembersAnalysisFocusChallengeEmployeeEffortCompletesCountCalendarsIndex,
  usePrevNextSelectMonth,
} from "~/hooks";

import { ActivityOriginalCalendar } from "~/components/molecules";

type PropsType = {
  className?: string;
};

export const FocusChallengeEmployeeEffortCompletesCountCalendar: FC<
  PropsType
> = ({ className = "" }: PropsType) => {
  const {
    targetDate,
    targetDateString,
    handlePrevMonthClick,
    handleNextMonthClick,
  } = usePrevNextSelectMonth();
  const { data, isLoading } =
    useApisMembersAnalysisFocusChallengeEmployeeEffortCompletesCountCalendarsIndex(
      {
        params: {
          targetDate: targetDateString,
        },
      },
    );

  return (
    <ActivityOriginalCalendar
      isLoading={isLoading}
      dailyActivity={data?.focusChallengeEmployeeEffortCompletesCalendar}
      targetDate={targetDate}
      onPrevMonthClick={handlePrevMonthClick}
      onNextMonthClick={handleNextMonthClick}
      isForRate={false}
      className={className}
    />
  );
};
