import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisAdminOperatorsCompaniesBillingPeriodLogsMutateRequestType,
  ResponseMessageType,
} from "~/domains";

type RequestDataType = {
  companyId: string;
  body: ApisAdminOperatorsCompaniesBillingPeriodLogsMutateRequestType;
};

const request = ({ companyId, body }: RequestDataType) => {
  return new ApiClient()
    .post<ResponseMessageType>(
      `/api/admin_operators/companies/${companyId}/billing_period_logs`,
      { companyBillingPeriodLog: body },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisAdminOperatorsCompaniesBillingPeriodLogsCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType> => {
  return useMutation(request, { ...options });
};
