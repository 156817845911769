import React, { FC } from "react";

import { Link } from "~/components/atoms";

type PropsType = {
  items: {
    href: string;
    name: string;
    isActive: boolean;
  }[];
};

export const TabItemLinks: FC<PropsType> = ({ items }: PropsType) => {
  return (
    <div className="flex items-center space-x-4">
      {items.map((item, index) => (
        <Link
          key={index}
          to={item.href}
          className={`text-lg block
          ${
            item.isActive
              ? "font-semibold text-primary-600"
              : "text-secondary-600"
          }`}
        >
          {item.name}
        </Link>
      ))}
    </div>
  );
};
