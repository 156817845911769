import React, { FormEvent, useState, FC, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { toDateString } from "~/libs";

import {
  useBoolean,
  useDropdown,
  useEditorState,
  useInput,
  useApisMembersRoleplayingConditionsBookmarksCreate,
  useApisMembersRoleplayingConditionsBookmarksDestroy,
  useApisManagersRoleplayingConditionsCreate,
  useApisManagersRoleplayingConditionsUpdate,
  useRoleplayingEvaluationForms,
  useMultipleDropdown,
  useProvidersCurrentEmployee,
  useMultiDivisionMultiSectionDropdown,
} from "~/hooks";

import {
  Button,
  ButtonWithIcon,
  Form,
  FormSubmitButton,
} from "~/components/atoms";
import {
  AddFormFieldButton,
  LabelWithDropDownField,
  LabelWithMultipleDropdownField,
  LabelWithTextField,
  SelectItemModal,
  TemplateSelectButtonWithModal,
} from "~/components/molecules";
import {
  BlogEditor,
  MultiParentsWithMultiChildrenDropdownField,
} from "~/components/organisms";

import { RoleplayingEvaluationForm } from "./RoleplayingEvaluationForm";

import {
  ArchivedSkillEvaluationStandardSummaryCategoryType,
  AvatarAndNameEmployeeType,
  ProvidingServiceType,
  RoleplayingConditionTemplateType,
  RoleplayingConditionWithEvaluationsType,
  SkillEvaluationStandardSummaryCategoryType,
} from "~/domains";

type PropsType = {
  roleplayingCondition?: RoleplayingConditionWithEvaluationsType;
  providingServices: ProvidingServiceType[];
  employees: AvatarAndNameEmployeeType[];
  date: Date;
  className?: string;
  setSkillCategory?: (
    skillCategory?: SkillEvaluationStandardSummaryCategoryType,
  ) => void;
  skillCategory?: ArchivedSkillEvaluationStandardSummaryCategoryType;
  roleplayingConditionTemplates: RoleplayingConditionTemplateType[];
  skillEvaluationStandardSummaryCategories: SkillEvaluationStandardSummaryCategoryType[];
};

export const ManagersRoleplayingConditionForm: FC<PropsType> = ({
  roleplayingCondition,
  providingServices,
  employees,
  date,
  className = "",
  roleplayingConditionTemplates = [],
  skillEvaluationStandardSummaryCategories = [],
  setSkillCategory,
  skillCategory,
}: PropsType) => {
  const navigate = useNavigate();
  const { currentEmployee } = useProvidersCurrentEmployee();
  const {
    isChecked: isConditionReadOnly,
    setTrue: setConditionReadOnly,
    setFalse: setConditionNotReadOnly,
  } = useBoolean(false);
  const {
    isChecked: isSkillModalOpen,
    setTrue: skillModalOpen,
    setFalse: skillModalClose,
  } = useBoolean(false);
  const [editorState, setEditorState] = useEditorState(
    roleplayingCondition?.content,
  );
  const [providingService] = useDropdown({
    selectableValue: [{ id: "", name: "すべて" }, ...providingServices],
    initialValue: roleplayingCondition?.providingService || {
      id: "",
      name: "すべて",
    },
  });
  const [
    {
      dropdownSelectableValue: dropdownSelectableEmployees,
      dropdownValue: dropdownEmployees,
      onChange: onChangeDropdownEmployees,
    },
  ] = useMultipleDropdown({
    initialValue: roleplayingCondition
      ? roleplayingCondition.participationEmployees
      : [
          {
            id: currentEmployee?.id || "",
            name: `${currentEmployee?.lastName}${currentEmployee?.firstName}`,
          },
        ],
    selectableValue: employees,
  });
  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    defaultDivisions: roleplayingCondition?.businessDivisions,
    defaultSections: roleplayingCondition?.businessSections,
  });

  const [title] = useInput(roleplayingCondition?.title || "");
  const [selectedTemplate, setSelectedTemplate] = useState<
    | Pick<RoleplayingConditionTemplateType, "id" | "title" | "content">
    | undefined
  >(
    roleplayingCondition?.roleplayingConditionTemplate
      ? {
          id: roleplayingCondition.roleplayingConditionTemplate.id,
          title: roleplayingCondition.roleplayingConditionTemplate.title,
          content: roleplayingCondition.roleplayingConditionTemplate.content,
        }
      : undefined,
  );
  const {
    isChecked: bookmarked,
    setTrue: setBookmarkedTrue,
    setFalse: setBookmarkedFalse,
  } = useBoolean(roleplayingCondition?.bookmarked);

  const { mutate: roleplayingCreateRequest } =
    useApisManagersRoleplayingConditionsCreate();
  const { mutate: roleplayingUpdateRequest } =
    useApisManagersRoleplayingConditionsUpdate();

  const { mutate: bookmarkDoRequest } =
    useApisMembersRoleplayingConditionsBookmarksCreate();
  const { mutate: bookmarkDeleteDoRequest } =
    useApisMembersRoleplayingConditionsBookmarksDestroy();

  const {
    onChangeMyEvaluationGoodContent,
    onChangeMyEvaluationImproveContent,
    onChangeOtherUserEvaluationGoodContent,
    onChangeOtherUserEvaluationImproveContent,
    addRoleplayingEvaluation,
    removeRoleplayingEvaluation,
    saveRoleplayingEvaluation,
    setNotReadOnly,
    roleplayingEvaluations,
  } = useRoleplayingEvaluationForms({
    roleplayingConditionId: roleplayingCondition?.id || "",
    roleplayingEvaluations: roleplayingCondition?.roleplayingEvaluations || [],
    isManager: true,
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    roleplayingCondition
      ? handleRoleplayingConditionUpdate()
      : handleRoleplayingConditionCreate();
  };

  const handleRoleplayingConditionUpdate = () => {
    if (!roleplayingCondition) return;

    roleplayingUpdateRequest(
      {
        id: roleplayingCondition.id,
        body: generateParams(),
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          setConditionReadOnly();
        },
      },
    );
  };

  const handleRoleplayingConditionCreate = () => {
    roleplayingCreateRequest(
      {
        body: generateParams(),
      },
      {
        onSuccess: (data) => {
          navigate(
            `/managers/roleplaying_conditions/${data.roleplayingCondition.id}/edit?date=${data.roleplayingCondition.postDate}`,
          );
          toast(data.message);
          setConditionReadOnly();
        },
      },
    );
  };

  const generateParams = () => {
    return {
      title: title.value,
      content: editorState.jsonContent,
      postDate: toDateString(date),
      providingServiceId: providingService.value?.id,
      participationEmployeeIds: dropdownEmployees.map((v) => v.value),
      skillEvaluationStandardSummaryCategoryId: skillCategory?.id,
      roleplayingConditionTemplateId: selectedTemplate?.id,
      businessSectionIds: optionSelectedSections.map((section) => section.id),
    };
  };

  const handlePostBookmark = () => {
    if (!roleplayingCondition) return;

    bookmarkDoRequest(
      {
        roleplayingConditionId: roleplayingCondition.id,
      },
      {
        onSuccess: () => {
          setBookmarkedTrue();
        },
      },
    );
  };

  const handleDeleteBookmark = () => {
    if (!roleplayingCondition || !bookmarked) return;
    bookmarkDeleteDoRequest(
      {
        roleplayingConditionId: roleplayingCondition.id,
      },
      {
        onSuccess: () => {
          setBookmarkedFalse();
        },
      },
    );
  };

  const hasSkillCategories = Boolean(
    skillEvaluationStandardSummaryCategories.length,
  );

  const handleSkillCheck = (itemId?: string) => {
    if (!setSkillCategory) return;
    const item = skillEvaluationStandardSummaryCategories.find(
      (v) => v.id === itemId,
    );
    if (!item) {
      const result = confirm("既存のスキル評価は削除されますがよろしいですか?");
      if (!result) return;
    }

    setSkillCategory(item);
    skillModalClose();
  };

  const handleSkillModalOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    skillModalOpen();
  };

  const handleNotReadOnly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setConditionNotReadOnly();
  };

  return (
    <>
      <Form className={className} onSubmit={handleSubmit}>
        <MultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentLabel="部署"
          parentRequired
          parentsOnChange={onDivisionChange}
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childLabel="課"
          childrenOnChange={onSectionChange}
          childRequired
        />
        <LabelWithDropDownField
          isDisabled={isConditionReadOnly}
          labelText="提案商材"
          name="providingServiceId"
          options={providingService.dropdownSelectableValue}
          placeholder="提案商材"
          value={providingService.dropdownValue}
          onChange={providingService.onChange}
        />
        <LabelWithMultipleDropdownField
          labelText="参加者"
          name="employeeIds"
          required
          options={dropdownSelectableEmployees}
          value={dropdownEmployees}
          onChange={onChangeDropdownEmployees}
        />
        <LabelWithTextField
          disabled={isConditionReadOnly}
          required
          labelText="タイトル"
          type="text"
          name="title"
          value={title.value}
          onChange={title.onChange}
          placeholder="タイトル"
        />
        <div>
          <p className="text-secondary-600">ロールプレイング条件</p>
          <TemplateSelectButtonWithModal
            templates={roleplayingConditionTemplates || []}
            setEditorState={setEditorState}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        </div>

        <BlogEditor
          readOnly={isConditionReadOnly}
          labelText="内容"
          required
          editorState={editorState.value}
          setEditorState={editorState.onChange}
        />
        {hasSkillCategories && (
          <div>
            <p className="text-secondary-600">スキル評価方法</p>
            <Button
              readonly={isConditionReadOnly}
              onClick={handleSkillModalOpen}
              text={skillCategory?.name ?? "スキルマップを選択"}
              className="w-full miniTablet:w-1/2 mx-auto mt-1.5 font-semibold block"
              outline
              color={!isConditionReadOnly && skillCategory ? "primary" : "gray"}
            />
          </div>
        )}
        <div>
          {isConditionReadOnly ? (
            <Button
              text="編集"
              color="gray"
              outline
              className="w-full"
              onClick={handleNotReadOnly}
            />
          ) : (
            <FormSubmitButton value="保存" color="primary" className="w-full" />
          )}
        </div>
        <div className="mt-4">
          {roleplayingCondition &&
            (bookmarked ? (
              <ButtonWithIcon
                onClick={handleDeleteBookmark}
                icon={{
                  icon: "ioBookmark",
                  color: "text-yellow-300",
                }}
                srOnlyText="ブックマークを削除"
              />
            ) : (
              <ButtonWithIcon
                onClick={handlePostBookmark}
                icon={{
                  icon: "ioBookmarkOutline",
                  color: "text-secondary-600",
                }}
                srOnlyText="ブックマーク"
              />
            ))}
        </div>
      </Form>
      {roleplayingCondition && (
        <>
          {roleplayingEvaluations.map((evaluation, index) => (
            <RoleplayingEvaluationForm
              key={evaluation.id}
              roleplayingEvaluation={evaluation}
              className="space-y-6 mt-9"
              onChangeMyEvaluationGoodContent={onChangeMyEvaluationGoodContent}
              onChangeMyEvaluationImproveContent={
                onChangeMyEvaluationImproveContent
              }
              onChangeOtherUserEvaluationGoodContent={
                onChangeOtherUserEvaluationGoodContent
              }
              onChangeOtherUserEvaluationImproveContent={
                onChangeOtherUserEvaluationImproveContent
              }
              handleSave={saveRoleplayingEvaluation}
              removeRoleplayingEvaluation={removeRoleplayingEvaluation}
              setNotReadOnly={setNotReadOnly}
              numberSpot={index + 1}
            />
          ))}
          <AddFormFieldButton
            onClick={addRoleplayingEvaluation}
            className="mt-6"
          />
        </>
      )}
      {isSkillModalOpen && (
        <SelectItemModal
          onClose={skillModalClose}
          items={skillEvaluationStandardSummaryCategories.map((v) => ({
            id: v.id,
            title: v.name,
          }))}
          handleSelectItemId={handleSkillCheck}
          title="スキルマップを選択"
        />
      )}
    </>
  );
};
