import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisAdminOperatorsCompaniesContractPlanLogsMutateRequestType,
  ResponseMessageType,
} from "~/domains";

type RequestDataType = {
  companyId: string;
  body: ApisAdminOperatorsCompaniesContractPlanLogsMutateRequestType;
};

const request = ({ companyId, body }: RequestDataType) => {
  return new ApiClient()
    .post<ResponseMessageType>(
      `/api/admin_operators/companies/${companyId}/contract_plan_logs`,
      { companyContractPlanLog: body },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisAdminOperatorsCompaniesContractPlanLogsCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType> => {
  return useMutation(request, { ...options });
};
