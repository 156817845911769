import React, { FC } from "react";

import { calculateMaxPoint } from "~/utils";

import { PickSkillEvaluationStandardType, useBoolean } from "~/hooks";

import { BorderedTitle, Icon } from "~/components/atoms";

import { ArchivedSkillEvaluationStandardSummaryDetail } from "./ArchivedSkillEvaluationStandardSummaryDetail";

import { ArchivedSkillEvaluationStandardSummaryCategoryWithSummariesType } from "~/domains";

type PropsType = {
  archivedSkillEvaluationStandardSummaryCategory: ArchivedSkillEvaluationStandardSummaryCategoryWithSummariesType;
  selectSkillEvaluationStandard?: (
    newSkillEvaluationStandard: PickSkillEvaluationStandardType,
  ) => void;
  className?: string;
  memberSelectedSkillEvaluationStandardIds?: string[];
  managerSelectedSkillEvaluationStandardIds?: string[];
};

export const ArchivedSkillEvaluationStandardSummaryCategoryDetail: FC<
  PropsType
> = ({
  archivedSkillEvaluationStandardSummaryCategory,
  className = "",
  selectSkillEvaluationStandard,
  memberSelectedSkillEvaluationStandardIds = [],
  managerSelectedSkillEvaluationStandardIds = [],
}: PropsType) => {
  const { isChecked, toggle } = useBoolean(false);

  const { name, archivedSkillEvaluationStandardSummaries } =
    archivedSkillEvaluationStandardSummaryCategory;
  const maxPoints = archivedSkillEvaluationStandardSummaries.map((summary) =>
    calculateMaxPoint(
      summary.archivedSkillEvaluationStandards.map(
        (standard) => standard.point,
      ),
    ),
  );
  const sumMaxPoint = maxPoints.reduce((sum, element) => sum + element, 0);
  return (
    <div className={className}>
      <BorderedTitle title={name} />
      <div className="mt-6 space-y-6">
        <button
          type="button"
          onClick={toggle}
          className="text-lg border-b border-secondary-400 pb-2 font-semibold flex items-center justify-end w-full text-secondary-600"
        >
          <div className="flex items-center space-x-2">
            <span>/ {sumMaxPoint}</span>
            <Icon
              icon={isChecked ? "ioRemoveOutline" : "ioAdd"}
              size="1.25rem"
            />
          </div>
        </button>
        {archivedSkillEvaluationStandardSummaries.map((summary) => (
          <ArchivedSkillEvaluationStandardSummaryDetail
            key={summary.id}
            isOpen={isChecked}
            archivedSkillEvaluationStandardSummary={summary}
            selectSkillEvaluationStandard={selectSkillEvaluationStandard}
            memberSelectedSkillEvaluationStandardIds={
              memberSelectedSkillEvaluationStandardIds
            }
            managerSelectedSkillEvaluationStandardIds={
              managerSelectedSkillEvaluationStandardIds
            }
          />
        ))}
      </div>
    </div>
  );
};
