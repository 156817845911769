import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersKnowledgeInsightPostEditType } from "~/domains";

type ParamsType = {
  id: string;
};

const request = async ({
  id,
}: ParamsType): Promise<ApiManagersKnowledgeInsightPostEditType> => {
  const response =
    await new ApiClient().get<ApiManagersKnowledgeInsightPostEditType>(
      `/api/managers/knowledge_insight_posts/${id}/edit`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  id: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersKnowledgeInsightPostsEdit = ({
  id,
  config = {},
}: PropsType): UseQueryResult<ApiManagersKnowledgeInsightPostEditType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersKnowledgeInsightPostsEdit", id],
    queryFn: () => request({ id }),
  });
};
