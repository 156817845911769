import React, { ChangeEvent, MouseEvent, FormEvent, FC } from "react";

import { sumPoints } from "~/utils";

import {
  FormKpiObjectiveType,
  FormEmployeeKpiObjectiveType,
  useBoolean,
} from "~/hooks/";

import {
  Form,
  Button,
  FormSubmitButton,
  ButtonWithIcon,
} from "~/components/atoms";
import {
  FolderIconWithTitle,
  LabelWithNumberField,
  LabelWithTextField,
} from "~/components/molecules";

type PropsType = {
  isSubmitting: boolean;
  defaultAccordionOpen: boolean;
  kpiObjective: FormKpiObjectiveType;
  onChangeTitle: (newValue: string) => void;
  onChangeObjectivePerformance: (newValue: number | "") => void;
  onChangeUnitName: (newValue: string) => void;
  onChangeEmployeeCount: (
    employeeKpiObjective: FormEmployeeKpiObjectiveType,
    newCount: number | "",
  ) => void;
  setNotReadOnly: () => void;
  setReadOnly: () => void;
  removeKpiObjective: () => void;
  saveKpiObjective: () => void;
  employeeCountEvenUp: () => void;
  handleDuplicate: () => void;
  onChangeSetEmployee: (
    employeeKpiObjective: FormEmployeeKpiObjectiveType,
  ) => void;
  onChangeUnsetEmployee: (
    employeeKpiObjective: FormEmployeeKpiObjectiveType,
  ) => void;
};

export const KpiObjectiveForm: FC<PropsType> = ({
  isSubmitting,
  defaultAccordionOpen,
  kpiObjective,
  setNotReadOnly,
  removeKpiObjective,
  saveKpiObjective,
  onChangeTitle,
  onChangeObjectivePerformance,
  onChangeUnitName,
  onChangeEmployeeCount,
  employeeCountEvenUp,
  handleDuplicate,
  setReadOnly,
  onChangeSetEmployee,
  onChangeUnsetEmployee,
}: PropsType) => {
  const accordionOpen = useBoolean(defaultAccordionOpen);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveKpiObjective();
  };

  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    removeKpiObjective();
  };

  const handleEvenUp = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    employeeCountEvenUp();
  };

  const handleNotReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setNotReadOnly();
  };

  const handleOnDuplicate = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    handleDuplicate();
  };

  const handleSetEmployee = (
    e: MouseEvent<HTMLButtonElement>,
    employeeKpiObjective: FormEmployeeKpiObjectiveType,
  ) => {
    e.preventDefault();
    onChangeSetEmployee(employeeKpiObjective);
  };

  const handleUnSetEmployee = (
    e: MouseEvent<HTMLButtonElement>,
    employeeKpiObjective: FormEmployeeKpiObjectiveType,
  ) => {
    e.preventDefault();
    onChangeUnsetEmployee(employeeKpiObjective);
  };
  const employeeKpiObjectives = kpiObjective.employeeKpiObjectives || [];
  const employeeSumPoints = sumPoints(
    employeeKpiObjectives.map(
      (employeeObjective) => employeeObjective.objectivePerformance || 0,
    ),
  );

  return (
    <Form className="space-y-4" onSubmit={onSubmit}>
      <FolderIconWithTitle
        title={kpiObjective.title}
        onClick={accordionOpen.toggle}
      />
      {accordionOpen.isChecked && (
        <div className="space-y-6 ml-9">
          <LabelWithTextField
            labelText="タイトル"
            type="text"
            name="objectiveTitle"
            placeholder="タイトル"
            required
            disabled={kpiObjective.isReadOnly}
            value={kpiObjective.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeTitle(e.target.value)
            }
          />
          <div className="grid grid-cols-5 gap-4">
            <LabelWithNumberField
              labelText="目標数字"
              name="objectivePerformance"
              placeholder="目標数字"
              required
              disabled={kpiObjective.isReadOnly}
              className="col-span-4"
              max={999999999999}
              min={0}
              value={kpiObjective.objectivePerformance}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangeObjectivePerformance(
                  isNaN(parseInt(e.target.value))
                    ? ""
                    : parseInt(e.target.value),
                )
              }
            />
            <LabelWithTextField
              labelText="単位"
              type="text"
              name="unitName"
              placeholder="単位"
              disabled={kpiObjective.isReadOnly}
              required
              value={kpiObjective.unitName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangeUnitName(e.target.value)
              }
            />
          </div>
          {Boolean(kpiObjective.objectivePerformance) &&
            Boolean(employeeKpiObjectives.length) && (
              <div className="flex items-center space-x-4">
                <Button
                  text="数字を均等に割り振る"
                  onClick={handleEvenUp}
                  color="gray"
                  outline
                  readonly={kpiObjective.isReadOnly}
                />
                <p
                  className={`font-semibold ${
                    (kpiObjective.objectivePerformance || 0) >
                      employeeSumPoints && "text-icon-heart"
                  } `}
                >
                  {`${employeeSumPoints.toLocaleString()} / ${kpiObjective.objectivePerformance.toLocaleString()}`}
                </p>
              </div>
            )}
          {Boolean(employeeKpiObjectives.length) && (
            <div className="grid grid-cols-2 gap-4">
              {employeeKpiObjectives.map((employeeKpiObjective) => (
                <div
                  key={employeeKpiObjective.employee.id}
                  className="flex items-center"
                >
                  <LabelWithNumberField
                    className="w-full"
                    labelClassName="truncate"
                    disabled={
                      kpiObjective.isReadOnly || employeeKpiObjective.isReadOnly
                    }
                    labelText={employeeKpiObjective.employee.name}
                    name="objectivePerformance"
                    placeholder="目標数字"
                    max={999999999999}
                    min={0}
                    value={employeeKpiObjective.objectivePerformance}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onChangeEmployeeCount(
                        employeeKpiObjective,
                        isNaN(parseInt(e.target.value))
                          ? ""
                          : parseInt(e.target.value),
                      )
                    }
                  />
                  {employeeKpiObjective.isReadOnly ? (
                    <ButtonWithIcon
                      onClick={(e) =>
                        handleSetEmployee(e, employeeKpiObjective)
                      }
                      icon={{
                        icon: "ioAdd",
                        size: "1.5rem",
                      }}
                      srOnlyText="追加"
                      disabled={kpiObjective.isReadOnly}
                    />
                  ) : (
                    <ButtonWithIcon
                      onClick={(e) =>
                        handleUnSetEmployee(e, employeeKpiObjective)
                      }
                      icon={{
                        icon: "ioCloseOutline",
                        size: "1.5rem",
                      }}
                      srOnlyText="削除"
                      disabled={kpiObjective.isReadOnly}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          <div className="flex items-center space-x-4 justify-end w-full">
            {kpiObjective.isReadOnly ? (
              <>
                <Button
                  text="複製"
                  onClick={handleOnDuplicate}
                  color="gray"
                  outline
                  size="sm"
                />
                <Button
                  text="削除"
                  onClick={handleRemove}
                  color="gray"
                  outline
                  size="sm"
                />
                <Button
                  text="編集"
                  onClick={handleNotReadonly}
                  color="gray"
                  outline
                  size="sm"
                />
              </>
            ) : (
              <>
                <Button
                  text="キャンセル"
                  onClick={setReadOnly}
                  color="gray"
                  outline
                  size="sm"
                />
                <FormSubmitButton
                  value="保存"
                  color="primary"
                  outline
                  size="sm"
                  isReadOnly={kpiObjective.isReadOnly || isSubmitting}
                />
              </>
            )}
          </div>
        </div>
      )}
    </Form>
  );
};
