import React, { FC } from "react";

import { BarChart, ModalWrapper } from "~/components/atoms";

type PropsType = {
  onClose: () => void;
  title: string;
  className?: string;
  graphData: { name: string; value: number; stackValue?: number }[];
  handleBarClick?: (index?: { index: number }) => void;
};

export const BarChartModal: FC<PropsType> = ({
  onClose,
  title,
  graphData,
  handleBarClick,
  className = "",
}: PropsType) => {
  return (
    <ModalWrapper onClose={onClose} headerTitle={title} className={className}>
      <div className="mt-4 rounded-md">
        <BarChart perforManceData={graphData} onClick={handleBarClick} />
      </div>
    </ModalWrapper>
  );
};
