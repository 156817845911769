import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import {
  ApiMembersKaizenIdeasMutateType,
  PreviewWithFileType,
  RawDraftContentState,
} from "~/domains";

type RequestDataType = {
  body: {
    title: string;
    content: RawDraftContentState;
    clientCompanyName: string;
    providingServiceId: string | undefined;
    priorityRatingId: number;
    attachFiles: PreviewWithFileType[];
  };
};

const request = ({ body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ kaizenIdea: body });
  return new ApiClient()
    .post<ApiMembersKaizenIdeasMutateType>(
      "/api/members/kaizen_ideas",
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersKaizenIdeasCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiMembersKaizenIdeasMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
