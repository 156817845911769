import React, { FC, FormEvent } from "react";

import { toast } from "react-toastify";

import {
  useInput,
  useApisAdminOperatorAuthenticatePasswordUpdate,
} from "~/hooks";

import { AdminOperatorLayout } from "~/components/layouts";
import { SettingPassword } from "~/components/organisms";

export const AdminOperatorsSettingPasswordShow: FC = () => {
  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: "/admin_operators",
    },
    {
      label: "パスワード編集",
      href: "/admin_operators/setting/password",
    },
  ];
  const [{ value: password, onChange: onChangePassword }] = useInput("");
  const [
    { value: passwordConfirmation, onChange: onChangePasswordConfirmation },
  ] = useInput("");

  const { mutate, isLoading } =
    useApisAdminOperatorAuthenticatePasswordUpdate();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      {
        body: {
          password,
          passwordConfirmation,
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
        },
        onError: () => {
          toast.error("更新に失敗しました");
        },
      },
    );
  };

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      <SettingPassword
        onSubmit={handleSubmit}
        onChangePassword={onChangePassword}
        onChangePasswordConfirmation={onChangePasswordConfirmation}
        isLoading={isLoading}
        className="mt-8"
      />
    </AdminOperatorLayout>
  );
};
