import React, { FC, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { useInput, useApisManagersBusinessDivisionsCreate } from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { BusinessDivisionForm } from "~/components/organisms";

export const ManagersBusinessDivisionsNew: FC = () => {
  const navigate = useNavigate();

  const [
    { value: businessDivisionName, onChange: onChangeBusinessDivisionName },
  ] = useInput("");
  const { mutate, isLoading: isSubmitting } =
    useApisManagersBusinessDivisionsCreate();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = { name: businessDivisionName };
    mutate(
      {
        body,
      },
      {
        onSuccess: (res) => {
          navigate("/managers/business_divisions");
          toast(res.message);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "部署設定", href: "/managers/business_divisions" },
    { label: "新規作成", href: "/managers/business_divisions/new" },
  ];

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          <BusinessDivisionForm
            businessDivisionName={businessDivisionName}
            onChangeBusinessDivisionName={onChangeBusinessDivisionName}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
