import React, { FormEvent, FC } from "react";

import { FormSubmitButton, Form } from "~/components/atoms";
import {
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  LabelWithDropDownField,
  LabelWithMultipleDropdownField,
  RangeDatePicker,
  RangeDatePropsType,
} from "~/components/molecules";
import { TextEditor } from "~/components/organisms";

import {
  PreviewWithFileType,
  AllPublicStatus,
  OptionType,
  SingleValueType,
  MultiValueType,
  EditorStateType,
  AvatarAndNameEmployeeType,
} from "~/domains";

type PropsType = {
  isSubmitting: boolean;
  mentions: AvatarAndNameEmployeeType[];
  editorState: {
    value: EditorStateType;
    onChange: (value: EditorStateType) => void;
  };
  employees: {
    options: OptionType[];
    dropdownValue?: MultiValueType<OptionType>;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
  publicStatus: {
    dropdownValue?: SingleValueType<OptionType>;
    onChange: (newValue: SingleValueType<OptionType>) => void;
  };
  files: PreviewWithFileType[];
  setFiles: (files: PreviewWithFileType[]) => void;
  dateRange: {
    startDate: Date | null;
    endDate: Date | null;
    onChange: (newValue: RangeDatePropsType) => void;
  };
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const ManagersFocusChallengesFormTemplate: FC<PropsType> = ({
  isSubmitting,
  mentions,
  editorState,
  employees,
  files,
  setFiles,
  dateRange,
  publicStatus,
  handleSubmit,
}: PropsType) => {
  return (
    <>
      <EmployeeLayoutMainContent withRightSidebar>
        <Form onSubmit={handleSubmit} className="space-y-6">
          <>
            <LabelWithMultipleDropdownField
              labelText="ユーザー"
              required
              name="employeeIds"
              options={employees.options}
              value={employees.dropdownValue}
              onChange={employees.onChange}
            />
            <TextEditor
              labelText="内容"
              readOnly={false}
              mentions={mentions}
              editorState={editorState.value}
              setEditorState={editorState.onChange}
              files={files}
              onChangeFiles={setFiles}
              required
            />
            <RangeDatePicker
              labelText="チャレンジ期間指定"
              required={true}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onChange={dateRange.onChange}
            />
            <LabelWithDropDownField
              labelText="ステータス"
              required
              name="publicStatus"
              options={AllPublicStatus.map((status) => ({
                value: status.id.toString(),
                label: status.name,
              }))}
              value={publicStatus.dropdownValue}
              onChange={publicStatus.onChange}
            />
            <FormSubmitButton
              color="primary"
              value="保存"
              className="w-full"
              isReadOnly={isSubmitting}
            />
          </>
        </Form>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </>
  );
};
