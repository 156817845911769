import React, { FC } from "react";

import { Link } from "~/components/atoms";

export type SlideNavigationType = {
  label: string;
  href: string;
  selected: boolean;
};

type PropsType = {
  navigations: SlideNavigationType[];
  className?: string;
};

export const SlideNavigation: FC<PropsType> = ({
  navigations,
  className = "",
}: PropsType) => {
  const calculateGridClass = () => {
    const gridColsCount = navigations.length;
    switch (gridColsCount) {
      case 2:
        return "grid-cols-2";
      case 3:
        return "grid-cols-3";
      default:
        return "grid-cols-3";
    }
  };

  return (
    <div
      className={`miniTablet:sticky top-header-offset bg-white z-layout grid text-center items-center ${calculateGridClass()} ${className}`}
    >
      {navigations.map((navigation, index) => (
        <Link
          key={`${navigation.label}-${index}`}
          to={navigation.href}
          className="border-b border-solid py-3.5 border-secondary-200 text-lg hover:bg-secondary-200"
        >
          <span
            className={`${
              navigation.selected
                ? "text-primary-600 pb-3.5 font-bold border-b-4 border-solid border-primary-600"
                : "text-secondary-600 font-medium"
            }`}
          >
            {navigation.label}
          </span>
        </Link>
      ))}
    </div>
  );
};
