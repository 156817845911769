import { MouseEvent, ChangeEvent, FormEvent, useState } from "react";

import { toDateString } from "~/libs";

import {
  useInput,
  useApisMembersRoleplayingConditionsIndex,
  useDateRange,
  useArray,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
} from "~/hooks";

import {
  ApiMembersRoleplayingConditionsIndexRequestType,
  OptionType,
  RangeDatePropsType,
  RoleplayingConditionType,
  SingleValueType,
} from "~/domains";

type ReturnType = {
  roleplayingConditions: RoleplayingConditionType[];
  keyword: string;
  hasNextPage?: boolean;
  startDate: Date | null;
  endDate: Date | null;
  selectableDropdownHasBookmarkIds: OptionType[];
  selectedDropdownHasBookmarkId?: SingleValueType<OptionType>;
  isLoading: boolean;
  selectableDropdownHasSkillEvaluationStandardSummaryCategoryIds: OptionType[];
  selectedDropdownHasSkillEvaluationStandardSummaryCategoryId?: SingleValueType<OptionType>;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDropdownHasBookmarkId: (value: SingleValueType<OptionType>) => void;
  onChangeDropdownHasSkillEvaluationStandardSummaryCategoryId: (
    value: SingleValueType<OptionType>,
  ) => void;
  findAndRemove: (func: (item: RoleplayingConditionType) => boolean) => void;
  fetchNextPage: () => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchMembersRoleplayingConditions = (): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const [
    {
      statusValue: hasBookmark,
      selectedDropdownCompleteStatusId: selectedDropdownHasBookmarkId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasBookmarkIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasBookmarkId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "ブックマークあり",
      false: "ブックマークなし",
    },
  });
  const [
    {
      statusValue: hasSkillEvaluationStandardSummaryCategory,
      selectedDropdownCompleteStatusId:
        selectedDropdownHasSkillEvaluationStandardSummaryCategoryId,
      selectableDropdownCompleteStatusIds:
        selectableDropdownHasSkillEvaluationStandardSummaryCategoryIds,
      onChangeDropdownCompleteStatusId:
        onChangeDropdownHasSkillEvaluationStandardSummaryCategoryId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "スキルマップ評価あり",
      false: "スキルマップ評価なし",
    },
  });

  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [params, setParams] =
    useState<ApiMembersRoleplayingConditionsIndexRequestType>();

  const { items, setItems, findAndRemove } = useArray<RoleplayingConditionType>(
    [],
  );

  const { fetchNextPage, hasNextPage, isFetching } =
    useApisMembersRoleplayingConditionsIndex({
      params,
      config: {
        onSuccess: (res) => {
          setItems(res.pages.map((page) => page.roleplayingConditions).flat());
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      postDateFrom: startDate ? toDateString(startDate) : "",
      postDateTo: endDate ? toDateString(endDate) : "",
      hasBookmark,
      hasSkillEvaluationStandardSummaryCategory,
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onChangeDateRange([null, null]);
    setKeyword("");
    onChangeDropdownHasBookmarkId(null);
    onChangeDropdownHasSkillEvaluationStandardSummaryCategoryId(null);
    setParams(undefined);
    setFalseRightSidebarMain();
  };

  return {
    roleplayingConditions: items,
    keyword,
    isLoading: isFetching,
    hasNextPage,
    startDate,
    endDate,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    selectableDropdownHasSkillEvaluationStandardSummaryCategoryIds,
    selectedDropdownHasSkillEvaluationStandardSummaryCategoryId,
    onChangeDateRange,
    onChangeDropdownHasBookmarkId,
    onChangeDropdownHasSkillEvaluationStandardSummaryCategoryId,
    fetchNextPage,
    findAndRemove,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  };
};
