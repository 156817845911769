import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useApisMembersKaizenIdeasKaizenIdeaRepliesIndex,
  useApisMembersKaizenIdeasNew,
  useApisMembersKaizenIdeasShow,
  useBoolean,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  KaizenIdeaDetailWithEditForm,
  KaizenIdeaRepliesWithKaizenIdea,
} from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const MembersKaizenIdeasShow: FC = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();
  const breadCrumbItems = [
    { label: "改善・相談", href: "/kaizen_ideas" },
    { label: "詳細", href: `/kaizen_ideas/${id}` },
  ];

  const { isChecked: replayOpen, toggle } = useBoolean(true);
  const { data, isError } = useApisMembersKaizenIdeasShow({
    id,
  });
  const { data: fetchNewData } = useApisMembersKaizenIdeasNew();
  const { data: kaizenIdeaRepliesIndexData, refetch: repliesRefetch } =
    useApisMembersKaizenIdeasKaizenIdeaRepliesIndex({
      kaizenIdeaId: id,
    });

  if (isError) return <NotFoundIndex />;

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
          {data && (
            <KaizenIdeaDetailWithEditForm
              kaizenIdea={data.kaizenIdea}
              deleteItem={() => navigate("/kaizen_ideas")}
              isHighlight={data.kaizenIdea.id === id}
              openComment={toggle}
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          {replayOpen && kaizenIdeaRepliesIndexData && (
            <>
              <RightSidebarTitle title="リプライ" />
              <KaizenIdeaRepliesWithKaizenIdea
                className="mt-6"
                kaizenIdea={kaizenIdeaRepliesIndexData.kaizenIdea}
                mentions={fetchNewData?.mentionableEmployees || []}
                kaizenIdeaReplies={
                  kaizenIdeaRepliesIndexData.kaizenIdeaReplies || []
                }
                refetch={repliesRefetch}
              />
            </>
          )}
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
