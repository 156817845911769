import React, { FC, FormEvent, MouseEvent } from "react";

import {
  Avatar,
  Button,
  Form,
  FormSubmitButton,
  Icon,
} from "~/components/atoms";
import { PostedFilePreviews } from "~/components/molecules";
import { PostFooter, TextEditor } from "~/components/organisms";

import {
  EditorStateType,
  PreviewWithFileType,
  AvatarAndNameEmployeeType,
  PostFooterType,
  AttachFileType,
} from "~/domains";

type PropsType = {
  isSubmitting: boolean;
  completable: boolean;
  readOnly: boolean;
  editorState: {
    value: EditorStateType;
    onChange: (editorState: EditorStateType) => void;
  };
  avatarUrl: string;
  mentions: AvatarAndNameEmployeeType[];
  files: PreviewWithFileType[];
  attachedFiles: AttachFileType[];
  footers: PostFooterType[];
  handleNotReadonly: (e: MouseEvent<HTMLButtonElement>) => void;
  handleReadonly: (e: MouseEvent<HTMLButtonElement>) => void;
  onChangeFiles: (files: PreviewWithFileType[]) => void;
  onEffortingSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onCompleteSubmit: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

export const FocusChallengeEmployeeEffortForm: FC<PropsType> = ({
  isSubmitting,
  readOnly,
  completable,
  avatarUrl,
  editorState,
  mentions,
  files,
  attachedFiles,
  handleNotReadonly,
  handleReadonly,
  onChangeFiles,
  onEffortingSubmit,
  onCompleteSubmit,
  footers,
  className = "",
}: PropsType) => {
  return (
    <>
      <Form className={className} onSubmit={onEffortingSubmit}>
        <div className="flex items-start">
          <Avatar src={avatarUrl} size="md" />
          <TextEditor
            editorState={editorState.value}
            setEditorState={editorState.onChange}
            readOnly={readOnly}
            mentions={mentions}
            files={files}
            onChangeFiles={onChangeFiles}
            className="w-full ml-4"
            required
          />
        </div>
        {readOnly && Boolean(attachedFiles.length) && (
          <PostedFilePreviews
            files={attachedFiles}
            className="w-full pl-16 mt-3"
          />
        )}
        <div className="mt-6 pl-16 w-full flex justify-between items-center">
          {readOnly && <PostFooter iconWithCounters={footers} />}
          {completable && (
            <>
              {readOnly ? (
                <Button
                  text="編集"
                  color="gray"
                  outline
                  onClick={handleNotReadonly}
                />
              ) : (
                <div className="w-full">
                  <div className="flex space-x-4">
                    <FormSubmitButton
                      value="一時保存"
                      color="primary"
                      className="w-full"
                      outline
                      size="sm"
                      isReadOnly={isSubmitting}
                    />
                    <Button
                      text="完了済み"
                      color="primary"
                      className="w-full"
                      size="sm"
                      onClick={onCompleteSubmit}
                      readonly={isSubmitting}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={handleReadonly}
                    className="mt-2"
                  >
                    <Icon
                      icon="ioCloseOutline"
                      color="text-secondary-600"
                      size="2rem"
                    />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </Form>
    </>
  );
};
