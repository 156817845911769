import React, { FC } from "react";

type PropsType = {
  className?: string;
};

export const PasswordRuleMessage: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  return (
    <ul className={`text-secondary-600 text-sm space-y-1 ${className}`}>
      <li>英字と数字をそれぞれ1つ以上含める必要があります</li>
      <li>ひらがな、カタカナ、漢字を含められません</li>
      <li>8文字以上である必要があります</li>
    </ul>
  );
};
