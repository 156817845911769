import React, { FC, FormEvent, ChangeEvent, MouseEvent } from "react";

import {
  Button,
  DropDownField,
  Form,
  FormSubmitButton,
  TextField,
} from "~/components/atoms";
import { RangeDatePropsType, TitleWithChild } from "~/components/molecules";
import { SelectDateRangeButtons } from "~/components/organisms";

import { OptionType, SingleValueType } from "~/domains";

type PropsType = {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  searchKeyword: {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  hasBookmark: {
    options: OptionType[];
    value: SingleValueType<OptionType> | undefined;
    onChange: (newValue: SingleValueType<OptionType>) => void;
  };
  date: {
    startDate: Date | null;
    endDate: Date | null;
    onChange: (value: RangeDatePropsType) => void;
  };
};

export const MeetingNoteSearchForm: FC<PropsType> = ({
  onSubmit,
  onConditionReset,
  searchKeyword,
  hasBookmark,
  date,
}: PropsType) => {
  return (
    <Form onSubmit={onSubmit} className="mt-6 space-y-6">
      <>
        <TitleWithChild title="期間指定">
          <SelectDateRangeButtons
            startDate={date.startDate}
            endDate={date.endDate}
            onChange={date.onChange}
            className="my-2"
          />
        </TitleWithChild>
        <TitleWithChild title="フリーワード">
          <TextField
            placeholder="キーワード"
            type="search"
            name="keyword"
            value={searchKeyword.value}
            onChange={searchKeyword.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="ブックマーク有無">
          <DropDownField
            name="hasBookmark"
            options={hasBookmark.options}
            value={hasBookmark.value}
            onChange={hasBookmark.onChange}
          />
        </TitleWithChild>
        <div className="space-x-4 flex">
          <Button
            text="リセット"
            color="gray"
            outline
            onClick={onConditionReset}
            className="w-full"
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full"
          />
        </div>
      </>
    </Form>
  );
};
