import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import { ApiManagersDocumentFileMutateType } from "~/domains";

type RequestDataType = {
  documentFileCategoryId: string;
  id: string;
  body: {
    name: string;
    displayOrder: number;
    attachFile?: File;
    thumbnailImage?: File;
    tagNames: string[];
  };
};

const request = ({ id, documentFileCategoryId, body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ documentFile: body });
  return new ApiClient()
    .put<ApiManagersDocumentFileMutateType>(
      `/api/managers/document_file_categories/${documentFileCategoryId}/document_files/${id}`,
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersDocumentFileCategoriesDocumentFilesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersDocumentFileMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
