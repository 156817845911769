import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import { ApiMembersKnowledgeInsightPostsShowType } from "~/domains";

type RequestDataType = {
  id: string;
};

const request = async ({
  id,
}: RequestDataType): Promise<ApiMembersKnowledgeInsightPostsShowType> => {
  const response =
    await new ApiClient().get<ApiMembersKnowledgeInsightPostsShowType>(
      `/api/members/knowledge_insight_posts/${id}`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  id: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersKnowledgeInsightPostsShow = ({
  id,
  config = {},
}: PropsType): UseQueryResult<ApiMembersKnowledgeInsightPostsShowType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersKnowledgeInsightPostsShow", id],
    queryFn: () => request({ id }),
    useErrorBoundary: (error) => {
      return error instanceof ApiResponseError && error.status >= 500;
    },
  });
};
