import React, { FC } from "react";

import {
  useBoolean,
  useFooters,
  useCounter,
  useApisMembersInsightPostCommentsLikesCreate,
  useApisMembersInsightPostCommentsLikesDestroy,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import {
  PostHeader,
  PostContent,
  InsightPostCommentsLikedEmployeesModal,
} from "~/components/organisms";

import { InsightPostCommentType, PostDetailMenuItemType } from "~/domains";

type PropsType = {
  insightPostComment: InsightPostCommentType;
  menuItems: PostDetailMenuItemType[];
  isHighlight?: boolean;
};

export const InsightPostCommentDetail: FC<PropsType> = ({
  insightPostComment,
  menuItems,
  isHighlight = false,
}: PropsType) => {
  const {
    isChecked: isLikedEmployeesModalOpen,
    setTrue: setLikedEmployeesModalOpen,
    setFalse: setLikedEmployeesModalClose,
  } = useBoolean(false);

  const {
    isChecked: liked,
    setTrue: setLiked,
    setFalse: setNotLiked,
  } = useBoolean(insightPostComment.liked);
  const {
    count: likeCount,
    increment: likeIncrement,
    decrement: likeDecrement,
  } = useCounter(insightPostComment.likesCount);

  const { mutate: likeCreateRequest } =
    useApisMembersInsightPostCommentsLikesCreate();
  const { mutate: likeDeleteRequest } =
    useApisMembersInsightPostCommentsLikesDestroy();

  const handleCreateLike = () => {
    likeCreateRequest(
      {
        insightPostCommentId: insightPostComment.id,
      },
      {
        onSuccess: () => {
          setLiked();
          likeIncrement();
        },
      },
    );
  };

  const handleDeleteLike = () => {
    likeDeleteRequest(
      {
        insightPostCommentId: insightPostComment.id,
      },
      {
        onSuccess: () => {
          setNotLiked();
          likeDecrement();
        },
      },
    );
  };

  const leftFooters = useFooters({
    like: {
      doneAlready: liked,
      undoOnClick: handleDeleteLike,
      doOnClick: handleCreateLike,
      textOnClick: setLikedEmployeesModalOpen,
      count: likeCount,
    },
  });

  return (
    <PostDetailWrapper isHighlight={isHighlight}>
      <PostHeader
        miniText={insightPostComment.createdAt}
        postedEmployee={insightPostComment.employee}
        menuItems={menuItems}
      />
      <PostContent
        content={insightPostComment.content}
        images={insightPostComment.attachFiles}
        footers={leftFooters.footers}
        rightFooters={[]}
        className="mt-2 pl-16"
        blockQuote={
          insightPostComment.insightPostCommentQuotePost
            ? {
                content: insightPostComment.insightPostCommentQuotePost.content,
                employee:
                  insightPostComment.insightPostCommentQuotePost.employee,
                files:
                  insightPostComment.insightPostCommentQuotePost.attachFiles,
                createdAt:
                  insightPostComment.insightPostCommentQuotePost.createdAt,
              }
            : undefined
        }
      />
      {isLikedEmployeesModalOpen && (
        <InsightPostCommentsLikedEmployeesModal
          insightPostCommentId={insightPostComment.id}
          onClose={setLikedEmployeesModalClose}
        />
      )}
    </PostDetailWrapper>
  );
};
