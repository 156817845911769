import React, { FC } from "react";

// NOTE: rounded-fullで画像が切れないように、余白のある画像を使用する
import Logo from "~/assets/images/logos/mobile_home_favicon.png";

import { Avatar } from "~/components/atoms";

import {
  AiEmployeeConversationSender,
  AiEmployeeConversationType,
} from "~/domains";

type PropsType = {
  className?: string;
  conversation: AiEmployeeConversationType;
  currentEmployeeAvatarUrl: string;
};

export const ChatDetail: FC<PropsType> = ({
  conversation,
  currentEmployeeAvatarUrl,
  className = "",
}: PropsType) => {
  return (
    <div className={`flex ${className}`}>
      {conversation.senderType.type ===
      AiEmployeeConversationSender.assistant.type ? (
        <Avatar src={Logo as string} size="sm" />
      ) : (
        <Avatar src={currentEmployeeAvatarUrl} size="sm" />
      )}
      <p className="whitespace-pre-wrap bg-secondary-200 rounded-xl ml-4 p-4">
        {conversation.message}
      </p>
    </div>
  );
};
