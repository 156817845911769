import React, { MouseEvent, useState, FC } from "react";

import { toDateString } from "~/libs";

import {
  useApisMembersMeetingNotesCreate,
  useApisMembersMeetingNotesUpdate,
  useEditorState,
  useInput,
  useMultipleDropdown,
} from "~/hooks";

import { Button } from "~/components/atoms";
import {
  LabelWithMultipleDropdownField,
  LabelWithTextField,
  PostedFilePreviews,
  TemplateSelectButtonWithModal,
} from "~/components/molecules";
import { TextEditor } from "~/components/organisms";

import {
  ApiMembersMeetingNoteMutateType,
  AvatarAndNameEmployeeType,
  MeetingNoteTemplateType,
  MeetingNoteType,
  PreviewWithFileType,
} from "~/domains";

type PropsType = {
  isReadOnly?: boolean;
  meetingNote?: MeetingNoteType;
  date: Date;
  className?: string;
  mutateRequestOnSuccess: (data: ApiMembersMeetingNoteMutateType) => void;
  meetingNoteTemplates?: MeetingNoteTemplateType[];
  selectableEmployees?: AvatarAndNameEmployeeType[];
};

export const MeetingNoteForm: FC<PropsType> = ({
  isReadOnly = false,
  meetingNote,
  date,
  className = "",
  mutateRequestOnSuccess,
  meetingNoteTemplates = [],
  selectableEmployees = [],
}: PropsType) => {
  const [editorState, setEditorState] = useEditorState(meetingNote?.content);
  const [title, setTitle] = useInput(meetingNote?.title || "");
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const [selectedTemplate, setSelectedTemplate] =
    useState<Pick<MeetingNoteTemplateType, "id" | "title" | "content">>();

  const [
    {
      dropdownValue: selectedEmployees,
      dropdownSelectableValue: dropdownSelectableEmployees,
      onChange: onChangeSelectableEmployees,
    },
  ] = useMultipleDropdown({
    initialValue: meetingNote?.participationEmployees,
    selectableValue: selectableEmployees,
  });

  const { mutate: postRequest, isLoading: isCreating } =
    useApisMembersMeetingNotesCreate();
  const { mutate: updateRequest, isLoading: isUpdating } =
    useApisMembersMeetingNotesUpdate();

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    meetingNote ? handleUpdate() : handleCreate();
  };

  const handleUpdate = () => {
    if (!meetingNote) return;
    updateRequest(
      {
        id: meetingNote.id,
        body: generateParams(),
      },
      {
        onSuccess: (data) => {
          mutateRequestOnSuccess(data);
          setFiles([]);
        },
      },
    );
  };

  const handleCreate = () => {
    postRequest(
      {
        body: generateParams(),
      },
      {
        onSuccess: (data) => {
          mutateRequestOnSuccess(data);
          formReset();
        },
      },
    );
  };

  const formReset = () => {
    editorState.setPlainText("");
    setTitle("");
    setSelectedTemplate(undefined);
    setFiles([]);
    onChangeSelectableEmployees([]);
  };

  const generateParams = () => {
    return {
      title: title.value,
      content: editorState.jsonContent,
      postDate: toDateString(date),
      meetingNoteTemplateId: selectedTemplate?.id,
      participationEmployeeIds: selectedEmployees.map(
        (employee) => employee.value,
      ),
      ...(files.length > 0 ? { attachFiles: files } : {}),
    };
  };

  return (
    <>
      <div className={`space-y-6 ${className}`}>
        <TemplateSelectButtonWithModal
          templates={meetingNoteTemplates || []}
          setEditorState={setEditorState}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
        <LabelWithMultipleDropdownField
          labelText="参加者"
          name="employee"
          required
          options={dropdownSelectableEmployees}
          value={selectedEmployees}
          onChange={onChangeSelectableEmployees}
          isDisabled={isReadOnly}
        />
        <LabelWithTextField
          required
          labelText="タイトル"
          type="text"
          name="title"
          value={title.value}
          onChange={title.onChange}
          placeholder="タイトル"
          disabled={isReadOnly}
        />
        <TextEditor
          labelText="内容"
          required
          editorState={editorState.value}
          setEditorState={editorState.onChange}
          readOnly={isReadOnly}
          mentions={[]}
          files={files}
          onChangeFiles={setFiles}
        />
        {Boolean(meetingNote?.attachFiles?.length) && (
          <PostedFilePreviews
            files={meetingNote?.attachFiles || []}
            className="mt-4"
          />
        )}
        {!isReadOnly && (
          <Button
            text="保存"
            onClick={handleSubmit}
            color="primary"
            className="w-full"
            readonly={isCreating || isUpdating}
          />
        )}
      </div>
    </>
  );
};
