import React, { useState, FC } from "react";

import { toast } from "react-toastify";

import { formatDateTime } from "~/libs";

import {
  useApisMembersMeetingNotesDestroy,
  useMeetingNoteSlideNavigation,
  useRightSidebarState,
  useSearchMembersMeetingNotes,
} from "~/hooks";

import { GridHeaderCells } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  GridRow,
  InfiniteScrollWithMoreButton,
  OpenedMenuInGrid,
  RightSidebarToggleIconWithLabel,
} from "~/components/molecules";
import {
  MeetingNoteDailyCountCalendar,
  MeetingNoteSearchForm,
} from "~/components/organisms";

import { MeetingNoteType } from "~/domains";

export const MembersMeetingNotesIndex: FC = () => {
  const query = new URLSearchParams(location.search);

  const paramsDate = query.get("date");
  const [postDate] = useState(paramsDate ? new Date(paramsDate) : new Date());
  const { navigations } = useMeetingNoteSlideNavigation(postDate, [
    false,
    true,
  ]);
  const date = formatDateTime(postDate, "yyyy-MM-dd");
  const {
    isSearchOpen,
    searchCalendarOpenToggle,
    setCalendarOpen,
    isOpen,
    isCalendarOpen,
    currentOpenLabel,
  } = useRightSidebarState("isCalendarOpen");

  const { mutate: deleteRequest } = useApisMembersMeetingNotesDestroy();

  const {
    meetingNotes,
    hasNextPage,
    isLoading,
    startDate,
    endDate,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    keyword,
    fetchNextPage,
    onChangeDropdownHasBookmarkId,
    onChangeDateRange,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
    findAndRemove,
  } = useSearchMembersMeetingNotes();
  const breadCrumbItems = [
    { label: "1on1・MTG", href: `/meeting_notes/new?date=${date}` },
    { label: "一覧", href: `/meeting_notes?date=${date}` },
  ];

  const handleDelete = (meetingNote: MeetingNoteType) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: meetingNote.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((item) => item.id === meetingNote.id);
        },
      },
    );
  };

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <InfiniteScrollWithMoreButton
          itemsLength={meetingNotes.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isLoading}
          className="mt-6"
        >
          <div className="grid grid-cols-4">
            <GridHeaderCells
              texts={["タイトル", "日付", "参加者", "ブックマーク"]}
            />
            {meetingNotes.map((meetingNote) => (
              <GridRow
                key={meetingNote.id}
                lists={[
                  meetingNote.title,
                  meetingNote.postDate,
                  meetingNote.participationEmployees
                    .map((employee) => employee.name)
                    .join(", "),
                  meetingNote.bookmarked ? "あり" : "",
                ]}
                openedMenu={
                  <OpenedMenuInGrid
                    cols={4}
                    buttons={[
                      {
                        text: "編集",
                        link: `/meeting_notes/${meetingNote.id}/edit?date=${date}`,
                      },
                      {
                        text: "削除",
                        onClick: () => handleDelete(meetingNote),
                      },
                    ]}
                  />
                }
              />
            ))}
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchCalendarOpenToggle}
          handleOnClose={isCalendarOpen ? undefined : setCalendarOpen}
          label={currentOpenLabel}
          isOpen={isOpen}
        >
          {isCalendarOpen && <MeetingNoteDailyCountCalendar className="mt-6" />}
          {isSearchOpen && (
            <MeetingNoteSearchForm
              onConditionReset={onConditionReset}
              onSubmit={onSearchSubmit}
              searchKeyword={{
                value: keyword,
                onChange: onChangeKeyword,
              }}
              hasBookmark={{
                options: selectableDropdownHasBookmarkIds,
                value: selectedDropdownHasBookmarkId,
                onChange: onChangeDropdownHasBookmarkId,
              }}
              date={{
                startDate,
                endDate,
                onChange: onChangeDateRange,
              }}
            />
          )}
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
