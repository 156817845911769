import React, { FC } from "react";

import { useProvidersCurrentEmployee } from "~/hooks";

import { EmployeeLayout } from "~/components/layouts";
import { Loading } from "~/components/molecules";
import { ProfilesShowTemplate } from "~/components/templates";

export const MembersProfilesShow: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const breadCrumbItems = [{ label: "マイページ", href: "/profile" }];

  if (!currentEmployee) return <Loading className="mt-8" />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <ProfilesShowTemplate currentEmployee={currentEmployee} />
    </EmployeeLayout>
  );
};
