import React, { FC, MouseEvent } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersBusinessSectionsBusinessSectionCompetencyReviewsDestroy,
  useBoolean,
} from "~/hooks";

import { Button } from "~/components/atoms";
import { LabelWithTextArea, TogglablePostHeader } from "~/components/molecules";

import { BusinessSectionCompetencyReviewType } from "~/domains";

type PropsType = {
  businessSectionCompetencyReview: BusinessSectionCompetencyReviewType;
  className?: string;
  refetch: () => void;
};

export const BusinessSectionCompetencyReviewDetail: FC<PropsType> = ({
  businessSectionCompetencyReview,
  className = "",
  refetch,
}: PropsType) => {
  const { isChecked, toggle } = useBoolean(false);

  const { mutate: deleteRequest } =
    useApisManagersBusinessSectionsBusinessSectionCompetencyReviewsDestroy();

  const handleToggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    toggle();
  };

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (confirm("本当に削除しますか？")) {
      deleteRequest(
        {
          id: businessSectionCompetencyReview.id,
          businessSectionId: businessSectionCompetencyReview.businessSection.id,
        },
        {
          onSuccess: () => {
            toast.success("削除しました");
            refetch();
          },
        },
      );
    }
  };

  return (
    <div className={`${className}`}>
      <div className="space-y-6">
        <TogglablePostHeader
          avatarUrl={businessSectionCompetencyReview.reviewer.avatarUrl}
          employeeName={businessSectionCompetencyReview.reviewer.name}
          createdAt={businessSectionCompetencyReview.createdAt}
          handleToggle={handleToggle}
          isOpen={isChecked}
        />
        {isChecked && (
          <>
            <LabelWithTextArea
              labelText="目標"
              name="goal"
              placeholder="目標"
              rows={6}
              value={businessSectionCompetencyReview.goal}
              disabled
            />
            <LabelWithTextArea
              labelText="強み"
              name="strength"
              placeholder="強み"
              rows={6}
              value={businessSectionCompetencyReview.strength}
              disabled
            />
            <LabelWithTextArea
              labelText="弱み"
              name="weakness"
              placeholder="弱み"
              rows={6}
              value={businessSectionCompetencyReview.weakness}
              disabled
            />
            <LabelWithTextArea
              labelText="特徴"
              name="competency"
              placeholder="特徴"
              rows={6}
              value={businessSectionCompetencyReview.competency}
              disabled
            />
            <LabelWithTextArea
              labelText="方針"
              name="strategy"
              placeholder="方針"
              rows={6}
              value={businessSectionCompetencyReview.strategy}
              disabled
            />
            <LabelWithTextArea
              labelText="備考"
              name="remark"
              placeholder="備考"
              rows={6}
              value={businessSectionCompetencyReview.remark}
              disabled
            />
            <div className="flex justify-end">
              <Button
                onClick={handleDelete}
                size="sm"
                color="red"
                text="削除する"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
