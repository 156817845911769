import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import { ApiMembersUnreadNotificationCountsType } from "~/domains";

const request = async (): Promise<ApiMembersUnreadNotificationCountsType> => {
  const response =
    await new ApiClient().get<ApiMembersUnreadNotificationCountsType>(
      "/api/members/unread_notification_counts",
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersUnreadNotificationCountsIndex = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiMembersUnreadNotificationCountsType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersUnreadNotificationCountsIndex"],
    queryFn: request,
    useErrorBoundary: (error) => {
      return error instanceof ApiResponseError && error.status >= 500;
    },
  });
};
