import { useContext } from "react";

import { CurrentPartnerConsoleContext } from "~/components/providers/";

import { AdminOperatorType } from "~/domains";

type Props = {
  currentAdminOperator?: AdminOperatorType;
  refetch: () => void;
};

export const useProvidersCurrentPartnerConsole = (): Props => {
  const { currentAdminOperator, refetch } = useContext(
    CurrentPartnerConsoleContext,
  );

  return { currentAdminOperator, refetch };
};
