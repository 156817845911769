import React, { FC } from "react";

import {
  useApisMembersFocusChallengeEmployeesLikedEmployeesIndex,
  useArray,
} from "~/hooks";

import { ReactedEmployeesModal } from "~/components/molecules";

import { ReactedEmployeeType } from "~/domains";

type PropsType = {
  focusChallengeEmployeeId: string;
  onClose: () => void;
};

export const FocusChallengeEmployeeLikedEmployeesModal: FC<PropsType> = ({
  focusChallengeEmployeeId,
  onClose,
}: PropsType) => {
  const { items, setItems } = useArray<ReactedEmployeeType>([]);
  const { fetchNextPage, hasNextPage, isLoading } =
    useApisMembersFocusChallengeEmployeesLikedEmployeesIndex({
      focusChallengeEmployeeId,
      config: {
        onSuccess: (data) => {
          setItems(
            data.pages.map((page) => page.focusChallengeEmployeeLikes).flat(),
          );
        },
      },
    });
  return (
    <ReactedEmployeesModal
      headerTitle="いいねしたユーザー"
      onClose={onClose}
      reactedEmployees={items}
      nextFetchFunction={fetchNextPage}
      hasMore={hasNextPage}
      isLoading={isLoading}
    />
  );
};
