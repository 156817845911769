import React, { FC, useState } from "react";

import { toDateString } from "~/libs";

import {
  useApisMembersKpiKpiObjectivesEmployeesClientCompaniesIndex,
  useBoolean,
} from "~/hooks";

import { ScalableBarGraph } from "~/components/organisms";

import { KpiDashboardClientCompaniesModal } from "./KpiDashboardByEmployee/KpiDashboardClientCompaniesModal";

import { KpiObjectiveDashboardType } from "~/domains";

type PropsType = {
  className?: string;
  kpiObjectiveDashboard: Pick<
    KpiObjectiveDashboardType,
    "kpiObjectiveId" | "title" | "performanceByEmployees"
  >;
  selectedDateRange?: {
    startDate: Date | null;
    endDate: Date | null;
  };
};

export const KpiDashboardByEmployee: FC<PropsType> = ({
  className = "",
  kpiObjectiveDashboard,
  selectedDateRange,
}: PropsType) => {
  const { title, performanceByEmployees, kpiObjectiveId } =
    kpiObjectiveDashboard;
  const [employeeId, setEmployeeId] = useState("");
  const graphData = performanceByEmployees.map((employee) => ({
    name: employee.employeeName,
    value: employee.performance,
    stackValue: employee.diffPerformance,
  }));

  const { isLoading, data } =
    useApisMembersKpiKpiObjectivesEmployeesClientCompaniesIndex({
      kpiObjectiveId,
      employeeId,
      params: {
        fromDate: selectedDateRange?.startDate
          ? toDateString(selectedDateRange?.startDate)
          : undefined,
        toDate: selectedDateRange?.endDate
          ? toDateString(selectedDateRange?.endDate)
          : undefined,
      },
      config: {
        enabled: !!employeeId,
      },
    });
  const {
    isChecked,
    setTrue: clientCompaniesModalOpen,
    setFalse: clientCompaniesModalClose,
  } = useBoolean(false);

  const handleBarClick = (index?: { index: number }) => {
    if (!index) return;
    const employee = performanceByEmployees[index.index];
    if (!employee) return;

    clientCompaniesModalOpen();
    setEmployeeId(employee.employeeId);
  };

  return (
    <>
      <ScalableBarGraph
        className={className}
        modalClassName={`${isChecked ? "hidden" : ""}`}
        title={`個人別: ${title}`}
        perforManceData={graphData}
        handleBarClick={handleBarClick}
        graphWrapperClassName="h-80"
        graphClassName="w-72 miniTablet:w-full"
        isLoading={false}
      />
      {isChecked && Boolean(employeeId) && (
        <KpiDashboardClientCompaniesModal
          onClose={clientCompaniesModalClose}
          title={title}
          loading={isLoading}
          data={data}
        />
      )}
    </>
  );
};
