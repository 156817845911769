import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApisPartnerConsolesProfilesShowResponseType } from "~/domains";

const request =
  async (): Promise<ApisPartnerConsolesProfilesShowResponseType> => {
    const response =
      await new ApiClient().get<ApisPartnerConsolesProfilesShowResponseType>(
        "/api/partner_consoles/profile",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisPartnerConsolesProfilesShow = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApisPartnerConsolesProfilesShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisPartnerConsolesProfilesShow"],
    queryFn: request,
    cacheTime: 0,
  });
};
