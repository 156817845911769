import React, { FC, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useEditorState,
  useDateRange,
  useDropdown,
  useMultipleDropdown,
  useApisManagersFocusChallengesNew,
  useApisManagersFocusChallengesCreate,
} from "~/hooks";

import { EmployeeLayout } from "~/components/layouts";
import { ManagersFocusChallengesFormTemplate } from "~/components/templates";

import { PreviewWithFileType } from "~/domains";

export const ManagersFocusChallengesNew: FC = () => {
  const navigate = useNavigate();

  const [dateRange] = useDateRange([null, null]);
  const [editorState] = useEditorState();
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const { data: fetchData } = useApisManagersFocusChallengesNew({
    config: {
      onSuccess: (data) => {
        dropdownEmployees.setSelectableOption(data.employees);
      },
    },
  });
  const { mutate: postRequest, isLoading: isSubmitting } =
    useApisManagersFocusChallengesCreate();

  const [dropdownEmployees] = useMultipleDropdown();
  const [dropdownPublicStatus] = useDropdown();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!editorState.hasText) {
      toast.error("テキストが空です");
      return;
    }
    if (!dropdownPublicStatus.dropdownValue) {
      toast.error("公開ステータスが選択されていません");
      return;
    }
    const body = {
      content: editorState.jsonContent,
      employeeIds: dropdownEmployees.dropdownValue.map((v) => v.value),
      challengeStartDate: dateRange.formattedStartDate || "",
      challengeFinishDate: dateRange.formattedEndDate || "",
      attachFiles: files,
      publicStatusId: Number(dropdownPublicStatus.dropdownValue?.value),
    };
    postRequest(
      { body },
      {
        onSuccess: (data) => {
          navigate("/managers/focus_challenges");
          toast(data?.message);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "チャレンジ作成", href: "/managers/focus_challenges" },
    { label: "新規作成", href: "/managers/focus_challenges/new" },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <ManagersFocusChallengesFormTemplate
        isSubmitting={isSubmitting}
        mentions={fetchData?.employees || []}
        editorState={{
          value: editorState.value,
          onChange: editorState.onChange,
        }}
        employees={{
          options: dropdownEmployees.dropdownSelectableValue,
          dropdownValue: dropdownEmployees.dropdownValue,
          onChange: dropdownEmployees.onChange,
        }}
        publicStatus={{
          dropdownValue: dropdownPublicStatus.dropdownValue,
          onChange: dropdownPublicStatus.onChange,
        }}
        files={files}
        setFiles={setFiles}
        dateRange={{
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          onChange: dateRange.onChange,
        }}
        handleSubmit={handleSubmit}
      />
    </EmployeeLayout>
  );
};
