import React, { FC, FormEvent, ChangeEvent } from "react";

import { Form, TextField, ButtonWithIcon } from "~/components/atoms";

type PropsType = {
  onFormClose?: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

export const FloatingChatRoomTitleForm: FC<PropsType> = ({
  onFormClose,
  value,
  onChange,
  onSubmit,
  className = "",
}: PropsType) => {
  return (
    <div className={`flex items-center w-full ${className}`}>
      <Form onSubmit={onSubmit} className="space-y-8 ml-2 w-full">
        <TextField
          type="text"
          name="folderName"
          value={value}
          onChange={onChange}
          className={"text-xs "}
          placeholder="新規フォルダ"
        />
      </Form>
      <ButtonWithIcon
        onClick={onFormClose}
        icon={{
          icon: "ioCloseOutline",
          size: "1.5rem",
          color: "text-secondary-600",
        }}
        srOnlyText="ChatRoomを編集を閉じる"
      />
    </div>
  );
};
