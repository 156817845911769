import React, { FC } from "react";
import { Outlet } from "react-router-dom";

import {
  CurrentEmployeeProvider,
  EmployeeLayoutProvider,
} from "~/components/providers";

import { CompanyContractPlanType, EmployeeRoleType } from "~/domains";

type PropsType = {
  accessAbleRole: EmployeeRoleType[];
  accessAblePlan: CompanyContractPlanType[];
};

export const EmployeeLayoutWrapper: FC<PropsType> = ({
  accessAbleRole,
  accessAblePlan,
}: PropsType) => {
  return (
    <CurrentEmployeeProvider
      accessAbleRole={accessAbleRole}
      accessAblePlan={accessAblePlan}
    >
      <EmployeeLayoutProvider>
        <Outlet />
      </EmployeeLayoutProvider>
    </CurrentEmployeeProvider>
  );
};
