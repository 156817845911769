import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersFocusChallengesNewResponseType } from "~/domains";

const request =
  async (): Promise<ApiManagersFocusChallengesNewResponseType> => {
    const response =
      await new ApiClient().get<ApiManagersFocusChallengesNewResponseType>(
        "/api/managers/focus_challenges/new",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersFocusChallengesNew = ({
  config = {},
}: PropsType = {}): UseQueryResult<ApiManagersFocusChallengesNewResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersFocusChallengesNew"],
    queryFn: request,
  });
};
