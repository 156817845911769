import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import { ApisMembersKaizenIdeaRepliesKaizenIdeaWithRepliesShowResponseType } from "~/domains";

type ParamsType = {
  kaizenIdeaReplyId: string;
};

const request = async ({
  kaizenIdeaReplyId,
}: ParamsType): Promise<ApisMembersKaizenIdeaRepliesKaizenIdeaWithRepliesShowResponseType> => {
  const response =
    await new ApiClient().get<ApisMembersKaizenIdeaRepliesKaizenIdeaWithRepliesShowResponseType>(
      `/api/members/kaizen_idea_replies/${kaizenIdeaReplyId}/kaizen_idea_with_replies`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  kaizenIdeaReplyId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersKaizenIdeaRepliesKaizenIdeaWithRepliesShow = ({
  kaizenIdeaReplyId,
  config = {},
}: PropsType): UseQueryResult<ApisMembersKaizenIdeaRepliesKaizenIdeaWithRepliesShowResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisMembersKaizenIdeaRepliesKaizenIdeaWithRepliesShow",
      kaizenIdeaReplyId,
    ],
    queryFn: () => request({ kaizenIdeaReplyId }),
    useErrorBoundary: (error) => {
      return error instanceof ApiResponseError && error.status >= 500;
    },
  });
};
