import React, { FC } from "react";

import {
  useApisManagersBelongingEmployeesMeetingNotesIndex,
  useSearchManagersMeetingNotes,
} from "~/hooks";

import { TabItemLinks } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import { ManagersMeetingNotesIndexTemplate } from "~/components/templates";

export const ManagersBelongingEmployeesMeetingNotesIndex: FC = () => {
  const breadCrumbItems = [
    { label: "1on1・MTG", href: "/managers/meeting_notes" },
    { label: "一覧", href: "/managers/meeting_notes" },
  ];

  const {
    meetingNotes,
    hasNextPage,
    isLoading,
    keyword,
    startDate,
    endDate,
    selectableDropdownCreatedEmployees,
    selectedDropdownCreatedEmployees,
    selectableDropdownParticipationEmployees,
    selectedDropdownParticipationEmployees,
    selectableDropdownCompleteStatusIds,
    selectedDropdownCompleteStatusId,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    onDivisionChange,
    onSectionChange,
    onChangeDropdownCreatedEmployees,
    onChangeDropdownParticipationEmployees,
    onChangeDropdownCompleteStatusId,
    onChangeDateRange,
    fetchNextPage,
    findAndRemove,
    updateIndexItem,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  } = useSearchManagersMeetingNotes({
    useApisMeetingNotes: useApisManagersBelongingEmployeesMeetingNotesIndex,
  });

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <TabItemLinks
          items={[
            {
              name: "チームに蓄積",
              href: "/managers/meeting_notes",
              isActive: false,
            },
            {
              name: "ユーザー紐付き",
              href: "/managers/belonging_employees/meeting_notes",
              isActive: true,
            },
          ]}
        />
        <ManagersMeetingNotesIndexTemplate
          className="mt-8"
          meetingNotes={meetingNotes}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          keyword={keyword}
          startDate={startDate}
          endDate={endDate}
          selectableDropdownCreatedEmployees={
            selectableDropdownCreatedEmployees
          }
          selectedDropdownCreatedEmployees={selectedDropdownCreatedEmployees}
          selectableDropdownParticipationEmployees={
            selectableDropdownParticipationEmployees
          }
          selectedDropdownParticipationEmployees={
            selectedDropdownParticipationEmployees
          }
          selectableDropdownCompleteStatusIds={
            selectableDropdownCompleteStatusIds
          }
          selectedDropdownCompleteStatusId={selectedDropdownCompleteStatusId}
          selectableDropdownDivisions={selectableDropdownDivisions}
          selectedDropdownDivisions={selectedDropdownDivisions}
          selectableDropdownSections={selectableDropdownSections}
          selectedDropdownSections={selectedDropdownSections}
          onDivisionChange={onDivisionChange}
          onSectionChange={onSectionChange}
          onChangeDropdownCreatedEmployees={onChangeDropdownCreatedEmployees}
          onChangeDropdownParticipationEmployees={
            onChangeDropdownParticipationEmployees
          }
          onChangeDropdownCompleteStatusId={onChangeDropdownCompleteStatusId}
          onChangeDateRange={onChangeDateRange}
          fetchNextPage={fetchNextPage}
          findAndRemove={findAndRemove}
          updateIndexItem={updateIndexItem}
          onChangeKeyword={onChangeKeyword}
          onSearchSubmit={onSearchSubmit}
          onConditionReset={onConditionReset}
        />
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
