import OpenAI from "openai";

import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersAiChatRoomsAiEmployeeConversationsMutateResponseType } from "~/domains";

type RequestDataType = {
  aiChatRoomId: string;
  body: {
    message: string;
    senderTypeId: number;
    aiResponseMetadata?: OpenAI.Chat.Completions.ChatCompletion;
  };
};

const request = ({ aiChatRoomId, body }: RequestDataType) => {
  return new ApiClient()
    .post<ApiMembersAiChatRoomsAiEmployeeConversationsMutateResponseType>(
      `/api/members/ai_chat_rooms/${aiChatRoomId}/ai_employee_conversations`,
      {
        aiEmployeeConversation: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersAiChatRoomsAiEmployeeConversationsCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiMembersAiChatRoomsAiEmployeeConversationsMutateResponseType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
