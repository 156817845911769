export const uniqueObjectArrayById = <T extends { id: string }>(
  array: T[],
): T[] => {
  const uniqueObjectArray = array.reduce((prev, current) => {
    const isExist = prev.some((item) => item.id === current.id);
    if (!isExist) {
      prev.push(current);
    }
    return prev;
  }, [] as T[]);

  return uniqueObjectArray;
};
