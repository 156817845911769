import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiMembersKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndexResponseType,
  ApiMembersKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndexRequestType,
} from "~/domains";

type RequestDataType = {
  knowledgeInsightPostBookmarkFolderId: string;
  params?: ApiMembersKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndexRequestType;
  page?: number;
};

const request = async ({
  knowledgeInsightPostBookmarkFolderId,
  params,
  page = 1,
}: RequestDataType): Promise<ApiMembersKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndexResponseType>(
      `/api/members/knowledge_insight_post_bookmark_folders/${knowledgeInsightPostBookmarkFolderId}/knowledge_insight_posts`,
      { page, ...params },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  knowledgeInsightPostBookmarkFolderId: string;
  params?: ApiMembersKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndexRequestType;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndex =
  ({
    knowledgeInsightPostBookmarkFolderId,
    params,
    config = {},
  }: PropsType): UseInfiniteQueryResult<ApiMembersKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndexResponseType> => {
    return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
      [
        "ApisMembersInsightPostBookmarkFoldersInsightPostsIndex",
        knowledgeInsightPostBookmarkFolderId,
        params,
      ],
      ({ pageParam }) => {
        return request({
          knowledgeInsightPostBookmarkFolderId,
          params,
          page: pageParam as number | undefined,
        });
      },
      {
        ...config,
        getNextPageParam: (data) => {
          return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
        },
      },
    );
  };
