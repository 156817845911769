import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersQuestionAnswerCategoryMutateType,
  ApiManagersQuestionAnswerCategoriesMutateRequestType,
} from "~/domains";

type RequestDataType = {
  body: ApiManagersQuestionAnswerCategoriesMutateRequestType;
};

const request = ({ body }: RequestDataType) => {
  return new ApiClient()
    .post<ApiManagersQuestionAnswerCategoryMutateType>(
      "/api/managers/question_answer_categories",
      {
        questionAnswerCategory: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersQuestionAnswerCategoriesCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersQuestionAnswerCategoryMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
