import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  body: {
    firstName: string;
    lastName: string;
    katakanaLastName: string;
    katakanaFirstName: string;
    jobTitle: string;
    objective: string;
  };
};

const request = ({ body }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseMessageType>("/api/members/profile", {
      profile: body,
    })
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersProfilesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
