import React, { FC } from "react";

type PropsType = {
  value?: string[] | string | number;
};

export const RequiredHiddenField: FC<PropsType> = ({
  value = "",
}: PropsType) => {
  return (
    <input
      tabIndex={-1}
      defaultValue={value}
      autoComplete="off"
      className="top-0 left-0 opacity-0 w-full h-full absolute z-lowest"
      required
    />
  );
};
