import React, { FC, ReactNode } from "react";

import { Helmet } from "react-helmet-async";

import AdminOperatorFavicon from "~/assets/images/logos/admin_operator_favicon.png";

import { EmployeeLayoutMainContent } from "~/components/layouts";
import { Header, PartnerConsoleSidebar } from "~/components/organisms";

import { BreadCrumbItemType } from "~/domains";

type Props = {
  breadCrumbItems: BreadCrumbItemType[];
  companyId?: string;
  className?: string;
  children?: ReactNode;
};

export const PartnerConsoleLayout: FC<Props> = ({
  breadCrumbItems,
  children,
  companyId,
  className = "",
}: Props) => {
  return (
    <>
      <Helmet defer={false}>
        <link
          rel="icon"
          type="image/png"
          href={AdminOperatorFavicon as string}
        />
      </Helmet>
      <Header breadCrumbItems={breadCrumbItems} withRightSidebar={false} />
      <div className="flex text-gray-800 mobile-screen-height miniTablet:miniTablet-screen-height">
        <div className="miniTablet:w-[20%] py-4 space-y-2 bg-primary-200 h-full">
          <PartnerConsoleSidebar
            breadCrumbText={breadCrumbItems.at(-1)?.label || "会社一覧"}
            companyId={companyId}
          />
        </div>
        <div className={`w-full miniTablet:w-[80%] ${className}`}>
          <EmployeeLayoutMainContent withRightSidebar={false}>
            {children}
          </EmployeeLayoutMainContent>
        </div>
      </div>
    </>
  );
};
