import React, { FC } from "react";

import {
  useApisMembersRoleplayingConditionDailyRoleplayingTimesIndex,
  usePrevNextSelectMonth,
} from "~/hooks";

import { ActivityOriginalCalendar } from "~/components/molecules";

type PropsType = {
  className?: string;
};

export const RoleplayingConditionCurrentEmployeeDailyCountCalendar: FC<
  PropsType
> = ({ className = "" }: PropsType) => {
  const {
    targetDate,
    targetDateString,
    handlePrevMonthClick,
    handleNextMonthClick,
  } = usePrevNextSelectMonth();

  const { data, isLoading } =
    useApisMembersRoleplayingConditionDailyRoleplayingTimesIndex({
      params: {
        targetDate: targetDateString,
      },
    });
  return (
    <ActivityOriginalCalendar
      isLoading={isLoading}
      dailyActivity={data?.dailyRoleplayingTimes.map((v) => ({
        date: v.postDate,
        postsCount: v.times,
      }))}
      targetDate={targetDate}
      onPrevMonthClick={handlePrevMonthClick}
      onNextMonthClick={handleNextMonthClick}
      isForRate={false}
      className={className}
    />
  );
};
