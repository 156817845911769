import React, { FC } from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";

export const Link: FC<LinkProps> = ({
  className = "",
  children,
  ...props
}: LinkProps) => {
  return (
    <RouterLink className={`${className}`} {...props}>
      {children}
    </RouterLink>
  );
};
