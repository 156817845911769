import React, { ChangeEvent, FC } from "react";

import { Button } from "~/components/atoms";
import { LabelWithCheckBox } from "~/components/molecules";
import { EmployeeSurveyQuestionDetail } from "~/components/organisms";

import {
  EmployeeSurveyWithQuestionsType,
  SelectedOption,
  FormEmployeeSurveySubmissionAnswerType,
} from "~/domains";

type PropsType = {
  employeeSurvey: EmployeeSurveyWithQuestionsType;
  className?: string;
  shareable: boolean;
  answers: FormEmployeeSurveySubmissionAnswerType[];
  isSubmitting: boolean;
  onChangeShareable: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeAnswer: (value: string, questionId: string) => void;
  handleSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const EmployeeSurveyDetail: FC<PropsType> = ({
  employeeSurvey,
  className = "",
  shareable,
  answers,
  isSubmitting,
  onChangeAnswer,
  onChangeShareable,
  handleSubmit,
}: PropsType) => {
  return (
    <div className={className}>
      <h2 className="text-secondary-900 font-semibold text-center w-full text-lg border border-solid border-secondary-400 py-2 px-3 rounded">
        {employeeSurvey.title}
      </h2>
      {employeeSurvey.description && (
        <h3 className="mt-6 text-secondary-900 font-semibold w-full text-lg border border-solid border-primary-600 py-2 px-3 rounded">
          {employeeSurvey.description}
        </h3>
      )}
      <div className="mt-12 space-y-8">
        {employeeSurvey.employeeSurveyQuestions.map((question) => (
          <EmployeeSurveyQuestionDetail
            key={question.id}
            options={question.employeeSurveyQuestionOptions || []}
            answers={answers}
            onChangeAnswer={onChangeAnswer}
            question={question}
          />
        ))}
      </div>
      <div className="mt-12">
        <LabelWithCheckBox
          labelText="シェア可能"
          name="share"
          labelEnd
          checked={shareable}
          onChange={onChangeShareable}
          disabled={
            employeeSurvey.answerShareOption.type !== SelectedOption.type
          }
        />
      </div>
      <Button
        color="primary"
        text="投稿する"
        className="w-full mt-4"
        size="md"
        readonly={isSubmitting}
        onClick={handleSubmit}
      />
    </div>
  );
};
