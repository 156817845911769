import React, { FC, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useApisMembersProfilesNewEmailsCreate,
  useApisMembersProfileCurrentEmailShow,
} from "~/hooks";

import { Form, FormSubmitButton } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { LabelWithTextField } from "~/components/molecules";

export const MembersSettingEmailShow: FC = () => {
  const breadCrumbItems = [
    { label: "マイページ", href: "/profile" },
    { label: "編集", href: "/setting/profile" },
    { label: "メールアドレス編集", href: "/setting/email" },
  ];
  const navigate = useNavigate();
  const [{ value: email, onChange: onChangeEmail }] = useInput("");
  const { mutate, isLoading } = useApisMembersProfilesNewEmailsCreate();
  const { data: fetchData } = useApisMembersProfileCurrentEmailShow();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      {
        body: {
          email,
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate("/profile");
        },
      },
    );
  };

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <Form onSubmit={handleSubmit} className="space-y-6">
          <LabelWithTextField
            labelText="現在のメールアドレス"
            type="email"
            name="email"
            autoComplete="email"
            disabled
            value={fetchData?.email || ""}
          />
          <LabelWithTextField
            labelText="新しいメールアドレス"
            type="email"
            name="email"
            autoComplete="email"
            required
            disabled={isLoading}
            value={email}
            onChange={onChangeEmail}
          />
          <FormSubmitButton
            color="primary"
            value="更新"
            className="w-full"
            isReadOnly={isLoading}
          />
        </Form>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
