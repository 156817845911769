import React, { FC } from "react";

import {
  useSearchMembersBusinessSectionsEmployeeSurveys,
  useEmployeeSurveysSlideNavigation,
  useDropdown,
  useProvidersCurrentEmployee,
  useRightSidebarState,
} from "~/hooks";

import {
  Button,
  DropDownField,
  Form,
  FormSubmitButton,
  TextField,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  RightSidebarToggleIconWithLabel,
  TitleWithChild,
} from "~/components/molecules";
import {
  EmployeeSurveySubmissionsCountCalendar,
  EmployeeSurveyTitleInfinitiesScroll,
} from "~/components/organisms";

export const MembersEmployeeSurveysIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";
  const [
    {
      dropdownValue: selectedBusinessSection,
      dropdownSelectableValue: selectableBusinessSections,
      onChange: onChangeBusinessSection,
    },
  ] = useDropdown({
    selectableValue: currentEmployee?.businessSections || [],
    initialValue: currentEmployee?.businessSections.find(
      (businessSection) => businessSection.id === businessSectionId,
    ),
  });
  const {
    isSearchOpen,
    searchCalendarOpenToggle,
    setCalendarOpen,
    isOpen,
    isCalendarOpen,
    currentOpenLabel,
  } = useRightSidebarState("isCalendarOpen");

  const breadCrumbItems = [
    {
      label: "チェックアウト",
      href: `/employee_surveys?business_section_id=${businessSectionId}`,
    },
    {
      label: "入力",
      href: `/employee_surveys?business_section_id=${businessSectionId}`,
    },
  ];

  const { navigations } = useEmployeeSurveysSlideNavigation({
    businessSectionId,
    selectedArray: [true, false],
  });

  const {
    managersEmployeeSurveys,
    ownersEmployeeSurveys,
    keyword,
    managerFetchNextPage,
    ownerFetchNextPage,
    managerHasNextPage,
    ownerHasNextPage,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  } = useSearchMembersBusinessSectionsEmployeeSurveys({
    businessSectionId: selectedBusinessSection?.value,
  });

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <DropDownField
          placeholder="課を選択"
          name="businessSectionId"
          value={selectedBusinessSection}
          options={selectableBusinessSections}
          onChange={onChangeBusinessSection}
          className="mt-9"
        />
        <EmployeeSurveyTitleInfinitiesScroll
          title="本部の発信"
          employeeSurveys={ownersEmployeeSurveys}
          fetchNextPage={ownerFetchNextPage}
          hasMore={ownerHasNextPage}
          className="mt-6"
          businessSectionId={selectedBusinessSection?.value}
        />
        <EmployeeSurveyTitleInfinitiesScroll
          title="部署の発信"
          employeeSurveys={managersEmployeeSurveys}
          fetchNextPage={managerFetchNextPage}
          hasMore={managerHasNextPage}
          className="mt-6"
          businessSectionId={selectedBusinessSection?.value}
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchCalendarOpenToggle}
          handleOnClose={isCalendarOpen ? undefined : setCalendarOpen}
          label={currentOpenLabel}
          isOpen={isOpen}
        >
          {isCalendarOpen && (
            <EmployeeSurveySubmissionsCountCalendar className="mt-6" />
          )}
          {isSearchOpen && (
            <Form onSubmit={onSearchSubmit} className="mt-6 space-y-6">
              <TitleWithChild title="フリーワード">
                <TextField
                  value={keyword}
                  placeholder="キーワード"
                  type="search"
                  name="keyword"
                  onChange={onChangeKeyword}
                />
              </TitleWithChild>
              <div className="space-x-4 flex">
                <Button
                  text="リセット"
                  color="gray"
                  outline
                  className="w-full"
                  onClick={onConditionReset}
                />
                <FormSubmitButton
                  value="検索する"
                  color="primary"
                  className="w-full"
                />
              </div>
            </Form>
          )}
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
