import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisPartnerConsolesCompaniesAnalyticsPostsRateCalendarsIndexRequestTypes,
  ApisPartnerConsolesCompaniesAnalyticsPostsRateCalendarsIndexResponseTypes,
} from "~/domains";

type RequestDataType = {
  companyId: string;
  params: ApisPartnerConsolesCompaniesAnalyticsPostsRateCalendarsIndexRequestTypes;
};

const request = async ({
  companyId,
  params,
}: RequestDataType): Promise<ApisPartnerConsolesCompaniesAnalyticsPostsRateCalendarsIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisPartnerConsolesCompaniesAnalyticsPostsRateCalendarsIndexResponseTypes>(
      `/api/partner_consoles/companies/${companyId}/analytics/posts_rate_calendars`,
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params: ApisPartnerConsolesCompaniesAnalyticsPostsRateCalendarsIndexRequestTypes;
  companyId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisPartnerConsolesCompaniesAnalyticsPostsRateCalendarsIndex =
  ({
    params,
    companyId,
    config = {},
  }: PropsType): UseQueryResult<ApisPartnerConsolesCompaniesAnalyticsPostsRateCalendarsIndexResponseTypes> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisPartnerConsolesCompaniesAnalyticsPostsRateCalendarsIndex",
        companyId,
        params,
      ],
      queryFn: () => request({ companyId, params }),
    });
  };
