import React, { FC } from "react";

import { useEditorState } from "~/hooks";

import { BackgroundThumbnailImage } from "~/components/atoms";
import { Editor } from "~/components/molecules";
import { PostHeader } from "~/components/organisms";

import { SalesConceptType } from "~/domains";

type PropsType = {
  salesConcept: SalesConceptType;
  className?: string;
};

export const SalesConceptDetail: FC<PropsType> = ({
  salesConcept,
  className = "",
}: PropsType) => {
  const [{ value, onChange }] = useEditorState(salesConcept.content);
  return (
    <div className={`mx-auto ${className}`}>
      <h1 className="text-4xl leading-normal font-semibold">
        {salesConcept.title}
      </h1>
      <BackgroundThumbnailImage
        bgImageUrl={salesConcept.topImage.url}
        className="mt-6"
      />
      <PostHeader
        underLabel={salesConcept.createdAt}
        postedEmployee={salesConcept.createdByEmployee}
        menuItems={[]}
        className="mt-6"
      />
      <Editor
        editorState={value}
        onChange={onChange}
        canFileInText={false}
        readOnly
        className="mt-9 text-xl leading-10"
      />
    </div>
  );
};
