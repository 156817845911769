import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { EmployeeProfileType } from "~/domains";

const request = async (): Promise<EmployeeProfileType> => {
  const response = await new ApiClient().get<EmployeeProfileType>(
    "/api/members/profile",
  );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersProfilesShow = ({
  config = {},
}: PropsType = {}): UseQueryResult<EmployeeProfileType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisMembersProfilesShow"],
    queryFn: request,
    cacheTime: 0,
  });
};
