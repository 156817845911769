import { useEffect } from "react";

type ParamsType = {
  onUpdate: () => void;
  intervalMs: number;
};

export const useInterval = ({ onUpdate, intervalMs }: ParamsType): void => {
  useEffect(() => {
    const timerId = setInterval(() => onUpdate(), intervalMs);
    return () => clearInterval(timerId);
  }, [intervalMs, onUpdate]);
};
