import React, { FC } from "react";

import { ToggleIconWithCounter } from "~/components/molecules";

import { PostFooterType } from "~/domains";

type PropsType = {
  className?: string;
  iconWithCounters: PostFooterType[];
  rightIconWithCounters?: PostFooterType[];
};

export const PostFooter: FC<PropsType> = ({
  className = "",
  iconWithCounters,
  rightIconWithCounters,
}: PropsType) => {
  return (
    <div className={`flex items-center justify-between ${className}`}>
      <div className="flex items-center space-x-4">
        {iconWithCounters
          .filter((iconWithCounter) => !iconWithCounter.disable)
          .map((iconWithCounter) => (
            <ToggleIconWithCounter
              key={iconWithCounter.doIcon.icon}
              doneAlready={iconWithCounter.doneAlready}
              undoIcon={iconWithCounter.undoIcon}
              undoOnClick={iconWithCounter.undoOnClick}
              doIcon={iconWithCounter.doIcon}
              doOnClick={iconWithCounter.doOnClick}
              textOnClick={iconWithCounter.textOnClick}
              count={iconWithCounter.count}
              buttonClassName={iconWithCounter.buttonClassName}
            />
          ))}
      </div>
      <div>
        {rightIconWithCounters
          ?.filter((iconWithCounter) => !iconWithCounter.disable)
          .map((iconWithCounter) => (
            <ToggleIconWithCounter
              key={iconWithCounter.doIcon.icon}
              doneAlready={iconWithCounter.doneAlready}
              undoIcon={iconWithCounter.undoIcon}
              undoOnClick={iconWithCounter.undoOnClick}
              doIcon={iconWithCounter.doIcon}
              doOnClick={iconWithCounter.doOnClick}
              textOnClick={iconWithCounter.textOnClick}
              count={iconWithCounter.count}
            />
          ))}
      </div>
    </div>
  );
};
