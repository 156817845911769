import React, { FC } from "react";

import {
  useFooters,
  useBoolean,
  useApisMembersKnowledgeInsightPostBookmarkFoldersIndex,
  useApisMembersKnowledgeInsightPostsBookmarksCreate,
  useApisMembersKnowledgeInsightPostsBookmarksDestroy,
  useApisMembersKnowledgeInsightPostsLikesCreate,
  useApisMembersKnowledgeInsightPostsLikesDestroy,
  useCounter,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import {
  PostHeader,
  KnowledgeInsightPostContent,
  KnowledgeInsightPostBookmarkMoveModal,
  KnowledgeInsightPostsLikedEmployeesModal,
} from "~/components/organisms";

import { KnowledgeInsightPostType, PostDetailMenuItemType } from "~/domains";

type PropsType = {
  knowledgeInsightPost: KnowledgeInsightPostType;
  menuItems: PostDetailMenuItemType[];
  openComment: () => void;
  className?: string;
  isHighlight?: boolean;
};

export const KnowledgeInsightPostDetail: FC<PropsType> = ({
  knowledgeInsightPost,
  menuItems,
  className = "",
  isHighlight = false,
  openComment,
}: PropsType) => {
  const {
    isChecked: isLikedEmployeesModalOpen,
    setTrue: setLikedEmployeesModalOpen,
    setFalse: setLikedEmployeesModalClose,
  } = useBoolean(false);
  const {
    isChecked: isBookmarkModalOpen,
    setTrue: setBookmarkModalOpen,
    setFalse: setBookmarkModalClose,
  } = useBoolean(false);
  const {
    isChecked: liked,
    setTrue: setLiked,
    setFalse: setNotLiked,
  } = useBoolean(knowledgeInsightPost.liked);
  const {
    count: likeCount,
    increment: likeIncrement,
    decrement: likeDecrement,
  } = useCounter(knowledgeInsightPost.knowledgeInsightPostStat.likesCount);
  const {
    isChecked: bookmarked,
    setTrue: setBookmarked,
    setFalse: setNotBookmarked,
  } = useBoolean(knowledgeInsightPost.bookmarked);
  const {
    count: bookmarkCount,
    increment: bookmarkIncrement,
    decrement: bookmarkDecrement,
  } = useCounter(knowledgeInsightPost.knowledgeInsightPostStat.bookmarksCount);

  const { mutate: likeCreateRequest } =
    useApisMembersKnowledgeInsightPostsLikesCreate();
  const { mutate: likeDeleteRequest } =
    useApisMembersKnowledgeInsightPostsLikesDestroy();

  const { mutate: bookmarkCreateRequest } =
    useApisMembersKnowledgeInsightPostsBookmarksCreate();
  const { mutate: bookmarkDeleteRequest } =
    useApisMembersKnowledgeInsightPostsBookmarksDestroy();

  const handleCreateLike = () => {
    likeCreateRequest(
      {
        knowledgeInsightPostId: knowledgeInsightPost.id,
      },
      {
        onSuccess: () => {
          setLiked();
          likeIncrement();
        },
      },
    );
  };

  const handleDeleteLike = () => {
    likeDeleteRequest(
      {
        knowledgeInsightPostId: knowledgeInsightPost.id,
      },
      {
        onSuccess: () => {
          setNotLiked();
          likeDecrement();
        },
      },
    );
  };

  const handleCreateBookmark = () => {
    bookmarkCreateRequest(
      {
        knowledgeInsightPostId: knowledgeInsightPost.id,
      },
      {
        onSuccess: () => {
          setBookmarked();
          bookmarkIncrement();
        },
      },
    );
  };

  const handleDeleteBookmark = () => {
    bookmarkDeleteRequest(
      {
        knowledgeInsightPostId: knowledgeInsightPost.id,
      },
      {
        onSuccess: () => {
          setNotBookmarked();
          bookmarkDecrement();
        },
      },
    );
  };

  const { data: bookmarkFoldersIndex } =
    useApisMembersKnowledgeInsightPostBookmarkFoldersIndex({
      config: {
        enabled: isBookmarkModalOpen,
      },
    });

  const leftFooters = useFooters({
    like: {
      doneAlready: liked,
      undoOnClick: handleDeleteLike,
      doOnClick: handleCreateLike,
      textOnClick: setLikedEmployeesModalOpen,
      count: likeCount,
    },
    comment: {
      doneAlready: Boolean(
        knowledgeInsightPost.knowledgeInsightPostStat.commentsCount,
      ),
      undoOnClick: openComment,
      doOnClick: openComment,
      count: knowledgeInsightPost.knowledgeInsightPostStat.commentsCount,
    },
    bookmark: {
      doneAlready: bookmarked,
      undoOnClick: handleDeleteBookmark,
      doOnClick: handleCreateBookmark,
      count: bookmarkCount,
    },
  });

  const rightFooters = useFooters({
    bookmarkFolder: {
      doneAlready: bookmarked,
      undoOnClick: setBookmarkModalOpen,
      doOnClick: setBookmarkModalOpen,
      disable: !bookmarked,
    },
  });

  const canBookmarkMove =
    isBookmarkModalOpen && bookmarked && bookmarkFoldersIndex;

  return (
    <PostDetailWrapper isHighlight={isHighlight} className={className}>
      <PostHeader
        miniText={knowledgeInsightPost.createdAt}
        postedEmployee={knowledgeInsightPost.employee}
        menuItems={menuItems}
      />
      <KnowledgeInsightPostContent
        knowledgeInsightPost={knowledgeInsightPost}
        footers={leftFooters.footers}
        rightFooters={rightFooters.footers}
      />
      {canBookmarkMove && (
        <KnowledgeInsightPostBookmarkMoveModal
          onClose={setBookmarkModalClose}
          knowledgeInsightPostId={knowledgeInsightPost.id}
        />
      )}
      {isLikedEmployeesModalOpen && (
        <KnowledgeInsightPostsLikedEmployeesModal
          onClose={setLikedEmployeesModalClose}
          knowledgeInsightPostId={knowledgeInsightPost.id}
        />
      )}
    </PostDetailWrapper>
  );
};
