import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersEmployeesEmployeeMemoReviewsMutateResponseType } from "~/domains";

type RequestDataType = {
  employeeId: string;
  employeeMemoReviewId: string;
};

const request = ({ employeeId, employeeMemoReviewId }: RequestDataType) => {
  return new ApiClient()
    .put<ApiManagersEmployeesEmployeeMemoReviewsMutateResponseType>(
      `/api/managers/employees/${employeeId}/employee_memo_reviews/${employeeMemoReviewId}/completeds`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersEmployeesEmployeeMemoReviewsCompletedsUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersEmployeesEmployeeMemoReviewsMutateResponseType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
