import { ChangeEvent, FormEvent, MouseEvent, useState } from "react";

import {
  useInput,
  useApisMembersBusinessSectionsSalesConceptCategoriesIndex,
  useMultipleDropdown,
  useApisMembersSalesConceptSearchItemsIndex,
  useArray,
  useProvidersEmployeeLayout,
} from "~/hooks";

import {
  ApiMembersSalesConceptsIndexTypeRequestType,
  ID_ALL_TYPE,
  MultiValueType,
  OptionType,
  SalesConceptCategoryWithSalesConceptType,
} from "~/domains";

type ReturnType = {
  salesConceptCategories: SalesConceptCategoryWithSalesConceptType[];
  keyword: string;
  selectableTags: OptionType[];
  selectedTags: MultiValueType<OptionType>;
  selectableProvidingServices: OptionType[];
  selectedProvidingServices: MultiValueType<OptionType>;
  hasNextPage?: boolean;
  isLoading: boolean;
  onChangeTags: (newValue: MultiValueType<OptionType>) => void;
  onChangeProvidingServices: (newValue: MultiValueType<OptionType>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

type PropsType = {
  businessSectionId?: string;
  employeeRoleId: number;
};

export const useSearchMembersBusinessSectionsSalesConceptCategories = ({
  businessSectionId = "",
  employeeRoleId,
}: PropsType): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const { items, setItems } =
    useArray<SalesConceptCategoryWithSalesConceptType>([]);
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [
    {
      dropdownSelectableValue: selectableTags,
      dropdownValue: selectedTags,
      onChange: onChangeTags,
      setSelectableOption: setSelectableTags,
    },
  ] = useMultipleDropdown();
  const [
    {
      dropdownSelectableValue: selectableProvidingServices,
      dropdownValue: selectedProvidingServices,
      onChange: onChangeProvidingServices,
      setSelectableOption: setSelectableProvidingServices,
    },
  ] = useMultipleDropdown();
  const [params, setParams] =
    useState<ApiMembersSalesConceptsIndexTypeRequestType>();

  useApisMembersSalesConceptSearchItemsIndex({
    config: {
      onSuccess: (data) => {
        setSelectableTags(data.salesConceptTags);
        setSelectableProvidingServices([
          ID_ALL_TYPE,
          ...data.providingServices,
        ]);
      },
    },
  });

  const { fetchNextPage, hasNextPage, isFetching } =
    useApisMembersBusinessSectionsSalesConceptCategoriesIndex({
      businessSectionId,
      params: { employeeRoleId, ...params },
      config: {
        enabled: !!businessSectionId,
        onSuccess: (data) => {
          setItems(
            data.pages.map((page) => page.salesConceptCategories).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      employeeRoleId,
      tagIds: selectedTags.map((tag) => tag.value),
      providingServiceIds: selectedProvidingServices.map(
        (providingService) => providingService.value,
      ),
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeTags([]);
    onChangeProvidingServices([]);
    setParams(undefined);
    setFalseRightSidebarMain();
  };

  return {
    salesConceptCategories: items,
    keyword,
    hasNextPage,
    selectableTags,
    selectedTags,
    selectableProvidingServices,
    selectedProvidingServices,
    isLoading: isFetching,
    onChangeTags,
    onChangeProvidingServices,
    fetchNextPage,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  };
};
