import React, { FC } from "react";

import { Icon, IconPropsType } from "~/components/atoms";

type PropsType = {
  icon: IconPropsType;
  text: string;
  className?: string;
};

export const IconWithText: FC<PropsType> = ({
  icon = {
    icon: "ioFolderOpenOutline",
    color: "text-secondary-600",
    size: "1.25rem",
  },
  text,
  className = "",
}: PropsType) => {
  return (
    <div className={`flex items-center truncate ${className}`}>
      <Icon
        icon={icon.icon}
        color={icon.color}
        size={icon.size}
        className="shrink-0"
      />
      <div className="ml-2 truncate">{text}</div>
    </div>
  );
};
