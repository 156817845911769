import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersMeetingNoteTemplatesDestroy,
  useSearchManagersMeetingNoteTemplates,
} from "~/hooks";

import {
  Form,
  Button,
  FormSubmitButton,
  GridHeaderCells,
  BooleanDropDownField,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  LabelWithDropDownField,
  LabelWithTextField,
  GridRow,
  InfiniteScrollWithMoreButton,
  OpenedMenuInGrid,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { MeetingNoteTemplateType } from "~/domains";

export const ManagersMeetingNoteTemplatesIndex: FC = () => {
  const { mutate: deleteRequest } =
    useApisManagersMeetingNoteTemplatesDestroy();
  const breadCrumbItems = [
    { label: "1on1・MTGテンプレ", href: "/managers/meeting_note_templates" },
    { label: "一覧", href: "/managers/meeting_note_templates" },
  ];

  const {
    meetingNoteTemplates,
    titleKeyword,
    isLoading,
    hasNextPage,
    allBusinessSectionsDistribution,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    onDivisionChange,
    onSectionChange,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    fetchNextPage,
    onChangeTitleKeyword,
    onConditionReset,
    onSearchSubmit,
    setAllBusinessSectionsDistribution,
  } = useSearchManagersMeetingNoteTemplates();
  const handleDelete = (meetingNoteTemplate: MeetingNoteTemplateType) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: meetingNoteTemplate.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((item) => item.id === meetingNoteTemplate.id);
        },
      },
    );
  };

  const generateMeetingNoteTemplateLists = (
    meetingNoteTemplate: MeetingNoteTemplateType,
  ) => {
    return [
      meetingNoteTemplate.title,
      meetingNoteTemplate.allBusinessSectionsDistribution
        ? "すべての部署"
        : meetingNoteTemplate.businessDivisions
            .map((division) => division.name)
            .join(","),
      meetingNoteTemplate.allBusinessSectionsDistribution
        ? "すべての課"
        : meetingNoteTemplate.businessSections
            .map((section) => section.name)
            .join(","),
      meetingNoteTemplate.publicStatus.name,
    ];
  };
  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <Form onSubmit={onSearchSubmit} className="space-y-6">
          <div className="grid miniTablet:grid-cols-2 gap-4">
            <MultiParentsWithMultiChildrenDropdownField
              allParents={selectableDropdownDivisions}
              parentsValue={selectedDropdownDivisions}
              parentLabel="部署"
              parentsOnChange={onDivisionChange}
              allChildren={selectableDropdownSections}
              childrenValue={selectedDropdownSections}
              childLabel="課"
              childrenOnChange={onSectionChange}
            />
            <BooleanDropDownField
              name="isAllBusinessSections"
              trueLabel="すべての課に配布する"
              falseLabel="個別の課に配布する"
              labelText="配布種別の選択"
              value={allBusinessSectionsDistribution}
              onChange={setAllBusinessSectionsDistribution}
            />
            <LabelWithDropDownField
              labelText="ステータス"
              name="publicStatus"
              options={selectableDropdownPublicStatusIds}
              value={selectedDropdownPublicStatusId}
              onChange={onChangeDropdownPublicStatusId}
            />
            <LabelWithTextField
              labelText="フリーワード"
              type="search"
              name="keyword"
              placeholder="フリーワード検索"
              value={titleKeyword}
              onChange={onChangeTitleKeyword}
            />
          </div>
          <div className="flex items-center justify-end space-x-4">
            <Button
              color="gray"
              outline
              className="w-full miniTablet:w-auto"
              text="リセット"
              onClick={onConditionReset}
            />
            <FormSubmitButton
              value="検索する"
              color="primary"
              className="w-full miniTablet:w-auto"
            />
          </div>
        </Form>
        <InfiniteScrollWithMoreButton
          itemsLength={meetingNoteTemplates.length}
          nextFetchFunction={fetchNextPage}
          isLoading={isLoading}
          hasMore={hasNextPage}
          className="mt-9"
        >
          <div className="grid grid-cols-4">
            <GridHeaderCells texts={["タイトル", "部署", "課", "ステータス"]} />
            {meetingNoteTemplates.map((meetingNoteTemplate) => (
              <GridRow
                key={meetingNoteTemplate.id}
                lists={generateMeetingNoteTemplateLists(meetingNoteTemplate)}
                openedMenu={
                  <OpenedMenuInGrid
                    cols={4}
                    buttons={[
                      {
                        text: "編集",
                        link: `/managers/meeting_note_templates/${meetingNoteTemplate.id}/edit`,
                      },
                      {
                        text: "削除",
                        onClick: () => handleDelete(meetingNoteTemplate),
                      },
                    ]}
                  />
                }
              />
            ))}
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
