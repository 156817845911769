import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  body: {
    avatar: File;
  };
};

type ApiResponseType = ResponseMessageType & {
  avatarUrl: string;
};

const request = ({ body }: RequestDataType) => {
  const formData = new FormData();
  formData.append("profile[avatar]", body.avatar);

  return new ApiClient()
    .put<ApiResponseType>("/api/members/profile/avatar", formData)
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersProfilesAvatarUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ApiResponseType, RequestDataType> => {
  return useMutation(request, { ...options });
};
