import React, { FC } from "react";

import {
  useApisMembersEmployeeSurveySubmissionRepliesLikedEmployeesIndex,
  useArray,
} from "~/hooks";

import { ReactedEmployeesModal } from "~/components/molecules";

import { ReactedEmployeeType } from "~/domains";

type PropsType = {
  employeeSurveySubmissionReplyId: string;
  onClose: () => void;
};

export const EmployeeSurveySubmissionRepliesLikedEmployeesModal: FC<
  PropsType
> = ({ employeeSurveySubmissionReplyId, onClose }: PropsType) => {
  const { items, setItems } = useArray<ReactedEmployeeType>([]);
  const { fetchNextPage, hasNextPage, isLoading } =
    useApisMembersEmployeeSurveySubmissionRepliesLikedEmployeesIndex({
      employeeSurveySubmissionReplyId,
      config: {
        onSuccess: (data) => {
          setItems(data.pages.map((page) => page.likedEmployees).flat());
        },
      },
    });
  return (
    <ReactedEmployeesModal
      headerTitle="いいねしたユーザー"
      onClose={onClose}
      reactedEmployees={items}
      nextFetchFunction={fetchNextPage}
      hasMore={hasNextPage}
      isLoading={isLoading}
    />
  );
};
