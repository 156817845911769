import { toast } from "react-toastify";

import {
  useArray,
  useApisMembersRoleplayingConditionsRoleplayingEvaluationsCreate,
  useApisMembersRoleplayingConditionsRoleplayingEvaluationsDestroy,
  useApisMembersRoleplayingConditionsRoleplayingEvaluationsUpdate,
  useApisManagersRoleplayingConditionsRoleplayingEvaluationsCreate,
  useApisManagersRoleplayingConditionsRoleplayingEvaluationsDestroy,
  useApisManagersRoleplayingConditionsRoleplayingEvaluationsUpdate,
} from "~/hooks";

import { RoleplayingEvaluationType } from "~/domains";

export type FormRoleplayingEvaluationType = Pick<
  RoleplayingEvaluationType,
  | "id"
  | "roleplayingConditionId"
  | "myEvaluationGoodContent"
  | "myEvaluationImproveContent"
  | "otherUserEvaluationGoodContent"
  | "otherUserEvaluationImproveContent"
> & {
  isNew?: boolean;
  isReadOnly?: boolean;
};

type OnChangeStringPropsType = {
  roleplayingEvaluation: FormRoleplayingEvaluationType;
  newValue: string;
};

type ReturnType = {
  roleplayingEvaluations: FormRoleplayingEvaluationType[];
  onChangeMyEvaluationGoodContent: ({
    roleplayingEvaluation,
    newValue,
  }: OnChangeStringPropsType) => void;
  onChangeMyEvaluationImproveContent: ({
    roleplayingEvaluation,
    newValue,
  }: OnChangeStringPropsType) => void;
  onChangeOtherUserEvaluationGoodContent: ({
    roleplayingEvaluation,
    newValue,
  }: OnChangeStringPropsType) => void;
  onChangeOtherUserEvaluationImproveContent: ({
    roleplayingEvaluation,
    newValue,
  }: OnChangeStringPropsType) => void;
  addRoleplayingEvaluation: () => void;
  removeRoleplayingEvaluation: (
    roleplayingEvaluation: FormRoleplayingEvaluationType,
  ) => void;
  saveRoleplayingEvaluation: (
    roleplayingEvaluation: FormRoleplayingEvaluationType,
  ) => void;
  setNotReadOnly: (
    roleplayingEvaluation: FormRoleplayingEvaluationType,
  ) => void;
};

type PropsType = {
  roleplayingConditionId: string;
  roleplayingEvaluations: FormRoleplayingEvaluationType[];
  isManager: boolean;
};

export function useRoleplayingEvaluationForms({
  roleplayingConditionId,
  roleplayingEvaluations,
  isManager,
}: PropsType): ReturnType {
  const { mutate: deleteRoleplayingEvaluation } =
    useApisMembersRoleplayingConditionsRoleplayingEvaluationsDestroy();
  const { mutate: postRoleplayingEvaluation } =
    useApisMembersRoleplayingConditionsRoleplayingEvaluationsCreate();
  const { mutate: putRoleplayingEvaluation } =
    useApisMembersRoleplayingConditionsRoleplayingEvaluationsUpdate();

  const { mutate: deleteManagersRoleplayingEvaluation } =
    useApisManagersRoleplayingConditionsRoleplayingEvaluationsDestroy();
  const { mutate: postManagersRoleplayingEvaluation } =
    useApisManagersRoleplayingConditionsRoleplayingEvaluationsCreate();
  const { mutate: putManagersRoleplayingEvaluation } =
    useApisManagersRoleplayingConditionsRoleplayingEvaluationsUpdate();

  const defaultItemFormat = (id: string) => {
    return {
      id: id,
      roleplayingConditionId: roleplayingConditionId,
      myEvaluationGoodContent: "",
      myEvaluationImproveContent: "",
      otherUserEvaluationGoodContent: "",
      otherUserEvaluationImproveContent: "",
      isNew: true,
      isReadOnly: false,
    };
  };

  const { items, findAndReplace, pushItem, findAndRemove } =
    useArray<FormRoleplayingEvaluationType>(
      roleplayingEvaluations.length
        ? roleplayingEvaluations.map((roleplayingEvaluation) => ({
            ...roleplayingEvaluation,
            isNew: false,
            isReadOnly: true,
          }))
        : [defaultItemFormat("1")],
    );

  const addItem = () => {
    const newId = items.length + 1;
    pushItem(defaultItemFormat(newId.toString()));
  };

  const removeRoleplayingEvaluation = (
    roleplayingEvaluation: FormRoleplayingEvaluationType,
  ) => {
    if (roleplayingEvaluation.isNew) {
      findAndRemove((item) => item.id === roleplayingEvaluation.id);
      return;
    }

    isManager
      ? deleteManagersRoleplayingEvaluation(
          {
            roleplayingConditionId: roleplayingConditionId,
            id: roleplayingEvaluation.id,
          },
          {
            onSuccess: (data) => {
              findAndRemove((item) => item.id === roleplayingEvaluation.id);
              toast(data.message);
            },
          },
        )
      : deleteRoleplayingEvaluation(
          {
            roleplayingConditionId: roleplayingConditionId,
            id: roleplayingEvaluation.id,
          },
          {
            onSuccess: (data) => {
              findAndRemove((item) => item.id === roleplayingEvaluation.id);
              toast(data.message);
            },
          },
        );
  };

  const saveRoleplayingEvaluation = (
    roleplayingEvaluation: FormRoleplayingEvaluationType,
  ) => {
    roleplayingEvaluation.isNew
      ? createRoleplayingEvaluation(roleplayingEvaluation)
      : updateRoleplayingEvaluation(roleplayingEvaluation);
  };

  const createRoleplayingEvaluation = (
    roleplayingEvaluation: FormRoleplayingEvaluationType,
  ) => {
    isManager
      ? postManagersRoleplayingEvaluation(
          {
            roleplayingConditionId: roleplayingConditionId,
            body: generateParams(roleplayingEvaluation),
          },
          {
            onSuccess: (data) => {
              onSuccessAction({
                successMessage: data.message,
                roleplayingEvaluation,
                newRoleplayingEvaluationId: data.roleplayingEvaluation.id,
              });
            },
          },
        )
      : postRoleplayingEvaluation(
          {
            roleplayingConditionId: roleplayingConditionId,
            body: generateParams(roleplayingEvaluation),
          },
          {
            onSuccess: (data) => {
              onSuccessAction({
                successMessage: data.message,
                roleplayingEvaluation,
                newRoleplayingEvaluationId: data.roleplayingEvaluation.id,
              });
            },
          },
        );
  };

  const updateRoleplayingEvaluation = (
    roleplayingEvaluation: FormRoleplayingEvaluationType,
  ) => {
    isManager
      ? putManagersRoleplayingEvaluation(
          {
            roleplayingConditionId: roleplayingConditionId,
            id: roleplayingEvaluation.id,
            body: generateParams(roleplayingEvaluation),
          },
          {
            onSuccess: (data) => {
              onSuccessAction({
                successMessage: data.message,
                roleplayingEvaluation,
                newRoleplayingEvaluationId: data.roleplayingEvaluation.id,
              });
            },
          },
        )
      : putRoleplayingEvaluation(
          {
            roleplayingConditionId: roleplayingConditionId,
            id: roleplayingEvaluation.id,
            body: generateParams(roleplayingEvaluation),
          },
          {
            onSuccess: (data) => {
              onSuccessAction({
                successMessage: data.message,
                roleplayingEvaluation,
                newRoleplayingEvaluationId: data.roleplayingEvaluation.id,
              });
            },
          },
        );
  };

  const onSuccessAction = ({
    successMessage,
    newRoleplayingEvaluationId,
    roleplayingEvaluation,
  }: {
    successMessage: string;
    newRoleplayingEvaluationId: string;
    roleplayingEvaluation: FormRoleplayingEvaluationType;
  }) => {
    toast(successMessage);
    const newObject = {
      ...roleplayingEvaluation,
      id: newRoleplayingEvaluationId,
      isNew: undefined,
      isReadOnly: true,
    };
    findAndReplace(newObject, (item) => item.id === roleplayingEvaluation.id);
  };

  const generateParams = (
    roleplayingEvaluation: FormRoleplayingEvaluationType,
  ) => {
    return {
      myEvaluationGoodContent: roleplayingEvaluation.myEvaluationGoodContent,
      myEvaluationImproveContent:
        roleplayingEvaluation.myEvaluationImproveContent,
      otherUserEvaluationGoodContent:
        roleplayingEvaluation.otherUserEvaluationGoodContent,
      otherUserEvaluationImproveContent:
        roleplayingEvaluation.otherUserEvaluationImproveContent,
    };
  };

  const onChangeMyEvaluationGoodContent = ({
    roleplayingEvaluation,
    newValue,
  }: OnChangeStringPropsType) => {
    const newObject = {
      ...roleplayingEvaluation,
      myEvaluationGoodContent: newValue,
    };

    findAndReplace(newObject, (item) => item.id === roleplayingEvaluation.id);
  };

  const onChangeMyEvaluationImproveContent = ({
    roleplayingEvaluation,
    newValue,
  }: OnChangeStringPropsType) => {
    const newObject = {
      ...roleplayingEvaluation,
      myEvaluationImproveContent: newValue,
    };

    findAndReplace(newObject, (item) => item.id === roleplayingEvaluation.id);
  };

  const onChangeOtherUserEvaluationGoodContent = ({
    roleplayingEvaluation,
    newValue,
  }: OnChangeStringPropsType) => {
    const newObject = {
      ...roleplayingEvaluation,
      otherUserEvaluationGoodContent: newValue,
    };

    findAndReplace(newObject, (item) => item.id === roleplayingEvaluation.id);
  };

  const onChangeOtherUserEvaluationImproveContent = ({
    roleplayingEvaluation,
    newValue,
  }: OnChangeStringPropsType) => {
    const newObject = {
      ...roleplayingEvaluation,
      otherUserEvaluationImproveContent: newValue,
    };

    findAndReplace(newObject, (item) => item.id === roleplayingEvaluation.id);
  };

  const setNotReadOnly = (
    roleplayingEvaluation: FormRoleplayingEvaluationType,
  ) => {
    const newObject = {
      ...roleplayingEvaluation,
      isReadOnly: false,
    };

    findAndReplace(newObject, (item) => item.id === roleplayingEvaluation.id);
  };

  return {
    roleplayingEvaluations: items,
    addRoleplayingEvaluation: addItem,
    onChangeMyEvaluationGoodContent,
    onChangeMyEvaluationImproveContent,
    onChangeOtherUserEvaluationGoodContent,
    onChangeOtherUserEvaluationImproveContent,
    removeRoleplayingEvaluation,
    saveRoleplayingEvaluation,
    setNotReadOnly,
  };
}
