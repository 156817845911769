import React, { FC } from "react";

import { useApisMembersQuestionAnswersClicksCreate, useBoolean } from "~/hooks";

import { Icon } from "~/components/atoms";

import { QuestionAnswerType } from "~/domains";

type PropsType = {
  questionAnswer: QuestionAnswerType;
};

export const QuestionAnswer: FC<PropsType> = ({
  questionAnswer,
}: PropsType) => {
  const {
    questionContent,
    answerContent,
    useScene,
    importantPoint,
    remark,
    link,
  } = questionAnswer;
  const { setTrue, setFalse, isChecked } = useBoolean(false);

  const { mutate: clickPostRequest } =
    useApisMembersQuestionAnswersClicksCreate();

  const handleTrue = () => {
    setTrue();
    clickPostRequest({ questionAnswerId: questionAnswer.id });
  };

  return (
    <div className="border border-primary-600 p-4 rounded">
      <div
        className="flex justify-between cursor-pointer items-center font-semibold"
        onClick={isChecked ? setFalse : handleTrue}
      >
        <p className={isChecked ? "" : "truncate"}>
          <span className="text-primary-600 font-semibold">Q</span>
          <span className="ml-2">{questionContent}</span>
        </p>
        <Icon
          icon={isChecked ? "ioRemoveOutline" : "ioAdd"}
          size="1.5rem"
          className="ml-2 shrink-0"
        />
      </div>
      {isChecked && (
        <div className="mt-2 pt-4 border-t border-solid border-secondary-300 space-y-6">
          <div>
            <span className="bg-primary-600 text-white rounded px-4 py-1 text-sm">
              回答
            </span>
            <p className="pt-2 whitespace-pre-line">{answerContent}</p>
          </div>
          <div>
            <span className="bg-primary-600 text-white rounded px-4 py-1 text-sm">
              よくある状況
            </span>
            <p className="pt-2 whitespace-pre-line">{useScene}</p>
          </div>
          <div>
            <span className="bg-primary-600 text-white rounded px-4 py-1 text-sm">
              ポイント
            </span>
            <p className="pt-2 whitespace-pre-line">{importantPoint}</p>
          </div>
          <div>
            <span className="bg-primary-600 text-white rounded px-4 py-1 text-sm">
              参考リンク
            </span>
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="pt-2 block text-primary-600"
            >
              {link}
            </a>
          </div>
          <div>
            <span className="bg-primary-600 text-white rounded px-4 py-1 text-sm">
              備考
            </span>
            <p className="pt-2 whitespace-pre-line">{remark}</p>
          </div>
        </div>
      )}
    </div>
  );
};
