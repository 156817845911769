import { SlideNavigationType } from "~/components/molecules";

import {
  EmployeeRoleType,
  MemberRole,
  ManagerRole,
  OwnerRole,
} from "~/domains";

type ReturnType = {
  navigations: SlideNavigationType[];
};

type PropsType = {
  selectedRole?: EmployeeRoleType;
  businessSectionId?: string;
};

export const useQuestionAnswerNavigation = ({
  selectedRole,
  businessSectionId = "",
}: PropsType): ReturnType => {
  const navigations = [
    {
      label: "本部の発信",
      href: `/question_answer_category/owner?business_section_id=${businessSectionId}`,
      selected: selectedRole === OwnerRole,
    },
    {
      label: "部署の発信",
      href: `/question_answer_category/manager?business_section_id=${businessSectionId}`,
      selected: selectedRole === ManagerRole,
    },
    {
      label: "投稿",
      href: `/question_answer_category/member?business_section_id=${businessSectionId}`,
      selected: selectedRole === MemberRole,
    },
  ];

  return {
    navigations: navigations,
  };
};
