import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersEmployeeSurveysDestroy,
  useSearchManagersEmployeeSurveys,
  useApisManagersEmployeeSurveysDuplicatesCreate,
} from "~/hooks";

import {
  FormSubmitButton,
  Form,
  Button,
  GridHeaderCells,
  BooleanDropDownField,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  GridRow,
  OpenedMenuInGrid,
  LabelWithTextField,
  LabelWithDropDownField,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { EmployeeSurveyType } from "~/domains";

export const ManagersEmployeeSurveysIndex: FC = () => {
  const {
    employeeSurveys,
    keyword,
    isLoading,
    allBusinessSectionsDistribution,
    hasNextPage,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    unshiftItem,
    onDivisionChange,
    onSectionChange,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    fetchNextPage,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
    setAllBusinessSectionsDistribution,
  } = useSearchManagersEmployeeSurveys();
  const { mutate: deleteRequest, isLoading: isDeleting } =
    useApisManagersEmployeeSurveysDestroy();
  const { mutate: duplicateRequest, isLoading: isSubmitting } =
    useApisManagersEmployeeSurveysDuplicatesCreate();

  const handleDelete = (employeeSurvey: EmployeeSurveyType) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;
    deleteRequest(
      {
        id: employeeSurvey.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((item) => item.id === employeeSurvey.id);
        },
      },
    );
  };

  const generateEmployeeSurveyLists = (employeeSurvey: EmployeeSurveyType) => {
    return [
      employeeSurvey.title,
      employeeSurvey.allBusinessSectionsDistribution
        ? "すべての部署"
        : employeeSurvey.businessDivisions
            .map((division) => division.name)
            .join(","),
      employeeSurvey.allBusinessSectionsDistribution
        ? "すべての課"
        : employeeSurvey.businessSections
            .map((section) => section.name)
            .join(","),
      employeeSurvey.publicStatus.name,
    ];
  };

  const duplicateRequestEmployeeSurvey = (
    targetEmployeeSurvey: EmployeeSurveyType,
  ) => {
    const isConfirm = confirm("本当に複製しますか？");
    if (!isConfirm) return;
    duplicateRequest(
      {
        employeeSurveyId: targetEmployeeSurvey.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          unshiftItem(data.employeeSurvey);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "チェックアウト作成", href: "/managers/employee_surveys" },
    { label: "一覧", href: "/managers/employee_surveys" },
  ];

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <Form onSubmit={onSearchSubmit} className="space-y-6">
          <div className="grid miniTablet:grid-cols-2 gap-4">
            <MultiParentsWithMultiChildrenDropdownField
              allParents={selectableDropdownDivisions}
              parentsValue={selectedDropdownDivisions}
              parentLabel="部署"
              parentsOnChange={onDivisionChange}
              allChildren={selectableDropdownSections}
              childrenValue={selectedDropdownSections}
              childLabel="課"
              childrenOnChange={onSectionChange}
            />
            <BooleanDropDownField
              name="isAllBusinessSections"
              trueLabel="すべての課に配布する"
              falseLabel="個別の課に配布する"
              labelText="配布種別の選択"
              value={allBusinessSectionsDistribution}
              onChange={setAllBusinessSectionsDistribution}
            />
            <LabelWithDropDownField
              labelText="ステータス"
              name="publicStatus"
              options={selectableDropdownPublicStatusIds}
              value={selectedDropdownPublicStatusId}
              onChange={onChangeDropdownPublicStatusId}
            />
            <LabelWithTextField
              labelText="フリーワード"
              type="search"
              name="keyword"
              placeholder="フリーワード検索"
              value={keyword}
              onChange={onChangeKeyword}
            />
          </div>
          <div className="flex items-center justify-end space-x-4">
            <Button
              color="gray"
              outline
              className="w-full miniTablet:w-auto"
              text="リセット"
              onClick={onConditionReset}
            />
            <FormSubmitButton
              value="検索する"
              color="primary"
              className="w-full miniTablet:w-auto"
            />
          </div>
        </Form>
        <InfiniteScrollWithMoreButton
          itemsLength={employeeSurveys.length}
          nextFetchFunction={fetchNextPage}
          isLoading={isLoading}
          hasMore={hasNextPage}
          className="mt-9"
        >
          <div className="grid grid-cols-4">
            <GridHeaderCells texts={["カテゴリ", "部署", "課", "ステータス"]} />
            {employeeSurveys.map((employeeSurvey) => (
              <GridRow
                key={employeeSurvey.id}
                lists={generateEmployeeSurveyLists(employeeSurvey)}
                openedMenu={
                  <OpenedMenuInGrid
                    cols={4}
                    buttons={[
                      {
                        text: "編集",
                        link: `/managers/employee_surveys/${employeeSurvey.id}/edit`,
                      },
                      {
                        text: "削除",
                        onClick: () => handleDelete(employeeSurvey),
                        readonly: isSubmitting || isDeleting,
                      },
                      {
                        text: "分析",
                        link: `/managers/employee_surveys/${employeeSurvey.id}/summary/submissions`,
                      },
                      {
                        text: "複製",
                        onClick: () =>
                          duplicateRequestEmployeeSurvey(employeeSurvey),
                        readonly: isSubmitting || isDeleting,
                      },
                    ]}
                  />
                }
              />
            ))}
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
