import React, { FC, ReactNode } from "react";

import { ButtonWithIcon } from "~/components/atoms";

type PropsType = {
  onClose: () => void;
  headerTitle?: string;
  isWhite?: boolean;
  children?: ReactNode;
  wrapperClassName?: string;
  className?: string;
};

export const ModalWrapper: FC<PropsType> = ({
  onClose,
  children,
  headerTitle,
  isWhite = false,
  wrapperClassName = "",
  className = "",
}: PropsType) => {
  return (
    <>
      <div
        className={`
          fixed
          inset-0
          flex
          items-center
          justify-center
          z-modal
          overflow-y-auto
          py-16
          ${isWhite ? "" : "bg-black/50"}
          ${wrapperClassName}
        `}
        onClick={onClose}
      />
      {children && (
        <dialog
          className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 my-auto pt-4 pb-8 px-8
            inline-block bg-white border-solid border border-gray-300 miniTablet:rounded-lg shadow z-dialog
            w-full h-full overflow-y-auto miniTablet:w-9/12 miniTablet:h-auto ${className}`}
        >
          <div className="miniTablet:max-w-[80dvw] max-h-[85dvh] hidden-scrollbar overflow-y-auto">
            {headerTitle && (
              <div className="sticky top-0 bg-white z-modal flex justify-between items-center py-2 rounded-t border-b">
                <h3 className="text-xl font-semibold">{headerTitle}</h3>
                <ButtonWithIcon
                  onClick={onClose}
                  className="inline-flex items-center"
                  withHoverEffect
                  srOnlyText="閉じる"
                  icon={{
                    icon: "ioCloseOutline",
                    size: "2rem",
                    color: "text-secondary-600",
                  }}
                />
              </div>
            )}
            {children}
          </div>
        </dialog>
      )}
    </>
  );
};
