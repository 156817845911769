import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useBoolean,
  useApisMembersFocusChallengesNew,
  useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesIndex,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  FocusChallengeEmployeeDetailWithEditForm,
  FocusChallengeEmployeeRepliesWithFocusChallengeEmployee,
} from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const MembersFocusChallengesShow: FC = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();
  const { isChecked: isOpen, toggle: openReply } = useBoolean(true);
  const breadCrumbItems = [
    { label: "チャレンジ", href: "/focus_challenges" },
    { label: "詳細", href: `/focus_challenges/${id}` },
  ];

  const { data: fetchNew } = useApisMembersFocusChallengesNew();
  const {
    data: replayFetch,
    refetch,
    isError,
  } = useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesIndex({
    focusChallengeEmployeeId: id,
  });

  if (isError) return <NotFoundIndex />;

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
          {replayFetch && (
            <FocusChallengeEmployeeDetailWithEditForm
              mentions={fetchNew?.mentionableEmployees || []}
              selectableProvidingServices={
                fetchNew?.selectableProvidingServices || []
              }
              selectableJobCategoryPhases={
                fetchNew?.selectableJobCategoryPhases || []
              }
              focusChallengeEmployee={replayFetch?.focusChallengeEmployee}
              openReply={openReply}
              deleteItem={() => navigate("/focus_challenges")}
              isHighlight={replayFetch?.focusChallengeEmployee?.id === id}
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          {isOpen && replayFetch && (
            <>
              <RightSidebarTitle title="リプライ" />
              <FocusChallengeEmployeeRepliesWithFocusChallengeEmployee
                className="mt-6 mx-4"
                mentions={fetchNew?.mentionableEmployees || []}
                focusChallengeEmployee={replayFetch.focusChallengeEmployee}
                openReply={openReply}
                resetPageFetch={refetch}
                focusChallengeReplies={
                  replayFetch.focusChallengeEmployeeReplies
                }
              />
            </>
          )}
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
