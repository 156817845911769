import React, { FC, FormEvent, ChangeEvent, MouseEvent } from "react";

import {
  ModalWrapper,
  Form,
  Icon,
  FormSubmitButton,
  Button,
} from "~/components/atoms";
import { LabelWithRadioButton } from "~/components/molecules";

import {
  InsightPostBookmarkFolderType,
  KnowledgeInsightPostBookmarkFolderType,
} from "~/domains";

type PropsType = {
  onClose: () => void;
  bookmarkFolders:
    | InsightPostBookmarkFolderType[]
    | KnowledgeInsightPostBookmarkFolderType[];
  bookmarkFolderId: string;
  onChangeBookmarkFolderId: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const BookmarkMoveModal: FC<PropsType> = ({
  onClose,
  bookmarkFolders,
  bookmarkFolderId,
  onChangeBookmarkFolderId,
  onSubmit,
}: PropsType) => {
  const handleOnClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClose();
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="フォルダの移動先">
      <Form onSubmit={onSubmit} className="space-y-5 mt-4">
        <div>
          <p className="flex items-center mb-2">
            <Icon icon="ioFolderOpenOutline" size="1.25rem" />
            <span className="ml-1">ブックマークフォルダ</span>
          </p>
          <div className="space-y-3 ml-2">
            {bookmarkFolders.map((bookmarkFolder) => (
              <LabelWithRadioButton
                key={bookmarkFolder.id}
                labelText={bookmarkFolder.name}
                name={bookmarkFolder.id}
                value={bookmarkFolder.id}
                checked={bookmarkFolderId === bookmarkFolder.id}
                onChange={onChangeBookmarkFolderId}
              />
            ))}
            <LabelWithRadioButton
              labelText="ブックマークフォルダなし"
              name="noBookmarkFolder"
              value=""
              checked={bookmarkFolderId === ""}
              onChange={onChangeBookmarkFolderId}
            />
          </div>
        </div>
        <div className="flex items-center justify-end space-x-4">
          <Button
            outline
            color="gray"
            onClick={handleOnClose}
            text="キャンセル"
          />
          <FormSubmitButton value="移動する" color="primary" />
        </div>
      </Form>
    </ModalWrapper>
  );
};
