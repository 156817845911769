import React, { FC, ReactNode } from "react";

import { TextWithHr } from "~/components/atoms";

type PropsType = {
  title: string;
  children: ReactNode;
  className?: string;
};

export const TitleWithChild: FC<PropsType> = ({
  title,
  children,
  className = "",
}: PropsType) => {
  return (
    <div>
      <TextWithHr label={title} />
      <div className={`mt-3.5 ${className}`}>{children}</div>
    </div>
  );
};
