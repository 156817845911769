import React, { FC } from "react";

import { useApisMembersAnalysisInsightPostObjectivesIndex } from "~/hooks";

import { GridHeaderCells, ModalWrapper } from "~/components/atoms";
import { GridRow, InfiniteScrollWithMoreButton } from "~/components/molecules";

type PropsType = {
  onClose: () => void;
  className?: string;
};

export const PastInsightPostPerformancesModal: FC<PropsType> = ({
  onClose,
  className = "",
}: PropsType) => {
  const { fetchNextPage, hasNextPage, isFetching, data } =
    useApisMembersAnalysisInsightPostObjectivesIndex();

  const calculatePerformances =
    data?.pages.map((page) => page.calculatePerformances).flat() || [];

  return (
    <ModalWrapper
      onClose={onClose}
      headerTitle={"過去のインサイト目標とポイント"}
      className={className}
    >
      <InfiniteScrollWithMoreButton
        itemsLength={calculatePerformances?.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        isLoading={isFetching}
        className={"mt-9"}
        scrollClassName="space-y-6"
      >
        <div className="grid grid-cols-3">
          <GridHeaderCells texts={["期間", "目標", "ポイント"]} />
          {calculatePerformances?.map((calculatePerformance) => (
            <GridRow
              key={calculatePerformance.title}
              lists={[
                `${calculatePerformance.startDate}~${calculatePerformance.endDate}`,
                `${calculatePerformance.performanceCount} / ${calculatePerformance.objectivePerformance}`,
                `${calculatePerformance.performancePoint} / ${calculatePerformance.objectivePerformance}`,
              ]}
            />
          ))}
        </div>
      </InfiniteScrollWithMoreButton>
    </ModalWrapper>
  );
};
