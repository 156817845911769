import React, { FC } from "react";

import {
  useApisManagersBelongingEmployeesSkillEvaluationTermsIndex,
  useSearchManagersSkillEvaluationTerms,
} from "~/hooks";

import { TabItemLinks } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import { ManagersSkillEvaluationTermsIndexTemplate } from "~/components/templates";

export const ManagersBelongingEmployeesSkillEvaluationTermsIndex: FC = () => {
  const breadCrumbItems = [
    { label: "スキルチェック", href: "/managers/skill_evaluation_terms" },
    { label: "一覧", href: "/managers/skill_evaluation_terms" },
  ];

  const {
    skillEvaluationTerms,
    titleKeyword,
    isLoading,
    hasNextPage,
    startDate,
    endDate,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    onDivisionChange,
    onSectionChange,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    fetchNextPage,
    onChangeDateRange,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  } = useSearchManagersSkillEvaluationTerms({
    useApisSkillEvaluationTermsIndex:
      useApisManagersBelongingEmployeesSkillEvaluationTermsIndex,
  });
  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <TabItemLinks
          items={[
            {
              name: "チームに蓄積",
              href: "/managers/skill_evaluation_terms",
              isActive: false,
            },
            {
              name: "ユーザー紐付き",
              href: "/managers/belonging_employees/skill_evaluation_terms",
              isActive: true,
            },
          ]}
        />
        <ManagersSkillEvaluationTermsIndexTemplate
          className="mt-8"
          skillEvaluationTerms={skillEvaluationTerms}
          titleKeyword={titleKeyword}
          startDate={startDate}
          endDate={endDate}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          selectableDropdownPublicStatusIds={selectableDropdownPublicStatusIds}
          selectedDropdownPublicStatusId={selectedDropdownPublicStatusId}
          selectableDropdownDivisions={selectableDropdownDivisions}
          selectedDropdownDivisions={selectedDropdownDivisions}
          selectableDropdownSections={selectableDropdownSections}
          selectedDropdownSections={selectedDropdownSections}
          onDivisionChange={onDivisionChange}
          onSectionChange={onSectionChange}
          findAndRemove={findAndRemove}
          onChangeDropdownPublicStatusId={onChangeDropdownPublicStatusId}
          fetchNextPage={fetchNextPage}
          onChangeDateRange={onChangeDateRange}
          onChangeKeyword={onChangeKeyword}
          onConditionReset={onConditionReset}
          onSearchSubmit={onSearchSubmit}
        />
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
