import React, { FC, useState } from "react";

import {
  useApisMembersKaizenIdeasKaizenIdeaRepliesIndex,
  useInterval,
  useRightSidebarState,
  useSearchManagersKaizenIdeas,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  FilterIconWithSortMenu,
  InfiniteScroll,
  RightSidebarToggleIconWithLabel,
} from "~/components/molecules";
import {
  KaizenIdeaDetailWithEditForm,
  KaizenIdeaRepliesWithKaizenIdea,
  KaizenIdeaSearchForm,
} from "~/components/organisms";

import { KaizenIdeaType } from "~/domains";

export const ManagersFeedbackKaizenIdeasIndex: FC = () => {
  const {
    isSearchOpen,
    isReplyOpen,
    searchOpenToggle,
    setReplyOpen,
    setClose,
    isOpen,
  } = useRightSidebarState("isClose");

  const {
    kaizenIdeas,
    keyword,
    isLoading,
    hasNextPage,
    startDate,
    endDate,
    mentionableEmployees,
    selectableDropdownCompleteStatusIds,
    selectedDropdownCompleteStatusId,
    selectableDropdownIsFromClientIds,
    selectedDropdownIsFromClientId,
    sort,
    isBelongingEmployee,
    selectableDropdownPriorityRatings,
    selectedDropdownPriorityRatings,
    selectableDropdownProvidingServices,
    selectedDropdownProvidingServices,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    selectableDropdownArchivedEmployees,
    selectedDropdownArchivedEmployees,
    onDivisionChange,
    onSectionChange,
    onEmployeesChange,
    onArchivedEmployeesChange,
    updateIndexKaizenIdea,
    deleteIndexKaizenIdea,
    fetchNextPage,
    setSort,
    onChangeDropdownPriorityRatings,
    onChangeDropdownProvidingServices,
    onChangeDateRange,
    onChangeKeyword,
    onChangeIsBelongingEmployee,
    onChangeDropdownCompleteStatusId,
    onChangeDropdownIsFromClientId,
    onSearchSubmit,
    onConditionReset,
  } = useSearchManagersKaizenIdeas();

  const [selectGetReplyKaizenIdeaId, setSelectGetReplyKaizenIdeaId] =
    useState<string>("");

  const { data: kaizenIdeaRepliesIndexData, refetch: repliesRefetch } =
    useApisMembersKaizenIdeasKaizenIdeaRepliesIndex({
      kaizenIdeaId: selectGetReplyKaizenIdeaId,
      config: {
        enabled: Boolean(selectGetReplyKaizenIdeaId),
      },
    });

  useInterval({
    onUpdate: async () => {
      isReplyOpen && selectGetReplyKaizenIdeaId && (await repliesRefetch());
    },
    intervalMs: 1000,
  });

  const openComment = (KaizenIdeaId: string) => {
    setSelectGetReplyKaizenIdeaId(KaizenIdeaId);
    setReplyOpen();
  };

  const breadCrumbItems = [
    { label: "改善・相談", href: "/managers/feedback/kaizen_ideas" },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
        <FilterIconWithSortMenu
          likeSort={() => setSort("likeCountDesc")}
          createdAtAscSort={() => setSort("createdAtAsc")}
          createdAtDescSort={() => setSort("createdAtDesc")}
          currentSortType={sort}
          className="mt-6"
        />
        <InfiniteScroll
          itemsLength={kaizenIdeas.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isLoading}
          className="border-t-2 border-secondary-200"
        >
          {kaizenIdeas.map((post, index) => (
            <KaizenIdeaDetailWithEditForm
              key={post.id}
              kaizenIdea={post}
              openComment={openComment}
              updateItem={(newItem: KaizenIdeaType) =>
                updateIndexKaizenIdea(newItem, index)
              }
              deleteItem={() => deleteIndexKaizenIdea(index)}
            />
          ))}
        </InfiniteScroll>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={setClose}
          label={isSearchOpen ? "検索条件" : "リプライ"}
          isOpen={isOpen}
        >
          {isSearchOpen && (
            <KaizenIdeaSearchForm
              onSubmit={onSearchSubmit}
              onConditionReset={onConditionReset}
              date={{
                startDate: startDate,
                endDate: endDate,
                onChange: onChangeDateRange,
              }}
              providingService={{
                options: selectableDropdownProvidingServices,
                value: selectedDropdownProvidingServices,
                onChange: onChangeDropdownProvidingServices,
              }}
              priorityRating={{
                options: selectableDropdownPriorityRatings,
                value: selectedDropdownPriorityRatings,
                onChange: onChangeDropdownPriorityRatings,
              }}
              isBelongingEmployee={{
                isChecked: isBelongingEmployee,
                onChange: onChangeIsBelongingEmployee,
              }}
              divisionAndSections={{
                selectableDivisions: selectableDropdownDivisions,
                selectedDivisions: selectedDropdownDivisions,
                selectableSections: selectableDropdownSections,
                selectedSection: selectedDropdownSections,
                selectableEmployees: selectableDropdownEmployees,
                selectedEmployees: selectedDropdownEmployees,
                selectableDropdownArchivedEmployees,
                selectedDropdownArchivedEmployees,
                onDivisionChange,
                onSectionChange,
                onEmployeesChange,
                onArchivedEmployeesChange,
              }}
              kaizenCompleted={{
                options: selectableDropdownCompleteStatusIds,
                value: selectedDropdownCompleteStatusId,
                onChange: onChangeDropdownCompleteStatusId,
              }}
              isFromClient={{
                options: selectableDropdownIsFromClientIds,
                value: selectedDropdownIsFromClientId,
                onChange: onChangeDropdownIsFromClientId,
              }}
              searchKeyword={{
                value: keyword,
                onChange: onChangeKeyword,
              }}
            />
          )}
          {isReplyOpen && kaizenIdeaRepliesIndexData && (
            <KaizenIdeaRepliesWithKaizenIdea
              className="mt-6"
              kaizenIdea={kaizenIdeaRepliesIndexData.kaizenIdea}
              mentions={mentionableEmployees}
              kaizenIdeaReplies={
                kaizenIdeaRepliesIndexData.kaizenIdeaReplies || []
              }
              refetch={repliesRefetch}
            />
          )}
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
