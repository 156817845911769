import React, { MouseEvent, FC } from "react";

import { useBoolean } from "~/hooks";

import { ModalWrapper, ButtonWithIcon } from "~/components/atoms";

import { SortType } from "~/domains";

type PropsType = {
  className?: string;
  likeSort: () => void;
  bookmarkSort?: () => void;
  createdAtAscSort: () => void;
  createdAtDescSort: () => void;
  currentSortType: SortType;
};

export const FilterIconWithSortMenu: FC<PropsType> = ({
  likeSort,
  bookmarkSort,
  createdAtAscSort,
  createdAtDescSort,
  className = "",
  currentSortType,
}: PropsType) => {
  const {
    setFalse: setClose,
    setTrue: setOpen,
    isChecked: isOpen,
  } = useBoolean(false);
  const likeSortClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setClose();
    likeSort();
  };
  const bookmarkSortClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!bookmarkSort) return;
    setClose();
    bookmarkSort();
  };
  const createdAtAscSortClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setClose();
    createdAtAscSort();
  };
  const createdAtADescSortClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setClose();
    createdAtDescSort();
  };

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen();
  };

  const selectedLiClass = "text-primary-600 font-semibold";
  return (
    <div className={`relative flex justify-end ${className}`}>
      <ButtonWithIcon
        srOnlyText="並び替えをする"
        icon={{
          icon: "ioFunnelOutline",
          color: "text-secondary-600",
          size: "1.25rem",
        }}
        onClick={handleOpen}
      />
      {isOpen && (
        <>
          <ModalWrapper isWhite onClose={setClose} />
          <ul
            className="absolute right-0 z-modal min-w-max shadow
                          p-3.5 bg-white border border-solid
                          border-gray-200 rounded-lg top-full"
          >
            <li>
              <button
                type="button"
                className={`w-full text-left hover:bg-secondary-100 py-1.5 px-1 rounded ${
                  currentSortType === "createdAtDesc" && selectedLiClass
                }`}
                onClick={createdAtADescSortClick}
              >
                新しい順
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`w-full text-left hover:bg-secondary-100 py-1.5 px-1 rounded ${
                  currentSortType === "createdAtAsc" && selectedLiClass
                }`}
                onClick={createdAtAscSortClick}
              >
                古い順
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`w-full text-left hover:bg-secondary-100 py-1.5 px-1 rounded ${
                  currentSortType === "likeCountDesc" && selectedLiClass
                }`}
                onClick={likeSortClick}
              >
                いいね数順
              </button>
            </li>
            {bookmarkSort && (
              <li>
                <button
                  type="button"
                  className={`w-full text-left hover:bg-secondary-100 py-1.5 px-1 rounded ${
                    currentSortType === "bookmarkCountDesc" && selectedLiClass
                  }`}
                  onClick={bookmarkSortClick}
                >
                  ブックマーク数順
                </button>
              </li>
            )}
          </ul>
        </>
      )}
    </div>
  );
};
