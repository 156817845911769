import React, { FC } from "react";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { InsightPostObjectiveForm } from "~/components/organisms";

export const ManagersInsightPostObjectivesNew: FC = () => {
  const breadCrumbItems = [
    { label: "インサイト目標", href: "/managers/insight_post_objectives" },
    { label: "新規作成", href: "/managers/insight_post_objectives/new" },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <InsightPostObjectiveForm />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
