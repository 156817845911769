import React, { FC } from "react";

import {
  useBoolean,
  useFooters,
  useCounter,
  useApisMembersEmployeeSurveySubmissionRepliesLikesCreate,
  useApisMembersEmployeeSurveySubmissionRepliesLikesDestroy,
} from "~/hooks";

import { PostDetailWrapper } from "~/components/atoms";
import {
  PostHeader,
  PostContent,
  EmployeeSurveySubmissionRepliesLikedEmployeesModal,
} from "~/components/organisms";

import {
  EmployeeSurveySubmissionReplyType,
  PostDetailMenuItemType,
} from "~/domains";

type PropsType = {
  employeeSurveySubmissionReply: EmployeeSurveySubmissionReplyType;
  menuItems: PostDetailMenuItemType[];
  isHighlight?: boolean;
};

export const EmployeeSurveySubmissionReplyDetail: FC<PropsType> = ({
  employeeSurveySubmissionReply,
  menuItems,
  isHighlight = false,
}: PropsType) => {
  const {
    isChecked: isLikedEmployeesModalOpen,
    setTrue: setLikedEmployeesModalOpen,
    setFalse: setLikedEmployeesModalClose,
  } = useBoolean(false);

  const {
    isChecked: liked,
    setTrue: setLiked,
    setFalse: setNotLiked,
  } = useBoolean(employeeSurveySubmissionReply.liked);
  const {
    count: likeCount,
    increment: likeIncrement,
    decrement: likeDecrement,
  } = useCounter(employeeSurveySubmissionReply.likesCount);

  const { mutate: likeCreateRequest } =
    useApisMembersEmployeeSurveySubmissionRepliesLikesCreate();
  const { mutate: likeDeleteRequest } =
    useApisMembersEmployeeSurveySubmissionRepliesLikesDestroy();

  const handleCreateLike = () => {
    likeCreateRequest(
      {
        employeeSurveySubmissionReplyId: employeeSurveySubmissionReply.id,
      },
      {
        onSuccess: () => {
          setLiked();
          likeIncrement();
        },
      },
    );
  };

  const handleDeleteLike = () => {
    likeDeleteRequest(
      {
        employeeSurveySubmissionReplyId: employeeSurveySubmissionReply.id,
      },
      {
        onSuccess: () => {
          setNotLiked();
          likeDecrement();
        },
      },
    );
  };

  const leftFooters = useFooters({
    like: {
      doneAlready: liked,
      undoOnClick: handleDeleteLike,
      doOnClick: handleCreateLike,
      textOnClick: setLikedEmployeesModalOpen,
      count: likeCount,
    },
  });

  return (
    <PostDetailWrapper isHighlight={isHighlight}>
      <PostHeader
        miniText={employeeSurveySubmissionReply.createdAt}
        postedEmployee={employeeSurveySubmissionReply.employee}
        menuItems={menuItems}
      />
      <PostContent
        content={employeeSurveySubmissionReply.content}
        images={employeeSurveySubmissionReply.attachFiles}
        footers={leftFooters.footers}
        rightFooters={[]}
        className="mt-2 pl-16"
      />
      {isLikedEmployeesModalOpen && (
        <EmployeeSurveySubmissionRepliesLikedEmployeesModal
          employeeSurveySubmissionReplyId={employeeSurveySubmissionReply.id}
          onClose={setLikedEmployeesModalClose}
        />
      )}
    </PostDetailWrapper>
  );
};
