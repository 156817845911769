import React, { FC } from "react";

import { useApisManagersSkillEvaluationTermsNew } from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { SkillEvaluationTermForm } from "~/components/organisms";

export const ManagersSkillEvaluationTermsNew: FC = () => {
  const breadCrumbItems = [
    { label: "スキルチェック", href: "/managers/skill_evaluation_terms" },
    { label: "新規作成", href: "/managers/skill_evaluation_terms/new" },
  ];

  const { data } = useApisManagersSkillEvaluationTermsNew();

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        {data && (
          <SkillEvaluationTermForm
            skillEvaluationStandardSummaryCategories={
              data.skillEvaluationStandardSummaryCategories
            }
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
