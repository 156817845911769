import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient, ApiResponseError } from "~/utils";

import { ApiManagersQuestionAnswerCategoriesEditType } from "~/domains";

type ParamsType = {
  id: string;
};

const request = async ({
  id,
}: ParamsType): Promise<ApiManagersQuestionAnswerCategoriesEditType> => {
  const response =
    await new ApiClient().get<ApiManagersQuestionAnswerCategoriesEditType>(
      `/api/managers/question_answer_categories/${id}/edit`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  id: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersQuestionAnswerCategoriesEdit = ({
  id,
  config = {},
}: PropsType): UseQueryResult<ApiManagersQuestionAnswerCategoriesEditType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersQuestionAnswerCategoriesEdit", id],
    cacheTime: 0,
    queryFn: () => request({ id }),
    useErrorBoundary: (error) => {
      return error instanceof ApiResponseError && error.status >= 500;
    },
  });
};
