import React, { useState, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import { toDateString } from "~/libs";

import {
  useMeetingNoteSlideNavigation,
  useApisMembersMeetingNotesEdit,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { DatePicker } from "~/components/molecules";
import {
  MeetingNoteDailyCountCalendar,
  MeetingNoteDetail,
} from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

import {
  ApiMembersMeetingNoteMutateType,
  ResponseMessageType,
} from "~/domains";

export const MembersMeetingNotesEdit: FC = () => {
  const query = new URLSearchParams(location.search);
  const paramsDate = query.get("date");

  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [postDate, setPostDate] = useState(
    paramsDate ? new Date(paramsDate) : new Date(),
  );
  const { navigations } = useMeetingNoteSlideNavigation(postDate, [
    false,
    true,
  ]);

  const { data, isError } = useApisMembersMeetingNotesEdit({
    id,
    config: {
      onSuccess: (data) => {
        setPostDate(new Date(data.meetingNote.postDate));
      },
    },
  });

  const putRequestOnSuccess = (data: ApiMembersMeetingNoteMutateType) => {
    toast(data.message);
    const date = toDateString(postDate);
    navigate(`/meeting_notes/${data.meetingNote.id}/edit?date=${date}`);
  };

  const deleteRequestOnSuccess = (data: ResponseMessageType) => {
    toast(data.message);
    const date = toDateString(postDate);
    navigate(`/meeting_notes?date=${date}`);
  };

  const breadCrumbItems = [
    { label: "1on1・MTG", href: "/meeting_notes/new" },
    { label: "一覧", href: "/meeting_notes" },
    { label: "編集", href: `/meeting_notes/${id}/edit` },
  ];

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        {data && (
          <>
            <DatePicker
              selectDate={postDate}
              onChange={setPostDate}
              parentClassName="mt-6"
            />
            <MeetingNoteDetail
              defaultReadOnly={false}
              meetingNote={{
                ...data.meetingNote,
                postDate: toDateString(postDate),
              }}
              selectableEmployees={data.selectableEmployees}
              deleteRequestOnSuccess={deleteRequestOnSuccess}
              putRequestOnSuccess={putRequestOnSuccess}
              className="mt-4"
            />
          </>
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarTitle title="Information" />
        <MeetingNoteDailyCountCalendar className="mt-6 mx-4" />
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
