import React, { MouseEvent, useState, FC } from "react";

import { QueryObserverResult } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  useBoolean,
  useClipboard,
  useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesDestroy,
  useEditorState,
  useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesUpdate,
} from "~/hooks";

import {
  FocusChallengeEmployeeReplyDetail,
  CommentEditForm,
} from "~/components/organisms";

import {
  FocusChallengeEmployeeReplyType,
  PostDetailMenuItemType,
  AvatarAndNameEmployeeType,
  PreviewWithFileType,
  ApiMembersFocusChallengeRepliesIndexType,
} from "~/domains";

import { Href } from "~/constants/postUrl";

type PropsType = {
  isHighlight?: boolean;
  focusChallengeEmployeeReply: FocusChallengeEmployeeReplyType;
  mentions: AvatarAndNameEmployeeType[];
  refetch: () => Promise<
    QueryObserverResult<ApiMembersFocusChallengeRepliesIndexType, unknown>
  >;
};

export const FocusChallengeEmployeeReplyDetailWithEditForm: FC<PropsType> = ({
  isHighlight = false,
  mentions,
  focusChallengeEmployeeReply,
  refetch,
}: PropsType) => {
  const { clipboardToCopy } = useClipboard();
  const {
    isChecked: isEditFormOpen,
    setFalse: closeEditForm,
    toggle: toggleEditForm,
  } = useBoolean(false);

  const [editorState] = useEditorState(focusChallengeEmployeeReply.content);
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);

  const { mutate: updateRequest, isLoading } =
    useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesUpdate();
  const { mutate: deleteRequest } =
    useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesDestroy();

  const deleteInsightPostCommentRequest = () => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;
    deleteRequest(
      {
        id: focusChallengeEmployeeReply.id,
        focusChallengeEmployeeId:
          focusChallengeEmployeeReply.focusChallengeEmployeeId,
      },
      {
        onSuccess: async (data) => {
          toast(data.message);
          await refetch();
        },
      },
    );
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const body = {
      content: editorState.jsonContent,
      attachFiles: files,
    };
    updateRequest(
      {
        focusChallengeEmployeeId:
          focusChallengeEmployeeReply.focusChallengeEmployeeId,
        id: focusChallengeEmployeeReply.id,
        body,
      },
      {
        onSuccess: async (data) => {
          toast(data.message);
          await refetch().then(() => closeEditForm());
        },
      },
    );
  };

  const detailItems: PostDetailMenuItemType[] = [
    {
      icon: "ioCopyOutline",
      text: "リンクをコピー",
      onClick: () =>
        clipboardToCopy(
          `${location.origin}${Href.FocusChallengeEmployeeReply(
            focusChallengeEmployeeReply.id,
          )}`,
        ),
      disabled: false,
    },
    {
      icon: "ioCreateOutline",
      text: "編集する",
      onClick: () => toggleEditForm(),
      disabled: !focusChallengeEmployeeReply.editable,
    },
    {
      icon: "ioTrashOutline",
      text: "削除する",
      onClick: deleteInsightPostCommentRequest,
      disabled: !focusChallengeEmployeeReply.editable,
    },
  ];

  return (
    <div className="" id={focusChallengeEmployeeReply.id}>
      {isEditFormOpen ? (
        <CommentEditForm
          submitButton={{
            isSubmitting: isLoading,
            onSubmit: handleSubmit,
          }}
          post={{
            createdAt: focusChallengeEmployeeReply.createdAt,
            employee: focusChallengeEmployeeReply.employee,
            content: focusChallengeEmployeeReply.content,
          }}
          closeEditForm={closeEditForm}
          files={files}
          setFiles={setFiles}
          editorStateContent={editorState.value}
          setEditorStateContent={editorState.onChange}
          mentions={mentions}
        />
      ) : (
        <FocusChallengeEmployeeReplyDetail
          focusChallengeEmployeeReply={focusChallengeEmployeeReply}
          menuItems={detailItems}
          isHighlight={isHighlight}
        />
      )}
    </div>
  );
};
