import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersBusinessSectionsLatestBusinessSectionCompetencyReviewsShowResponseType } from "~/domains";

type RequestDataType = {
  businessSectionId: string;
};

const request = async ({
  businessSectionId,
}: RequestDataType): Promise<ApiManagersBusinessSectionsLatestBusinessSectionCompetencyReviewsShowResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersBusinessSectionsLatestBusinessSectionCompetencyReviewsShowResponseType>(
      `/api/managers/business_sections/${businessSectionId}/latest_business_section_competency_review`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  businessSectionId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersBusinessSectionsLatestBusinessSectionCompetencyReviewsShow =
  ({
    businessSectionId,
    config = {},
  }: PropsType): UseQueryResult<ApiManagersBusinessSectionsLatestBusinessSectionCompetencyReviewsShowResponseType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisManagersBusinessSectionsLatestBusinessSectionCompetencyReviewsShow",
        businessSectionId,
      ],
      queryFn: () => request({ businessSectionId }),
      cacheTime: 0,
    });
  };
