import React, { FC } from "react";

import { useBoolean } from "~/hooks";

import { Button } from "~/components/atoms";
import { AreaChartBox, PieChartBox } from "~/components/molecules";
import { ScalableBarGraph } from "~/components/organisms/";

import { EmployeeSurveysSummariesAnswers } from "./EmployeeSurveysSummariesAnswers";

import {
  EmployeeSurveyQuestionSummaryGroupByDateType,
  EmployeeSurveyQuestionSummaryType,
} from "~/domains";

type PropsType = {
  summaries: EmployeeSurveyQuestionSummaryType[];
  summaryGroupDates?: EmployeeSurveyQuestionSummaryGroupByDateType[];
  title: string;
  className?: string;
};

export const EmployeeSurveySubmissionSummary: FC<PropsType> = ({
  summaries,
  summaryGroupDates,
  title,
  className = "",
}) => {
  const { isChecked: open, toggle } = useBoolean(false);
  const optionSummaries = summaries.filter(
    (summary) =>
      "answer" in summary && "point" in summary && "count" in summary,
  );

  return (
    <div className={className}>
      {optionSummaries.length > 0 && (
        <div className="grid grid-cols-1 miniTablet:grid-cols-2 miniTablet:gap-8 gap-12 mt-4 min-h-max">
          <PieChartBox
            isLoading={false}
            dataSets={optionSummaries.map((summary) => ({
              label: summary.answer,
              value: summary.count,
            }))}
            title={title}
            className=""
            onlyModalTitle
          />
          <ScalableBarGraph
            perforManceData={optionSummaries.map((summary) => ({
              name: summary.answer,
              value: summary.count,
            }))}
            isLoading={false}
            title={title}
            graphWrapperClassName=""
            onlyModalTitle
          />
          {summaryGroupDates && (
            <AreaChartBox
              isLoading={false}
              title={title}
              datasets={summaryGroupDates.map((summary) => ({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                name: summary.date,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                value: summary.point,
              }))}
              className="miniTablet:col-span-2"
              onlyModalTitle
            />
          )}
        </div>
      )}
      <Button
        onClick={toggle}
        color="primary"
        size="sm"
        className="mt-4"
        text={open ? "回答一覧を隠す" : "回答一覧を見る"}
      />
      {open && (
        <div className="mt-4 space-y-4">
          {summaries.map((summary, index) => (
            <EmployeeSurveysSummariesAnswers key={index} summary={summary} />
          ))}
        </div>
      )}
    </div>
  );
};
