import React, { FC, FormEvent } from "react";

import {
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
  useApisMembersInsightPostsSharesCreate,
  useBoolean,
} from "~/hooks";

import { ModalWrapper, Form, FormSubmitButton } from "~/components/atoms";
import { AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import {
  ResponseMessageType,
  InsightPostType,
  InsightPostCommentType,
} from "~/domains";

type PropsType = {
  originalInsightPost: InsightPostType | InsightPostCommentType;
  onClose: () => void;
  onSuccess: (data: ResponseMessageType) => void;
};

export const InsightPostShareFormModal: FC<PropsType> = ({
  originalInsightPost,
  onClose,
  onSuccess,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });

  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: setAllBusinessSectionsDistribution,
  } = useBoolean(false);

  const { mutate: postRequest, isLoading: isSubmitting } =
    useApisMembersInsightPostsSharesCreate();
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      allBusinessSectionsDistribution: allBusinessSectionsDistribution,
    };
    postRequest(
      {
        insightPostId: originalInsightPost.id,
        body,
      },
      {
        onSuccess: (data) => {
          onSuccess(data);
        },
      },
    );
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="ナレッジシェア">
      <Form className="space-y-8 mt-8" onSubmit={handleSubmit}>
        <AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentsOnChange={onDivisionChange}
          parentRequired={true}
          parentLabel="配布先: 部署"
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childrenOnChange={onSectionChange}
          childRequired={true}
          childLabel="配布先: 課"
          onChangeAllBusinessSectionsDistribution={
            setAllBusinessSectionsDistribution
          }
          allBusinessSectionsDistribution={allBusinessSectionsDistribution}
          currentEmployeeRole={currentEmployee?.employeeRole}
        />
        <FormSubmitButton
          value="シェアする"
          color="primary"
          className="w-full"
          isReadOnly={isSubmitting}
        />
      </Form>
    </ModalWrapper>
  );
};
