import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { MemberInsightPostsMutateType } from "~/domains";

type RequestDataType = {
  body: {
    name: string;
    businessDivisionId: string;
  };
};

const request = ({ body }: RequestDataType) => {
  return new ApiClient()
    .post<MemberInsightPostsMutateType>("/api/managers/business_sections", {
      businessSection: body,
    })
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersBusinessSectionsCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  MemberInsightPostsMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
