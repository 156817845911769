import React, { useRef, FC } from "react";

import { useReactDrag, useReactDrop } from "~/libs";

import { Icon } from "~/components/atoms";

type PropsType = {
  name: string;
  index: number;
  changeDisplayOrder: (beforeIndex: number, newIndex: number) => void;
  className?: string;
};

export const NameOrderableForm: FC<PropsType> = ({
  name,
  index,
  changeDisplayOrder,
  className = "",
}: PropsType) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId, isOver }, drop] = useReactDrop({
    accept: "name",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: !!monitor.isOver(),
      };
    },
    drop(item: { index: number }) {
      changeDisplayOrder(item.index, index);
    },
  });

  const [{ isDragging }, drag] = useReactDrag({
    type: "name",
    item: () => ({ index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      className={`
        flex
        items-center
        justify-between
        cursor-grab
        ${isDragging ? "opacity-50" : "opacity-100"}
        ${isOver ? "bg-primary-200" : ""}
        ${className}
      `}
      ref={ref}
      data-handler-id={handlerId}
    >
      <div className="flex items-center">
        <div className="bg-primary-600 rounded-full p-2">
          <Icon icon="ioWaterOutline" color="text-white" size="1.5rem" />
        </div>
        <h3 className="text-primary-600 font-bold w-full ml-2">{name}</h3>
      </div>
      <Icon
        icon="ioSwapVerticalOutline"
        color="text-primary-600"
        size="1.5rem"
        className="shrink-0"
      />
    </div>
  );
};
