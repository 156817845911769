import React, { FC, MouseEvent } from "react";

import { Icon, IconPropsType } from "~/components/atoms";

type PropsType = {
  icon: IconPropsType;
  onClick: () => void;
  textOnClick?: () => void;
  count: number | string;
  className?: string;
  buttonClassName?: string;
};

export const IconWithCounter: FC<PropsType> = ({
  icon = {
    icon: "ioHeartOutline",
    color: "text-secondary-600",
    size: "1.25rem",
  },
  onClick,
  textOnClick,
  count,
  className = "",
  buttonClassName = "",
}: PropsType) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick();
  };
  return (
    <div className={`flex items-center ${className}`}>
      <button
        type="button"
        onClick={handleClick}
        className={`p-1.5 group rounded-full ${buttonClassName}`}
      >
        <Icon
          icon={icon.icon}
          color={icon.color}
          size={icon.size}
          className={icon.className}
        />
      </button>
      {textOnClick ? (
        <button
          type="button"
          className="ml-0.5 underline underline-offset-2 decoration-secondary-400"
          onClick={textOnClick}
        >
          {count}
        </button>
      ) : (
        <span className="ml-0.5">{count}</span>
      )}
    </div>
  );
};
