import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersEmployeeSurveysMutateResponseType } from "~/domains";

type RequestDataType = {
  employeeSurveyId: string;
};

const request = ({ employeeSurveyId }: RequestDataType) => {
  return new ApiClient()
    .post<ApiManagersEmployeeSurveysMutateResponseType>(
      `/api/managers/employee_surveys/${employeeSurveyId}/duplicates`,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersEmployeeSurveysDuplicatesCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersEmployeeSurveysMutateResponseType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
