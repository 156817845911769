import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersArchivedJobCategoriesIndexResponseType,
  ApiManagersArchivedJobCategoriesIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiManagersArchivedJobCategoriesIndexRequestType;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApiManagersArchivedJobCategoriesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiManagersArchivedJobCategoriesIndexResponseType>(
      "/api/managers/archived_job_categories",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiManagersArchivedJobCategoriesIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersArchivedJobCategoriesIndex = ({
  params,
  config = {},
}: PropsType = {}): UseQueryResult<ApiManagersArchivedJobCategoriesIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersArchivedJobCategoriesIndex", params],
    queryFn: () => request({ params }),
  });
};
