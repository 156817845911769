import React, { FC } from "react";

import { QuestionAnswer } from "./QuestionAnswer";

import { QuestionAnswerCategoryWithQuestionAnswerType } from "~/domains";

type PropsType = {
  questionAnswerCategory: Pick<
    QuestionAnswerCategoryWithQuestionAnswerType,
    "name" | "questionAnswers" | "providingService"
  >;
};

export const QuestionAnswerCategoryList: FC<PropsType> = ({
  questionAnswerCategory,
}: PropsType) => {
  const { name, providingService, questionAnswers } = questionAnswerCategory;

  return (
    <div>
      <span className="text-xs bg-primary-600 text-white rounded px-4 py-0.5">
        {providingService?.name || "すべて"}
      </span>
      <h3 className="text-primary-600 pb-2 border-b border-secondary-400 mt-2 font-semibold text-lg">
        {name}
      </h3>
      <div className="space-y-4 mt-4">
        {questionAnswers.map((questionAnswer) => (
          <QuestionAnswer
            key={questionAnswer.id}
            questionAnswer={questionAnswer}
          />
        ))}
      </div>
    </div>
  );
};
