import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersEmployeeSearchItemsIndexResponseType } from "~/domains";

const request =
  async (): Promise<ApiManagersEmployeeSearchItemsIndexResponseType> => {
    const response =
      await new ApiClient().get<ApiManagersEmployeeSearchItemsIndexResponseType>(
        "/api/managers/employee_search_items",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisManagersEmployeeSearchItemsIndex = ({
  config = {},
}: PropsType): UseQueryResult<ApiManagersEmployeeSearchItemsIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisManagersEmployeeSearchItemsIndex"],
    queryFn: () => request(),
  });
};
