import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";

import { InfiniteDataType, toDateString } from "~/libs";

import {
  useInput,
  useDateRange,
  useApisMembersKaizenIdeasIndex,
  usePostSortType,
  useMultipleDropdown,
  useArray,
  useApisMembersKaizenIdeaSearchItemsIndex,
  useProvidersEmployeeLayout,
  useCompleteStatusDropdown,
} from "~/hooks";

import { RangeDatePropsType } from "~/components/molecules";

import {
  ApiMembersKaizenIdeasIndexResponseType,
  KaizenIdeaType,
  MultiValueType,
  OptionType,
  SortType,
  ApiMembersKaizenIdeasIndexRequestType,
  AllPriorityRatings,
  SingleValueType,
} from "~/domains";

type ReturnType = {
  kaizenIdeas: KaizenIdeaType[];
  data?: InfiniteDataType<ApiMembersKaizenIdeasIndexResponseType>;
  isLoading: boolean;
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  selectableDropdownCompleteStatusIds: OptionType[];
  selectedDropdownCompleteStatusId?: SingleValueType<OptionType>;
  selectableDropdownIsFromClientIds: OptionType[];
  selectedDropdownIsFromClientId?: SingleValueType<OptionType>;
  hasNextPage?: boolean;
  sort: SortType;
  selectableDropdownPriorityRatings: OptionType[];
  selectedDropdownPriorityRatings: MultiValueType<OptionType>;
  selectableDropdownProvidingServices: OptionType[];
  selectedDropdownProvidingServices: MultiValueType<OptionType>;
  deleteIndexKaizenIdea: (index: number) => void;
  updateIndexKaizenIdea: (newItem: KaizenIdeaType, index: number) => void;
  unshiftKaizenIdea: (newItem: KaizenIdeaType) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDropdownPriorityRatings: (
    options: MultiValueType<OptionType>,
  ) => void;
  onChangeDropdownProvidingServices: (
    options: MultiValueType<OptionType>,
  ) => void;
  fetchNextPage: () => void;
  setSort: (val: SortType) => void;
  onChangeDropdownCompleteStatusId: (
    value: SingleValueType<OptionType>,
  ) => void;
  onChangeDropdownIsFromClientId: (value: SingleValueType<OptionType>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchMembersKaizenIdeas = (): ReturnType => {
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const [{ startDate, endDate, onChange: onChangeDateRange }] = useDateRange();
  const [
    {
      statusValue,
      selectedDropdownCompleteStatusId,
      selectableDropdownCompleteStatusIds,
      onChangeDropdownCompleteStatusId,
    },
  ] = useCompleteStatusDropdown();

  const [
    {
      statusValue: isFromClient,
      selectedDropdownCompleteStatusId: selectedDropdownIsFromClientId,
      selectableDropdownCompleteStatusIds: selectableDropdownIsFromClientIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownIsFromClientId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "顧客からの発信",
      false: "本人からの発信",
    },
  });

  const { sort, snakeKeyValue, setSort } = usePostSortType();
  const [params, setParams] = useState<ApiMembersKaizenIdeasIndexRequestType>({
    sortType: snakeKeyValue,
  });

  const { items, setItems, deleteIndexItem, updateIndexItem, unshiftItem } =
    useArray<KaizenIdeaType>([]);

  const [
    {
      dropdownValue: selectedDropdownProvidingServices,
      value: selectedProvidingServices,
      onChange: onChangeDropdownProvidingServices,
      dropdownSelectableValue: selectableDropdownProvidingServices,
      setSelectableOption: setSelectableDropdownProvidingServices,
    },
  ] = useMultipleDropdown();

  useApisMembersKaizenIdeaSearchItemsIndex({
    config: {
      onSuccess: (res) => {
        setSelectableDropdownProvidingServices(res.providingServices);
      },
    },
  });

  const [
    {
      dropdownValue: selectedDropdownPriorityRatings,
      value: selectedPriorityRatings,
      onChange: onChangeDropdownPriorityRatings,
      dropdownSelectableValue: selectableDropdownPriorityRatings,
    },
  ] = useMultipleDropdown({
    selectableValue: AllPriorityRatings.map((ele) => ({
      id: ele.id.toString(),
      name: "★".repeat(ele.id),
    })),
  });

  const { data, isFetching, fetchNextPage, hasNextPage } =
    useApisMembersKaizenIdeasIndex({
      params,
      config: {
        onSuccess: (data) => {
          setItems(data.pages.map((page) => page.kaizenIdeas).flat());
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      completed: statusValue,
      isFromClient,
      priorityRatingIds: selectedPriorityRatings?.map((ele) => ele.id),
      providingServiceIds: selectedProvidingServices?.map((ele) => ele.id),
      sortType: snakeKeyValue,
      createdAtFrom: startDate ? toDateString(startDate) : "",
      createdAtTo: endDate ? toDateString(endDate) : "",
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    onChangeDropdownCompleteStatusId(null);
    onChangeDropdownIsFromClientId(null);
    onChangeDropdownPriorityRatings([]);
    onChangeDropdownProvidingServices([]);
    onChangeDateRange([null, null]);
    setSort("createdAtDesc");
    setParams({ sortType: snakeKeyValue });
    setFalseRightSidebarMain();
  };

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      sortType: snakeKeyValue,
    }));
  }, [snakeKeyValue]);

  return {
    kaizenIdeas: items,
    data,
    isLoading: isFetching,
    keyword,
    selectableDropdownCompleteStatusIds,
    selectedDropdownCompleteStatusId,
    selectableDropdownIsFromClientIds,
    selectedDropdownIsFromClientId,
    hasNextPage,
    startDate,
    endDate,
    sort,
    selectableDropdownPriorityRatings,
    selectedDropdownPriorityRatings,
    selectableDropdownProvidingServices,
    selectedDropdownProvidingServices,
    unshiftKaizenIdea: unshiftItem,
    updateIndexKaizenIdea: updateIndexItem,
    deleteIndexKaizenIdea: deleteIndexItem,
    fetchNextPage,
    setSort,
    onChangeDropdownPriorityRatings,
    onChangeDropdownProvidingServices,
    onChangeDateRange,
    onChangeKeyword,
    onChangeDropdownCompleteStatusId,
    onChangeDropdownIsFromClientId,
    onSearchSubmit,
    onConditionReset,
  };
};
