import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiAnalyticsPlansDetailAnalysisIndexResponseType,
  ApiAnalyticsPlansDetailAnalysisIndexRequestType,
} from "~/domains";

type RequestDataType = {
  params?: ApiAnalyticsPlansDetailAnalysisIndexRequestType;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApiAnalyticsPlansDetailAnalysisIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiAnalyticsPlansDetailAnalysisIndexResponseType>(
      "/api/analytics_plans/detail_analysis",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiAnalyticsPlansDetailAnalysisIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisAnalyticsPlansDetailAnalysisIndex = ({
  params,
  config = {},
}: PropsType = {}): UseQueryResult<ApiAnalyticsPlansDetailAnalysisIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisAnalyticsPlansDetailAnalysisIndex", params],
    queryFn: () => request({ params }),
  });
};
