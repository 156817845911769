import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiManagersDocumentFileCategoryMutateType,
  ApiManagersDocumentFileCategoryMutateRequestType,
} from "~/domains";

type RequestDataType = {
  body: ApiManagersDocumentFileCategoryMutateRequestType;
};

const request = ({ body }: RequestDataType) => {
  return new ApiClient()
    .post<ApiManagersDocumentFileCategoryMutateType>(
      "/api/managers/document_file_categories",
      {
        documentFileCategory: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersDocumentFileCategoriesCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersDocumentFileCategoryMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
