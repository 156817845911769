import React, { ReactNode, FC } from "react";

import { useApisJogpShow } from "~/hooks";

type PropsType = {
  href: string;
  children: ReactNode;
  readOnly?: boolean; // ここでreadOnlyを追加
};

export const Link: FC<PropsType> = ({
  href,
  readOnly,
  children,
}: PropsType) => {
  const canFetch = readOnly && !!href;
  const { data } = useApisJogpShow({
    params: {
      url: href,
    },
    config: {
      enabled: canFetch,
    },
  });
  const canDisplayOgp =
    readOnly &&
    data?.["og:title"] &&
    data?.["og:description"] &&
    data?.["og:image"];

  return (
    <a href={href} rel="noopener noreferrer" target="_blank">
      <div className={"text-icon-reply"}>{children}</div>
      {canDisplayOgp && (
        <div className="mt-2 border border-gray-200 rounded-lg w-full h-36 flex items-center justify-between">
          <div className="select-none px-6 py-4 space-y-2 w-3/4">
            <h3 className="text-lg font-semibold truncate">
              {data["og:title"]}
            </h3>
            <p className="text-gray-500 text-sm line-clamp-2">
              {data["og:description"]}
            </p>
            <div className="flex space-x-2 text-sm text-gray-500 items-center">
              <img
                src={`http://www.google.com/s2/favicons?domain=${href}`}
                alt="favicon"
                width="16"
                height="16"
                className="w-4 h-4"
              />
              <span>{data["og:site_name"]}</span>
            </div>
          </div>
          <img
            src={data["og:image"]}
            alt="OGP"
            className="w-1/4 max-h-[9rem] h-full object-fill rounded-r-lg"
          />
        </div>
      )}
    </a>
  );
};
