import React, { FC } from "react";

import { toDateString } from "~/libs";

import {
  useKpiSlideNavigation,
  useApisMembersKpiTermEndKpiTermsIndex,
  useArray,
} from "~/hooks";

import { GridHeaderCells, RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  GridRow,
  InfiniteScrollWithMoreButton,
  OpenedMenuInGrid,
} from "~/components/molecules";
import { KpiDailyPerformanceCalendar } from "~/components/organisms";

import { KpiTermType } from "~/domains";

const date = new Date();
export const MembersKpiTermsIndex: FC = () => {
  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";

  const { navigations } = useKpiSlideNavigation({
    selectedArray: [false, false, true],
    businessSectionId: businessSectionId || "",
    date: date,
  });
  const { items, setItems } = useArray<KpiTermType>();
  const { fetchNextPage, hasNextPage } = useApisMembersKpiTermEndKpiTermsIndex({
    config: {
      onSuccess: (data) => {
        setItems(data.pages.map((page) => page.kpiTerms).flat());
      },
    },
  });

  const breadCrumbItems = [
    {
      label: "KPI・KGI",
      href: `/kpi_daily_performances/${toDateString(
        date,
      )}?business_section_id=${businessSectionId}`,
    },
    {
      label: "過去",
      href: "/kpi_terms",
    },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <InfiniteScrollWithMoreButton
          itemsLength={items.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          className="mt-6"
        >
          <div className="grid grid-cols-2">
            <GridHeaderCells texts={["タイトル", "期間"]} />
            {items.map((kpiTerm) => (
              <GridRow
                key={kpiTerm.id}
                lists={[kpiTerm.title, `${kpiTerm.fromDate}~${kpiTerm.toDate}`]}
                openedMenu={
                  <OpenedMenuInGrid
                    cols={2}
                    buttons={[
                      {
                        text: "確認",
                        link: `/term_end_kpi_terms/${kpiTerm.id}/dashboard?business_section_id=${businessSectionId}`,
                      },
                    ]}
                  />
                }
              />
            ))}
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        {businessSectionId && (
          <>
            <RightSidebarTitle title="Information" />
            <KpiDailyPerformanceCalendar
              className="mx-4 mt-6"
              businessSectionId={businessSectionId}
            />
          </>
        )}
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
