import React, { FC, ChangeEvent, FormEvent, MouseEvent, useState } from "react";

import {
  useApisMembersInsightPostsInsightPostCommentsIndex,
  useRightSidebarState,
  useApisMembersInsightPostsNew,
  useInterval,
} from "~/hooks";

import {
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  RightSidebarToggleIconWithLabel,
  InfiniteScroll,
  FilterIconWithSortMenu,
} from "~/components/molecules";
import {
  InsightPostCommentsWithInsightPost,
  InsightPostSearchForm,
  InsightPostDetailWithEditForm,
} from "~/components/organisms";

import {
  ChildType,
  InsightPostType,
  MultiValueType,
  OptionType,
  ParentType,
  RangeDatePropsType,
  SingleValueType,
  SortType,
} from "~/domains";

type PropsType = {
  insightPosts: InsightPostType[];
  startDate: Date | null;
  endDate: Date | null;
  hasNextPage?: boolean;
  keyword: string;
  isLoading: boolean;
  sort: SortType;
  selectableDropdownHasCommentIds: OptionType[];
  selectedDropdownHasCommentId?: SingleValueType<OptionType>;
  selectableDropdownSharedIds: OptionType[];
  selectedDropdownSharedId?: SingleValueType<OptionType>;
  selectableDropdownEmployees: OptionType[];
  selectedDropdownEmployees: MultiValueType<OptionType>;
  selectableJobCategories: ParentType[];
  selectedJobCategories: ParentType[];
  optionSelectableJobCategoryPhases: ChildType[];
  optionSelectedJobCategoryPhases: ChildType[];
  selectedProvidingServices: MultiValueType<OptionType>;
  selectableProvidingServices: OptionType[];
  onChangeProvidingServices: (options: MultiValueType<OptionType>) => void;
  onChangeJobCategoryPhases: (options: ChildType[]) => void;
  onChangeJobCategories: (options: ParentType[]) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDropdownEmployees: (options: MultiValueType<OptionType>) => void;
  onChangeDropdownHasCommentId: (value: SingleValueType<OptionType>) => void;
  onChangeDropdownSharedId: (value: SingleValueType<OptionType>) => void;
  fetchNextPage: () => void;
  setSort: (val: SortType) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  deleteIndexInsightPost: (index: number) => void;
  updateIndexInsightPost: (newItem: InsightPostType, index: number) => void;
};

export const BookmarkInsightPostsIndexTemplate: FC<PropsType> = ({
  insightPosts,
  startDate,
  endDate,
  hasNextPage,
  sort,
  keyword,
  isLoading,
  selectableDropdownHasCommentIds,
  selectedDropdownHasCommentId,
  selectableDropdownSharedIds,
  selectedDropdownSharedId,
  selectableDropdownEmployees,
  selectedDropdownEmployees,
  selectableJobCategories,
  selectedJobCategories,
  optionSelectableJobCategoryPhases,
  optionSelectedJobCategoryPhases,
  selectedProvidingServices,
  selectableProvidingServices,
  onChangeProvidingServices,
  onChangeJobCategoryPhases,
  onChangeJobCategories,
  onChangeDateRange,
  onChangeKeyword,
  onChangeDropdownEmployees,
  onChangeDropdownHasCommentId,
  onChangeDropdownSharedId,
  fetchNextPage,
  setSort,
  onSearchSubmit,
  onConditionReset,
  updateIndexInsightPost,
  deleteIndexInsightPost,
}: PropsType) => {
  const {
    isSearchOpen,
    isReplyOpen,
    searchOpenToggle,
    setReplyOpen,
    setClose,
    isOpen,
  } = useRightSidebarState("isClose");
  const [selectGetCommentInsightPostId, setSelectGetCommentInsightPostId] =
    useState("");
  const { data: insightNewData } = useApisMembersInsightPostsNew();
  const { data: commentsData, refetch: commentsRefetch } =
    useApisMembersInsightPostsInsightPostCommentsIndex({
      insightPostId: selectGetCommentInsightPostId,
      config: {
        enabled: Boolean(selectGetCommentInsightPostId),
      },
    });
  useInterval({
    onUpdate: async () => {
      isReplyOpen && selectGetCommentInsightPostId && (await commentsRefetch());
    },
    intervalMs: 1000,
  });
  const openComment = (insightPostId: string) => {
    setSelectGetCommentInsightPostId(insightPostId);
    setReplyOpen();
  };
  const { data: fetchNewData } = useApisMembersInsightPostsNew();

  return (
    <>
      <EmployeeLayoutMainContent withRightSidebar>
        <FilterIconWithSortMenu
          likeSort={() => setSort("likeCountDesc")}
          bookmarkSort={() => setSort("bookmarkCountDesc")}
          createdAtAscSort={() => setSort("createdAtAsc")}
          createdAtDescSort={() => setSort("createdAtDesc")}
          currentSortType={sort}
        />
        <InfiniteScroll
          itemsLength={insightPosts.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isLoading}
          className="border-t-2 border-secondary-200"
        >
          {insightPosts.map((post, index) => (
            <InsightPostDetailWithEditForm
              key={post.id}
              insightPost={post}
              openComment={openComment}
              mentions={insightNewData?.mentionableEmployees || []}
              selectableProvidingServices={
                insightNewData?.selectableProvidingServices || []
              }
              selectableJobCategoryPhases={
                insightNewData?.selectableJobCategoryPhases || []
              }
              updateItem={(newItem) => updateIndexInsightPost(newItem, index)}
              deleteItem={() => {
                deleteIndexInsightPost(index);
                if (selectGetCommentInsightPostId === post.id) {
                  setSelectGetCommentInsightPostId("");
                  setClose();
                }
              }}
            />
          ))}
        </InfiniteScroll>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <>
          <RightSidebarToggleIconWithLabel
            iconOnClick={searchOpenToggle}
            handleOnClose={setClose}
            label={isSearchOpen ? "検索条件" : "リプライ"}
            isOpen={isOpen}
          >
            {isSearchOpen && (
              <InsightPostSearchForm
                onSubmit={onSearchSubmit}
                onConditionReset={onConditionReset}
                date={{
                  startDate: startDate,
                  endDate: endDate,
                  onChange: onChangeDateRange,
                }}
                employee={{
                  options: selectableDropdownEmployees,
                  value: selectedDropdownEmployees,
                  onChange: onChangeDropdownEmployees,
                }}
                searchKeyword={{
                  value: keyword,
                  onChange: onChangeKeyword,
                }}
                providingService={{
                  options: selectableProvidingServices,
                  value: selectedProvidingServices,
                  onChange: onChangeProvidingServices,
                }}
                jobCategoryAndJobCategoryPhase={{
                  selectableJobCategories: selectableJobCategories,
                  selectedJobCategories: selectedJobCategories,
                  selectableJobCategoryPhases:
                    optionSelectableJobCategoryPhases,
                  selectedJobCategoryPhases: optionSelectedJobCategoryPhases,
                  onJobCategoryChange: onChangeJobCategories,
                  onJobCategoryPhaseChange: onChangeJobCategoryPhases,
                }}
                hasComment={{
                  options: selectableDropdownHasCommentIds,
                  value: selectedDropdownHasCommentId,
                  onChange: onChangeDropdownHasCommentId,
                }}
                shared={{
                  options: selectableDropdownSharedIds,
                  value: selectedDropdownSharedId,
                  onChange: onChangeDropdownSharedId,
                }}
              />
            )}
            {isReplyOpen && commentsData && (
              <InsightPostCommentsWithInsightPost
                className="mt-6"
                insightPost={commentsData.insightPost}
                mentions={fetchNewData?.mentionableEmployees || []}
                insightPostComments={commentsData.insightPostComments || []}
                refetch={commentsRefetch}
              />
            )}
          </RightSidebarToggleIconWithLabel>
        </>
      </EmployeeLayoutRightSidebar>
    </>
  );
};
