import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useRoleplayingConditionSlideNavigation,
  useRightSidebarState,
  useSearchMembersRoleplayingConditions,
  useApisMembersRoleplayingConditionsDestroy,
} from "~/hooks";

import { GridHeaderCells } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  GridRow,
  InfiniteScrollWithMoreButton,
  OpenedMenuInGrid,
  RightSidebarToggleIconWithLabel,
} from "~/components/molecules";
import {
  RoleplayingConditionCurrentEmployeeDailyCountCalendar,
  RoleplayingConditionSearchForm,
} from "~/components/organisms";

import { RoleplayingConditionType } from "~/domains";

export const MembersRoleplayingConditionsIndex: FC = () => {
  const { navigations } = useRoleplayingConditionSlideNavigation([
    false,
    false,
    true,
  ]);
  const { searchOpenToggle, isOpen } = useRightSidebarState("isClose");
  const {
    roleplayingConditions,
    keyword,
    isLoading,
    hasNextPage,
    startDate,
    endDate,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    selectedDropdownHasSkillEvaluationStandardSummaryCategoryId,
    selectableDropdownHasSkillEvaluationStandardSummaryCategoryIds,
    onChangeDateRange,
    onChangeDropdownHasBookmarkId,
    onChangeDropdownHasSkillEvaluationStandardSummaryCategoryId,
    fetchNextPage,
    findAndRemove,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  } = useSearchMembersRoleplayingConditions();
  const { mutate: deleteRequest } =
    useApisMembersRoleplayingConditionsDestroy();

  const handleDelete = (roleplayingCondition: RoleplayingConditionType) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;
    deleteRequest(
      {
        id: roleplayingCondition.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((item) => item.id === roleplayingCondition.id);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "ロールプレイング", href: "/roleplaying_conditions/new" },
    { label: "一覧", href: "/roleplaying_conditions" },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <InfiniteScrollWithMoreButton
          itemsLength={roleplayingConditions.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isLoading}
          className="mt-6"
        >
          <div className="grid grid-cols-4">
            <GridHeaderCells
              texts={["タイトル", "日付", "参加者", "ブックマーク"]}
            />
            {roleplayingConditions.map((roleplayingCondition) => (
              <GridRow
                key={roleplayingCondition.id}
                lists={[
                  roleplayingCondition.title,
                  roleplayingCondition.postDate,
                  roleplayingCondition.participationEmployees
                    .map((employee) => employee.name)
                    .join(", "),
                  roleplayingCondition.bookmarked ? "あり" : "",
                ]}
                openedMenu={
                  <OpenedMenuInGrid
                    cols={4}
                    buttons={[
                      {
                        text: "編集",
                        link: `/roleplaying_conditions/${roleplayingCondition.id}/edit`,
                      },
                      {
                        text: "削除",
                        onClick: () => handleDelete(roleplayingCondition),
                      },
                    ]}
                  />
                }
              />
            ))}
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={isOpen ? searchOpenToggle : undefined}
          label={isOpen ? "検索条件" : "Information"}
          isOpen
        >
          {isOpen ? (
            <RoleplayingConditionSearchForm
              onSubmit={onSearchSubmit}
              onConditionReset={onConditionReset}
              searchKeyword={{
                value: keyword,
                onChange: onChangeKeyword,
              }}
              hasBookmark={{
                options: selectableDropdownHasBookmarkIds,
                value: selectedDropdownHasBookmarkId,
                onChange: onChangeDropdownHasBookmarkId,
              }}
              hasSkillEvaluationStandardSummaryCategory={{
                options:
                  selectableDropdownHasSkillEvaluationStandardSummaryCategoryIds,
                value:
                  selectedDropdownHasSkillEvaluationStandardSummaryCategoryId,
                onChange:
                  onChangeDropdownHasSkillEvaluationStandardSummaryCategoryId,
              }}
              date={{
                startDate,
                endDate,
                onChange: onChangeDateRange,
              }}
            />
          ) : (
            <RoleplayingConditionCurrentEmployeeDailyCountCalendar className="mt-6" />
          )}
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
