import React, { FC, FormEvent, MouseEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { SignOut } from "~/utils";

import {
  useApisPartnerConsolesProfilesUpdate,
  useInput,
  useProvidersCurrentPartnerConsole,
} from "~/hooks";

import { Button, Form, FormSubmitButton, Link } from "~/components/atoms";
import { PartnerConsoleLayout } from "~/components/layouts";
import { LabelWithTextField } from "~/components/molecules";

export const PartnerConsolesSettingProfileShow: FC = () => {
  const navigate = useNavigate();
  const { currentAdminOperator, refetch } = useProvidersCurrentPartnerConsole();
  const [{ value: name, onChange: onChangeName }, setName] = useInput("");
  const { mutate, isLoading: isUpdating } =
    useApisPartnerConsolesProfilesUpdate();
  const breadCrumbItems = [
    {
      label: "パートナー画面",
      href: "/partner_consoles",
    },
    {
      label: "プロフィール編集",
      href: "/partner_consoles/setting/profile",
    },
  ];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      {
        body: {
          name,
        },
      },
      {
        onSuccess: (data) => {
          refetch();
          toast(data.message);
          navigate("/partner_consoles");
        },
      },
    );
  };

  const handleLogout = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = confirm("本当にログアウトしますか？");
    if (!result) return;

    await SignOut({
      isAdmin: true,
      onSuccess: () => {
        toast("ログアウトしました");
        navigate("/partner_consoles/sign_in");
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });
  };

  useEffect(() => {
    if (currentAdminOperator) {
      setName(currentAdminOperator.name);
    }
  }, [currentAdminOperator, setName]);

  return (
    <PartnerConsoleLayout breadCrumbItems={breadCrumbItems}>
      <Form className="mt-4 space-y-4" onSubmit={handleSubmit}>
        <LabelWithTextField
          labelText="名前"
          type="text"
          name="name"
          autoComplete="name"
          placeholder="名前"
          required
          onChange={onChangeName}
          value={name}
        />
        <div className="flex justify-end items-center space-x-4">
          <FormSubmitButton
            color="primary"
            value="更新"
            isReadOnly={isUpdating}
          />
        </div>
      </Form>
      <div className="mt-10 text-center">
        <Link
          to="/partner_consoles/setting/password"
          className="text-primary-600 underline visited:text-purple-600 block"
        >
          パスワード変更
        </Link>
        <Button
          onClick={handleLogout}
          text="ログアウト"
          color="primary"
          className="mt-2 w-fit"
          outline
        />
      </div>
    </PartnerConsoleLayout>
  );
};
