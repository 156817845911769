import React, { FC, useState, ChangeEvent, FormEvent, MouseEvent } from "react";

import {
  useBoolean,
  useApisMembersKnowledgeInsightPostsNew,
  useApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsIndex,
  useInterval,
} from "~/hooks";

import {
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  RightSidebarToggleIconWithLabel,
  InfiniteScroll,
  FilterIconWithSortMenu,
  RangeDatePropsType,
} from "~/components/molecules";
import {
  KnowledgeInsightPostCommentsWithKnowledgeInsightPost,
  KnowledgeInsightPostSearchForm,
  KnowledgeInsightPostDetailWithEditForm,
} from "~/components/organisms";

import {
  KnowledgeInsightPostType,
  SortType,
  OptionType,
  MultiValueType,
  SingleValueType,
} from "~/domains";

type PropsType = {
  bookmarkFolderId?: string;
  bookmarkFolderName?: string;
  knowledgeInsightPosts: KnowledgeInsightPostType[];
  startDate: Date | null;
  endDate: Date | null;
  hasNextPage?: boolean;
  isLoading: boolean;
  sort: SortType;
  keyword: string;
  selectableDropdownHasCommentIds: OptionType[];
  selectedDropdownHasCommentId?: SingleValueType<OptionType>;
  selectableDropdownEmployees: OptionType[];
  selectedDropdownEmployees: MultiValueType<OptionType>;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDropdownHasCommentId: (value: SingleValueType<OptionType>) => void;
  onChangeDropdownEmployees: (options: MultiValueType<OptionType>) => void;
  fetchNextPage: () => void;
  setSort: (val: SortType) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  deleteIndexKnowledgeInsightPost: (index: number) => void;
  updateIndexKnowledgeInsightPost: (
    newItem: KnowledgeInsightPostType,
    index: number,
  ) => void;
};

export const BookmarkKnowledgeInsightPostsIndexTemplate: FC<PropsType> = ({
  knowledgeInsightPosts,
  startDate,
  endDate,
  hasNextPage,
  isLoading,
  sort,
  keyword,
  selectableDropdownHasCommentIds,
  selectedDropdownHasCommentId,
  selectableDropdownEmployees,
  selectedDropdownEmployees,
  onChangeDropdownHasCommentId,
  onChangeDateRange,
  onChangeKeyword,
  onChangeDropdownEmployees,
  fetchNextPage,
  setSort,
  onSearchSubmit,
  onConditionReset,
  updateIndexKnowledgeInsightPost,
  deleteIndexKnowledgeInsightPost,
}: PropsType) => {
  const {
    isChecked: isSidebarOpen,
    setFalse: handleRightSidebarClose,
    setTrue: handleRightSidebarOpen,
  } = useBoolean(false);

  const [
    selectGetCommentKnowledgeInsightPostId,
    setSelectGetCommentKnowledgeInsightPostId,
  ] = useState("");

  const { data: commentsData, refetch: commentsRefetch } =
    useApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsIndex({
      knowledgeInsightPostId: selectGetCommentKnowledgeInsightPostId,
      config: {
        enabled: Boolean(selectGetCommentKnowledgeInsightPostId),
      },
    });

  useInterval({
    onUpdate: async () => {
      !isSidebarOpen &&
        selectGetCommentKnowledgeInsightPostId &&
        (await commentsRefetch());
    },
    intervalMs: 1000,
  });

  const { data: fetchNewData } = useApisMembersKnowledgeInsightPostsNew();
  const openComment = (knowledgeInsightPostId: string) => {
    setSelectGetCommentKnowledgeInsightPostId(knowledgeInsightPostId);
    handleRightSidebarClose();
  };

  return (
    <>
      <EmployeeLayoutMainContent withRightSidebar>
        <FilterIconWithSortMenu
          likeSort={() => setSort("likeCountDesc")}
          bookmarkSort={() => setSort("bookmarkCountDesc")}
          createdAtAscSort={() => setSort("createdAtAsc")}
          createdAtDescSort={() => setSort("createdAtDesc")}
          currentSortType={sort}
        />
        <InfiniteScroll
          itemsLength={knowledgeInsightPosts.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isLoading}
          className="border-t-2 border-secondary-200"
        >
          {knowledgeInsightPosts.map((post, index) => (
            <KnowledgeInsightPostDetailWithEditForm
              key={post.id}
              knowledgeInsightPost={post}
              openComment={openComment}
              deleteItem={() => {
                deleteIndexKnowledgeInsightPost(index);
                if (selectGetCommentKnowledgeInsightPostId === post.id) {
                  setSelectGetCommentKnowledgeInsightPostId("");
                  handleRightSidebarClose();
                }
              }}
              updateItem={(newItem) =>
                updateIndexKnowledgeInsightPost(newItem, index)
              }
            />
          ))}
        </InfiniteScroll>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        {isSidebarOpen ? (
          <>
            <RightSidebarToggleIconWithLabel
              iconOnClick={handleRightSidebarOpen}
              handleOnClose={handleRightSidebarClose}
              label="検索条件"
              isOpen={isSidebarOpen}
            >
              <KnowledgeInsightPostSearchForm
                onSubmit={onSearchSubmit}
                onConditionReset={onConditionReset}
                date={{
                  startDate: startDate,
                  endDate: endDate,
                  onChange: onChangeDateRange,
                }}
                employee={{
                  options: selectableDropdownEmployees,
                  value: selectedDropdownEmployees,
                  onChange: onChangeDropdownEmployees,
                }}
                hasComment={{
                  options: selectableDropdownHasCommentIds,
                  value: selectedDropdownHasCommentId,
                  onChange: onChangeDropdownHasCommentId,
                }}
                searchKeyword={{
                  value: keyword,
                  onChange: onChangeKeyword,
                }}
              />
            </RightSidebarToggleIconWithLabel>
          </>
        ) : (
          <>
            <RightSidebarToggleIconWithLabel
              iconOnClick={handleRightSidebarOpen}
              handleOnClose={handleRightSidebarOpen}
              label="リプライ"
              isOpen={!!selectGetCommentKnowledgeInsightPostId}
            >
              {commentsData && (
                <KnowledgeInsightPostCommentsWithKnowledgeInsightPost
                  className="mt-6"
                  refetch={commentsRefetch}
                  mentions={fetchNewData?.mentionableEmployees || []}
                  knowledgeInsightPost={commentsData.knowledgeInsightPost}
                  knowledgeInsightPostComments={
                    commentsData.knowledgeInsightPostComments
                  }
                />
              )}
            </RightSidebarToggleIconWithLabel>
          </>
        )}
      </EmployeeLayoutRightSidebar>
    </>
  );
};
