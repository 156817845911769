import {
  ExtractFnReturnType,
  InfiniteQueryConfigType,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersKnowledgeInsightPostsLikedEmployeesIndexResponseType } from "~/domains";

type RequestDataType = {
  knowledgeInsightPostId: string;
  page?: number;
};

const request = async ({
  knowledgeInsightPostId,
  page = 1,
}: RequestDataType): Promise<ApiMembersKnowledgeInsightPostsLikedEmployeesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersKnowledgeInsightPostsLikedEmployeesIndexResponseType>(
      `/api/members/knowledge_insight_posts/${knowledgeInsightPostId}/liked_employees`,
      { page },
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  knowledgeInsightPostId: string;
  config?: InfiniteQueryConfigType<QueryFnType>;
};

export const useApisMembersKnowledgeInsightPostsLikedEmployeesIndex = ({
  knowledgeInsightPostId,
  config = {},
}: PropsType): UseInfiniteQueryResult<ApiMembersKnowledgeInsightPostsLikedEmployeesIndexResponseType> => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>(
    [
      "ApisMembersKnowledgeInsightPostsLikedEmployeesIndex",
      knowledgeInsightPostId,
    ],
    ({ pageParam }) => {
      return request({
        knowledgeInsightPostId,
        page: pageParam as number | undefined,
      });
    },
    {
      ...config,
      getNextPageParam: (data) => {
        return data.pagy.hasMore ? data.pagy.currentPage + 1 : false;
      },
    },
  );
};
