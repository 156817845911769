import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApisMembersAnalysisFocusChallengeEmployeeEffortCompletesCountCalendarsIndexRequestTypes,
  ApisMembersAnalysisFocusChallengeEmployeeEffortCompletesCountCalendarsIndexResponseTypes,
} from "~/domains";

type RequestDataType = {
  params: ApisMembersAnalysisFocusChallengeEmployeeEffortCompletesCountCalendarsIndexRequestTypes;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApisMembersAnalysisFocusChallengeEmployeeEffortCompletesCountCalendarsIndexResponseTypes> => {
  const response =
    await new ApiClient().get<ApisMembersAnalysisFocusChallengeEmployeeEffortCompletesCountCalendarsIndexResponseTypes>(
      "api/members/analysis/focus_challenge_employee_effort_completes_count_calendars",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params: ApisMembersAnalysisFocusChallengeEmployeeEffortCompletesCountCalendarsIndexRequestTypes;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersAnalysisFocusChallengeEmployeeEffortCompletesCountCalendarsIndex =
  ({
    params,
    config = {},
  }: PropsType): UseQueryResult<ApisMembersAnalysisFocusChallengeEmployeeEffortCompletesCountCalendarsIndexResponseTypes> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisMembersAnalysisFocusChallengeEmployeeEffortCompletesCountCalendarsIndex",
        params,
      ],
      queryFn: () => request({ params }),
    });
  };
