import React, { FC } from "react";

import {
  useApisManagersRoleplayingConditionEmployeesDailyRoleplayingTimesIndex,
  usePrevNextSelectMonth,
} from "~/hooks";

import { RoleplayingConditionChart } from "./RoleplayingConditionChart";

type PropsType = {
  employeeId: string;
  title: string;
  chartType: "Bar" | "Area";
};

export const RoleplayingConditionSelectEmployeeChartContainer: FC<
  PropsType
> = ({ title, chartType, employeeId = "" }: PropsType) => {
  const {
    targetDate,
    targetDateString,
    handlePrevMonthClick,
    handleNextMonthClick,
  } = usePrevNextSelectMonth();

  const { data } =
    useApisManagersRoleplayingConditionEmployeesDailyRoleplayingTimesIndex({
      employeeId,
      params: {
        targetDate: targetDateString,
      },
    });

  return (
    <RoleplayingConditionChart
      title={title}
      chartType={chartType}
      dailyRoleplayingTimes={data?.dailyRoleplayingTimes || []}
      targetDate={targetDate}
      handlePrevMonthClick={handlePrevMonthClick}
      handleNextMonthClick={handleNextMonthClick}
    />
  );
};
