import React, { FC } from "react";

import { ModalWrapper } from "~/components/atoms";
import { InfiniteScrollWithMoreButton } from "~/components/molecules";
import { PostHeader } from "~/components/organisms";

import { ReactedEmployeeType } from "~/domains";

type PropsType = {
  headerTitle: string;
  onClose: () => void;
  reactedEmployees: ReactedEmployeeType[];
  nextFetchFunction: () => void;
  hasMore?: boolean;
  isLoading: boolean;
};

export const ReactedEmployeesModal: FC<PropsType> = ({
  headerTitle,
  onClose,
  reactedEmployees,
  nextFetchFunction,
  hasMore,
  isLoading,
}: PropsType) => {
  return (
    <ModalWrapper headerTitle={headerTitle} onClose={onClose}>
      <InfiniteScrollWithMoreButton
        itemsLength={reactedEmployees.length}
        nextFetchFunction={nextFetchFunction}
        hasMore={hasMore}
        isLoading={isLoading}
        className="mt-4"
        scrollClassName="space-y-6"
      >
        {reactedEmployees.map((reactedEmployee) => (
          <PostHeader
            key={reactedEmployee.id}
            miniText={reactedEmployee.createdAt}
            postedEmployee={reactedEmployee.employee}
            underLabel={reactedEmployee.employee.businessSectionName}
            isDmOnly
            menuItems={[]}
          />
        ))}
      </InfiniteScrollWithMoreButton>
    </ModalWrapper>
  );
};
