import React, { FC, useEffect, useState } from "react";

import { useBoolean } from "~/hooks";

import { Loading } from "~/components/molecules";

type PropsType = {
  url: string;
  className?: string;
};

export const TxtFileViewer: FC<PropsType> = ({
  url,
  className = "",
}: PropsType) => {
  const [content, setContent] = useState<string>("");
  const { isChecked: isLoading, setTrue, setFalse } = useBoolean(false);

  useEffect(() => {
    const fetchFile = async (url: string) => {
      setTrue();
      const response = await fetch(url);
      const text = await response.text();
      setContent(text);
      setFalse();
    };

    void fetchFile(url);
  }, [setFalse, setTrue, url]);

  return (
    <div className={className}>
      {isLoading ? (
        <Loading />
      ) : (
        content && <pre className="whitespace-pre-wrap">{content}</pre>
      )}
    </div>
  );
};
