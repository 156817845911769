import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersRoleplayingConditionSelectSkillEvaluationStandardSummaryCategoryPutType } from "~/domains";

type RequestDataType = {
  roleplayingConditionId: string;
  body: {
    skillEvaluationStandardSummaryCategoryId?: string;
  };
};

const request = ({ roleplayingConditionId, body }: RequestDataType) => {
  return new ApiClient()
    .put<ApiMembersRoleplayingConditionSelectSkillEvaluationStandardSummaryCategoryPutType>(
      `/api/members/roleplaying_conditions/${roleplayingConditionId}/select_skill_evaluation_standard_summary_category`,
      {
        roleplayingCondition: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersRoleplayingConditionsSelectSkillEvaluationStandardSummaryCategoriesUpdate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ApiMembersRoleplayingConditionSelectSkillEvaluationStandardSummaryCategoryPutType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
