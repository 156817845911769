export type PriorityRatingType =
  | typeof PriorityRatingVeryLow
  | typeof PriorityRatingLow
  | typeof PriorityRatingNormal
  | typeof PriorityRatingHigh
  | typeof PriorityRatingVeryHigh;

type RatingType = {
  id: 1 | 2 | 3 | 4 | 5;
  name: "最も低い" | "低い" | "普通" | "高い" | "最も高い";
  type: "veryLow" | "low" | "normal" | "high" | "veryHigh";
};

export const PriorityRatingVeryLow = {
  id: 1,
  name: "最も低い",
  type: "veryLow",
} as const satisfies RatingType;

export const PriorityRatingLow = {
  id: 2,
  name: "低い",
  type: "low",
} as const satisfies RatingType;

export const PriorityRatingNormal = {
  id: 3,
  name: "普通",
  type: "normal",
} as const satisfies RatingType;

export const PriorityRatingHigh = {
  id: 4,
  name: "高い",
  type: "high",
} as const satisfies RatingType;

export const PriorityRatingVeryHigh = {
  id: 5,
  name: "最も高い",
  type: "veryHigh",
} as const satisfies RatingType;

export const PriorityRating = {
  VERY_LOW: PriorityRatingVeryLow,
  Low: PriorityRatingLow,
  NORMAL: PriorityRatingNormal,
  HIGH: PriorityRatingHigh,
  VERY_HIGH: PriorityRatingVeryHigh,
} as const satisfies {
  VERY_LOW: RatingType;
  Low: RatingType;
  NORMAL: RatingType;
  HIGH: RatingType;
  VERY_HIGH: RatingType;
};

export const AllPriorityRatings = [
  PriorityRatingVeryLow,
  PriorityRatingLow,
  PriorityRatingNormal,
  PriorityRatingHigh,
  PriorityRatingVeryHigh,
] as const satisfies readonly RatingType[];
