import React, { FC, ChangeEvent } from "react";

import { ButtonSizes, ButtonColor } from "~/constants/buttonAndForm";

type PropsType = {
  name: string;
  value?: string | number;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  labelText: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const RoundedRadioButtonField: FC<PropsType> = ({
  name,
  value,
  checked = false,
  required = false,
  disabled = false,
  labelText,
  className = "",
  onChange,
}: PropsType) => {
  return (
    <div className={`flex flex-wrap ${className}`}>
      <input
        type="radio"
        name={name}
        id={name}
        value={value}
        checked={checked}
        required={required}
        disabled={disabled}
        hidden
        onChange={onChange}
      />
      <label
        htmlFor={name}
        className={`rounded border border-solid text-xs miniTablet:text-sm ${
          disabled ? "" : "cursor-pointer"
        } ${
          ButtonColor[checked ? "contain" : "outline"][
            checked ? "primary" : "gray"
          ]
        } ${ButtonSizes["sm"]}`}
      >
        {labelText}
      </label>
    </div>
  );
};
