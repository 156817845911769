import React, { FC } from "react";

import {
  useApisMembersInsightPostBookmarkFoldersIndex,
  useApisMembersKnowledgeInsightPostBookmarkFoldersIndex,
} from "~/hooks";

import { BookmarkFoldersAccordion } from "~/components/organisms";

type PropsType = {
  breadCrumbHref: string;
};

export const BookmarkSidebar: FC<PropsType> = ({
  breadCrumbHref,
}: PropsType) => {
  const {
    data: insightPostBookmarkFolders,
    isLoading: insightPostBookmarkFoldersLoading,
    error: insightPostBookmarkFoldersError,
    refetch: refetchInsightPostBookmarkFolders,
  } = useApisMembersInsightPostBookmarkFoldersIndex();

  const {
    data: knowledgeInsightPostBookmarkFolders,
    isLoading: knowledgeInsightPostBookmarkFoldersLoading,
    error: knowledgeInsightPostBookmarkFoldersError,
    refetch: refetchKnowledgeInsightPostBookmarkFolders,
  } = useApisMembersKnowledgeInsightPostBookmarkFoldersIndex();

  const insightError =
    insightPostBookmarkFoldersError instanceof Error
      ? insightPostBookmarkFoldersError.message
      : null;
  const knowledgeError =
    knowledgeInsightPostBookmarkFoldersError instanceof Error
      ? knowledgeInsightPostBookmarkFoldersError.message
      : null;

  return (
    <div className="">
      <BookmarkFoldersAccordion
        breadCrumbHref={breadCrumbHref}
        loading={insightPostBookmarkFoldersLoading}
        error={insightError}
        refetch={refetchInsightPostBookmarkFolders}
        bookmarkFolders={insightPostBookmarkFolders?.bookmarkFolders || []}
        allBookmarks={{
          url: "/bookmark/insight_posts",
          text: "個人・チームへのブクマ",
        }}
        folderType="InsightPost"
      />
      <hr className="bg-secondary-300 mx-4 my-6" />
      <BookmarkFoldersAccordion
        breadCrumbHref={breadCrumbHref}
        loading={knowledgeInsightPostBookmarkFoldersLoading}
        error={knowledgeError}
        refetch={refetchKnowledgeInsightPostBookmarkFolders}
        allBookmarks={{
          url: "/bookmark/knowledge_insight_posts",
          text: "上司へのブクマ",
        }}
        folderType="KnowledgeInsightPost"
        bookmarkFolders={
          knowledgeInsightPostBookmarkFolders?.bookmarkFolders || []
        }
      />
    </div>
  );
};
