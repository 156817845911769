import React, { FC, FormEvent, ReactNode } from "react";

type PropsType = {
  className?: string;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  children: ReactNode;
};

export const Form: FC<PropsType> = ({
  className = "",
  onSubmit,
  children,
}: PropsType) => {
  return (
    <form className={className} onSubmit={onSubmit}>
      {children}
    </form>
  );
};
