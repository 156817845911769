import React, { FC, StrictMode } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { ErrorBoundary } from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HelmetProvider } from "react-helmet-async";
import { Flip, ToastContainer } from "react-toastify";

import { queryClient } from "~/libs";

import { ErrorFallback } from "~/components/molecules";

import "~/assets/styles/index.css";
import "react-toastify/dist/ReactToastify.css";

import { AppRoutes } from "~/config/AppRoutes";

export const App: FC = () => {
  return (
    <StrictMode>
      <HelmetProvider>
        <Router>
          <ErrorBoundary fallback={<ErrorFallback />}>
            <ToastContainer
              position="top-right"
              autoClose={1500}
              limit={2}
              transition={Flip}
              pauseOnHover
              closeOnClick
              newestOnTop
              className={"opacity-90"}
            />
            <QueryClientProvider client={queryClient}>
              <AppRoutes />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </ErrorBoundary>
        </Router>
      </HelmetProvider>
    </StrictMode>
  );
};
