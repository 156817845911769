import { ChangeEvent, useCallback, useState } from "react";

type ReturnType = {
  count: number;
  increment: () => void;
  decrement: () => void;
  setValue: (value: number) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const useCounter = (initial = 0): ReturnType => {
  const [count, setCount] = useState<number>(initial);

  const increment = useCallback(() => setCount(count + 1), [count]);
  const decrement = useCallback(() => setCount(count - 1), [count]);
  const setValue = useCallback((value: number) => setCount(value), []);

  return {
    count: count,
    increment: increment,
    decrement: decrement,
    setValue: setValue,
    onChange: (e: ChangeEvent<HTMLInputElement>) =>
      setValue(Number(e.target.value)),
  };
};
