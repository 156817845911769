import React, { FC, ChangeEvent, MouseEvent } from "react";

import { ButtonWithIcon, IconType, Label, TextField } from "~/components/atoms";

import { FormSizes } from "~/constants/buttonAndForm";

type FieldType = "text" | "email" | "password" | "search";

type PropsType = {
  label: string;
  icon: IconType;
  type: FieldType;
  name: string;
  value?: string;
  defaultValue?: string;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  size?: keyof typeof FormSizes;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const IconButtonWithTextField: FC<PropsType> = ({
  label,
  icon,
  type = "text",
  name,
  value,
  defaultValue,
  autoComplete,
  placeholder = "",
  required = false,
  disabled = false,
  className = "",
  onChange,
  onClick,
  size = "sm",
}: PropsType) => {
  return (
    <div className={className}>
      <Label
        htmlFor={name}
        labelText={label}
        required={required}
        className="block"
      />
      <div className="relative mt-1.5 ">
        <TextField
          type={type}
          name={name}
          value={value}
          defaultValue={defaultValue}
          autoComplete={autoComplete}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onChange={onChange}
          size={size}
        />
        <ButtonWithIcon
          icon={{
            icon,
            size: "1.5rem",
            color: "text-secondary-600",
          }}
          onClick={onClick}
          srOnlyText={label}
          className="!absolute inset-y-0 right-0 px-2"
        />
      </div>
    </div>
  );
};
