import React, { FC, FormEvent, MouseEvent, useState } from "react";

import {
  useAiChatConversationRequest,
  useApisMembersAiChatRoomsAiEmployeeConversationsIndex,
  useArray,
  useInput,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { Form, DotLoadingAnimation } from "~/components/atoms";
import { IconButtonWithTextArea, InfiniteScroll } from "~/components/molecules";

import { ChatDetail } from "./ChatDetail";
import { FloatingChatBoxHeader } from "../FloatingChatBoxHeader";

import { AiEmployeeConversationType, AiChatRoomSettingType } from "~/domains";

type PropsType = {
  aiChatRoomId: string;
  onClose: (e: MouseEvent<HTMLButtonElement>) => void;
  setAiChatRoomId: (chatRoomId: null) => void;
};

export const FloatingChatForm: FC<PropsType> = ({
  aiChatRoomId,
  onClose,
  setAiChatRoomId,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const [{ value, onChange }, setValue] = useInput("");
  const { items, setItems, unshiftItem } =
    useArray<AiEmployeeConversationType>();
  const [aiChatRoomSetting, setAiChatRoomSetting] =
    useState<AiChatRoomSettingType>();
  const { aiChatConversationRequest, isEmployeeSubmitting, isChatRequesting } =
    useAiChatConversationRequest(currentEmployee?.id || "");
  const { fetchNextPage, hasNextPage, isLoading } =
    useApisMembersAiChatRoomsAiEmployeeConversationsIndex({
      aiChatRoomId,
      config: {
        onSuccess: (data) => {
          data.pages[0]?.aiChatRoom.aiChatRoomSetting &&
            setAiChatRoomSetting(data.pages[0].aiChatRoom.aiChatRoomSetting);
          setItems(
            data.pages.map((page) => page.aiEmployeeConversations).flat(),
          );
        },
      },
    });

  const handleSetAiChatRoomId = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAiChatRoomId(null);
  };

  const handleSubmit = (
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();

    aiChatConversationRequest({
      message: value,
      systemMessage: aiChatRoomSetting?.aiSystemMessage,
      alreadyMessages: items,
      aiChatRoomId,
      sendMessageOnSuccess: (response) => {
        unshiftItem(response.aiEmployeeConversation);
        setValue("");
      },
      onSuccess: (response) => {
        unshiftItem(response.aiEmployeeConversation);
      },
    });
  };

  return (
    <div className="flex flex-col h-full space-y-6">
      <div>
        <FloatingChatBoxHeader
          onClose={onClose}
          onBack={handleSetAiChatRoomId}
        />
        {aiChatRoomSetting && (
          <p className="text-center mt-2 text-sm text-secondary-600">
            {aiChatRoomSetting.expertRoleName}
          </p>
        )}
      </div>
      <InfiniteScroll
        itemsLength={items.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        inverse
        isLoading={isLoading}
      >
        {items.map((item) => (
          <ChatDetail
            key={item.id}
            conversation={item}
            currentEmployeeAvatarUrl={currentEmployee?.avatarUrl || ""}
            className="mb-4"
          />
        ))}
      </InfiniteScroll>
      {isChatRequesting && <DotLoadingAnimation className="justify-center" />}
      <Form
        onSubmit={handleSubmit}
        className="sticky bottom-0 flex flex-col grow justify-end"
      >
        <IconButtonWithTextArea
          value={value}
          onChange={onChange}
          placeholder="質問を入力してください"
          name="freeWord"
          rows={1}
          size="md"
          icon="ioSend"
          onClick={handleSubmit}
          disabled={isChatRequesting || isEmployeeSubmitting}
        />
      </Form>
    </div>
  );
};
