import React, { ChangeEvent, FC, FormEvent, MouseEvent } from "react";

import { toast } from "react-toastify";

import { useApisManagersInsightPostObjectivesDestroy } from "~/hooks";

import {
  Form,
  Button,
  FormSubmitButton,
  GridHeaderCells,
} from "~/components/atoms";
import {
  LabelWithTextField,
  GridRow,
  InfiniteScrollWithMoreButton,
  OpenedMenuInGrid,
  RangeDatePicker,
  LabelWithDropDownField,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import {
  BusinessDivisionType,
  ChildType,
  InsightPostObjectiveType,
  OptionType,
  ParentType,
  RangeDatePropsType,
  SingleValueType,
} from "~/domains";

type PropsType = {
  className?: string;
  insightPostObjectives: InsightPostObjectiveType[];
  keyword: string;
  startDate: Date | null;
  endDate: Date | null;
  hasNextPage?: boolean;
  isLoading: boolean;
  selectableDropdownPublicStatusIds: OptionType[];
  selectedDropdownPublicStatusId?: SingleValueType<OptionType>;
  selectableDropdownDivisions: BusinessDivisionType[];
  selectedDropdownDivisions: BusinessDivisionType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  findAndRemove: (func: (item: InsightPostObjectiveType) => boolean) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeDropdownPublicStatusId: (value: SingleValueType<OptionType>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const ManagersInsightPostObjectivesIndexTemplate: FC<PropsType> = ({
  className = "",
  insightPostObjectives,
  keyword,
  isLoading,
  hasNextPage,
  startDate,
  endDate,
  selectableDropdownPublicStatusIds,
  selectedDropdownPublicStatusId,
  selectableDropdownDivisions,
  selectedDropdownDivisions,
  selectableDropdownSections,
  selectedDropdownSections,
  onDivisionChange,
  onSectionChange,
  findAndRemove,
  onChangeDropdownPublicStatusId,
  fetchNextPage,
  onChangeDateRange,
  onChangeKeyword,
  onConditionReset,
  onSearchSubmit,
}: PropsType) => {
  const { mutate: destroyRequest } =
    useApisManagersInsightPostObjectivesDestroy();

  const deleteInsightPostObjective = (
    targetInsightPostObjective: InsightPostObjectiveType,
  ) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;
    destroyRequest(
      {
        id: targetInsightPostObjective.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove(
            (insightPostObjective) =>
              insightPostObjective.id === targetInsightPostObjective.id,
          );
        },
      },
    );
  };

  const generateInsightPostObjectiveLists = (
    insightPostObjective: InsightPostObjectiveType,
  ) => {
    return [
      `${insightPostObjective.startDate}~${insightPostObjective.endDate}`,
      insightPostObjective.title,
      insightPostObjective.businessDivision.name,
      insightPostObjective.businessSection.name,
      insightPostObjective.publicStatus.name,
    ];
  };
  return (
    <div className={className}>
      <Form onSubmit={onSearchSubmit} className="space-y-6">
        <div className="grid miniTablet:grid-cols-2 gap-4">
          <MultiParentsWithMultiChildrenDropdownField
            allParents={selectableDropdownDivisions}
            parentsValue={selectedDropdownDivisions}
            parentLabel="部署"
            parentsOnChange={onDivisionChange}
            allChildren={selectableDropdownSections}
            childrenValue={selectedDropdownSections}
            childLabel="課"
            childrenOnChange={onSectionChange}
          />
          <RangeDatePicker
            labelText="期間指定"
            startDate={startDate}
            endDate={endDate}
            onChange={onChangeDateRange}
          />
          <LabelWithDropDownField
            labelText="ステータス"
            name="publicStatus"
            options={selectableDropdownPublicStatusIds}
            value={selectedDropdownPublicStatusId}
            onChange={onChangeDropdownPublicStatusId}
          />
          <LabelWithTextField
            labelText="フリーワード"
            type="search"
            name="keyword"
            placeholder="フリーワード検索"
            value={keyword}
            onChange={onChangeKeyword}
          />
        </div>
        <div className="flex items-center justify-end space-x-4">
          <Button
            color="gray"
            outline
            className="w-full miniTablet:w-auto"
            text="リセット"
            onClick={onConditionReset}
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full miniTablet:w-auto"
          />
        </div>
      </Form>
      <InfiniteScrollWithMoreButton
        itemsLength={insightPostObjectives.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        isLoading={isLoading}
        className="mt-12"
      >
        <div className="grid grid-cols-5">
          <GridHeaderCells
            texts={["期間", "タイトル", "部署", "課", "ステータス"]}
          />
          {insightPostObjectives.map((insightPostObjective) => (
            <GridRow
              key={insightPostObjective.id}
              lists={generateInsightPostObjectiveLists(insightPostObjective)}
              openedMenu={
                <OpenedMenuInGrid
                  cols={5}
                  buttons={[
                    {
                      text: "編集",
                      link: `/managers/insight_post_objectives/${insightPostObjective.id}/edit`,
                    },
                    {
                      text: "削除",
                      onClick: () =>
                        deleteInsightPostObjective(insightPostObjective),
                    },
                    {
                      text: "状況確認",
                      link: `/managers/insight_post_objectives/${insightPostObjective.id}/employee_and_insight_post_objectives`,
                    },
                  ]}
                />
              }
            />
          ))}
        </div>
      </InfiniteScrollWithMoreButton>
    </div>
  );
};
