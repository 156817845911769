import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";

import {
  useInput,
  useArray,
  useProvidersEmployeeLayout,
  useApisMembersEmployeeSurveySubmissionsIndex,
  usePostSortType,
} from "~/hooks";

import {
  EmployeeSurveySubmissionType,
  ApiMembersEmployeeSurveySubmissionsIndexRequestType,
  SortType,
} from "~/domains";

type ReturnType = {
  keyword: string;
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  employeeSurveySubmissions: EmployeeSurveySubmissionType[];
  sort: SortType;
  setSort: (val: SortType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const useSearchMembersEmployeeSurveySubmissions = (): ReturnType => {
  const { sort, snakeKeyValue, setSort } = usePostSortType();
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const { items, setItems } = useArray<EmployeeSurveySubmissionType>([]);

  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");

  const [params, setParams] =
    useState<ApiMembersEmployeeSurveySubmissionsIndexRequestType>({
      sortType: snakeKeyValue,
    });

  const { isFetching, fetchNextPage, hasNextPage } =
    useApisMembersEmployeeSurveySubmissionsIndex({
      params: { ...params },
      config: {
        onSuccess: (data) => {
          setItems(
            data.pages.map((page) => page.employeeSurveySubmissions).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      sortType: snakeKeyValue,
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    setParams({
      sortType: snakeKeyValue,
    });
    setFalseRightSidebarMain();
  };

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      sortType: snakeKeyValue,
    }));
  }, [snakeKeyValue]);

  return {
    sort,
    setSort,
    keyword,
    isFetching,
    fetchNextPage,
    hasNextPage,
    employeeSurveySubmissions: items,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  };
};
