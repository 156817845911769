import { MouseEvent, ChangeEvent, FormEvent, useState } from "react";

import {
  useInput,
  useArray,
  useApisManagersArchivedBusinessDivisionsIndex,
} from "~/hooks";

import {
  BusinessDivisionType,
  ManagersBusinessDivisionsIndexRequestType,
} from "~/domains";

type ReturnType = {
  businessDivisions: BusinessDivisionType[];
  hasNextPage?: boolean;
  keyword: string;
  isLoading: boolean;
  deleteIndexItem: (index: number) => void;
  findAndRemove: (func: (item: BusinessDivisionType) => boolean) => void;
  unshiftItem: (item: BusinessDivisionType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const useSearchManagersArchivedBusinessDivisions = (): ReturnType => {
  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");

  const [params, setParams] =
    useState<ManagersBusinessDivisionsIndexRequestType>();
  const {
    items: businessDivisions,
    setItems: setBusinessDivisions,
    deleteIndexItem,
    unshiftItem,
    findAndRemove,
  } = useArray<BusinessDivisionType>([]);

  const { fetchNextPage, hasNextPage, isFetching } =
    useApisManagersArchivedBusinessDivisionsIndex({
      params,
      config: {
        onSuccess: (data) => {
          setBusinessDivisions(
            data.pages.map((page) => page.businessDivisions).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
    });
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    setParams({});
  };

  return {
    businessDivisions,
    hasNextPage,
    isLoading: isFetching,
    keyword,
    fetchNextPage,
    unshiftItem,
    deleteIndexItem,
    findAndRemove,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  };
};
