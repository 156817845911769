import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import {
  ApiMembersRoleplayingConditionDailyRoleplayingTimesIndexRequestType,
  ApiMembersRoleplayingConditionDailyRoleplayingTimesIndexResponseType,
} from "~/domains";

type RequestDataType = {
  params: ApiMembersRoleplayingConditionDailyRoleplayingTimesIndexRequestType;
};

const request = async ({
  params,
}: RequestDataType): Promise<ApiMembersRoleplayingConditionDailyRoleplayingTimesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApiMembersRoleplayingConditionDailyRoleplayingTimesIndexResponseType>(
      "/api/members/roleplaying_condition/daily_roleplaying_times",
      params,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  params?: ApiMembersRoleplayingConditionDailyRoleplayingTimesIndexRequestType;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersRoleplayingConditionDailyRoleplayingTimesIndex = ({
  params = {},
  config = {},
}: PropsType): UseQueryResult<ApiMembersRoleplayingConditionDailyRoleplayingTimesIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [
      "ApisMembersRoleplayingConditionDailyRoleplayingTimesIndex",
      params,
    ],
    queryFn: () => request({ params }),
  });
};
