import React, { FC } from "react";

import {
  FileViewerIframe,
  ModalWrapper,
  PdfFileViewer,
  TxtFileViewer,
} from "~/components/atoms";

import { AttachFileType } from "~/domains";

type PropsType = {
  onClose: () => void;
  attachedFile: AttachFileType;
  title?: string;
};

type ContentTypeHashRecord = {
  [key: string]: (attachedFile: AttachFileType) => JSX.Element;
};

const ContentTypeHash: ContentTypeHashRecord = {
  image: (attachedFile: AttachFileType) => (
    <img src={attachedFile.url} alt={attachedFile.name} className="mx-auto" />
  ),
  video: (attachedFile: AttachFileType) => (
    <video src={attachedFile.url} controls className="mx-auto" />
  ),
  audio: (attachedFile: AttachFileType) => (
    <audio src={attachedFile.url} controls className="mx-auto" />
  ),
  application: (attachedFile: AttachFileType) => (
    <FileViewerIframe url={attachedFile.url} title={attachedFile.name} />
  ),
  text: (attachedFile: AttachFileType) => (
    <TxtFileViewer url={attachedFile.url} />
  ),
  pdf: (attachedFile: AttachFileType) => (
    <PdfFileViewer url={attachedFile.url} />
  ),
  "": (attachedFile: AttachFileType) => (
    <FileViewerIframe url={attachedFile.url} title={attachedFile.name} />
  ),
};

export const FileViewerModal: FC<PropsType> = ({
  onClose,
  attachedFile,
  title = "",
}: PropsType) => {
  const type =
    attachedFile.contentType === "application/pdf"
      ? "pdf"
      : attachedFile.contentType.split("/")[0]?.toString() || "";

  return (
    <ModalWrapper onClose={onClose} headerTitle={title || attachedFile.name}>
      {ContentTypeHash[type || ""]?.(attachedFile)}
    </ModalWrapper>
  );
};
