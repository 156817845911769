import React, { FC, FormEvent, ChangeEvent, MouseEvent } from "react";

import {
  Form,
  FormSubmitButton,
  TextField,
  Button,
  DropDownField,
} from "~/components/atoms";
import { RangeDatePropsType, TitleWithChild } from "~/components/molecules";
import { SelectDateRangeButtons } from "~/components/organisms";

import { OptionType, SingleValueType } from "~/domains";

type PropsType = {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  date: {
    startDate: Date | null;
    endDate: Date | null;
    onChange: (value: RangeDatePropsType) => void;
  };
  completed: {
    options: OptionType[];
    value: SingleValueType<OptionType> | undefined;
    onChange: (newValue: SingleValueType<OptionType>) => void;
  };
  searchKeyword: {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
};

export const EmployeeAndBusinessSectionMemoReviewSearchForm: FC<PropsType> = ({
  onSubmit,
  onConditionReset,
  date,
  completed,
  searchKeyword,
}: PropsType) => {
  return (
    <Form onSubmit={onSubmit} className="mt-6 space-y-6">
      <>
        <TitleWithChild title="期間指定">
          <SelectDateRangeButtons
            startDate={date.startDate}
            endDate={date.endDate}
            onChange={date.onChange}
            className="my-2"
          />
        </TitleWithChild>
        <TitleWithChild title="完了状況">
          <DropDownField
            name="completed"
            options={completed.options}
            value={completed.value}
            onChange={completed.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="フリーワード">
          <>
            <TextField
              value={searchKeyword.value}
              placeholder="キーワード"
              type="search"
              name="keyword"
              onChange={searchKeyword.onChange}
            />
          </>
        </TitleWithChild>
        <div className="space-x-4 flex">
          <Button
            text="リセット"
            color="gray"
            outline
            onClick={onConditionReset}
            className="w-full"
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full"
          />
        </div>
      </>
    </Form>
  );
};
