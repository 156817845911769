import React, { FC } from "react";

import { useSearchMembersBookmarkedKnowledgeInsightPosts } from "~/hooks";

import { EmployeeLayout } from "~/components/layouts";
import { BookmarkKnowledgeInsightPostsIndexTemplate } from "~/components/templates";

export const MembersBookmarkedKnowledgeInsightPostsIndex: FC = () => {
  const {
    knowledgeInsightPosts,
    startDate,
    endDate,
    hasNextPage,
    isLoading,
    sort,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    keyword,
    selectableDropdownHasCommentIds,
    selectedDropdownHasCommentId,
    onChangeDropdownHasCommentId,
    onChangeDateRange,
    onChangeKeyword,
    onChangeDropdownEmployees,
    fetchNextPage,
    setSort,
    onSearchSubmit,
    onConditionReset,
    updateIndexKnowledgeInsightPost,
    deleteIndexKnowledgeInsightPost,
  } = useSearchMembersBookmarkedKnowledgeInsightPosts();
  const breadCrumbItems = [
    {
      label: "上司へのブクマ",
      href: "/bookmark/knowledge_insight_posts",
    },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <BookmarkKnowledgeInsightPostsIndexTemplate
        isLoading={isLoading}
        knowledgeInsightPosts={knowledgeInsightPosts}
        startDate={startDate}
        endDate={endDate}
        hasNextPage={hasNextPage}
        sort={sort}
        keyword={keyword}
        selectableDropdownHasCommentIds={selectableDropdownHasCommentIds}
        selectedDropdownHasCommentId={selectedDropdownHasCommentId}
        selectableDropdownEmployees={selectableDropdownEmployees}
        selectedDropdownEmployees={selectedDropdownEmployees}
        onChangeDropdownHasCommentId={onChangeDropdownHasCommentId}
        onChangeDateRange={onChangeDateRange}
        onChangeKeyword={onChangeKeyword}
        onChangeDropdownEmployees={onChangeDropdownEmployees}
        fetchNextPage={fetchNextPage}
        setSort={setSort}
        onSearchSubmit={onSearchSubmit}
        onConditionReset={onConditionReset}
        updateIndexKnowledgeInsightPost={updateIndexKnowledgeInsightPost}
        deleteIndexKnowledgeInsightPost={deleteIndexKnowledgeInsightPost}
      />
    </EmployeeLayout>
  );
};
