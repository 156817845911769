export type PublicStatusType =
  | typeof PublicStatusDrafted
  | typeof PublicStatusPublished;

type StatusType = {
  id: 1 | 10;
  name: "下書き" | "公開";
  type: "drafted" | "published";
};

export const PublicStatusDrafted = {
  id: 1,
  name: "下書き",
  type: "drafted",
} as const satisfies StatusType;

export const PublicStatusPublished = {
  id: 10,
  name: "公開",
  type: "published",
} as const satisfies StatusType;

export const PublicStatus = {
  DRAFTED: PublicStatusDrafted,
  PUBLISHED: PublicStatusPublished,
} as const satisfies {
  DRAFTED: StatusType;
  PUBLISHED: StatusType;
};

export const AllPublicStatus = [PublicStatusDrafted, PublicStatusPublished];
