import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  skillEvaluationTermId: string;
  employeeId: string;
  body: {
    comment: string;
  };
};

const request = ({
  skillEvaluationTermId,
  employeeId,
  body,
}: RequestDataType) => {
  return new ApiClient()
    .post<ResponseMessageType>(
      `/api/managers/skill_evaluation_terms/${skillEvaluationTermId}/employees/${employeeId}/comments`,
      {
        skillEvaluationTermEmployeeComment: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersSkillEvaluationTermsEmployeesCommentsCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
