import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient, ConvertParamsToFormData } from "~/utils";

import { ResponseMessageType, RawDraftContentState } from "~/domains";

type RequestDataType = {
  focusChallengeEmployeeId: string;
  body: {
    content: RawDraftContentState;
    attachFiles: File[];
  };
};

const request = ({ focusChallengeEmployeeId, body }: RequestDataType) => {
  const formData = ConvertParamsToFormData({ reply: body });
  return new ApiClient()
    .post<ResponseMessageType>(
      `/api/members/focus_challenge_employees/${focusChallengeEmployeeId}/focus_challenge_employee_replies`,
      formData,
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisMembersFocusChallengeEmployeesFocusChallengeEmployeeRepliesCreate =
  ({ options = {} }: PropsType = {}): UseMutationResult<
    ResponseMessageType,
    RequestDataType
  > => {
    return useMutation(request, { ...options });
  };
