import { AvatarAndNameEmployeeType, EmployeeRoleType } from "~/domains";

export type DeliveryEventNotificationHistoryType = {
  id: string;
  jaPostableTypeName: string;
  title: string;
  postableType: DeliveryEventNotificationHistoryPostableType;
  postableId: string;
  sender: AvatarAndNameEmployeeType;
  employeeRole: EmployeeRoleType;
  readAt?: string;
  createdAt: string;
  updatedAt: string;
};

export type DeliveryEventNotificationHistoryPostableType =
  keyof typeof DeliveryEventNotificationHistoryPostableTypeColorMapping;

export const DeliveryEventNotificationHistoryPostableTypeColorMapping = {
  InsightPost: "border-4 border-solid border-icon-bookmark",
  FocusChallenge: "border-4 border-solid border-icon-bookmark",
  KaizenIdea: "border-4 border-solid border-icon-bookmark",
  InsightPostSharedEmployee: "border-4 border-solid border-icon-share",
  KnowledgeInsightPost: "border-4 border-solid border-icon-share",
  InsightPostObjective: "border-4 border-solid border-primary-600",
  KpiTerm: "border-4 border-solid border-primary-600",
  SkillEvaluationTerm: "border-4 border-solid border-primary-600",
  SkillEvaluationStandardSummaryCategory:
    "border-4 border-solid border-primary-600",
  DocumentFileCategory: "border-4 border-solid border-primary-600",
  SalesConceptCategory: "border-4 border-solid border-primary-600",
  QuestionAnswerCategory: "border-4 border-solid border-primary-600",
} as const;
