import { useContext } from "react";

import { EmployeeLayoutContext } from "~/components/providers/";

type Props = {
  isRightSidebarMain: boolean;
  setTrueRightSidebarMain: () => void;
  setFalseRightSidebarMain: () => void;
  toggleRightSidebarMain: () => void;
};

export const useProvidersEmployeeLayout = (): Props => {
  const {
    isRightSidebarMain,
    setTrueRightSidebarMain,
    setFalseRightSidebarMain,
    toggleRightSidebarMain,
  } = useContext(EmployeeLayoutContext);

  return {
    isRightSidebarMain,
    setTrueRightSidebarMain,
    setFalseRightSidebarMain,
    toggleRightSidebarMain,
  };
};
