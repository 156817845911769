import React, { FC, MouseEvent } from "react";

import { Icon } from "~/components/atoms";

type PropsType = {
  title: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const FolderIconWithTitle: FC<PropsType> = ({
  title,
  onClick,
}: PropsType) => {
  return (
    <button
      type="button"
      className="flex items-center w-full"
      onClick={onClick}
    >
      <div className="bg-primary-600 rounded-full p-2">
        <Icon icon="ioWaterOutline" color="text-white" size="1.5rem" />
      </div>
      <h3 className="text-primary-600 font-bold ml-2 text-left">{title}</h3>
    </button>
  );
};
