import React, { FC, MouseEvent } from "react";

import { PrevNextSelectMonthly } from "~/components/molecules";

import { OriginalCalendar } from "./OriginalCalendar";

type PropsType = {
  dailyActivity?: {
    date: string;
    postsCount: number;
  }[];
  targetDate: Date;
  isLoading: boolean;
  isForRate: boolean;
  title?: string;
  onPrevMonthClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onNextMonthClick: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

export const ActivityOriginalCalendar: FC<PropsType> = ({
  dailyActivity,
  targetDate,
  isLoading,
  isForRate,
  title,
  onPrevMonthClick,
  onNextMonthClick,
  className = "",
}: PropsType) => {
  return (
    <div className={`${className}`}>
      {title && (
        <p className="font-semibold text-primary-600 text-lg text-center">
          {title}
        </p>
      )}
      <PrevNextSelectMonthly
        targetDate={targetDate}
        onPrevMonthClick={onPrevMonthClick}
        onNextMonthClick={onNextMonthClick}
      />
      <OriginalCalendar
        isLoading={isLoading}
        targetDate={targetDate}
        dailyActivity={dailyActivity}
        isForRate={isForRate}
        className="mt-2"
      />
    </div>
  );
};
