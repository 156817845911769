import React, { FC } from "react";

import {
  useApisMembersFocusChallengeEmployeeRepliesLikedEmployeesIndex,
  useArray,
} from "~/hooks";

import { ReactedEmployeesModal } from "~/components/molecules";

import { ReactedEmployeeType } from "~/domains";

type PropsType = {
  focusChallengeEmployeeReplyId: string;
  onClose: () => void;
};

export const FocusChallengeEmployeeReplyLikedEmployeesModal: FC<PropsType> = ({
  focusChallengeEmployeeReplyId,
  onClose,
}: PropsType) => {
  const { items, setItems } = useArray<ReactedEmployeeType>([]);
  const { fetchNextPage, hasNextPage, isLoading } =
    useApisMembersFocusChallengeEmployeeRepliesLikedEmployeesIndex({
      focusChallengeEmployeeReplyId,
      config: {
        onSuccess: (data) => {
          setItems(
            data.pages
              .map((page) => page.focusChallengeEmployeeReplyLikes)
              .flat(),
          );
        },
      },
    });
  return (
    <ReactedEmployeesModal
      headerTitle="いいねしたユーザー"
      onClose={onClose}
      reactedEmployees={items}
      nextFetchFunction={fetchNextPage}
      hasMore={hasNextPage}
      isLoading={isLoading}
    />
  );
};
