import { useState, useEffect, useCallback, useMemo } from "react";

import { toast } from "react-toastify";

import { ApiClient } from "~/utils";

type ReturnType<T> = {
  loading: boolean;
  data: T | null;
  error: string | null;
  refetch: ({
    refetchUrl,
    refetchParams,
    refetchOnSuccess,
  }: RefetchRequestType<T>) => void;
};

type PropsType<T> = {
  url?: string;
  params?: ParamsType;
  shouldSkipInitialFetch?: boolean;
  onSuccess?: (data: T) => void;
  onError?: (err: { message: string }) => void;
  showToast?: boolean;
};

type RefetchRequestType<T> = {
  refetchUrl?: string;
  refetchParams?: ParamsType;
  refetchOnSuccess?: (data?: T) => void;
};

type ParamsType = {
  [key: string]: unknown;
};

export function useFetch<T>({
  url,
  params,
  shouldSkipInitialFetch = false,
  onSuccess,
  onError,
  showToast = false,
}: PropsType<T>): ReturnType<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const memoizeUrl = useMemo(() => url, [url]);

  const refetch = useCallback(
    ({
      refetchParams,
      refetchUrl,
      refetchOnSuccess,
    }: RefetchRequestType<T>) => {
      const url = refetchUrl || memoizeUrl;

      if (!url) return;
      setLoading(true);
      const apiClient = new ApiClient();
      apiClient
        .get<T>(url, refetchParams || params)
        .then((response) => {
          setData(response.data);
          if (refetchOnSuccess) {
            refetchOnSuccess(response.data);
          } else if (onSuccess) {
            onSuccess(response.data);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          setError(err.message);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          onError?.(err);
          if (showToast) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            toast.error(err.message);
          }
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memoizeUrl],
  );

  const clear = useCallback(() => {
    setData(null);
    setLoading(false);
    setError(null);
  }, []);

  useEffect(() => {
    if (shouldSkipInitialFetch) return;

    if (memoizeUrl) refetch({});
    return () => clear();
  }, [clear, memoizeUrl, refetch, shouldSkipInitialFetch]);

  return {
    loading,
    data,
    error,
    refetch,
  };
}
