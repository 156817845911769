import React, { FC } from "react";

import { PickSkillEvaluationStandardType } from "~/hooks";

import { ArchivedSkillEvaluationStandardType } from "~/domains";

type PropsType = {
  skillEvaluationStandard: ArchivedSkillEvaluationStandardType;
  selectSkillEvaluationStandard?: (
    newSkillEvaluationStandard: PickSkillEvaluationStandardType,
  ) => void;
  memberSelected?: boolean;
  managerSelected?: boolean;
};

export const ArchivedSkillEvaluationStandardDetail: FC<PropsType> = ({
  skillEvaluationStandard,
  selectSkillEvaluationStandard,
  memberSelected = false,
  managerSelected = false,
}: PropsType) => {
  const { title, description, point } = skillEvaluationStandard;
  return (
    <div
      className={`rounded px-3 py-2 min-w-[13rem] max-w-[13rem] ${
        selectSkillEvaluationStandard && "cursor-pointer"
      } ${
        memberSelected
          ? "border-primary-600 border-2"
          : "border border-gray-300"
      }
        ${managerSelected && "bg-primary-200"}`}
      onClick={() =>
        selectSkillEvaluationStandard &&
        selectSkillEvaluationStandard(skillEvaluationStandard)
      }
    >
      <p className="font-semibold text-secondary-600 truncate">{title}</p>
      <p className="font-semibold text-secondary-600 ">{point}pt</p>
      <p className="whitespace-pre-wrap mt-2 border-t border-gray-300 pt-2 min-h-[10rem]">
        {description}
      </p>
    </div>
  );
};
